import Axios from 'axios'
import Cookies from 'js-cookie'
import { replaceId } from './helpers'

export const api = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export enum EndT {
  signup,
  me,
  login,
  validateOtp,
  addProduct,
  deleteProduct,
  addAddress,
  deleteAddress,
  castingVogz,
  brief,
  allBriefsBrand,
  deleteBrands,
  actionBrands,
  vogzterList,
  briefApplications,
  detailBA,
  bAUser,
  userinfo,
  userinfoPublic,
  updateBasket,
  updateRating,
  markSeen,
  generateMaster,
  sendProduct,
  validateSelection,
  brands,
  allbriefs,
  validateExclus,
  validateStep2,
  declineBa,
  allMedias,
  sendMessage,
  allTeamMember,
  updateTeamMember,
  actionFavourite,
  createBrief,
  updateBrief,
  notificationsBrief,
  duplicateBrief,
  closeBrief,
  cancelBrief,
  validateBrief,
  typesBrief,
  aiBrief,
  favourite,
  guestList,
  uploadFile,
  deleteFile,
  addStripePaymentMethod,
  extendBriefDeadline,
  allBoughtMedia,
  company,
  getPlans,
  getPublicSubscriptionsPlans,
  getTopUpLink,
  PlanOlder,
  saveMedia,
  brandMessages,
  brandMessagesRead,
  confirmRdv,
  editRdv,
  updateNotifMember,
  getCategories,
  generatePassword,
  setPassword,
  getUsedCategories,
  addReferrer,
  goPremium,
  getSubscriptionsPlans,
  sendTips,
  getOffer,
  getOffersByVogzter,
  getLinkStripe,
  publicationDate,
  declinedPublication,
  confirmPublication,
  getCountries,
  getLanguages,
  getReviews,
  addReview
}

export class Endpoint {
  private prefixe = 'clients'

  private prefixeCp = 'companies'

  private [EndT.login] = `${this.prefixe}/login`

  private [EndT.generatePassword] = `${this.prefixe}/generate-password`

  private [EndT.setPassword] = `${this.prefixe}/set-password`

  private [EndT.signup] = `${this.prefixe}/signup`

  private [EndT.company] = `${this.prefixeCp}/:id`

  private [EndT.addReferrer] = `${this.prefixeCp}/:id/referrer/:parentCode`	

  private [EndT.goPremium] = `${this.prefixeCp}/:id/premium`

  private [EndT.createBrief] = `/briefs`

  private [EndT.updateBrief] = `/briefs/:id`

  private [EndT.notificationsBrief] = `${this[EndT.updateBrief]}/notifications`

  private [EndT.validateBrief] = `/briefs/:id/validation`

  private [EndT.aiBrief] = `/briefs/aibrief`

  private [EndT.uploadFile] = `/briefs/:id/files`

  private [EndT.deleteFile] = `/briefs/:id/files`

  private [EndT.addStripePaymentMethod] = `/companies/:id/payment-link`
  
  private [EndT.me] = `${this.prefixe}/me`

  private readonly [EndT.allbriefs] = `${this.prefixe}/briefs`

  private readonly [EndT.allBoughtMedia] = `${this.prefixe}/bought-media`

  readonly userme: string = `${this.prefixe}/me`;

  readonly [EndT.userinfo] = 'users/:id/info';

  readonly [EndT.userinfoPublic] = 'users/:id/info-public';

  readonly [EndT.validateOtp]: string = `${this.prefixe}/validate-otp`

  private [EndT.allTeamMember] = `${this.prefixeCp}/:id/members`

  private [EndT.updateTeamMember] = `${this.prefixeCp}/:id/members/:memberId`

  private [EndT.updateNotifMember] = `${this.prefixeCp}/:id/members/notifications`

  private [EndT.castingVogz] = 'briefs/:id/media'

  private [EndT.markSeen] = '/media/:id/mark-seen'

  private [EndT.generateMaster] = '/media/:id/generate-master'

  private [EndT.brief] = 'briefs/:id'

  private [EndT.briefApplications] = 'briefs/:id/briefapplications'

  private [EndT.detailBA] = '/briefapplications/:id'

  private [EndT.sendTips] = `${this[EndT.detailBA]}/tips`

  private [EndT.bAUser] = 'briefapplications/:id'

  private [EndT.updateBasket] = '/media/:id/update-basket'

  private [EndT.publicationDate] = '/media/:id/publication-date'

  private [EndT.declinedPublication] = '/media/:id/declined-publication'

  private [EndT.confirmPublication] = '/media/:id/confirm-publication'

  private [EndT.sendMessage] = `/${this[EndT.bAUser]}/messages`

  private [EndT.validateExclus] = `${this[EndT.brief]}/validate-exclu`

  private [EndT.typesBrief] = '/settings/brieftypes'

  private [EndT.getCountries] = '/settings/countries'

  private [EndT.getLanguages] = '/settings/languages'

  private [EndT.extendBriefDeadline] = '/briefs/:id/extend-deadline'

  private [EndT.duplicateBrief] = 'briefs/:id/duplicate'

  private [EndT.closeBrief] = 'briefs/:id/close'

  private [EndT.cancelBrief] =  `${this[EndT.detailBA]}/cancel`

  private [EndT.addProduct] = '/brands/:id/products'

  private [EndT.deleteProduct] = '/brands/:id/products/:productId'

  private [EndT.addAddress] = '/brands/:id/addresses'

  private [EndT.deleteAddress] = '/brands/:id/addresses/:addressId'

  private [EndT.brandMessages] = '/brands/:id/messages'

  private [EndT.brandMessagesRead] = `${this[EndT.brandMessages]}/mark-read`
  
  private [EndT.getPlans] = 'settings/plans'

  private [EndT.getPublicSubscriptionsPlans] = 'settings/subscription-plans-public'

  private [EndT.PlanOlder] = `${this.prefixeCp}/:id/plan-orders`

  private [EndT.saveMedia] = `/companies/:id/media/:mediaId`

  private [EndT.getCategories] = '/settings/categories'

  private [EndT.getUsedCategories] = '/settings/categories/used'

  private [EndT.getSubscriptionsPlans] = 'settings/subscription-plans'
  
  private [EndT.getOffer] = '/users/offer/public/:id'

  private [EndT.getTopUpLink] = '/companies/:id/top-up'

  private [EndT.getOffersByVogzter] = '/users/:id/public/offers'

  private [EndT.getLinkStripe] = '/companies/:id/portal-url'

  private [EndT.getReviews] = '/users/:id/reviews'

  private [EndT.addReview] = '/users/:id/review'


  /* type request
   * @method post
   * validate ba to step 2
   */
  private [EndT.validateStep2] = `${this[EndT.bAUser]}/step2`

  /* type request
   * @method post
   * decline a brief application, turn it to lost
   */
  private [EndT.declineBa] = `${this[EndT.bAUser]}/decline`

  /* type request
   * @method patch
   */
  private [EndT.updateRating] = '/media/:id/update-rating'

  /* type request
   * @method get
   * @{trackingLink} traking link
   */
  private [EndT.sendProduct] = `${this[EndT.bAUser]}/send-product`
  
  private [EndT.confirmRdv] = `${this[EndT.bAUser]}/confirm-rdv`

  private [EndT.editRdv] = `${this[EndT.bAUser]}/edit-rdv`
  
  /* type request
   * @method get
   */
  private [EndT.allMedias] = 'briefapplications/:id/media'

  /* type request
   * @method get
   */
  private [EndT.validateSelection] = `${this[EndT.bAUser]}/validate`

  /* type request
   * @method post get
   */
  private [EndT.allBriefsBrand] = `/brands/:id/briefs`

  private [EndT.brands] = `/${this.prefixeCp}/:id/brands`

  private [EndT.favourite] = `/brands/:id/favourites`

  private [EndT.actionFavourite] = `/brands/:id/favourites/:favouriteId`

  private [EndT.deleteBrands] = `/brands/:id/guests/:guestId`

  private [EndT.guestList] = `/brands/:id/guests`

  private [EndT.actionBrands] = `/brands/:id`

  private [EndT.vogzterList] = `/users/all`

  public getEndpoint(type: EndT, id?: string) {
    return this[type].replace(':id', id || '')
  }

  // get brief infos
  public getBrief(id: string) {
    return this[EndT.brief].replace(':id', id)
  }
}

export default api
