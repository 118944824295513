import React, { FC } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { BrandI, Guest } from '../../../../../../types-project/Client'
import styles from './components/styles.module.scss'
import userMobx from '../../../../../../mobx/user'

const BrandCard: FC<Brands> = ({ brand }) => {
  return (
    <button
      type="button"
      className={styles['card-brand']}
      onClick={() => {
        userMobx.setBrandSession((brand! as unknown) as BrandI<Guest>)
      }}
    >
      {brand?.logo ? <img src={brand.logo} alt="logo brand" /> : brand?.name}
    </button>
  )
}
interface Brands {
  brand?: BrandI
}
BrandCard.propTypes = {
  brand: PropTypes.any,
}
BrandCard.defaultProps = {
  brand: undefined,
}

export default observer(BrandCard)
