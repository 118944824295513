import React, { FC } from "react";
import PropTypes from "prop-types"
import ReactCountryFlag from "react-country-flag";
import styles from "./components/badge.module.scss"

interface BadgeProps {
  label: string
  deletable?: boolean
  country?: string
  handleDeletion?: () => void
  className?: string
}

const Badge: FC<BadgeProps> = ({ label, country, deletable, handleDeletion, className }) => {

  return (
    <div className={`${styles['badge']} ${className}`}>
      {label}
      {!!deletable && <i className="fa-solid fa-xmark ml-2 text-[#AFDDE0]" onClick={handleDeletion} />}
      {
        country && (
          <ReactCountryFlag
            countryCode={country}
            svg
            className="emoji-flag ml-2"
            title={country}
          />
        )
      }
    </div>
  )
}

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  handleDeletion: PropTypes.func,
  country: PropTypes.string,
  deletable: PropTypes.bool,
  className: PropTypes.string,
}

Badge.defaultProps = {
  deletable: false,
  handleDeletion: () => { },
  className: '',
}


export default Badge