import TextareaAutosize from 'react-textarea-autosize'
import { motion } from 'framer-motion'
import { observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'
import { ChatFeed } from 'react-chat-ui'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import ReactLoading from 'react-loading'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import _, { indexOf } from 'lodash'

import userMobx from '../../mobx/user'
import utilsMobx from '../../mobx/utils'
import {
  AllMessages,
  ClientI,
  GroupedMessages,
  TypeClient,
  TypeCompanyRole,
  Vogzter,
} from '../../types-project/Client'
import BrandSwitcher from '../../utils/components/BrandSwitcher/BrandSwitcher'
import Btn1 from '../../utils/components/Button/Btn1'
import SearchBar from '../../utils/components/SearchBar'
import { countMsgNRead, getAvatar, readMessageSocket, variantPage } from '../../utils/helpers'
import Bubble from './components/Bubble'
import CardMsg from './components/Card-message'
import styles from './components/styles.module.scss'
import BtnBack from '../../utils/components/Button/BtnBack'
import ChatMessages from './components/ChatMessages'
import BtnSendMessage from './components/ChatMessages/components/BtnSend'
import { Message } from './components/Bubble/components/message'
import VogzterPicture from '../Favorites-vogzters/Profil-vogzters/components/VogzterPicture'
import briefR from '../../mobx/brief'
import modal from '../../mobx/modal'
import i18n from '../../utils/i18n'

const Chat = observer(ChatFeed)

const Messaging = () => {
  const navigate = useNavigate()
  // text message to be send
  const [textMessage, setTextMessage] = useState<string>('')
  const [showMessages, setShowMessages] = useState<boolean>(true)
  const [
    selectedIdUserCurrentMessages,
    setSelectedIdUserCurrentMessages,
  ] = useState<string>("")
  const [search, setSearch] = useState('')
  const filterSearch = (groups: GroupedMessages[]) => {
    if (!search) {
      return groups
    }
    const matchingGroup = groups.filter((group) => {
      return group.messages.some((message) => {
        const messageText = (message.text || '').toLowerCase()
        const searchTerms = search.toLowerCase()
        const firstName = (
          (message.user as Vogzter)?.first_name ||
          ''
        ).toLowerCase()
        const lastName = (
          (message.user as Vogzter)?.last_name ||
          ''
        ).toLowerCase()
        // Check if any of the search terms appear in the message text or sender's first name
        return (
          messageText?.includes(searchTerms) ||
          message.sender?.includes(searchTerms) ||
          firstName?.includes(searchTerms) ||
          lastName?.includes(searchTerms)
        )
      })
    })
    return matchingGroup
  }

  // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  const brand =
    userInfos._company.type === 'agency'
      ? userMobx.getBrandSession()!
      : userMobx.brand.data

  useEffect(() => {
    briefR.getAllBoughtMedia()
    userMobx.getMe()
    userMobx.getAllMsgBrand()
  }, [])

  useEffect(() => {
    setShowMessages(utilsMobx.getWidth > utilsMobx.mobileMaxWidth)
  }, [utilsMobx.window.width])

  const { loading, data, error } = userMobx.messages
  const allMsgUser = data?.filter(
    (group) => (group?.brief_applications?.length ?? 0) > 0 || (userMobx.brand.data?.favourites ?? [])?.includes(group.user?._id)
  )

  const msgArchived = data?.filter(
    (group) =>
      (group?.brief_applications?.length ?? 0) === 0 && !(userMobx.brand.data?.favourites ?? [])?.includes(group.user?._id)
  )

  const countMsgArchivedNRead = countMsgNRead(
    msgArchived!.flatMap((group) => group.messages)
  )

  const [filterTypeConversation, setFilterTypeConversation] = useState<
    'all' | 'archived'
  >('all')
  const { t } = useTranslation()
  const sendMessage = () => {
      userMobx.sendMessage(textMessage, selectedIdUserCurrentMessages!, brand?._id!);
      setTextMessage('')
  }
  const allMsgUserSelected = data!
    .filter((group) => group.user?._id === selectedIdUserCurrentMessages)
    .flatMap((group) => group.messages)
  const conversationSelected = data!
    .filter((group) => group.user?._id === selectedIdUserCurrentMessages)
    .flatMap((group) =>
      group.messages.flatMap(
        (message) =>
          new Message({
            id: message._id,
            message: message.text,
            senderName: message.sender,
            createdAt: message.createdAt,
            read: message.read && message.sender === 'client',
            lastOne: _.last(group.messages)?._id === message._id,
            offer: message.offer,
            brief: message.brief
          })
      )
    )
  useEffect(() => {
    readMessageSocket(allMsgUserSelected)
  }, [allMsgUserSelected])
  const userSelectedBa = allMsgUser?.find(
    (group) => group.user?._id === selectedIdUserCurrentMessages
  )
  const userSelected = allMsgUserSelected.at(allMsgUserSelected?.length - 1)
    ?.user as Vogzter
  return (
    <main className={`h-full ${styles['container-messaging']}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Messaging</title>
      </Helmet>
      <>
        <motion.section
          variants={variantPage} // Pass the variant object into Framer Motion
          initial="hidden" // Set the initial state to variants.hidden
          animate="enter" // Animated state to variants.enter
          exit="exit" // Exit state (used later) to variants.exit
          transition={{ type: 'linear' }} // Set the transition to linear
          onAnimationComplete={() => {
            utilsMobx.handleAssideEnded(true)
          }}
          className={`
            ${styles['all-page']}
            ${utilsMobx.aside_animation_ended ? '!transform-none' : ''}`}
        >
          <section className={styles['section-list-messages']}>
            <div className={styles['header-bar']}>
              {userMobx.me.data?.company_role !== TypeCompanyRole.GUEST && (
                <BrandSwitcher dntComesFromBriefList />
              )}
            </div>
            {filterTypeConversation === 'archived' ?
              <BtnBack btnName={t('mycastings:return')} customAction={() => setFilterTypeConversation('all')} />
              : null}
            <div className={styles['header-list']}>
              <SearchBar
                inputClass="sm:!w-[280px]"
                onChange={(v) => {
                  setSearch(v.target.value ?? undefined)
                }}
              />
              <button
                aria-pressed={
                  filterTypeConversation === 'archived' ? 'true' : 'false'
                }
                type="button"
                onClick={() => {
                  if (filterTypeConversation === 'archived')
                    setFilterTypeConversation('all')
                  else setFilterTypeConversation('archived')
                  setSelectedIdUserCurrentMessages('')
                }}
              >
                <p>{t('messages:chat-archived')}</p>
                {countMsgArchivedNRead > 0 ? (
                  <span>{countMsgArchivedNRead}</span>
                ) : null}
              </button>
            </div>
            <div className={styles['list-messages']}>
              <>
                {!loading &&
                  !error &&
                  data &&
                  (filterTypeConversation !== 'archived'
                    ? filterSearch(allMsgUser!).length > 0
                    : filterSearch(msgArchived!).length > 0) ? (
                  (filterTypeConversation !== 'archived'
                    ? filterSearch(allMsgUser!)
                    : filterSearch(msgArchived!)
                  ).sort((a, b) => new Date(b.messages.at(b.messages.length - 1)?.createdAt!).getTime() - new Date(a.messages.at((a.messages?.length ?? 0) - 1)?.createdAt!).getTime())
                    .map((msgUser, k) => (
                      <CardMsg
                        key={k}
                        message={msgUser as GroupedMessages}
                        selectedIdUserCurrentMessages={
                          selectedIdUserCurrentMessages
                        }
                        setSelectedIdUserCurrentMessages={
                          (id: string) => {
                            setSelectedIdUserCurrentMessages(id)
                            if (!showMessages) setShowMessages(true)
                          }
                        }
                      />
                    ))
                ) : (
                  <div className={styles['placeholder-list-msg']}>
                    <img
                      src="/assets/imgs/placeholder-list-msg.png"
                      alt="decorative"
                    />
                    <h3>{t('messages:placeholder.list.title')}</h3>
                    <p>
                      {t('messages:placeholder.list.desc1')}{' '}
                      <span
                        aria-label="emojis visage fatigué et triste"
                        role="img"
                      >
                        😪
                      </span>{' '}
                      {t('messages:placeholder.list.desc2')}
                    </p>
                    <Btn1
                      disabled={!userMobx.me.data?._company.premium}
                      onclick={() => {
                        if ((userMobx.brandSession.data !== null || userMobx.getBrandSession() !== null || 
                            (userMobx.me.data?._company.type === TypeClient.BRAND ))&&
                        userMobx.me.data !== null && userMobx.me.data._company.premium)
                          navigate('/dashboard/new-castings')
                      }}
                      btnName={t('messages:placeholder.list.btn')}
                    />
                  </div>
                )}
              </>
            </div>
          </section>
          <hr />
          {showMessages && (
            <ChatMessages
              conversation={conversationSelected ?? []}
              onChangeTextMessage={(v) => { setTextMessage(v) }}
              textMessage={textMessage}
              classnameContainer="!z-50"
              sendMessage={sendMessage}
              disabledTextMessage={selectedIdUserCurrentMessages === undefined ||
                (filterTypeConversation === 'archived' &&
                  !((userMobx.brand.data?.favourites ?? []).includes(
                    selectedIdUserCurrentMessages!
                  )))}
              renderHeader={
                selectedIdUserCurrentMessages ? (<Fragment>
                  <button
                    className={styles['btn-close-msg']}
                    type="button"
                    onClick={() => {
                      setShowMessages(false)
                      setSelectedIdUserCurrentMessages('')
                    }}
                  >
                    <i className="fa-regular fa-xmark" />
                  </button>
                  <section>
                    <div className={styles['profil']}>
                      <VogzterPicture
                        imgSource={userSelected?.profile?.picture?.source}
                        gender={userSelected?.profile?.gender}
                        size={50}
                        showCertified={userSelected?.certified}
                        lastActivity={userSelected?.last_activity}
                        href={`/dashboard/profil-vogzter/${selectedIdUserCurrentMessages}`}
                      />
                      <div className={styles['profil-info']}>
                        <h3>
                          {userSelected?.first_name}{' '}
                          {userSelected?.last_name}.{' '}
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                (userMobx.brand.data?.favourites ?? [])?.includes(
                                  selectedIdUserCurrentMessages
                                )
                              ) {
                                userMobx.deleteFavouriteVogzters(
                                  selectedIdUserCurrentMessages!
                                )
                              } else {
                                userMobx.addFavouriteVogzters(
                                  selectedIdUserCurrentMessages!
                                )
                                if (filterTypeConversation === 'archived')
                                  setFilterTypeConversation('all')
                              }
                            }}
                            className={`${filterTypeConversation === 'archived'
                              ? '!text-4xl pl-12'
                              : ''
                              }`}
                          >
                            {(userMobx.brand.data?.favourites ?? [])?.includes(
                              selectedIdUserCurrentMessages
                            ) ? (
                              <i className="fa-solid fa-heart top-5 right-6 text-[#F41A1A] z-[3]" />
                            ) : (
                              <i className="fa-solid fa-heart top-5 right-6 text-[#FFD1D1] z-[3]" />
                            )}
                          </button>
                        </h3>
                        <p>
                          {userSelected?.certified
                            ? t('messages:vogzter-certified')
                            : null}
                        </p>
                      </div>
                    </div>
                    <br />
                    {(userSelectedBa?.brief_applications?.length ?? 0) > 0 ? (
                      <Swiper
                        slidesPerView={2}
                        centeredSlides
                        className={'w-full'}
                        spaceBetween={10}
                        autoplay={{
                          delay: 2500,
                        }}
                        modules={[Autoplay]}
                      >
                        {Array.from(
                          new Set(
                            userSelectedBa?.brief_applications
                              ?.filter((briefAp, i, array) => {
                                return (
                                  array.findIndex(
                                    (briefa) => briefa?.brief._id === briefAp.brief?._id
                                  ) === i
                                )
                              })
                          )
                        ).map((briefAp, k) => (
                          <SwiperSlide key={k}>
                            <Btn1
                              btnName={briefAp.brief?.name!}
                              className={`${styles['btn-header']} `}
                              type="button"
                              onclick={() => {
                                if (filterTypeConversation === 'all') {
                                  if (briefAp.brief.private?.is_private ?? false)
                                    navigate(`/dashboard/castings/${briefAp.brief?._id!}#messages`)
                                  else
                                    navigate(`/dashboard/castings/${briefAp.brief?._id!}/briefapplication/${briefAp._id}#messages`)
                                }
                              }}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )  : null}
                  </section>
                  <hr />
                </Fragment>) : undefined
              }
              renderCustomSendButton={
                <Fragment>
                  {userMobx.messages.loading ? (
                    <ReactLoading
                      type="spokes"
                      color="#000"
                      width={22}
                      className="flex items-center"
                    />
                  ) : <BtnSendMessage sendMessage={sendMessage} />}
                </Fragment>
              } />
          )}
        </motion.section>
      </>
    </main>
  )
}

export default observer(Messaging)
