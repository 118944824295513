import React, { FC } from 'react'
import PropTypes from 'prop-types'
import styles from '../components/styles.module.scss'

const ErrorMsg: FC<Btn1Props> = ({ type, text }) => {
  return <span className={styles['err-msg1']}>{text}</span>
}

interface Btn1Props {
  type?: 'darken' | 'lighten'
  text: string
}

ErrorMsg.defaultProps = {
  type: 'lighten',
}

ErrorMsg.propTypes = {
  type: PropTypes.oneOf(['darken', 'lighten']),
  text: PropTypes.string.isRequired,
}

export default ErrorMsg
