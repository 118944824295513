import { observer } from 'mobx-react'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { TextField } from '@mui/material'
import TextareaAutosize from 'react-textarea-autosize'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import styles from '../brief-styles.module.scss'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import briefR from '../../../../../mobx/brief'
import  userMobx  from '../../../../../mobx/user'
import { ClientI } from '../../../../../types-project/Client'

interface CardTipsProps {
    setModalTips: Dispatch<SetStateAction<boolean>>,
}
const CardTips: FC<CardTipsProps> = ({setModalTips}) => {
    const { t } = useTranslation()
    type Tips = {
        amount: number | undefined
        description: string
      }
    const userInfos =userMobx.getUserInfos() as ClientI
    const handleSendTips = () => {
        briefR.sendTips(
          briefR.briefApplication.data?.at(0)?._id ?? briefR.detailBA.data?.briefApplication._id ?? '',
          formikTips.values.amount!,
          formikTips.values.description,
          (tips) => {
            setModalTips(false)
            toast.success(`Tips de ${tips}€ à été envoyé avec succès`)
          }
        )
    }

    const validationSchemaTips = Yup.object().shape({
        amount: Yup.number().required('Merci de renseigner le montant'),
      })
    const onSuccess = (stripeUrl: string) => {
      window.location.href = stripeUrl
    }
    const formikTips = useFormik<Tips>({
        initialValues: { amount: undefined, description: '' },
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true,
        validationSchema: validationSchemaTips,
        onSubmit: () => {
          if (userInfos._company.credits < formikTips.values.amount!){
            userMobx.getTopUpLink(formikTips.values.amount! - userInfos._company.credits, {
              ok: `/dashboard/castings/${briefR.brief.data?._id}?card=ok`,
              ko: `/dashboard/castings/${briefR.brief.data?._id}?card=ko`,
            }, onSuccess, () => {})
          } else
            handleSendTips()
        },
      })
  return (
    <div className={styles['tips-modal']}>
    <div className={styles['top-part']}>
      <h1>{t('mycastings:private-deal:tips-title')}</h1>
      <p>{t('mycastings:private-deal:tips-desc')}</p>
      <button type='button' onClick={()=> setModalTips(false)}>
        <i className='fa-regular fa-circle-xmark'/>
      </button>
    </div>
    <div className={styles['bottom-part']}>
      <b dangerouslySetInnerHTML={{__html:t('mycastings:private-deal:tips-comment')}}/>
      <br />
      <FormikProvider value={formikTips}>
        <form onSubmit={formikTips.handleSubmit} className={styles['form']}>
          <TextareaAutosize
            style={{ boxSizing: 'border-box' }}
            minRows={3}
            maxRows={5}
            className={styles['text-field']}                          
            onChange={formikTips.handleChange}
            value={formikTips.values.description}
            name="description"
            placeholder={t('mycastings:private-deal:tips-placeholder')}
          />
          <div className={styles['input-tips']}>
            <TextField
              required
              className={styles['text-field']}
              type="number"
              onChange={formikTips.handleChange}
              value={formikTips.values.amount}
              name="amount"
              id='amount'
              placeholder={t('mycastings:private-deal:tips-placeholder-amount')}
            />
            <BtnBasic
              disabled={!formikTips.isValid}
              type="inverted-sky-violet"
              btnName={t('mycastings:private-deal:send')}
              typeBtn="submit"
            />
          </div>
        </form>
      </FormikProvider>
    </div>
  </div>
  )
}

CardTips.propTypes = {
    setModalTips: PropTypes.func.isRequired,
}

export default observer(CardTips)
