/* eslint-disable jsx-a11y/media-has-caption */
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FormikErrors, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { motion } from 'framer-motion'

import styles from './component/style.module.scss'
import briefR from '../../../../../mobx/brief'
import {
  getAvatar,
  Tags,
  truncate,
  MOTION_VARIANTS,
} from '../../../../../utils/helpers'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import BtnBack from '../../../../../utils/components/Button/BtnBack'
import userMobx from '../../../../../mobx/user'
import teams from '../../../../../mobx/teams'
import { ClientI, Notification } from '../../../../../types-project/Client'
import { NotificationsBrief } from '../../../../../types-project/Brief'
import SwitchBtn from '../../../../../utils/components/Ui/inputs/SwitchBtn'

type FormikValues = {
  all_notifications: Notification
  notifications_client: NotificationsBrief[]
}

function NotificationBrief() {
  const briefData = briefR.brief?.data
  const userInfos = userMobx.getUserInfos() as ClientI
  const { id } = useParams<{
    id: string
  }>()
  const edit = new URLSearchParams(window.location.search).get('edit')  
  const members = teams.members?.data ?? []
  const navigate = useNavigate()
  const [displayBtnSwitch, setDisplayBtnSwitch] = useState({
    all: false,
    client: members?.map((member)=>(
       false
    ) ) || [false],
  })
  const initialValues = {
    all_notifications: {
      new_message: true,
      new_vogz: true,
      news: true,
    },
    notifications_client: members?.map((member) => ({
      client: member._id,
      new_message: member?.notifications.new_message || true,
      new_vogz:
        briefData?.notifications?.find((notif) => notif.client === member._id)
          ?.new_vogz || true,
      news:
        briefData?.notifications?.find((notif) => notif.client === member._id)
          ?.news || true,
    })),
  }
  const formik = useFormik<FormikValues>({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: false,
    onSubmit: async () => {
      briefR.patchNotifications(
          [...formik.values.notifications_client] ,
        id as string
      )
      if (userInfos.company_role === 'admin')
        teams.notifAllMember(
          formik.values.notifications_client.map((notif) => {
            return { client: notif.client, notifications: { new_message: !!notif?.new_message} }
          })
        )
      else
        userMobx.updateClient({
          notifications: {
            new_message: !!formik.values?.notifications_client?.find((client) => client.client === userInfos._id)?.new_message
          }
        })
    if(edit === 'true') 
      navigate('/dashboard/castings', { replace: true })
    else
      navigate('/dashboard/castings?brief=ok', { replace: true })
    },
  })
  useEffect(() => {
    teams.allMember((dataM) => {
      briefR.getBrief(id!, (data) => {
        formik.setValues({
          ...formik.values,
          all_notifications: {
            new_message: dataM.every((member) => member?.notifications.new_message === true),
            new_vogz:
              data?.notifications?.every((brief) => brief?.new_vogz === true),
            news:
              data?.notifications?.every((brief) => brief?.news === true) ,
          },
          notifications_client: dataM?.map((members) => ({
            client: members._id,
            new_vogz:
              data?.notifications?.find((data) => data.client === members._id)
                ?.new_vogz ,
            news:
              data?.notifications?.find((data) => data.client === members._id)
                ?.news ,
            new_message: members?.notifications.new_message ,
          })),
        })
      })
      setDisplayBtnSwitch({
        all: false,
        client: dataM?.map((member) => false),
      })
    })
  }, [])
  // translation
  const { t } = useTranslation()

  useEffect(() => {
    if (
      !teams.members.error &&
      !teams.members.loading &&
      members &&
      members?.length > 0
    ) {
      if (
        formik.values.notifications_client.filter(
          (notification) => notification?.new_vogz === true
        ).length === members?.length
      )
        formik.setFieldValue('all_notifications.new_vogz', true)
      if (
        formik.values.notifications_client.filter(
          (notification) => notification?.new_message === true
        ).length === members?.length
      )
        formik.setFieldValue('all_notifications.new_message', true)
      if (
        formik.values.notifications_client.filter(
          (notification) => notification?.news === true
        ).length === members?.length
      )
        formik.setFieldValue('all_notifications.news', true)
    }
  }, [formik.values])
  const handleClientButtonClick = (i:number) => {
    setDisplayBtnSwitch({
      ...displayBtnSwitch,
      client: displayBtnSwitch.client.map((clientBtn, index) =>
        index === i ? !clientBtn : clientBtn
      ),
    });
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Notification</title>
      </Helmet>
      <section className="flex items-center justify-center h-full w-full relative">
        <div className="absolute top-2 left-2">
          <BtnBack btnName="" />
        </div>
        <div className={styles['container-notification']}>
          <div className={styles['container-notification-header']}>
            <h1> {t('notification:title')}</h1>
            <p> {t('notification:desc')}</p>
          </div>
          <br />
          <div className={styles['array-notification']}>
            {!teams.members.loading &&
            !teams.members.error &&
            members &&
            members.length > 0 ? (
              <FormikProvider value={formik}>
                <form
                  className={styles['step-content']}
                  onSubmit={formik.handleSubmit}
                >
                  <table className={styles['table-desktop']}>
                    <tbody>
                      <tr className={styles['header-table']}>
                        <td colSpan={2}>{t('notification:array-title')}</td>
                        <td>{t('notification:champ-1')}</td>
                        <td className={styles['center-col']}>
                          {t('notification:champ-2')}
                        </td>
                        <td>{t('notification:champ-3')}</td>
                      </tr>
                      <tr className={styles['champs-all-team']}>
                        <td colSpan={2}>
                          <p>{t('notification:champ-teams')}</p>
                        </td>
                        <td className={styles['first-col-center']}>
                          <SwitchBtn
                            name="all_notifications.new_vogz"
                            checked={
                              formik.values.all_notifications.new_vogz ?? true
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                'all_notifications.new_vogz',
                                e.target.checked
                              )
                              if (e.target.checked === true)
                                formik.setFieldValue(
                                  'notifications_client',
                                  formik.values.notifications_client?.map(
                                    (notification) => ({
                                      ...notification,
                                      new_vogz: e.target.checked,
                                      // user: notification._id
                                    })
                                  )
                                )
                              else
                                formik.setFieldValue(
                                  'notifications_client',
                                  formik.values.notifications_client?.map(
                                    (notification) => ({
                                      ...notification,
                                      new_vogz: e.target.checked,
                                      // user: notification._id
                                    })
                                  )
                                )
                            }}
                          />
                        </td>
                        <td className={styles['center-col']}>
                          <SwitchBtn
                            name="all_notifications.new_message"
                            checked={
                              formik.values.all_notifications.new_message ??
                              true
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                'all_notifications.new_message',
                                e.target.checked
                              )
                              if (e.target.checked === true)
                                formik.setFieldValue(
                                  'notifications_client',
                                  formik.values.notifications_client?.map(
                                    (notification) => ({
                                      ...notification,
                                      new_message: e.target.checked,
                                      // user: notification._id
                                    })
                                  )
                                )
                              else
                                formik.setFieldValue(
                                  'notifications_client',
                                  formik.values.notifications_client?.map(
                                    (notification) => ({
                                      ...notification,
                                      new_message: e.target.checked,
                                      // user: notification._id
                                    })
                                  )
                                )
                            }}
                          />
                        </td>
                        <td className={styles['last-col']}>
                          <SwitchBtn
                            name="all_notifications.news"
                            checked={
                              formik.values.all_notifications.news ?? true
                            }
                            onChange={(e) => {
                              formik.setFieldValue(
                                'all_notifications.news',
                                e.target.checked
                              )
                              if (e.target.checked === true)
                                formik.setFieldValue(
                                  'notifications_client',
                                  formik.values.notifications_client?.map(
                                    (notification) => ({
                                      ...notification,
                                      news: e.target.checked,
                                      // user: notification._id
                                    })
                                  )
                                )
                              else
                                formik.setFieldValue(
                                  'notifications_client',
                                  formik.values.notifications_client?.map(
                                    (notification) => ({
                                      ...notification,
                                      news: e.target.checked,
                                      // user: notification._id
                                    })
                                  )
                                )
                            }}
                          />
                        </td>
                      </tr>
                      {members?.length > 0 &&
                        members?.map((member, i) => (
                          <tr className={styles['champs-team']} key={i}>
                            <td className={styles['first-col']}>
                              <p>
                                {member.profile.first_name}{' '}
                                {member.profile.name}{' '}
                                <span
                                  role={'img'}
                                  aria-label="emojis validation"
                                >
                                  ✅
                                </span>
                              </p>
                            </td>
                            <td className={styles['second-col']}>
                              <p>{member.email}</p>
                            </td>
                            <td className={styles['first-col-center']}>
                              <SwitchBtn
                                name={`notification_client[${i}].new_vogz`}
                                checked={
                                  (formik.values.notifications_client[i]
                                    ?.new_vogz ??
                                    true) ||
                                  formik.values.all_notifications.new_vogz ===
                                    true
                                }
                                onChange={(e) => {
                                  if (e.target.checked === false)
                                    formik.setFieldValue(
                                      'all_notifications.new_vogz',
                                      false
                                    )
                                  formik.setFieldValue(
                                    `notifications_client[${i}].new_vogz`,
                                    e.target.checked
                                  )
                                  // formik.setFieldValue(`notifications_client[${i}].user`, member._id)
                                }}
                              />
                            </td>
                            <td className={styles['center-col']}>
                              <SwitchBtn
                                name={`notification_client[${i}].new_message`}
                                checked={
                                  (formik.values.notifications_client[i]
                                    ?.new_message ??
                                    true) ||
                                  formik.values.all_notifications
                                    .new_message === true
                                }
                                onChange={(e) => {
                                  if (e.target.checked === false)
                                    formik.setFieldValue(
                                      'all_notifications.new_message',
                                      false
                                    )
                                  formik.setFieldValue(
                                    `notifications_client[${i}].new_message`,
                                    e.target.checked
                                  )
                                }}
                              />
                            </td>
                            <td className={styles['last-col']}>
                              <SwitchBtn
                                name={`notification_client[${i}].news`}
                                checked={
                                  (formik.values.notifications_client[i]
                                    ?.news ??
                                    true) ||
                                  formik.values.all_notifications.news === true
                                }
                                onChange={(e) => {
                                  if (e.target.checked === false)
                                    formik.setFieldValue(
                                      'all_notifications.news',
                                      false
                                    )
                                  formik.setFieldValue(
                                    `notifications_client[${i}].news`,
                                    e.target.checked
                                  )
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <table className={styles['table-mobile']}>
                    <tbody className="flex flex-col">
                      <tr className={styles['champs-all-team']}>
                        <td colSpan={3}>
                          <p>{t('notification:champ-teams')}</p>
                          <span>
                            <i
                              className={`fa-regular cursor-pointer ${
                                displayBtnSwitch.all
                                  ? 'fa-arrow-up'
                                  : 'fa-arrow-down'
                              }`}
                              onClick={() => {
                                setDisplayBtnSwitch({
                                  ...displayBtnSwitch,
                                  all: !displayBtnSwitch.all,
                                })
                              }}
                            />
                          </span>
                        </td>
                      </tr>
                      {displayBtnSwitch.all && (
                        <motion.tr
                          initial={{
                            opacity: 0,
                            marginTop: -160,
                            marginBottom: 160,
                          }}
                          animate={{
                            opacity: 1,
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                          // @ts-ignore */
                          delay={4000}
                          exit={{
                            opacity: 0,
                            marginTop: 160,
                            marginBottom: -160,
                          }}
                          className={styles['tr-btn']}
                        >
                          <tr className={styles['tr-head-btn']}>
                            <td>{t('notification:champ-1')}</td>
                            <td className={styles['td-center']} >
                              {t('notification:champ-2')}
                            </td>
                            <td>{t('notification:champ-3')}</td>
                          </tr>
                          <tr className={styles['tr-body-btn']}>
                            <td>
                              <SwitchBtn
                                name="all_notifications.new_vogz"
                                checked={
                                  formik.values.all_notifications.new_vogz ??
                                  true
                                }
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'all_notifications.new_vogz',
                                    e.target.checked
                                  )
                                  if (e.target.checked === true)
                                    formik.setFieldValue(
                                      'notifications_client',
                                      formik.values.notifications_client?.map(
                                        (notification) => ({
                                          ...notification,
                                          new_vogz: e.target.checked,
                                          // user: notification._id
                                        })
                                      )
                                    )
                                  else
                                    formik.setFieldValue(
                                      'notifications_client',
                                      formik.values.notifications_client?.map(
                                        (notification) => ({
                                          ...notification,
                                          new_vogz: e.target.checked,
                                          // user: notification._id
                                        })
                                      )
                                    )
                                }}
                              />
                            </td>
                            <td className={styles['td-center']}>
                              <SwitchBtn
                                name="all_notifications.new_message"
                                checked={
                                  formik.values.all_notifications.new_message ??
                                  true
                                }
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'all_notifications.new_message',
                                    e.target.checked
                                  )
                                  if (e.target.checked === true)
                                    formik.setFieldValue(
                                      'notifications_client',
                                      formik.values.notifications_client?.map(
                                        (notification) => ({
                                          ...notification,
                                          new_message: e.target.checked,
                                          // user: notification._id
                                        })
                                      )
                                    )
                                  else
                                    formik.setFieldValue(
                                      'notifications_client',
                                      formik.values.notifications_client?.map(
                                        (notification) => ({
                                          ...notification,
                                          new_message: e.target.checked,
                                          // user: notification._id
                                        })
                                      )
                                    )
                                }}
                              />
                            </td>
                            <td>
                              <SwitchBtn
                                name="all_notifications.news"
                                checked={
                                  formik.values.all_notifications.news ?? true
                                }
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'all_notifications.news',
                                    e.target.checked
                                  )
                                  if (e.target.checked === true)
                                    formik.setFieldValue(
                                      'notifications_client',
                                      formik.values.notifications_client?.map(
                                        (notification) => ({
                                          ...notification,
                                          news: e.target.checked,
                                          // user: notification._id
                                        })
                                      )
                                    )
                                  else
                                    formik.setFieldValue(
                                      'notifications_client',
                                      formik.values.notifications_client?.map(
                                        (notification) => ({
                                          ...notification,
                                          news: e.target.checked,
                                          // user: notification._id
                                        })
                                      )
                                    )
                                }}
                              />
                            </td>
                          </tr>
                        </motion.tr>
                      )}
                      <hr />

                      {members?.length > 0 &&
                        members?.map((member, i) => (
                          <>
                            <tr className={styles['champs-team']} key={i}>
                              <td className={styles['first-col']} colSpan={3}>
                                <div className={styles['info-member']}>
                                  <p>
                                    {member.profile.first_name}{' '}
                                    {member.profile.name}{' '}
                                    <span
                                      role={'img'}
                                      aria-label="emojis validation"
                                    >
                                      ✅
                                    </span>
                                  </p>
                                  <p>{member.email}</p>
                                </div>
                                <span>
                                  <i
                                    className={`fa-regular cursor-pointer ${
                                      displayBtnSwitch.client[i]
                                        ? 'fa-arrow-up'
                                        : 'fa-arrow-down'
                                    }`}
                                    onClick={() => {
                                      handleClientButtonClick(i)
                                    }}
                                  />
                                </span>
                              </td>
                            </tr>
                            {displayBtnSwitch.client[i] && (
                             <motion.tr
                              initial={{
                                opacity: 0,
                                marginTop: -160,
                                marginBottom: 160,
                              }}
                              animate={{
                                opacity: 1,
                                marginTop: -8,
                                marginBottom: 8,
                              }}
                              // @ts-ignore */
                              delay={4000}
                              exit={{
                                opacity: 0,
                                marginTop: 160,
                                marginBottom: -160,
                              }}
                              className={styles['tr-btn']}
                            >
                             <tr className={styles['tr-head-btn']}>
                                <td>{t('notification:champ-1')}</td>
                                <td className={styles['td-center']} >
                                  {t('notification:champ-2')}
                                </td>
                                <td>{t('notification:champ-3')}</td>
                             </tr>
                             <tr className={styles['tr-body-btn']}>
                               <td>
                                <SwitchBtn
                                  name={`notification_client[${i}].new_vogz`}
                                  checked={
                                    (formik.values.notifications_client[i]
                                      ?.new_vogz ??
                                      true) ||
                                    formik.values.all_notifications
                                      .new_vogz === true
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked === false)
                                      formik.setFieldValue(
                                        'all_notifications.new_vogz',
                                        false
                                      )
                                    formik.setFieldValue(
                                      `notifications_client[${i}].new_vogz`,
                                      e.target.checked
                                    )
                                    // formik.setFieldValue(`notifications_client[${i}].user`, member._id)
                                  }}
                                />
                                </td>
                                <td className={styles['td-center']}>
                                  <SwitchBtn
                                    name={`notification_client[${i}].new_message`}
                                    checked={
                                      (formik.values.notifications_client[i]
                                        ?.new_message ??
                                        true) ||
                                      formik.values.all_notifications
                                        .new_message === true
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked === false)
                                        formik.setFieldValue(
                                          'all_notifications.new_message',
                                          false
                                        )
                                      formik.setFieldValue(
                                        `notifications_client[${i}].new_message`,
                                        e.target.checked
                                      )
                                    }}
                                  />
                                </td>
                                <td>
                                  <SwitchBtn
                                    name={`notification_client[${i}].news`}
                                    checked={
                                      (formik.values.notifications_client[i]
                                        ?.news ??
                                        true) ||
                                      formik.values.all_notifications.news ===
                                        true
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked === false)
                                        formik.setFieldValue(
                                          'all_notifications.news',
                                          false
                                        )
                                      formik.setFieldValue(
                                        `notifications_client[${i}].news`,
                                        e.target.checked
                                      )
                                    }}
                                  />
                                </td>
                             </tr>
                           </motion.tr>
                            )}
                          </>
                        ))}
                    </tbody>
                  </table>
                  <br />
                  <br />
                  <div className={styles['container-btn']}>
                    <BtnBasic
                      className={styles['btn-save']}
                      type="inverted-sky-violet"
                      btnName={t('notification:btn-confirm')}
                      onclick={() => {
                        formik.submitForm()
                      }}
                    />
                  </div>
                </form>
              </FormikProvider>
            ) : null}
          </div>
        </div>
      </section>
    </>
  )
}

export default observer(NotificationBrief)
