import React from 'react'
import Skeleton from 'react-loading-skeleton'

function SkeletonCards() {
  return (
    <div className="card-casting" style={{ minHeight: '37rem' }}>
      <Skeleton height="50%" style={{ lineHeight: 'unset' }} />
      <div className="card-casting-body">
        <aside className="top-part-card-casting" style={{ display: 'unset' }}>
          <Skeleton className="date-card-casting" />
          <Skeleton className="title-card-casting" />
          {/* Description of casting card */}
          <Skeleton
            width="100%"
            height={50}
            className="desc-video-card-casting"
          />
        </aside>
        <hr />
        <aside className="bottom-part-card-casting">
          <div
            className="date-part-bottom-card-casting"
            style={{ marginTop: '0.8em' }}
          >
            <div className="date-bottom-card-casting">
              <Skeleton width={80} height={50} />
            </div>
            <div className="date-bottom-card-casting">
              <Skeleton width={80} height={50} />
            </div>
          </div>
          <Skeleton height={45} />
        </aside>
      </div>
    </div>
  )
}

export default SkeletonCards
