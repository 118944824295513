import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Btn1 from '../../../../../utils/components/Button/Btn1'
import styles from '../register-process.module.scss'

interface BookingProps {
    setOpenBooking: Dispatch<SetStateAction<boolean>>
    setStep?: (e: number) => void
  }
const Booking:FC<BookingProps> = ({setOpenBooking,setStep}) => {
  const { t } = useTranslation()

    return (
      <div className={styles['booking']}>
        <div className={styles['booking-card']}>
          <div className={styles['booking-header']}>
            <h3>{t('register-process:booking.title')}</h3>
          </div>
          <div className={styles['booking-body']}>
            <p>{t('register-process:booking.desc')}</p>
            <a
              href={process.env.REACT_APP_LINK_RDV}
              target="_blank"
              rel="noreferrer"
              className={`btn-txt`}
            >
              {process.env.REACT_APP_LINK_RDV}
            </a>
            <div className={styles['img-booking']}>
              <img src="/assets/imgs/booking.png" alt="decorative" />
            </div>
            <Btn1
              btnName={t('register-process:confirm')}
              type="button"
              onclick={() => {
                setOpenBooking(false)
                if(setStep)setStep(7)!
               }}
            />
          </div>
        </div>
      </div>
    )
  }

  Booking.propTypes = {
    setOpenBooking: PropTypes.any.isRequired,
    setStep: PropTypes.any
  }

  Booking.defaultProps = {
    setStep: undefined,
  }

  export default Booking