export class DateHandler {
  static monthsAsString: string[] = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ]

  static getFormatedDate(date: Date): string {
    const dayFormat = DateHandler.getDayFromDate(date) < 10 ? '0' : ''
    const monthFormat = DateHandler.getMonthFromDate(date) < 10 ? '0' : ''
    return `${dayFormat + DateHandler.getDayFromDate(date)}/${monthFormat + DateHandler.getMonthFromDate(date)}/${DateHandler.getYearFromDate(date)}`
  }

  static compareDates(date1: Date, date2: Date): boolean {
    if (date1.getDate() !== date2.getDate()) {
      return false 
    }
    if (date1.getMonth() !== date2.getMonth()) {
      return false
    }
    if (date1.getFullYear() !== date2.getFullYear()) {
      return false
    }
    return true
  }

  static getFormatedUSDate(date: Date): string {
    const dayFormat = DateHandler.getDayFromDate(date) < 10 ? '0' : ''
    const monthFormat = DateHandler.getMonthFromDate(date) < 10 ? '0' : ''
    return `${DateHandler.getYearFromDate(date)}/${monthFormat + DateHandler.getMonthFromDate(date)}/${dayFormat + DateHandler.getDayFromDate(date)}`
  }

  static getFormatedUSDate2(date: Date): string {
    const dayFormat = DateHandler.getDayFromDate(date) < 10 ? '0' : ''
    const monthFormat = DateHandler.getMonthFromDate(date) < 10 ? '0' : ''
    return `${DateHandler.getYearFromDate(date)}-${monthFormat + DateHandler.getMonthFromDate(date)}-${dayFormat + DateHandler.getDayFromDate(date)}`
  }
  
  static getFormatedDateFromInfo(day: number, month: number, year: number): string {
    const dayFormat = day < 10 ? '0' : ''
    const monthFormat = month < 10 ? '0' : ''
    return `${dayFormat + day}/${monthFormat + month}/${year}`
  }

  static getDayFromDate(date: Date): number {
    return date.getDate()
  }

  static getMonthFromDate(date: Date): number {
    // +1 car le premier mois est égal à 0.
    return date.getMonth() + 1
  }

  static getYearFromDate(date: Date): number {
    return date.getFullYear()
  }
}
