import * as React from 'react'
import PropTypes from 'prop-types'

interface IconProps {
  color?: string
}

const VogzIcon: React.FC<IconProps> = ({ color }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_339_19090)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25ZM10.376 7.20214C10.0146 6.93847 9.56055 6.97265 9.19434 7.18261C8.82324 7.38769 8.59375 7.77832 8.59375 8.15918V16.7969C8.59375 17.2217 8.82324 17.6123 9.19434 17.8174C9.56055 18.0273 10.0146 18.0176 10.376 17.7978L17.4072 13.501C17.7539 13.2861 17.9688 12.9102 17.9688 12.5C17.9688 12.0898 17.7539 11.7139 17.4072 11.499L10.376 7.20214Z"
        fill={color}
      />
      {/* Custom edit starts here */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25ZM10.376 7.20214C10.0146 6.93847 9.56055 6.97265 9.19434 7.18261C8.82324 7.38769 8.59375 7.77832 8.59375 8.15918V16.7969C8.59375 17.2217 8.82324 17.6123 9.19434 17.8174C9.56055 18.0273 10.0146 18.0176 10.376 17.7978L17.4072 13.501C17.7539 13.2861 17.9688 12.9102 17.9688 12.5C17.9688 12.0898 17.7539 11.7139 17.4072 11.499L10.376 7.20214Z"
        fill={color}
        stroke={color}
        strokeWidth={0.1}
        shapeRendering="auto"
        opacity={0.1}
      />
      {/* Custom edit ends here */}
    </g>
    <defs>
      <filter
        id="filter0_i_339_19090"
        x="0"
        y="-1"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="-1" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0431373 0 0 0 0 0.113725 0 0 0 0 0.407843 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_339_19090"
        />
      </filter>
    </defs>
  </svg>
)

VogzIcon.propTypes = {
  color: PropTypes.string,
}

VogzIcon.defaultProps = {
  color: '#2A42A7',
}

export default VogzIcon
