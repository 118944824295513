import  userMobx from "../mobx/user";
import { AllMessages } from "../types-project/Client";


export class SocketEvents {
    updateUser = () => {
        // store.dispatch(getUserInfos(undefined, true))
    }
    
    updateMessage =(data:string) => {
        userMobx.updateMsgBrandSocket(data)
    }

    getMessage = (data:AllMessages) => {       
        userMobx.getMsgBrandSocket(data)
    }
}