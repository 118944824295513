import React, { MouseEventHandler } from "react";

interface BtnCloseProps {
  className?: {
    container: string
    icon: string
  }
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export default (props: BtnCloseProps) => {
  const { className, onClick } = props

  return (
    <button
      className={`w-6 h-6 flex justify-center items-center rounded-full border border-black ${className?.container ?? ''}`}
      type="button"
      onClick={onClick}
    >
      <i className={`fa-sharp fa-solid fa-xmark text-[.65em] ${className?.icon}`}/>
    </button>
  )
}