import React, { Fragment, useEffect, useState, useMemo } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { ChatFeed, Message } from 'react-chat-ui'
import ReactLoading from 'react-loading'
import { Spinner } from 'grommet'
import { observer } from 'mobx-react'
import ReactTooltip from 'react-tooltip'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Bubble from './components/Bubble'
import styles from './components/styles.module.scss'
import bStyles from '../MyCasting/components/brief-styles.module.scss'
import briefR from '../../../mobx/brief'
import modal from '../../../mobx/modal'
import { getAvatar, Tags, truncate, variantPage } from '../../../utils/helpers'
import {
  Applicant,
  BaCustom,
  DetailBa,
  MediaBA,
  MyCasting,
  VideoDemo,
} from '../../../types-project/Brief'
import ModalAlert from '../../../utils/components/Modals/ModalAlert'
import ModalCard from '../../../utils/components/Modals/ModalCart'
import CardVogz from '../MyCasting/components/CardVogz'
import utilsMobx from '../../../mobx/utils'
import Favourite from '../../../utils/components/Favourite/favourite'
import { BrandI, ClientI, TypeCompanyRole } from '../../../types-project/Client'
import BtnBasic from '../../../utils/components/Button/BtnBasic'
import InfoProfil from '../../Favorites-vogzters/Profil-vogzters/components/InfoProfil'
import Portfolio from '../../Favorites-vogzters/Profil-vogzters/components/InfoProfil/components/Portfolio'
import userMobx from '../../../mobx/user'
import ChatMessages from '../../Messaging/components/ChatMessages'
import CardTips from '../MyCasting/components/CardTips'

const Chat = observer(ChatFeed)

function BriefApplication() {
  // show messa
  const [showMessages, setShowMessages] = useState<boolean>(false)
  // text message to be send
  const [textMessage, setTextMessage] = useState<string>('')

  const [showPortfolio, setShowPortfolio] = useState(false)

  // step
  const [step, setStep] = useState<1 | 2>(2)
  const [showModalTips, setModalTips] = useState(false)

  const { data: brief, loading, error } = briefR.brief
  // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  const brand =
    userInfos._company.type === 'agency'
      ? userMobx.getBrandSession()
      : userMobx.brand.data

  // params got from react router dom
  const { id, idba } = useParams<{ idba: string; id: string }>()

  // Capture parameter from URL

  // handle navigation from our router
  const navigate = useNavigate()

  const sendMessage = () => {
    userMobx.sendMessage(
      textMessage,
      ba.briefApplication.applicant._id,
      brand?._id ?? ''
    )
    setTextMessage('')
  }

  // const { data: { data: brief } = {}, error, isValidating } = useSWR<dataBrief>(`/briefs/${id}`, api.get);

  const mediaCallback = (medias: MediaBA[]) => {
    const briefStep = new URLSearchParams(window.location.search)
    if (
      briefStep.get('briefStep')! === '1' &&
      medias.filter((v) => v.status === 'validated' && v.step === 2).length > 0
    ) {
      setStep(1)
      navigate('?briefStep=1')
    } else if (briefStep.get('briefStep')! === '2') {
      setStep(2)
    } else if (
      (briefStep.get('briefStep')! === '1' &&
        medias.filter((v) => v.status === 'validated' && v.step === 2)
          .length === 0) ||
      undefined
    ) {
      setStep(2)
      navigate('?briefStep=2')
    }
  }
  const ba = briefR.detailBA.data!

  useEffect(() => {
    briefR.getOneBa(idba!, (ba) => {
      mediaCallback(ba.media)
      if (window.location.hash.substring(1) === 'messages') {
        userMobx.getUserMessages(ba.applicant._id)
        userMobx.readMsgBrand(brand?._id ?? '', ba.applicant._id, true, () =>
          setShowMessages(true)
        )
      }
    })
  }, [])
  useMemo(() => {
    if (
      utilsMobx.getWidth > 1200 &&
      !showMessages &&
      ba !== null &&
      ba !== undefined &&
      ba.briefApplication !== null &&
      ba.briefApplication !== undefined
    ) {
      userMobx.getUserMessages(ba.briefApplication.applicant._id)
      userMobx.readMsgBrand(
        brand?._id ?? '',
        ba.briefApplication.applicant._id,
        true,
        () => setShowMessages(true)
      )
    }
  }, [utilsMobx.getWidth, showMessages, ba])
  // translation
  const { t } = useTranslation()

  function getAge(birthYear: number) {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const age = currentYear - birthYear
    return age
  }
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Profil vogzter</title>
      </Helmet>
      {briefR.detailBA.error ? (
        <h3>Error</h3>
      ) : briefR.detailBA.loading || !briefR.detailBA.data?.briefApplication ? (
        <Spinner />
      ) : Object.prototype.hasOwnProperty.call(
          briefR.detailBA.data?.briefApplication,
          'media'
        ) ? (
        <>
          {' '}
          <motion.div
            variants={variantPage} // Pass the variant object into Framer Motion
            initial="hidden" // Set the initial state to variants.hidden
            animate="enter" // Animated state to variants.enter
            exit="exit" // Exit state (used later) to variants.exit
            transition={{ type: 'linear' }} // Set the transition to linear
            onAnimationComplete={(evt) => {
              utilsMobx.handleAssideEnded(true)
            }}
            className={`${styles['page-main']} 
            ${styles['all-page']}
            ${utilsMobx.aside_animation_ended ? '!transform-none' : ''}`}
          >
            {showModalTips && (
              <div className={`!top-0 ${bStyles['parent-modal']}`}>
                <CardTips setModalTips={setModalTips} />
              </div>
            )}
            <div className={styles['header-page']}>
              <button
                type="button"
                className="btn-txt"
                onClick={() => {
                  if (ba.briefApplication.brief.private?.is_private) {
                    navigate(-1)
                  } else {
                    navigate(
                      `/dashboard/castings/${ba.briefApplication.brief._id}`
                    )
                  }
                }}
              >
                <i className="fa-regular fa-angle-left" />{' '}
                {t('mycastings:return')}
              </button>
            </div>

            {briefR.briefApplication.error ? (
              <h4>{briefR.briefApplication.error}</h4>
            ) : (
              !briefR.detailBA.loading &&
              !briefR.detailBA.error &&
              ba !== null && (
                <>
                  <ReactTooltip
                    id="info-not-available"
                    place={'top'}
                    effect={'solid'}
                    multiline
                    clickable
                    disable={
                      ba!?.briefApplication.media.filter(
                        (v) => v.status === 'validated' && v.step === 2
                      ).length > 0
                    }
                  >
                    <div
                      style={{
                        backgroundColor: 'rgba(0,0,0,.5)',
                        padding: '0px 10px',
                        borderRadius: 12,
                      }}
                    >
                      {t('mycastings:no-vogz-available')}
                    </div>
                  </ReactTooltip>
                  <aside>
                    <section className={styles['sticky']}>
                      <div className={styles['profile-info']}>
                        <InfoProfil
                          first_name={ba.briefApplication.applicant.first_name}
                          last_name={ba.briefApplication.applicant.last_name}
                          parent_code={
                            ba.briefApplication.applicant.parent_code ?? ''
                          }
                          delivery_address={
                            ba.briefApplication.applicant.delivery_address
                          }
                          profile={ba.briefApplication.applicant.profile}
                          user={ba.briefApplication.applicant}
                          setShowMessages={setShowMessages}
                          showMessages={showMessages}
                          briefApplicationsWon={ba.briefApplicationsWon}
                          showDisqualifyBtn={
                            userMobx.me.data?.company_role !==
                            TypeCompanyRole.GUEST
                          }
                          displayInfoVogzter
                          displaySocials
                          showBtnAction={{
                            viewProfile: true,
                            tips: {
                              show: true,
                              onClick: () => {
                                setModalTips(true)
                              },
                            },
                          }}
                          idba={idba!}
                          classNames={{
                            container: styles['info-profile-container'],
                            sectionName: styles['section-name'],
                            msgBtn: styles['btn-msg-profile'],
                            parentSectionName: styles['parentSectionName'],
                          }}
                          videoIntro={
                            ba.briefApplication.applicant.profile?.video_intro
                              ?.source
                          }
                          // showFavBtn={userMobx.me.data?.company_role !== TypeCompanyRole.GUEST}
                          lastActivity={
                            ba.briefApplication.applicant.last_activity
                          }
                          // portfolio={{
                          //   showBtn:
                          //     ((ba?.briefApplication.applicant.profile?.video_demo !== undefined &&
                          //       (ba.briefApplication.applicant.profile?.video_demo as VideoDemo[]).filter(vd => vd.status === "uploaded").length > 0)) ||
                          //     (ba?.briefApplication.applicant.profile.video_intro !== undefined &&
                          //       ([ba.briefApplication.applicant.profile?.video_intro].filter(vd => vd.status === "uploaded").length > 0)),
                          //   setShowPortfolio: setShowPortfolio,
                          // }}
                        />
                      </div>
                      {/* <div className={`${styles["action-profile"]}`}>
                        <br /> */}
                      {/* <button className={`${styles["blue-little-btn"]}`} onClick={() => { setShowMessages(true) }}>Ouvrir la messagerie</button> */}
                      {/* <br />
                        <button className={`${styles["profile-info"]} btn`}>Valider le Vogzter</button> */}
                      {/* </div> */}
                    </section>
                    {(ba!?.briefApplication.brief.with_product ||
                      ba.briefApplication.brief.on_spot?.is_on_spot || 
                      (!ba.briefApplication.brief.private?.is_private && ba.briefApplication.brief.content_type === 'influence')) && (
                      <div className={`${styles['options-set']}`}>
                        <button
                          type="button"
                          data-tip=""
                          data-for="info-not-available"
                          onClick={() => {
                            if (
                              ba.briefApplication.media.filter(
                                (v) => v.status === 'validated' && v.step === 2
                              ).length === 0
                            ) {
                              setStep(2)
                            } else {
                              setStep(1)
                              navigate('?briefStep=1')
                            }
                          }}
                          className={`${
                            step === 1
                              ? styles['active']
                              : ba.briefApplication.media.filter(
                                  (v) =>
                                    v.status === 'validated' && v.step === 2
                                ).length === 0
                              ? styles['disabled']
                              : ''
                          }`}
                        >
                          {t('mycastings:finals')}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setStep(2)
                            navigate('?briefStep=2')
                          }}
                          className={`${step === 2 ? styles['active'] : ''}`}
                        >
                          {t('mycastings:initial')}
                        </button>
                      </div>
                    )}
                    <section>
                      {ba?.briefApplication.brief.with_product === false &&
                        ba.briefApplication.brief.on_spot?.is_on_spot ===
                          false && <br />}
                      {/*
                                // @ts-ignore */}
                      <AnimatePresence exitBeforeEnter>
                        {step === 1 ? (
                          <motion.div
                            initial={{ opacity: 0, x: '-800px' }}
                            exit={{ opacity: 0, x: '-800px' }}
                            transition={{ duration: 0.5 }}
                            animate={{ x: 0, opacity: 1 }}
                            className={`${styles['grid2']} grid--flex-1--s`}
                          >
                            {ba?.briefApplication.media.filter(
                              (v) => v.step === 2
                            ).length === 0 ? (
                              <h2>{t('mycastings:in-production')}</h2>
                            ) : (
                              ba?.briefApplication.media
                                .filter(
                                  (v) =>
                                    v.step === 2 && v.status === 'validated'
                                )
                                .map((v, i) => (
                                  <CardVogz
                                    key={i}
                                    briefApplication={
                                      (ba.briefApplication as unknown) as MyCasting
                                    }
                                    media={v}
                                    showBasket
                                  />
                                ))
                            )}
                          </motion.div>
                        ) : (
                          <motion.div
                            key={3}
                            initial={{ opacity: 0, x: '800px' }}
                            exit={{ opacity: 0, x: '800px' }}
                            transition={{ duration: 0.5 }}
                            animate={{ x: 0, opacity: 1 }}
                            className={`${styles['grid2']} grid grid--flex grid--flex-3 grid--flex-2--ms grid--flex-1--s `}
                          >
                            {ba?.briefApplication.media.filter((v) =>
                              ba?.briefApplication.brief?.with_product ||
                              ba.briefApplication.brief.on_spot?.is_on_spot ||
                              (!ba.briefApplication.brief.private?.is_private && ba.briefApplication.brief.content_type === 'influence')
                                ? v.status === 'validated'
                                : ['validated', 'chosen'].indexOf(v.status) > -1
                            ).length === 0 ? (
                              <div className={styles['placeholder-nodata']}>
                                <img
                                  src="/assets/svgs/placeholder.svg"
                                  alt="holder"
                                />
                                <br />
                                <h3> {t('mycastings:vogzters-in-prod')}</h3>
                              </div>
                            ) : ba?.briefApplication.media.filter(
                                (v) => v.step === 1
                              ).length === 0 ? (
                              <div className={styles['placeholder-nodata']}>
                                <img
                                  src="/assets/svgs/placeholder.svg"
                                  alt="holder"
                                />
                                <br />
                                <h3> {t('mycastings:vogzters-in-prod')}</h3>
                              </div>
                            ) : (
                              ba?.briefApplication.media
                                .filter(
                                  (v) =>
                                    (ba?.briefApplication.brief?.with_product ||
                                    ba.briefApplication.brief.on_spot
                                    ?.is_on_spot ||
                                    (!ba.briefApplication.brief.private?.is_private && ba.briefApplication.brief.content_type === 'influence')
                                      ? v.status === 'validated'
                                      : ['validated', 'chosen'].indexOf(
                                          v.status
                                        ) > -1) &&
                                    (ba.briefApplication.brief.with_product ||
                                    ba.briefApplication.brief.on_spot 
                                    ?.is_on_spot ||
                                    (!ba.briefApplication.brief.private?.is_private && ba.briefApplication.brief.content_type === 'influence')
                                      ? v.step === 1
                                      : true)
                                )
                                .map((v, i) => (
                                  <CardVogz
                                    key={i}
                                    briefApplication={
                                      (ba.briefApplication as unknown) as MyCasting
                                    }
                                    media={v}
                                    showBookmarked
                                    showBasket={
                                      ba.briefApplication.brief.with_product ===
                                        false &&
                                      ba.briefApplication.brief.on_spot
                                        ?.is_on_spot === false &&
                                      ba.briefApplication.status !== 'won' &&
                                      ba.briefApplication.status !== 'lost' &&
                                      (ba.briefApplication.brief.content_type === 'influence' && v.step === 2)
                                    }
                                  />
                                ))
                            )}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </section>
                    {ba?.briefApplication.status === 'applied' &&
                    (ba.briefApplication.brief.on_spot?.is_on_spot ||
                      ba.briefApplication.brief.with_product || 
                      (!ba.briefApplication.brief.private?.is_private && ba.briefApplication.brief.content_type === 'influence') ) ? (
                      <button
                        type="button"
                        onClick={() => {
                          if (ba.briefApplication.brief.on_spot?.is_on_spot || ba.briefApplication.brief.with_product){
                            if (ba.briefApplication.brief.on_spot?.is_on_spot)
                              modal.toogleAlert('confirmation-rdv')
                            else if (ba.briefApplication.brief.with_product)
                              modal.toogleAlert('confirmation')
                            modal.setOnConfirm(() => {
                              briefR.validateOrCloseBriefApplication(
                                (idba as unknown) as string,
                                ['confirmation', 'confirmation-rdv'].indexOf(
                                  modal.alert.type
                                ) > -1
                                  ? 'validate'
                                  : 'decline'
                              )
                            })
                          }else if (!ba.briefApplication.brief.private?.is_private && ba.briefApplication.brief.content_type === 'influence')
                            briefR.validateOrCloseBriefApplication(
                              (idba as unknown) as string,
                              'validate'
                            )
                        }}
                        className={`${styles['start-collab-onspot']} btn`}
                      >
                        {t(
                          `mycastings:profil-vogzter:${
                            ba.briefApplication.brief.with_product ||
                            ba.briefApplication.brief.on_spot?.is_on_spot || 
                            (!ba.briefApplication.brief.private?.is_private && ba.briefApplication.brief.content_type === 'influence')
                              ? 'start-collab'
                              : 'validate-select'
                          }`
                        )}
                      </button>
                    ) : (
                      ((ba?.briefApplication.status === 'step2' &&
                        ba?.briefApplication.media.filter(
                          (ba, i) => ba.in_basket === true
                        ).length > 0 &&
                        (ba.briefApplication.brief.with_product ||
                          (!ba.briefApplication.brief.private?.is_private && ba.briefApplication.brief.content_type === 'influence') ||
                          ba.briefApplication.brief.on_spot?.is_on_spot)) ||
                        (ba?.briefApplication.brief.with_product === false &&
                          ba.briefApplication.brief.on_spot?.is_on_spot ===
                            false &&
                          ba.briefApplication.media.filter(
                            (v) => v.status === 'validated'
                          ).length > 0)) && (
                        <button
                          type="button"
                          disabled={
                            ba?.briefApplication.media.filter(
                              (ba, i) => ba.in_basket === true
                            ).length === 0
                          }
                          onClick={() => {
                            modal.toogleSelection(
                              ba.briefApplication,
                              undefined,
                              !modal.selection.open
                            )
                          }}
                          className={`${styles['start-collab-onspot']} ${
                            ba?.briefApplication.media.filter(
                              (ba, i) => ba.in_basket === true
                            ).length > 0
                              ? styles['active']
                              : ''
                          } ${styles['step2-btn']}`}
                        >
                          {t('mycastings:validate-select')}{' '}
                          <i className="fa-solid fa-bag-shopping" />
                        </button>
                      )
                    )}
                  </aside>
                  <ChatMessages
                    conversation={
                      (userMobx.messageUser.data ?? [])?.map((message, i) => ({
                        id: message._id,
                        message: message.text,
                        senderName: message.sender,
                        createdAt: message.createdAt,
                        offer: message.offer,
                        read: message.read && message.sender === 'client',
                        lastOne: userMobx.messageUser.data ? i === userMobx.messageUser.data?.length - 1 : true,
                        brief: message.brief,
                      })) ?? []
                    }
                    showBtnSendMessage
                    onChangeTextMessage={(v) => {
                      setTextMessage(v)
                    }}
                    textMessage={textMessage}
                    classnameContainer={`${showMessages ? 'sm:!right-0' : ''} ${
                      styles['container-dash-message-container']
                    }`}
                    styleContainer={{
                      right: showMessages ? '-10px' : '-1300px',
                      transition: '.5s right ease-in-out',
                    }}
                    sendMessage={sendMessage}
                    disabledTextMessage={briefR.message.loading}
                    inputStyles={{
                      classnameInput: styles['casting-message'],
                      classnameContainerInput:
                        styles['input-container-casting'],
                      classnameWrapperInput: styles['input-content-casting'],
                    }}
                    renderHeader={
                      <Fragment>
                        {utilsMobx.getWidth < 1200 && (
                          <button
                            type="button"
                            onClick={() => {
                              setShowMessages(false)
                            }}
                          >
                            <i className="fa-regular fa-xmark" />
                          </button>
                        )}
                        <section>
                          {!!ba?.briefApplication.brief.type && (
                            <div className={styles['tags']}>
                              <Tags
                                type={ba?.briefApplication.brief.type.name}
                              />
                            </div>
                          )}
                          <h2>{ba!?.briefApplication.brief.name}</h2>
                          <p className="hidden sm:!flex">
                            {truncate(
                              ba!?.briefApplication.brief?.description,
                              120
                            )}
                          </p>
                        </section>
                        <hr />
                      </Fragment>
                    }
                  />
                </>
              )
            )}
          </motion.div>
          <AnimatePresence>
            {showPortfolio && (
              <Portfolio
                className="absolute top-0 bottom-0"
                setShowPortfolio={setShowPortfolio}
                videosDemo={ba.briefApplication.applicant.profile.video_demo}
                videoIntro={ba.briefApplication.applicant.profile.video_intro}
              />
            )}
          </AnimatePresence>
        </>
      ) : null}
    </main>
  )
}

export default observer(BriefApplication)
