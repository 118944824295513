import { Socket, io } from "socket.io-client";
import Cookies from "js-cookie";
import { SocketEvents } from "./events";
import userMobx from "../mobx/user";

export class SocketService {
    socket: Socket;

    constructor() {
        const token = userMobx.getToken()
        this.socket = io(process.env.REACT_APP_PUBLIC_API_URL ?? "",
            {
                auth: {
                    token: `Bearer ${token}`
                },
                'reconnection': true,
                'reconnectionDelay': 50000,
                'reconnectionDelayMax': 50000,
                'reconnectionAttempts': 3,
                transports: ['websocket'],
            });
        const socketEvents = new SocketEvents()
        this.socket.on("connect", () => {
            console.log("connected")
        })
        this.socket.on('message:new', data => socketEvents.getMessage(data))
        this.socket.on('message:read', data => socketEvents.updateMessage(data))
    }

    on(event: string, callback: (...args: any[]) => void): SocketService {
        this.socket.on(event, callback);
        return this
    }

    emit(event: string, data: any): SocketService {
        this.socket.emit(event, data);
        return this
    }
}
const socketServiceInstance = new SocketService();

Object.freeze(socketServiceInstance);
export default socketServiceInstance;