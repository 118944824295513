/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Field, useFormik, FormikProvider } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import Loading from 'react-loading'

import userMobx from '../../../mobx/user'
import styles from './components/register.module.scss'
import Btn1 from '../../../utils/components/Button/Btn1'
import Title1 from '../../../utils/components/Headings/Title1'
import SubTitle1 from '../../../utils/components/Headings/SubTitle1'
import ErrorMsg from '../../../utils/components/Ui/errors/ErrorMsg'
import TextField from '../../../utils/components/Ui/inputs/TextField'
import { Account } from '../../../types-project/Client'
import { authCookies } from '../../../utils/helpers'
import { Offer } from '../../../types-project/Brief'
import modal from '../../../mobx/modal'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const initialValues: Account = {
  email: '',
  company_url: '',
  company_name: '',
  brand_name: '',
  contact_firstName: '',
  contact_phone: '',
  contact_name: '',
  conditions: false,
  otp: undefined,
  type: 'brand',
  siret_number: '',
  password: '',
  password_confirmation: '',
}

function Signup() {
  // translation
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  const [isBrand, setIsBrand] = useState(true)
  useEffect(() => {
    if (token) {
      userMobx.setToken(token)
      setStep(4)
    } else
      userMobx.getMe((user) => {
        authCookies(user)
        if (idOffer) userMobx.getOffer(idOffer)
        userMobx.getVogzter(offer.owner._id)
      })
  }, [])

  // validation
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('signup-login:form-errors:email'))
      .required(t('signup-login:form-required:email')),
    company_url: Yup.string()
      .url(t('signup-login:form-errors:company_url'))
      .required(t('signup-login:form-required:company_url')),
    contact_name: Yup.string().required(
      t('signup-login:form-required:contact_name')
    ),
    contact_firstName: Yup.string().required(
      t('signup-login:form-required:contact_firstName')
    ),
    contact_phone: Yup.string()
      .matches(phoneRegExp, t('signup-login:form-errors:contact_phone'))
      .required(t('signup-login:form-required:contact_phone')),
    company_name: Yup.string().required(
      t('signup-login:form-required:company_name')
    ),
    brand_name: isBrand
      ? Yup.string().required(t('signup-login:form-required:brand_name'))
      : Yup.string().optional(),
    siret_number: Yup.string()
      .matches(/^[0-9]{14}$/, t('signup-login:form-errors:siret_number'))
      .required(t('signup-login:form-required:siret_number')),
    conditions: Yup.boolean().oneOf(
      [true],
      t('signup-login:form-required:conditions')
    ),
  })
  const validationSchema2 = Yup.object().shape({
    password: Yup.string()
      .required(t('signup-login:form-required:password'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t('signup-login:form-errors:password')
      ),
    password_confirmation: Yup.string()
      .required(t('signup-login:form-required:password_confirm'))
      .oneOf(
        [Yup.ref('password'), null],
        t('signup-login:form-errors:password_confirm')
      ),
  })
  const isGuest = localStorage.getItem('isGuest')
  const idOffer = localStorage.getItem('idOffer')
  const offer = userMobx.offer.data ?? ({} as Offer)
  const [step, setStep] = useState<number>(isGuest ? 2 : 1)

  const navigate = useNavigate()

  const formik = useFormik<Account>({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: step === 4 ? validationSchema2 : validationSchema,
    onSubmit: async (values) => {
      const vogzterId = JSON.parse(sessionStorage.getItem('vogzter') ?? '{}')
      const parentCode = JSON.parse(sessionStorage.getItem('referral') ?? '{}')
      if (Object.keys(vogzterId).length > 0) values.referrer = vogzterId
      if (Object.keys(parentCode).length > 0) values.parent_code = parentCode
      handleBtn(values)
    },
  })

  const onSuccess = (stripeUrl: string) => {
    window.location.href = stripeUrl
  }

  const handleBtn = useCallback(
    async (client?: Account) => {
      if (step < 2) return setStep(step + 1)
      const values = await formik.validateForm()
      if (!client) return formik.submitForm()
      if (step === 2 && Object.keys(values).length === 0) {
        await userMobx.register(client).then(() => {
          sessionStorage.removeItem('referral')
          if (!userMobx.user.error) setStep(step + 1)
        })
      } else if (
        step === 4 &&
        Object.keys(values).length === 0 &&
        client.password
      ) {
        await userMobx.setPassword(client.password, (data) => {
          if (!userMobx.user.error && !isGuest) {
            if (data?.client?._company.premium) {
              return onSuccess(`/dashboard/new-deal/${idOffer}`)
            }
            navigate('/vogz-tunnel')
          } else if (isGuest && idOffer) {
            const priceToPay =
              offer.price - (data?.client?._company.credits ?? 0)
            const onFail = () => {
              modal.toogleAlert('stripe-error', true)
              modal.setOnConfirm(() => {
                userMobx.getTopUpLink(
                  priceToPay,
                  {
                    ok: `/dashboard/new-deal/${idOffer}?card=ok`,
                    ko: `/dashboard/new-deal/${idOffer}?card=ko`,
                  },
                  onSuccess,
                  onFail
                )
              })
            }
            userMobx.getTopUpLink(
              priceToPay,
              {
                ok: `/dashboard/new-deal/${idOffer}?card=ok`,
                ko: `/dashboard/new-deal/${idOffer}?card=ko`,
              },
              onSuccess,
              onFail
            )
          }
        })
      }
    },
    [step, isGuest, idOffer, userMobx, navigate, formik]
  )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Register</title>
      </Helmet>
      <main className={styles['container-all-body']}>
        {step === 1 && (
          <button
            type="button"
            className={`btn-txt ${styles['btn-back']}`}
            onClick={() => {
              navigate(-1)
              formik.resetForm({ values: initialValues })
            }}
          >
            <i className="fa-regular fa-angle-left" /> Retour
          </button>
        )}
        <div className={styles['all-body']}>
          {step > 1 && !isGuest && step === 2 && (
            <button
              type="button"
              className={`btn-txt ${styles['btn-back']} !top-0 !left-0`}
              onClick={() => {
                setStep(step - 1)
                formik.resetForm({ values: initialValues })
              }}
            >
              <i className="fa-regular fa-angle-left" /> Retour
            </button>
          )}
          <Title1
            text={
              step !== 3
                ? t('signup-login:signup')
                : t('signup-login:signup-end')
            }
          />
          {step === 3 && <br />}
          <SubTitle1
            className={`${step === 3 ? 'text-center text-lg' : ''}`}
            text={
              step !== 3
                ? t('signup-login:login-desc')
                : t('signup-login:signup-confirm')
            }
          />
          <br />
          {/* All form animation */}
          <AnimatePresence exitBeforeEnter>
            {/* first section choose casting */}
            {step === 1 ? (
              <section>
                <div
                  className={styles['card-choose']}
                  style={{ opacity: formik.values.type === 'brand' ? 1 : 0.5 }}
                  onClick={() => {
                    formik.setFieldValue('type', 'brand')
                    setIsBrand(true)
                  }}
                >
                  <input
                    readOnly
                    type="checkbox"
                    checked={formik.values.type === 'brand'}
                  />
                  <figure>
                    <img alt="illustration" src="./assets/svgs/brand.svg" />
                  </figure>
                  <Title1
                    size={24}
                    style={{ textTransform: 'uppercase', marginTop: 20 }}
                    text={t('signup-login:iam-brand')}
                  />
                </div>
                <div
                  className={styles['card-choose']}
                  style={{ opacity: formik.values.type === 'agency' ? 1 : 0.5 }}
                  onClick={() => {
                    formik.setFieldValue('type', 'agency')
                    setIsBrand(false)
                  }}
                >
                  <input
                    readOnly
                    type="checkbox"
                    checked={formik.values.type === 'agency'}
                  />
                  <figure>
                    <img alt="illustration" src="./assets/svgs/agency.svg" />
                  </figure>
                  <Title1
                    size={24}
                    style={{ textTransform: 'uppercase', marginTop: 20 }}
                    text={t('signup-login:iam-agency')}
                  />
                  <Title1
                    size={15}
                    style={{ textTransform: 'uppercase' }}
                    text={t('signup-login:iam-agency-p')}
                  />
                </div>
              </section>
            ) : /* second section register */
            step === 2 ? (
              <section>
                <FormikProvider value={formik}>
                  <form
                    className={styles['form']}
                    onSubmit={formik.handleSubmit}
                  >
                    <motion.div
                      key="1"
                      initial={{
                        opacity: 0,
                        marginRight: -160,
                        marginLeft: 160,
                      }}
                      animate={{ opacity: 1, marginRight: 0, marginLeft: 0 }}
                      exit={{ opacity: 0, marginRight: 160, marginLeft: -160 }}
                      className={styles['container-form']}
                    >
                      <TextField
                        required
                        autoFocus
                        error={formik.errors.contact_name}
                        onChange={formik.handleChange}
                        value={formik.values.contact_name}
                        label={t('signup-login:form.name')}
                        name="contact_name"
                        placeholder="Romain"
                      />

                      <TextField
                        required
                        error={formik.errors.contact_firstName}
                        onChange={formik.handleChange}
                        value={formik.values.contact_firstName}
                        label={t('signup-login:form.firstname')}
                        name="contact_firstName"
                        placeholder="Alfred"
                      />

                      <TextField
                        required
                        error={formik.errors.email}
                        type="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        label="Email"
                        name="email"
                        placeholder="contact@vogz.io"
                      />

                      <TextField
                        required
                        error={formik.errors.contact_phone}
                        type="phone"
                        onChange={formik.handleChange}
                        value={formik.values.contact_phone}
                        label={t('signup-login:form.contact_phone')}
                        name="contact_phone"
                        placeholder="0601020304"
                      />
                      {formik.values.type === 'agency' ? (
                        <TextField
                          required
                          error={formik.errors.company_name}
                          onChange={formik.handleChange}
                          value={formik.values.company_name}
                          label={t('signup-login:form.society_name')}
                          name="company_name"
                          placeholder="VOGZ"
                        />
                      ) : (
                        <>
                          <TextField
                            required
                            error={formik.errors.company_name}
                            onChange={formik.handleChange}
                            value={formik.values.company_name}
                            label={'Raison sociale'}
                            name="company_name"
                            placeholder="Société associée à la facturation"
                          />
                          <TextField
                            required
                            error={formik.errors.brand_name}
                            onChange={formik.handleChange}
                            value={formik.values.brand_name}
                            label={'Marque'}
                            name="brand_name"
                            placeholder="Marque à afficher sur l'application VOGZ"
                          />
                        </>
                      )}

                      <TextField
                        required
                        error={formik.errors.company_url}
                        type="url"
                        onChange={formik.handleChange}
                        value={formik.values.company_url}
                        label={t('signup-login:form.url_site')}
                        name="company_url"
                        placeholder="https://vogz.io"
                      />

                      <TextField
                        required
                        error={formik.errors.siret_number}
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.siret_number}
                        maxLength={14}
                        label={t('signup-login:form.siret_number')}
                        name="siret_number"
                        placeholder="552 178 639 00132"
                      />

                      <div className={styles['divInputcgv']}>
                        <div className={styles['FormFieldCGV']}>
                          <Field
                            type="checkbox"
                            id="conditions"
                            name="conditions"
                            className={`${styles['check-box']} ${
                              formik.errors.conditions
                                ? styles['errored-field']
                                : ''
                            }`}
                            required
                          />
                          <label
                            htmlFor="conditions"
                            className={`${styles['label-sub-heading']}`}
                          >
                            {t('signup-login:conditions')}{' '}
                            <Link
                              className=""
                              onClick={() => {
                                window.open('https://vogz.io/cgu', '_blank')
                              }}
                              target="_blank"
                              to=""
                            >
                              {t('signup-login:conditions_')}
                            </Link>
                          </label>
                        </div>
                        {formik.errors.conditions && (
                          <div className={styles['ErrorMsg']}>
                            <ErrorMsg text={formik.errors.conditions} />
                          </div>
                        )}
                      </div>
                    </motion.div>
                    <input type="submit" style={{ display: 'none' }} />
                  </form>
                </FormikProvider>
              </section>
            ) : (
              step === 4 && (
                <section>
                  <FormikProvider value={formik}>
                    <form
                      className={styles['form']}
                      onSubmit={formik.handleSubmit}
                    >
                      <motion.div
                        key="1"
                        initial={{
                          opacity: 0,
                          marginRight: -160,
                          marginLeft: 160,
                        }}
                        animate={{ opacity: 1, marginRight: 0, marginLeft: 0 }}
                        exit={{
                          opacity: 0,
                          marginRight: 160,
                          marginLeft: -160,
                        }}
                        className={styles['container-form']}
                      >
                        <TextField
                          required
                          error={formik.errors.password}
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          label={t('signup-login:form.password')}
                          name="password"
                          type="password"
                          placeholder="********"
                        />
                        <TextField
                          required
                          error={formik.errors.password_confirmation}
                          onChange={formik.handleChange}
                          value={formik.values.password_confirmation}
                          label={t('signup-login:form.password_confirm')}
                          name="password_confirmation"
                          type="password"
                          placeholder="********"
                        />
                      </motion.div>
                      <input type="submit" style={{ display: 'none' }} />
                    </form>
                  </FormikProvider>
                </section>
              )
            )}
          </AnimatePresence>
          <br />
          {userMobx.user.loading ? (
            <Loading />
          ) : (
            step !== 3 && (
              <Btn1
                onclick={() => handleBtn()}
                type="submit"
                btnName={
                  step < 2
                    ? t('signup-login:confirm')
                    : t('signup-login:signup_')
                }
              />
            )
          )}
          <div className={styles['text-bottom']}>
            <SubTitle1 text={t('signup-login:already-account')} />{' '}
            <Link className="basic-link" to="/login">
              {t('signup-login:login')}
            </Link>
          </div>
        </div>
      </main>
    </>
  )
}

export default observer(Signup)
