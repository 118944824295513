import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import * as yup from 'yup'
import { observer } from 'mobx-react'
import { useFormik, FormikProvider, FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import ReactTooltip from 'react-tooltip'
import ScrollToTop from '../../../../routing/ScrollToTop'

import { pageVariantsAnimation } from '../../../../utils/constants'
import {
  Client,
  ClientI,
  CustomClient,
  TypeClient,
  TypeCompanyRole,
} from '../../../../types-project/Client'
import teams from '../../../../mobx/teams'
import s from './components/styles.module.scss'
import TextField from '../../../../utils/components/Ui/inputs/TextField'
import UserMobx from '../../../../mobx/user'
import Title1 from '../../../../utils/components/Headings/Title1'
import Btn1 from '../../../../utils/components/Button/Btn1'
import SubTitle1 from '../../../../utils/components/Headings/SubTitle1'
import SwitchBtn from '../../../../utils/components/Ui/inputs/SwitchBtn'
import BtnBack from '../../../../utils/components/Button/BtnBack'

const Team = () => {
  // translation
  const { t } = useTranslation()
  const members = teams.members.data ?? ([] as Client[])
  const user = UserMobx.me.data
  const userInfos = UserMobx.getUserInfos() as ClientI

  const [displayBtnSwitch, setDisplayBtnSwitch] = useState({
    client: members?.map((member) => false) || [false],
  })
  const validationSchema = yup.object({
    name: yup.string().trim().required(t('team:enter-name')),
    first_name: yup.string().trim().required(t('team:enter-name')),
    email: yup
      .string()
      .email(t('team:valid-email'))
      .required(t('team:enter-email')),
  })

  const initialValues: CustomClient = {
    name: '',
    first_name: '',
    email: '',
  }

  const formik = useFormik<CustomClient>({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (e) => {
      await teams.addMember({
        name: e.name,
        first_name: e.first_name,
        email: e.email,
      })
      formik.resetForm({
        values: initialValues,
      })
    },
  })
  type FormikValues = {
    client: Array<{
      new_message: boolean
      id: string
    }>
  }
  const initialValuesNotif = {
    client: members?.map((member) => ({
      new_message: member.notifications.new_message ?? false,
      id: member._id,
    })),
  }
  const formikNotif = useFormik<FormikValues>({
    initialValues: initialValuesNotif,
    validateOnChange: true,
    validateOnBlur: false,

    validationSchema: false,
    onSubmit: async (e) => {
      if (userInfos.company_role === 'admin')
        teams.notifAllMember(
          formikNotif.values.client.map((notif) => {
            return {
              client: notif.id,
              notifications: { new_message: notif.new_message },
            }
          })
        )
      else
        UserMobx.updateClient({
          notifications: {
            new_message: formikNotif.values?.client?.find(
              (client) => client.id === userInfos._id
            )?.new_message,
          },
        })
    },
  })

  const deleteElement = (id: string) => {
    teams.deleteMember(id)
  }

  useEffect(() => {
    teams.allMember((data) => {
      formikNotif.setValues({
        client: data.map((member) => ({
          new_message: member.notifications.new_message ?? false,
          id: member._id,
        })),
      })
      setDisplayBtnSwitch({
        client: data?.map((member) => false),
      })
    })
  }, [teams])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Profil - Team</title>
      </Helmet>
      <main className="flex flex-col items-center w-full h-auto sm:min-h-full p-4 justify-center">
        <div className=" w-full absolute top-16 left-2 sm:top-4 z-20">
          <BtnBack btnName={t('mycastings:return')} />
        </div>
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          transition={{ type: 'spring', bounce: 0 }}
          variants={pageVariantsAnimation}
        >
          <ScrollToTop />
          <div className={s['all-body']}>
            <div className={s['title']}>
              <Title1
                style={{ textAlign: 'start', textTransform: 'uppercase' }}
                text={`${t('team:team')} ${user?._company?.name}`}
                size={35}
              />
            </div>
            <FormikProvider value={formikNotif}>
              <form
                className={s['step-content']}
                onSubmit={formikNotif.handleSubmit}
              >
                <table className={s['body-list']}>
                  <tbody>
                    <tr className={s['header-table']}>
                      <td>
                        {' '}
                        <SubTitle1 text={t('team:name')} />
                      </td>
                      <td>
                        <SubTitle1 text={t('team:mail')} />
                      </td>
                      <td className={s['center-col']}>
                        <SubTitle1 text={t('team:notif-msg')} />
                      </td>
                      <td>
                        {' '}
                        <SubTitle1 text={t('team:deletion')} />
                      </td>
                    </tr>
                    {!teams.members.error && members?.length > 0 ? (
                      members?.map((member, i) => (
                        <tr
                          className={s['champs-team']}
                          key={i}
                          id={member?._id}
                        >
                          <td className={s['first-col']}>
                            <p>
                              {member.profile?.first_name} {member.profile.name}{' '}
                              <span role={'img'} aria-label="emojis validation">
                                {' '}
                                ✅
                              </span>
                            </p>
                          </td>
                          <td className={s['second-col']}>
                            <p>{member.email}</p>
                          </td>
                          {formikNotif.values.client.length > 0 && (
                            <td className={s['first-col-center']}>
                              <SwitchBtn
                                name={`client[${i}].new_message`}
                                checked={
                                  formikNotif.values.client[i]?.new_message
                                }
                                onChange={(e) => {
                                  formikNotif.setFieldValue(
                                    `client[${i}].new_message`,
                                    e.target.checked
                                  )
                                  formikNotif.submitForm()
                                }}
                              />
                            </td>
                          )}
                          <td
                            className={s['last-col']}
                            data-tip
                            data-for="delete-last-team-member"
                          >
                            <Btn1
                              className={`!min-w-[120px] ${s['btn-delete']} ${
                                (teams.members.data ?? [])?.length === 1 ||
                                user?.company_role === TypeCompanyRole.MEMBER
                                  ? s['disabled']
                                  : ''
                              }`}
                              onclick={() => {
                                if (
                                  (teams.members.data ?? [])?.length > 1 &&
                                  user?.company_role === TypeCompanyRole.ADMIN
                                )
                                  deleteElement(member?._id)
                              }}
                              btnName={t('team:delete')}
                            />
                          </td>
                          {((teams.members.data ?? [])?.length === 1 ||
                            user?.company_role === 'member') && (
                            <ReactTooltip
                              id="delete-last-team-member"
                              effect={'float'}
                              backgroundColor={'#E8ECFC !important'}
                              place={'bottom'}
                              textColor={'#615AF6'}
                              arrowColor={'transparent'}
                              className={'tooltip-team'}
                            >
                              {(teams.members.data ?? [])?.length === 1 ? (
                                <span>{t('team:last-member')}</span>
                              ) : user?.company_role === 'member' ? (
                                <span> {t('team:no-access')} </span>
                              ) : null}
                            </ReactTooltip>
                          )}
                        </tr>
                      ))
                    ) : (
                      <div className={s['no-member']}>
                        <SubTitle1 text={t('team:no-member')} />
                      </div>
                    )}
                  </tbody>
                </table>

                <table className={s['table-mobile']}>
                  <tbody className="flex flex-col">
                    {members?.length > 0 &&
                      members?.map((member, i) => (
                        <>
                          <tr className={s['champs-team']} key={i}>
                            <td className={s['first-col']} colSpan={2}>
                              <div className={s['info-member']}>
                                <p>
                                  {member.profile.first_name}{' '}
                                  {member.profile.name}{' '}
                                  <span
                                    role={'img'}
                                    aria-label="emojis validation"
                                  >
                                    ✅
                                  </span>
                                </p>
                                <p>{member.email}</p>
                              </div>
                              <span>
                                <i
                                  className={`fa-regular cursor-pointer ${
                                    displayBtnSwitch.client[i]
                                      ? 'fa-arrow-up'
                                      : 'fa-arrow-down'
                                  }`}
                                  onClick={() => {
                                    setDisplayBtnSwitch({
                                      client: displayBtnSwitch.client.map(
                                        (clientBtn, index) =>
                                          index === i ? !clientBtn : clientBtn
                                      ),
                                    })
                                  }}
                                />
                              </span>
                            </td>
                          </tr>
                          {displayBtnSwitch.client[i] && (
                            <motion.tr
                              initial={{
                                opacity: 0,
                                marginTop: -160,
                                marginBottom: 160,
                              }}
                              animate={{
                                opacity: 1,
                                marginTop: -8,
                                marginBottom: 8,
                              }}
                              // @ts-ignore */
                              delay={4000}
                              exit={{
                                opacity: 0,
                                marginTop: 160,
                                marginBottom: -160,
                              }}
                              className={s['tr-btn']}
                            >
                              <tr className={s['tr-head-btn']}>
                                <td>{t('team:notif-msg')}</td>
                                <td>{t('team:deletion')}</td>
                              </tr>
                              <tr className={s['tr-body-btn']}>
                                <td>
                                  <SwitchBtn
                                    name={`client[${i}].new_message`}
                                    checked={
                                      formikNotif.values.client[i]
                                        .new_message === true
                                    }
                                    onChange={(e) => {
                                      formikNotif.setFieldValue(
                                        `client[${i}].new_message`,
                                        e.target.checked
                                      )
                                      formikNotif.submitForm()
                                    }}
                                  />
                                </td>
                                <td>
                                  <Btn1
                                    className={`!min-w-[120px] ${
                                      s['btn-delete']
                                    } ${
                                      ((teams.members.data ?? [])?.length ===
                                        1 ||
                                        user?.company_role === 'member') &&
                                      s['disabled']
                                    }`}
                                    onclick={() =>
                                      (teams.members.data ?? [])?.length > 1 &&
                                      user?.company_role === 'admin' &&
                                      deleteElement(member?._id)
                                    }
                                    btnName={t('team:delete')}
                                  />
                                </td>
                              </tr>
                            </motion.tr>
                          )}
                        </>
                      ))}
                  </tbody>
                </table>
              </form>
            </FormikProvider>
            {user?.company_role === 'admin' && (
              <div className={s['body-add-member']}>
                <h2>{t('team:add-member')} </h2>
                <FormikProvider value={formik}>
                  <form name="member" onSubmit={formik.handleSubmit}>
                    <div className={s['input-add-member']}>
                      <motion.div
                        initial={{
                          opacity: 0,
                          marginRight: -160,
                          marginLeft: 160,
                        }}
                        animate={{ opacity: 1, marginRight: 0, marginLeft: 0 }}
                        exit={{
                          opacity: 0,
                          marginRight: 160,
                          marginLeft: -160,
                        }}
                        className={s['div-input']}
                      >
                        <TextField
                          required
                          error={formik.errors.first_name}
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.first_name}
                          label={t('team:first_name')}
                          name="first_name"
                          placeholder={t('team:first-name-member')}
                        />
                        <div className="w-4" />
                        <TextField
                          required
                          error={formik.errors.name}
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          label={t('team:name')}
                          name="name"
                          placeholder={t('team:name-member')}
                        />
                      </motion.div>
                      <motion.div
                        initial={{
                          opacity: 0,
                          marginRight: -160,
                          marginLeft: 160,
                        }}
                        animate={{ opacity: 1, marginRight: 0, marginLeft: 0 }}
                        exit={{
                          opacity: 0,
                          marginRight: 160,
                          marginLeft: -160,
                        }}
                        className={s['div-input']}
                      >
                        <TextField
                          required
                          error={formik.errors.email}
                          type="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          label={t('team:mail')}
                          name="email"
                          placeholder={t('team:mail-member')}
                        />
                      </motion.div>
                      <button type="submit">
                        {t('team:new-member')} &nbsp;
                        <i className={`fa-light fa-user-plus`} />
                      </button>
                    </div>
                  </form>
                </FormikProvider>
              </div>
            )}
          </div>
        </motion.div>
      </main>
    </>
  )
}
export default observer(Team)
