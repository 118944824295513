/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useEffect, useState } from 'react'
import { Box, Text, Spinner } from 'grommet'
import ReactTooltip from 'react-tooltip'
import { observer } from 'mobx-react'
import * as Yup from 'yup'
import useSWR from 'swr'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { AnimatePresence, motion } from 'framer-motion'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { TFunction, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownItemProps,
  StrictDropdownProps,
} from 'semantic-ui-react'
import Loading from 'react-loading'
import { FormikProvider, useFormik } from 'formik'
import { TextField } from '@mui/material'
import SearchBar from '../../../utils/components/Ui/inputs/SearchBar'
import CardBriefApplication from './components/CardBriefApplication'
import CardSendProduct from './components/CardSendProduct'
import CardVogz from './components/CardVogz'
import BtnDrop, {
  CustomDopDownProps,
  // eslint-disable-next-line import/no-named-as-default-member
} from '../../../utils/components/Button/BtnDrop'

import briefR, { Brief } from '../../../mobx/brief'
import ModalCard from '../../../utils/components/Modals/ModalCart'
import modalR from '../../../mobx/modal'
import styles from './components/brief-styles.module.scss'
import CardVogzClient from './components/CardVogzClient'
import api from '../../../utils/apiUtils'
import {
  variantPage,
  getAvatar,
  countDown,
  Tags,
  truncate,
  sortByCreatedDate,
  filterPublishedDraft,
  searchFilter,
  displayAvatar,
  TypeSort,
  formatVogzterName,
} from '../../../utils/helpers'
import { Applicant, Media, MediaBA, MyCasting as MyCasting_ } from '../../../types-project/Brief'
import utilsMobx from '../../../mobx/utils'
import ProgressEnroll from './components/ProgressEnroll'
import briefs from '../../brands/briefs'
import Title1 from '../../../utils/components/Headings/Title1'
import BtnBasic from '../../../utils/components/Button/BtnBasic'
import { Vogzter, TypeCompanyRole } from '../../../types-project/Client'
import userMobx from '../../../mobx/user'
import PrivateDeal from './components/PrivateDeal'
import Portfolio from '../../Favorites-vogzters/Profil-vogzters/components/InfoProfil/components/Portfolio'
import VogzterPicture from '../../Favorites-vogzters/Profil-vogzters/components/VogzterPicture'
import CardRDVStore from './components/CardRDVStore'
import Favourite from '../../../utils/components/Favourite/favourite'
import CardSetPublication from './components/CardSetPublication'
import CardDeclinedPublication from './components/CardDeclinedPublication'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function MyCasting(props: any) {
  // params from router to get id and other parameters query
  const params = useParams<{ id: string }>()
  // translation
  const { t } = useTranslation()
  const [step, setStep] = useState<number>(1)

  const { data: brief, loading, error } = briefR.brief

  // eslint-disable-next-line no-restricted-globals
  const param = new URLSearchParams(location.search)
  const status = param!?.get('status')


  // handle navigation from our router
  const navigate = useNavigate()

  useEffect(() => {    
    briefR.getBrief(params.id!, (data) => {      
      briefR.getBriefApplications(params.id!, true, async (bas) => {
        if (data.private?.is_private && bas.length > 0) {
          briefR.getOneBa(bas[0]._id)
        } else {
          switch (status) {
            case 'participations':
              if ((data?.with_product || data.on_spot?.is_on_spot || (!data.private?.is_private && data.content_type === 'influence')) && !data.exclusive?.is_exclusive)
                setStep(1)
              else setStep(3)
              if (step !== 4 && data?.status === 'closed') setStep(4)
              break
            case 'deliveries':
              if (
                (data?.with_product || data.on_spot?.is_on_spot) &&
                bas?.filter((v) => v?.status === 'step2').length > 0
              ) {
                setStep(2)
              }
              break
            case 'finals':
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              if (
                (data?.with_product || data.on_spot?.is_on_spot || data.content_type === 'influence') &&
                !data?.exclusive?.is_exclusive &&
                bas!?.filter(
                  (v) => v?.status === 'step2' && (v?.product_sent.sent || (v?.rendez_vous?.address ?? false) ||(v.brief.content_type === 'influence'))
                )!.length > 0
              ) {
                setStep(3)
              }
              break
            case 'buys':              
              if (step !== 4) {
                if (
                  bas.filter(
                    (ba) =>
                      ba.media.filter((md) => md.status === 'chosen')?.length >
                      0
                  )?.length > 0
                ) {
                  setStep(4)
                }
              }
              break
            default:
              if ((data?.with_product || data.on_spot?.is_on_spot || (!data.private?.is_private && data.content_type === 'influence')) && !data.exclusive?.is_exclusive)
                setStep(1)
              else setStep(3)
              if (step !== 4 && data?.status === 'closed') setStep(4)
              break
          }
        }
      })
    })
  }, [])


  const [showPortfolio, setShowPortfolio] = useState(false)

  return (
    <motion.main
      variants={variantPage} // Pass the variant object into Framer Motion
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
      transition={{ type: 'linear' }} // Set the transition to linear
      className={`${styles['brief-page']} ${brief?.private?.is_private && 'mb-7'
        }`}
      onAnimationComplete={(evt) => {
        utilsMobx.handleAssideEnded(true)
      }}
    >
        <header
          className={brief?.private?.is_private ? styles['header-private'] : ''}
        >
          <button className={`btn-txt`} type='button' onClick={() => { navigate(-1) }}>
            <i className="fa-regular fa-angle-left" /> {t('mycastings:return')}
          </button>
          { userMobx.me.data?.company_role !== TypeCompanyRole.GUEST && brief?.prepaid  && (brief?.prepaid  > 0) ?
            <p>{(t('mycastings:prepaid', { prepaid:brief?.prepaid.toString() }))}</p>
            : null
          }
        </header>

      {(!briefR.briefApplication.loading ||
        !briefR.briefApplication.error ||
        briefR.briefApplication.data) &&
        (!briefR.brief.error || !briefR.brief.loading || briefR.brief.data) &&
        brief?.private?.is_private ? (
        <PrivateDeal idBa={briefR.briefApplication.data?.at(0)?._id ?? ''} setShowPortfolio={setShowPortfolio} showPortfolio={false} />
      ) : (
        <ContentNotPrivate_ error={error} t={t} step={step} setStep={setStep} brief={briefR.brief.data} />
      )}
      <AnimatePresence>
        {showPortfolio && (
          <Portfolio
            setShowPortfolio={setShowPortfolio}
            videosDemo={briefR.briefApplication.data?.at(0)?.applicant.profile.video_demo}
            videoIntro={briefR.briefApplication.data?.at(0)?.applicant.profile.video_intro}
          />
        )}
      </AnimatePresence>
    </motion.main>
  )
}

function ContentNotPrivate(props: ContentNotPrivateType) {
  // Récupération des propriétés passées en paramètre
  const { brief, t, error, step, setStep } = props

  const [isExpanded, setExpand] = useState<boolean>(false)
  const [showModalCalendar, setModalCalendar] = useState<MediaBA|null>(null)  
  const [showModalDeclinePublication, setShowModalDeclinePublication] = useState<MediaBA|null>(null)  

  useEffect (() => {
    if (showModalCalendar) {
      document.getElementById('calendar-modal')?.scrollIntoView({ behavior: 'smooth' })
      document.getElementById('page-aside')?.style.setProperty('overflow', 'hidden')      
    } else if (showModalDeclinePublication) {
      document.getElementById('decline-publication-modal')?.scrollIntoView({ behavior: 'smooth' })
      document.getElementById('page-aside')?.style.setProperty('overflow', 'hidden')      
    
    } else
      document.getElementById('page-aside')?.style.setProperty('overflow', 'auto')

  }, [showModalCalendar,showModalDeclinePublication])

  // Utilisation de useState pour initialiser l'état du filtre de recherche et de tri
  const [search, setSearch] = useState<undefined | string>(undefined)
  const [filterCasting, setfilterCasting] = useState<TypeSort>(TypeSort.basic)

  // Obtention des médias achetés dans le brief
  const medias_buys = briefR.briefApplication
    .data!.map((ba) => ba.media)
    .flat()
    .filter((m) => m.status === 'chosen')

  // Filtrage et tri des candidatures du brief
  const briefApplications = searchFilter(
    sortByCreatedDate(briefR.briefApplication.data ?? [], filterCasting)
  )

  // Condition pour déterminer si les autres étapes doivent être affichées après la dernière étape
  const condition_others_last = medias_buys?.length > 0

  /*
    *  Condition permettant d'afficher les vogzter dans le step 3 dans le cas d'un brief avec produit ou au step 1 d'un brief sans produit
  */

  const last_steps_finals = (brief?.with_product || brief?.on_spot?.is_on_spot ||  (!brief?.private?.is_private && brief?.content_type === 'influence') )
    ?
    (!brief?.private?.is_private && brief?.content_type === 'influence')?
    briefApplications?.filter(
      (k) =>
        k.status === 'step2' 
       
    )
    : briefApplications?.filter(
      (k) =>
        k.status === 'step2' &&
        k.media.filter((md) => md.status === 'validated' && md.step === 2)
          ?.length > 0
    )
    : briefApplications?.filter(
      (k) =>
        k.status === 'applied' &&
        k.media.filter(
          (md) =>
            md.step === ((brief?.with_product || brief?.on_spot?.is_on_spot || (!brief?.private?.is_private && brief?.content_type === 'influence')) ? 2 : 1) &&
            md.status === 'validated'
        )?.length > 0
    )

  // Calcul du nombre de médias achetés
  const length_buys = brief?.exclusive?.is_exclusive
    ? briefApplications?.filter((ba) => ba.status === 'won')?.length
    : medias_buys?.length

  // Condition pour filtrer les briefApplications au step 1
  const condition_step_1 = briefApplications?.filter(
    (k) =>
      k.media.filter((md) => md.status === 'validated')?.length > 0 &&
      k.status === 'applied'
  )

  // Condition pour filtrer les briefApplications au step 2
  const condition_step_2 = briefApplications?.filter(
    (v, i) => v?.status === 'step2'
  )

  // Condition pour envoyer le produit || aller en boutique
  const send_product_cond = briefApplications?.filter(
    (k) => k.status === 'step2'
  )

  // Options pour le filtre de tri
  const options = [
    { key: 1, text: t('mycastings:Plus anciennes'), value: 'createdAt' },
    { key: 2, text: t('mycastings:Plus récentes'), value: 'note' },
  ]

  // Condition pour déterminer si les autres étapes doivent être affichées après l'étape actuelle
  const condition_others_step = (brief?.with_product || brief?.on_spot?.is_on_spot || (!brief?.private?.is_private && brief?.content_type === 'influence'))
    ? briefApplications?.filter(
      (v, i) => v?.status === 'step2' && (v?.product_sent.sent || v.rendez_vous || v.brief.content_type === 'influence' )
    ).length > 0
    : true


  // Fonction pour gérer le changement d'étape dans le parcours client
  // click to handle step of the client parcours
  // @index
  function handleStep(index: number) {
    if (index === 2 && condition_step_2!.length > 0) {
      setStep(index)
      navigate('?status=deliveries')
    } else if (index === 3 && condition_others_step) {
      setStep(index)
      if ((brief?.with_product || brief?.on_spot?.is_on_spot || (!brief?.private?.is_private && brief?.content_type === 'influence')) && !brief.exclusive?.is_exclusive)
        navigate('?status=finals')
      else navigate('?status=participations')
    } else if (index === 4 && condition_others_last) {
      setStep(index)
      navigate('?status=buys')
    } else {
      setStep(index)
      navigate('?status=participations')
    }
  }

  // changer d'url
  const navigate = useNavigate()

  return (
    <div className={brief?.status === "closed" ? styles['brief-page_all'] : ''}>
      {/* Condition d'affichage du Spinner s'il n'y a pas de brief ou s'il y a une erreur */}
      {(!brief || error) && (
        <Box style={{ paddingTop: '150px' }}>
          <Spinner
            justify="center"
            size="medium"
            pad="large"
            alignSelf="center"
          />
        </Box>
      )}
      {/* Section du header de la page */}
      <section className={styles['header-brief-page']}>
        {/* Affichage des tags */}
          {
            !!brief?.type && (
            <div className={styles['tags']}>
              <Tags type={brief?.type.name} />
              {/* Si le brief est exclusif on rajoute le tag vip */}
              {brief?.exclusive!.is_exclusive && (<Tags type={'vip'} />)}
            </div>
          )}
        {/* Affichage du nom et du cadenas s'il est privé sinon un skéleton */}
        {brief?.name ? (
          <div className={styles['container-name']}>
            <h2>{brief?.name}</h2>
            {brief?.private?.is_private && (
              <img
                className={styles['lock-icon']}
                src="/assets/svgs/lock.svg"
                alt="lock.svg"
              />
            )}
          </div>
        ) : (
          <>
            <Skeleton
              className={styles['skeleton-brief-page']}
              highlightColor="#ffffff"
            />
          </>
        )}
        {/* Affichage de la description et du cadenas s'il est privé sinon un skéleton */}
        {brief?.description ? (
          <p>{truncate(brief?.description, 130)}</p>
        ) : (
          <>
            <Skeleton
              className={styles['skeleton-brief-page']}
              highlightColor="#ffffff"
            />
            <Skeleton
              className={styles['skeleton-brief-page']}
              width={'20vw'}
              highlightColor="#ffffff"
            />
          </>
        )}
        {/* Affichage de la ProgressEnroll si le brief n'est pas privé et n'est pas exclusif */}
        {!error && brief && !brief?.private?.is_private && !brief?.exclusive?.is_exclusive && (
          <ProgressEnroll
            value={
              briefApplications.filter((ba) => ba.status === 'enrolled').length
            }
          />
        )}
      </section>

      {/* Affichage des steps */}
      {brief && (
        <div className={styles['all-body']}>
          <section>
            <div
              style={{
                justifyContent: (brief?.with_product || brief?.on_spot?.is_on_spot || (!brief?.private?.is_private && brief?.content_type === 'influence'))
                  ? 'space-between'
                  : 'center',
              }}
              className={styles['steps']}
            >
              {/* Affichage de cette partie du step 1 si le brief est un brief en deux step, qu'il n'est pas exclusif et qu'il n'est pas closed */}
              {(brief?.with_product || brief?.on_spot?.is_on_spot || (!brief?.private?.is_private && brief?.content_type === 'influence')) &&
                !brief?.exclusive?.is_exclusive &&
                brief.status !== 'closed' && (
                  <>
                    <div className={styles['step']}>
                      {/* Affichage en bleu si sélectionné */}
                      <h2
                        className={
                          step === 1 ? '' : styles['not-selected-text']
                        }
                      >
                        1
                      </h2>
                      <button
                        type="button"
                        onClick={() => handleStep(1)}
                        className={`${styles['btn']} ${step === 1
                          ? styles['active-btn']
                          : styles['not-selected']
                          }`}
                      >
                        {t('mycastings:participations')}
                        {/* Affichage du nombre de brief */}
                        {briefApplications!.filter(
                          (k) =>
                            k.media.filter(
                              (md) =>
                                md.seen === false && md.status === 'validated'
                            ).length > 0 && k.status === 'applied'
                        ).length > 0 && (
                            <span className="badge">
                              {
                                briefApplications?.filter(
                                  (k) =>
                                    k.media.filter(
                                      (md) =>
                                        md.seen === false &&
                                        md.status === 'validated'
                                    ).length > 0 && k.status === 'applied'
                                ).length
                              }
                            </span>
                          )}
                      </button>
                      {!(
                        !brief.exclusive?.is_exclusive &&
                        brief.private?.is_private
                      ) && (
                          <span className={step !== 1 ? styles['disable'] : ''}>
                            <p>{condition_step_1!.length}</p>{' '}
                            <i className="fa-solid fa-user" />
                          </span>
                        )}
                    </div>
                    {(brief?.with_product || brief?.on_spot?.is_on_spot )&&
                    <div data-tip data-for="main" className={styles['step']}>
                      <h2
                        className={
                          step === 2
                            ? ''
                            : send_product_cond!.length > 0
                              ? styles['not-selected-text']
                              : styles['disabled']
                        }
                      >
                        2
                      </h2>
                      <button
                        type="button"
                        onClick={() => handleStep(2)}
                        className={`${styles['btn']} ${step === 2
                          ? styles['active-btn']
                          : send_product_cond!.length > 0
                            ? styles['not-selected']
                            : styles['disabled']
                          }`}
                      >
                        {brief.with_product ? t('mycastings:shipping-products') : t('mycastings:rdv-vogzter')}
                      </button>
                      {!(
                        !brief.exclusive?.is_exclusive &&
                        brief.private?.is_private
                      ) && (
                          <span className={step !== 2 ? styles['disable'] : ''}>
                            <p>{send_product_cond?.length}</p>{' '}
                            <i className="fa-solid fa-user" />
                          </span>
                        )}
                    </div>}
                  </>
                )}
              {brief.status !== 'closed' && (
                <div className={styles['step']}>
                  <h2
                    className={
                      step === 3
                        ? styles['active-btn']
                        : condition_others_step
                          ? styles['not-selected-text']
                          : styles['disabled']
                    }
                  >
                    {(brief?.with_product || brief?.on_spot?.is_on_spot) && !brief.exclusive?.is_exclusive
                      ? 
                      3
                      :(!brief?.private?.is_private && brief?.content_type === 'influence') ? 
                      2
                      : 1}
                  </h2>
                  <div data-tip data-for="main">
                    <button
                      type="button"
                      onClick={() => handleStep(3)}
                      className={`${styles['btn']} ${step === 3
                        ? styles['active-btn']
                        : condition_others_step
                          ? styles['not-selected']
                          : styles['disabled']
                        }`}
                    >
                      {(brief?.with_product || brief?.on_spot?.is_on_spot)
                        ? 'VOGZ finales'
                        : (!brief?.private?.is_private && brief?.content_type === 'influence')?
                        'Vidéos'
                        : brief?.exclusive?.is_exclusive
                          ? 'Participations'
                          : 'Propositions'}{' '}
                      {last_steps_finals!.filter(
                        (k) =>
                          k.media.filter(
                            (md) =>
                              (md.seen === false &&
                              md.step === ((brief?.with_product || brief?.on_spot?.is_on_spot ) ? 2 : 1) &&
                              md.status === 'validated' && brief?.content_type !== 'influence') ||
                              ( !brief?.private?.is_private &&  k.brief.content_type === 'influence' && k.media.filter((md) => md.status === 'chosen' ).length === 0  &&  
                                md.seen === false && 
                                md.status === 'validated' && 
                                md.step === 2 )
                          ).length > 0
                      ).length > 0 && (
                          <span className="badge ">
                            {
                              last_steps_finals?.filter(
                                (k) =>
                                  k.media.filter((md) => (
                                    md.seen === false && brief?.content_type !== 'influence') ||
                                    ((brief?.with_product || brief?.on_spot?.is_on_spot ) && (md.step === 2 && md.status === 'validated') && brief?.content_type !== 'influence') ||
                                    ( k.brief.content_type === 'influence' && k.media.filter((md) => md.status === 'chosen' ).length === 0  &&  
                                    md.seen === false && 
                                    md.status === 'validated' && 
                                    md.step === 2 )
                                  )
                                    .length > 0
                              ).length
                            }
                          </span>
                        )}
                    </button>
                  </div>
                  {!(
                    !brief.exclusive?.is_exclusive && brief.private?.is_private
                  ) && (
                      <span className={step !== 3 ? styles['disable'] : ''}>
                        <p>{last_steps_finals?.filter(
                                (k) =>
                                  k.media.filter((md) =>  k.brief.content_type !== 'influence' || (k.brief.content_type === 'influence' && k.media.filter((md) => md.status === 'chosen' ).length === 0 ))
                                    .length > 0
                              ).length}</p>{' '}
                        <i className="fa-solid fa-user" />
                      </span>
                    )}
                </div>
              )}
              <ReactTooltip
                id="main"
                place={'top'}
                effect={'float'}
                disable
                multiline
              >
                <span>{t('mycastings:step-unavailable')}</span>
              </ReactTooltip>

              <div className={styles['step']}>
                {brief.status !== 'closed' && (
                  <h2
                    className={
                      step === 4
                        ? ''
                        : condition_others_last
                          ? styles['not-selected-text']
                          : styles['disabled']
                    }
                  >
                    {(brief?.with_product || brief?.on_spot?.is_on_spot ) && !brief.exclusive?.is_exclusive
                      ? 4
                      : (!brief?.private?.is_private && brief?.content_type === 'influence') ?
                      3
                      : 2}
                  </h2>
                )}
                <div data-tip data-for="main">
                  <button
                    type="button"
                    onClick={() => handleStep(4)}
                    className={`${styles['btn']} ${step === 4
                      ? styles['active-btn']
                      : (length_buys ?? 0) > 0
                        ? styles['not-selected']
                        : styles['disabled']
                      }`}
                  >
                  {(!brief?.private?.is_private && brief?.content_type === 'influence')?
                    t('mycastings:to-posted')
                   : t('mycastings:purchases')}
                  </button>
                </div>
                {!(
                  !brief.exclusive?.is_exclusive && brief.private?.is_private
                ) && (
                    <span className={step !== 4 ? styles['disable'] : ''}>
                      <p>{medias_buys.length}</p>{' '}
                      <i className="fa-solid fa-circle-play" />
                    </span>
                  )}
              </div>
            </div>
          </section>

          {brief?.status === 'active' && !brief?.exclusive?.is_exclusive && (
            <section className={styles['filter-section']}>
              <div className={styles['search-bar']}>
                {step !== 4 && !brief?.private?.is_private && (
                  <SearchBar
                    onChange={(v) => {
                      setSearch(v.target.value ?? undefined)
                    }}
                    placeholder={t('mycastings:search')}
                  />
                )}
              </div>
              <div
                className={`${styles['alert-info']}  ${new Date(brief.deadline).getTime() >= new Date().getTime()
                  ? ''
                  : styles['deadline2']
                  }`}
              >
                <span>
                  {' '}
                  {!utilsMobx.isMobile && (
                    (new Date(brief.deadline).getTime() >= new Date().getTime()
                      ? t('mycastings:end-recrument-in')
                      : t('mycastings:end-selection-in')).concat(' ')
                  )}
                  <strong>
                    {new Date(brief.deadline).getTime() >= new Date().getTime()
                      ? countDown(new Date(brief.deadline))
                      : countDown(new Date(brief.deadline2!))}
                  </strong>
                </span>
              </div>
              <div className={styles['btns-filter']}>
                {step !== 4 && (
                  <BtnDrop
                    onSelect={(e) => {
                      setfilterCasting(
                        e === 'createdAt' ? TypeSort.oldestDate : TypeSort.date
                      )
                    }}
                    defaultVal={t('mycastings:sort')}
                    options={options}
                  />
                )}
              </div>
            </section>
          )}

          {brief?.exclusive?.is_exclusive && (
            <>
              <br />
              <br />
            </>
          )}

          {step === 1 ? (
            <section>
              <div
                className={`${styles['grid2']} grid grid--flex grid--flex-4 grid--flex-3--t grid--flex-2--ms grid--flex-1--s`}
              >
                {/* briefapplication != de won or step2 */}
                {briefR.briefApplication.loading ? (
                  <div />
                ) : briefApplications?.filter((k) =>
                  !brief?.exclusive?.is_exclusive &&
                    !brief.private?.is_private
                    ? k.media.filter((md) => md.status === 'validated')
                      .length > 0 && k.status === 'applied'
                    : k._id
                ).length === 0 ? (
                  <div className={styles['placeholder-nodata']}>
                    <img src="/assets/svgs/placeholder.svg" alt="holder" />
                    <br />
                    <h3> {t('mycastings:vogzters-in-prod')}</h3>
                  </div>
                ) : (
                  searchFilter(
                    sortByCreatedDate(
                      briefR.briefApplication.data
                        ? briefR.briefApplication.data.filter((k) =>
                          !brief?.exclusive?.is_exclusive &&
                            !brief.private?.is_private
                            ? k.media.filter(
                              (md) => md.status === 'validated'
                            ).length > 0 && k.status === 'applied'
                            : k._id
                        )
                        : [],
                      filterCasting
                    ),
                    search
                  ).map((v, i) =>
                    !brief.exclusive?.is_exclusive &&
                      !brief.private?.is_private ? (
                      <CardBriefApplication
                        key={i}
                        showBookmarked
                        briefApplication={v}
                        media={v.media.at(0)}
                        type="briefApplication"
                        nameCtaViewProfile={t('mycastings:discover-profile')}
                      />
                    ) : null
                  )
                )}
              </div>
            </section>
          ) : step === 2 ? (
            <section className={styles['card-send-product']}>
              {send_product_cond!.length === 0 ? (
                <div>
                  <h3>{t('mycastings:unavailable')}</h3>
                </div>
              ) :
                brief.on_spot?.is_on_spot ?
                  (
                    searchFilter(
                      sortByCreatedDate(send_product_cond!, filterCasting),
                      search
                    ).map((v, i) => <CardRDVStore id={v?._id} applicant={v?.applicant} rendez_vous={v.rendez_vous} key={i} />)
                  )
                  :
                  (
                    searchFilter(
                      sortByCreatedDate(send_product_cond!, filterCasting),
                      search
                    ).map((v, i) => <CardSendProduct {...v} key={i} />)
                  )
              }
            </section>
          ) : step === 3 ? (
            !brief?.exclusive!.is_exclusive ? (
              <section className={styles['applicant-vogz']}>
                {last_steps_finals?.filter(
                  (v) => v.status !== 'won' && v.status !== 'lost'
                ).length === 0 ? (
                  <div className={styles['placeholder-nodata']}>
                    <img src="/assets/svgs/placeholder.svg" alt="holder" />
                    <br />
                    <h3>{t('mycastings:vogzters-in-prod')}</h3>
                  </div>
                ) : (
                  searchFilter(
                    sortByCreatedDate(
                      last_steps_finals
                        ? last_steps_finals.filter(
                          (v) => v.status !== 'won' && v.status !== 'lost' && (v.brief.content_type !== 'influence' || (v.brief.content_type === 'influence' && v.media.filter((md) => md.status === 'chosen' ).length === 0 ))
                        )
                        : [],
                      filterCasting
                    ),
                    search
                  )
                    .filter((v) => v.media?.length > 0)
                    .map((v, i) => (
                      <div key={i}>
                        <header>
                          <aside className={styles['card-applicant-left']}>
                            <figure>
                              <VogzterPicture
                                size={85}
                                gender={v.applicant?.profile?.gender}
                                imgSource={v.applicant?.profile?.picture?.source}
                                showCertified={v.applicant?.certified}
                                lastActivity={v.applicant?.last_activity}
                                href={`/dashboard/castings/${v?.brief._id}/briefapplication/${v?._id}`}
                              />
                              {v.media.filter(
                                (md, i) => md.seen === false && md.step === 2
                              ).length > 0 && (
                                  <span className={styles['badge-red']}>NEW</span>
                                )}
                            </figure>
                            <div
                              className={styles['card-desc-briefapplication']}
                            >
                              <h3>{formatVogzterName(v.applicant.first_name, v.applicant.last_name)}</h3>
                              <button
                                type="button"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/castings/${v?.brief._id}/briefapplication/${v?._id}`
                                  )
                                }
                              >
                                {t('mycastings:view-profile')}
                              </button>
                            </div>
                          </aside>
                          <aside className={styles['card-applicant-right']}>
                            <div>
                              <button
                                type="button"
                                disabled={
                                  v.media.filter(
                                    (ba, i) => ba.in_basket === true
                                  ).length === 0
                                }
                                onClick={() => {
                                  modalR.toogleSelection(
                                    v,
                                    undefined,
                                    !modalR.selection.open
                                  )
                                }}
                                className={
                                  v.media.filter(
                                    (ba, i) => ba.in_basket === true
                                  ).length > 0
                                    ? styles['active']
                                    : ''
                                }
                              >
                                {!utilsMobx.isMobile && t('mycastings:validate-select').concat(' ')}
                                <i className="fa-solid fa-bag-shopping" />
                              </button>
                              {v.media.filter((ba, i) => ba.in_basket === true)
                                .length > 0 && (
                                  <span className={styles['badge']}>
                                    {
                                      v.media.filter(
                                        (ba, i) => ba.in_basket === true
                                      ).length
                                    }
                                  </span>
                                )}
                            </div>
                          </aside>
                        </header>
                        <footer>
                          {v.media?.length === 0 || ( v.brief.content_type === 'influence' && v.media.filter((md) => md.status === 'validated' && md.step === 2)
                           ?.length === 0)? (
                            <div className={styles['placeholder-nodata']}>
                              <img
                                src="/assets/svgs/placeholder-step2-vid.svg"
                                alt="holder2"
                              />
                              <br />
                              <h3>{t('mycastings:vogzter-in-prod')}</h3>
                            </div>
                          ) : (
                            v.media
                              ?.filter(
                                (v) =>
                                  v.status === 'validated' &&
                                  v.step === ((brief?.with_product || brief?.on_spot?.is_on_spot || (!brief?.private?.is_private && brief?.content_type === 'influence')) ? 2 : 1)
                              )
                              .map((k, i) => (
                                <CardVogz
                                  key={i}
                                  showBasket
                                  showBookmarked
                                  briefApplication={v}
                                  media={k}
                                />
                              ))
                          )}
                        </footer>
                      </div>
                    ))
                )}
              </section>
            ) : (
              <div
                style={{ gridGap: '40px 0' }}
                className={`${styles['grid2']} grid grid--flex grid--flex-4 grid--flex-3--t grid--flex-2--ms grid--flex-1--s`}
              >
                {/* briefapplication != de won or step2 */}
                {briefApplications?.filter(
                  (v) =>
                    v.status !== 'won' &&
                    v.status !== 'lost' &&
                    v.media.filter((md) => md.status === 'validated').length > 0
                )?.length === 0 ? (
                  <div className={styles['placeholder-nodata']}>
                    <img src="/assets/svgs/placeholder.svg" alt="holder" />
                    <br />
                    <h3>{t('mycastings:vogzters-in-prod')}</h3>
                  </div>
                ) : (
                  briefApplications
                    ?.filter(
                      (k) =>
                        k.media.filter((md) => md.status === 'validated')
                          .length > 0 && k.status !== 'won'
                    )
                    ?.map((v, i) => (
                      <CardVogzClient
                        briefApplication={v}
                        media={
                          v.media.filter((md) => md.status === 'validated')[0]
                        }
                        key={i}
                      />
                    ))
                )}
              </div>
            )
          ) : (
            <section>
              <div
                className={`${styles['grid2']} `}
              >
                {briefR.briefApplication
                  .data!.map((ba) => ba.media)
                  .flat()
                  .filter((m) => m.status === 'chosen')
                  ?.map((v, i) => (
                    <CardBriefApplication
                      className={`card-brief-application-${brief.content_type}`}
                      briefApplication={briefR.briefApplication.data?.find(
                        (ba) => ba._id === v.brief_application
                      )}
                      showBookmarked
                      media={v}
                      key={i}
                      type="download"
                      isPrivate={false}
                      contentType={brief?.content_type}
                      showCalendar={() =>setModalCalendar(v)}
                      showDeclinePublication={() => setShowModalDeclinePublication(v)}
                    />
                  ))}
              </div>
              {/* Modal set publication */}
              {
                showModalCalendar && (
                  <CardSetPublication
                    showModalCalendar={showModalCalendar}
                    setModalCalendar={setModalCalendar}
                    className='modal-set-publication'
                    briefApplication={
                      briefR.briefApplication.data?.find(
                        (ba) => ba._id === showModalCalendar?.brief_application
                      )
                    }
                  />
                )
              }
              {/* Modal declined publication */}
              {
                  showModalDeclinePublication && (
                  <CardDeclinedPublication
                    className='modal-decline-publication'
                    showModalDeclinePublication={showModalDeclinePublication}
                    setShowModalDeclinePublication={setShowModalDeclinePublication}
                  />
                ) 
              }
            </section>
          )}
        </div>
      )}
      {!briefR.briefApplication.loading &&
        briefApplications!?.filter(
          (ba) =>
            ba.media.filter((md) => md.in_basket).length > 0 &&
            ba.status !== 'won'
        ).length > 0 &&
        brief?.exclusive?.is_exclusive &&
        step !== 4 && (
          <>
            {' '}
            <motion.div
              initial={{ opacity: 0, y: '200px' }}
              exit={{ opacity: 0, y: '200px' }}
              transition={{ duration: 0.6 }}
              animate={{ y: 0, opacity: 1 }}
              className={`${styles['infos-brief-info']} ${styles['add-selection']}`}
            >
              <button
                type="button"
                onClick={() =>
                  modalR.toogleSelection(
                    undefined,
                    briefApplications!,
                    undefined
                  )
                }
                className={styles['active']}
              >
                {t('mycastings:validate-select')}{' '}
                <i className="fa-solid fa-bag-shopping" />
                <span className={styles['badge']}>
                  {
                    briefApplications?.filter(
                      (ba: any) =>
                        ba.media.filter((md: any) => md.in_basket).length > 0 &&
                        ba.status !== 'won'
                    ).length
                  }
                </span>
              </button>
            </motion.div>
            <br />
            <br />
            <br />
            <br />
          </>
        )}

      {new Date(brief?.deadline!).getTime() < new Date().getTime() &&
        new Date(brief?.deadline2!).getTime() > new Date().getTime() && (
          <>
            <br />
            <br />
            <br />
          </>
        )}
    </div>
  )
}

const ContentNotPrivate_ = observer(ContentNotPrivate)

ContentNotPrivate.propTypes = {
  brief: PropTypes.shape({
    deadline: PropTypes.any,
    deadline2: PropTypes.any,
    description: PropTypes.any,
    exclusive: PropTypes.shape({
      is_exclusive: PropTypes.any
    }),
    name: PropTypes.any,
    private: PropTypes.shape({
      is_private: PropTypes.any
    }),
    status: PropTypes.string,
    type: PropTypes.shape({
      name: PropTypes.any
    }),
    with_product: PropTypes.any,
    on_spot: PropTypes.shape({
      is_on_spot: PropTypes.any
    }),
    content_type: PropTypes.string,
  }),
  t: PropTypes.func.isRequired
}

ContentNotPrivate.defaultProps = {
  brief: undefined,
}

interface ContentNotPrivateType {
  brief?: typeof briefR.brief.data,
  error: typeof briefR.brief.error,
  t: TFunction<"translation", undefined>,
  step: number,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

interface dataBrief {
  data: Brief
}

export default observer(MyCasting)
