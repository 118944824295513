import { action, computed, makeObservable, observable } from 'mobx'

let instance: Utils

export class Utils {
  constructor() {
    if (instance) {
      makeObservable(instance)
      return instance
    }
    instance = this
    makeObservable(instance)
  }

  @observable mobile: boolean = false

  @observable mobileMaxWidth = 600

  @observable open: boolean = true

  @observable aside_animation_ended: boolean = false

  @observable window: { width: number; height: number } = {
    width: 0,
    height: 0,
  }

  @observable sideBarWidth: number = 0

  @action.bound
  handleWindow(width?: number, height?: number) {
    this.window = {
      ...this.window,
      width: width ?? this.window.width,
      height: height ?? this.window.height,
    }
  }

  @action.bound
  handleOpen(value?: boolean) {
    this.open = value ?? !this.open
  }

  @action.bound
  handleMobile(value?: boolean) {
    this.mobile = value ?? !this.mobile
  }

  @action.bound
  handleAssideEnded(value?: boolean) {
    this.aside_animation_ended = value ?? !this.aside_animation_ended
  }

  @action.bound
  setSideBarWidth(value: number) {
    this.sideBarWidth = value
  }

  @computed get getWidth() {
    return this.window.width
  }

  @computed get getSideBarWidth() {
    return (this.getWidth <= this.mobileMaxWidth && !this.mobile) ? 0 : this.sideBarWidth
  }

  @computed get isMobile() {
    return this.getWidth <= this.mobileMaxWidth && !this.mobile
  }

  @computed get getHeight() {
    return this.window.height
  }
}

const utilsMobx = new Utils()
export default utilsMobx
