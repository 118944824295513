import React, { FC } from 'react'
import PropTypes from 'prop-types'
import styles from '../components/styles.module.scss'

const SwitchBtn: FC<SwitchBtnProps> = ({...props}) => {
  const { onChange, checked, name } = props
  return (
    
    <div className={styles['box-swtich']}>
    <label className={styles["switch"]}>
      <input type="checkbox" 
        name={name}
        checked={checked} 
        onChange={onChange}
      />
      <span className={styles["slider"]}/>
    </label>
</div>


  )
}

interface SwitchBtnProps extends React.HTMLProps<HTMLInputElement> {
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void,
  checked: boolean,
  name: string
}


SwitchBtn.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
}

export default SwitchBtn
