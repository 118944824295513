/* eslint-disable react/prop-types */
import React, { FC, useState } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import useSWR, { mutate } from 'swr'
import ReactStars from 'react-stars'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styles from './components/card-styles.module.scss'
import briefMobx from '../../../../../mobx/brief'
import modal from '../../../../../mobx/modal'
import {
  Brief,
  MediaBA,
  MediaCastings,
  MyCasting,
} from '../../../../../types-project/Brief'
import { countDown } from '../../../../../utils/helpers'
import userMobx from '../../../../../mobx/user'
import { BrandI } from '../../../../../types-project/Client'

const CardVogz: FC<CardVogzProps> = ({
  briefApplication,
  media,
  showBasket,
  input,
  showCancel,
  purchase,
  profilFav,
  showBookmarked,
  showBottomStreamer,
  className,
  isGuest,
  confirmBasket
}) => {
  const [mouseEnter, setmouseEnter] = useState(false)
  // translation
  const { t } = useTranslation()

  return (
    <article className={`${styles['card-briefapplication']} ${className} ${showBottomStreamer ? '!h-auto' : ''}`}>
      {briefApplication!?.brief?.exclusive?.is_exclusive && !profilFav && (
        <h2 className={styles['countdown-vogz-client']}>
          {countDown(new Date(briefApplication!.expiresAt!))}
        </h2>
      )}
      <div className={showBottomStreamer ? '!h-[310px]' : ''}
        onMouseEnter={() => setmouseEnter(!mouseEnter)}
        onMouseLeave={() => setmouseEnter(!mouseEnter)}
      >
        {showCancel && media?.in_basket && (
          <button
            type="button"
            onClick={() => {
              briefMobx.addToBasket(media!._id)
              modal.toogleSelection(undefined, undefined, false)
            }}
            className={styles['cancel-choice']}
          >
            <span className={styles['info-cancel']}>
              {t('mycastings:card-vogz:cancel-choice')}
            </span>
            <i className="fa-solid fa-circle-x" />
          </button>
        )}
        <div className={styles['card-body']}>
          <span>#{media!?.readable_id}</span>
          {confirmBasket && media.offered && (
            <span className={styles['badge-offered']} role='img' aria-label='emoji cadeau'>🎁</span>
          )}
          {!isGuest &&
            <>
              {(media !== undefined && showBookmarked) && (<button type='button' onClick={() => { userMobx.toggleSaveMedia(media!._id) }} className={`${styles['bookmarked_btn']}
              ${(userMobx.company.data?.media_saved.map((v) => v.media?._id ?? '').includes(media._id ?? '')) ? styles['active'] : ''}`} >
                <i className="fa-solid fa-bookmark" />
              </button>)}

              {media?.source && (<button type='button' onClick={() => {
                window.open(`${media?.source.replace('.m3u8', '/medium.mp4')}`, '_blank')
              }}
                className={`${styles['open-btn']}`}>
                <i className="fa-solid fa-arrow-up-from-bracket" />
              </button>)}
            </>
          }
          {purchase && briefApplication?.payment.status !== 'none' && (
            <span className={styles['span-won']}>Achat confirmé</span>
          )}
          {!media!?.seen && media.status !== 'chosen' && (
            <motion.span
              style={{
                bottom:
                  media!?.step === 2 || !briefApplication!?.brief?.with_product
                    ? 20
                    : 'unset',
              }}
              initial={{ opacity: 0, x: -60, y: 30 }}
              transition={{ delay: 2 }}
              animate={{ opacity: 1, y: 0, x: 0 }}
              className={`${styles['badge-red']} !bottom-2 !left-2 absolute !right-0 !w-min`}
            >
              NEW
            </motion.span>
          )}
          {/* <img
            alt="avatar"

            src={'/assets/svgs/video-play.svg'}
          /> */}
          <span
            className={styles['play-btn']}
            onClick={() => {
              briefMobx.setSeenMediaFromBA(briefApplication!?._id, media!?._id)
              modal.toogleModalVideo(media!?.source, true)
            }}>
            <i className='fa-solid fa-play' />
          </span>
          <img
            className={showBottomStreamer ? '!rounded-b-none' : ''}
            src={mouseEnter ? media!?.gif : media!.thumbnail}
            alt="gif"
          />
        </div>
        {briefMobx.briefApplication.data!?.find(
          (ba) => ba._id === briefApplication!?._id
        )?.exclusive?.error && (
            <span className="error">{briefApplication!?.exclusive!.error}</span>
          )}
        {input && (
          <input
            placeholder={t('mycastings:card-vogz.discount-code')}
            value={briefApplication!.exclusive?.code}
            onChange={(v) => {
              briefMobx.briefApplication.data!.find(
                (ba) => ba._id === briefApplication!?._id
              )!.exclusive = {
                code: v.target.value,
                redeemed: false,
              }
            }}
          />
        )}
        {showBottomStreamer && (
          <div className={`${styles['bottom-streamer']} ${media.status === 'chosen' ? styles['winner-streamer'] : ''}`}>
            <b>{((media.brief as Brief)?.brand as BrandI)?.name}</b>
          </div>
        )}
        {showBasket && (
          <button
            type='button'
            onClick={() => {
              if (!media.offered)
                briefMobx.addToBasket(media!?._id)
            }}
            className={`${styles['add-to-cart_btn']} ${media.offered ? styles['offered'] : ''} ${media?.in_basket ? styles['active'] : ''}`}
          >
            {
              media.offered ? (<span>{t('mycastings:card-vogz.offered')}</span>) :
                (!media?.in_basket ?
                  briefApplication?.brief.private?.is_private ?
                    (<span>{t('mycastings:select')}</span>) :
                    (<span>{t('mycastings:card-vogz.add-to-cart')} <i className="fa-regular fa-circle-plus" /></span>) :
                  (<span><i className="fa-regular fa-circle-check" /></span>)
                )
            }
          </button>
        )}
      </div>

    </article>
  )
}

interface CardVogzProps {
  media: MediaBA
  briefApplication?: MyCasting
  showBasket?: boolean
  showCancel?: boolean
  input?: boolean
  purchase?: boolean
  profilFav?: boolean
  showBookmarked?: boolean
  showBottomStreamer?: boolean
  className?: string
  isGuest?: boolean
  confirmBasket?: boolean
}

CardVogz.defaultProps = {
  className: '',
  isGuest: false,
  confirmBasket: false,
}

export default observer(CardVogz)
