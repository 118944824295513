/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import react, { FC } from "react";
import { motion } from "framer-motion";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./components/styles.module.scss"
import ReviewRatings from "../ReviewRatings";
import { Review } from "../../../../../types-project/Client";
import VogzterPicture from "../VogzterPicture";

interface UserReviewsProps {
    review: Review
}

const UserReviews: FC<UserReviewsProps> = ({ review }) => {

    const { t } = useTranslation()

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            className={styles["review-wrapper"]}>
            <div className={styles['review-section']}>
                <div className={styles["review-content"]}>
                    <div className={`${styles["review-content__header"]} flex justify-start items-center gap-2`}>
                        <div className={styles["logo"]}>
                            <img src={review.brand.logo} alt="logo" />
                        </div>
                        <div className={styles["review-content__header__text"]}>
                            <h2>{review.client.profile?.first_name} -<span>{review.brand.name}</span></h2>
                            <p className={styles['date']}>
                                {new Date(review.updatedAt).toLocaleDateString()}
                            </p>
                        </div>
                    </div>
                    <div className={styles["review-content__body"]}>
                        <p className={styles['comment']}>{review.comment}</p>
                    </div>
                    <ReviewRatings
                        quality={review.rating.quality}
                        communication={review.rating.communication}
                        punctuality={review.rating.punctuality}
                        overall={review.rating.overall}
                    />
                </div>
            </div>
            {
                review.reply?.text && (
                    <Accordion
                        className={styles['accordion']}
                    >
                        <AccordionSummary className={styles["vogzter-name"]}>
                            <h2>
                                {t('vogzters:reviews.see-reply')}
                                {review.user.first_name}
                                {' '}
                                {review.user.last_name}.
                            </h2>
                        </AccordionSummary>
                        <AccordionDetails className={styles["reply-section"]}>
                            <div className={styles["reply-section__content"]}>
                                <div className="flex justify-center items-center gap-2">
                                    <VogzterPicture
                                        size={60}
                                        gender={review.user.profile?.gender ?? 'male'}
                                        imgSource={
                                            review.user.profile?.picture ?
                                                review.user.profile?.picture.source
                                                : undefined
                                        }
                                        showCertified={review.user.certified}
                                        lastActivity={review.user.last_activity}
                                    />
                                    <div className={styles["reply-section__header"]}>
                                        <h2>{review.user.first_name} {review.user.last_name}.</h2>
                                        {
                                            review.reply?.date && (
                                                <p className={styles['date']}>
                                                    {new Date(review.reply?.date).toLocaleDateString()}
                                                </p>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={styles["reply-section__body"]}>
                                    <p className={styles['comment']}>{review.reply?.text}</p>
                                </div>
                            </div>

                        </AccordionDetails>
                    </Accordion>
                )
            }
        </motion.div>
    )
}

UserReviews.defaultProps = {
    review: undefined
}

export default UserReviews;