import React, { FC } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getAvatar, timeLeft } from "../../../../../utils/helpers";
import modal from "../../../../../mobx/modal";


const VogzterPicture: FC<VPProps> = ({ imgSource, gender, showCertified, size, lastActivity, msgCount, href, removeShadow, videoIntro, className }) => {

  if (!size) size = 110
  if (!msgCount) msgCount = 0

  const isActive = lastActivity ? timeLeft(new Date(lastActivity)).daysLeft >= -4 : false

  return (
    <a 
      className={`relative w-min flex ${href ? 'cursor-pointer' : 'cursor-default'} ${className}`}
      href={href}
    >
      <img
        style={{ width: size, height: size, minWidth: size, minHeight: size, boxShadow: removeShadow ? "none" : '0px 6px 11px #f5f5f5' }}
        className="rounded-full border-2 border-white"
        alt="avatar"
        src={getAvatar(
          gender ?? "male",
          imgSource
        )}
      />
      {showCertified &&
        <img
          className="!absolute top-1 right-1 !border-none !bg-transparent z-[1]"
          style={{ width: size / 4.2, height: size / 4.2 }}
          src={'/assets/svgs/certify-new.svg'}
          alt="valid"
        />
      }
      {msgCount > 0 &&
        <span
          className="!absolute flex top-1 right-1 w-5 h-5 justify-center items-center font-hurmeBold bg-[#CD0000] text-white text-xs rounded-full z-[4]"
        >
          {msgCount}
        </span>
      }
      {isActive &&
        <span
          className="!absolute bottom-1 right-1 bg-[#33FF85] drop-shadow-sm rounded-full border border-white z-[2]"
          style={{ width: size / 5.3, height: size / 5.3 }}
        />
      }
      {videoIntro &&
        <div className="!absolute w-full h-full flex justify-center items-center">
          <img
            className="cursor-pointer transition items-center justify-center !border-none !bg-transparent z-[4] hover:!h-9 hover:!w-9"
            style={{ width: size / 3.6, height: size / 3.6 }}
            src={'/assets/svgs/player-video.svg'}
            alt="video"
            onClick={() => modal.toogleModalVideo(videoIntro)}
          />
        </div>
      }
    </a>
  )
}

type VPBaseProps = {
  size?: number
  lastActivity?: string
  imgSource?: string
  gender?: "male" | "female"
  showCertified?: boolean
  msgCount?: number
  href?: string
  removeShadow?: boolean
  videoIntro?: string
  className?: string
}

type VPPropsWithMsgCount = VPBaseProps & {
  msgCount: number
  showCertified?: undefined
}

type VPPropsWithCertified = VPBaseProps & {
  showCertified?: boolean
  msgCount?: number
}

type VPProps = VPPropsWithMsgCount | VPPropsWithCertified

VogzterPicture.defaultProps = {
  showCertified: false,
  size: 110,
  imgSource: undefined,
  gender: undefined,
  lastActivity: undefined,
  msgCount: 0,
  href: undefined,
  removeShadow: false,
  videoIntro: undefined,
  className: ''
}

VogzterPicture.propTypes = {
  imgSource: PropTypes.any,
  gender: PropTypes.any,
  showCertified: PropTypes.any,
  size: PropTypes.number,
  lastActivity: PropTypes.any,
  msgCount: PropTypes.any,
  href: PropTypes.any,
  removeShadow: PropTypes.bool,
  videoIntro: PropTypes.any,
  className: PropTypes.string
}

export default VogzterPicture;