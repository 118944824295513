import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import styles from './components/styles.module.scss'

const BtnBack: FC<Btn1Props> = ({ btnName, className, customAction }) => {
  const navigate = useNavigate()
  return (
    <button
      type="button"
      className={`btn-txt ${className}`}
      onClick={() => {
        if (customAction) customAction!()
        else navigate(- 1)
      }}
    >
      <i className="fa-regular fa-angle-left" style={{ marginRight: 5 }} />
      {btnName}
    </button >
  )
}

interface Btn1Props {
  btnName: string
  className?: string
  customAction?: () => any
}

BtnBack.defaultProps = {
  className: undefined,
  customAction: undefined
}

BtnBack.propTypes = {
  btnName: PropTypes.string.isRequired,
  className: PropTypes.string,
  customAction: PropTypes.func
}

export default BtnBack
