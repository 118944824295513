import React, { Dispatch, FC, useEffect, useRef } from "react"
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import styles from './components/portfolio.module.scss'
import CardBriefApplication from "../../../../../../Castings/MyCasting/components/CardBriefApplication"
import { VideoDemo, VideoIntro } from "../../../../../../../types-project/Brief"
import CustomSlider from './components/CustomSlider'

interface PortfolioProps {
  setShowPortfolio: Dispatch<React.SetStateAction<boolean>>
  videosDemo: VideoDemo[] | undefined
  videoIntro: VideoIntro | undefined
  className?: string
}

const Portfolio: FC<PortfolioProps> = ({ setShowPortfolio, videosDemo, videoIntro, className }) => {

  const videos: (VideoIntro | VideoDemo)[] = []

  if (videosDemo) {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < videosDemo.length; index++) {
      const video = videosDemo[index];
      if (Object.prototype.hasOwnProperty.call(video, 'source')) videos.push(video)
    }
  }
  // insert at the middle of the array
  if (videoIntro && Object.prototype.hasOwnProperty.call(videoIntro, 'source')) {
    videos.splice(Math.floor(videos.length / 2), 0, videoIntro)
  }

  return (
    <motion.section className={`${styles['portfolio']} ${className}`}
      key="portfolio"
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1 }}
    >
      <button className="self-start"
        type="button"
        onClick={() => {
          setShowPortfolio(false)
        }}
      >
        <i className={`fa-sharp fa-solid fa-xmark ${styles['close-btn']}`} />
      </button>
      <h1>Portfolio</h1>

      <div className={styles['slider-container']}>
        <CustomSlider
          data={videos}
          autoScroll={false}
          autoScrollDelay={3000}
          renderItem={(item, index) => (
            <div>
              {videos.at(index)?.type === 'videoIntro' ? (
                <CardBriefApplication
                  type="videoIntro"
                  videoIntro={videos.at(index) as VideoIntro}
                />
              ) : (
                <CardBriefApplication
                  type="videoDemo"
                  videoDemo={videos.at(index) as VideoDemo}
                />
              )}
            </div>
          )}
          iconsAtBottom
        />
      </div>
    </motion.section>
  )
}

Portfolio.propTypes = {
  setShowPortfolio: PropTypes.any.isRequired,
  videosDemo: PropTypes.arrayOf(PropTypes.any).isRequired,
  videoIntro: PropTypes.any.isRequired,
  className: PropTypes.string,
}

Portfolio.defaultProps = {
  className: '',
}

export default Portfolio
