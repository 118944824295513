import * as React from 'react'
import PropTypes from 'prop-types'

interface IconProps {
  color?: string
}

const VogztersIcon: React.FC<IconProps> = ({ color }) => (
  <svg
  width={30}
  height={24}
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#a)" fillRule="evenodd" clipRule="evenodd" fill={color}>
    <path d="M14.843 10.48a4.84 4.84 0 0 1-1.96-.413 5.04 5.04 0 0 1-1.603-1.124 5.259 5.259 0 0 1-1.084-1.665 5.352 5.352 0 0 1-.399-2.038c0-.705.135-1.39.4-2.037a5.236 5.236 0 0 1 1.083-1.665A5.049 5.049 0 0 1 12.882.414 4.843 4.843 0 0 1 14.842 0c.68 0 1.34.14 1.961.413.6.264 1.14.642 1.603 1.124a5.281 5.281 0 0 1 1.084 1.665c.264.646.398 1.332.398 2.037 0 .706-.134 1.391-.398 2.038a5.253 5.253 0 0 1-1.084 1.665 5.06 5.06 0 0 1-1.603 1.124 4.83 4.83 0 0 1-1.96.412Zm0-10.072c-2.567 0-4.656 2.167-4.656 4.832 0 2.664 2.089 4.832 4.656 4.832 2.568 0 4.657-2.169 4.657-4.832 0-2.665-2.089-4.832-4.657-4.832Z" />
    <path d="M10.321 4.085c.076.004.15.006.223.006 2.149 0 4.015-1.533 4.521-3.677a4.041 4.041 0 0 0-.222-.006c-2.15 0-4.017 1.533-4.522 3.677Z" />
    <path d="M10.544 4.498c-.154 0-.314-.007-.48-.024a.188.188 0 0 1-.137-.081.21.21 0 0 1-.035-.162c.11-.594.32-1.159.619-1.68.293-.508.666-.96 1.107-1.34.442-.383.94-.683 1.48-.889a4.852 4.852 0 0 1 2.225-.298.193.193 0 0 1 .137.081.21.21 0 0 1 .036.162 5.351 5.351 0 0 1-.62 1.68 5.19 5.19 0 0 1-1.106 1.34 4.989 4.989 0 0 1-1.481.89 4.864 4.864 0 0 1-1.745.321ZM15.51.457c.68 1.403 2 2.392 3.496 2.617C18.32 1.652 17.026.681 15.51.457Z" />
    <path d="m19.325 3.52-.014-.002a4.906 4.906 0 0 1-2.612-.994 5.218 5.218 0 0 1-1.69-2.228.216.216 0 0 1 .022-.198.196.196 0 0 1 .172-.084c.945.069 1.847.413 2.612.994a5.231 5.231 0 0 1 1.686 2.22.202.202 0 0 1 .018.086.2.2 0 0 1-.193.205h-.001ZM23.817 13.638c-.536 0-1.056-.109-1.547-.324a3.951 3.951 0 0 1-1.262-.883 4.12 4.12 0 0 1-.854-1.313 4.247 4.247 0 0 1-.312-1.608c0-.557.106-1.098.312-1.607.201-.492.488-.933.854-1.312a3.96 3.96 0 0 1 1.262-.885 3.831 3.831 0 0 1 1.547-.324c.536 0 1.056.11 1.547.324.472.209.898.506 1.262.885.365.38.652.82.852 1.312.208.509.313 1.05.313 1.607 0 .558-.106 1.1-.313 1.608-.2.492-.487.934-.852 1.313-.364.378-.79.676-1.262.883-.49.216-1.01.324-1.547.324Zm0-7.847c-1.978 0-3.586 1.668-3.586 3.72 0 2.052 1.608 3.72 3.586 3.72 1.976 0 3.585-1.668 3.585-3.72 0-2.052-1.61-3.72-3.585-3.72Z" />
    <path d="M20.358 9.08c-.07 0-.14-.002-.21-.006.269-1.913 1.86-3.386 3.777-3.386.185 0 .366.014.544.04-.1.428-.26.834-.478 1.213a4.356 4.356 0 0 1-.927 1.124 4.15 4.15 0 0 1-2.707 1.015Zm-.544-.473c.099-.427.26-.834.478-1.213l.005-.008a4.454 4.454 0 0 0-.483 1.221Zm.945-1.873Z" />
    <path d="M27.276 8.293a4.128 4.128 0 0 1-2.059-.821 4.39 4.39 0 0 1-1.363-1.742c1.567.063 2.894 1.11 3.422 2.563Zm-3.278-2.99h.01a4 4 0 0 1-.01 0Zm2.361.962ZM6.186 13.638c-.536 0-1.055-.109-1.546-.324a3.949 3.949 0 0 1-1.264-.883 4.21 4.21 0 0 1-1.165-2.92c0-.558.105-1.1.313-1.608.201-.492.487-.933.852-1.312a3.96 3.96 0 0 1 1.264-.885 3.825 3.825 0 0 1 1.546-.324c.536 0 1.057.11 1.547.324.472.209.897.506 1.262.885.366.38.653.82.853 1.312.209.509.312 1.05.312 1.607 0 .558-.104 1.1-.312 1.608-.2.492-.487.934-.853 1.313-.364.378-.79.676-1.262.883-.49.216-1.011.324-1.547.324Zm0-7.847C4.208 5.79 2.6 7.459 2.6 9.51c0 2.052 1.609 3.72 3.586 3.72 1.978 0 3.587-1.668 3.587-3.72 0-2.052-1.61-3.72-3.587-3.72Z" />
    <path d="M6.067 5.792C6.474 7.416 7.9 8.57 9.536 8.57l.12-.002c-.408-1.623-1.833-2.777-3.47-2.777l-.119.001Z" />
    <path d="M9.536 8.977a3.83 3.83 0 0 1-1.375-.254 3.922 3.922 0 0 1-1.166-.7c-.347-.3-.64-.655-.871-1.056a4.186 4.186 0 0 1-.49-1.323.202.202 0 0 1 .036-.16.19.19 0 0 1 .137-.082 3.822 3.822 0 0 1 1.753.236 3.91 3.91 0 0 1 1.166.7c.347.3.64.655.872 1.056.236.41.4.855.49 1.322a.216.216 0 0 1-.036.161.194.194 0 0 1-.137.082 3.91 3.91 0 0 1-.38.019ZM5.587 5.84c-1.101.191-2.046.898-2.57 1.924 1.09-.192 2.05-.91 2.57-1.923Z" />
    <path d="M2.692 8.214a.193.193 0 0 1-.158-.085.207.207 0 0 1-.02-.197c.278-.7.739-1.307 1.33-1.757a3.872 3.872 0 0 1 2.058-.782.192.192 0 0 1 .172.085.208.208 0 0 1 .02.197 4.102 4.102 0 0 1-1.33 1.756 3.873 3.873 0 0 1-2.058.783h-.014ZM19.976 24v-5.666a.2.2 0 0 0-.194-.205.2.2 0 0 0-.195.204V24h-9.185v-5.666a.198.198 0 0 0-.193-.204.199.199 0 0 0-.195.204V24H7.266v-4.287c0-1.078.206-2.127.61-3.117a8.3 8.3 0 0 1 .715-1.363 7.994 7.994 0 0 1 2.09-2.17c.414-.29.855-.54 1.312-.742a7.401 7.401 0 0 1 3.001-.633c1.04 0 2.048.213 3.001.633.458.202.899.452 1.312.742a7.942 7.942 0 0 1 2.09 2.17 8.19 8.19 0 0 1 1.326 4.48V24h-2.747Z" />
    <path d="M23.192 24v-4.287a8.679 8.679 0 0 0-1.407-4.757l-.028-.042a5.845 5.845 0 0 1 2.178-.423c.814 0 1.607.168 2.355.498.723.317 1.37.772 1.928 1.352a6.42 6.42 0 0 1 1.783 4.45v3.208h-6.81ZM0 24v-3.21a6.404 6.404 0 0 1 1.783-4.449 6.06 6.06 0 0 1 1.928-1.352 5.79 5.79 0 0 1 3.802-.315c.245.063.485.142.72.236l-.03.046a8.71 8.71 0 0 0-1.406 4.757V24H.001Z" />
  </g>
  <defs>
      <filter
        id="a"
        x={0.001}
        y={0}
        width={32}
        height={24}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={2} />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.560784 0 0 0 0 0.631373 0 0 0 0 0.898039 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1415_16764" />
      </filter>
    </defs>
</svg>
)

VogztersIcon.propTypes = {
  color: PropTypes.string,
}

VogztersIcon.defaultProps = {
  color: '#2A42A7',
}

export default VogztersIcon
