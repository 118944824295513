/* eslint-disable react/prop-types */
import React, { FC, useState } from 'react'
import styles from './switcher.module.scss';




const Switcher: FC<SwitcherProps> = ({
  classNames,
  swhitcherStyles,
  content,
  onChangeState
}) => {
  const [gender, setGender] = useState("first");

  function onChangeValue(event: any) {
    setGender(event.target.value);
    if (onChangeState) {
      onChangeState(event.target.value)
    }
  }

  return (
    <React.StrictMode>
      <div
        className={`${styles["segmented-controls"]} ${classNames?.wrapper}`}
        onChange={onChangeValue}
        style={{
          // @ts-ignore
          '--color-slider': swhitcherStyles?.activeBackgroundColorSwitcher,
          '--color-text-active': swhitcherStyles?.activeTextColorSwitcher
        }}>
        <input id="two-1" name="two" type="radio" value="first" onChange={(evt) => {

        }} checked={gender === "first"}
        />
        <label htmlFor="two-1">{content?.first}</label>
        <input onChange={(evt) => {

        }} id="two-2" name="two" value="second" checked={gender === "second"} type="radio" />
        <label htmlFor="two-2">{content?.second}</label>
      </div>
    </React.StrictMode>
  )
}

export default Switcher

type SwitcherProps = {
  content: {
    first: string,
    second: string,
  }
  onChangeState?: (state: 'first' | 'second') => void,
  swhitcherStyles?: {
    activeBackgroundColorSwitcher?: string,
    activeTextColorSwitcher?: string,
  }
  classNames?: {
    wrapper?: string
    label?: string
    input?: string
  }
}