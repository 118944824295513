import React, { useState, FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { FieldInputProps } from 'formik'
import { useNavigate } from 'react-router-dom'

import { formatVogzterName, getAvatar } from '../../../../utils/helpers'
import s from './components/styles.module.scss'
import { Vogzter } from '../../../../types-project/Client'
import userMobx from '../../../../mobx/user'
import { CustomBriefI } from '../../../Castings/NewCasting/subscreens/EditCasting/components/Forms/FormModel/castingFormModel'
import Favourite from '../../../../utils/components/Favourite/favourite'

const CardFavouriteVogzter = ({
  user,
  onclick,
  form,
  _select,
}: SectionFormProps) => {
  const brand = userMobx.brand.data
  const changeStatusFavourite = () => {
    if (!form) {
      if (brand?.favourites?.includes(user._id)) {
        userMobx.deleteFavouriteVogzters(user._id)
      } else {
        userMobx.addFavouriteVogzters(user._id)
      }
    }
  }

  const navigate = useNavigate()
  // translation
  const { t } = useTranslation()
  return (
    <div
      className={`${form ? s['card-favourite-bord'] : s['card-favourite']} ${
        _select ? s['active'] : ''
      } `}
    >
      <div className={s['card-header']}>
        <Favourite form={form} user={user}/>
        {/* <button
          type="button"
          onClick={changeStatusFavourite}
          className={` ${form ? s['favourite-cursor-none'] : ''} ${
            s['favourite']
          }`}
        >
          <i
            className={`fa-solid fa-heart ${
              brand?.favourites.includes(user._id) ? s['is-favourite']:""
            }`}
          />
        </button> */}
      </div>
      <div className={s['card-body']}>
        <img
          className={s['img-vogzter']}
          src={getAvatar(user!?.profile.gender, user.profile.picture?.source)}
          alt="Vogz"
        />
        <p className={s['name-vogzter']}>
          {formatVogzterName(user.first_name, user.last_name)}
        </p>
        {/* {user.certification && (
          <p className={s['certify-vogzter']}>
            {t('favorite:certified')} &nbsp;
            <img src="../assets/svgs/certify.svg" alt="V.ogz" />
          </p>
        )} */}
      </div>
      <div className={s['card-footer']}>
        <button
          type="button"
          onClick={
            form
              ? onclick
              : () => navigate(`/dashboard/profil-vogzter/${user._id}`)
          }
        >
          {form ? (
            _select ? (
              <i className="fa-solid fa-circle-check" />
            ) : (
              'Selectionner'
            )
          ) : (
            t('favorite:gallery')
          )}
        </button>
      </div>
    </div>
  )
}
interface SectionFormProps {
  user: Vogzter
  onclick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  form?: boolean
  _select?: boolean
}

CardFavouriteVogzter.defaultProps = {
  onclick: PropTypes.func,
  form: false,
  _select: false,
}

CardFavouriteVogzter.prototype = {
  user: PropTypes.object,
}
export default observer(CardFavouriteVogzter)
