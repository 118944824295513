import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { useTranslation } from 'react-i18next'
import { Navigation, Scrollbar, Manipulation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Loading from 'react-loading'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'

import Btn1 from '../../../../../utils/components/Button/Btn1'
import styles from '../register-process.module.scss'
import userMobx from '../../../../../mobx/user'
import modal from '../../../../../mobx/modal'
import { Plan } from '../../../../../types-project/Client'

interface PlansListProps {
  setOpenBooking: Dispatch<SetStateAction<boolean>>
  setSwiperIndex?: Dispatch<SetStateAction<number>>
  displayNavBubble?: (e: number) => void
  setStep?: (e: number) => void
  displayHeader?: boolean
  paymentRedirect: string
}

const PlansList: FC<PlansListProps> = ({
  setOpenBooking,
  setSwiperIndex,
  displayNavBubble,
  setStep,
  displayHeader,
  paymentRedirect,
}) => {
  const { t } = useTranslation()
  useEffect(() => {
    userMobx.getPlans()
    const params = new URLSearchParams(window.location.search)
    const card = params.get('card')
    if (card === 'ok') {
      if (setStep) setStep(5)
      setTimeout(() => {
        toast.success(t('register-process:payment-success'), {
          theme: 'colored',
        })
      }, 2000)
    } else if (card === 'ko') {
      toast.error(t('register-process:payment-error'), { theme: 'colored' })
    }
  }, [])

  const { error, data, loading } = userMobx.plans

  const onSuccess = (stripeUrl: string) => {
      window.location.href = stripeUrl
  }

  const onFail = (p:Plan) => {
    modal.toogleAlert('stripe-error', true)
    modal.setOnConfirm(() => {
        userMobx.getTopUpLink(
          p.price,
          {
            ok:`${paymentRedirect}?card=ok`,
            ko:`${paymentRedirect}?card=ko`
          },
          onSuccess,
          ()=>onFail(p),
          p._id
        )
    })
  }

  return (
    <>
      {!error && !loading && data!?.length > 0 ? (
        <div className="relative w-full">
          <div className="flex flex-col items-center h-full justify-center max-w-screnn-xl w-full">
            <div
              className={`${styles['step-6']} flex items-center justify-center content-center flex-row w-full h-full flex-wrap max-w-screen-xl`}
            >
              {!error &&
                !loading &&
                data!?.length > 0 &&
                data!.map((p, i) => (
                  <div
                    style={{
                      backgroundColor: p.background_color,
                      boxShadow: `-10px 10px  ${
                        p.text_color === '#FFFFFF' ? '#000000' : p.text_color
                      }`,
                      border: `1px solid ${p.text_color}`,
                    }}
                    className={styles['step-6-desktop']}
                    key={i}
                  >
                    <br />
                    <div className={styles['bottom-card']}>
                      <div className={styles['part-price']}>
                        <h3 style={{ color: p.text_color }}>{p.price}€</h3>
                        {p.commission ? (
                          <p
                            style={{
                              color:
                                p.text_color !== '#FFFFFF'
                                  ? '#000000'
                                  : p.text_color,
                            }}
                          >
                            +{p.commission}% {t('register-process:comission')}
                          </p>
                        ) : null}
                      </div>
                      <div className="bg-white rounded-3xl">
                        <Btn1
                          btnName={t('register-process:deposit')}
                          type="button"
                          onclick={() => {
                            if (p.payment_method === 'stripe') {
                              userMobx.getTopUpLink(
                                p.price,
                                {
                                  ok:`${paymentRedirect}?card=ok`,
                                  ko:`${paymentRedirect}?card=ko`
                                },
                                onSuccess,
                                ()=>onFail(p),
                                p._id
                              )
                            } else setOpenBooking(true)
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles['img-card']}>
                      <img src={p.image} alt="decorative" />
                      <p>Vogz</p>
                    </div>
                    <br />
                  </div>
                ))}

              <Swiper
                className={`${styles['swiper']} ${styles['swiper-mobile']}`}
                modules={[Navigation, Scrollbar, Manipulation, Autoplay]}
                initialSlide={0}
                longSwipes={false}
                autoplay={{
                  delay: 3000,
                }}
                centeredSlides
                navigation={{
                  prevEl: '.swiper-btn-prev',
                  nextEl: '.swiper-btn-next',
                }}
                onSlideChange={(swiper) => {
                  if (setSwiperIndex) setSwiperIndex(swiper.realIndex)
                }}
                slidesPerView={data?.length}
                spaceBetween={0}
                direction="horizontal"
                pagination={{ clickable: true }}
              >
                {!error &&
                  !loading &&
                  data!?.length > 0 &&
                  data!.map((p, i) => (
                    <SwiperSlide key={i}>
                      {({ isActive }) => (
                        <div
                          className={` ${
                            isActive
                              ? styles['swiper-active']
                              : styles['swiper-inactive']
                          } ${styles['card-pricing']}`}
                          style={{ backgroundColor: p.background_color }}
                          key={i}
                        >
                          <div className={styles['header-card']}>
                            <h3 style={{ color: p.text_color }}>{p.price}€</h3>
                            {p.commission ? (
                              <p
                                style={{
                                  color:
                                    p.text_color !== '#FFFFFF'
                                      ? '#000000'
                                      : p.text_color,
                                }}
                              >
                                +{p.commission}%{' '}
                                {t('register-process:comission')}
                              </p>
                            ) : null}
                          </div>
                          <br />
                          <div className={styles['img-card']}>
                            <img src={p.image} alt="decorative" />
                            <p>Vogz</p>
                          </div>
                          <br />
                          <div className="bg-white rounded-3xl">
                            <Btn1
                              btnName={t('register-process:deposit')}
                              type="button"
                              onclick={() => {
                                if (p.payment_method === 'stripe') {
                                  userMobx.getTopUpLink(
                                    p.price,
                                    {
                                      ok:`${paymentRedirect}?card=ok`,
                                      ko:`${paymentRedirect}?card=ko`
                                    },
                                    onSuccess,
                                    ()=>onFail(p),
                                    p._id
                                  )
                                } else setOpenBooking(true)
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}
PlansList.propTypes = {
  setOpenBooking: PropTypes.any.isRequired,
  setSwiperIndex: PropTypes.any,
  displayNavBubble: PropTypes.any.isRequired,
  setStep: PropTypes.any,
  displayHeader: PropTypes.bool,
  paymentRedirect: PropTypes.string.isRequired,
}
PlansList.defaultProps = {
  setStep: undefined,
  setSwiperIndex: undefined,
  displayHeader: true,
}
export default observer(PlansList)
