/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import Tooltip from 'react-tooltip'
import ReactLoading from 'react-loading'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import s from './components/styles.module.scss'
import ScrollToTop from '../../routing/ScrollToTop'
import SearchBar from '../../utils/components/Ui/inputs/SearchBar'
import BtnDrop from '../../utils/components/Button/BtnDrop'
import CardBriefApplication from '../Castings/MyCasting/components/CardBriefApplication'
import { getAge, searchFilter, sortByCreatedDate, TypeSort } from '../../utils/helpers'
import briefR from '../../mobx/brief'
import BtnBasic from '../../utils/components/Button/BtnBasic'
import { Applicant, ApplicantBa, Brief, MediaBA, MediaBrief, MyCasting } from '../../types-project/Brief'
import CardBriefApplicationSkeleton from './components/CardBriefApplicationSkeleton'
import utils from '../../mobx/utils'
import { BrandI, ClientI, MediaSaved } from '../../types-project/Client'
import userMobx from '../../mobx/user'


type LocalMediaBA = (MediaBA & { brief: (Brief & { brand: BrandI }), user: Applicant })





/**
 * Cette fonction filtre les médias en fonction des options choisies.
 * @param medias Le tableau des médias à filtrer.
 * @param options Les options de filtrage.
 * @param boughtOrSaved Indique si on doit retourner les médias achetés ou sauvegardés.
 * @returns Le tableau des médias filtrés.
 */
function optionPurchases<T extends Array<string>>(medias: LocalMediaBA[], options: T, boughtOrSaved: 'bought' | 'saved'): LocalMediaBA[] {

  // On filtre les médias en fonction des options choisies
  const filteredMedia = medias.filter(md => {


    // Récupérer l'âge du média
    const age = getAge(new Date(md.user?.profile.birthdate).getTime())
    const getValidAge: () => boolean = () => {
      let with_options: boolean = false
      if (options.includes('18-24old'))
        with_options = with_options || age >= 18 && age <= 24
      if (options.includes('25-35old'))
        with_options = with_options || age >= 25 && age <= 35
      if (options.includes('+35old'))
        with_options = with_options || age >= 35
      return with_options
    }

    // Vérifier si le média correspond à la sélection de genre
    const isValidGender = !options.some(opt => ['male', 'female'].includes(opt)) || options.includes(md.user?.profile.gender);
    // Vérifier si le média correspond à la sélection de privé
    const isValidPrivate = !options.includes('private') || (md.brief?.private?.is_private ?? false);
    // Vérifier si le média correspond à la sélection d'âge
    const isValidAge = !options.some(opt => ['18-24old', '25-35old', '+35old'].includes(opt))
      || getValidAge();
    const isValidOrganic = !options.includes('organic') || md.brief?.content_usage === 'organic';
    const isValidAdvertising = !options.includes('ad') || md.brief?.content_usage === 'ad';
    // Retourne vrai si toutes les conditions sont respectées
    return isValidGender && isValidPrivate && isValidAge && isValidOrganic && isValidAdvertising;
  });
  // Filtrer les médias en fonction de l'option "boughtOrSaved"
  if (boughtOrSaved === 'saved') {
    return (
      filteredMedia.filter((md) =>
        userMobx.company.data?.media_saved.map((v: MediaSaved) => v.media?._id ?? '').includes(md?._id ?? '')
      )
    )
  } else {
    return (
      filteredMedia.filter(
        (md) => md.status === 'chosen' && md.brief?.owner as unknown as string === userMobx.company.data?._id)
    )
  }
}


const Purchases = () => {
  // Traduction
  const { t } = useTranslation()

  // État de la recherche dans la barre de recherche
  const [value, setvalue] = useState<string>()

  // État des options sélectionnées dans la barre de filtre
  const [isSelect, setIsSelect] = useState<Array<(typeof btnOption[number]['value'])>>([])

  // État de l'onglet actif (achats ou sauvegardes)
  const [boughtOrSaved, setBoughtOrSaved] = useState<'bought' | 'saved'>('bought')

  // État de la visibilité du menu déroulant de filtre
  const [isVisibleFilter, setVisibleFilter] = useState<boolean>(false)

  // État de l'option de tri sélectionnée dans le menu déroulant de filtre
  const [filterCasting, setfilterCasting] = useState<null | string>(null)

  // Achats triés par date de création et filtres sélectionnés
  const boughtMedias = optionPurchases(
    sortByCreatedDate(
      (briefR.boughtMedia.data as LocalMediaBA[]) ?? [],
      filterCasting === 'createdAt'
        ? TypeSort.date
        : filterCasting === 'oldestDate'
          ? TypeSort.oldestDate
          : TypeSort.basic
    ),
    isSelect,
    boughtOrSaved
  )

  // Options de la barre de filtre
  const btnOption = [
    { key: 1, txt: t("purchases:btnOption.0"), value: 'female' },
    { key: 2, txt: t("purchases:btnOption.1"), value: 'male' },
    { key: 3, txt: t("purchases:btnOption.2"), value: 'public' },
    { key: 4, txt: t("purchases:btnOption.3"), value: 'private' },
    { key: 5, txt: t("purchases:btnOption.4"), value: 'organic' },
    { key: 6, txt: t("purchases:btnOption.5"), value: 'ad' },
    { key: 7, txt: t("purchases:btnOption.6"), value: '18-24old' },
    { key: 8, txt: t("purchases:btnOption.7"), value: '25-35old' },
    { key: 9, txt: t("purchases:btnOption.8"), value: '+35old' },
  ] as const

  // Appel à l'API pour récupérer les achats de l'utilisateur
  useEffect(() => {
    briefR.getAllBoughtMedia()
  }, [])

  // Fonction de filtre de recherche pour la barre de recherche
  const filterSearch = (element: LocalMediaBA[]) => {
    if (!value) {
      return element
    }

    return element.filter(
      ({ brief, user }) => {
        const briefName = brief?.name.toLowerCase()
        const brandName = brief?.brand.name?.toLowerCase()
        const userFirstName = user?.first_name.toLowerCase()
        const userLastName = user?.last_name.toLowerCase()
        return (
          briefName?.includes(value) ||
          brandName?.includes(value) ||
          userFirstName?.includes(value) ||
          userLastName?.includes(value)
        )
      }
    )
  }

  // Options pour le tri des médias achetés
  const options = [
    { key: 1, text: t('purchases:lastest'), value: 'createdAt' },
    { key: 2, text: t('purchases:oldest'), value: 'oldestDate' },
  ]

  const myElement = document.getElementById("page-aside");
  if (filterSearch(boughtMedias)?.length === 0) {
    if (myElement) {
      myElement.scrollTo({ top: 0, behavior: 'smooth' })
      myElement.style.overflowY = "hidden"
    }
  } else {
    if (myElement) myElement.style.overflowY = "auto"
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Mes Achats</title>
      </Helmet>

      <main className={s['purchases-page']}>
        <ScrollToTop />
        <div className={s['all-body']}>
          <div className={s['header']}>
            <div className={s['head-header']}>
              <SearchBar
                onChange={(v) => {
                  setvalue(v.target.value)
                }}
                placeholder={t('mycastings:search')}
              />
              <div className={s['btns-filter']}>
                <BtnDrop
                  onSelect={(e) => {
                    setfilterCasting(e)
                  }}
                  defaultVal={t('mycastings:sort')}
                  options={options}
                />
              </div>
            </div>


            <div className={`${s['head-bar-vogz']} w-full md:justify-center xl:flex-row items-center flex-col mt-9`}>
              <div className='hidden flex-grow-[1] xl:block' />
              <div className='flex flex-grow-2 gap-6 justify-center sm:flex-row flex-col'>
                <button className={`${s['btn-btn_filter']} ${boughtOrSaved === 'bought' ? s['active'] : ''}`} type='button' onClick={() => {
                  setIsSelect(isSelect)
                  setBoughtOrSaved('bought')
                }}>
                  {t('purchases:vogz-bought')}
                  <span />
                  <i className="fa-light fa-cart-shopping" />
                </button>
                <button className={`${s['btn-btn_filter']} ${boughtOrSaved === 'saved' ? s['active'] : ''}`} type='button' onClick={() => {
                  setIsSelect(isSelect)
                  setBoughtOrSaved('saved')
                }}>
                  {t('purchases:vogz-saved')}
                  <span />
                  <i className="fa-solid fa-bookmark" />
                </button>
              </div>
              <div className='flex-[1] w-full relative flex xl:w-max flex-col flex-grow-1 justify-end xl:items-end items-center px-3'>
                <button onClick={() => { setVisibleFilter(!isVisibleFilter) }} className={s['btn-btn_filter_']} type='button'>
                  {t('purchases:filter')}
                  <span />
                  <i className="fa-sharp fa-solid fa-filter" />
                </button>

                <AnimatePresence >
                  {isVisibleFilter && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className='mt-3 xl:absolute block bottom-[-12rem] 2xl:bottom-[-9rem] 2xl:min-w-[848px] xl:bottom-[-13rem] xl:min-w-[648px] w-full xl:w-fit'
                    // className='absolute sm:max-w[12rem] sm:bottom-[-13rem] md:bottom-[-14rem] xl:bottom-[-12rem] bottom-[-28rem] md:min-w-[544px]  min-w-full max-w-full'
                    >
                      <div className={s['sub-header']}>
                        <div className={s['sub-header-scroll']}>
                          {btnOption.map((v) => (
                            <BtnBasic
                              btnName={v.txt}
                              type={`pastel-blue${isSelect?.includes(v.value) ? '-actif' : ''}`}
                              onclick={() => {
                                if (isSelect?.includes(v.value)) {
                                  setIsSelect(isSelect.filter(selected => selected !== v.value))
                                } else {
                                  setIsSelect(isSelect.concat(v.value))
                                }
                              }
                              }
                              typeBtn="button"
                            />
                          ))}
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
          <div className="w-full relative contents">
            {briefR.boughtMedia.loading ? (<div className='flex align-items items-center w-full justify-center pt-6'>
              <ReactLoading
                type="cylon"
                color="#5777FF"
                width={92}
                className="flex items-center"
              />{' '}
            </div>) : (<div className={s['body']}>

              <AnimatePresence>
                {(filterSearch(boughtMedias) ?? [])?.length === 0 ? (
                  <>
                    {Array.from(
                      Array(
                        utils.getWidth > 1425 ? 3 : utils.getWidth > 1200 ? 2 : 1
                      ).keys()
                    ).map((e) => (
                      <CardBriefApplicationSkeleton key={e} />
                    ))}
                    <div className={s['msg-loading']}>
                      <h3
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: t('purchases:msg-loading')
                        }}
                      />
                    </div>
                  </>
                ) : (
                  filterSearch(boughtMedias)?.map((m, i) => {
                    if (boughtOrSaved === 'bought') {
                      return (
                        <CardBriefApplication
                          key={i}
                          purchases
                          briefApplication={m.brief_application as MyCasting}
                          showBookmarked
                          media={m}
                          type="download"
                          isPrivate={false}
                        />
                      )
                    } else {
                      return (
                        <CardBriefApplication
                          key={i}
                          purchases
                          showDownload={false}
                          showBookmarked
                          media={m}
                          type="download"
                          isPrivate={false}
                        />)
                    }
                  }
                  )
                )}
              </AnimatePresence>
            </div>)}
          </div>
        </div>
      </main>

    </>
  )
}


export default observer(Purchases)
