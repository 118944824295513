/* eslint-disable react/no-unused-prop-types */
import { motion } from 'framer-motion'
import { Spinner } from 'grommet'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import Calendar from 'react-calendar'
import { useTranslation } from 'react-i18next'

import calendarStyles from './calendar.module.scss'
import { DateHandler } from './components/DateHandler'
import BtnBack from '../../../utils/components/Button/BtnBack'
import BtnBasic from '../../../utils/components/Button/BtnBasic'
import styles from './styles.module.scss'
import briefR from '../../../mobx/brief'
import { Brief } from '../../../types-project/Brief'
import { CalendarStyleHandler } from './components/CalendarStylesHandler'
import modal from '../../../mobx/modal'
import { ClientI } from '../../../types-project/Client'
import userMobx from '../../../mobx/user'

// import 'semantic-ui-css/components/dropdown.min.js'

function ExtendCasting() {

  const { t } = useTranslation()

  const { id } = useParams<{
    id: string
  }>()

  const navigate = useNavigate()

  useEffect(() => {
    briefR.getBrief(id!)
  }, [])

  const userInfos = userMobx.getUserInfos() as ClientI

  const brief = briefR.brief.data as Brief & { deadline_: string }

  const today = new Date()
  let date = new Date()
  let date2 = new Date()
  if (!briefR.brief.error && !briefR.brief.loading) {
    date = brief?.deadline ? new Date(brief?.deadline) : new Date()
    date2 = brief?.deadline2 ? new Date(brief?.deadline2) : new Date()
  }

  const minDate = new Date(
    briefR.brief.data?.deadline2 ?? new Date().toDateString()
  )
  minDate.setDate(minDate.getDate() + 1)
  const maxDate = new Date(
    today.getFullYear() + 1, today.getMonth(), today.getDate()
  )
  const [btnDisabled, setBtnDisabled] = useState(true)
  // state for selected date
  const [selectedDate, setSelectedDate] = useState(new Date())

  const deadlineBrief = briefR.brief.data?.deadline2 ? new Date(briefR.brief.data?.deadline2) : undefined
  let days: number | undefined;
  // calculating number of days between selected date and deadline
  if (deadlineBrief) days = Math.round(Math.abs((deadlineBrief.getTime() - selectedDate.getTime()) / (1000 * 3600 * 24)))

  // modal for when the client can afford the extension of the brief deadline
  const modalAfford = () => {
    modal.resetData()
    modal.setTitle(t('mycastings:extend-brief.title'))
    modal.setDesc(t('mycastings:extend-brief.description', {days: days ? String(days) : '',
     initialDate: deadlineBrief ? deadlineBrief.toLocaleDateString('fr-FR') : '', finalDate: selectedDate.toLocaleDateString('fr-FR')}))
    modal.setHeader(t('mycastings:extend-brief.header', { days: days ? String(days) : '', price: days ? days! * 10 : 0 }))
    modal.setOnConfirm(() => {
      if (days) briefR.extendBriefDeadline(id!, days * 10)
      setBtnDisabled(true)
    })
    modal.toogleAlert('extend-brief')
  }

  // modal for when the client can't afford the extension of the brief deadline
  const modalNotAfford = () => {
    modal.resetData()
    modal.setTitle(t('mycastings:extend-brief-error.title'))
    modal.setDesc(t('mycastings:extend-brief-error.description'))
    modal.setOnConfirm(() => {
      navigate('/dashboard/credits-vogz')
    })
    modal.toogleAlert('extend-brief')
  }

  const handleclick = () => {
    if ((userInfos !== null && days && briefR.brief.data?.remuneration) &&
      ((userInfos._company.credits - days * 10) - briefR.brief.data?.remuneration >= 0)) {
        modalAfford()
      } else {
        modalNotAfford()
      }
    }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Prolonger mon casting</title>
      </Helmet>

      {briefR.brief.loading ? (
        <Spinner />
      ) : !briefR.brief.error && (

        <>
          <BtnBack
            btnName={'Retour'}
            customAction={() => navigate(-1)}
          />

          <main className={`${styles['body-extend-casting']}`}>

            <motion.header
              initial={{ opacity: 0, x: '-800px' }}
              exit={{ opacity: 0, x: '-800px' }}
              transition={{ duration: 1 }}
              animate={{ x: 0, opacity: 1 }}>
              <h2>
                Vous souhaitez prolonger votre casting ?
              </h2>
              <p>La fin de votre casting approche et vous voulez davantage de contenus ?</p>
              <p>Prolongez votre casting en 2 clics !</p>
            </motion.header>
            <br />
            <br />
            <motion.section
              initial={{ opacity: 0, x: '-800px' }}
              exit={{ opacity: 0, x: '-800px' }}
              transition={{ duration: 1.5 }}
              animate={{ x: 0, opacity: 1 }}>
              <div className='justify-center flex flex-col'>

                <div>
                  <div className={`justify-center flex ${styles['calendar-container']}`}>
                    <div className='flex flex-col justify-evenly'>
                      <Calendar
                        className={`${calendarStyles['react-calendar']} ${calendarStyles['not-clickable']}`}
                        tileClassName={({ activeStartDate, date, view }) =>
                          CalendarStyleHandler.getFinalTileClassName(
                            activeStartDate,
                            date,
                            view,
                            new Date(
                              briefR.brief.data?.deadline2 ??
                              new Date().toDateString()
                            )
                          )
                        }
                        minDate={new Date(
                          briefR.brief.data?.deadline2 ??
                          new Date().toDateString()
                        )}
                        maxDate={maxDate}
                        minDetail="year"
                        formatMonthYear={(locale, date) => (
                          `${DateHandler.monthsAsString[date.getMonth()]
                          } ${date.getFullYear()}`
                        )}
                        prevLabel={CalendarStyleHandler.getPrevLabel()}
                        nextLabel={CalendarStyleHandler.getNextLabel()}
                        navigationLabel={({ label }) =>
                          CalendarStyleHandler.getNavigationLabel(label)
                        }
                        value={
                          new Date(
                            briefR.brief.data?.deadline2 ??
                            new Date().toDateString()
                          )
                        }
                        prev2Label={null}
                        next2Label={null}
                      />
                      <br />
                      <br />
                      <div className={`${styles['date-result-container']}`}>
                        <div>
                          <p>{t('mycastings:extend-casting:initial-deadline')} :</p> <br />
                          <h3>
                            {DateHandler.getFormatedDate(new Date(briefR.brief.data?.deadline2 ?? new Date().toLocaleDateString()))}
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col justify-end'>
                      <table className={`${styles['rotatable-responsive']}`}>
                        <tr>
                          <td>
                            <div>
                              <img
                                src='/assets/svgs/chevron-right.svg'
                                alt='chevron-right' />
                            </div>
                          </td>
                          <td>
                            <img
                              src='/assets/svgs/chevron-right.svg'
                              alt='chevron-right' />
                          </td>
                        </tr>
                      </table>
                      <div className={`w-full ${styles['disappear-responsive']}`}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <img
                          src="/assets/svgs/arrow-right.svg"
                          alt="arrow-right" />
                      </div>

                    </div>

                    <div className='flex flex-col justify-evenly'>
                      <Calendar
                        className={`${calendarStyles['react-calendar']}`}
                        tileClassName={({ activeStartDate, date, view }) =>
                          CalendarStyleHandler.getTileClassName(
                            activeStartDate,
                            date,
                            view,
                            brief
                          )
                        }
                        minDate={minDate}
                        maxDate={maxDate}
                        minDetail="month"
                        formatMonth={(locale, date) =>
                          DateHandler.monthsAsString[date.getMonth()]
                        }
                        formatMonthYear={(locale, date) =>
                          `${DateHandler.monthsAsString[date.getMonth()]
                          } ${date.getFullYear()}`
                        }
                        prevLabel={CalendarStyleHandler.getPrevLabel()}
                        nextLabel={CalendarStyleHandler.getNextLabel()}
                        navigationLabel={({ label }) =>
                          CalendarStyleHandler.getNavigationLabel(label)
                        }
                        prev2Label={null}
                        next2Label={null}
                        defaultValue={
                          new Date(
                            brief?.deadline_ ??
                            new Date(
                              brief?.deadline ?? new Date().toDateString()
                            )
                          )
                        }
                        value={
                          new Date(
                            brief?.deadline_ ??
                            new Date(
                              brief?.deadline ?? new Date().toDateString()
                            )
                          )
                        }
                        onChange={(val: Date) => {
                          setSelectedDate(val)
                          briefR.setBriefDeadline(
                            DateHandler.getFormatedUSDate2(val)
                          )
                        }}
                        onClickDay={() => {
                          setBtnDisabled(false)
                        }}
                      />
                      <br />
                      <br />
                      <div className={`${styles['date-result-container']}`}>
                        <div>
                          <p>{t('mycastings:extend-casting:new-deadline')} :</p> <br />
                          <h3>
                            {DateHandler.getFormatedDate(new Date(brief?.deadline_ ?? new Date(briefR.brief.data?.deadline2 ?? new Date().toLocaleDateString())))}
                          </h3>
                        </div>
                      </div>

                    </div>

                  </div>

                  <br />
                  <br />

                </div>

                <br />
                <br />
                <br />

                <div>
                  <BtnBasic
                    disabled={btnDisabled}
                    btnName='Confirmer la date'
                    iconAfter={
                      <i className='fa-solid fa-circle-check ml-2' />
                    }
                    type='inverted-sky-violet'
                    typeBtn='button'
                    onclick={handleclick} />
                </div>

              </div>
            </motion.section>

          </main>
        </>
      )}
    </>

  )
}

export default observer(ExtendCasting)