import React, { FC, useEffect, useState } from "react"
import { motion } from "framer-motion"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import Modal from 'react-modal'
import Loading from "react-loading"
import modal from "../../../../mobx/modal"
import utilsMobx from "../../../../mobx/utils"
import SubTitle1 from "../../Headings/SubTitle1"
import styles from "./styles.module.scss"
import briefR from "../../../../mobx/brief"
import VogzterPicture from "../../../../pages/Favorites-vogzters/Profil-vogzters/components/VogzterPicture"
import { formatVogzterName } from "../../../helpers"

const ModalEnroll: FC = () => {
  const { t } = useTranslation()
  const customStyles: Modal.Styles = {
    content:{
      top: '50%',
      left: '50%',
      right: 'auto',
      inset: 0,
      transform: 'initial',
      bottom: 'auto',
      height: '100%',
      transition: '1s',
      background: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      backdropFilter: ' blur(5px)',
      marginLeft: utilsMobx.getSideBarWidth,
    },
    overlay: {
      background: 'none'
    }
  }

  const briefApEnroll = briefR.briefApplication?.data?.filter((ba) => ba.status === 'enrolled')

  return (
      <>
        <Modal
          className={styles["modal-enroll"]}
          isOpen={modal.modalEnroll.visible}
          style={customStyles}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          ariaHideApp={false}
          closeTimeoutMS={500}
          onRequestClose={() => {
            modal.toogleModalEnroll(false)
          }}
        >
          <motion.div
            className={styles["child-modal-enroll"]}
            initial={{ opacity: 0, y: '800px' }}
            exit={{ opacity: 0, y: '800px' }}
            transition={{ duration: 0.5 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div className={styles["header-modal"]}>
              <span role="img" aria-label="flamme" >🔥</span>
              <button className= {styles['close-btn']}
                type="button"
                onClick={() => {
                  modal.toogleModalEnroll(false)
                }}
              >
                <i className={`fa-sharp fa-solid fa-xmark`} />
              </button>
            </div>
            { !briefR.briefApplication?.error && !briefR.briefApplication?.loading ?
              <div className={styles["main-content"]}>
                <h3>
                  {t('mycastings:modal-enroll:title')}
                </h3>
                <SubTitle1 
                  className={styles['desc']} 
                  text={t('mycastings:modal-enroll:desc')} 
                  dangerouslySetInnerHTML
                />
                <div className={styles['enroll-list']}>
                  {briefApEnroll?.map((ba) => (
                    <div className={styles['enroll-item']} key={ba._id}>
                      <VogzterPicture
                        size={79}
                        href={`/dashboard/profil-vogzter/${ba.applicant._id}`}
                        gender={ba.applicant.profile.gender}
                        imgSource={ba.applicant.profile?.picture?.source}
                        showCertified={ba.applicant.certified}
                        lastActivity={ba.applicant.last_activity}
                      />
                      <h4 className={styles['enroll-item__name']}>{formatVogzterName(ba.applicant.first_name, ba.applicant.last_name)}</h4>
                      <a className={styles['enroll-item__info']} href={`/dashboard/profil-vogzter/${ba.applicant._id}`}> {t('mycastings:view-profile')}</a>
                    </div>
                  ))}
                </div>
              </div>
              : <Loading/>
            }
          </motion.div>
        </Modal>
      </>
  )
}

export default observer(ModalEnroll)