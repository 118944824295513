import { useDispatch, useSelector } from 'react-redux';
import React, { ReactNode, useEffect } from 'react'
import { isIE, browserName } from 'react-device-detect';
import { Navigate, Outlet, Route, RouteProps, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading'
import { CardText, Card } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from "prop-types"
import Cookies from 'js-cookie';
import userMobx from '../mobx/user';
import { ClientI } from '../types-project/Client';

import briefR from '../mobx/brief';
import modal from '../mobx/modal';
import PageNotAvailable from '../utils/components/Modals/ModalDefault/component/PageBrowserNotAvailable/page-notavailable';

const adminExclusive = [
  '/dashboard/brands',
  '/dashboard/documents',
  '/dashboard/new-castings'
]

const hasPaymentMethodExclusive = [
  null
]

/* variable to check if the page PageNotAvailable has been seen or not from cookies
  * if not seen, we show the modal
  * if seen, we don't show the modal
*/

function AdminRoute({ to: to, routes, ...rest }: AdminPROPS) {
  
  useEffect(() => {
    const browserNotCompatibleSeen = Cookies.get('browserNotCompatibleSeen')
    userMobx.getMe()
    if (browserName === "Safari" && !browserNotCompatibleSeen) {
      modal.toogleAlert('default', true, <PageNotAvailable />)
    }
  }, [])

  const { data, loading, error } = userMobx.me

  const location = useLocation()

  const isAllowedInPage = () => {
    let isAllowed: boolean = true

    if (data?.company_role === 'guest') {
      adminExclusive.forEach((exclusivePath) => {
        if (location.pathname === exclusivePath) {
          isAllowed = false;
        }
      })
    }

    if (!userMobx.hasPaymentMethod()) {
      hasPaymentMethodExclusive.forEach((exclusivePath) => {
        if (location.pathname === exclusivePath) {
          isAllowed = false;
        }
      })
    }

    return isAllowed;
  }
  const pathsToCheck = [
    '/dashboard/profil-vogzter/',
    '/dashboard/new-deal/',
    '/dashboard/premium'
  ];
  return (
    <>
      {loading ?
        (<div className='loading-container'>
          <h3 className='text-loading'> Merci de patienter...</h3>
        </div>) :
        (error === null && (typeof data === 'object' && data !== null) && (Object.keys(data as ClientI) ?? []).length > 0) ? (
          (isAllowedInPage() && routes !== undefined) ?
            routes(data)
            : (
              <>
                <Navigate to="/404" />
              </>
            )
        ) : (
          // if not user connceted and if in url I have as argument include '/dashboard/profil-vogzter/' if yes I redirect to profil-vogzter not connected
          <>
            {pathsToCheck.some(path => location.pathname.includes(path)) 
              ?
              <Navigate to={`${location.pathname.replace('/dashboard', '')}${location.search}`} />
              :
              <Navigate to="/login" />
            }

          </>
        )
      }
    </>
  );
}

AdminRoute.propTypes = {
  to: PropTypes.string,
  routes: PropTypes.node
};

AdminRoute.defaultProps = {
  to: "",
  routes: undefined
};

interface AdminPROPS extends RouteProps {
  to?: `/${string}`;
  routes?: (client: ClientI) => ReactNode
}

export default observer(AdminRoute)
