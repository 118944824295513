/* eslint-disable no-restricted-globals */
import Cookies from 'js-cookie'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { createRef, FC, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import userMobx from '../../../mobx/user'
import brands from '../../../pages/brands'
import { BrandI, ClientI, TypeClient } from '../../../types-project/Client'

import styles from './components/styles.module.scss'
import socketServiceInstance from '../../../sockets'

// 
interface BrandSwitcherProps {
  dntComesFromBriefList?: boolean
  className?: string
  classNames?: {
    brandList: string
  }
}


/**
 * @param {BrandSwitcher} dntComesFromBriefList Pour ne pas rediriger lorsque le brandswicher n'est pas utilisé sur la page de la liste des briefs.
 */
const BrandSwitcher: FC<BrandSwitcherProps> = ({ dntComesFromBriefList, className, classNames }) => {
  const [selected, setSelected] = useState<BrandI | undefined>(undefined)
  const [show, setShow] = useState<boolean>(false)
  const listener = () =>
    document.addEventListener('mousedown', (event) => {
      if (event.target === null || !(event.target instanceof HTMLElement)) return
      if (!menuRef.current?.contains(event.target)) {
        setShow(false)
      }
    })

  const { t } = useTranslation()

  const menuRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setSelected(userMobx.getBrandSession() ?? {})
    const userInfos = userMobx.getUserInfos() as ClientI
    if (userInfos._company.type === TypeClient.AGENCY)
      userMobx.selectBrand(userMobx.getBrandSession() ?? {})
    else
      userMobx.selectBrand(userInfos.brand!)

    listener()
    userMobx.getBrands()

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [])
  useEffect(() => {
    const userInfos = userMobx.getUserInfos() as ClientI
    if (userInfos._company.type === TypeClient.AGENCY && (userMobx.allBrands.data?.length ?? 0) > 0)
      userMobx.allBrands.data?.forEach((brand) => {
        socketServiceInstance.emit(`brand`, brand._id)
      })
  }, [userMobx.allBrands.data])
  useMemo(() => {
    setSelected(userMobx.getBrandSession() ?? {})
  }, [userMobx.brandSession.data])
  const handleClick = () => {
    setShow(!show)
  }

  const navigate = useNavigate()

  const BrandList = {
    transform: !show ? 'translateY(-250%)' : 'translateY(0)',
    opacity: !show ? 0 : 1,
    transition: 'transform .2s ease-in-out, opacity .6s linear',
  }

  return (
    <div
      ref={menuRef}
      className={
        `${styles[userMobx.me.data?._company?.type === TypeClient.AGENCY ? 'brandswitcher' : 'notAgency']} ${className}`
      }
      onClick={handleClick}
    >
      <div className={styles['logo']}>
        <div className="flex justify-center items-center gap-2">
          {
            selected && (selected.logo || selected.name) ? (
              selected.logo ? (
                <img src={selected.logo} alt="logo" />
              ) : (
                <div className={styles['logo-alt-container']}>
                  <span className={styles['logo-alt']}>{selected?.name?.charAt(0)}</span>
                </div>
              )
            ) : (
              <div className={styles['no-brand-selected']}>
                <span>{t('mycastings:select-brand')}</span>
              </div>
            )
          }
          <i className="fa-solid fa-caret-down" onClick={handleClick} />
        </div>
      </div>
      <div style={BrandList} className={`${styles['BrandList']} ${classNames?.brandList}`}>
        <ul onClick={handleClick}>
          {userMobx.allBrands.data?.slice(0).reverse().map((brand) => (
            <li
              key={brand._id}
              onClick={() => {
                setSelected(brand)
                if (typeof sessionStorage !== undefined) userMobx.setBrandSession(brand)
                else Cookies.set('brand', JSON.stringify(brand))
                if (!dntComesFromBriefList) {
                  navigate(`/dashboard/brands/${brand._id}/briefs`)
                }
                location.reload()
              }}
              className={
                styles[`${selected?._id === brand?._id ? 'active' : ''}`]
              }
            >
              {brand.name}
              {brand.messages_count! > 0 ?
                <span>{brand.messages_count}</span>
                : null
              }
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
BrandSwitcher.propTypes = {
  dntComesFromBriefList: PropTypes.bool,
  className: PropTypes.string,
  classNames: PropTypes.any
}
BrandSwitcher.defaultProps = {
  dntComesFromBriefList: false,
  className: '',
  classNames: {
    brandList: ''
  }
}
export default observer(BrandSwitcher)
