/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable no-restricted-globals */
import { FormikProps, useFormik } from 'formik'
import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { observer } from 'mobx-react'
import styles from './components/style.module.scss'
import userR from '../../../mobx/user'
import { BrandI, Guest } from '../../../types-project/Client'
import briefR from '../../../mobx/brief'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Merci d'entrez votre Nom de marque")
    .min(2, 'Le nom doit être supérieur à 2'),
  info: Yup.object().shape({
    url: Yup.string().url('Url invalide').required("L'url est obligatoire")
  })
})

function ResponsiveGrid(formik: FormikProps<BrandI<Array<string>>>) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <label htmlFor="add_user">Ajouter un invité</label>
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 2, sm: 3, md: 8 }}
        direction={{ sm: 'column', md: 'row' }}
      >
        {formik.values.guests!.map((_, index) => (
          <Grid item xs={2} sm md="auto" key={index}>
            <input
              key={`${index}`}
              id="add_user"
              name="add_mail"
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              title="Email invalide"
              type="email"
              placeholder="Adresse mail de l'invité"
              onChange={(e) => {
                e.preventDefault()
                const copyUsers = [...formik.values.guests!]
                copyUsers[index] = e.target.value
                formik.setValues({
                  ...formik.values,
                  guests: copyUsers as Array<string>,
                })
              }}
              value={_ as string}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

const NewBrand = (props: BrandIP) => {
  const { brand, loading } = props
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const initialValues: BrandI<Array<string>> = {
    name: brand?.name || '',
    info: {
      url: brand?.info?.url || ''
    },
    guests: [''],
  }

  useEffect(() => { }, [brand?._id])

  const askAlertAndDelete = (idGuest: string) => {
    if (confirm('Êtes vous sur de vouloir retirer cet invité?') === true) {
      userR.deleteBrandGuest(id as string, idGuest)
    }
  }

  const formik = useFormik<BrandI<Array<string>>>({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values, { resetForm, setFieldValue }) => {
      const filteredValues = {
        ...values,
        guests: (values.guests as Array<string>)?.slice().filter((v) => v !== ''),
        // .concat(brand!?.guests!?.map((guest: any) => guest!?.email) ?? []),
      }

      if (brand?._id) {
        userR.updateBrand(id as string, filteredValues, () =>
          setFieldValue('guests', [''])
        )
      } else {
        userR.createBrand(filteredValues, () => setFieldValue('guests', ['']))
      }
    },
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Vogz - ${id ? 'Editer' : 'Ajouter'} une marque`}</title>
      </Helmet>
      <div className={styles['new-brand-page']}>
        <main>
          <header>
            <button
              type="button"
              className={`btn-txt`}
              onClick={() => navigate(-1)}
            >
              <i className="fa-regular fa-angle-left" /> Retour
            </button>
          </header>
          <section>
            <h1>{`${id ? 'Editer' : 'Ajouter'} une marque`} </h1>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles['container-input']}>
                <label className="required" htmlFor="name">
                  Nom de la marque
                </label>
                <input
                  id="name"
                  name="name"
                  type="name"
                  placeholder="Votre Marque"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className={formik.errors.name ? styles['error'] : ''}
                />
                {formik.errors.name && (
                  <span className={styles['textdanger']}>
                    {formik.errors.name}
                  </span>
                )}
              </div>

              <div className={styles['container-input']}>
                <label className="required" htmlFor="url_site">
                  URL du site
                </label>
                <input
                  id="url"
                  name="info.url"
                  type="url"
                  placeholder="Vogz.io"
                  onChange={formik.handleChange}
                  value={formik.values.info?.url}
                  // @ts-ignore
                  className={formik.errors.info?.url ? styles['error'] : ''}
                />
                   {/* @ts-ignore */}
                {formik.errors.info?.url && (
                  <span className={styles['textdanger']}>
                     {/* @ts-ignore */}
                    {formik.errors.info?.url}
                  </span>
                )}
              </div>
              <hr />

              {/* <input
                            id="add_user"
                            name="add_mail"
                            type="url"
                            placeholder='Adresse mail de l’utilisateur'
                            onChange={formik.handleChange}
                        // value={formik.values.email}
                        /> */}
              <div
                className={`${styles['container-input']} ${styles['container-input3']}`}
              >
                {formik.values.guests!?.length! > 0 && ResponsiveGrid(formik)}
              </div>

              <button
                type="button"
                onClick={() => {
                  const copyUsers = [...formik.values.guests!]
                  if (copyUsers.length < 6) copyUsers.push('')
                  formik.setValues({
                    ...formik.values,
                    guests: copyUsers as Array<string>,
                  })
                }}
                className={styles['new-user']}
              >
                <span>Nouvel invité</span>{' '}
                <i className="fa-solid fa-person-circle-plus" />{' '}
              </button>

              <button
                disabled={userR.client.loading}
                type="submit"
                className={`btn ${styles['confirm-user']} ${userR.client.loading ? 'disabled':''}`}
              >
                Confirmer
              </button>
            </form>
          </section>
          <br />
          {loading ? (
            <h3>Loading</h3>
          ) : (
            <section className={styles['email-guests-container']}>
              <div className={styles['email-guests']}>
                {brand?.guests!?.map((br, i) => (
                  <div className={styles['email-guest']} key={i}>
                    <span>{br!.email} ✅</span>
                    <div style={{ width: '20px' }} />
                    <button
                      type="button"
                      onClick={() => askAlertAndDelete(br!._id)}
                    >
                      Supprimer
                    </button>
                  </div>
                ))}
              </div>
            </section>
          )}
        </main>
      </div>
    </>
  )
}

type BrandIP = { brand: BrandI<Array<Guest>> | null; loading: boolean }

NewBrand.propTypes = {
  brand: PropTypes.object,
  loading: PropTypes.bool,
}

NewBrand.defaultProps = {
  brand: undefined,
  loading: false,
}

export default observer(NewBrand)