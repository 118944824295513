/* eslint-disable react/prop-types */
import React, { FC, useState, useEffect } from 'react'
// import { motion } from "framer-motion";
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import styles from './components/card-rdv-store.module.scss'
import { MyCasting } from '../../../../../types-project/Brief'

import briefR from '../../../../../mobx/brief'
import { displayAvatar, getDayt, getHour } from '../../../../../utils/helpers'
import { Address } from '../../../../../types-project/Client'

interface CardRdvStoreProps {
  id: string
  applicant: MyCasting['applicant']
, rendez_vous: MyCasting['rendez_vous']
}

const CardRdvStore: FC<CardRdvStoreProps> = ({ id, applicant, rendez_vous }) => {

  const [formRdv, setFormRdv] = useState<{
    date:{ 
      day: { value : string, error: null | string },
      hour: { value: string, error: null | string }
    },
    address:{ value: string | undefined, error: null | string }
  }>({ date:{
    day: { value: (_.has(rendez_vous, "date")) ? getDayt(rendez_vous?.date) :'' , error: null },
    hour: { value: (_.has(rendez_vous, "date")) ?getHour(rendez_vous?.date) :'', error: null }
    } , address: {value: rendez_vous?.address, error: null }})
  // translation
  const [edit, setEdit] = useState<"step-1"|"step-2">()
  const { t } = useTranslation()
  const checkFormSend = () => {
    const { address, date } = formRdv
    let addressError: string | null = null
    let dayError: string | null = null
    let hourError: string | null = null
  
    if (!address.value) {
      addressError = t('mycastings:required-fields')
    }
  
    if (!date.day.value || date.day.value.toString() === 'NaN') {
      dayError = t('mycastings:required-fields')
    }
  
    if (!date.hour.value || date.hour.value?.toString() === 'NaN' ) {
      hourError = t('mycastings:required-fields')
    }
    setFormRdv(prevState => ({
      ...prevState,
      date: {
        ...prevState.date,
        day: { ...prevState.date.day, error: dayError },
        hour: { ...prevState.date.hour, error: hourError }
      },
      address: { ...prevState.address, error: addressError }
    }))
    if (!addressError && !dayError && !hourError) {
      const dateStr = `${date.day.value} ${date.hour.value}`
      if (edit === "step-2"){
        briefR.editRdv(id, address.value!, new Date(dateStr))
        setEdit("step-1")
      }
      else
        briefR.confirmRdv(id, address.value!, new Date(dateStr))
    }
  }
  useEffect(() => {
    if (rendez_vous && rendez_vous.address && rendez_vous.date) {
      setEdit("step-1")
    }
  }, [rendez_vous])
  
  const navigate = useNavigate()
  return (
    <article className={styles['card-rdv-store']}>
      <aside>
        {displayAvatar(
          applicant?.profile.gender,
          applicant?.profile?.picture?.source,
          `briefapplication/${id}`
        )}
        <br />
        <button
          type="button"
          onClick={() => navigate(`/dashboard/castings/${briefR.brief.data?._id}/briefapplication/${id}`)}
          className={`btn`}
        >
          { t('mycastings:view-profile')}
        </button>
      </aside>

      <aside>
        {rendez_vous?.date && (
          <span className={styles['sent-tag']}>
            {t('mycastings:rdv-confirmed')}{' '}
            <i className="fa-solid fa-circle-check" />
          </span>
        )}
        <div>
          <h4>
            {applicant?.first_name} {applicant?.last_name}
          </h4>

          <div>
            <div className={styles['input-rdv']}>
                <h5>{t('mycastings:date-rdv')}</h5>
                <div className={styles['card-input-follow']}>
                  <input
                    value={formRdv.date.day.value}
                    disabled={briefR.reqConfirmStep2.loading || (edit === "step-1" && rendez_vous && rendez_vous?.date !== undefined && rendez_vous?.address !== undefined)}
                    type='date'
                    min={new Date().toISOString().substring(0, 10)}
                    onChange={(val) =>{
                      setFormRdv({ ...formRdv, date:{...formRdv.date, day: { value : val.target.value ,error: null}} })
                      }
                    }
                    placeholder={t('mycastings:date-rdv-placeholder')}
                  />
                  {formRdv.date.day.error && (
                    <span className="error">{formRdv.date.day.error}</span>
                  )}
                </div>
            </div>
            <div className={styles['input-rdv']}>
              <h5>{t('mycastings:hour-rdv')}</h5>
              <div className={styles['card-input-follow']}>
                <input
                  value={formRdv.date.hour.value!}
                  type='time'
                  disabled={briefR.reqConfirmStep2.loading || (edit === "step-1" && rendez_vous && rendez_vous?.date !== undefined && rendez_vous?.address !== undefined)}
                  onChange={(val) =>
                    setFormRdv({ ...formRdv, date:{...formRdv.date, hour: {value: val.target.value, error: null}} })
                  }
                  placeholder={t('mycastings:hour-rdv-placeholder')}
                />
                {formRdv.date.hour.error && (
                  <span className="error">{formRdv.date.hour.error}</span>
                )}
              </div>
            </div>
          </div>
          <div>
            <h5>{t('mycastings:location-rdv')}</h5>
            <div className={styles['card-input-follow']}>
              <select
                disabled={briefR.reqConfirmStep2.loading || (edit === "step-1" && rendez_vous && rendez_vous?.date !== undefined && rendez_vous?.address !== undefined)}
                onChange={(val) =>{
                  setFormRdv({ ...formRdv, address: {value: val.target.value, error: null} })}
                }
                placeholder={t('mycastings:address-rdv-placeholder')}
              >
                <option value={undefined}>{t('mycastings:address-rdv-placeholder')}</option>
                { briefR.brief.data?.on_spot?.addresses?.map((address) => (
                  <option selected={ (address as Address)._id === formRdv.address.value ? true : false ?? false } value={(address as Address)._id}>{(address as Address).address}</option>
                ))}
              </select>
              {formRdv.address.error && (
                <span className="error">{formRdv.address.error}</span>
              )}
            </div>
          </div>
          <div>
            <button
              type="button"
              disabled={briefR.reqConfirmStep2.loading}
              onClick={() => {
                if((formRdv.date.day.value.length > 0 && formRdv.date.hour.value.length > 0 && formRdv.address.value && !rendez_vous) || edit === "step-2" )
                  checkFormSend()
                if(rendez_vous && edit === "step-1")
                  setEdit("step-2")
              }}
              className={`btn !w-full !m-0 !max-w-none ${ rendez_vous ? styles['btn-border'] : '' }`}
            >
              {edit === "step-1" ? t('mycastings:edit-rdv') : t('mycastings:confirm-rdv')}
            </button>
          </div>
        </div>
      </aside>
    </article>
  )
}

export default observer(CardRdvStore)
