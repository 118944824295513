import React, { FC, useEffect, useState } from 'react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Navigation, Scrollbar, Manipulation, Autoplay, Pagination, EffectCards } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import BtnBack from '../../../utils/components/Button/BtnBack'
import BtnBasic from '../../../utils/components/Button/BtnBasic'
import Btn1 from '../../../utils/components/Button/Btn1'
import styles from './components/register-process.module.scss'
import SlideStepVogz from './components/SlideStepVogz'
import userMobx from '../../../mobx/user'
import utilsMobx from '../../../mobx/utils'
import Booking from './components/Booking'
import { ClientI, TypeClient } from '../../../types-project/Client'
import Premium from '../../Premium'

interface RegisterProcessProps {
  onlyPricing?: boolean
}

const RegisterProcess: FC<RegisterProcessProps> = ({ onlyPricing }) => {
  const [step, setStep] = useState(1)
  const [openBooking, setOpenBooking] = useState(false)
  const [swiperIndex, setSwiperIndex] = useState(1)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const displayNavBubble = (nbSlide: number) => {
    const tmpArr = []
    for (let i = 0; i < nbSlide; i += 1) {
      tmpArr.push(i)
    }

    return tmpArr.map((value, index) => {
      return (
        <i
          className={`${styles['swiper-navigation-bubble']} ${swiperIndex === index && styles['swiper-navigation-bubble__active']
            }`}
        />
      )
    })
  }

  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const userInfos = userMobx.getUserInfos() as ClientI  
  const isAgency = userInfos !== null && userInfos._company.type === TypeClient.AGENCY
  useEffect(() => {
    if (utilsMobx.getWidth <= 600) setIsMobileDevice(true)
    else setIsMobileDevice(false)
    if (userInfos !== null && step <= 3) {
      setStep(3)
    }
  }, [userInfos])

  const step1 = () => (
    <div className={styles['step-1-wrapper']}>
      <div className={styles['step-1-video-wrapper']}>
        <div className={styles['step-1-bg']}>
          <></>
        </div>
        <div className={styles['step-1']}>
          <video
            id="video-home"
            loop
            autoPlay
            controls
            preload='auto'
            muted
            playsInline
            webkit-playsinline
            src="https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Site_web/video_home/Vide%CC%81o%20onboarding%20portail%20marque%20alan.mp4"
          />
        </div>
      </div>
      <div className={styles['icon-wrapper']}>
        <img src="/assets/svgs/play.svg" alt="" />
      </div>
    </div>
  )

  const step2 = () => (
    <div className={styles['step-2']}>
      <div className={styles['step-2-bottom']}>
        <div className={`w-5/6 h-full ${styles['step-2-bottom-child-mobile']}`}>
          <Swiper
            className={styles['swiper']}
            modules={[Navigation, Scrollbar, Manipulation, Autoplay, Pagination, EffectCards]}
            effect='coverflow'
            initialSlide={0}
            autoplay={{
              delay: 8000,
            }}
            centeredSlides
            onSlideChange={(swiper) => {
              setSwiperIndex(swiper.realIndex)
            }}
            slidesPerView={3}
            spaceBetween={0}
            direction="horizontal"
            pagination={{ clickable: true }}
          >
            <SwiperSlide>
              {({ isActive }) => (
                <SlideStepVogz
                  className={`${isActive
                    ? styles['swiper-active']
                    : styles['swiper-inactive']
                    } ${styles['swiper-slide-box']}`}
                  step={1}
                />
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({ isActive }) => (
                <SlideStepVogz
                  className={`${isActive
                    ? styles['swiper-active']
                    : styles['swiper-inactive']
                    } ${styles['swiper-slide-box']}`}
                  step={2}
                />
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({ isActive }) => (
                <SlideStepVogz
                  className={`${isActive
                    ? styles['swiper-active']
                    : styles['swiper-inactive']
                    } ${styles['swiper-slide-box']}`}
                  step={3}
                />
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({ isActive }) => (
                <SlideStepVogz
                  className={`${isActive
                    ? styles['swiper-active']
                    : styles['swiper-inactive']
                    } ${styles['swiper-slide-box']}`}
                  step={4}
                />
              )}
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={`${styles['swiper-btn-mobile-container']} mb-3 mt-5`}>
          <button
            type="button"
            className={`swiper-btn-prev ${styles['swiper-btn-mobile']}`}
          >
            <img src="/assets/svgs/arrow-left-swiper.svg" alt="" />
          </button>
          <div>{displayNavBubble(5)}</div>
          <button
            type="button"
            className={`swiper-btn-next ${styles['swiper-btn-mobile']}`}
          >
            <img src="/assets/svgs/arrow-right-swiper.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  )

  /*
   **************
   * New step 3 *
   **************
   */

  const step3 = () => {
    return (
      <div className={styles['step-3']}>
        <div className={styles['element-child']}>
          <div className={styles['step-3-tab-desktop']}>
            <div className={styles['step-3-tab-desktop-left']}>
              {isAgency &&
                <>
                  <div className={styles['step-3-element-tab']}>
                    <div className={`bg-[#E5E0FF] border-2 border-[#DBD3FF] ${styles['circle']}`}>
                      <img
                        src="./assets/imgs/icon-account-manager.png"
                        alt="icon-account-manager"
                      />
                    </div>
                    <div className={styles['text-tab']}>
                      <h3>{t('register-process:step-3.card-8.title')}</h3>
                      <p className=" flex flex-nowrap justify-between">
                        {t('register-process:step-3.card-8.desc')}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className={styles['step-3-element-tab']}>
                    <div className={`bg-[#FFE4CC] border-2 border-[#FFCEA0] ${styles['circle']}`}>
                      <img
                        src="/assets/imgs/icon-watermark.png"
                        alt="icon-watermark"
                      />
                    </div>
                    <div className={styles['text-tab']}>
                      <h3>{t('register-process:step-3.card-9.title')}</h3>
                      <p className=" flex flex-nowrap justify-between">
                        {t('register-process:step-3.card-9.desc')}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className={styles['step-3-element-tab']}>
                    <div className={`bg-[#FFFFFF] border-2 border-[#F4FFFB] ${styles['circle']}`}>
                      <img
                        src="./assets/imgs/icon-multimarques.png"
                        alt="icon-multimarques"
                      />
                    </div>
                    <div className={styles['text-tab']}>
                      <h3>{t('register-process:step-3.card-10.title')}</h3>
                      <p className=" flex flex-nowrap justify-between">
                        {t('register-process:step-3.card-10.desc')}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className={styles['step-3-element-tab']}>
                    <div className={`bg-[#FADCFF] border-2 border-[#F7C5FF] ${styles['circle']}`}>
                      <img
                        src="./assets/imgs/icon-gestion-account.png"
                        alt="icon-gestion-account"
                      />
                    </div>
                    <div className={styles['text-tab']}>
                      <h3>{t('register-process:step-3.card-11.title')}</h3>
                      <p className=" flex flex-nowrap justify-between">
                        {t('register-process:step-3.card-11.desc')}
                      </p>
                    </div>
                  </div>
                  <hr />
                </>
              }
              <div className={styles['step-3-element-tab']}>
                <div className={`bg-[#FCFFEA] border-2 border-[#E7F0B2] ${styles['circle']}`}>
                  <img
                    src="./assets/imgs/icon-msg.png"
                    alt="icon-customer-success-management"
                  />
                </div>
                <div className={styles['text-tab']}>
                  <h3>{t('register-process:step-3.card-5.title')}</h3>
                  <p className=" flex flex-nowrap justify-between">
                    {t('register-process:step-3.card-5.desc')}
                  </p>
                </div>
              </div>
              <hr />
              <div className={styles['step-3-element-tab']}>
                <div className={`bg-[#E1F2FF] border-2 border-[#CCE9FF] ${styles['circle']}`}>
                  <img src="./assets/imgs/icon-on-spot.png" alt="icon-on-spot" />
                </div>
                <div className={styles['text-tab']}>
                  <h3>{t('register-process:step-3.card-1.title')}</h3>
                  <p className=" flex flex-nowrap justify-between">
                    {t('register-process:step-3.card-1.desc')}
                  </p>
                </div>
              </div>
              {!isAgency &&
                <>
                  <div className={styles['step-3-element-tab']}>
                    <div className={`bg-[#FAF1FF] border-2 border-[#F5E2FF] ${styles['circle']}`}>
                      <img src="./assets/imgs/icon-collab-plus.png" alt="icon-collab-plus" />
                    </div>
                    <div className={styles['text-tab']}>
                      <h3>{t('register-process:step-3.card-2.title')}</h3>
                      <p className=" flex flex-nowrap justify-between">
                        {t('register-process:step-3.card-2.desc')}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className={styles['step-3-element-tab']}>
                    <div className={`bg-[#BCFFCF] border-2 border-[#8BFFAC] ${styles['circle']}`}>
                      <img src="./assets/imgs/icon-check.png" alt="icon-check" />
                    </div>
                    <div className={styles['text-tab']}>
                      <h3>{t('register-process:step-3.card-3.title')}</h3>
                      <p className=" flex flex-nowrap justify-between">
                        {t('register-process:step-3.card-3.desc')}
                      </p>
                    </div>
                  </div>
                </>
              }
              <div className={styles['absolute-tab']}>
                <span role="img" aria-label="check">
                  <i className="fa-sharp fa-solid fa-check" />
                </span>
                <hr />
                <span role="img" aria-label="check">
                  <i className="fa-sharp fa-solid fa-check" />
                </span>
                <hr />
                <span role="img" aria-label="check">
                  <i className="fa-sharp fa-solid fa-check" />
                </span>
                <hr />
                <span role="img" aria-label="check">
                  <i className="fa-sharp fa-solid fa-check" />
                </span>
                {
                  isAgency &&
                  <>
                    <hr />
                    <span role="img" aria-label="check">
                      <i className="fa-sharp fa-solid fa-check" />
                    </span>
                    <hr />
                    <span role="img" aria-label="check">
                      <i className="fa-sharp fa-solid fa-check" />
                    </span>
                  </>
                }
              </div>
            </div>
            <div className={styles['step-3-tab-desktop-right']}>
              {isAgency &&
                <>
                  <div className={styles['step-3-element-tab']}>
                    <div className={`bg-[#FAF1FF] border-2 border-[#F5E2FF] ${styles['circle']}`}>
                      <img src="./assets/imgs/icon-collab-plus.png" alt="icon-collab-plus" />
                    </div>
                    <div className={styles['text-tab']}>
                      <h3>{t('register-process:step-3.card-2.title')}</h3>
                      <p className=" flex flex-nowrap justify-between">
                        {t('register-process:step-3.card-2.desc')}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className={styles['step-3-element-tab']}>
                    <div className={`bg-[#BCFFCF] border-2 border-[#8BFFAC] ${styles['circle']}`}>
                      <img src="./assets/imgs/icon-check.png" alt="icon-check" />
                    </div>
                    <div className={styles['text-tab']}>
                      <h3>{t('register-process:step-3.card-3.title')}</h3>
                      <p className=" flex flex-nowrap justify-between">
                        {t('register-process:step-3.card-3.desc')}
                      </p>
                    </div>
                  </div>
                  <hr />
                </>
              }
              <div className={styles['step-3-element-tab']}>
                <div className={`bg-[#96D0FF] border-2 border-[#55B4FF] ${styles['circle']}`}>
                  <img
                    src="./assets/imgs/icon-cession.png"
                    alt="icon-cession-droit-auteur"
                  />
                </div>
                <div className={styles['text-tab']}>
                  <h3>{t('register-process:step-3.card-4.title')}</h3>
                  <p className=" flex flex-nowrap justify-between">
                    {t('register-process:step-3.card-4.desc')}
                  </p>
                </div>
              </div>
              <hr />
              <div className={styles['step-3-element-tab']}>
                <div className={`bg-[#F1F4FF] border-2 border-[#DDE4FF] ${styles['circle']}`}>
                  <img src="./assets/imgs/icon-cinema.png" alt="icon-cinema" />
                </div>
                <div className={styles['text-tab']}>
                  <h3 dangerouslySetInnerHTML={{ __html: t('register-process:step-3.card-6.title') }} />
                  <p className=" flex flex-nowrap justify-between">
                    {t('register-process:step-3.card-1.desc')}
                  </p>
                </div>
              </div>
              <hr />
              <div className={styles['step-3-element-tab']}>
                <div className={`bg-[#EDEDED] border-2 border-[#C8C7C7] ${styles['circle']}`}>
                  <img
                    src="./assets/imgs/post-prod.png"
                    alt="post-production"
                  />
                </div>
                <div className={styles['text-tab']}>
                  <h3>{t('register-process:step-3.card-7.title')}</h3>
                  <p className=" flex flex-nowrap justify-between">
                    {t('register-process:step-3.card-7.desc')}
                  </p>
                </div>
              </div>
              <div className={styles['absolute-tab']}>
                {
                  isAgency &&
                  <>
                    <span role="img" aria-label="check">
                      <i className="fa-sharp fa-solid fa-check" />
                    </span>
                    <hr />
                    <span role="img" aria-label="check">
                      <i className="fa-sharp fa-solid fa-check" />
                    </span>
                    <hr />
                  </>
                }
                <span role="img" aria-label="check">
                  <i className="fa-sharp fa-solid fa-check" />
                </span>
                <hr />
                <span role="img" aria-label="check">
                  {t('register-process:step-3.card-6.desc-2')}
                </span>
                <hr />
                <span role="img" aria-label="check">
                  {t('register-process:step-3.card-7.desc-2')}
                </span>
              </div>
            </div>
          </div>
          <div className={styles['step-3-tab-mobile']}>
            {isAgency &&
              <div className={`${styles['step-3-tab-mobile']} !m-0 !bg-[#F3F1FF]`}>
                <span className='bg-[#A073FF] text-white absolute'>Exclusif Agence</span>
                <div className={styles['step-3-element-tab']}>
                  <div className={styles['title-mobile']}>
                    <img
                      src="./assets/imgs/icon-account-manager.png"
                      alt="icon-account-manager"
                    />
                    <hr />
                    <h3>{t('register-process:step-3.card-8.title')}</h3>
                  </div>
                  <div className={styles['text-tab']}>
                    <p className=" flex flex-nowrap justify-between">
                      {t('register-process:step-3.card-8.desc')}
                    </p>
                  </div>
                </div>
                <hr />
                <div className={styles['step-3-element-tab']}>
                  <div className={styles['title-mobile']}>
                    <img
                      src="./assets/imgs/icon-watermark.png"
                      alt="icon-watermark"
                    />
                    <hr />
                    <h3>{t('register-process:step-3.card-9.title')}</h3>
                  </div>
                  <div className={styles['text-tab']}>
                    <p className=" flex flex-nowrap justify-between">
                      {t('register-process:step-3.card-9.desc')}
                    </p>
                  </div>
                </div>
                <hr />
                <div className={styles['step-3-element-tab']}>
                  <div className={styles['title-mobile']}>
                    <img
                      src="./assets/imgs/icon-multimarques.png"
                      alt="icon-multimarques"
                    />
                    <hr />
                    <h3>{t('register-process:step-3.card-10.title')}</h3>
                  </div>
                  <div className={styles['text-tab']}>
                    <p className=" flex flex-nowrap justify-between">
                      {t('register-process:step-3.card-10.desc')}
                    </p>
                  </div>
                </div>
                <hr />
                <div className={styles['step-3-element-tab']}>
                  <div className={styles['title-mobile']}>
                    <img
                      src="./assets/imgs/icon-gestion-account.png"
                      alt="icon-gestion-account"
                    />
                    <hr />
                    <h3>{t('register-process:step-3.card-11.title')}</h3>
                  </div>
                  <div className={styles['text-tab']}>
                    <p className=" flex flex-nowrap justify-between">
                      {t('register-process:step-3.card-11.desc')}
                    </p>
                  </div>
                </div>
                <hr />
              </div>
            }
            <div className={styles['step-3-element-tab']}>
              <div className={styles['title-mobile']}>
                <img
                  src="./assets/imgs/icon-msg.png"
                  alt="icon-customer-success-management"
                />
                <hr />
                <h3>{t('register-process:step-3.card-5.title')}</h3>
              </div>
              <div className={styles['text-tab']}>
                <p className=" flex flex-nowrap justify-between">
                  {t('register-process:step-3.card-5.desc')}
                </p>
              </div>
            </div>
            <hr />
            <div className={styles['step-3-element-tab']}>
              <div className={styles['title-mobile']}>
                <img src="./assets/imgs/icon-on-spot.png" alt="icon-on-spot" />
                <hr />
                <h3>{t('register-process:step-3.card-1.title')}</h3>
              </div>
              <div className={styles['text-tab']}>
                <p className=" flex flex-nowrap justify-between">
                  {t('register-process:step-3.card-1.desc')}
                </p>
              </div>
            </div>
            <hr />
            <div className={styles['step-3-element-tab']}>
              <div className={styles['title-mobile']}>
                <img src="./assets/imgs/icon-collab-plus.png" alt="icon-collab-plus" />
                <hr />
                <h3>{t('register-process:step-3.card-2.title')}</h3>
              </div>
              <div className={styles['text-tab']}>
                <p className=" flex flex-nowrap justify-between">
                  {t('register-process:step-3.card-2.desc')}
                </p>
              </div>
            </div>
            <hr />
            <div className={styles['step-3-element-tab']}>
              <div className={styles['title-mobile']}>
                <img src="./assets/imgs/icon-check.png" alt="icon-check" />
                <hr />
                <h3>{t('register-process:step-3.card-3.title')}</h3>
              </div>
              <div className={styles['text-tab']}>
                <p className=" flex flex-nowrap justify-between">
                  {t('register-process:step-3.card-3.desc')}
                </p>
              </div>
            </div>
            <hr />
            <div className={styles['step-3-element-tab']}>
              <div className={styles['title-mobile']}>
                <img
                  src="./assets/imgs/icon-cession.png"
                  alt="icon-cession-droit-auteur"
                />
                <hr />
                <h3>{t('register-process:step-3.card-4.title')}</h3>
              </div>
              <div className={styles['text-tab']}>
                <p className=" flex flex-nowrap justify-between">
                  {t('register-process:step-3.card-4.desc')}
                </p>
              </div>
            </div>
            <hr />
            <div className={styles['step-3-element-tab']}>
              <div className={styles['title-mobile']}>
                <img src="./assets/imgs/icon-cinema.png" alt="icon-cinema" />
                <hr />
                <h3 dangerouslySetInnerHTML={{ __html: t('register-process:step-3.card-6.title') }} />
              </div>
              <div className={styles['text-tab']}>
                <p className=" flex flex-nowrap justify-between">
                  {t('register-process:step-3.card-1.desc')}
                </p>
              </div>
            </div>
            <hr />
            <div className={styles['step-3-element-tab']}>
              <div className={styles['title-mobile']}>
                <img
                  src="./assets/imgs/post-prod.png"
                  alt="post-production"
                />
                <hr />
                <h3>{t('register-process:step-3.card-7.title')}</h3>
              </div>
              <div className={styles['text-tab']}>
                <p className=" flex flex-nowrap justify-between">
                  {t('register-process:step-3.card-7.desc')}
                </p>
              </div>
            </div>
            <div className={`${isAgency ? ' top-8' : ''} ${styles['absolute-tab']}`}>
              {isAgency &&
                <>
                  <span role="img" aria-label="check">
                    <i className="fa-sharp fa-solid fa-check" />
                  </span>
                  <hr />
                  <span role="img" aria-label="check">
                    <i className="fa-sharp fa-solid fa-check" />
                  </span>
                  <hr />
                  <span role="img" aria-label="check">
                    <i className="fa-sharp fa-solid fa-check" />
                  </span>
                  <hr />
                  <span role="img" aria-label="check">
                    <i className="fa-sharp fa-solid fa-check" />
                  </span>
                  <hr />
                </>
              }
              <span role="img" aria-label="check">
                <i className="fa-sharp fa-solid fa-check" />
              </span>
              <hr />
              <span role="img" aria-label="check">
                <i className="fa-sharp fa-solid fa-check" />
              </span>
              <hr />
              <span role="img" aria-label="check">
                <i className="fa-sharp fa-solid fa-check" />
              </span>
              <hr />
              <span role="img" aria-label="check">
                <i className="fa-sharp fa-solid fa-check" />
              </span>
              <hr />
              <span role="img" aria-label="check">
                <i className="fa-sharp fa-solid fa-check" />
              </span>
              <hr />
              <span role="img" aria-label="check">
                {t('register-process:step-3.card-6.desc-2')}
              </span>
              <hr />
              <span role="img" aria-label="check">
                {t('register-process:step-3.card-7.desc-2')}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const endProcess = () => {
    const vogzterId = JSON.parse(sessionStorage.getItem('vogzter') ?? '{}')
    return (
      <div className={styles['end-process']}>
        <h3>{t('register-process:end-process.title')}</h3>
        <p dangerouslySetInnerHTML={{ __html: Object.keys(vogzterId).length > 0 ? t('register-process:end-process.desc2') : t('register-process:end-process.desc1') }} />
        <Btn1
          btnName={Object.keys(vogzterId).length > 0 ? t('register-process:end-process.btn2') : t('register-process:end-process.btn1')}
          type="button"
          className='!rounded-3xl'
          onclick={() => {
            if (isAgency)
              navigate('/dashboard/brands')
            else if (Object.keys(vogzterId).length > 0) {
              navigate(`/dashboard/profil-vogzter/${vogzterId}`)
            } else
              navigate('/dashboard/profile')
          }}
        />
        <div className={styles['img-end-process']}>
          <img src="/assets/imgs/endprocess.png" alt="decorative" />
        </div>
      </div>
    )
  }
  return (
    <>
      <section className={styles['register-process']}>
        {onlyPricing ? (
          <div className={styles['step-container']}>{step3()}</div>
        ) : (
          <>
            {step > 1 && (
              <div className="self-start ml-10 mt-4 mb-4">
                <BtnBack
                  className={`${styles['btn-back-desktop']} ml-[3vw]`}
                  btnName="Retour"
                  customAction={() => {
                    setStep(step - 1)
                  }}
                />
                <BtnBack
                  className={`${styles['btn-back-mobile']}`}
                  btnName=""
                  customAction={() => {
                    setStep(step - 1)
                  }}
                />
              </div>
            )}
            <div className={`${styles['step-container']} ${step >= 3 ? styles['step-end-process'] : ''}`}>
              {step === 1 ? (
                step1()
              ) : step === 2 ? (
                step2()
              ) : step === 3 ? (
                <Premium classNames={{
                  wrapper: 'w-full'
                }} />
              ) : (
                endProcess()
              )}
            </div>

            <div
              className={`${styles['btn-action']} ${styles['appear-desktop']} mb-4`}
            >
              {step === 1 ? (
                <div
                  className={`${styles['btn-step-1']} ${styles['btn-next-step']}`}
                >
                  <BtnBasic
                    btnName={t('register-process:step-1:lets-go')}
                    type="inverted-sky-violet"
                    onclick={() => {
                      setStep(step + 1)
                    }}
                  />
                </div>
              ) : [2, 3].indexOf(step) > -1 ? (
                <div className={styles['btn-next-step']}>
                  <BtnBasic
                    btnName={`${step === 3 ? t('register-process:deposit-later') : t('register-process:next')}`}
                    type={`${step === 3 ? "sky-violet-bordered" : "inverted-sky-violet"}`}
                    className={`${step === 3 ? styles['btn-step-4'] : ''}`}
                    onclick={() => {
                      if (step === 2) {
                        navigate('/signup')
                      } else
                        setStep(step + 1)
                    }}
                  />
                </div>
              ) : null}
            </div>
          </>
        )}
        {openBooking ? (
          <Booking setOpenBooking={setOpenBooking} setStep={setStep} />
        ) : null}
      </section>
    </>
  )
}

RegisterProcess.propTypes = {
  onlyPricing: PropTypes.bool,
}

RegisterProcess.defaultProps = {
  onlyPricing: false,
}

export default RegisterProcess
