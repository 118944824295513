// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_brief-type__2fsw9 {\n  display: flex;\n  width: -moz-fit-content;\n  width: fit-content;\n  height: 100%;\n  justify-content: center;\n  margin: 0 4px;\n  padding: 0 10px;\n  border-radius: 3px;\n  line-height: 150%;\n}\n.styles_brief-type__2fsw9.styles_simple__2XQ_W {\n  padding: 0;\n  line-height: 154%;\n}\n.styles_brief-type__2fsw9.styles_casting-plus__3KjMn {\n  background: linear-gradient(93.26deg, #a785f0 0%, #627aff 100%);\n  color: white;\n}\n.styles_brief-type__2fsw9.styles_deal-plus__2eJmz {\n  background: linear-gradient(151.35deg, #FFD806 0%, #FFEC84 100%);\n  color: black;\n}\n.styles_brief-type__2fsw9.styles_on-spot__dKcV9 {\n  background: linear-gradient(151.35deg, #5EDCE4 0%, #33A8FC 100%);\n  color: white;\n}", "",{"version":3,"sources":["webpack://src/pages/Castings/NewCasting/subscreens/EditCasting/components/BadgeBriefType/components/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EAAA,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AACF;AACE;EACE,UAAA;EACA,iBAAA;AACJ;AAEE;EACE,+DAAA;EACA,YAAA;AAAJ;AAGE;EACE,gEAAA;EACA,YAAA;AADJ;AAIE;EACE,gEAAA;EACA,YAAA;AAFJ","sourcesContent":[".brief-type {\n  display: flex;\n  width: fit-content;\n  height: 100%;\n  justify-content: center;\n  margin: 0 4px;\n  padding: 0 10px;\n  border-radius: 3px;\n  line-height: 150%;\n\n  &.simple {\n    padding: 0;\n    line-height: 154%;\n  }\n\n  &.casting-plus {\n    background: linear-gradient(93.26deg, #a785f0 0%, #627aff 100%);\n    color: white;\n  }\n  \n  &.deal-plus {\n    background: linear-gradient(151.35deg, #FFD806 0%, #FFEC84 100%);\n    color: black;\n  }\n  \n  &.on-spot {\n    background: linear-gradient(151.35deg, #5EDCE4 0%, #33A8FC 100%);\n    color: white;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brief-type": "styles_brief-type__2fsw9",
	"simple": "styles_simple__2XQ_W",
	"casting-plus": "styles_casting-plus__3KjMn",
	"deal-plus": "styles_deal-plus__2eJmz",
	"on-spot": "styles_on-spot__dKcV9"
};
export default ___CSS_LOADER_EXPORT___;
