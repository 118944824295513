import React, { FC, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import styles from './components/custom-slider.module.scss'

interface CustomSliderI {
  data: any[]
  renderItem: (item: HTMLElement, index: number) => JSX.Element
  iconsAtBottom?: boolean
  showIcons?: boolean
  classes?: {
    className?: string
    wrapperClassName?: string
    itemWrapperClassName?: string
    itemClassName?: string
    itemWrapperActiveClassName?: string
  }
}

type CustomSliderTypeNoAutoScroll = CustomSliderI & {
  autoScroll?: undefined
  autoScrollDelay?: undefined
}

type CustomSliderTypeAutoScroll = CustomSliderI & {
  autoScroll: boolean
  autoScrollDelay: number
}

type CustomSliderType =
  | CustomSliderTypeAutoScroll
  | CustomSliderTypeNoAutoScroll

const CustomSlider: FC<CustomSliderType> = ({
  data,
  renderItem,
  iconsAtBottom,
  autoScroll,
  autoScrollDelay,
  showIcons,
  classes,
}) => {
  const itemDivs: { id: string, element?: HTMLDivElement }[] = []
  const bubbleRefs: React.RefObject<HTMLElement>[] = []

  const cssClasses = [
    {
      itemClass: styles['custom-slider-item-left'],
      bubbleClass: styles['nav-bubble'],
    },
    {
      itemClass: styles['custom-slider-item-active'],
      itemWrapperCustomClass: classes?.itemWrapperActiveClassName,
      bubbleClass: styles['nav-bubble-dot'],
      bubbleClass2: 'fa-circle-dot',
    },
    {
      itemClass: styles['custom-slider-item-right'],
      bubbleClass: styles['nav-bubble'],
    },
  ]

  /* Used to define autoScroll
  The clearInterval function is used somewhere else to close the function and 
  stop autoscroll when the user manually clicks one of the scroll buttons */
  let scrollIntervalId: ReturnType<typeof setInterval>
  if (autoScroll) {
    scrollIntervalId = setInterval(() => {
      customScroll('right', data, itemDivs, cssClasses, classes, bubbleRefs)
    }, autoScrollDelay)
  }

  useEffect(() => {
    itemDivs.forEach((itemDiv) => {
      itemDiv.element = document.getElementById(itemDiv.id) as HTMLDivElement
    })
    customScroll('none', data, itemDivs, cssClasses, classes, bubbleRefs)
    return () => {
      clearInterval(scrollIntervalId)
    }
  }, [])

  return (
    <div className={`${styles['custom-slider']} ${iconsAtBottom ? 'flex-col' : 'flex-row'} ${classes?.className}`}>
      {!iconsAtBottom && showIcons && data.length > 1 && (
        <button
          className={styles['arrow-btn-left']}
          type='button'
          onClick={() => {
            customScroll('left', data, itemDivs, cssClasses, classes, bubbleRefs)
            if (scrollIntervalId) clearInterval(scrollIntervalId)
          }}
        >
          <i className='fa-light fa-arrow-left-long' />
        </button>
      )}

      {data.length === 0 && <p>[...]</p>}

      <div className={`${styles['custom-slider-wrapper']} ${classes?.wrapperClassName}`}>
        <div className={styles['custom-slider-div']}>
          {data.map((item, index) => {
            const id = uuidv4()
            itemDivs.push({ id: id, element: undefined })
            return (
              <div
                key={index} 
                className={`${styles['custom-slider-item-wrapper']} ${classes?.itemWrapperClassName}`}
              >
                <div
                  id={id}
                  className={`${styles['custom-slider-item']} ${classes?.itemClassName}`}
                >
                  {/* Function passed in props to define how to render each item in the data variable */}
                  {renderItem(item, index)}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {data.length > 1 && (
        <div className='flex flex-row justify-between items-center'>
          {iconsAtBottom && showIcons && (
            <>
              <button
                className={styles['arrow-btn-left']}
                type='button'
                onClick={() => {
                  customScroll('left', data, itemDivs, cssClasses, classes, bubbleRefs)
                  if (scrollIntervalId) clearInterval(scrollIntervalId)
                }}
              >
                <i className='fa-light fa-arrow-left-long' />
              </button>

              <div className='flex flex-row items-center ml-4 mr-4'>
                {itemDivs.map((v, i) => {
                  const ref = useRef<HTMLElement>(null)
                  bubbleRefs.push(ref)
                  return (
                    <i
                      ref={ref}
                      className={`fa-regular fa-circle ${styles['nav-bubble']}`}
                    />
                  )
                })}
              </div>
            </>
          )}

          {showIcons &&
            <button
              className={styles['arrow-btn-right']}
              type='button'
              onClick={() => {
                customScroll('right', data, itemDivs, cssClasses, classes, bubbleRefs)
                if (scrollIntervalId) clearInterval(scrollIntervalId)
              }}
            >
            <i className='fa-light fa-arrow-right-long' />
          </button>
        }
        </div>
      )}
    </div>
  )
}

// This function moves all elements of the array passed in arguments to the left
const moveArrayElementsToLeft = (array: any[]) => {
  const lastElement = array[array.length - 1]
  for (let i = array.length - 1; i > 0; i -= 1) {
    array[i] = array[i - 1]
  }
  array[0] = lastElement
  return array
}

// Same function than moveArrayElementsToLeft but to the right
const moveArrayElementsToRight = (array: any[]) => {
  const firstElement = array[0]
  for (let i = 0; i < array.length - 1; i += 1) {
    array[i] = array[i + 1]
  }
  array[array.length - 1] = firstElement
  return array
}

// This function handles movement of slider items and navBubbles updates
const customScroll = (
  direction: 'left' | 'right' | 'none',
  data: any[],
  itemRefs: { id: string, element?: HTMLDivElement }[],
  cssClasses: any[],
  classes: CustomSliderType['classes'],
  bubbleRefs: React.RefObject<HTMLElement>[]
) => {
  if (data.length === 1) {
    itemRefs[0]?.element?.classList.add(styles['custom-slider-item-active'])
    const parent = itemRefs[0]?.element?.parentElement
    if (classes?.itemWrapperActiveClassName && parent) {
      parent.classList.add(classes.itemWrapperActiveClassName)
    }
    return
  }

  if (direction === 'left') {
    moveArrayElementsToLeft(itemRefs)
    moveArrayElementsToLeft(bubbleRefs)
  } else if (direction === 'right') {
    moveArrayElementsToRight(itemRefs)
    moveArrayElementsToRight(bubbleRefs)
  }

  itemRefs.forEach((value, i) => {
    value?.element?.classList.remove(
      styles['custom-slider-item-left'],
      styles['custom-slider-item-active'],
      styles['custom-slider-item-right']
    )
    bubbleRefs.at(i)?.current?.classList.remove(
        styles['nav-bubble'],
        styles['nav-bubble-dot'],
        'fa-circle-dot'
      )
    const parent = value?.element?.parentElement
    if (classes?.itemWrapperActiveClassName) {
      parent?.classList.remove(cssClasses.at(1).itemWrapperCustomClass)
    }

    if (i > data.length - 1) i = 0
    let itemClassPos = i
    if (itemClassPos >= cssClasses.length) itemClassPos = 0
    value?.element?.classList.add(cssClasses.at(itemClassPos).itemClass)
    if (parent && cssClasses.at(itemClassPos).itemWrapperCustomClass) {
      parent.classList.add(cssClasses.at(itemClassPos).itemWrapperCustomClass)
    }
    bubbleRefs
      .at(i)
      ?.current?.classList.add(
        cssClasses.at(itemClassPos).bubbleClass,
        cssClasses.at(itemClassPos).bubbleClass2
      )
  })
}

CustomSlider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  renderItem: PropTypes.func.isRequired,
  iconsAtBottom: PropTypes.bool,
  autoScroll: PropTypes.any,
  autoScrollDelay: PropTypes.any,
  showIcons: PropTypes.bool,
  classes: PropTypes.any,
}

CustomSlider.defaultProps = {
  iconsAtBottom: false,
  autoScroll: undefined,
  autoScrollDelay: undefined,
  showIcons: true,
  classes: {
    className: '',
    wrapperClassName: '',
    itemWrapperClassName: '',
    itemClassName: '',
    itemWrapperActiveClassName: '',
  },
}

export default CustomSlider
