// import/no-named-as-default-member
import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import s from '../brands.module.scss'
import { BrandI, Guest } from '../../../../types-project/Client'
import userMobx from '../../../../mobx/user'

const BrandsCard: FC<Brands> = ({ first, brand }) => {
  const vogzterId = JSON.parse(sessionStorage.getItem('vogzter') ?? '{}')
  const { t } = useTranslation()

  return (
    <article
      className={`${s['card-brands']}  ${first ? s['card-brands-first'] : ''}`}
      onClick={() => {
        if (!first && brand) {
          userMobx.setBrandSession(brand as BrandI<Guest>)
        }
      }}
    >
      {first ? (
        <Link className={s['card-brands-link']} to="/dashboard/brands/new">
          <button type="button" className={s['btn-add']}>
            <i className="fa-solid fa-plus" />
          </button>
          <span>{t('myprofil:brand-page.add-brand')}</span>
        </Link>
      ) : (
        <>
          <Link
            className={s['card-brands-link']}
            to={Object.keys(vogzterId).length > 0 ? `/dashboard/profil-vogzter/${vogzterId}` :
              `/dashboard/brands/${brand?._id}/briefs`}
          >
            <h2>{brand?.name}</h2>
          </Link>
          <Link
            className={s['card-brands-edit']}
            to={`/dashboard/brands/${brand!._id}/edit`}
          >
            <i className="fa-regular fa-pen-to-square" />
          </Link>
        </>
      )}
    </article>
  )
}
interface Brands {
  first?: boolean
  brand?: BrandI
}
BrandsCard.propTypes = {
  first: PropTypes.bool,
  brand: PropTypes.any,
}
BrandsCard.defaultProps = {
  first: false,
  brand: undefined,
}

export default observer(BrandsCard)
