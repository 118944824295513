/* eslint-disable no-restricted-globals */
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './component/style.module.scss'
import briefR from '../../../../../mobx/brief'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import Title1 from '../../../../../utils/components/Headings/Title1'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import BadgeBriefType from '../EditCasting/components/BadgeBriefType'

function EndedCasting() {
  const { id } = useParams<{
    id: string
  }>()

  useEffect(() => {
    briefR.getBrief(id as string)
  }, [])

  // translation
  const { t } = useTranslation()

  const navigate = useNavigate()

  const briefData = briefR.brief.data
  const briefScope = briefData?.private?.is_private ? 'private' : 'public'
  const briefType = briefData?.on_spot?.is_on_spot ? 'on-spot' : briefData?.with_product ? 'plus' : 'simple'

  const briefPlanning = {
    leftPart: {
      title: (briefScope === 'private' ? '3' : '14').concat(' ').concat(t('common:days')),
      desc: t(`sent-casting:${briefScope === 'private' ? 'validation-phase' : 'recruitment-phase'}`),
      sub: briefScope === 'private' ? '(par le Vogzter)' : undefined
    },
    rightPart: {
      title: (briefType === 'simple' ? '7' : '14').concat(' ').concat(t('common:days')),
      desc: t('sent-casting:selection-phase')
    }
  }

  return (
    <section className={styles['ended-casting']}>

      <Title1 text={t('sent-casting:brief-sent')} size={40} />
      <SubTitle1
        className='max-w-2xl sm:!text-xl text-center leading-normal mt-2'
        text={t('sent-casting:brief-sent-desc')} />

      <div className={styles['primary-content']}>
        <span 
          className='font-hurmeBlack text-3xl'
          role='img'
          aria-label='emoji'
        >
          {t('sent-casting:reminder')}
        </span>
        <span className='flex max-w-[380px] items-center text-[15px] font-light mt-1'>
          {t('sent-casting:reminder-desc-1')}
          <BadgeBriefType 
            briefScope={briefScope}
            briefType={briefType} />
        </span>
        <span className='max-w-sm text-[15px] font-light'>
          {t('sent-casting:reminder-desc-2')}
        </span>
        <div className={styles['brief-planning']}>
          <div>
            <h4>{briefPlanning.leftPart.title}</h4>
            <b>{briefPlanning.leftPart.desc}</b>
            {briefPlanning.leftPart.sub && <span>{briefPlanning.leftPart.sub}</span>}
          </div>
          <img
            className='absolute left-0 right-0 mx-auto top-6'
            src="/assets/svgs/long-arrow-right.svg" 
            alt="" />
          <div>
            <h4>{briefPlanning.rightPart.title}</h4>
            <b>{briefPlanning.rightPart.desc}</b>
          </div>
        </div>
        <b className='max-w-sm text-sm'>
          {t('sent-casting:planning-warning')}
        </b>
      </div>

      <div className='w-80 pb-10'>
        <BtnBasic
          onclick={() => {
            navigate(`/dashboard/castings/${id}/notifications?edit=true`)
          }}
          btnName={t('sent-casting:brief-sent-cta')}
          type="inverted-sky-violet"
        />
      </div>
    </section>
  )
}

export default observer(EndedCasting)
