/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/require-default-props */
import React from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import _ from 'lodash'
import i18n from '../i18n'
import { CastingTypes } from '../../pages/Castings/MyCastings/components/type'
import {
  Applicant,
  ApplicantBa,
  MediaBA,
  MediaCastings,
  MyCasting,
  StatusCasting,
  TypeBrief,
} from '../../types-project/Brief'
import { AllMessages, ClientI, GroupedMessages, Vogzter } from '../../types-project/Client'
import styles from './globals.module.scss'
import userMobx from '../../mobx/user'

export function replaceId(string: string, specificId?: string) {
  if (!specificId) {
    specificId = "";
  }
  string = string.replace(/\/:id/g, `/${specificId}`);
  if (!string.endsWith("/")) {
    string += "/"
  }
  return string;
}

// get user image base on data received
export const getAvatar = (gender: 'male' | 'female', uri?: string) => {
  if (uri) return uri
  else if (gender === 'male') return '/assets/imgs/placeholder-male.png'
  else return '/assets/imgs/placeholder-women.png'
}

export function displayAvatar(
  gender: 'male' | 'female',
  source?: string,
  link?: string
) {
  return (
    <Link
      to={link ?? ''}
      className={`${link === undefined ? 'pointer-events-none' : ''}`}
    >
      <img alt="avatar" src={getAvatar(gender, source)} />
    </Link>
  )
}

interface TypeTag {
  tag: 'vip'
}

/**
 * generate tag based on parameter
 */
export const Tags = ({
  type,
  className,
}: {
  type: TypeBrief
  className?: string
}) => {
  return (
    <div className={`tag tag-${type} ${className}`}>
      <h3>
        {type === 'product-review-unboxing'
          ? 'Test de produit'
          : type === 'choregraphy'
            ? 'Choregraphie'
            : type === 'creativity'
              ? 'Créativité'
              : type === 'testimony'
                ? 'Témoignage'
                : type === 'acting'
                  ? 'Acting'
                  : type === 'vip'
                    ? 'Casting Client'
                    : ''}
      </h3>
    </div>
  )
}

/**
 * countdown from a Date
 */
export const countDown = (deadline: Date) => {
  const deadlineDate = new Date(deadline)
  const day = new Date()
  const time = deadlineDate.getTime() - day.getTime()
  if (time >= 0) {
    const days = Math.floor(time / (1000 * 60 * 60 * 24))
    const heure = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const min = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
    return `${days}j ${heure}h ${min}min`
  }
  return 'Temps écoulé'
}

export const variantPage = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
}

export function startTimer(
  duration: any,
  setTimeMedia: (arg: string) => void,
  callback?: () => void
) {
  let timer = duration
  let minutes: any = 0
  let seconds: any = 0
  const interval = setInterval(() => {
    minutes = parseInt((timer / 60) as any, 10)
    seconds = parseInt((timer % 60) as any, 10)
    minutes = minutes < 10 ? `0${minutes}` : minutes
    seconds = seconds < 10 ? `0${seconds}` : seconds
    setTimeMedia(`${minutes}:${seconds}`)
    // eslint-disable-next-line no-plusplus
    if (--timer < 0) {
      timer = 0
      clearInterval(interval)
      callback!()
    }
  }, 1000)
}

/**
 * Ajouter trois points a la fin d'un text s'il est trop long
 * @param text text string to truncate
 * @param size what size truncate to
 */
export function truncate(text: string, size: number) {
  return text?.length > size ? `${text?.slice(0, size - 1)}…` : text
}

export const MOTION_VARIANTS = {
  initial: ({
    direction,
    delay,
  }: {
    direction: 'forward' | 'backward'
    delay: any
  }) => ({
    x: direction === 'backward' ? '-100%' : '100%',
    transition: {
      type: 'spring',
      duration: delay ?? 1,
      delay: 0,
    },
  }),
  in: {
    x: 0,
    transition: {
      type: 'spring',
      duration: 1,
      delay: 0,
    },
  },
  out: ({ direction }: { direction: 'forward' | 'backward' }) => ({
    x: direction === 'backward' ? '100%' : '-100%',
    transition: {
      type: 'spring',
      duration: 1,
      delay: 0,
    },
  }),
}

// overlay handle
export function onOverlay() {
  document.getElementById('overlay')!.style.display = 'block'
}

export function offOverlay() {
  document.getElementById('overlay')!.style.display = 'none'
}

export enum TypeSort {
  basic = 'basic',
  date = 'date',
  oldestDate = 'oldestDate',
  deadline = 'deadline',
  status = 'status',
  private = 'private',
  favorite = 'favorite',
  media_count = 'media_count',
  nb_collab = 'nb_collab'
}

// sort array by date
export function sortByCreatedDate<
  T extends {
    createdAt: string
    deadline?: string
    status?: string
    private?: any
    _id?: string
    media_count?: number
    nb_collab?: number
  },
  TypeT extends TypeSort
>(
  array: Array<
    TypeT extends TypeSort.deadline
    ? T & {
      createdAt: string
      deadline: string
      status?: string
      private?: any
    }
    : TypeT extends TypeSort.status
    ? T & {
      createdAt: string
      deadline?: string
      status: string
      private?: any
    }
    : TypeT extends TypeSort.private
    ? T & {
      createdAt: string
      deadline?: string
      status?: string
      private: any
    }
    : TypeT extends TypeSort.media_count
    ? T & {
      createdAt: string
      deadline?: string
      status?: string
      private?: any
      media_count: number
    }
    : TypeT extends TypeSort.nb_collab
    ? T & {
      createdAt: string
      deadline?: string
      status?: string
      private?: any
      nb_collab: number
    }
    : T & {
      createdAt: string
      deadline?: string
      status?: string
      private?: any
    }
  >,
  type: TypeT
): Array<T> {
  return type === TypeSort.date
    ? array.slice().sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
    : type === TypeSort.oldestDate
      ? array.slice().sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })
      : type === TypeSort.basic
        ? array.slice().sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).sort((a, b) => {
          return a.status! < b.status! ? 1 : -1
        }).reverse()
        : type === TypeSort.deadline
          ? array
            .slice()
            .sort(
              (a, b) =>
                new Date(a.deadline!).getTime() - new Date(b.deadline!).getTime()
            ).filter(a => new Date(a.deadline!) >= new Date())
          // : type === TypeSort.private
          // ? array.slice().sort((a, b) => {
          //     return a?.private?.is_private === true &&
          //       [false, undefined].indexOf(b?.private?.is_private) !== -1
          //       ? -1
          //       : 1
          //   })
          : type === TypeSort.status
            ? array
              .slice()
              .sort((a, b) => {
                return a.status! < b.status! ? 1 : -1
              })
              .reverse()
            : type === TypeSort.media_count
              // eslint-disable-next-line radix
              ? array.slice().sort((a, b) => (b.media_count ?? 0) - (a.media_count ?? 0))
              : type === TypeSort.nb_collab
                ? array.slice().sort((a, b) => (b.nb_collab ?? 0) - (a.nb_collab ?? 0))
                : array
}

type Filtered<P> = P extends {
  name: string
  createdAt: string
  status: StatusCasting
  deadline: string
}
  ? P & {
    name: string
    createdAt: string
    status: StatusCasting
    deadline: string
  }
  : MyCasting & {
    createdAt: string
    status: any
    deadline: string
  }
// filter array by published or draft
// export function filterPublishedDraft<T>(
//   array: Array<Filtered<T>>,
//   type: CastingTypes,
//   val?: string
// ): Array<Filtered<T>> {
//   return array.slice().filter((a) => {
//     return val
//       ? ((a as any).name as string)!?.toLowerCase().includes(val?.toLowerCase())
//       : type === 'published'
//       ? a.status === 'active' || a.status === 'closed' || a.status === 'step2'
//       : type === 'draft'
//       ? a.status === 'created'
//       : null
//   })
// }

export function filterPublishedDraft<T>(
  array: Array<Filtered<T>>,
  type: CastingTypes,
  val?: string
): Array<Filtered<T>> {
  return array.slice().filter((a) => {
    return val
      ? ((a as any).name as string)!?.toLowerCase().includes(val?.toLowerCase())
      : type === 'published'
        ? a.status === 'active' ||
        a.status === 'closed' ||
        a.status === 'step2' ||
        a.status === 'to-validate'
        : // || new Date(a.deadline) < new Date ()
        type === 'draft'
          ? a.status === 'created'
          : null
  })
}

export function searchFilter<T extends SearchArr>(
  array: Array<T>,
  keyword?: string
): Array<T> {
  return array.filter((a) =>
    keyword !== '' && keyword !== undefined
      ? a?.applicant?.first_name.toLowerCase().includes(keyword!.toLowerCase())
      : array
  )
}
export function authCookies(user: ClientI) {
  const idVogzter = localStorage.getItem('idVogzter')
  const idOffer = localStorage.getItem('idOffer')
  const isGuest = localStorage.getItem('isGuest')
  if (user !== undefined && Object.keys(user).length > 0 && userMobx.getToken() !== undefined && idVogzter && idOffer && isGuest)
    location.replace('/dashboard/castings')
}

interface TimeLeft {
  daysLeft: number
  hoursLeft: number
  minutesLeft: number
}
// Check how much time left there is before a defined date
export function timeLeft(date: Date): TimeLeft {
  let diff = date.getTime() - new Date().getTime()
  const daysLeft = Math.floor(diff / (1000 * 60 * 60 * 24))
  diff -= daysLeft * (1000 * 60 * 60 * 24)
  const hoursLeft = Math.floor(diff / (1000 * 60 * 60))
  diff -= hoursLeft * (1000 * 60 * 60)
  const minutesLeft = Math.floor(diff / (1000 * 60))
  return { daysLeft, hoursLeft, minutesLeft }
}

// Returns the 1st day of the startYear and last day of the endYear
export function getDatesFromAgeInterval(ageStart: number, ageEnd: number) {
  const dateStart = new Date(new Date().getFullYear() - ageEnd, 0, 1)
  const dateEnd = new Date((new Date().getFullYear() - ageStart) + 1, 0, 0)
  dateEnd.setDate(dateEnd.getDate() - 1)
  return { dateStart, dateEnd }
}

type SearchArr = { applicant?: Applicant }

// Check message not read
export function countMsgNRead(messagesArray: AllMessages[]) {
  return (messagesArray.filter(
    (message) =>
      !message?.text?.includes('message de la part de vogz') && message?.read === false && ['user', 'app'].indexOf(message.sender) > -1
  ).length)
}

export function countMsgClient(messagesArray: AllMessages[]) {
  return (messagesArray.filter(
    (message) => ['client'].indexOf(message.sender) > -1
  ).length)
}

//  time render message
export function timeRender(delay: Date) {
  const tpsRecu = new Date(delay)
  const date = new Date()
  const time1ms = tpsRecu.getTime()
  const time2ms = date.getTime()
  const difference = Math.abs(time2ms - time1ms)
  const days = Math.floor(difference / (1000 * 60 * 60 * 24))
  const heure = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const min = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
  if (days < 1) {
    if (heure < 1) {
      if (min < 1) {
        return i18n.t('common:now')
      }
      return `${i18n.t('common:there-are')} ${min}min`
    }
    return `${i18n.t('common:there-are')} ${heure}h ${i18n.t('common:and')} ${min}min`
  }

  return `${i18n.t('common:there-are')} ${days}j ${heure}h ${i18n.t('common:and')} ${min}min`
}

export function formatVogzterName(firstName: string, lastName: string) {
  const limit = 12
  let ending = ''
  if (firstName.length >= limit) ending = '...'
  const firstNameTruncated = firstName.substring(0, limit).concat(ending)

  return `${firstNameTruncated} ${lastName.at(0)}.`
}

/**

Calculates the age based on the birth year.
@param {number} birthYear - The year of birth.
@returns {number} The age in years.
*/
export function getAge(birthYear: number) {
  const currentDate = new Date()
  const birthYear_ = new Date(birthYear).getFullYear()
  const currentYear = currentDate.getFullYear()
  const age = currentYear - birthYear_
  return age
}

/**
return the date in format AAAA-MM-JJ
[@param](https://github.com/param) {string} date - The date.
[@returns](https://github.com/returns) {string} The date in format 2021-01-01.
*/
export function getDayt (date: string) {
  const d = new Date(date)
  return d.toISOString().substring(0, 10)
}
/**
 return the hour in format HH:MM:SS
[@param](https://github.com/param) {string} date - The date.
[@returns](https://github.com/returns) {string} The hour in format 12:00:00.
*/
export function getHour (date: string) {
  const d = new Date(date)
  return d.toLocaleTimeString()
}

/** *********************
 * READ MESSAGE SOCKET *
 * *********
 * @param {Array} allMessageRecep - all message user .
 * 
*********************** */
export function readMessageSocket(allMessageRecep: AllMessages[]) {
  if (allMessageRecep.length > 0) {
    const lastMsg = _.last(allMessageRecep)
    if (['user','app'].indexOf(lastMsg?.sender ?? '') > -1 && !lastMsg?.read) {
      userMobx.readMsgBrand((lastMsg?.brand ?? ''),(lastMsg?.user as Vogzter)?._id)
    }
  }
}