/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'

import { Brief as Briefs, MyCasting } from '../../types-project/Brief'
import styles from './components/brands.module.scss'
import BrandsCard from './components/BrandsCard'
import userR from '../../mobx/user'

function Brands() {
  useEffect(() => {
    userR.getBrands()
  }, [])

  return (
    <div className={styles['all-body']}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Brands</title>
      </Helmet>
      <div className={styles['list-card']}>
        {/* {(!briefs || isLoading || error) && <Spinner />} */}
        <BrandsCard first={true} />
        {!userR.allBrands.loading &&
          !userR.allBrands.error &&
          !userR.me.loading &&
          !userR.me.error &&
          userR.allBrands.data!.length > 0 &&
          userR.allBrands.data!.map((brand, k) => (
            <BrandsCard key={brand._id} brand={brand} />
          ))}
        {/* {(!error && briefs && briefs.length === 0) &&
                    <Button icon={<AddCircle />} onClick={() => setShowNewBriefLayer(true)} primary size='large' label="Créer mon premier casting" />
                } */}
      </div>
    </div>
  )
}

export default observer(Brands)
