import { TextField } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, FC, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import userMobx from '../../../../mobx/user'
import {
  ClientUpdate,
  Profile as ProfileClient,
} from '../../../../types-project/Client'
import BtnBasic from '../../../../utils/components/Button/BtnBasic'
import SubTitle1 from '../../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../../utils/components/Headings/Title1'
import styles from './components/styles.module.scss'
import BtnBack from '../../../../utils/components/Button/BtnBack'

type ProfileEdit = Pick<any, string> & {
  name: ProfileClient['name']
  first_name: ProfileClient['first_name']
  email: ProfileClient['email']
  logo: ProfileClient['logo'] | File
}

const SectionProfile: FC = () => {
  const [hasNameChanged, setHasNameChanged] = useState(false)
  const [hasFirstNameChanged, setHasFirstNameChanged] = useState(false)
  const [hasMailChanged, setHasMailChanged] = useState(false)
  const [hasLogoChanged, setHasLogoChanged] = useState(false)
  const { t } = useTranslation()

  const imgPicker = useRef<HTMLInputElement>(null)
  const imgLogo = useRef<HTMLImageElement>(null)

  const formik = useFormik<ProfileEdit>({
    initialValues: {
      logo: userMobx.me.data?.company?.logo ?? '',
      name: userMobx.me.data?.profile?.name ?? '',
      first_name: userMobx.me.data?.profile?.first_name ?? '',
      email: userMobx.me.data?.email ?? '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: null,
    onSubmit: async (value) => {
      if (hasLogoChanged) {
        if (value.logo instanceof File) {
          userMobx.updateCompany(value.logo, () => {
            imgLogo.current!.src = URL.createObjectURL(value.logo as File)
            userMobx.me.data!._company.logo = URL.createObjectURL(
              value.logo as File
            )
          })
        } else {
          imgPicker.current?.click()
        }
      }
      if (hasMailChanged) {
        userMobx.updateClient({
          email: value.email,
        } as ClientUpdate)
      }
      if (hasNameChanged || hasFirstNameChanged) {
        userMobx.updateClient({
          first_name: value.first_name as ProfileClient['first_name'],
          name: value.name as ProfileClient['name'],
        } as ClientUpdate)
      }
    },
  })
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Profil</title>
      </Helmet>
      <main className="flex flex-col items-center w-full h-full p-4 justify-center">
        <div className=" w-full absolute top-16 left-2 sm:top-4 z-20">
          <BtnBack btnName= {t('mycastings:return')} />
        </div>
        <div className={`max-w-screen-2xl ${styles['main-profile']}`}>
          <section className="relative">
            <Title1
              text={t('myprofil:your-profil')}
              className="uppercase"
              size={35}
            />
            <br />
            <div className="flex items-center flex-col lg:flex-row">
              <div className="flex flex-col lg:mr-16 mr-0 ">
                <FormikProvider value={formik}>
                  <form name="profile" onSubmit={formik.handleSubmit}>
                    <SubTitle1
                      text={t('team:name')}
                      className={`${styles['form-label']}`}
                    />
                    <div className={`${styles['form-container']}`}>
                      <motion.div
                        key="1"
                        initial={{
                          opacity: 0,
                          marginRight: -160,
                          marginLeft: 160,
                        }}
                        animate={{
                          width: '100%',
                          height: '100%',
                          opacity: 1,
                          marginRight: 0,
                          marginLeft: 0,
                        }}
                        exit={{
                          opacity: 0,
                          marginRight: 160,
                          marginLeft: -160,
                        }}
                        className={"s['div-input']"}
                      >
                        <TextField
                          className={`${styles['form-input']}`}
                          required
                          type="text"
                          sx={{
                            input: { color: '#2A42A7', background: '#FCFCFF' },
                            fieldset: { borderColor: '#F5F4FF' },
                          }}
                          onChange={(e) => {
                            formik.handleChange(e)
                            setHasNameChanged(true)
                          }}
                          value={formik.values.name}
                          label=""
                          name="name"
                          placeholder={'Nom'}
                        />
                      </motion.div>
                      <div className={`${styles['form-btn']} opacity-40`}>
                        <BtnBasic
                          disabled={!hasNameChanged}
                          type="sky-violet-bordered"
                          typeBtn="submit"
                          btnName={t('myprofil:edit')}
                        />
                      </div>
                    </div>
                    <SubTitle1
                      text={t('team:first_name')}
                      className={`${styles['form-label']}`}
                    />
                    <div className={`${styles['form-container']}`}>
                      <motion.div
                        key="1"
                        initial={{
                          opacity: 0,
                          marginRight: -160,
                          marginLeft: 160,
                        }}
                        animate={{
                          width: '100%',
                          height: '100%',
                          opacity: 1,
                          marginRight: 0,
                          marginLeft: 0,
                        }}
                        exit={{
                          opacity: 0,
                          marginRight: 160,
                          marginLeft: -160,
                        }}
                        className={"s['div-input']"}
                      >
                        <TextField
                          className={`${styles['form-input']}`}
                          required
                          type="text"
                          sx={{
                            input: { color: '#2A42A7', background: '#FCFCFF' },
                            fieldset: { borderColor: '#F5F4FF' },
                          }}
                          onChange={(e) => {
                            formik.handleChange(e)
                            setHasFirstNameChanged(true)
                          }}
                          value={formik.values.first_name}
                          label=""
                          name="first_name"
                          placeholder={t('team:first_name')}
                        />
                      </motion.div>
                      <div className={`${styles['form-btn']} opacity-40`}>
                        <BtnBasic
                          disabled={!hasFirstNameChanged}
                          type="sky-violet-bordered"
                          typeBtn="submit"
                          btnName={t('myprofil:edit')}
                        />
                      </div>
                    </div>
                    <br />
                    <SubTitle1
                      text="Adresse mail"
                      className={`${styles['form-label']}`}
                    />
                    <div className={`${styles['form-container']}`}>
                      <motion.div
                        key="1"
                        initial={{
                          opacity: 0,
                          marginRight: -160,
                          marginLeft: 160,
                        }}
                        animate={{
                          width: '100%',
                          opacity: 1,
                          marginRight: 0,
                          marginLeft: 0,
                        }}
                        exit={{
                          opacity: 0,
                          marginRight: 160,
                          marginLeft: -160,
                        }}
                        className={"s['div-input']"}
                      >
                        <TextField
                          className={`${styles['form-input']}`}
                          required
                          type="email"
                          sx={{
                            input: { color: '#2A42A7', background: '#FCFCFF' },
                            fieldset: { borderColor: '#F5F4FF' },
                          }}
                          onChange={(e) => {
                            formik.handleChange(e)
                            setHasMailChanged(true)
                          }}
                          value={formik.values.email}
                          name="email"
                          placeholder={t('myprofil:adress-mail')}
                        />
                      </motion.div>
                      <div className={`${styles['form-btn']} opacity-40`}>
                        <BtnBasic
                          disabled={!hasMailChanged}
                          type="sky-violet-bordered"
                          typeBtn="submit"
                          btnName={t('myprofil:edit')}
                        />
                      </div>
                    </div>
                    {userMobx.me.data?._company.type === 'agency' ? (
                      <>
                        <br />
                        <SubTitle1
                          text={t('myprofil:label-logo')}
                          className={`${styles['form-label']}`}
                        />
                        <div className={`${styles['form-container']}`}>
                          <motion.div
                            key="1"
                            initial={{
                              opacity: 0,
                              marginRight: -160,
                              marginLeft: 160,
                            }}
                            animate={{
                              opacity: 1,
                              marginRight: 0,
                              marginLeft: 0,
                            }}
                            exit={{
                              opacity: 0,
                              marginRight: 160,
                              marginLeft: -160,
                            }}
                            className={"s['div-input']"}
                          >
                            <div
                              className={`${styles['form-input']}`}
                              onClick={(e) => {
                                imgPicker.current?.click()
                              }}
                            >
                              <img
                                ref={imgLogo}
                                className={`${styles['img-picker-container']}`}
                                src={`${userMobx.me.data?._company.logo ?? ''}`}
                                alt="company logo"
                              />
                            </div>
                            <input
                              className="hidden"
                              ref={imgPicker}
                              type="file"
                              accept="image/*"
                              name="logo"
                              onChange={(e) => {
                                const file = e.currentTarget.files
                                const fileLength = file?.length ?? -1
                                if (fileLength > 0) {
                                  formik.setValues({
                                    ...formik.values,
                                    logo: file![0],
                                  })
                                  setHasLogoChanged(true)
                                  formik.submitForm()
                                }
                              }}
                            />
                          </motion.div>
                          <div className={`${styles['form-btn']} opacity-40`}>
                            <BtnBasic
                              type="sky-violet-bordered"
                              typeBtn="submit"
                              btnName={t('myprofil:edit')}
                              onclick={(e) => {
                                imgPicker.current?.click()
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </form>
                </FormikProvider>
                {/* <br />
                <div className={styles['part-cancel-subcription']}>
                  <h3>{t('myprofil:cancel-subscription-title')}</h3>
                  <div className="flex flex-col mt-2 sm:flex-row ">
                    <p>{t('myprofil:cancel-subscription-desc')}</p>
                    <Btn1
                      type="button"
                      btnName={t('myprofil:cancel-subscription')}
                      onclick={() => {
                        console.log('cancel')
                      }}
                    />
                  </div>
                </div> */}
              </div>
              <div className={styles['part-credit']}>
                <span>{userMobx.me.data?._company.credits ?? 0}</span>
                <h3>
                  <span>€</span>
                  {t('myprofil:available')}
                </h3>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  )
}

export default SectionProfile
