/* eslint-disable react/no-danger */
import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import 'react-step-progress-bar/styles.css'
// @ts-ignore
import { ProgressBar, Step } from 'react-step-progress-bar'
// import the stylesheet
import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../../../utils/components/Headings/Title1'
import styles from './components/style.module.scss'
import {
    pageVariantsAnimation,
    stepVariantsAnimation,
} from '../../../../../utils/constants'
import i18n from '../../../../../utils/i18n'

function BriefTypeSectionForm({
    children,
    title,
    description,
    required,
    recommended,
    optional,
    error,
    castingPlus,
    dealPlus,
    conditions,
    info,
    override,
    influence,
    checkboxOnSpot,
    checkboxPlus,
    checkboxSimple
}: NewSectionFormProps) {
    if (error) {
        document
            .getElementById(title)
            ?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    const header = override ? (<h3
        dangerouslySetInnerHTML={{ __html: title }}
        className={`${styles['title-section-form']} ${info ? styles['info'] : ''} ${castingPlus ? styles['casting-plus']
            : dealPlus
                ? styles['deal-plus']  // Deal privé avec envoie de produit
                : ''}
            flex items-center`}
    />) : (
        <div className={`${styles['section-header']} ${styles['section-brief-type']}`}>
            <div className={styles['casting-simple']}>
                <h3 dangerouslySetInnerHTML={{
                    __html: i18n.t(
                        dealPlus ?
                            'form-casting:casting.cards-casting-simple.title-deal' :
                            'form-casting:casting.cards-casting-simple.title-simple'
                    )
                }} />
                <p dangerouslySetInnerHTML={{ __html: i18n.t('form-casting:casting.cards-casting-simple.desc-simple') }} />
                {influence && checkboxSimple}
            </div>
            <hr className={`${influence? "h-['140px']":''}`} />
            <div className={`${dealPlus ? styles['deal-plus'] : styles['casting-plus']}`}>
                <h3 dangerouslySetInnerHTML={{
                    __html: i18n.t(
                        dealPlus ?
                            'form-casting:casting.cards-casting-simple.deal-plus' :
                            'form-casting:casting.cards-casting-simple.title-plus'
                    )
                }} />
                <p dangerouslySetInnerHTML={{ __html: i18n.t('form-casting:casting.cards-casting-simple.desc-plus') }} />
                {influence && checkboxPlus}
            </div>
            <hr />
            <div className={styles['casting-on-spot']}>
                <h3 dangerouslySetInnerHTML={{
                    __html: i18n.t(
                        dealPlus ?
                            'form-casting:casting.cards-casting-simple.deal-on-spot' :
                            'form-casting:casting.cards-casting-simple.title-on-spot'
                    )
                }} />
                <p dangerouslySetInnerHTML={{ __html: i18n.t('form-casting:casting.cards-casting-simple.desc-on-spot') }} />
                {influence  && checkboxOnSpot}
            </div>
        </div>
    )
    return (
        <section
            id={title}
            data-info={info}
            data-required={required}
            data-recommended={recommended}
            data-optional={optional}
            data-errored={error !== undefined}
            className={`${styles['brief-type-form']} ${styles['active']}`}
        >
            { influence && (
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
            )
            }
            {header}
            {description && (
                <p
                    dangerouslySetInnerHTML={{ __html: description }}
                    data-required
                //  className={styles['desc-section-form']}
                />
            )
            }
            <aside className={styles['form-aside-content']}>{children}</aside>
            {conditions && (
                <p data-conditions dangerouslySetInnerHTML={{ __html: conditions }} />
            )}
        </section>
    )
}

interface NewSectionFormProps {
    children?: NewSectionFormProps['override'] extends boolean ? (title: Element, desc?: string) => React.ReactNode : React.ReactNode
    title: string
    description?: string
    required?: boolean
    recommended?: boolean
    optional?: boolean
    error?: string
    conditions?: string
    castingPlus?: boolean
    info?: boolean
    dealPlus?: boolean
    override?: boolean
    influence?: boolean
    checkboxSimple?: React.ReactNode
    checkboxPlus?: React.ReactNode
    checkboxOnSpot?:React.ReactNode
}

BriefTypeSectionForm.defaultProps = {
    required: false,
    description: undefined,
    recommended: false,
    optional: false,
    error: undefined,
    conditions: undefined,
    castingPlus: false,
    info: false, dealPlus: false,
    override: false,
    influence: false
}

BriefTypeSectionForm.prototype = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    conditions: PropTypes.string,
}

export default BriefTypeSectionForm
