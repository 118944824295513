import React, { FC } from "react"
import { useTranslation } from 'react-i18next'
import styles from "./components/styles.module.scss"

const CertifiedVogzter: FC = () => {

  const { t } = useTranslation()

  return (
    <div className={`flex align-middle mb-3 self-center ${styles['certified-vogzter']}`}>
      <b>{t('mycastings:certified-vogzter')}</b>
      <i className={`fa-solid fa-badge-check ${styles['icon-certif']}`} />
    </div>
  )
}

export default CertifiedVogzter