/* eslint-disable react/prop-types */
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
// import { motion } from "framer-motion";
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import styles from './components/card-declined-publication.module.scss'
import { BaCustom, MediaBA, MyCasting, Publication } from '../../../../../types-project/Brief'

import briefR from '../../../../../mobx/brief'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'

const CardDeclinedPublication: FC<
  {
    showModalDeclinePublication:MediaBA,
    setShowModalDeclinePublication:Dispatch<SetStateAction<MediaBA|null>>,
    className?:string
  }> = ({ showModalDeclinePublication, setShowModalDeclinePublication, className }) => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const validationSchemaDecline = Yup.object().shape({
    declined_reason: Yup.string().required(t('mycastings:decline-publication.error'))
    .test('notEmpty',t('mycastings:decline-publication.error'), (value) => value !== "none")
    ,
  })
  const handleDecline = () => {   
    const propsDeclinedPublication = {
      idBA: showModalDeclinePublication.brief_application as string ?? '',
      idMedia: showModalDeclinePublication?._id ?? '',
      reason: formikDecline.values.declined_reason,
    } 
    briefR.declinePublication(propsDeclinedPublication, () => {
      setShowModalDeclinePublication(null)
      toast.success(t('mycastings:decline-publication.success'))
    })
  }
  const formikDecline = useFormik<{declined_reason:Publication['declined_reason']}>({
    initialValues: {declined_reason: "none"},
    validateOnBlur: true,
    validationSchema: validationSchemaDecline,
    onSubmit: () => {
      handleDecline()
    },
  })

  const declineReason = [
    {value: 'link', label: t('mycastings:decline-publication.reason.link')},
    {value: 'video', label: t('mycastings:decline-publication.reason.video')},
    {value: 'mention', label: t('mycastings:decline-publication.reason.mention')},
    {value: 'publication', label: t('mycastings:decline-publication.reason.publication')},
    {value: 'other', label: t('mycastings:decline-publication.reason.other')},
  ]

  return (
    <article id='decline-publication-modal' className={`${styles['parent-modal']} ${className? styles[className]: ''}`}>
      <div className={styles['decline-publication-modal']}>
        <div className={styles['top-part']}>
          <figure>
            <img src="/assets/svgs/icon-declined-modal.svg" alt="decline" />
          </figure>
          <button type='button' onClick={()=> setShowModalDeclinePublication(null)}>
            <i className='fa-regular fa-circle-xmark'/>
          </button>
        </div>
        <div className={styles['bottom-part']}>
          <div className={`flex flex-col ${styles['form-part']}`}>
            <h3>{t('mycastings:decline-publication.title')}</h3>
            <p>{t('mycastings:decline-publication.desc1')}</p>
            <p>{t('mycastings:decline-publication.desc2')}</p>
            <br />
            <FormikProvider value={formikDecline}>
              <form onSubmit={formikDecline.handleSubmit} className={styles['form']}>
                <div className='flex flex-col items-center'>
                  <select defaultValue={'none'} placeholder={t('mycastings:decline-publication.reason.default')} onChange={(e)=>{
                    formikDecline.setFieldValue('declined_reason', e.target.value)
                  }} name="declined_reason" id="declined_reason">
                    <option value="none">{t('mycastings:decline-publication.reason.default')}</option>
                    {declineReason.map((item,index)=>(
                      <option key={index} value={item.value}>{item.label}</option>
                    ))}
                  </select>
                  {formikDecline.errors.declined_reason &&
                    <p className='error leading-normal'>{formikDecline.errors.declined_reason}</p>
                  }
                </div>
              </form>
            </FormikProvider>
            <br />
            <BtnBasic
              disabled={!formikDecline.isValid || formikDecline.values.declined_reason === "none"}
              type="inverted-sky-violet"
              btnName={t('mycastings:decline-publication.send')}
              typeBtn="submit"
              onclick={() => {
                handleDecline()
              }}
            />
          </div>
        </div>
      </div>
    </article>
  )
}

export default observer(CardDeclinedPublication)
