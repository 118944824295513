import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    backend: {
      /* translation file path */
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    keySeparator: '.',

    ns: ['signup-login',
      'common',
      'mycastings',
      'dashboard-right-panel',
      'team',
      'documents',
      'favorite',
      'messages',
      'new-casting',
      'new-deal',
      'form-casting',
      'summary',
      'purchases',
      'myprofil',
      'sent-casting',
      'notification',
      'register-process',
      'vogzters',
      'premium',
      'credit'],
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
