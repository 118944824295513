/* eslint-disable react/no-danger */
import { Form, Formik, FormikErrors } from 'formik'
import { Session } from 'inspector'
import { split } from 'lodash'
import React, { useCallback, useState, useEffect } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import briefR from '../../../../../../../mobx/brief'
import userMobx from '../../../../../../../mobx/user'
import {
  Media,
  MediaBrief,
  MediaCastings,
} from '../../../../../../../types-project/Brief'
import { BrandI, ClientI } from '../../../../../../../types-project/Client'
import brands from '../../../../../../brands'
import FieldForm from '../../FieldForm'

import SectionForm from '../../SectionForm'
import styles from '../form.module.scss'
import castingFormModel, { CustomBriefI } from './FormModel/castingFormModel'
import { Form1Props, FormProps } from './FormModel/type-forms'

function Form3({ brief, errors }: { brief: Form1Props, errors: FormikErrors<CustomBriefI> }) {
  // translation
  const { t } = useTranslation()
  const { id } = useParams<{
    id: string
  }>()

  // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  let brand: BrandI;
  // if the user is an agency, we get the brand from the session storage
  if (userInfos._company.type === 'agency') {
    brand = userMobx.getBrandSession() ?? {}
  } else {
    // if the user is a brand, we keep the brand we got from the local storage
    brand = userInfos.brand!;
  }
  const { media } = errors!

  useEffect(() => {
    if (window.location.hash.substring(1))
      window.document
        .getElementById(
          `${window.location.hash
            .substring(1)
            .replaceAll('%20', ' ')
            .replaceAll('%C3%A9', 'é')}`
        )!
        ?.scrollIntoView({block:'center'})
  }, [])

  return (
    <>
      {/* Illustration du brief */}
      { brief.content_type !== 'influence' && 
        <SectionForm
          title={t('form-casting:casting.cards-casting-illustration.title')}
          description={t('form-casting:casting.cards-casting-illustration.desc')}
          conditions={t(
            'form-casting:casting.cards-casting-illustration.conditions'
          )}
          required
          error={media}
        >
          <FieldForm<Form1Props> name="media" required>
            {(field, meta, helpers) => {
              const { setValue } = helpers
              const { value } = field

              return (
                <>
                  <div
                    className={`${styles['div-content-file']} flex-row flex flex-wrap !items-start`}
                  >
                    <div className="mt-2" />
                    {(value as CustomBriefI['media'])?.map((v, i) => {
                      return (
                        <Dropzone
                          key={i}
                          multiple={false}
                          noClick
                          accept={{
                            'image/png': [],
                            'video/mp4': [],
                            'image/jpeg': [],
                          }}
                          disabled={v instanceof File || !(v === undefined)}
                          onDrop={(acceptedFiles) => {
                            const files = value as Array<File>
                            // eslint-disable-next-line prefer-destructuring
                            files[i] = acceptedFiles[0]
                            setValue(files)
                          }}
                        >
                          {({
                            getRootProps,
                            getInputProps,
                            isDragAccept,
                            isDragReject,
                          }) => (
                            <div
                              className={`${styles['div-input-file-photo']} flex-row  mt-6 flex mr-5 items-center`}
                              {...getRootProps()}
                            >
                              {v === undefined && (
                                <p
                                  className={`
                                    ${styles['info-file']}
                                  ${i === 0
                                      ? styles['requis']
                                      : styles['recommended']
                                    }`}
                                >
                                  {i === 0 ? 'Obligatoire' : 'Recommandé'}
                                </p>
                              )}
                              {v && (
                                <span
                                  onClick={(e) => {
                                    if (v instanceof File) {
                                      const files = (value as Array<
                                        File | undefined
                                      >).slice()
                                      files[i] = undefined
                                      setValue(files)
                                    } else if (v as MediaBrief) {
                                      const files = (value as Array<
                                        MediaBrief | undefined
                                      >).slice()
                                      files[i] = undefined
                                      setValue(files)
                                    } else {
                                      briefR.deleteFile(
                                        (v as unknown) as MediaBrief,
                                        id!,
                                        'media',
                                        'media'
                                      )
                                    }
                                  }}
                                  className={styles['btn-close']}
                                >
                                  <i className="fa-sharp fa-solid fa-xmark" />
                                </span>
                              )}
                              <label
                                className={`${styles['label-file']} relative`}
                                htmlFor={`input-file-media-${i}`}
                              >
                                {v !== undefined ? (
                                  <div className="absolute flex justify-center items-center h-full w-full overflow-hidden">
                                    <img
                                      alt="sdsdkk/sdf"
                                      src={
                                        (v as File).type === 'video/mp4' ||
                                          (v as File).type === 'video'
                                          ? '/assets/svgs/video-play.svg'
                                          : v instanceof File
                                            ? URL.createObjectURL(v!)
                                            : v.source
                                      }
                                      className={`${(v as File).type === 'video/mp4' ||
                                        (v as File).type === 'video'
                                        ? 'w-2/5 h-auto'
                                        : ''
                                        }  object-cover h-full rounded-lg w-full`}
                                      // Revoke data uri after image is loaded
                                      onLoad={() => {
                                        if (v instanceof File)
                                          URL.revokeObjectURL(
                                            URL.createObjectURL(v!)
                                          )
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    alt="decorative"
                                    src="/assets/svgs/AppareilPhoto.svg"
                                  />
                                )}
                                {(v !== undefined &&
                                  (v as File).type === 'video') ||
                                  (!(v instanceof File) &&
                                    t(
                                      'form-casting:casting.cards-casting-illustration.placeholder'
                                    ))}
                              </label>
                              <input
                                className="mt-2"
                                data-input-media
                                id={`input-file-media-${i}`}
                                placeholder={t(
                                  'form-casting:casting.cards-casting-illustration.placeholder'
                                )}
                                onChange={(e) => {
                                  setValue(e.target)
                                }}
                                {...getInputProps()}
                              />
                            </div>
                          )}
                        </Dropzone>
                      )
                    })}
                  </div>
                </>
              )
            }}
          </FieldForm>
        </SectionForm>
      }
      {/* Video inspirationnelles */}
      <SectionForm
        title={t('form-casting:casting.cards-casting-inspirations.title')}
        description={t('form-casting:casting.cards-casting-inspirations.desc')}
        conditions={t(
          'form-casting:casting.cards-casting-inspirations.conditions'
        )}
      >
        <FieldForm<Form1Props> name="inspiration">
          {(field, meta, helpers) => {
            const { setValue } = helpers
            const { value } = field
            return (
              <>
                <div
                  className={`${styles['content-medias']} mt-4 flex flex-col`}
                >
                  {/* media loop */}
                  {(value as CustomBriefI['inspiration'])?.media?.map(
                    (v, i) => (
                      <div
                        key={i}
                        className={`${styles['div-media']} px-2 my-1 flex flex-row`}
                      >
                        <i className="fa-solid fa-play" />
                        <p>
                          {v instanceof File
                            ? (v as File).name
                            : split(v.source, '/')[
                            split(v.source, '/')?.length - 1
                            ]}
                        </p>
                        <i
                          className="fa-solid fa-xmark"
                          onClick={() => {
                            if (v instanceof File) {
                              brief.inspiration = {
                                media: (value?.media as Array<
                                  File
                                > | null)!.filter((v, k) => k !== i),
                              }
                              setValue(brief.inspiration, false)
                            } else {
                              briefR.deleteFile(
                                (v as unknown) as MediaBrief,
                                id!,
                                'inspi',
                                'inspiration'
                              )
                            }
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
                <div
                  className={`${styles['div-content-file']} flex-wrap !items-center`}
                >
                  <div className="mt-2" />
                  <Dropzone
                    accept={{
                      'video/*': ['.mp4'],
                    }}
                    noClick
                    onDrop={(acceptedFiles) => {
                      const files = {
                        media: (value as CustomBriefI['inspiration'])?.media.slice(),
                      }

                      files.media!.push(...acceptedFiles)
                      setValue(files)
                    }}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragAccept,
                      isDragReject,
                    }) => (
                      <div
                        className={`${styles['div-input-file']} flex-row  mt-6 flex mr-5 items-center`}
                        {...getRootProps()}
                      >
                        <label
                          className={styles['label-file']}
                          htmlFor="input-video"
                        >
                          <i className="fa-solid fa-arrow-down-to-bracket fa-2xl" />
                          <br />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                `form-casting:casting.cards-casting-inspirations.${isDragAccept
                                  ? 'placeholder-drop-here'
                                  : isDragReject
                                    ? 'placeholder-wrong-file'
                                    : 'placeholder'
                                }`
                              ),
                            }}
                          />
                          <input
                            className="mt-2"
                            data-input-media
                            id="input-video"
                            {...getInputProps()}
                          />
                        </label>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </>
            )
          }}
        </FieldForm>
      </SectionForm>
      {/* Music */}
      { brief.content_type !== 'influence' &&
        <SectionForm
        title={t('form-casting:casting.cards-casting-music.title')}
        description={t('form-casting:casting.cards-casting-music.desc')}
        conditions={t('form-casting:casting.cards-casting-music.conditions')}
      >
        <FieldForm<Form1Props> name="music">
          {(field, meta, helpers) => {
            const { setValue } = helpers
            const { value } = field
            return (
              <>
                <div
                  className={`${styles['content-medias']} mt-4 flex flex-col`}
                >
                  {(value as CustomBriefI['music']) && (
                    <div
                      className={`${styles['div-media']} px-2 my-1 flex flex-row`}
                    >
                      <i className="fa-solid fa-music" />
                      <p>
                        {value instanceof File
                          ? (value as File).name
                          : split(value as string, '/')[
                          split(value as string, '/').length - 1
                          ]}
                      </p>
                      <i
                        className="fa-solid fa-xmark"
                        onClick={() => {
                          if (value instanceof File) {
                            brief.music = undefined
                            setValue(brief.music)
                          } else {
                            briefR.deleteFile(
                              (value as unknown) as MediaBrief,
                              id!,
                              'inspi',
                              'music'
                            )
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={`${styles['div-content-file']} flex-wrap !items-center`}
                >
                  <div className="mt-2" />
                  <Dropzone
                    accept={{
                      'audio/*': ['.mp3', '.mpeg'],
                    }}
                    multiple={false}
                    noClick
                    onDrop={(acceptedFiles) => {
                      setValue(acceptedFiles[0])
                    }}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragAccept,
                      isDragReject,
                    }) => (
                      <div
                        className={`${styles['div-input-file']} flex-row  mt-6 flex mr-5 items-center`}
                        {...getRootProps()}
                      >
                        <label
                          className={styles['label-file']}
                          htmlFor="input-music"
                        >
                          <i className="fa-solid fa-arrow-down-to-bracket fa-2xl" />
                          <br />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                isDragAccept
                                  ? 'form-casting:casting.cards-casting-inspirations.placeholder-drop-here'
                                  : isDragReject
                                    ? 'form-casting:casting.cards-casting-inspirations.placeholder-wrong-file'
                                    : 'form-casting:casting.cards-casting-music.placeholder'
                              ),
                            }}
                          />
                        </label>
                        <input
                          className="mt-2"
                          data-input-media
                          id="input-music"
                          {...getInputProps()}
                        />
                      </div>
                    )}
                  </Dropzone>
                </div>
              </>
            )
          }}
        </FieldForm>
        </SectionForm>
      }
      {/* Script */}
      <SectionForm
        title={t('form-casting:casting.cards-casting-script.title')}
        description={t('form-casting:casting.cards-casting-script.desc')}
        conditions={t('form-casting:casting.cards-casting-script.conditions')}
      >
        <FieldForm<Form1Props> name="script">
          {(field, meta, helpers) => {
            const { setValue } = helpers
            const { value } = field
            // console.log(value)
            return (
              <>
                <div
                  className={`${styles['content-medias']} mt-4 flex flex-col`}
                >
                  {/* media loop */}
                  {(value as CustomBriefI['script']) && (
                    <div
                      className={`${styles['div-media']} px-2 my-1 flex flex-row`}
                    >
                      <i className="fa-solid fa-file-pdf" />
                      <p>
                        {value instanceof File
                          ? (value as File).name
                          : split(value as string, '/')[
                          split(value as string, '/').length - 1
                          ]}
                      </p>
                      <i
                        className="fa-solid fa-xmark"
                        onClick={() => {
                          if (value instanceof File) {
                            brief.script = undefined
                            setValue(brief.script)
                          } else {
                            briefR.deleteFile(
                              (value as unknown) as MediaBrief,
                              id!,
                              'inspi',
                              'script'
                            )
                          }
                        }}
                      />
                    </div>
                  )}
                </div>

                <div
                  className={`${styles['div-content-file']} flex-wrap !items-center`}
                >
                  <div className="mt-2" />
                  <Dropzone
                    accept={{
                      'application/pdf': ['.pdf'],
                    }}
                    multiple={false}
                    noClick
                    onDrop={(acceptedFiles) => {
                      setValue(acceptedFiles[0])
                    }}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragAccept,
                      isDragReject,
                    }) => (
                      <div
                        className={`${styles['div-input-file']} flex-row  mt-6 flex mr-5 items-center`}
                        {...getRootProps()}
                      >
                        <label
                          className={styles['label-file']}
                          htmlFor="input-script"
                        >
                          <i className="fa-solid fa-arrow-down-to-bracket fa-2xl" />
                          <br />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'form-casting:casting.cards-casting-script.placeholder'
                              ),
                            }}
                          />
                        </label>
                        <input
                          className="mt-2"
                          data-input-media
                          id="input-script"
                          {...getInputProps()}
                        />
                      </div>
                    )}
                  </Dropzone>
                </div>
              </>
            )
          }}
        </FieldForm>
      </SectionForm>
    </>
  )
}

export default Form3
