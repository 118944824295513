import { motion } from "framer-motion"
import Cookies from "js-cookie"
import { observer } from "mobx-react"
import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Modal from 'react-modal'
import modal from "../../../../mobx/modal"
import userMobx from "../../../../mobx/user"
import utilsMobx from "../../../../mobx/utils"
import { ClientUpdate } from "../../../../types-project/Client"
import { formatVogzterName, getAvatar, truncate } from "../../../helpers"
import SubTitle1 from "../../Headings/SubTitle1"
import styles from "./styles.module.scss"

const ModalAddFavorite: FC = () => {

  const { t } = useTranslation()

  const customStyles: Modal.Styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      inset: 0,
      transform: 'initial',
      bottom: 'auto',
      height: '100%',
      transition: '1s',
      background: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      backdropFilter: ' blur(5px)',
      marginLeft: utilsMobx.getSideBarWidth
    },
    overlay: {
      background: 'none'
    }
  }

  return (
      <>
        <Modal
          className={styles["modal-add-favorite"]}
          isOpen={modal.modalAddFavorite.visible}
          style={customStyles}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          ariaHideApp={false}
          closeTimeoutMS={500}
          onRequestClose={() => {
            modal.toogleModalAddFavorite(false)
          }}
        >
          <motion.div
            className={styles["child-modal-add-favorite"]}
            initial={{ opacity: 0, y: '800px' }}
            exit={{ opacity: 0, y: '800px' }}
            transition={{ duration: 0.5 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <button className="self-end"
              type="button"
              onClick={() => {
                modal.toogleModalAddFavorite(false)
              }}
            >
              <i className={`fa-sharp fa-solid fa-xmark ${styles['close-btn']}`} />
            </button>
            <div className={styles["main-content"]}>
              <h3>
                {t('mycastings:modal-add-favorite:title', 
                  {vogzterName: formatVogzterName(truncate(modal.getModalAddFavorite.vogzter?.first_name ?? "", 10), modal.getModalAddFavorite.vogzter?.last_name ?? "")}
                )}
                </h3>
              <SubTitle1 
                className={styles['desc']} 
                text={t('mycastings:modal-add-favorite:desc')} 
                dangerouslySetInnerHTML
              />
              <div className={styles["bottom-content"]}>
                <span role='img' aria-label="emoji">👇</span>
                <div className="relative flex w-full flex-col items-center mb-8">
                  <button
                    type='button'
                    onClick={() => {
                      userMobx.addFavouriteVogzters(modal.getModalAddFavorite.vogzter?._id ?? '', true, ()=> modal.toogleModalAddFavorite(false) )
                    }}
                  >
                    {userMobx.brand.data?.favourites?.includes(modal.getModalAddFavorite.vogzter?._id) ? (
                      <i className="fa-solid fa-heart text-[#F41A1A]" />
                    ) : (
                      <i className="fa-solid fa-heart text-[#FFD1D1]" />
                    )}
                  </button>
                  <img 
                    className={styles["pp"]} 
                    src={getAvatar(modal.getModalAddFavorite.vogzter?.profile.gender ?? "male", modal.getModalAddFavorite.vogzter?.profile?.picture?.source)} 
                    alt="profile-picture" 
                  />
                  <img className={styles["curved-arrow"]} src="/assets/imgs/curved-arrow.png" alt="" />
                </div>
                <div className="flex w-full justify-center">
                  <input
                    defaultChecked={!JSON.parse(Cookies.get("showPopupAddFavorite") ?? '{}')}
                    className="mr-2"
                    type="checkbox"
                    onClick={() => {
                      const showPopupAddFavorite = JSON.parse(Cookies.get("showPopupAddFavorite") ?? "{}") ?? true
                      Cookies.set('showPopupAddFavorite', JSON.stringify(!showPopupAddFavorite))
                    }}
                  />
                  <SubTitle1
                    className={styles['checkbox-desc']}
                    text={t('mycastings:modal-add-favorite:dont-show-anymore')}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </Modal>
      </>
  )
}

export default observer(ModalAddFavorite)