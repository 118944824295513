import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import ReactTooltip from 'react-tooltip'
import { CardCastingsPROPS } from './type'
import { diffSDate, truncate } from '../../../../utils/constants'
import BtnBasic from '../../../../utils/components/Button/BtnBasic'
import { getAvatar, MOTION_VARIANTS, Tags } from '../../../../utils/helpers'
import modal from '../../../../mobx/modal'
import briefR from '../../../../mobx/brief'
import userMobx from '../../../../mobx/user'
import { TypeClient, TypeCompanyRole, Vogzter } from '../../../../types-project/Client'
import { Brief } from '../../../../types-project/Brief'
import VogzterPicture from '../../../Favorites-vogzters/Profil-vogzters/components/VogzterPicture'

function CardCastingsVogz(props: CardCastingsPROPS) {
  const {
    status,
    type,
    title,
    titlePrivate,
    description,
    creationDate,
    endDate,
    illustration,
    castingScope,
    id,
    favoriteVogzter,
    loading,
    callBack,
  } = props

  const [options, setOptions] = useState<boolean>(false)
  const card = useRef<HTMLElement>(null)
  // translation
  const { t } = useTranslation()

  // condition to stay active even if in api i receive step2
  const statusStep2 = status === 'step2' ? 'active' : status

  const [mainImg, setMainImg] = useState(illustration)
  const defaultImg = '/assets/imgs/vogz-logo-basic.png'

  useEffect(() => {
    if (options)
      document.addEventListener('click', (event) => {
        if (card.current) {
          // .contains() return true if click target is inside card.current else return false
          const isClickInside = card.current.contains(event.target as Node)
          if (!isClickInside) {
            setOptions(false)
          }
        }
      })
    if (!illustration) setMainImg(defaultImg)
    return document.removeEventListener('click', () => {})
  }, [options])
  const navigate = useNavigate()
  const callBackT = (id: string) => {
    setOptions(false)
    callBack!(id)
  }

  return (
    <>
      <article
        ref={card}
        className={`card-casting ${
          castingScope === 'exclusive'
            ? 'fair-green-background'
            : castingScope === 'creator'
            ? 'alice-blue-background'
            : 'medium-champagne-background'
        }`}
      >
        {(statusStep2 === 'active' || statusStep2 === 'closed' ) && userMobx.me.data?.company_role !== TypeCompanyRole.GUEST  ? (
          <button
            onClick={() => setOptions(!options)}
            type="button"
            className="card-casting-header-setting-btn triangle triangle-top-right"
          >
            <i className={`fa-regular ${options ? 'fa-xmark' : 'fa-bars'} `} />
          </button>
        ) : statusStep2 === 'created' ? (
          <button
            style={{ transition: '0s' }}
            onClick={() => {
              modal.toogleAlert('warning-brief', true)
              modal.setOnConfirm(() => {
                briefR.deleteBrief([id])
              })
              modal.setOnDismiss(() => {})
            }}
            type="button"
            className="triangle card-casting-header-delete-btn"
          >
            <i className="fa-solid fa-trash delete-draft-btn" />
          </button>
        ) : null}
        <header className="card-casting-header">
          {status === 'to-validate' && <div className="moderation-overlay" />}
          <span className={`status-card status-card-${statusStep2}`}>
            {t(`mycastings:status.${statusStep2}`)}
          </span>
          <div className="illustration-card-casting">
            <img
              className={mainImg === defaultImg ? '!object-contain p-12 opacity-50' : ''}
              src={mainImg}
              onError={() => setMainImg(defaultImg)}
              alt="image-illustration"
            />
          </div>
          <hr
            style={{
              width: '100%',
              border: '1px solid white',
              backgroundColor: 'white',
            }}
          />
        </header>
        <div className="img-scope-card-casting-container">
          <div>
            <img
              className={`${
                castingScope === 'exclusive'
                  ? 'fair-green-background'
                  : castingScope === 'creator'
                  ? 'alice-blue-background'
                  : 'medium-champagne-background'
              }`}
              src={`${
                castingScope === 'exclusive'
                  ? '/assets/imgs/icon-bag.png'
                  : castingScope === 'creator'
                  ? '/assets/imgs/icon-cinema.png'
                  : '/assets/imgs/icon-lock.png'
              }`}
              alt="image-icon"
            />
          </div>
          {castingScope === 'private' && (
            <div>
              <VogzterPicture
                size={70}
                gender={favoriteVogzter?.profile?.gender}
                imgSource={favoriteVogzter?.profile?.picture?.source}
                lastActivity={favoriteVogzter?.last_activity}
                showCertified={favoriteVogzter?.certified}
                href={`/dashboard/profil-vogzter/${favoriteVogzter?._id}`}
                removeShadow
              />
            </div>
          )}
        </div>
        <footer className="card-casting-body mt-2">
          <header className="top-part-card-casting">
            <br />
            <Tags type={type} />
            {/* <span className={`type-card-casting type-${type}`}>
            {t(`mycastings:type-castings.${type}`)}
          </span> */}
            {status !== 'to-validate' ? (
              <span className="date-card-casting">{diffSDate(endDate)}</span>
            ) : (
              <br />
            )}
            <strong className="title-card-casting">
              {titlePrivate
                ? truncate(titlePrivate, 25)
                : title
                ? truncate(title, 25)
                : ''}
            </strong>
            {/* Description of casting card */}
            <p className="desc-video-card-casting">
              {description ? truncate(description, 130) : ''}
            </p>
          </header>
          {/* {statusStep2 === 'to-validate' && (
            // <div className="tooltip-castings">
            //   <span>{t('Ce casting est en modération')}</span>
            // </div>
          )} */}
          <footer className="bottom-part-card-casting">
            {statusStep2 === 'active' || 'to-validate' ? <hr /> : null}
            {statusStep2 === 'active' || 'to-validate' ? (
              <>
                {' '}
                <div
                  className={`date-part-bottom-card-casting ${
                    status === 'to-validate' && 'center'
                  }`}
                >
                  <div className="date-bottom-card-casting">
                    <span className="date-text-card-casting">
                      {t('mycastings:date-creation')}
                    </span>
                    <h5 className="date-date-card-casting">
                      {DateTime.fromISO(creationDate)
                        .setLocale('fr')
                        .toLocaleString()}
                    </h5>
                  </div>
                  {statusStep2 === 'active' && (
                    <div className="date-bottom-card-casting">
                      <span className="date-text-card-casting">
                        {t('mycastings:date-delivery')}
                      </span>
                      <h5 className="date-date-card-casting">
                        {DateTime.fromISO(endDate)
                          .setLocale('fr')
                          .toLocaleString()}
                      </h5>
                    </div>
                  )}
                </div>
              </>
            ) : null}
            <Link
              className={`mb-2 ${
                status === 'created'
                  ? userMobx.me.data?.company_role !== TypeCompanyRole.GUEST && castingScope !== 'private'
                    ? 'button-edit-casting'
                    : 'button-disabled button-edit-casting'
                  : 'button-discover-vogz'
              } ${status !== 'to-validate' ? null : 'button-disabled'}`}
              to={
                status === 'active' || status === 'step2' || status === 'closed'
                  ? `/dashboard/castings/${id}`
                  : status === 'created' && castingScope !== 'private'
                  ? `/dashboard/castings/${id}/summary`
                  : ''
              }
            >
              {status !== 'created'
                ? t('mycastings:discover-vogz')
                : castingScope === 'private'
                ? t('mycastings:edit-deal')
                : t('mycastings:edit-casting')}
            </Link>
          </footer>
        </footer>
        <AnimatePresence>
          {options && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overlay-coloured main-blue"
            >
              <div className="flex flex-col items-center justify-end h-full pb-8">
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {statusStep2 !== 'closed' && (castingScope === 'private' && statusStep2 === 'created') ? (
                    <>
                      <motion.div
                        custom={{ direction: 'backward', delay: 4 }}
                        initial="initial"
                        animate="in"
                        exit="out"
                        key={`btn11-${id}`}
                        variants={MOTION_VARIANTS}
                        className="w-full justify-center flex"
                      >
                        <Link
                          className="flex w-full flex-col items-center"
                          to={{ pathname: `/dashboard/castings/${id}/edit` }}
                        >
                          <BtnBasic
                            onclick={() => {}}
                            btnName={t('mycastings:edit-active-casting')}
                            type="sky-violet"
                          />
                          <span style={{ flex: 1 }} />
                        </Link>
                      </motion.div>
                      <br />
                    </>
                  ) : null}
                  { castingScope !== 'private' &&
                    <motion.div
                      custom={{ direction: 'backward', delay: 4 }}
                      initial="initial"
                      animate="in"
                      exit="out"
                      key={`btn10-${id}`}
                      variants={MOTION_VARIANTS}
                      className="w-full justify-center flex"
                    >
                      <BtnBasic
                        onclick={() =>
                          briefR.duplicateBrief(id, (id) => callBackT(id), false)
                        }
                        btnName={
                          t('mycastings:duplicate-casting')
                        }
                        type="sky-violet"
                      />
                    </motion.div>
                  }
                </div>
                <br />

                {statusStep2 !== 'closed' && castingScope !== 'private' && (
                  <div style={{ width: '100%' }}>
                    <motion.div
                      custom={{ direction: 'backward', delay: 4 }}
                      initial="initial"
                      animate="in"
                      exit="out"
                      key={`btn12-${id}`}
                      variants={MOTION_VARIANTS}
                      className="w-full justify-center flex"
                    >
                      <BtnBasic
                        onclick={() => {
                          navigate(`/dashboard/castings/${id}/extend`)
                        }}
                        btnName={t('mycastings:extend-casting.extend')}
                        type="sky-violet"
                      />
                    </motion.div>
                    <br />
                  </div>
                )}
                {statusStep2 !== 'closed' && (
                  <motion.div
                    custom={{ direction: 'backward' }}
                    initial="initial"
                    animate="in"
                    exit="out"
                    key={`btn2-${id}`}
                    variants={MOTION_VARIANTS}
                    className="w-full justify-center flex"
                  >
                    <BtnBasic
                      className={loading? 'cursor-wait' : ''}
                      onclick={() => {
                        if (!loading) {
                          briefR.getAllBoughtMedia((data) => {
                            if (
                              data?.filter(
                                (media) => (media.brief as Brief)._id === id
                              ).length > 0
                            ) {
                              modal.toogleAlert('close-casting', true)
                              modal.setOnConfirm(() => {
                                briefR.closeBrief(id)
                              })
                            } else {
                              modal.toogleAlert('warning-close-casting', true)
                            }
                          })
                          modal.setOnDismiss(() => {})
                        }
                      }}
                      btnName={`${t('mycastings:close-casting')} 👋`}
                      disabled={loading}
                      type={'dark-blue'}
                    />
                  </motion.div>
                )}
                <br />
                 {statusStep2 !== 'closed' && (
                  <motion.div
                    custom={{ direction: 'backward' }}
                    initial="initial"
                    animate="in"
                    exit="out"
                    key={`btn2-${id}`}
                    variants={MOTION_VARIANTS}
                    className="w-full justify-center flex"
                  >
                    <BtnBasic
                      onclick={() => {
                        navigate(`/dashboard/castings/${id}/notifications?edit=true`)
                      }}
                      btnName={`${t('mycastings:notification-casting')}`}
                      type={'dark-blue'}
                    />
                  </motion.div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        F
      </article>
    </>
  )
}

export default CardCastingsVogz
