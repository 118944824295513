/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { ErrorMessage, Field, useFormik, FormikProvider } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'

import styles from './components/styles.module.scss'
import Title1 from '../../../utils/components/Headings/Title1'
import SubTitle1 from '../../../utils/components/Headings/SubTitle1'
import TextField from '../../../utils/components/Ui/inputs/TextField'

import userMobx from '../../../mobx/user'
import Btn1 from '../../../utils/components/Button/Btn1'
import {
  ClientI,
  LoginAccount,
  TypeClient,
  TypeCompanyRole,
} from '../../../types-project/Client'
import { authCookies } from '../../../utils/helpers'
import { Offer } from '../../../types-project/Brief'
import modal from '../../../mobx/modal'

function Login() {
  // translation
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')  
  const [resetPassword, setResetPassword] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const isGuest = localStorage.getItem('isGuest')
  const idOffer = localStorage.getItem('idOffer')
  const offer = userMobx.offer.data ?? {} as Offer

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('signup-login:mail-invalid'))
      .required(t('signup-login:enter_mail')),
    password: Yup.string().required(t('signup-login:enter-password')),
  })
  const validationSchemaPassword = Yup.object().shape({
    email: Yup.string()
      .email(t('signup-login:mail-invalid'))
      .required(t('signup-login:enter_mail')),
  })
  const validationSchemaResetPassword = Yup.object().shape({
    password: Yup.string().required(t('signup-login:form-required:password')).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t('signup-login:form-errors:password')),
    password_confirmation: Yup.string().required(t('signup-login:form-required:password_confirm')).oneOf([Yup.ref('password'), null], t('signup-login:form-errors:password_confirm'))
  })
  const initialValues: LoginAccount = {
    email: '',
    password: '',
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (token) {
      userMobx.setToken(token)
      setResetPassword(true)
    } else
      userMobx.getMe((user) => {
        authCookies(user)
        if (idOffer){
          userMobx.getOffer(idOffer)
          userMobx.getVogzter(offer.owner._id)
        }
      })
  }, [])
  
  const formik = useFormik<LoginAccount>({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: forgotPassword ? validationSchemaPassword :
     resetPassword ? validationSchemaResetPassword :
      validationSchema ,  
    onSubmit: async (value) => {
      validateLoginForm(value)
    },
  })
  const onSuccess = (stripeUrl: string) => {
    window.location.href = stripeUrl
  }
  const validateLoginForm = async (value: LoginAccount) => {
    const values = await formik.validateForm(formik.values)
    if (!forgotPassword && !resetPassword && Object.keys(values).length === 0) {
      const val = await userMobx.login(value.email, value.password)
      if (!userMobx.user.error && val) {
        const vogzterId = JSON.parse(sessionStorage.getItem('vogzter') ?? '{}')
        const referralCode = JSON.parse(
          sessionStorage.getItem('referral') ?? '{}',
        )
        if ( Object.keys(vogzterId).length > 0) {
          if ((val as ClientI)._company?.type === TypeClient.AGENCY && (val as ClientI)?.company_role !== TypeCompanyRole.MEMBER)
            navigate('/dashboard/brands')
          else {
            setTimeout(() => {
              if (!userMobx.me.data?.brand?.favourites?.includes(vogzterId)) {
                userMobx.addFavouriteVogzters(vogzterId);
              }
            }, 1000); // Set the timeout to 1000 milliseconds (1 second)

            navigate(`/dashboard/profil-vogzter/${vogzterId}`)
          }
        } else if(Object.keys(referralCode).length > 0) {
            navigate('/dashboard/premium')
        } else if ((val as ClientI)._company?.type === TypeClient.AGENCY && (val as ClientI)?.company_role !== TypeCompanyRole.GUEST){      
          navigate('/dashboard/brands')
        } else {          
          navigate('/dashboard/castings')
        } 
      } 
    } else if (resetPassword && token && Object.keys(values).length === 0) 
      await userMobx.setPassword(value.password, (data)=>{
        if (data.firstLogin){
          if (data?.client?._company.premium) {
            return onSuccess(`/dashboard/new-deal/${idOffer}`)
          }
          if (!userMobx.user.error && !isGuest) {
            navigate('/vogz-tunnel')
          } else if (isGuest && idOffer) {
            const priceToPay = offer.price - (data?.client?._company.credits ?? 0)
            const onFail = () => {
              modal.toogleAlert('stripe-error', true)
              modal.setOnConfirm(() => {
                userMobx.getTopUpLink(priceToPay, {
                  ok: `/dashboard/new-deal/${idOffer}?card=ok`,
                  ko: `/dashboard/new-deal/${idOffer}?card=ko`
                }, onSuccess, onFail)
              })
            }
            userMobx.getTopUpLink(priceToPay, {
              ok: `/dashboard/new-deal/${idOffer}?card=ok`,
              ko: `/dashboard/new-deal/${idOffer}?card=ko`
            }, onSuccess, onFail)
          }
        }else{
          Cookies.remove('token')
          navigate('/login')
          window.location.reload()
        }
          
      })
    else if (Object.keys(values).length === 0) 
      await userMobx.generatePassword(value.email, ()=>{
        setForgotPassword(false)
        navigate('/login')
      })
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Login</title>
      </Helmet>
      <main className={styles['main-page-login']}>
        <section className={styles['all-video']}>
          <div className={styles['video']}>
            <video
              id="video1"
              loop
              preload="auto"
              autoPlay
              muted
              src="./video/acting.mp4"
              playsInline
              webkit-playsinline
            />
          </div>
          <div className={styles['video']}>
            <video
              id="video2"
              loop
              preload="auto"
              autoPlay
              muted
              src="./video/temoignageV3.mp4"
              playsInline
              webkit-playsinline
            />
          </div>
          <div className={styles['video']}>
            <video
              id="video3"
              loop
              preload="auto"
              autoPlay
              muted
              src="./video/crea.mp4"
              playsInline
              webkit-playsinline
            />
          </div>
        </section>
        { !resetPassword ?
          <section>
            <div data-aos="fade-up" className={styles['login-register']}>
              <Title1
                style={{ textAlign: 'center' }}
                text={forgotPassword ?t('signup-login:create-password') : t('signup-login:login')}
              />
              {forgotPassword?
                <SubTitle1 text={t('signup-login:create-password_desc')} className='text-center'/>
              :
                <a
                  className={styles['link-register']}
                  onClick={() => navigate('/vogz-tunnel')}                    >
                  {t('signup-login:no-account')}{' '}
                  <span> &nbsp;{t('signup-login:register')}</span>
                </a>
              }
              <br />
              <FormikProvider value={formik}>
                <form className={styles['form']} onSubmit={formik.handleSubmit}>
                    <motion.div
                      key="1"
                      initial={{ opacity: 0, marginRight: -160, marginLeft: 160 }}
                      animate={{ opacity: 1, marginRight: 0, marginLeft: 0 }}
                      exit={{ opacity: 0, marginRight: 160, marginLeft: -160 }}
                      className={styles['FormField']}
                    >
                      <TextField
                        required
                        autoFocus
                        error={formik.errors.email}
                        type="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        label={t('signup-login:mail')}
                        name="email"
                        placeholder="contact@vogz.io"
                      />
                      {!forgotPassword && (
                        <TextField
                          required
                          autoFocus
                          error={formik.errors.password}
                          type="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          label={t('signup-login:password')}
                          name="password"
                          id='password-view'
                          placeholder={t('signup-login:password')}
                        />
                      )}
                    </motion.div>
                  <br />
                  <div className={styles['div-login-btn']}>
                    <Btn1
                      type={'submit'}
                      onclick={() => {
                        // validateLoginForm(formik.values)
                      }}
                      btnName={ t('signup-login:confirm')}
                    />
                  </div>
                  <br />
                  {!forgotPassword && (
                    <>
                      <a className={styles['forgot-password']} onClick={() => setForgotPassword(true)}>
                        {t('signup-login:create-password')}
                      </a>
                      <br />
                    </>
                  )}
                  {forgotPassword && (
                    <button
                      type="button"
                      className={`btn-txt ${styles['btn-back']}`}
                      onClick={() => {
                        setForgotPassword(false)
                      }}
                    >
                      <i className="fa-regular fa-angle-left" />{' '}
                      {t('signup-login:back')}
                    </button>
                  )}
                </form>
              </FormikProvider>
            </div>
          </section>
        :
          <section>
            <div data-aos="fade-up" className={styles['login-register']}>
              <Title1
                style={{ textAlign: 'center' }}
                text={t('signup-login:create-password')}
              />
                <SubTitle1 text={t('signup-login:reset-password_desc')} className='text-center'/>
              <br />
              <FormikProvider value={formik}>
                <form className={styles['form']} onSubmit={formik.handleSubmit}>
                    <motion.div
                      key="1"
                      initial={{ opacity: 0, marginRight: -160, marginLeft: 160 }}
                      animate={{ opacity: 1, marginRight: 0, marginLeft: 0 }}
                      exit={{ opacity: 0, marginRight: 160, marginLeft: -160 }}
                      className={styles['FormField']}
                    >
                      <TextField
                        required
                        autoFocus
                        error={formik.errors.password}
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        label={t('signup-login:password')}
                        name="password"
                        id='password-view'
                        placeholder={t('signup-login:password')}
                      />
                      <TextField
                        required
                        error={formik.errors.password_confirmation}
                        onChange={formik.handleChange}
                        value={formik.values.password_confirmation}
                        label={t('signup-login:form.password_confirm')}
                        name="password_confirmation"
                        id='password-confirm-view'
                        type="password"
                        placeholder="********"
                      />
                    </motion.div>
                  <br />
                  <div className={styles['div-login-btn']}>
                    <Btn1
                      type={'submit'}
                      onclick={() => {
                        validateLoginForm(formik.values)
                      }}
                      btnName={ t('signup-login:confirm')}
                    />
                  </div>
                </form>
              </FormikProvider>
            </div>
          </section>            
        }
      </main>
    </>
  )
}

export default Login
