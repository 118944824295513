import React, { FC } from "react";
import styles from "./components/styles.module.scss";

interface BadgeBriefTypeProps {
  briefScope: "public" | "private"
  briefType: "simple" | "plus" | "on-spot"
}

// eslint-disable-next-line react/prop-types
const BadgeBriefType: FC<BadgeBriefTypeProps> = ({ briefScope, briefType }) => {
  
  const typeClassName =
    briefType === 'simple'
      ? styles['simple']
      : briefType === 'plus'
        ? (briefScope === 'public' ? styles['casting-plus'] : styles['deal-plus'])
        : styles['on-spot']

  return (
    <div className="flex h-5">
      <span className="flex flex-shrink-0 h-full font-hurmeBlack uppercase text-base text-blue-gulf mx-1">
        {(briefScope === 'public' ? 'Casting' : 'Deal').concat(' ')}
        <span className={`${styles['brief-type']} ${typeClassName}`}>
          {briefType === 'simple' ? 'Simple' : briefType === 'plus' ? 'Plus' : 'On Spot'}
        </span>
      </span>
    </div>
  )
}

export default BadgeBriefType