import React, { CSSProperties, FC } from 'react'
import PropTypes from 'prop-types'
import styles from './components/styles.module.scss'

const Title1: FC<Title1Props> = ({ text, size, style, className }) => {
  return (
    <h2
      className={`${styles['h2-heading']} ${className}`}
      style={{ ...(size ? { fontSize: `${size}px` } : {}), ...style }}
    >
      {text}
    </h2>
  )
}

interface Title1Props {
  text: string
  size?: number
  style?: CSSProperties
  className?: string
}

Title1.defaultProps = {
  size: 30,
  style: {},
  className: '',
}

Title1.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
}

export default Title1
