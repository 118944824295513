const DB_NAME = 'dealFiles'
const DB_VERSION = 1
const STORE_NAME = 'dealFile'

export type FileType = 'video' | 'pdf'

export interface FileRecord {
    id: string
    type: FileType
    name: string
    file: File
}

let db: IDBDatabase

export const initializeDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION)
        
        request.onupgradeneeded = (event) => {
            db = (event.target as IDBOpenDBRequest).result
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                const store = db.createObjectStore(STORE_NAME, { keyPath: 'id' })
                store.createIndex('type', 'type', { unique: false })
            }
        }
        
        request.onerror = (event) => {
            console.error("Database error:", (event.target as IDBRequest).error)
            reject((event.target as IDBRequest).error)
        }
        
        request.onsuccess = (event) => {
            db = (event.target as IDBOpenDBRequest).result
            resolve(event)
        }
    })
}

export function saveFile(fileRecord: FileRecord | undefined): Promise<void> {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([STORE_NAME], 'readwrite')
        const store = transaction.objectStore(STORE_NAME)
        const request = store.put(fileRecord)
    
        request.onerror = () => {
            reject(request.error)
        }
    
        request.onsuccess = () => {
            resolve()
        }
    })
}

export function getFilesByType(type: 'video' | 'pdf', callback?: (files: FileRecord[]) => void) {
    const transaction = db.transaction(STORE_NAME, 'readonly')
    const store = transaction.objectStore(STORE_NAME)
    const index = store.index('type')
    const request = index.getAll(IDBKeyRange.only(type))

    request.onerror = () => {
        console.error("Error", request.error)
    }

    request.onsuccess = () => {
        callback && callback(request.result)
    }
}
