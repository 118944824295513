import { observer } from 'mobx-react'
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import briefR from '../../../../../../../mobx/brief'
import { Vogzter, VogzterSearchResult } from '../../../../../../../types-project/Client'
import BtnBasic from '../../../../../../../utils/components/Button/BtnBasic'
import styles from './components/styles.module.scss'
import { formatVogzterName, getAvatar } from '../../../../../../../utils/helpers'
import {
  ApplicantBa,
  MyCasting,
} from '../../../../../../../types-project/Brief'
import userMobx from '../../../../../../../mobx/user'
import VogzterPicture from '../../../../../../Favorites-vogzters/Profil-vogzters/components/VogzterPicture'

interface VogzterPickerItemProps {
  vogzter: Vogzter | VogzterSearchResult
  selected?: Vogzter
  setSelection?: (vogzter: Vogzter) => void
  isFavorite?: boolean
  className?: string
}

type VogzterPickerItemSelectableProps = VogzterPickerItemProps & {
  vogzter: Vogzter
  selected?: Vogzter
  setSelection?: (vogzter: Vogzter) => void
}

type VogzterPickerItemSearchProps = VogzterPickerItemProps & {
  vogzter: VogzterSearchResult
  selected?: undefined
  setSelection?: undefined
}

type VogzterPickerItemTypes = VogzterPickerItemSelectableProps | VogzterPickerItemSearchProps

const VogzterPickerItem: FC<VogzterPickerItemTypes> = ({
  vogzter,
  selected,
  setSelection,
  isFavorite,
  className,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const brand = userMobx.brand.data

  const vogzterId = setSelection ? (vogzter as Vogzter)._id : (vogzter as VogzterSearchResult).objectID
  const vogzterGender = setSelection ? (vogzter as Vogzter).profile.gender : (vogzter as VogzterSearchResult).gender
  const vogzterPic = setSelection ? (vogzter as Vogzter).profile.picture?.source : (vogzter as VogzterSearchResult).picture
  const vogzterFirstName = setSelection ? (vogzter as Vogzter).first_name : (vogzter as VogzterSearchResult).firstName
  const vogzterLastName = setSelection ? (vogzter as Vogzter).last_name : (vogzter as VogzterSearchResult).lastName
  const vogzterLastActivity = setSelection ? (vogzter as Vogzter).last_activity : (vogzter as VogzterSearchResult).lastActivity

  return (
    <div className={`${styles['vogzter-picker-item']} ${setSelection === undefined ? styles['vogzter-picker-item-small'] : ''} ${isFavorite === false ? styles['not-favorite'] : ''} ${className}`}>
      <button
        type='button'
        onClick={() => {
          if (brand?.favourites?.includes(vogzterId)) {
            userMobx.deleteFavouriteVogzters(vogzterId)
          } else {
            userMobx.addFavouriteVogzters(vogzterId)
          }
        }}
      >
        {isFavorite ? (
          <i className="fa-solid fa-heart absolute top-5 right-6 text-[#F41A1A] z-[3]" />
        ) : (
          <i className="fa-solid fa-heart absolute top-5 right-6 text-[#FFD1D1] z-[3]" />
        )}
      </button>
      <div className={styles['profile-pic-container']}>
      <VogzterPicture
        size={90}
        gender={vogzterGender ?? 'male'}
        imgSource={vogzterPic ?? ''}
        lastActivity={vogzterLastActivity}
        showCertified={vogzter?.certified}
        href={`/dashboard/profil-vogzter/${vogzterId}`}
        removeShadow
      />
      </div>


      <h1 className="leading-[62px]">
        {formatVogzterName(vogzterFirstName, vogzterLastName)}
      </h1>


      <BtnBasic
        className={setSelection === undefined ? styles['btn-small'] : ''}
        type={setSelection !== undefined ? "sky-violet-bordered" : "inverted-sky-violet"}
        btnName="Voir le profil"
        onclick={() => {
          navigate(`/dashboard/profil-vogzter/${vogzterId}`)
        }}
      />
      {setSelection && (
        <BtnBasic
          className={`${styles['btn']} ${selected?._id === vogzter._id ? styles['btnSelected'] : ''
            }`}
          type="inverted-sky-violet"
          btnName={selected?._id === vogzter._id ? '' : 'Sélectionner'}
          iconAfter={
            selected?._id === vogzter._id ? (
              <i className="fa-solid fa-circle-check" />
            ) : (
              ''
            )
          }
          onclick={() => {
            setSelection(vogzter)
          }}
        />
      )}
    </div>
  )
}

VogzterPickerItem.propTypes = {
  vogzter: PropTypes.any.isRequired,
  selected: PropTypes.any,
  setSelection: PropTypes.func,
  isFavorite: PropTypes.bool,
  className: PropTypes.any,
}

VogzterPickerItem.defaultProps = {
  selected: undefined,
  setSelection: undefined,
  isFavorite: false,
  className: ''
}

export default observer(VogzterPickerItem)
