import Cookies from 'js-cookie'
import { action, computed, makeObservable, observable } from 'mobx'
import React, { ReactElement } from 'react'
import { Applicant, BaCustom, MyCasting } from '../types-project/Brief'
import { Vogzter } from '../types-project/Client'
import briefR from './brief'
import userMobx from './user'

let instance: Modal

export class Modal {
  constructor() {
    if (instance) {
      makeObservable(instance)
      return instance
    }
    instance = this
    makeObservable(instance)
  }

  // eslint-disable-next-line react/sort-comp
  @observable open: boolean = false

  @observable title?: string = undefined

  @observable desc?: string = undefined

  @observable header?: ReactElement = undefined

  @observable onlyOneCTA?: boolean = false

  @observable titleBtnCTA?: string = undefined

  @observable onConfirm: () => void = () => { }

  @observable onDismiss: () => void = () => { }

  // To be triggered when API returns "200 OK"
  @observable onRequestSuccess: () => void = () => { }

  @observable modalVideo: { video?: string; open: boolean } = {
    video: undefined,
    open: false,
  }

  @observable selection: {
    briefapplication: MyCasting | BaCustom | null
    open: boolean
    briefapplications: null | MyCasting[]
  } = { briefapplication: null, open: false, briefapplications: null }

  @observable popup: { open: boolean, content: undefined | ReactElement } = { open: false, content: undefined }

  @observable alert: {
    type: 'confirmation' | 'confirmation-rdv' | 'warning' |
    'warning-brief' | 'launch-casting' |
    'stripe-valid' | 'stripe-error' |
    'close-casting' | 'warning-close-casting' |
    'no-favorite-vogzter' | 'extend-brief' |
    'default' |
    'message-restriction';
    open: boolean,
    child: ReactElement | undefined,
  } = {
      type: 'confirmation',
      open: false,
      child: undefined,
    }

  @observable modalAddFavorite: { visible: boolean, vogzter: Applicant | Vogzter | undefined, onClose?: () => void } = { visible: false, vogzter: undefined }
    
  @observable modalEnroll: { visible: boolean,  onClose?: () => void } = { visible: false}

  @action.bound
  toogleVisibility(val?: boolean) {
    this.open = val ?? !this.open
  }

  @action.bound
  toogleVisibilityPopup({ val, content }: { val: false, content: undefined } | { val: true, content: ReactElement }) {
    this.popup = { open: val ?? !this.popup.open, content: content }
  }

  @action.bound
  toogleModalAddFavorite(val: boolean, vogzter?: Vogzter | Applicant, onClose?: () => void) {
    if (val === true) {
      if (vogzter) {
        const shouldShow = JSON.parse(Cookies.get("showPopupAddFavorite") ?? "{}") ?? true
        const isFavorite = userMobx.brand.data?.favourites?.includes(vogzter?._id)
        if (shouldShow && !isFavorite) {
          this.modalAddFavorite.visible = val
          this.modalAddFavorite.vogzter = vogzter
          this.modalAddFavorite.onClose = onClose
          return true
        }
      }
    }
    this.modalAddFavorite.onClose?.()
    this.modalAddFavorite = { visible: val, vogzter: undefined, onClose: undefined }
    return false
  }

  @action.bound
  toogleModalEnroll(val: boolean, onClose?: () => void) {
    this.modalEnroll.visible = val
    if (onClose) {
      onClose()
    }
  }

  @action toogleSelection(
    briefapplication?: MyCasting | BaCustom,
    briefapplications?: MyCasting[],
    val?: boolean,
    onClose?: () => void
  ) {
    this.selection = {
      ...this.selection,
      briefapplication: briefapplication ?? this.selection.briefapplication,
      open: val ?? !this.selection?.open,
      briefapplications: briefapplications ?? this.selection.briefapplications,
    }
    if (!val && onClose) {
      onClose()
    }
  }

  @action toogleAlert(
    type?: 'confirmation' | 'warning' | 'confirmation-rdv' |
      'warning-brief' | 'launch-casting' |
      'stripe-valid' | 'stripe-error' |
      'close-casting' | 'warning-close-casting' |
      'no-favorite-vogzter' | 'message-restriction' | 'extend-brief' |
      'default',
    open?: boolean,
    child?: ReactElement | undefined
  ) {
    this.alert = {
      type: type ?? this.alert.type,
      open: open ?? !this.alert?.open,
      child
    }
  }

  @action toogleModalVideo(video?: string, open?: boolean) {
    this.modalVideo = {
      ...this.modalVideo,
      open: open ?? !this.modalVideo.open,
      video: video ?? this.modalVideo.video,
    }
  }

  @computed get show() {
    return this.open
  }

  @computed get getSelection() {
    return this.selection
  }

  @computed get getModalAddFavorite() {
    return this.modalAddFavorite
  }

  @action.bound
  setTitle(_title: string | undefined) {
    this.title = _title
  }

  @action.bound
  setDesc = (description: string | undefined) => {
    this.desc = description
  }

  @action.bound
  setHeader(header: ReactElement | undefined) {
    this.header = header
  }

  setOnlyOneCTA = (onlyOneCTA: boolean, btnTitle: string) => {
    this.onlyOneCTA = onlyOneCTA
    this.titleBtnCTA = btnTitle
  }

  resetData = () => {
    this.setTitle(undefined)
    this.setDesc(undefined)
    this.setOnConfirm(() => { })
    this.setOnDismiss(() => { })
    this.setOnRequestSuccess(() => { })
    this.setHeader(undefined)
    // Prevents user from seeing transition to 2 buttons
    if (this.onlyOneCTA) {
      setTimeout(() => {
        this.setOnlyOneCTA(false, '')
      }, 400)
    }
  }

  setOnConfirm = (_onConfirm: () => void) => {
    this.onConfirm = _onConfirm
  }

  setOnDismiss = (_onDismiss: () => void) => {
    this.onDismiss = _onDismiss
  }

  setOnRequestSuccess = (_onRequestSuccess: () => void) => {
    this.onRequestSuccess = _onRequestSuccess
  }

}

const modal = new Modal()

export default modal