import React from 'react'
import { Brief } from '../../../../../mobx/brief'
import calendarStyles from '../../calendar.module.scss'
import { DateHandler } from '../DateHandler'

export class CalendarStyleHandler {

  static getTileClassName(activeStartDate: Date, date: Date, view: string, brief: any) {
    const styleTab: string[] = [`${calendarStyles['react-calendar__tile']}`]
    const activeDate = new Date(
      brief?.deadline_ ?? new Date(brief?.deadline ?? new Date().toDateString())
    )
    if (view === 'month') {
      if (DateHandler.compareDates(date, new Date())) {
        styleTab.push(`${calendarStyles['react-calendar__tile--now']}`)
      }
      if (date.getDay() === 0 || date.getDay() === 6) {
        styleTab.push(
          `${calendarStyles['react-calendar__month-view__days__day--weekend']}`
        )
      }
      if (activeStartDate.getMonth() !== date.getMonth()) {
        styleTab.push(
          `${calendarStyles['react-calendar__month-view__days__day--neighboringMonth']}`
        )
      }
      if (DateHandler.compareDates(date, activeDate)) {
        styleTab.push(
          `${calendarStyles['react-calendar__tile--active']} ${calendarStyles['react-calendar__tile--hasActive']}`
        )
      }
    }
    return styleTab
  }

  static getFinalTileClassName(activeStartDate: Date, date: Date, view: string, deadline: Date) {
    const styleTab: string[] = [`${calendarStyles['react-calendar__tile']}`]
    const activeDate = new Date(deadline)
    if (view === 'month') {
      if (DateHandler.compareDates(date, new Date())) {
        styleTab.push(`${calendarStyles['react-calendar__tile--now']}`)
      }
      if (date.getDay() === 0 || date.getDay() === 6) {
        styleTab.push(
          `${calendarStyles['react-calendar__month-view__days__day--weekend']}`
        )
      }
      if (activeStartDate.getMonth() !== date.getMonth()) {
        styleTab.push(
          `${calendarStyles['react-calendar__month-view__days__day--neighboringMonth']}`
        )
      }
      if (DateHandler.compareDates(date, activeDate)) {
        styleTab.push(
          `${calendarStyles['react-calendar__tile--active']} ${calendarStyles['react-calendar__tile--hasActive']}`
        )
      }
    }
    return styleTab
  }

  static getPrevLabel() {
    return <i className='fa-solid fa-chevron-left' />;
  }

  static getNextLabel() {
    return <i className="fa-solid fa-chevron-right" />;
  }

  static getNavigationLabel(label: string) {
    return (
      <b className={`${calendarStyles['react-calendar__navigation__label']}`}>
        {label}
      </b>
    )
  }

}
