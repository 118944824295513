/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { AnimatePresence, motion } from 'framer-motion'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


import styles from './styles.module.scss'
import briefR from '../../../../mobx/brief'
import modal from '../../../../mobx/modal'

const customStyles = {
  content: {
    innerHeight: '400px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    transition: '1s',
    background: 'none',
    border: 'none',
    overflow: 'visible',
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.3)',
    backdropFilter: ' blur(15px)',
  },
}

const ModalAlert: FC = () => {

  const { t } = useTranslation()

  let tmpTitle = modal.title
  let tmpDesc = modal.desc


  if (modal.title === undefined) {
    switch (modal.alert.type) {
      case 'confirmation':
        tmpTitle = t('mycastings:private-deal:product-shipment')
        break
      case 'confirmation-rdv':
        tmpTitle = t('mycastings:go-to-store')
        break
      case 'warning':
        tmpTitle = t('mycastings:disqualify-warning')
        break

      case 'warning-brief':
        tmpTitle = t('mycastings:delete-draft')
        break

      case 'launch-casting':
        tmpTitle = t('new-casting:modal-launch-casting-title')
        break

      case 'stripe-valid':
        tmpTitle = t('new-casting:modal-launch-casting-title')
        break

      case 'stripe-error':
        tmpTitle = t('new-casting:modal-launch-casting-title')
        break
      case 'close-casting':
        tmpTitle = t('mycastings:title-close-casting')
        break
      case 'warning-close-casting':
        tmpTitle = t('mycastings:warning-title-close-casting')
        break
      default:
        break
    }
  }

  if (modal.desc === undefined) {
    switch (modal.alert.type) {
      case 'confirmation':
        tmpDesc = t('mycastings:product-shipment-modal-desc')
        break
      case 'confirmation-rdv':
        tmpDesc = t('mycastings:go-to-store-desc')
        break
      case 'warning':
        tmpDesc = t('mycastings:disqualify-modal-desc')
        break

      case 'warning-brief':
        tmpDesc = t('mycastings:delete-draft-modal-desc')
        break

      case 'launch-casting':
        tmpDesc = t('new-casting:modal-launch-casting-desc')
        break

      case 'stripe-valid':
        tmpDesc = t('register-process:payment-success-modal-desc')
        break

      case 'stripe-error':
        tmpDesc = t('register-process:payment-error-modal-desc')
        break
      case 'close-casting':
        tmpDesc = t('mycastings:desc-close-casting')
        break
      case 'warning-close-casting':
        tmpDesc = t('mycastings:warning-close-casting')
        break
      case 'no-favorite-vogzter':
        tmpDesc = `${t('new-casting:modal-private-deal:desc-1')} \n\n${t('new-casting:modal-private-deal:desc-2')} \n\n${t('new-casting:modal-private-deal:desc-3')}`
        break
      case 'message-restriction':
        tmpDesc = t('mycastings:profile-vogzter:message-limit')
        break
      case 'extend-brief':
        tmpDesc = t('mycastings:extend-brief:description')
        break

      default:
        break
    }
  }


  const { idba } = useParams()

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={modal.alert.open && modal.alert.type !== 'default'}
      shouldCloseOnEsc
      ariaHideApp={false}
      onRequestClose={() => {
        modal.toogleAlert(undefined, false)
      }}
      style={customStyles}
      shouldCloseOnOverlayClick
    >
      <motion.main
        initial={{ opacity: 0, y: '-800px' }}
        transition={{ duration: 0.5 }}
        animate={{ opacity: 1, y: 0 }}
        className={`
          ${styles['content-modal-cart']}
          ${modal.alert.type === 'no-favorite-vogzter' && styles['content-modal-cart-nfv']}
          ${modal.alert.type === 'extend-brief' && styles['extend-modal']}
        `}
      >
        <section
          className={`${modal.alert.type === 'warning'
              ? styles['warning']
              : modal.alert.type === 'warning-brief' || modal.alert.type === 'warning-close-casting'
                ? styles['warning-brief']
                : modal.alert.type === 'confirmation'
                  ? styles['confirmation']
                  : modal.alert.type === 'confirmation-rdv'
                    ? styles['confirmation-rdv']
                    : modal.alert.type === 'launch-casting'
                      ? styles['launch-casting']
                      : modal.alert.type === 'stripe-valid'
                        ? styles['stripe-valid']
                        : modal.alert.type === 'stripe-error'
                          ? styles['stripe-error']
                          : modal.alert.type === 'close-casting'
                            ? styles['close-casting']
                            : modal.alert.type === 'no-favorite-vogzter'
                              ? styles['no-favorite-vogzter']
                              : modal.alert.type === 'message-restriction'
                                ? styles['message-restriction']
                                : (modal.alert.type === 'extend-brief' && modal.header)
                                  ? styles['header-extend']
                                  :(modal.alert.type === 'extend-brief' && !modal.header)
                                  ? styles['extend-brief']
                                  : styles['confirmation']
            }`}>
          {modal.header ?
            modal.header
            :
            <i
              className={
                modal.alert.type === 'stripe-valid'
                  ? 'fa-light fa-circle-check'
                  : modal.alert.type === 'stripe-error'
                    ? 'fa-light fa-circle-xmark'
                    : modal.alert.type === 'no-favorite-vogzter'
                      ? 'fa-solid fa-lock-keyhole'
                        : 'fa-solid fa-triangle-exclamation'
              }
            />
          }
          {modal.onlyOneCTA === true && (
            <i className={`${styles['exit-icon']} fa-light fa-circle-xmark`}
              onClick={() => {
                modal.onDismiss()
                modal.toogleAlert(undefined, false)
              }}
            />
          )}
        </section>
        <section>
          <div>
            <h2>{tmpTitle}</h2>
            <br />
            <p
              className={modal.alert.type === 'no-favorite-vogzter' ? 'whitespace-pre-line' : ''}
              dangerouslySetInnerHTML={{
                __html: tmpDesc ?? ''
              }}
            />
            <br />
          </div>
          <br />
          {modal.onlyOneCTA !== true ? (
            <div className={`${modal.alert.type === 'stripe-valid' || modal.alert.type === 'warning-close-casting' ? '!justify-center' : ''}`}>
              {modal.alert.type === 'stripe-valid' ||
                modal.alert.type === 'warning-close-casting' ? null : (
                <button
                  type="button"
                  onClick={() => {
                    modal.onDismiss()
                    modal.toogleAlert(undefined, false)
                  }}
                  className={`${styles['not-selected']} btn`}
                >
                  Annuler
                </button>
              )}
              <button
                type="button"
                onClick={() => {
                  modal.onConfirm()
                  modal.toogleAlert(undefined, false)
                }}
                className={`${styles['btn']} btn`}
              >
                {modal.alert.type !== 'stripe-error' ? modal.alert.type === 'close-casting' ? 'Cloturer 👋' : modal.alert.type === 'warning-close-casting' ? 'Ok' : modal.alert.type === 'message-restriction' ? 'Effectuer un dépôt' : (modal.alert.type === 'extend-brief' && !modal.header) ? t('mycastings:extend-brief-error.recharge') : 'Confirmer' : 'Réessayer'}
              </button>
            </div>
          ) : (
            <div className='w-full !justify-center'>
              <button className={`${styles['btn']} btn w-full`}
                type="button"
                onClick={() => {
                  modal.onConfirm()
                  modal.toogleAlert(undefined, false)
                }}
              >
                {modal.titleBtnCTA}
              </button>
            </div>
          )}
        </section>
      </motion.main>
    </Modal>
  )
}

// onClick={() => briefR.validateOrCloseBriefApplication((router.query.id as unknown as string), modal.alert.type == "confirmation" ? "validate" : "decline")}

export default observer(ModalAlert)
