import * as React from 'react'
import PropTypes from 'prop-types'

interface IconProps {
  color?: string
}

const ProfileIcon: React.FC<IconProps> = ({ color }) => (
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_339_19133)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 0C13.4013 0 15.7514 2.43 15.7514 5.43C15.7514 8.43 13.4013 10.86 10.5 10.86C7.59871 10.86 5.24864 8.43 5.24864 5.43C5.24864 2.43 7.59871 0 10.5 0ZM10.5 24H10.6451C14.8478 23.9486 18.5615 21.81 20.866 18.5357L20.2609 15.9043C20.0661 14.4986 19.1832 13.56 17.7284 13.0114L13.9402 11.8843C11.8139 13.0971 9.18614 13.0971 7.05989 11.8843L3.2716 13.0114C1.8168 13.56 0.933967 14.4986 0.739165 15.9043L0.134033 18.5357C2.43851 21.81 6.15219 23.9529 10.355 24H10.5Z"
        fill={color}
      />
      {/* Custom edit starts here */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        shapeRendering="auto"
        d="M10.5 0C13.4013 0 15.7514 2.43 15.7514 5.43C15.7514 8.43 13.4013 10.86 10.5 10.86C7.59871 10.86 5.24864 8.43 5.24864 5.43C5.24864 2.43 7.59871 0 10.5 0ZM10.5 24H10.6451C14.8478 23.9486 18.5615 21.81 20.866 18.5357L20.2609 15.9043C20.0661 14.4986 19.1832 13.56 17.7284 13.0114L13.9402 11.8843C11.8139 13.0971 9.18614 13.0971 7.05989 11.8843L3.2716 13.0114C1.8168 13.56 0.933967 14.4986 0.739165 15.9043L0.134033 18.5357C2.43851 21.81 6.15219 23.9529 10.355 24H10.5Z"
        fill={color}
        stroke={color}
        strokeWidth={0.05}
        opacity={0.2}
      />
      {/* Custom edit ends here */}
    </g>
    <defs>
      <filter
        id="filter0_i_339_19133"
        x="0.134033"
        y="-1"
        width="21.7319"
        height="25"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="-1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0431373 0 0 0 0 0.113725 0 0 0 0 0.407843 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_339_19133"
        />
      </filter>
    </defs>
  </svg>
)

ProfileIcon.propTypes = {
  color: PropTypes.string,
}

ProfileIcon.defaultProps = {
  color: '#2A42A7',
}

export default ProfileIcon
