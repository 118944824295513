import {
  Brief,
  MediaCastings,
  MediaBrief,
  TypeBrief,
  Social,
} from '../../../../../../../../types-project/Brief'

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
  ? DeepPartial<U>[]
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
}

export type CustomBriefI = Omit<
  DeepPartial<Brief>,
  'media' | 'script' | 'music' | 'inspiration'
> & {
  media?: Array<File | MediaBrief>
  script?: File | string
  music?: File | string
  inspiration?: { media: Array<MediaBrief | File> }
  product?: string
  targeted_social?: {
    social: Social | string
    type_content: 'post' | 'story' | 'reel' | 'video' | 'short'
  }
  property_rights?: number
  content_usage?: 'ad'|'organic'
}

export default {
  added_info: [undefined, undefined, undefined, undefined],
  dos: ['', '', ''],
  donts: ['', '', ''],
  mentions: ['', '', '', '', '', ''],
  redirection: '',
  media: [undefined, undefined, undefined, undefined] as any[],
  script: undefined,
  music: undefined,
  inspiration: { media: [] },
  createdAt: '',
  creative_guidelines: ['', '', ''],
  description: '',
  exclusive: undefined,
  length: { max: 0, min: 0 },
  max_applicants: 0,
  name: '',
  name_private: '',
  nb_max_vogz: 0,
  our_advice: '',
  price_vogz_ht: 0,
  on_spot: { is_on_spot: false, dotation: undefined, addresses: [] },
  private: undefined,
  favourites: [],
  remuneration: undefined,
  remuneration_vogz_coin: 2,
  scenario: ['', '', ''],
  targeting: { age: {}, gender: 'none' },
  gender: '',
  tier: '',
  to_checkout: { links: [{ url: '' }, { url: '' }, { url: '' }] },
  product: undefined,
  property_rights: undefined,
  content_usage: undefined,
  languages: { name: ''},
  country:  { name: ''},
} as CustomBriefI
