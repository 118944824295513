/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TFunction, useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import Dropzone from 'react-dropzone'
import { FormikProps, useFormik } from 'formik'
import { toast } from 'react-toastify'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import styles from './components/steps_casting.module.scss'
import Title1 from '../../../../../utils/components/Headings/Title1'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import modal from '../../../../../mobx/modal'
import i18n from '../../../../../utils/i18n'
import { BrandI, ClientI, ProductsBrand } from '../../../../../types-project/Client'
import userMobx from '../../../../../mobx/user'
import Login from '../../../../Auth/login'
import briefR from '../../../../../mobx/brief'
import brands from '../../../../brands'
import ElementPicker, { DropPhoto, ElementPickerProps } from '../EditCasting/components/ElementPicker'



const initialValues: Omit<ProductsBrand, "images"> & {images: Array<(File | { source: string, key: string }) | undefined>} = {
    url: undefined,
    name: "",
    description: "",
    price: undefined,
    note: undefined,
    images: [undefined, undefined, undefined] as Array<File | undefined>
}

function ProductPicker({ defaultSelection, onProductSelected, onProductRemoved, displayOnly, preventSelection, enableDeletion, onDelete, className }: ProductPickerProps) {
    // translation
    const { t } = useTranslation()

    if (onProductSelected === undefined) onProductSelected = () => { }
    if (onProductRemoved === undefined) onProductRemoved = () => { }

    return (
        <ElementPicker<CustomProductBrand>
            className={`w-full ${className}`}
            placeholderURL='/assets/imgs/placeholder-product.png'
            data={{
                names: userMobx.me.data?.products?.map((product) => product.name) || [],
                descriptions: userMobx.me.data?.products?.map((product) => product.description) || [],
                images: userMobx.me.data?.products?.map((product) => product.images) || []
            }}      
            pickList={userMobx.me.data?.products || []}
            defaultSelection={defaultSelection}
            onElementSelected={onProductSelected}
            onElementRemoved={onProductRemoved}
            pushHandler={() => modal.toogleVisibilityPopup({ val: true, content: <ContentModal t={t} /> })}
            pushBtnTitle='Nouveau Produit'
            disableOnClick={preventSelection}
            enableDeletion={enableDeletion}
            onDelete={onDelete}
        />
    )
}

// component to handle popup modal content
export function ContentModal({ t }: { t: TFunction<"translation", undefined> }) {

    const [isLoading, setIsLoading] = useState(false)

    // scheme for form product validation
    const validationFormScheme = Yup.object().shape({
        url: Yup.string(),
        name: Yup.string().required(t('new-casting:step2.form-product.name_required')),
        description: Yup.string().required(t('new-casting:step2.form-product.description_required')),
        price: Yup.number().required(t('new-casting:step2.form-product.price_required')),
        note: Yup.string(),
    })
    
    const formik = useFormik<CustomProductBrand>({
        initialValues: initialValues,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: validationFormScheme,
        onSubmit: async (values) => {
            const errors = await formik.validateForm(values)
            if (!(Object.keys(values).length === 0 && Object.keys(errors).length === 0)) {
                formik.validateForm(values)
            }
            const product: CustomProductBrand = values
            product.images = (product.images as Array<any>)?.filter((img) => img !== undefined) as Array<File>
            setIsLoading(true)
            await userMobx.addProduct(product, () => setIsLoading(false))
            modal.toogleVisibilityPopup({ val: false, content: undefined })
        }
    })

    

    // translation
    return (
        <form className={styles['content-modal']} onSubmit={formik.submitForm}>
            <div className={styles['header-content-modal']}>
                <Title1 text={t('new-casting:step2.form-product.title')} size={23} />
                <hr />
            </div>
            <div className={styles['body-content-modal']}>
                <div className={styles['body-content-input-modal']}>
                    <div className="flex flex-row items-baseline">
                        <label htmlFor="url">{t('new-casting:step2.form-product.url')}</label>
                        <span className="ml-2 text-[#5777FF] font-bold text-[14px]">{t('new-casting:step2.form-product.optionnal')}</span>
                    </div>
                    <input id='url' value={formik.values.url} name="url" onChange={formik.handleChange} placeholder={t('new-casting:step2.form-product.url_placeholder')} />
                    {formik.errors.url && (<span className={styles['body-content-input-modal-error']}>{formik.errors.url}</span>)}
                </div>

                <div className={styles['body-content-input-modal']}>
                    <div className="flex flex-row items-baseline">
                        <label htmlFor="name">{t('new-casting:step2.form-product.name')}</label>
                        <span className="ml-2 text-[#5777FF] font-bold text-[14px]">{t('new-casting:step2.form-product.required')}</span>
                    </div>
                    <input id='name' value={formik.values.name} name="name" onChange={formik.handleChange} placeholder={t('new-casting:step2.form-product.name_placeholder')} />
                    {formik.errors.name && (<span className={styles['body-content-input-modal-error']}>{formik.errors.name}</span>)}
                </div>

                <div className={styles['body-content-input-modal']}>
                    <div className="flex flex-row items-baseline">
                        <label htmlFor="description">{t('new-casting:step2.form-product.description')}</label>
                        <span className="ml-2 text-[#5777FF] font-bold text-[14px]">{t('new-casting:step2.form-product.required')}</span>
                    </div>
                    <textarea id='description' value={formik.values.description} onChange={formik.handleChange} name="description" placeholder={t('new-casting:step2.form-product.descritpion_placeholder')} />
                    {formik.errors.description && (<span className={styles['body-content-input-modal-error']}>{formik.errors.description}</span>)}
                </div>

                <div className={styles['body-content-input-modal']}>
                    <div className="flex flex-row items-baseline">
                        <label>{t('new-casting:step2.form-product.illustation')}</label>
                        <span className="ml-2 text-[#5777FF] font-bold text-[14px]">{t('new-casting:step2.form-product.optionnal')}</span>
                    </div>
                    <div
                        className={`${styles['div-content-file']} flex-wrap flex gap-3 pt-2 !items-center`}
                    >
                        {formik.values.images.map((image, index) => {
                            return (
                                <div className='m-auto'>
                                    <DropPhoto
                                        t={t}
                                        image={image}
                                        onRemove={(img)=> {
                                            formik.setFieldValue('images',(formik.values.images as Array<File>).map((value, index_)=> {
                                                if(index_ === index) {
                                                    return undefined
                                                } else {
                                                    return value
                                                }
                                            }))
                                        }}
                                        onAccept={(file) => {
                                            formik.setFieldValue('images',(formik.values.images as Array<File>).map((value, index_)=> {
                                                if(index_ === index) {
                                                    return file
                                                } else {
                                                    return value
                                                }
                                            }))
                                        }}
                                        key={index} 
                                    />
                                </div>
                            )
                        })}
                       
                    </div>
                    <div className={styles['body-content-input-modal-infos']}>
                        <span dangerouslySetInnerHTML={{ __html: t('new-casting:step2.form-product.format') }} />
                        <span dangerouslySetInnerHTML={{ __html: t('new-casting:step2.form-product.maxsize') }} />
                    </div>
                </div>

                <div className={styles['body-content-input-modal']}>
                    <div className="flex flex-row items-baseline">
                        <label htmlFor="note">{t('new-casting:step2.form-product.note')}</label>
                        <span className="ml-2 text-[#5777FF] font-bold text-[14px]">{t('new-casting:step2.form-product.optionnal')}</span>
                    </div>
                    <input id='note' onChange={formik.handleChange} value={formik.values.note} placeholder={t('new-casting:step2.form-product.name_placeholder')} />
                    {formik.errors.note && (<span className={styles['body-content-input-modal-error']}>{formik.errors.note}</span>)}
                </div>
                <div className={styles['body-content-input-modal']}>
                    <div className="flex flex-row items-baseline">
                        <label htmlFor="price">{t('new-casting:step2.form-product.price')}</label>
                        <span className="ml-2 text-[#5777FF] font-bold text-[14px]">{t('new-casting:step2.form-product.required')}</span>
                    </div>
                    <input id='price' onChange={formik.handleChange} value={formik.values.price} placeholder={t('new-casting:step2.form-product.price_placeholder')} />
                    {formik.errors.price && (<span className={styles['body-content-input-modal-error']}>{formik.errors.price}</span>)}
                </div>
            </div>
            <BtnBasic
                disabled={isLoading}
                isLoading={isLoading}
                spinnerColor='white'
                type='inverted-sky-violet' 
                typeBtn='button' onclick={() => { formik.submitForm(); }} 
                btnName={t('new-casting:step2.form-product.save')} 
            />
        </form>
    )
}

interface ProductPickerProps {
    defaultSelection?: ElementPickerProps<CustomProductBrand>['defaultSelection']
    onProductSelected?: ElementPickerProps<CustomProductBrand>['onElementSelected']
    onProductRemoved?: ElementPickerProps<CustomProductBrand>['onElementRemoved']
    displayOnly?: ElementPickerProps<CustomProductBrand>['displayOnly']
    preventSelection?: ElementPickerProps<CustomProductBrand>['disableOnClick']
    enableDeletion?: ElementPickerProps<CustomProductBrand>['enableDeletion']
    onDelete?: ElementPickerProps<CustomProductBrand>['onDelete']
    className?: string
}

ProductPicker.defaultProps = {
    onProductSelected: () => { },
    onProductRemoved: () => { },
    defaultSelection: undefined,
    displayOnly: [],
    preventSelection: false,
    enableDeletion: false,
    onDelete: () => { },
    className: ''
}

export type CustomProductBrand = Omit<ProductsBrand, "images"> & {images: Array<(File | { source: string, key: string }) | undefined>}
export default observer(ProductPicker)
