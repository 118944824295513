/* eslint-disable react/prop-types */
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
// import { motion } from "framer-motion";
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import styles from './components/card-set-publication.module.scss'
import { BaCustom, MediaBA, MyCasting } from '../../../../../types-project/Brief'

import briefR from '../../../../../mobx/brief'
import { displayAvatar, getAvatar, getDayt, getHour } from '../../../../../utils/helpers'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import CardVogz from '../CardVogz'

const CardSetPublication: FC<
  {
    showModalCalendar:MediaBA,
    setModalCalendar:Dispatch<SetStateAction<MediaBA|null>>,
    briefApplication:BaCustom | MyCasting | undefined
    className?:string
  }> = ({ showModalCalendar, setModalCalendar, briefApplication, className }) => {
  type PublicationDate = {
    date: string,
    hour: string
  }
  const { t } = useTranslation()
  const navigate = useNavigate()
  const initValueCalendar = { 
    date: showModalCalendar?.publication.date? getDayt(showModalCalendar?.publication.date!) : '' ,
    hour: showModalCalendar?.publication.date ? getHour(showModalCalendar?.publication.date!) : '' 
  }
  const validationSchemaCalendar = Yup.object().shape({
    date: Yup.string().required('Merci de renseigner la date de publication'),
    hour: Yup.string().required('Merci de renseigner l\'heure de publication'),
  })
  const handlePublicationDate = () => {    
    const propsPublicationDate = {
      idBA: briefR.briefApplication.data?.at(0)?._id ?? '',
      idMedia: showModalCalendar?._id ?? '',
      date: `${formikCalendar.values.date} ${formikCalendar.values.hour}`,
    }
    briefR.publicationDate(propsPublicationDate,() => {      
      setModalCalendar(null)
      toast.success(t('mycastings:private-deal:publication-validate'))
    })
  }
  const formikCalendar = useFormik<PublicationDate>({
    initialValues: initValueCalendar,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: validationSchemaCalendar,
    onSubmit: () => {
      handlePublicationDate()
    },
  })
  useEffect(() => {
    const newValues = {
      date: showModalCalendar?.publication.date ? getDayt(showModalCalendar?.publication.date!) : '',
      hour: showModalCalendar?.publication.date ? getHour(showModalCalendar?.publication.date!) : ''
    }
    formikCalendar.setValues(newValues)
  }, [showModalCalendar])

  return (
    <article id='calendar-modal' className={`${styles['parent-modal']} ${className? styles[className]: ''}`}>
      <div className={styles['calendar-modal']}>
        <div className={styles['top-part']}>
          <h1>{t('mycastings:private-deal:publication-date')}</h1>
          <p>{t('mycastings:private-deal:publication-desc')}</p>
          <button type='button' onClick={()=> setModalCalendar(null)}>
            <i className='fa-regular fa-circle-xmark'/>
          </button>
        </div>
        <div className={styles['bottom-part']}>
          <div className={`flex flex-col w-2/5 ${styles['form-part']}`}>
            <h4>#{showModalCalendar.readable_id}</h4>
            <br />
            <FormikProvider value={formikCalendar}>
              <form onSubmit={formikCalendar.handleSubmit} className={styles['form']}>
                <div className='flex flex-col'>
                  <label htmlFor="publication-date">{t('mycastings:private-deal:publication-date')}</label>
                  <input
                    required
                    id='publication-date'
                    className={styles['text-field']}
                    type="date"
                    onChange={formikCalendar.handleChange}
                    value={formikCalendar.values.date}
                    min={new Date().toISOString().substring(0, 10)}
                    name="date"
                  />
                  {formikCalendar.errors.date &&
                    <p className='error leading-normal'>{formikCalendar.errors.date}</p>
                  }
                </div>
                <div className='flex flex-col'>
                  <label htmlFor="publication-hour">{t('mycastings:private-deal:publication-hour')}</label>
                  <input
                    required
                    id='publication-hour'
                    className={styles['text-field']}
                    type="time"
                    onChange={formikCalendar.handleChange}
                    value={formikCalendar.values.hour}
                    name="hour"
                  />
                  {formikCalendar.errors.hour &&
                    <p className='error leading-normal'>{formikCalendar.errors.hour}</p>
                  }
                </div>
                <br />
              </form>
            </FormikProvider>
            <BtnBasic
              disabled={!formikCalendar.isValid}
              type="inverted-sky-violet"
              btnName={t('mycastings:private-deal:confirm')}
              typeBtn="submit"
              onclick={() => {
                handlePublicationDate()
              }}
            />
          </div>
          <div className='flex'>
            <CardVogz
              briefApplication={briefApplication!}
              media={showModalCalendar}
              showBookmarked
              className={'mb-8'}
            />
          </div>
        </div>
      </div>
    </article>
  )
}

export default observer(CardSetPublication)
