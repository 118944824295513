import React, { FC, useRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useFormik, FormikProvider, FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { BrandI, Category, ClientI } from '../../../../../../types-project/Client'
import s from '../styles.module.scss'
import style from './components/styles.module.scss'
import TextField from '../../../../../../utils/components/Ui/inputs/TextField'
import userMobx from '../../../../../../mobx/user'
import Products from './components/Products'
import BtnBasic from '../../../../../../utils/components/Button/BtnBasic'
import ProductPicker from '../../../../../Castings/NewCasting/subscreens/Steps/ProductPicker'
import AddressPicker from '../../../../../Castings/NewCasting/subscreens/EditCasting/components/AddressPicker/AddressPicker'
import modal from '../../../../../../mobx/modal'
import DropdownCheckbox, { DropdownCheckboxOption } from '../../../../../../utils/components/Ui/dropdown/DropdownCheckbox'

type ProfileEditLogo = File | Pick<any, string> & {
  logo: BrandI['logo']
}
const BodyBrand: FC<BodyBrandProps> = ({ isAgency }) => {
  // translation
  const { t } = useTranslation()
  const [hasChanged, setHasChanged] = useState(false)
  // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  const brand = isAgency ? userMobx.getBrandSession()! : userInfos?.brand
  const [numInputs, setNumInputs] = useState(brand?.info?.social_links?.length ?? 0 > 2 ? brand?.info?.social_links?.length! : 2)

  const categoryRef = useRef<DropdownCheckbox>(null)

  const addSocialInput = () => {
    if (numInputs < 5) setNumInputs(numInputs + 1)
  }
  const imgPickerBrand = useRef<HTMLInputElement>(null)
  const imgLogo = useRef<HTMLImageElement>(null)

  useEffect(() => {
    userMobx.getCategories(() => {
      brand?.categories && (
        brand?.categories.forEach((category) => {
          categoryRef.current?.select(category._id)
        })
      )}
    )
  }, [])

  const formikLogoBrand = useFormik<ProfileEditLogo>({
    initialValues: {
      logo: isAgency ? brand?.logo : userInfos._company.logo ?? '',
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: null,
    onSubmit: async (value) => {
      if (value instanceof File) {
        if (isAgency)
          userMobx.updateBrandLogo(brand?._id ?? '', value, () => {
            imgLogo.current!.src = URL.createObjectURL(value)
          })
        else
          userMobx.updateCompany(value, () => {
            imgLogo.current!.src = URL.createObjectURL(value)
            userInfos!._company.logo = URL.createObjectURL(value)
          })
      } else {
        imgPickerBrand.current?.click()
      }
    },
  })

  // verification of the social media inputs with Yup
  const validationSchema = Yup.object().shape({
    info: Yup.object().shape({
      text: Yup.string(),
      url: Yup.string().url(t('myprofil:brand-page.validation-url')),
      social_links: Yup.array().of(
        Yup.object().shape({
          url: Yup.string().url(t('myprofil:brand-page.validation-url')),
        })
      )
    }),
    categories: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
      })
    )
  })
  // initial values for the form
  const initialValues = {
    info: {
      text: brand?.info?.text,
      url: brand?.info?.url,
      social_links: brand?.info?.social_links?.map((link) => ({
        url: link.url,
      })) || [{ url: '' }],
    },
    categories: brand?.categories,
  }
  // formik hook to handle the form state and validation with Yup schema and the initial values above
  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const brnd: BrandI = { ...values }
      await userMobx.updateBrand(brand?._id!, brnd)
    },
  })

  let categoryOptions: DropdownCheckboxOption[] = []
  if (userMobx.categories.data) {
    categoryOptions = userMobx.categories.data.map((category: Category) => ({
      id: category._id,
      label: category.name,
      data: category as Category,
    }))
  }

  return (
    <div className={s['body-brand']}>
      <div className="flex w-full items-start">
        <FormikProvider value={formikLogoBrand}>
          <form name="profile" onSubmit={formikLogoBrand.handleSubmit}>
            <div
              onClick={(e) => {
                imgPickerBrand.current?.click()
              }}
              className={s['img-brand']}
            >
              <img
                ref={imgLogo}
                src={`${isAgency ? brand?.logo : userInfos?._company?.logo ?? ''}`}
                alt="company logo"
              />
              <i className="fa-solid fa-camera" />
            </div>
            <input
              className="hidden"
              ref={imgPickerBrand}
              type="file"
              name='logo'
              onChange={(e) => {
                const file = e.currentTarget.files
                const fileLength = file?.length ?? -1
                if (fileLength > 0) {
                  formikLogoBrand.setValues(file![0])
                  formikLogoBrand.submitForm()
                }
              }}
              accept="image/*"
            />
          </form>
        </FormikProvider>
        <div className={s['name-brand']}>
          <h3>{isAgency ? brand?.name : userInfos?.brand?.name ?? ''}</h3>
          <p>{t('myprofil:brand-page.desc')}</p>
        </div>
      </div>
      <FormikProvider value={formik}>
        <form name="profile" onSubmit={formik.handleSubmit}>
          <div className={s['info-brand']}>
            <div className={s['part-info']}>
              <div className={s['category']}>
                <h3>{t('myprofil:brand-page.choose-category')}</h3>
                <DropdownCheckbox
                  ref={categoryRef}
                  isSearchable
                  multiSelection
                  placeholder={t('myprofil:brand-page.category')}
                  searchPlaceholder={t('myprofil:brand-page.search-category')}
                  options={categoryOptions}
                  onElementSelected={(element, allSelection) => {
                    formik.setFieldValue('categories', allSelection.flatMap(element => 
                      JSON.parse(JSON.stringify(element.data) ?? '{}')
                      ))
                    setHasChanged(true)
                  }}
                  onElementRemoved={(element, allSelection) => {
                    formik.setFieldValue('categories', allSelection.flatMap(element =>
                      JSON.parse(JSON.stringify(element.data) ?? '{}')
                    ))
                    setHasChanged(true)
                  }}
                />
              </div>
              <textarea
                name="info.text"
                id="infoText"
                className={`w-full mt-2 ${s['text-area-describe-brief']}`}
                onChange={(e) => {
                  formik.handleChange(e)
                  setHasChanged(true)
                }}
                cols={25}
                rows={0}
                value={formik.values.info.text}
                placeholder={t('myprofil:brand-page.placeholder')}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t('myprofil:brand-page.info'),
                }}
              />
            </div>
            <hr />
            <div className={s['socials-brand']}>
              <div className={`${s['social-input']} ${s['site']}`}>
                <TextField
                  label="Site Web"
                  type="text"
                  name="info.url"
                  error={formik.errors.info?.url}
                  // onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e)
                    setHasChanged(true)
                  }}
                  placeholder="https://www.website.com/"
                  value={formik.values.info.url}
                />
              </div>

              <hr />
              <div className={s['social-input']} data-type={!formik.values.info.social_links[0]?.url || formik.values.info.social_links[0]?.url.includes('instagram.com')?'instagram':'network'}>
                <TextField
                  type="text"
                  error={(formik.errors.info?.social_links?.at(0) as FormikErrors<{ url: string; }>)?.url}
                  name="info.social_links[0].url"
                  onChange={(e) => {
                    formik.handleChange(e)
                    setHasChanged(true)
                  }}
                  placeholder="https://instagram.com/"
                  label={!formik.values.info.social_links[0]?.url || formik.values.info.social_links[0]?.url.includes('instagram.com')?'Instagram':t('myprofil:brand-page.placeholder-network')}
                  value={formik.values.info.social_links[0]?.url}
                />
              </div>
              <div className={s['social-input']} data-type={!formik.values.info.social_links[1]?.url || formik.values.info.social_links[1]?.url.includes('tiktok.com')?'tiktok':'network'}>
                <TextField
                  type="text"
                  error={(formik.errors.info?.social_links?.at(1) as FormikErrors<{ url: string; }>)?.url}
                  name="info.social_links[1].url"
                  onChange={(e) => {
                    formik.handleChange(e)
                    setHasChanged(true)
                  }}
                  placeholder="https://Tiktok.com/"
                  label={!formik.values.info.social_links[1]?.url || formik.values.info.social_links[1]?.url.includes('tiktok.com')?'TikTok':t('myprofil:brand-page.placeholder-network')}
                  value={formik.values.info.social_links[1]?.url}
                />
              </div>
              {Array.from({ length: numInputs - 2 }, (_, i) => i + 2).map(
                (i) => (
                  <div className={s['social-input']} key={i}>
                    <TextField
                      type="text"
                      
                      error={(formik.errors.info?.social_links?.at(i) as FormikErrors<{ url: string; }>)?.url}
                      name={`info.social_links[${i}].url`}
                      onChange={(e) => {
                        formik.handleChange(e)
                        setHasChanged(true)
                      }}
                      placeholder="https://exemple.com/"
                      label={t('myprofil:brand-page.placeholder-network')}
                      value={formik.values.info.social_links[i]?.url}
                    />
                  </div>
                )
              )}
              <button
                data-btn-add
                className="self-center mt-5"
                type="button"
                onClick={() => {
                  addSocialInput()
                }}
              >
                <i className="fa-light fa-circle-plus" />
                {t('myprofil:add-social-network')}
              </button>
            </div>
          </div>
          <br />
          <div className={`${style['form-btn']} opacity-40`}>
            <BtnBasic
              disabled={!hasChanged}
              type="sky-violet-bordered"
              typeBtn="submit"
              btnName={t('myprofil:save')}
            />
          </div>
          <br />
        </form>
      </FormikProvider>
      <div className={style['pickers-container']}>
        <section className={style['products']}>
          <h3>{t('myprofil:brand-page.prod.title')}</h3>
          <p>{t('myprofil:brand-page.prod.desc')}</p>
          <div className={style['content-product']}>
            <ProductPicker
              preventSelection
              enableDeletion
              onDelete={(product) => {
                modal.setTitle(t('myprofil:brand-page.prod.delete-product'))
                modal.setDesc(t('myprofil:brand-page.prod.delete-product-desc'))
                modal.setOnConfirm(async () => {
                  await userMobx.deleteProduct(product._id)
                })
                modal.toogleAlert('warning')
              }}
            />
          </div>
        </section>
        <br />
        <section className={style['addresses']}>
          <h3>{t('myprofil:brand-page.addresses.title')}</h3>
          <p>{t('myprofil:brand-page.addresses.desc')}</p>
          <div className={style["content-addresses"]}>
            <AddressPicker
              preventSelection
              enableDeletion
              onDelete={(address) => {
                modal.setTitle(t('myprofil:brand-page.addresses.delete-address'))
                modal.setDesc(t('myprofil:brand-page.addresses.delete-address-desc'))
                modal.setOnConfirm(async () => {
                  await userMobx.deleteAddress(address._id)
                })
                modal.toogleAlert('warning')
              }}
            />
          </div>
        </section>
      </div>
    </div>
  )
}
interface BodyBrandProps {
  isAgency?: boolean
}
BodyBrand.propTypes = {
  isAgency: PropTypes.bool,
}
BodyBrand.defaultProps = {
  isAgency: false,
}

export default observer(BodyBrand)
