/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as Yup from 'yup'
import moment from 'moment'
import castingFormModel, { DeepPartial } from './castingFormModel'
import i18n from '../../../../../../../../utils/i18n'
import { Brief } from '../../../../../../../../types-project/Brief'

const {
  added_info,
  dos,
  donts,
  createdAt,
  creative_guidelines,
  description,
  exclusive,
  inspiration,
  length,
  name,
  name_private,
  our_advice,
  ratio,
  remuneration,
  remuneration_vogz_coin,
  scenario,
  tier,
  with_product,
  to_checkout,
  property_rights,
  content_usage,
  language,
  country
} = castingFormModel

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/

export default (type: 'exclusive' | 'creator' | 'private', opt: 'casting' | 'casting-plus' | 'on-spot', content_type: 'ugc' | 'influence', min: number) => [
  // Form 1
  type === 'creator' || type === 'private'
    ? type === 'private'
      ? Yup.object().shape({
          with_product: Yup.boolean().required(i18n.t('form-casting:casting:required-step')),
          ratio: Yup.mixed()
            .oneOf(['landscape', 'portrait'])
            .required(
              i18n.t('form-casting:casting.required-form-step', {
                name: 'ratio',
                defaultValue: 'Étape requise',
              })
            ),
          product: opt === 'casting-plus' 
            ? Yup.object().required(i18n.t('form-casting:casting:product-required'))
            : Yup.object().optional(),
          on_spot: opt === 'on-spot'
            ? Yup.object().shape({
              is_on_spot: Yup.boolean().isTrue().required(),
              addresses: Yup.array(Yup.object()).min(1, i18n.t('form-casting:casting:address-required')).required(i18n.t('form-casting:casting:address-required')),
              dotation: Yup.number().optional(),
            }).required(i18n.t('form-casting:casting:address-required'))
            : Yup.object().optional(),
          length: Yup.object().shape({
            min: Yup.number()
              .required(
                i18n.t('form-casting:casting.this-required', {
                  field: 'Un minimum',
                })
              )
              .min(2, 'La taille doit être au minimum de 2.'),
            max: Yup.number()
              .label('length')
              .moreThan(Yup.ref('min'))
              .required(
                i18n.t('form-casting:casting.this-required', {
                  field: 'Un maximum',
                })
              )
              .max(555, 'La durée doit être au maximum de 555.'),
          }),
          favourites: Yup.array(Yup.string())
            .min(1, i18n.t('form-casting:casting:vogzter-min'))
            .max(1,i18n.t('form-casting:casting:vogzter-max'))
            .required(i18n.t('form-casting:casting:vogzter-min')),
        })
      : Yup.object().shape({
          with_product: Yup.boolean().required(i18n.t('form-casting:casting:required-step')),
          ratio: content_type === 'ugc'?
            Yup.mixed()
              .oneOf(['landscape', 'portrait'])
              .required(
                i18n.t('form-casting:casting.required-form-step', {
                  name: 'ratio',
                  defaultValue: 'Étape requise',
                })
              )
              : Yup.mixed().optional() ,
          product: opt === 'casting-plus' 
            ? Yup.object().required(i18n.t('form-casting:casting:product-required'))
            : Yup.object().optional(),
          on_spot: opt === 'on-spot'
            ? Yup.object().shape({
              is_on_spot: Yup.boolean().isTrue().required(),
              addresses: Yup.array(Yup.object()).min(1, i18n.t('form-casting:casting:address-required')).required(i18n.t('form-casting:casting:address-required')),
              dotation: Yup.number().optional(),
            }).required(i18n.t('form-casting:casting:address-required'))
            : Yup.object().optional(),
          targeted_social: content_type === 'influence' ? 
            Yup.object().shape({
              social: Yup.object().shape({
                name: Yup.string().required(i18n.t('form-casting:casting:required-step')),
              }).required(i18n.t('form-casting:casting:required-step')),
              type_content: Yup.string().required(i18n.t('form-casting:casting:required-step')),
            }).required(i18n.t('form-casting:casting:required-step'))
            : Yup.object().optional(),
          language: Yup.object().shape({
              name: Yup.string().required(i18n.t('form-casting:casting:required-step')),
            }).required(i18n.t('form-casting:casting:required-step')),
          country:  Yup.object().shape({
              name: Yup.string(),
            }),
          length: content_type === 'ugc'?
            Yup.object().shape({
              min: Yup.number()
                .required(
                  i18n.t('form-casting:casting.this-required', {
                    field: 'Un minimum',
                  })
                )
                .min(2, 'La taille doit être au minimum de 2.'),
              max: Yup.number()
                .label('length')
                .moreThan(Yup.ref('min'))
                .required(
                  i18n.t('form-casting:casting.this-required', {
                    field: 'Un maximum',
                  })
                )
                .max(555, 'La durée doit être au maximum de 555.'),
            })
            : Yup.object().optional(),
        })
    : Yup.object().shape({
        exclusive: Yup.object().shape({
          reward: Yup.object().shape({
            type: Yup.string()
              .oneOf(['amount', 'percentage'])
              .required('Champs requis.'),
            value: Yup.number()
              .label('Valeur du coupon')
              .required('Champs requis.'),
            // .min(50, 'La valeur du coupon doit être au minimum de 50.'),
          }),
          reward_lost: Yup.object().shape({
            type: Yup.string()
              .oneOf(['amount', 'percentage'])
              .optional(),
            value: Yup.number()
              .label('Valeur du coupon')
              .optional(),
          }),
        }),
        language: Yup.string().required( i18n.t('form-casting:casting.required-form-step', {
          name: 'ratio',
          defaultValue: 'Étape requise',
        })),
        country: Yup.string().required( i18n.t('form-casting:casting.required-form-step', {
          name: 'ratio',
          defaultValue: 'Étape requise',
        })),
        ratio: Yup.mixed()
          .oneOf(['landscape', 'portrait'])
          .required(
            i18n.t('form-casting:casting.required-form-step', {
              name: 'ratio',
              defaultValue: 'Étape requise',
            })
          ),
        length: Yup.object().shape({
          min: Yup.number()
            .required(
              i18n.t('form-casting:casting.this-required', {
                field: 'Un minimum',
              })
            )
            .min(2, 'La taille doit être au minimum de 2.'),
          max: Yup.number()
            .label('length')
            .moreThan(Yup.ref('min'))
            .required(
              i18n.t('form-casting:casting.this-required', {
                field: 'Un maximum',
              })
            )
            .max(555, 'La durée doit être au maximum de 555.'),
        }),
      }),
  // Form 2
  Yup.object().shape({
    name: Yup.string().required(`Ce champs est requis`),
    name_private: Yup.string().optional(),
    description: content_type === 'ugc'? Yup.string().required(`Ce champs est requis`) : Yup.string().optional(),
    our_advice: Yup.string().optional(),
    creative_guidelines: Yup.array().of(Yup.string()).optional(),
    scenario: Yup.array().of(Yup.string().nullable()).optional(),
    dos: Yup.array().of(Yup.string().optional()),
    donts: Yup.array().of(Yup.string().optional()),
    property_rights: Yup.number().optional(),
    content_usage: Yup.string().optional(), 
    mentions: Yup.array().of(Yup.string().optional()),
    added_info: content_type === 'ugc'? Yup.array().test(
      "at-least-one-undefined",
      'Ces informations sont requises.',
      function(array) {
        return !array?.some(str => str === null);
      }
    ):Yup.array().of(Yup.string().nullable()).optional(),
    to_checkout: Yup.object().shape({
      links: Yup.array().label('to_checkout').optional(),
    }),
    remuneration: Yup.number()
    .min(min, i18n.t('form-casting:casting.cards-remuneration.rem-error', { min: min }))
    .label(i18n.t('form-casting:casting.cards-remuneration.rem-required'))
    .required('Champs requis.'),
  }),
  // Form 3
  Yup.object().shape({
    media: content_type === 'ugc' ? Yup.array()
      // Vérifie si au moins un média à été ajouté
      .test('firstFile', "Vous devez ajouter au moins 1 média", value => {
        if (!value || value.length === 0) {
          return false
        }
        if(!value[0]){
          return false
        }
        return true
      }) : Yup.array().optional(),
    inspiration: Yup.object().shape({
      file: Yup.mixed().optional(),
    }),
  }),

]
