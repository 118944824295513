/* eslint-disable no-prototype-builtins */
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import Modal from 'react-modal'
import Lottie from 'react-lottie'
import { Splide, SplideSlide } from '@splidejs/react-splide'

import modal from '../../../../mobx/modal'

import styles from './styles.module.scss'
import * as animationData from '../../../../assets/animations/successful.json'
import CardVogz from '../../../../pages/Castings/MyCasting/components/CardVogz'
import utils from '../../../../mobx/utils'
import briefR from '../../../../mobx/brief'
import { ApplicantBa, Applicant } from '../../../../types-project/Brief'
import { getAvatar } from '../../../helpers'


const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    inset: 0,
    position: 'relative',
    transform: 'initial',
    bottom: 'auto',
    height: '100%',
    transition: '1s',
    background: 'none',
    overflow: 'hidden scroll',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.3)',
    backdropFilter: ' blur(15px)',
  },
}

const ModalPopUp: FC = () => {

  // destructuration of selection popup from mobx
  const { open, content } = modal.popup

  return (
    <>
      <Modal
        ariaHideApp={false}
        closeTimeoutMS={500}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        isOpen={open}
        onRequestClose={() => {
          modal.toogleVisibilityPopup({ val: false, content: undefined })
        }}
        style={customStyles}
      >

        <motion.main
          initial={{ opacity: 0, y: '-800px' }}
          transition={{ duration: 0.5 }}
          animate={{ opacity: 1, y: 0 }}
          className={styles['content-modal-cart']}
        >
       {open &&  (<button type='button' className={styles['content-modal-btn']} onClick={() => {   modal.toogleVisibilityPopup({ val: false, content: undefined })}}>
            <i className="fa-solid fa-xmark" />
          </button>)}
          {content}
        </motion.main>
      </Modal>
    </>
  )
}

export default observer(ModalPopUp)
