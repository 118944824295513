import React, { FC, useState } from "react";
import PropTypes from 'prop-types'
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { VogzterSearchResult, VogzterSearchResultMedia } from '../../../../types-project/Client'
import VogzterPicture from '../../Profil-vogzters/components/VogzterPicture'
import styles from './components/vogzter-search-item.module.scss'
import { formatVogzterName } from "../../../../utils/helpers";
import userMobx from "../../../../mobx/user";
import ListOverflowHandler from "../ListOverflowHandler";
import Badge from "../Badge";
import Title1 from "../../../../utils/components/Headings/Title1";
import { Instagram, Tiktok } from "../../../../utils/components/Ui/icons";
import modal from "../../../../mobx/modal";

interface VogzterSearchItemProps {
  vogzter: VogzterSearchResult
}

const VogzterSearchItem: FC<VogzterSearchItemProps> = ({ vogzter }) => {

  const redirectionLink = `/dashboard/profil-vogzter/${vogzter.objectID}`
  const bubbleClassName = "!bg-alice-blue !text-blue-dark !border-blue-dark text-xs !py-px !px-2 !my-1 !mx-0.5 !rounded-[3px]"
  const isFavorite = userMobx.brand.data?.favourites?.includes(vogzter.objectID)
  const videos: Array<VogzterSearchResultMedia> = [
    vogzter.profileVideoIntro, 
    ...vogzter.profileVideoDemo?.map(video => video) ?? []
  ].filter(video => video?.source) as Array<VogzterSearchResultMedia>
  const [GIFHandler, updateGIFHandler] = useState(videos.map(() => ({ animate: false })))

  const { t } = useTranslation()

  return (
    <div className={styles['vogzter-search-item']}>
      <a 
        className={styles['top-part']}
        href={redirectionLink}
      >
        <div className="flex flex-col w-full min-h-[93px] pl-3 pr-3 overflow-visible z-[2]">
          <div className="flex w-full">
            <VogzterPicture
              className={`mr-2 ${styles['vogzter-picture']}`}
              href={redirectionLink}
              gender={vogzter.gender}
              imgSource={vogzter.picture}
              lastActivity={vogzter.lastActivity}
              showCertified={vogzter.certified}
              size={60}
            />
            <div className="flex flex-col flex-1 w-full h-full justify-center">
              <span>
                <h4>{formatVogzterName(vogzter.firstName, vogzter.lastName)}</h4>
                <button
                  type='button'
                  onClick={(e) => {
                    e.preventDefault()
                    if (isFavorite) {
                      userMobx.deleteFavouriteVogzters(vogzter.objectID)
                      return
                    }
                    userMobx.addFavouriteVogzters(vogzter.objectID)
                  }}
                >
                  {isFavorite ? (
                    <i className="fa-solid fa-heart text-[#F41A1A]" />
                  ) : (
                    <i className="fa-solid fa-heart text-[#FFD1D1]" />
                  )}
                </button>
              </span>
              {vogzter.address && 
                <i className={styles['location']}>
                  <a href={`https://www.google.fr/maps/place/${vogzter.address}`} target="_blank" rel="noopener noreferrer">
                    {vogzter.address}
                  </a>
                </i>
              }
              <div className="mt-1 cursor-default"
                onClick={(e) => e.preventDefault()}
              >
                {/* 
                  The following condition reduces the number of re-render
                  It also checks if algolia returns the full object instead of just the id
                */}
                {vogzter.categories && vogzter.categories.at(0)?.name && (
                  <ListOverflowHandler
                    list={(vogzter.categories ?? []).map((value) => 
                      <Badge className={bubbleClassName} label={value.name} />
                    )}
                    renderOverflow={(nbOverflow) => 
                      <Badge className={bubbleClassName} label={`+${nbOverflow}`} />
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div 
          className={`w-full flex flex-1 items-center cursor-default  ${videos.length > 1 ? 'scroll-container-x pb-2 justify-start scroll-container-x ml-2' : 'w-full justify-center'}`}
          onClick={e => e.preventDefault()}
        >
          {videos.length === 0 ? (
            <div className={styles['media-placeholder']}>
              <Title1 text={t('favorite:vogzter-search-result:placeholder-media:title')} size={18} />
              <span role="img" aria-label="">{t('favorite:vogzter-search-result:placeholder-media:desc')}</span>
            </div>
          ) : videos.map((video, i) => (
            <div 
              key={i} 
              className={styles['media-preview']}
              onMouseEnter={() => 
                updateGIFHandler(prev => {
                  const newGIFHandler = [...prev]
                  newGIFHandler[i] = { animate: true }
                  return newGIFHandler
                })
              }
              onMouseLeave={() => 
                updateGIFHandler(prev => {
                  const newGIFHandler = [...prev]
                  newGIFHandler[i] = { animate: false }
                  return newGIFHandler
                })
              }
              onClick={(e) => {
                modal.toogleModalVideo(video.source, true)
                e.preventDefault()
              }}
            >
              <i className="fa-sharp fa-solid fa-play" />
              <img src={(GIFHandler.at(i)?.animate ? video.gif : video.thumbnail) ?? '/assets/imgs/placeholder-media.png'} alt="" />
            </div>
          ))}
        </div>
      </a>
      <div className={styles['bottom-info']}>
        <div className={styles['bg-gradient']}>
          {/* These divs are circles */}
          <div />
          <div />
          <div />
        </div>
        <span role="img" aria-label="">
          🎂 {vogzter.age}
          <p>{t('favorite:vogzter-search-result:vogzter-age')}</p>
        </span>
        <span role="img" aria-label="">
          🎥 {vogzter.vogzCount}
          <p>{t('favorite:vogzter-search-result:vogz-sent')}</p>
        </span>
        <span role="img" aria-label="">
          🏆 {vogzter.collabWonCount}
          <p>{t('favorite:vogzter-search-result:vogz-sold')}</p>
        </span>
      </div>
    </div>
  )
}

VogzterSearchItem.propTypes = {
  vogzter: PropTypes.any.isRequired,
}

VogzterSearchItem.defaultProps = {
}

export default observer(VogzterSearchItem)