/* eslint-disable react/react-in-jsx-scope */
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import userR from '../../../mobx/user'
import NewBrand from '../new'

function EditBrand() {
  // params got from react router dom
  const { id } = useParams<{ id: string }>()
  useEffect(() => {
    userR.getBrand(id as string)
    userR.getListGuestsFromBrand(id as string)
    return () => {
      userR.resetBrand()
    }
  }, [])

  return (
    <>
      {!userR.brand.loading && !userR.brand.error ? (
        <NewBrand brand={userR.brand.data} loading={userR.brand.loading} />
      ) : (
        userR.brand.error && <h3>ERROR</h3>
      )}
    </>
  )
}

export default observer(EditBrand)
