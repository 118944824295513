/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/prop-types */
import { Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Form, Formik, useField, FieldHookConfig, FormikErrors, useFormik, useFormikContext, getIn } from 'formik'
import { at } from 'lodash'
import { observer } from 'mobx-react'
import * as Yup from 'yup'
import React, { useState, useEffect, Dispatch, SetStateAction, Fragment, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'
import { getName as getNameCountries } from 'i18n-iso-countries'
import { getName as getNameLanguages } from '@cospired/i18n-iso-languages'
import { blue } from '@mui/material/colors'

import briefR from '../../../../../../../mobx/brief'
import userMobx from '../../../../../../../mobx/user'
import { Brief } from '../../../../../../../types-project/Brief'
import { Address, ProductsBrand, Vogzter } from '../../../../../../../types-project/Client'
import FieldForm from '../../FieldForm'
import { formatVogzterName, getAvatar } from '../../../../../../../utils/helpers'

import SectionForm from '../../SectionForm'
import styles from '../form.module.scss'
import castingFormModel, {
  CustomBriefI,
  DeepPartial,
} from './FormModel/castingFormModel'
import { Form1Props, FormProps } from './FormModel/type-forms'
import CardFavouriteVogzter from '../../../../../../Favorites-vogzters/components/CardFavouriteVogzter'
import Btn1 from '../../../../../../../utils/components/Button/Btn1'
import FavoritesVogzters from '../../../../../../Favorites-vogzters'
import VogzterPickerItem from '../../../Steps/components/VogzterPicker/VogzterPickerItem'
import BriefTypeSectionForm from '../../BriefTypeSectionForm'
import ProductPicker, { CustomProductBrand } from '../../../Steps/ProductPicker'
import ElementPicker from '../ElementPicker'
import modal from '../../../../../../../mobx/modal'
import AddressPicker from '../AddressPicker/AddressPicker'


function Form1({
  brief,
  values,
  errors,
  onBriefTypeChanged
}: {
  brief: Form1Props,
  values: CustomBriefI,
  errors?: FormikErrors<CustomBriefI>
  onBriefTypeChanged: (type: 'casting' | 'casting-plus' | 'on-spot') => void
}) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      transition: '1s',
      border: 'none',
    },
  }
  const [userLanguage, setUserLanguage] = useState(navigator.language);

  useEffect(() => {
    userMobx.getFavouriteVogzters()
    briefR.getAllBriefsFromBrand()
    setUserLanguage(navigator.language);

  }, [])
  useEffect(() => {
    if (window.location.hash.substring(1))
      window.document
        .getElementById(
          `${window.location.hash
            .substring(1)
            .replaceAll('%20', ' ')
            .replaceAll('%C3%A9', 'é')}`
        )!
        ?.scrollIntoView({ block: 'center' })
  }, [userMobx.favouriteVogzters])
  const brand = userMobx.brand.data
  // translation
  const { t } = useTranslation()
  const [openSelect, setOpenSelect] = useState(false)
  const {
    with_product,
    // remuneration,
    description,
    targeting,
    ratio,
    length,
    exclusive,
    product,
    on_spot
  } = errors!
  const handleOpenModal = () => {
    setOpenSelect(true)
  }
  const targetedSocial = [
    { id: 1, name: 'Instagram', value: 'instagram', type: ['story', 'reel'], icon: 'fa-brands fa-instagram' },
    { id: 2, name: 'TikTok', value: 'tiktok', type: ['story', 'post'], icon: 'fa-brands fa-tiktok' },
    { id: 3, name: 'Youtube', value: 'youtube', type: ['video', 'short'], icon: 'fa-brands fa-youtube' },
    { id: 4, name: 'Snapchat', value: 'snapchat', type: ['story'], icon: 'fa-brands fa-snapchat' },
  ]
  const socialTargetSelected = (value: CustomBriefI['targeted_social']) => {
    return targetedSocial.find(e => e.value === ((value as CustomBriefI['targeted_social'])?.social.name || (value as Brief['targeted_social'])?.social))
  }
  const [displayPropertyRights, setDisplayPropertyRights] = useState<boolean>(false)
  const propertyRights = [
    { value: 6, txt: `6 ${t('new-casting:step3.month')}` },
    { value: 12, txt: `1 ${t('new-casting:step3.year')}` },
    { value: 36, txt: `3 ${t('new-casting:step3.years')}` },
    { value: 60, txt: `5 ${t('new-casting:step3.years')}` },
  ]
  const handleCloseModal = () => {
    setOpenSelect(false)
  }

  const optionsGender = [
    {
      label: 'Hommes',
      value: 'male',
    },
    {
      label: 'Femmes',
      value: 'female',
    },
    {
      label: 'Les Deux',
      value: 'none',
    },
  ]

  const optionsCoupon = [
    {
      label: t('form-casting:casting.cards-casting-coupon.type-coupon.online'),
      value: 'online',
    },
    {
      label: t('form-casting:casting.cards-casting-coupon.type-coupon.store'),
      value: 'store',
    },
  ]

  const optionsCouponPrice = [
    {
      label: t(
        'form-casting:casting.cards-casting-coupon.value-coupon-money.euro'
      ),
      value: 'amount',
    },
    {
      label: t(
        'form-casting:casting.cards-casting-coupon.value-coupon-money.percentage'
      ),
      value: 'percentage',
    },
  ]

  // Needed in case of private brief
  const urlParams = new URLSearchParams(window.location.search)
  const vogzterId = urlParams.get("vogzter_id")

  useEffect(() => {
    if (vogzterId && brief.favourites?.at(0) === undefined && (brief.favourites?.length ?? 0) < 1) {
      // add the selected vogzter to the brief
      brief.favourites!?.push(vogzterId)
    }
  }, [])

  const formikCtx = useFormikContext<CustomBriefI>()

  useEffect(() => {
    if (formikCtx.values.property_rights)
      setDisplayPropertyRights(formikCtx.values.property_rights !== undefined)
  }
    , [formikCtx.values.property_rights])

  return (
    <>
      <BriefTypeSectionForm
        required
        // optional
        dealPlus={values.private && values.private.is_private}
        castingPlus={
          values?.private
            ? !values.private.is_private
            : true
        }
        title={t(
          'form-casting:casting.cards-casting-simple.title-onspot'
        )}
        error={with_product}
      >
        <div className={`${styles['section-first-form']} ${styles['section-brief-type-radio']}`}>
          <h4 className={styles['title-section-form']}>
            {t('form-casting:casting.cards-casting-simple.choose-casting-type')}
          </h4>
          <div className='flex'>
            <FieldForm<Form1Props>
              name="with_product"
              key={'Casting type Casting Simple'}
              checked={(!!values.with_product || !!values.on_spot?.is_on_spot) === false}
            >
              {(field, meta, helpers) => (
                <>
                  <div className={styles['active-plus-casting']}>
                    <fieldset
                      id="c-group"
                      className={styles['checkboxes']}
                    >
                      <label
                        className={styles['input-checkbox']}
                        htmlFor="casting-simple"
                      >
                        <input
                          onChange={(e) => {
                            onBriefTypeChanged('casting')
                            formikCtx.setFieldValue('with_product', false)
                            formikCtx.setFieldValue('on_spot', { ...meta.value.on_spot, is_on_spot: false })
                          }}
                          className="app-checkbox"
                          type="radio"
                          readOnly
                          id="casting-plus-no"
                          value="true"
                          checked={(!!values.with_product || !!values.on_spot?.is_on_spot) === false ?? false}
                        />
                        <span className="text-[14px]">
                          {briefR.brief.data?.private?.is_private ?
                            t('form-casting:casting.cards-casting-simple.title-deal')
                            :
                            t('form-casting:casting.cards-casting-simple.title-simple')
                          }
                        </span>
                      </label>
                    </fieldset>
                  </div>
                  {meta.error && (
                    <span className="error leading-3 absolute bottom-2.5 right-4">
                      {meta.error}
                    </span>
                  )}
                </>
              )}
            </FieldForm>
            <FieldForm<Form1Props>
              name="with_product"
              key={'Casting type Casting Plus'}
              checked={!!values.with_product}
            >
              {(field, meta, helpers) => (
                <>
                  <div className={styles['active-plus-casting']}>
                    <fieldset
                      id="c-group"
                      className={styles['checkboxes']}
                    >
                      <label
                        className={styles['input-checkbox']}
                        htmlFor="casting-plus"
                      >
                        <input
                          onChange={(e) => {
                            onBriefTypeChanged('casting-plus')
                            formikCtx.setFieldValue('with_product', true)
                            if (meta.value !== undefined)
                              formikCtx.setFieldValue('on_spot', { ...(meta.value.on_spot), is_on_spot: false })
                          }}
                          className="app-checkbox"
                          type="radio"
                          readOnly
                          id="casting-plus-yes"
                          name="with_product"
                          value="false"
                          checked={!!values.with_product}
                        />
                        {/* eslint-disable-next-line react/no-danger */}
                        <span className={`${briefR.brief.data?.private?.is_private ? styles['deal-plus'] : styles['casting-plus']} text-[14px]`} dangerouslySetInnerHTML={{
                          __html:
                            briefR.brief.data?.private?.is_private ?
                              t('form-casting:casting.cards-casting-simple.deal-plus')
                              :
                              t('form-casting:casting.cards-casting-simple.title-plus')

                        }} />
                      </label>
                    </fieldset>
                  </div>
                  {meta.error && (
                    <span className="error leading-3 absolute bottom-2.5 right-4">
                      {meta.error}
                    </span>
                  )}
                </>
              )}
            </FieldForm>
            <FieldForm<Form1Props>
              name="on_spot"
              key={'Casting type Casting OnSpot'}
              checked={!!values.on_spot?.is_on_spot}
            >
              {(field, meta, helpers) => (
                <>
                  <div className={styles['active-plus-casting']}>
                    <fieldset
                      id="c-group"
                      className={styles['checkboxes']}
                    >
                      <label
                        className={styles['input-checkbox']}
                        htmlFor="casting-on-spot"
                      >
                        <input
                          onChange={(e) => {
                            onBriefTypeChanged('on-spot')
                            formikCtx.setFieldValue('with_product', false)
                            formikCtx.setFieldValue('on_spot', { ...meta.value.on_spot, is_on_spot: true })
                          }}
                          className="app-checkbox"
                          type="radio"
                          readOnly
                          id="casting-on-spot"
                          name="on_spot"
                          value="false"
                          checked={!!values.on_spot?.is_on_spot}
                        />
                        {/* eslint-disable-next-line react/no-danger */}
                        <span className={`${styles['casting-on-spot']} text-[14px]`} dangerouslySetInnerHTML={{
                          __html:
                            briefR.brief.data?.private?.is_private ?
                              t('form-casting:casting.cards-casting-simple.deal-on-spot')
                              :
                              t('form-casting:casting.cards-casting-simple.title-on-spot')

                        }} />
                      </label>
                    </fieldset>
                  </div>
                </>
              )}
            </FieldForm>
          </div>
        </div>
      </BriefTypeSectionForm>
      {!values.on_spot?.is_on_spot && (
        <SectionForm
          error={product}
          required={values.with_product}
          title={values.with_product ? t('form-casting:casting.cards-casting-product:title-plus') : t('form-casting:casting.cards-casting-product:title')}
          description={t('form-casting:casting.cards-casting-product:description')}
        >
          <FieldForm<Form1Props & { product: CustomProductBrand }> name='product' key='product'>
            {(field, meta, helpers) => (
              <>
                {JSON.stringify(brief.product)}
                <ProductPicker
                  className='w-full pt-7 pb-7'
                  defaultSelection={[
                    userMobx.me.data?.products?.findIndex((p) => {
                      const product = JSON.parse(sessionStorage.getItem('product') ?? "{}")
                      if (!product) return false
                      return p._id === (product as ProductsBrand)?._id
                    }) ?? -1
                  ]}
                  onProductSelected={(product) => {
                    helpers.setValue((product as ProductsBrand))
                    sessionStorage.setItem('product', JSON.stringify(product))
                  }}
                  onProductRemoved={() => {
                    helpers.setValue(undefined)
                    sessionStorage.removeItem('product')
                  }}
                />
                {meta.error && (
                  <span className="error leading-3 absolute bottom-2.5 right-4">
                    {meta.error}
                  </span>
                )}
              </>
            )}
          </FieldForm>
        </SectionForm>
      )}
      {values.on_spot?.is_on_spot && (
        <SectionForm
          error={getIn(errors, 'on_spot.dotation')}
          title={t('form-casting:casting.cards-casting-on-spot-dotation:title')}
          description={t('form-casting:casting.cards-casting-on-spot-dotation:description')}
        >
          {
            <FieldForm<Form1Props>
              name='on_spot'
              key={'dotation'}>
              {(field, meta, helpers) => {
                return (
                  <>
                    <div className="flex !align-middle">
                      <input
                        type='number'
                        data-input-brief
                        value={formikCtx.values.on_spot?.dotation ?? ''}
                        onChange={(evt) => {
                          const cleanedValue = evt.target.value.replace(/[^0-9]/g, '')
                          formikCtx.setFieldValue("on_spot.dotation", cleanedValue && (parseInt(cleanedValue, 10)))
                        }}
                        placeholder={t('form-casting:casting.cards-casting-on-spot-dotation:placeholder')}
                        className={`${styles['input-dotation']} w-full !text-[20px] font-bold flex sm:w-5/12`}
                      />
                      {
                        formikCtx.values.on_spot?.dotation && (
                          <span
                            className="w-full !text-[20px] font-bold flex sm:w-5/12 my-auto mx-2"
                          >€</span>
                        )
                      }
                    </div>
                    {meta.error && (
                      <span className="error leading-3 absolute bottom-2.5 right-4">
                        {getIn(errors, 'on_spot.addresses')}
                      </span>
                    )}
                  </>
                )
              }}
            </FieldForm>
          }
        </SectionForm>
      )}
      {values.on_spot?.is_on_spot && userMobx.me.data?.addresses && (
        <SectionForm
          required
          error={getIn(errors, 'on_spot.addresses')}
          title={t('form-casting:casting.cards-casting-on-spot:title')}
          description={t('form-casting:casting.cards-casting-on-spot:description')}
        >
          <FieldForm<Form1Props> name='on_spot' key='onSpot'>
            {(field, meta, helpers) => (
              <>
                <AddressPicker
                  className='w-full pt-7 pb-7'
                  defaultSelection={
                    values.on_spot?.addresses?.map((address) =>
                      userMobx.me.data?.addresses?.findIndex((a) => (address instanceof String ? address : (address as Address)?._id) === a._id) ?? -1
                    )
                  }
                  onAddressSelected={(addresses) => {
                    formikCtx.setFieldValue('on_spot', {
                      ...values.on_spot,
                      is_on_spot: true,
                      addresses: addresses
                    })
                    // helpers.setValue({ is_on_spot: true, addresses: addresses })
                    // brief.on_spot = { is_on_spot: true, addresses: addresses as Address[] }
                  }}
                  onAddressRemoved={(addresses, element, selectedCount) => {
                    // if (selectedCount === 0) {
                    //   helpers.setValue(undefined)
                    //   return
                    // }
                    // brief.on_spot = { ...brief.on_spot, addresses: addresses } as Brief['on_spot']
                    formikCtx.setFieldValue('on_spot', { ...values.on_spot, addresses: addresses })
                  }}
                />
                {meta.error && (
                  <span className="error leading-3 absolute bottom-2.5 right-4">
                    {getIn(errors, 'on_spot.addresses')}
                  </span>
                )}
              </>
            )}
          </FieldForm>
        </SectionForm>
      )}
      {/* Definition Coupon */}
      {briefR.brief.data?.exclusive?.is_exclusive && (
        <SectionForm
          required
          error={exclusive}
          title={t('form-casting:casting.cards-casting-coupon.title')}
          description={t(
            'form-casting:casting.cards-casting-coupon.description'
          )}
        >
          <FieldForm<Form1Props> name="exclusive" key="exclusive">
            {(field, meta, helpers) => (
              <>
                <div
                  className={`${styles['section-first-form']} !max-w-full flex-col mt-4`}
                >
                  <div className={`${styles['active-plus-casting']}`}>
                    <h4>Type du coupon :</h4>
                    <fieldset
                      onChange={(e) => {
                        helpers.setValue(
                          { ...field.value, type: (e.target as any).value },
                          false
                        )
                      }}
                      id="c-group"
                      className={`${styles['checkboxes']} !flex-col md:!flex-row`}
                    >
                      {optionsCoupon.map((v, i) => (
                        <label
                          key={i}
                          className={styles['input-checkbox']}
                          htmlFor={`casting-plus-${v.value}`}
                        >
                          <input
                            key={i}
                            className="app-checkbox"
                            type="radio"
                            readOnly
                            id={`casting-plus-${v.value}`}
                            name={v.value}
                            value={v.value}
                            checked={
                              v.value ===
                              ((field.value as unknown) as Brief['exclusive'])!
                                ?.type
                            }
                          />
                          <span className="text-[14px]">{v.label}</span>
                        </label>
                      ))}
                    </fieldset>
                  </div>
                  <div className={`${styles['active-plus-casting']}`}>
                    <div className="w-full mt-2">
                      <label
                        htmlFor="desc-coupon"
                        style={{ fontSize: 16 }}
                        data-h4="true"
                      >
                        {t(
                          'form-casting:casting.cards-casting-coupon.label-text-coupon'
                        )}
                      </label>
                      <textarea
                        id="desc-coupon"
                        placeholder="Décrivez les conditions d’utilisation dans lesquelles le coupon est valide et utilisable"
                        onChange={(e) => {
                          helpers.setValue(
                            { ...field.value, conditions: e.target.value },
                            false
                          )
                        }}
                        value={
                          ((field.value as unknown) as CustomBriefI['exclusive'])
                            ?.conditions ?? ''
                        }
                        className={`w-full mt-2 ${styles['text-area-describe-brief']}`}
                      />
                    </div>
                  </div>
                  <div className="flex-row flex flex-wrap w-full flex-start">
                    <div className={`${styles['active-plus-casting']} `}>
                      <div className=" mt-2">
                        <label
                          htmlFor="input-coupon-value"
                          style={{ fontSize: 16 }}
                          data-h4="true"
                        >
                          {t(
                            'form-casting:casting.cards-casting-coupon.value-coupon'
                          )}
                        </label>
                        <div className="w-full flex flex-col items-start ">
                          <input
                            id="input-coupon-value"
                            type="tel"
                            placeholder={`0 ${((field.value as unknown) as Brief['exclusive'])!
                              ?.reward?.type === 'amount'
                              ? '€'
                              : '%'
                              }`}
                            onChange={(e) => {
                              helpers.setValue(
                                {
                                  ...field.value,
                                  reward: {
                                    ...((field.value as unknown) as CustomBriefI['exclusive'])!
                                      ?.reward,
                                    value: e.target.value.replace(/\D/g, ''),
                                  },
                                },
                                false
                              )
                            }}
                            value={
                              ((field.value as unknown) as CustomBriefI['exclusive'])
                                ?.reward?.value ?? ''
                            }
                            className={`${styles['input-coupon-value']}`}
                          />
                          <fieldset
                            onChange={(e) => {
                              helpers.setValue(
                                {
                                  ...field.value,
                                  reward: {
                                    ...((field.value as unknown) as CustomBriefI['exclusive'])
                                      ?.reward,
                                    type: (e.target as any).value,
                                  },
                                },
                                false
                              )
                            }}
                            id="c-group"
                            className={`${styles['checkboxes']} !flex-col md:!flex-row !m-0`}
                          >
                            {optionsCouponPrice.map((v, i) => (
                              <label
                                key={i}
                                className={styles['input-checkbox']}
                                htmlFor={`casting-plus-${v?.value}`}
                              >
                                <input
                                  key={i}
                                  className="app-checkbox"
                                  type="radio"
                                  readOnly
                                  id={`casting-plus-${v?.value}`}
                                  name={v?.value}
                                  value={v?.value}
                                  checked={
                                    v.value ===
                                    ((field.value as unknown) as Brief['exclusive'])
                                      ?.reward?.type
                                  }
                                />
                                <span className="text-[14px]">{v?.label}</span>
                              </label>
                            ))}
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <div className="w-6 h-3" />
                    <div className={`${styles['active-plus-casting']} `}>
                      <div className=" mt-2">
                        <label
                          htmlFor="input-coupon-value-losers"
                          style={{ fontSize: 16 }}
                          data-h4="true"
                          className={styles['optionnal']}
                        >
                          {t(
                            'form-casting:casting.cards-casting-coupon.value-coupon-losers'
                          )}
                        </label>
                        <div className="w-full flex flex-col items-start ">
                          <input
                            id="input-coupon-value-losers"
                            placeholder={`0 ${((field.value as unknown) as Brief['exclusive'])!
                              ?.reward_lost?.type === 'amount'
                              ? '€'
                              : '%'
                              }`}
                            onChange={(e) => {
                              helpers.setValue(
                                {
                                  ...field.value,
                                  reward_lost: {
                                    ...((field.value as unknown) as CustomBriefI['exclusive'])!
                                      ?.reward_lost,
                                    value: e.target.value.replace(/\D/g, ''),
                                  },
                                },
                                false
                              )
                            }}
                            value={
                              ((field.value as unknown) as CustomBriefI['exclusive'])
                                ?.reward_lost?.value ?? ''
                            }
                            className={`${styles['input-coupon-value']}`}
                          />
                          <fieldset
                            onChange={(e) => {
                              helpers.setValue(
                                {
                                  ...field.value,
                                  reward_lost: {
                                    ...((field.value as unknown) as CustomBriefI['exclusive'])
                                      ?.reward_lost,
                                    type: (e.target as any).value,
                                  },
                                },
                                false
                              )
                            }}
                            id="c-group"
                            className={`${styles['checkboxes']} !flex-col md:!flex-row !m-0`}
                          >
                            {optionsCouponPrice.map((v, i) => (
                              <label
                                key={i}
                                className={styles['input-checkbox']}
                                htmlFor={`casting-plus-${v?.value}-lost`}
                              >
                                <input
                                  key={i}
                                  className="app-checkbox"
                                  type="radio"
                                  readOnly
                                  id={`casting-plus-${v?.value}-lost`}
                                  name={`${v?.value}-l`}
                                  value={v?.value}
                                  checked={
                                    v.value ===
                                    ((field.value as unknown) as Brief['exclusive'])
                                      ?.reward_lost?.type
                                  }
                                />
                                <span className="text-[14px]">{v?.label}</span>
                              </label>
                            ))}
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {meta.error && (
                  <span className="error leading-3 absolute bottom-2.5 right-4">
                    {(meta.error as CustomBriefI['exclusive'])?.reward?.value ||
                      ((meta.error as CustomBriefI['exclusive'])?.reward_lost
                        .value &&
                        'Certains champs sont requis')}
                  </span>
                )}
              </>
            )}
          </FieldForm>
        </SectionForm>
      )}
      {/* Language */}
      <SectionForm
        title={t('new-casting:languages.title')}
        description={t('new-casting:languages.desc')}
        required
      >
        <FieldForm<Form1Props> name="language">
          {(field, meta, helpers) => {
            const { setValue } = helpers
            const { value } = field
            return (
              <>
                <div
                  className={`${styles['name-brief']} flex-col !items-start`}
                >
                  <div className="mt-2" />
                  <div className="w-full flex-col flex lg:">
                    <div className={`${styles['property-rights-checkbox']} flex-wrap flex w-full`}>
                      {
                        briefR.languages.data?.map((language, i) =>
                          <Fragment key={i}>
                            <div className='flex items-center m-2'>
                              <Checkbox
                                key={i}
                                data-input-brief
                                value={language.name}
                                checked={(value as Brief['language'])?.name === language.name}
                                onChange={(evt) => setValue({ name: evt.target.value })}
                                sx={{
                                  color: blue[900],
                                  '&.Mui-checked': {
                                    color: blue[800],
                                  },
                                }}
                                id={`languages-${i}`}
                              />
                              <label htmlFor={`languages-${i}`}>{getNameLanguages(['us', 'uk'].indexOf(language.name) === -1 ? language.name : 'en', userLanguage.split('-')[0])}</label>
                            </div>
                            {meta.error && meta.error.name && (
                              <span className="error leading-3 absolute bottom-2.5 right-4">
                                {meta.error.name}
                              </span>
                            )}
                          </Fragment>
                        )
                      }
                    </div>
                  </div>
                </div>
              </>
            )
          }}
        </FieldForm>
      </SectionForm>
      {/* Country */}
      <SectionForm
        title={t('new-casting:country.title')}
        description={t('new-casting:country.desc')}
      >
        <FieldForm<Form1Props> name="country">
          {(field, meta, helpers) => {
            const { setValue } = helpers
            const { value } = field
            return (
              <>
                <div className={`${styles['name-brief']} flex-col !items-start`}>
                  <div className="mt-2" />
                  <div className="w-full flex-col flex lg:">
                    <div className={`${styles['countries-part']} flex-wrap flex w-full`}>
                      {
                        <Fragment >
                          <div className='flex items-center m-2 gap-3 w-full max-w-sm'>
                            <FormControl fullWidth>
                              <InputLabel id="country-picker-label">{t('new-casting:country.placeholder')}</InputLabel>
                              <Select
                                data-input-brief
                                defaultValue={(value as Brief['country'])?.name ?? ''}
                                label={t('new-casting:country.placeholder')}
                                labelId="country-picker-label"
                                id="country-picker"
                                value={(value as Brief['country'])?.name ?? ''}
                                onChange={(e) => {
                                  setValue({ name: e.target.value })
                                }}
                              >
                                {briefR.countries.data?.map((item, index) => (
                                  <MenuItem key={index} value={item.name}>{getNameCountries(item.name, userLanguage.split('-')[0])}</MenuItem>
                                ))}
                              </Select>

                            </FormControl>
                          </div>
                          {meta.error && meta.error.name && (
                            <span className="error leading-3 absolute bottom-2.5 right-4">
                              {meta.error.name}
                            </span>
                          )}
                        </Fragment>
                      }
                    </div>
                  </div>
                </div>
              </>
            )
          }}
        </FieldForm>
      </SectionForm>
      {/* Target social */}
      {briefR.brief.data?.content_type === 'influence' && (<SectionForm
        title={t('new-casting:influence.targeted-social.title')}
        description={t('new-casting:influence.targeted-social.desc')}
        required
      >
        <FieldForm<Form1Props> name="targeted_social">
          {(field, meta, helpers) => {
            const { setValue } = helpers
            const { value } = field
            return (
              <>
                <div
                  className={`${styles['targeted-social']} flex-col !items-start`}
                >
                  <div className="mt-2" />
                  <div className="w-full flex flex-col">
                    <div className='w-full flex-wrap flex gap-4'>
                      {targetedSocial?.map((v, i) => (
                        <Fragment key={i}>
                          <input
                            key={i}
                            type='checkbox'
                            className="mt-2"
                            data-input-brief
                            checked={(value as Brief['targeted_social'])?.social?.name === v.value}
                            value={v.name}
                            onChange={(e) => {
                              setValue({ social: { name: v.value } })
                            }}
                            id={v.name}
                          />
                          <label htmlFor={v.name}>
                            <i className={v.icon} /> {v.name}
                          </label>
                          {meta.error && meta.error.social && (
                            <span className="error leading-3 absolute bottom-2.5 right-4">
                              {meta.error.social.name}
                            </span>
                          )}
                        </Fragment>
                      ))}
                    </div>
                    {(value as CustomBriefI['targeted_social'])?.social &&
                      <Fragment>
                        <br />
                        <h4 data-required >{t('new-casting:influence.targeted-social.subtitle')}</h4>
                        <p data-required>{t('new-casting:influence.targeted-social.desc-2')}</p>
                        <div className="mt-2" />
                        <div className='flex flex-wrap gap-4'>
                          {socialTargetSelected(value as CustomBriefI['targeted_social'])
                            ?.type.map((v, i) => (
                              <Fragment key={i}>
                                <input
                                  key={i}
                                  type='checkbox'
                                  className="mt-2"
                                  data-input-brief
                                  checked={(value as CustomBriefI['targeted_social'])?.type_content === v}
                                  value={v}
                                  onChange={(e) => {
                                    setValue({ ...meta.value, type_content: v })
                                  }}
                                  id={v}
                                />
                                <label htmlFor={v}>
                                  <i className={socialTargetSelected(value as CustomBriefI['targeted_social'])?.icon} /> {v} {socialTargetSelected(value as CustomBriefI['targeted_social'])?.name}
                                </label>
                                {meta.error && meta.error.type_content && (
                                  <span className="error leading-3 absolute bottom-2.5 right-4">
                                    {meta.error.type_content}
                                  </span>
                                )}
                              </Fragment>
                            ))
                          }
                        </div>
                      </Fragment>
                    }
                  </div>
                </div>
                {/* {meta.error && (
                            <span className="error leading-3 absolute bottom-2.5 right-4">
                              {meta.error}
                            </span>
                          )} */}
              </>
            )
          }}
        </FieldForm>
      </SectionForm>)}
      {/* Cession des droits d'auteur */}
      {briefR.brief.data?.content_type === 'influence' &&
        <SectionForm
          title={t('form-casting:casting.cards-assignment-copyright.title')}
          description={t('form-casting:casting.cards-assignment-copyright.desc')}
          optional
        >
          <FieldForm<Form1Props> name="property_rights">
            {(field, meta, helpers) => {
              const { setValue } = helpers
              const { value } = field
              return (
                <>
                  <div
                    className={`${styles['name-brief']} flex-col !items-start`}
                  >
                    <div className="mt-2" />
                    <div className="w-full flex-col flex lg:">
                      <div className={`${styles['property-rights-checkbox']} flex w-full`}>
                        <input
                          className="mt-2"
                          type="checkbox"
                          data-input-brief
                          checked={displayPropertyRights}
                          onChange={(evt) => {
                            setDisplayPropertyRights(!displayPropertyRights)
                            setValue(undefined)
                            formikCtx.setFieldValue('content_usage', '')
                          }}
                          id="property-rights"
                        />
                        <div>
                          <label htmlFor="property-rights">{t('form-casting:casting.cards-assignment-copyright.input-1')}</label>
                          <p>{t('form-casting:casting.cards-assignment-copyright.label-1')}</p>
                        </div>
                      </div>
                      {displayPropertyRights && (
                        <div className={`${styles['targeted-social']} ${styles['property-right-time']}`}>
                          <div className="mt-2" />
                          <h4>{t('form-casting:casting.cards-assignment-copyright.input-2')}</h4>
                          <div className="mt-2" />
                          <div className='flex flex-wrap gap-4'>
                            {propertyRights.map((v, i) => (
                              <Fragment key={i}>
                                <input
                                  className="mt-2"
                                  type="checkbox"
                                  data-input-brief
                                  onChange={() => {
                                    setValue(v.value)
                                  }}
                                  checked={value === v.value}
                                  value={v.value}
                                  id={`property-rights-time-${i}`}
                                />
                                <label htmlFor={`property-rights-time-${i}`}>{v.txt}</label>
                              </Fragment>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )
            }}
          </FieldForm>
          <FieldForm<Form1Props> name="content_usage">
            {(field, meta, helpers) => {
              const { setValue } = helpers
              const { value } = field
              return (
                <>
                  <div
                    className={`${styles['name-brief']} flex-col !items-start`}
                  >
                    <div className="mt-2" />
                    <div className="w-full flex-col flex ">
                      {displayPropertyRights && (
                        <div className={`${styles['targeted-social']} ${styles['property-right-time']}`}>
                          <div className="mt-2" />
                          <h4>{t('form-casting:casting.cards-assignment-copyright.input-2')}</h4>
                          <div className="mt-2" />
                          <div className='flex flex-wrap gap-4'>
                            <Fragment>
                              <input
                                className="mt-2"
                                type="checkbox"
                                onChange={() => {
                                  setValue('organic')
                                }}
                                checked={value === 'organic'}
                                value={'organic'}
                                id='contant-usage-organic'
                              />
                              <label htmlFor="contant-usage-organic">{t('new-casting:step3.organic')}</label>
                            </Fragment>
                            <Fragment>
                              <input
                                className="mt-2"
                                type="checkbox"
                                onChange={() => {
                                  setValue('ad')
                                }}
                                checked={value === 'ad'}
                                value={'ad'}
                                id='contant-usage-ad'
                              />
                              <label htmlFor="contant-usage-ad">{t('new-casting:step3.ad')}</label>
                            </Fragment>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )
            }}
          </FieldForm>
        </SectionForm>
      }
      {/* Ratio de la vogz */}
      {briefR.brief.data?.content_type !== 'influence' &&
        <SectionForm error={ratio} required title="Ratio pour votre VOGZ">
          <FieldForm<Form1Props> name="ratio" key="ratio">
            {(field, meta, helpers) => {
              const { setValue } = helpers
              return (
                <>
                  <div
                    className={`${styles['section-first-form']} !max-w-[430px]`}
                  >
                    <img
                      onClick={() => {
                        setValue('landscape')
                      }}
                      alt=""
                      src="/assets/svgs/phone-landscape.svg"
                      className={
                        field.value === 'landscape' ? styles['active'] : ''
                      }
                    />
                    <p data-styleform="true">ou</p>

                    <img
                      onClick={() => {
                        setValue('portrait')
                      }}
                      alt=""
                      src="/assets/svgs/phone-vertical.svg"
                      className={
                        field.value === 'portrait' ? styles['active'] : ''
                      }
                    />
                  </div>
                  {meta.error && (
                    <span className="error leading-3 absolute bottom-2.5 right-4">
                      {meta.error}
                    </span>
                  )}
                </>
              )
            }}
          </FieldForm>
        </SectionForm>
      }
      {/* Durée de votre VOGZ */}
      {briefR.brief.data?.content_type !== 'influence' &&
        <SectionForm
          required
          error={length}
          title={t('form-casting:casting.cards-casting-time.title')}
        >
          <FieldForm<Form1Props> name="length" key="length">
            {(field, meta, helpers) => {
              const { setValue } = helpers
              const { value } = field
              return (
                <>
                  <div className={styles['section-first-form']}>
                    <div className={styles['content-time-vogz']}>
                      <div className={styles['block-time-vogz']}>
                        <span>Min</span>
                        <input
                          onChange={(e) => {
                            setValue({
                              ...field.value,
                              min: (e.target as any).value.slice(0, 3),
                            })
                          }}
                          type="number"
                          placeholder="00"
                          min="0"
                          max="555"
                          name="min"
                          style={{ width: '100px' }}
                          data-gradient-input
                          // @ts-ignore
                          value={meta?.value?.min}
                        />
                        <span>Sec</span>
                      </div>
                      <div className="w-2" />
                      <div className={styles['block-time-vogz']}>
                        <span>Max</span>
                        <input
                          onChange={(e) => {
                            setValue({
                              ...field.value,
                              max: (e.target as any).value.slice(0, 3),
                            })
                          }}
                          style={{ width: '100px' }}
                          type="number"
                          min="0"
                          placeholder="00"
                          max="555"
                          data-gradient-input
                          // @ts-ignore
                          // defaultValue={meta.value.max}
                          // @ts-ignore
                          value={meta?.value?.max}
                        />
                        <span>Sec</span>
                      </div>
                    </div>
                  </div>
                  {!!meta.error!?.max && (
                    <span className="error leading-3 absolute bottom-2.5 right-4">
                      {meta.error.max}
                    </span>
                  )}
                  {!!meta.error!?.min && (
                    <span className="error leading-3 absolute bottom-2.5">
                      {meta.error.min}
                    </span>
                  )}
                </>
              )
            }}
          </FieldForm>
        </SectionForm>
      }
      {/* Ciblage vogzter */}
      {!briefR.brief.data?.exclusive?.is_exclusive &&
        !briefR.brief.data?.private?.is_private &&
        briefR.brief.data?.content_type !== 'influence' && (
          <SectionForm
            error={targeting}
            title={t('form-casting:casting.cards-casting-targeting.title')}
            description={t(
              'form-casting:casting.cards-casting-targeting.description'
            )}
          >
            <FieldForm<Form1Props> name="targeting">
              {(field, meta, helpers) => {
                const { setValue } = helpers
                const { value } = field
                return (<>
                  <div
                    className={`${styles['section-first-form']} !max-w-full flex-col mt-4`}
                  >
                    <div className={`${styles['active-plus-casting']}`}>
                      <h4>Sexe :</h4>
                      <fieldset
                        onChange={(e) => {
                          setValue(
                            { ...field.value, gender: (e.target as any).value },
                            false
                          )
                        }}
                        id="c-group"
                        className={`${styles['checkboxes']} !flex-col lg:!flex-row`}
                      >
                        {optionsGender.map((v, i) => (
                          <label
                            key={i}
                            className={styles['input-checkbox']}
                            htmlFor={`casting-plus-${v.value}`}
                          >
                            <input
                              key={i}
                              className="app-checkbox"
                              type="radio"
                              readOnly
                              id={`casting-plus-${v.value}`}
                              name={v.value}
                              value={v.value}
                              checked={
                                v.value ===
                                (field.value as Brief['targeting'])?.gender
                              }
                            />
                            <span className="text-[14px]">{v.label}</span>
                          </label>
                        ))}
                      </fieldset>
                    </div>
                    <div className={`${styles['active-plus-casting']}`}>
                      <h4>Age&nbsp;: </h4>
                      <div className={`${styles['age-inputs']}`}>
                        <div className="flex items-center">
                          <input
                            onChange={(e) => {
                              setValue(
                                {
                                  ...field.value,
                                  age: {
                                    ...(field.value as Brief['targeting'])!?.age,
                                    min: (e.target as any).value,
                                  },
                                },
                                false
                              )
                            }}
                            placeholder="00"
                            value={(field.value as Brief['targeting'])?.age?.min}
                            data-gradient-input
                            className="!text-[30px]"
                          />
                          <span className="text-[14px]">Minimum</span>
                        </div>
                        <div className="flex items-center">
                          <input
                            onChange={(e) => {
                              helpers.setValue(
                                {
                                  ...field.value,
                                  age: {
                                    ...(field.value as Brief['targeting'])!?.age,
                                    max: (e.target as any).value,
                                  },
                                },
                                false
                              )
                            }}
                            placeholder="00"
                            value={(field.value as Brief['targeting'])?.age?.max}
                            className="!text-[30px]"
                            data-gradient-input
                          />
                          <span className="text-[14px]">Maximum</span>
                        </div>
                      </div>
                    </div>

                    {(field.value as Brief['targeting'])!?.age!?.min! > 25 && (
                      <div className="mt-3 justify-start w-full">
                        <span className="text-[14px] warm-gray-300">
                          <i className="fa-regular fa-circle-exclamation text-[16px] mr-3 gray-500" />
                          {t(
                            'form-casting:casting.cards-casting-targeting.alert-targeting'
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  {meta.error && (
                    <span className="error leading-3 absolute bottom-2.5 right-4">
                      {meta.error}
                    </span>
                  )}
                </>
                )
              }}
            </FieldForm>
          </SectionForm>
        )}
      {/* Favoris vogzters */}
      {!briefR.brief.data?.exclusive?.is_exclusive &&
        (briefR.brief.data?.private?.is_private) &&
        !briefR.brief.loading &&
        !briefR.brief.error &&
        !userMobx.favouriteVogzters.loading &&
        !userMobx.favouriteVogzters.error &&
        (userMobx.favouriteVogzters.data?.length ?? 0) > 0 && (
          <SectionForm
            title={t('form-casting:casting.cards-casting-fav.title')}
            required={briefR.brief.data.private.is_private && !vogzterId}
          >
            <FieldForm<Form1Props> name="favourites" key="favourites">
              {(field, meta, helpers) => {
                const { setValue } = helpers
                const { value } = field

                if (brief.favourites?.at(0) && (value as Array<string>).at(0) === undefined && !value._id) {
                  setValue(brief.favourites)
                }

                return (
                  <>
                    <div className={styles['div-fav']}>
                      <div
                        className={`${styles['section-first-form']} !justify-start flex-wrap w-full`}
                      >
                        {(value.length ?? 0) === 0 ? (
                          <div
                            className={`mt-6 mr-6 cursor-pointer ${styles['card-add-fav']}`}
                            onClick={() => {
                              handleOpenModal()
                            }}
                          >
                            <img alt="" src="/assets/svgs/add-favourite.svg" />
                            <p>Ajouter un Vogter</p>
                          </div>
                        ) : (
                          // This is done on purpose in order to
                          // prevent the CSS from styling the vogzter card from taking button style
                          <div> </div>
                        )}
                        {!userMobx.favouriteVogzters.loading &&
                          (userMobx.favouriteVogzters.data?.length ?? 0) > 0 &&
                          (value as Array<string>)?.length! > 0 &&
                          (value as Array<string>)?.map((val, i) => {
                            const v = userMobx.favouriteVogzters.data?.filter(v => v._id === (value as CustomBriefI['favourites'])?.at(0)).at(0)

                            return (
                              <div
                                key={i}
                                className={`mt-6 mr-6 ${styles['card-add-fav']}`}
                              >
                                <i
                                  className="fa-duotone fa-xmark"
                                  onClick={() => {
                                    const favourites = (value as CustomBriefI['favourites'])?.filter(
                                      (e) => e !== v?._id
                                    )
                                    setValue(favourites)
                                  }}
                                />
                                <img
                                  alt=""
                                  src={getAvatar(
                                    v!?.profile.gender,
                                    v?.profile.picture?.source
                                  )}
                                  className={'cursor-none'}
                                />
                                <p className={styles['name-vogzter']}>
                                  {formatVogzterName(v?.first_name ?? "", v?.last_name ?? "")}
                                </p>
                              </div>
                            )
                          })}

                      </div>
                      {!briefR.brief.data?.private?.is_private && (
                        <p
                          data-required
                          className="mt-2.5	mb-2.5 !max-w-full"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: t(
                              'form-casting:casting.cards-casting-fav.desc'
                            ),
                          }}
                        />
                      )}
                      {meta.error && (
                        <span className="error leading-3 absolute bottom-2.5 right-4">
                          {meta.error}
                        </span>
                      )}
                      <ReactModal
                        isOpen={openSelect}
                        onRequestClose={handleCloseModal}
                        className={styles['Modal']}
                        overlayClassName={styles['Overlay']}
                        ariaHideApp={false}
                        style={customStyles}
                      >
                        <div className={styles['div-card-select']}>
                          <div className={`${styles['card-select-fav']}`}>

                            {/* {vogzterPicker.displayVogzters()} */}
                            {(userMobx.favouriteVogzters.data?.length ?? 0) > 0 &&
                              userMobx.favouriteVogzters.data?.map((val, i) => {
                                return (<VogzterPickerItem
                                  className={`${styles['vogzter-picker-item']} max-w-[17rem]`}
                                  selected={userMobx.favouriteVogzters.data?.filter(v => v._id === (value as CustomBriefI['favourites'])?.at(0)).at(0)}
                                  setSelection={(v) => {
                                    setValue([v._id])
                                  }}
                                  vogzter={val}
                                  key={i}
                                  isFavorite
                                />)
                              }
                              )}
                          </div>
                        </div>
                        <Btn1
                          onclick={() => {
                            // values.favourites?.push(vogzterId)
                            // sessionStorage.setItem('selectedVogzter', JSON.stringify(selectedVogzter))
                            // setValue(
                            //   vogzters?.filter(
                            //     (v) => v._id === selectedVogzter?._id
                            //   ).map((vz) => vz._id)
                            // )
                            handleCloseModal()
                          }}
                          btnName={t('mycastings:confirm-select')}
                          className={`${styles['btn-selection']} my-0 mx-auto flex bottom-0 justify-center`}
                        >
                          <img src="./assets/sgvs/lock.svg" alt="" />
                        </Btn1>
                      </ReactModal>
                    </div>
                  </>
                )
              }}
            </FieldForm>
          </SectionForm>
        )}
      {/* Description de votre brief * */}
      {/* <SectionForm
        required
        title={t('form-casting:casting.cards-casting-descbrief.title')}
      >
        {(required) => (
          <div className={styles['section-first-form']}>
           gfsfs
          </div>
        )}
      </SectionForm> */}
    </>
  )
}

Form1.defaultProps = {
  errors: {},
}

export default observer(Form1)
