/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  FieldHookConfig,
  FieldMetaProps,
  useField,
  FieldInputProps,
  FieldHelperProps,
} from 'formik'
import { at } from 'lodash'
import React, { ReactNode } from 'react'
import { Brief } from '../../../../../types-project/Brief'
import { DeepPartial, CustomBriefI} from './components/Forms/FormModel/castingFormModel'

type ValueOf<T> = T[keyof T]

export default function FieldForm<T extends CustomBriefI>(
  props: FieldHookConfig<T> & {
    name: keyof T
    children: (
      field: FieldInputProps<T>,
      meta: CustomFieldMeta<T, typeof props.name>,
      helpers: FieldHelperProps<T[typeof props.name]>
    ) => ReactNode
  }
) {
  const [field, meta, helpers] = useField<T>({ name: props.name })
  return (
    <>
      {props.children(
        field,
        (meta as unknown) as CustomFieldMeta<T, typeof props.name>,
        (helpers as unknown) as FieldHelperProps<T[typeof props.name]>
      )}
    </>
  )
}

interface CustomFieldMeta<T extends CustomBriefI, N extends keyof T>
  extends Omit<FieldMetaProps<T>, 'error'> {
  error: T[N]
}
