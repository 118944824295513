/* eslint-disable react/prop-types */
import React, {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Navigation, Scrollbar, Manipulation, Autoplay } from 'swiper'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import Loading from 'react-loading'
import ReactCountryFlag from 'react-country-flag'

import {
  Applicant,
  Brief,
  DetailBa,
  MediaBA,
  MyCasting,
  Profile,
} from '../../../../../types-project/Brief'
import styles from './components/info-profil.module.scss'
import { countMsgNRead } from '../../../../../utils/helpers/index'
import { BrandI, ClientI, Vogzter } from '../../../../../types-project/Client'
import modal from '../../../../../mobx/modal'
import briefR from '../../../../../mobx/brief'
import userMobx from '../../../../../mobx/user'
import utilsMobx from '../../../../../mobx/utils'
import VogzterPicture from '../VogzterPicture'
import Favourite from '../../../../../utils/components/Favourite/favourite'
import ListOverflowHandler from '../../../components/ListOverflowHandler'
import Badge from '../../../components/Badge'

interface InfoProfilProps {
  user: Applicant | Vogzter
  profile: Profile
  first_name: string
  last_name: string
  parent_code: string
  delivery_address: string
  briefApplicationsWon?: MyCasting[] | undefined | null
  mediaValidatedCount?: DetailBa['mediaValidatedCount']
  showFavBtn?: boolean
  setShowMessages?: Dispatch<SetStateAction<boolean>>
  showMessages?: boolean
  portfolio?: {
    showBtn: boolean
    setShowPortfolio: Dispatch<SetStateAction<boolean>>
  }
  lastActivity?: string
  isGuest?: boolean
  mediaSold?: MediaBA[]
  videoIntro?: string
  displayBio?: boolean
  showBtnAction?: {
    tips?: {
      show: boolean
      onClick: () => void
    }
    sendProduct?: {
      show: boolean
      onClick: () => void
    }
    closeCollab?: {
      show: boolean
      onClick: () => void
    }
    viewProfile?: boolean
  }
  displaySocials?: boolean
  displayInfoVogzter?: boolean
  classNames?: {
    container?: string
    sectionName?: string
    msgBtn?: string
    parentSectionName?: string
    descApplicant?: string
  }
}

type RequiredOrNotDiscalifyInInfo =
  | NotRequiredDiscalifyInInfo
  | RequiredDiscalifyInInfo

type RequiredDiscalifyInInfo = InfoProfilProps & {
  showDisqualifyBtn: boolean
  idba: string
}

type NotRequiredDiscalifyInInfo = InfoProfilProps & {
  showDisqualifyBtn?: undefined
  idba?: undefined
}

const InfoProfil: FC<RequiredOrNotDiscalifyInInfo> = ({
  user,
  profile,
  first_name,
  last_name,
  parent_code,
  delivery_address,
  briefApplicationsWon,
  mediaValidatedCount,
  showFavBtn,
  showDisqualifyBtn,
  idba,
  setShowMessages,
  portfolio,
  showMessages,
  lastActivity,
  isGuest,
  mediaSold,
  videoIntro,
  displayBio,
  displaySocials,
  classNames,
  displayInfoVogzter,
  showBtnAction,
}) => {
  const [swiperIndex, setSwiperIndex] = useState(1)
  // const iconChevron = useRef<HTMLElement>(null)

  const navigate = useNavigate()

  const refPortfolioContainer = useRef<HTMLElement>(null)

  const { t } = useTranslation()

  function getAge(birthDate: Date) {
    const currentDate = new Date()
    // subtract the birthdate from the current date to get age in years rounded down.
    const age = Math.floor(
      (currentDate.getTime() - birthDate.getTime()) / 31557600000
    )
    return age
  }
  const userInfos = userMobx.getUserInfos() as ClientI

  useEffect(() => {
    if (user && userInfos !== null) {
      userMobx.getUserMessages(user._id)
    }
  }, [])
  const baWonLength = briefApplicationsWon?.length ?? 0
  const briefApplication = briefR.detailBA.data?.briefApplication
  const msgCount = countMsgNRead(userMobx.messageUser.data ?? [])
  const googleMapsLink = `https://www.google.com/maps/search/${encodeURIComponent(
    delivery_address
  )}`
  const mediaSoldByBrandData = _.groupBy(mediaSold, 'brief.brand._id')
  const mediaSoldByBrand = _.map(mediaSoldByBrandData, (item) => {
    return {
      brand: (item[0].brief as Brief).brand,
      nb_media: item.length,
    }
  })

  const badgeClassName =
    '!bg-alice-blue !text-blue-dark !border-blue-dark text-xs !py-px !px-6 !my-1 !mx-0.5 !rounded-[3px]'
  const badgeClassNameLang = '!bg-alice-green !text-alice-green-dark !border-alice-green-dark text-xs !py-px !px-6 !my-1 !mx-0.5 !rounded-[3px]'

  return (
    <>
      <section
        className={`${styles['profile-info-wrapper']} ${classNames?.container ?? ''
          } ${videoIntro ? styles['video-intro'] : ''}`}
      >
        <div className={styles['profile-info']}>
          <div className="flex flex-wrap justify-center lg:mt-[auto] flex-col sm:flex-row">
            <figure>
              <VogzterPicture
                imgSource={profile?.picture?.source}
                showCertified={user?.certified}
                lastActivity={lastActivity}
                videoIntro={videoIntro}
              />
            </figure>

            <div
              className={`${styles['card-desc-applicant']} ${classNames?.descApplicant ?? ''
                }`}
            >
              {first_name === undefined ? (
                <Skeleton
                  width={192}
                  className="!-ml-3"
                  highlightColor="#ffffff"
                />
              ) : (
                <h3>
                  {first_name}{" "}{last_name}
                </h3>
              )}
              <div
                className={`${styles['part-name']} ${classNames?.parentSectionName ?? ''
                  } flex w-full flex-wrap justify-between`}
              >
                <div
                  className={`flex-row flex justify-between w-full flex-wrap${classNames?.sectionName ?? ''
                    }`}
                >
                  {delivery_address && (
                    <Fragment>
                      <div className={`${styles['gradient-verified']}`}>
                        <span className={styles['gradient-text-verified']}>
                          <a
                            href={googleMapsLink}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <i className="fa-solid fa-location-dot" />
                            {delivery_address.replace(/\s/, '')}
                            {' '}
                            <ReactCountryFlag
                              countryCode={profile?.country?.name ?? ''}
                              svg
                              className="emoji-flag mb-1"
                              title={profile?.country?.name ?? ''}
                            />
                          </a>
                        </span>
                      </div>
                      <hr className="vertical !min-h-0 !h-4" />
                    </Fragment>
                  )}

                  {parent_code && (
                    <div className="flex">
                      <p className="leading-[.5rem] text-sm font-normal uppercase">
                        {parent_code ? '#' : ''}
                        {parent_code}
                      </p>
                    </div>
                  )}

                  {profile?.birthdate && (
                    <>
                      <hr className="vertical !min-h-0 !h-4" />
                      <div className={styles['profile-info']}>
                        <span role="img" aria-label="cake">
                          🎂
                        </span>
                        <h3>
                          {getAge(new Date(profile?.birthdate ?? new Date()))}
                        </h3>
                        <span>
                          {' '}
                          {t('mycastings:years')}
                        </span>
                      </div>
                    </>

                  )
                  }

                  {(
                    <>
                      <hr className="vertical !min-h-0 !h-4" />
                      <div className={styles['profile-info']}>
                        <span role="img" aria-label="trophee">
                          🏆
                        </span>
                        <h3
                          data-tip=""
                          data-for={
                            mediaSold?.length ?? -1 > 0 ? 'success-vogzter' : ''
                          }
                        >
                          {briefApplicationsWon
                            ? baWonLength
                            : mediaSold?.length ?? 0}
                        </h3>
                        <span>
                          {briefApplicationsWon ? (
                            <>
                              Casting <br /> {t('mycastings:won')}
                            </>
                          ) : (
                            <>
                              {t('mycastings:collaborations')}
                            </>
                          )}
                        </span>
                      </div>
                    </>
                  )}
                  {showDisqualifyBtn && utilsMobx.getWidth > 1200 && (
                    <>
                      <hr className="vertical !min-h-0 !h-4" />
                      <button
                        type="button"
                        onClick={() => {
                          modal.resetData()
                          modal.toogleAlert('warning')
                          modal.setOnConfirm(() => {
                            briefR.validateOrCloseBriefApplication(
                              (idba as unknown) as string,
                              'decline'
                            )
                          })
                        }}
                        className={`${styles['little-btn']} ${styles['disqualify-btn']}`}
                      >
                        {t('mycastings:disqualify')}{' '}
                        <i className="fa-regular fa-circle-minus" />
                      </button>
                    </>
                  )}
                  <div className="flex flex-row gap-2 sm:flex-col mb-2">
                    {briefR.briefApplication.loading ? (
                      <Loading
                        height={'15px'}
                        width={'20px'}
                        color="grey"
                        className={`!inline !bg-transparent`}
                      />
                    ) : (briefApplication?.applicant._id || user) &&
                      showFavBtn ? (
                      <Favourite
                        user={(briefApplication?.applicant ?? user)!}
                        profilBa
                        className="!relative"
                      />
                    ) : null}
                  </div>
                </div>
                {portfolio?.showBtn && (
                  <button
                    className={styles['btn-portfolio']}
                    type="button"
                    onClick={() => {
                      portfolio?.setShowPortfolio(true)
                      refPortfolioContainer.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      })
                    }}
                  >
                    {t('mycastings:portfolio')}
                    <i className="fa-regular fa-circle-play" />
                  </button>
                )}
              </div>

              {utilsMobx.getWidth < 1200 && (
                <div className="flex items-center !justify-evenly mt-2">
                  {showDisqualifyBtn && (
                    <button
                      type="button"
                      onClick={() => {
                        modal.resetData()
                        modal.toogleAlert('warning')
                        modal.setOnConfirm(() => {
                          briefR.validateOrCloseBriefApplication(
                            (idba as unknown) as string,
                            'decline'
                          )
                        })
                      }}
                      className={`mr-4 ${styles['little-btn']} ${styles['disqualify-btn']}`}
                    >
                      {t('mycastings:disqualify')}{' '}
                      <i className="fa-regular fa-circle-minus" />
                    </button>
                  )}
                  {setShowMessages && user?.status !== 'deleted' && !isGuest && (
                    <div
                      className={`${styles['div-btn-msg']} ${classNames?.msgBtn ?? ''
                        }`}
                    >
                      <div className={styles['btn-msg']}>
                        <button
                          type="button"
                          className={`${styles['blue-little-btn']}`}
                          onClick={() => {
                            if (!isGuest) {
                              userMobx.getUserMessages(
                                briefApplication?.applicant._id ?? user._id
                              )
                              userMobx.readMsgBrand(
                                userMobx.brand.data?._id ?? '',
                                user._id,
                                true,
                                () => setShowMessages(!showMessages)
                              )
                            } else {
                              setShowMessages(!showMessages)
                            }
                          }}
                        >
                          {msgCount > 0 && <span>{msgCount}</span>}
                          {t('mycastings:messages')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {(displayInfoVogzter && (user.profile.socials && user.profile.socials?.length > 0)) && (
                <div className="flex flex-wrap justify-center">
                  <div className={styles['vogzter-right']}>
                    {displaySocials &&
                      user?.profile.socials &&
                      user?.profile.socials?.length > 0 && (
                        <>
                          <div className={styles['social-icons']}>
                            {user?.profile.socials?.map((social, index) => {
                              return (
                                <>
                                  <a
                                    key={index}
                                    className={styles['social-icon']}
                                    href={
                                      !isGuest && userInfos._company.credits > 0 && userInfos._company.premium
                                        ? social.url
                                        : undefined
                                    }
                                    onClick={() => {
                                      if (isGuest) {
                                        sessionStorage.setItem(
                                          'vogzter',
                                          JSON.stringify(user._id)
                                        )
                                        navigate('/login')
                                      } else if (userInfos._company.premium && userInfos._company.credits <= 0)
                                        navigate('/dashboard/credits-vogz', { replace: true })
                                      else if (!userInfos._company.premium)
                                        navigate('/dashboard/premium', { replace: true })
                                    }}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    <img
                                      src={social.social.img_profile}
                                      alt={social.social.name}
                                    />
                                    {
                                      social.followers ? (
                                        social.followers.max > parseInt(process.env.REACT_APP_MACRO_FOLLOWERS ?? '', 10) ? (
                                          <p className={`${styles['followers']} ${styles['mega']}`}>
                                            Mega
                                          </p>
                                        ) : social.followers.max > parseInt(process.env.REACT_APP_MICRO_FOLLOWERS ?? '', 10) ? (
                                          <p className={`${styles['followers']} ${styles['macro']}`}>
                                            Macro
                                          </p>
                                        ) : social.followers.max > parseInt(process.env.REACT_APP_NANO_FOLLOWERS ?? '', 10) ? (
                                          <p className={`${styles['followers']} ${styles['micro']}`}>
                                            Micro
                                          </p>
                                        ) : social.followers.max > parseInt(process.env.REACT_APP_MIN_FOLLOWERS ?? '', 10) ? (
                                          <p className={`${styles['followers']} ${styles['nano']}`}>
                                            Nano
                                          </p>
                                        ) : (
                                          <p className={`${styles['followers']} ${styles['unclassified']}`}>
                                            -
                                          </p>
                                        )
                                      ) : (
                                        <p className={`${styles['followers']} ${styles['unclassified']}`}>
                                          -
                                        </p>
                                      )
                                    }

                                  </a>
                                  {
                                    index !== (user?.profile?.socials?.length ?? 0) - 1 && <hr className="vertical" />
                                  }
                                </>
                              )
                            })}
                          </div>
                        </>
                      )}
                  </div>
                </div>
              )}

              {(showBtnAction?.tips?.show || showBtnAction?.sendProduct?.show ||
                showBtnAction?.viewProfile || showBtnAction?.closeCollab?.show) && (
                  <div className={`${styles['div-action']} ${!showBtnAction.sendProduct?.show && !showBtnAction.closeCollab?.show ? '!w-2/3' : ''}`}>
                    {showBtnAction?.viewProfile && (
                      <>
                        <button
                          type="button"
                          className={`${styles['btn-view-profil']} btn-outlined_ w-full sm:self-start self-center`}
                          onClick={() =>
                            navigate(
                              `/dashboard/profil-vogzter/${briefApplication?.applicant._id ?? user._id
                              }`
                            )
                          }
                        >
                          {t('mycastings:view-profile')}
                        </button>

                        <div className="w-2" />
                      </>
                    )}
                    <div className={styles['btn-send']}>
                      {showBtnAction?.tips?.show && (
                        <button
                          type="button"
                          className={`${styles['btn-send-tips']} btn-outlined_ w-full sm:self-start self-center`}
                          onClick={() => {
                            if (showBtnAction.tips) showBtnAction.tips.onClick()
                          }}
                        >
                          {t('mycastings:send-tips')}
                        </button>
                      )}
                      {showBtnAction?.sendProduct?.show && (
                        <button
                          type="button"
                          className={`${styles['btn-send-product']} btn-outlined_ w-full sm:self-start self-center`}
                          onClick={() => {
                            if (showBtnAction?.sendProduct)
                              showBtnAction.sendProduct.onClick()
                          }}
                        >
                          {t('mycastings:send-product')}
                        </button>
                      )}
                    </div>
                    {showBtnAction?.closeCollab?.show && (
                      <Fragment>
                        <hr />
                        <button
                          type="button"
                          className={`${styles['btn-close-collab']} btn-outlined_ w-full sm:self-start self-center`}
                          onClick={() => {
                            if (showBtnAction?.closeCollab)
                              showBtnAction.closeCollab.onClick()
                          }}
                        >
                          {t('mycastings:close-collab')}
                        </button>
                      </Fragment>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
        {displayBio && (profile?.bio?.length ?? -1) > 0 && (
          <>
            <hr />
            <div className={styles['profil-section']}>
              <div className={styles['profile-bio']}>
                {user.categories && user.categories.length > 0 && (
                  <div className={styles['head-bio']}>
                    <h3>
                      Hello{' '}
                      <span role="img" aria-label="Waving hand">
                        👋
                      </span>
                    </h3>
                    <div className={`${styles['languages']}`}>
                      <ListOverflowHandler
                        list={user.profile.languages.map((lang, index) => (
                          <Badge
                            className={badgeClassNameLang}
                            country={lang.name}
                            key={index}
                            label={lang.full_name}
                          />
                        ))}
                        renderOverflow={(nbOverflow) => (
                          <Badge
                            className={badgeClassNameLang}
                            label={`+${nbOverflow}`}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
                <p>{profile?.bio}</p>
              </div>
              {user.categories && user.categories.length > 0 && (
                <>
                  <hr />
                  <div className={styles['tag']}>
                    <ListOverflowHandler
                      list={user.categories.map((category) => (
                        <Badge
                          className={badgeClassName}
                          key={category._id}
                          label={category.name}
                        />
                      ))}
                      renderOverflow={(nbOverflow) => (
                        <Badge
                          className={badgeClassName}
                          label={`+${nbOverflow}`}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {mediaSoldByBrand && mediaSoldByBrand?.length > 0 && (
          <>
            <br />
            <div className={styles['part-media-sold']}>
              <Swiper
                className={styles['swiper']}
                modules={[Autoplay]}
                initialSlide={0}
                autoplay={{
                  delay: 2000,
                }}
                loop
                slidesPerView={
                  mediaSoldByBrand.length < 2
                    ? 1
                    : utilsMobx.getWidth < 1200
                      ? utilsMobx.getWidth < 600
                        ? 1
                        : 2
                      : 3
                }
                centeredSlides
                onSlideChange={(swiper) => {
                  setSwiperIndex(swiper.realIndex)
                }}
                spaceBetween={10}
              >
                {mediaSoldByBrand.map((group, index: any) => (
                  <SwiperSlide
                    className={styles['swiper-media-sold']}
                    key={index}
                  >
                    <div className={styles['card-media-sold']}>
                      {(group.brand as BrandI).logo ? (
                        <img src={(group.brand as BrandI).logo} alt="img" />
                      ) : (
                        <h3>{(group.brand as BrandI).name}</h3>
                      )}
                      <div className={styles['nb-media-sold']}>
                        <h3>
                          x<span>{group.nb_media}</span>
                        </h3>
                        <p>
                          {t('mycastings:videos')} <br /> {t('mycastings:sold')}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        )}
      </section>
    </>
  )
}

InfoProfil.defaultProps = {
  showFavBtn: false,
  showBtnAction: {
    tips: {
      show: false,
      onClick: () => { },
    },
    sendProduct: {
      show: false,
      onClick: () => { },
    },
    closeCollab: {
      show: false,
      onClick: () => { },
    },
    viewProfile: false,
  },
  isGuest: false,
  videoIntro: undefined,
  displayBio: false,
  displaySocials: false,
  classNames: undefined,
  displayInfoVogzter: false,
}

export default observer(InfoProfil)
