import { observer } from 'mobx-react'
import React, { FC } from 'react'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'
import modal from '../../../../mobx/modal'
import styles from './styles.module.scss'

const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    transition: '1s',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.40)',
    backdropFilter: 'blur(2px)'
  }
}

const ModalVideo: FC = () => {
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle!.style.color = '#f00';
  }

  return (
    <Modal
      ariaHideApp={false}
      closeTimeoutMS={500}
      isOpen={modal.modalVideo.open}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => modal.toogleModalVideo(undefined, false)}
      style={customStyles}
      className={styles['modal-video']}
      // width={120}
      // height={"100%"}
      contentLabel="video-label"
    >
      <div className={`${styles['close-video']} absolute rounded-full cursor-pointer`}
        onClick={() => modal.toogleModalVideo(undefined, false)}
      >
        <i className="fa-solid fa-xmark" />
      </div>
      <div className={styles['content-modal-video']}>
        <ReactPlayer url={modal.modalVideo.video} controls playsinline muted />
      </div>
    </Modal>
  )
}

export default observer(ModalVideo)
