import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

function BtnSendMessage({ sendMessage, disabled }: BtnSendMessageProps) {
    return (
        <button
            type="button"
            onClick={sendMessage}
            disabled={disabled}
        >
            <i className="fas fa-paper-plane" />
        </button>
    )
}

interface BtnSendMessageProps {
    sendMessage?: () => void
    disabled: boolean
}

BtnSendMessage.defaultProps = {
    disabled: false,
    sendMessage: undefined,
}

BtnSendMessage.propTypes = {
    sendMessage: PropTypes.func,
    disabled: PropTypes.bool,
}

export default BtnSendMessage