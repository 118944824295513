/* eslint-disable react/no-danger */
import { Form, Formik, FormikErrors, useFormikContext } from 'formik'
import { observer } from 'mobx-react'
import { stringify } from 'querystring'
import React, { useEffect, useRef, useState, Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import briefR from '../../../../../../../mobx/brief'
import userMobx from '../../../../../../../mobx/user'
import { Brief, TypeBrief } from '../../../../../../../types-project/Brief'
import { BrandI, ClientI, ProductsBrand } from '../../../../../../../types-project/Client'
import FieldForm from '../../FieldForm'

import SectionForm from '../../SectionForm'
import styles from '../form.module.scss'
import castingFormModel, {
  DeepPartial,
  CustomBriefI,
} from './FormModel/castingFormModel'
import { Form1Props, FormProps } from './FormModel/type-forms'
import Btn1 from '../../../../../../../utils/components/Button/Btn1'

function Form2({
  brief,
  errors,
  recommendedPrice,
  minimumPrice
}: {
  brief: Form1Props
  errors?: FormikErrors<CustomBriefI>,
  recommendedPrice: number,
  minimumPrice: number,
}) {
  // translation
  const { t } = useTranslation()
  const [regen, setRegen] = useState(false)
  const [refine, setRefine] = useState('')

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { name, description, added_info, remuneration } = errors!

  const userInfos: ClientI = userMobx.getUserInfos() as ClientI
  const brand: BrandI = userMobx.getBrandSession() ?? {}
  const selectedProduct: ProductsBrand | undefined = JSON.parse(sessionStorage.getItem('product') ?? "{}")

  useEffect(() => {
    if (window.location.hash.substring(1))
      window.document
        .getElementById(
          `${window.location.hash
            .substring(1)
            .replaceAll('%20', ' ')
            .replaceAll('%C3%A9', 'é')}`
        )!
        ?.scrollIntoView({ block: 'center' })
    const div = document.querySelector('.Page') as HTMLDivElement
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (briefR.aiBriefReq.loading) {
      div!.style.overflow = 'hidden'
    }
    div!.style.overflow = 'auto'
  }, [])

  const cancelBtn = useRef<HTMLButtonElement>(null)

  const handleCancel = () => {
    setTimeout(() => {
      cancelBtn.current?.style.setProperty('display', 'flex', 'important')
    }, 10000)
  }

  const optionsOutfit = [
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsOutfit.0.label'),
      value: 'casual',
      text: t('form-casting:casting.cards-casting-remarks.optionsOutfit.0.text'),
    },
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsOutfit.1.label'),
      value: 'chic',
      text: t('form-casting:casting.cards-casting-remarks.optionsOutfit.1.text'),
    },
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsOutfit.2.label'),
      value: 'basic',
      text: '',
    },
  ]
  const optionsType = [
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsType.0.label'),
      value: t('form-casting:casting.cards-casting-remarks.optionsType.0.value'),
      text: '',
    },
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsType.1.label'),
      value: t('form-casting:casting.cards-casting-remarks.optionsType.1.value'),
      text: t('form-casting:casting.cards-casting-remarks.optionsType.1.text'),

    },
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsType.2.label'),
      value: t('form-casting:casting.cards-casting-remarks.optionsType.2.value'),
    },
  ]
  const optionsMusic = [
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsMusic.0.label'),
      value: t('form-casting:casting.cards-casting-remarks.optionsMusic.0.value'),
      text: ''
    },
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsMusic.1.label'),
      value: t('form-casting:casting.cards-casting-remarks.optionsMusic.1.value'),
      text: t('form-casting:casting.cards-casting-remarks.optionsMusic.1.text'),
    },
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsMusic.2.label'),
      value: t('form-casting:casting.cards-casting-remarks.optionsMusic.2.value'),
    },
  ]
  const optionsBackground = [
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsBackground.0.label'),
      value: t('form-casting:casting.cards-casting-remarks.optionsBackground.0.value'),
      text: t('form-casting:casting.cards-casting-remarks.optionsBackground.0.text'),

    },
    {
      label: t('form-casting:casting.cards-casting-remarks.optionsBackground.1.label'),
      value: t('form-casting:casting.cards-casting-remarks.optionsBackground.1.value'),
      text: '',
    },
  ]

  const formikCtx = useFormikContext<CustomBriefI>() 
  return (
    <>
      {briefR.aiBriefReq.loading && (
        <div id="loading" className={`${styles['loading']}`}>
          <div className={`${styles['loading-modal']}`}>
            <div className={`${styles['loading-img-wrapper']}`}>
              <div
                className={`${styles['loading-img']} ${briefR.aiBriefReq.error && styles['error']
                  }`}
              />
              <img src="/assets/imgs/ai-face.png" alt="" />
              <div className={`${styles['eyes']}`}>
                <span
                  className={`${styles['right-eye']} ${briefR.aiBriefReq.error ? styles['eye-error'] : ''
                    }`}
                />
                <span
                  className={`${styles['left-eye']} ${briefR.aiBriefReq.error ? styles['eye-error'] : ''
                    }`}
                />
              </div>
            </div>
            <h1>
              {t('form-casting:casting.cards-casting-descbrief.brief-creation')}
            </h1>
            <p>
              {t(
                'form-casting:casting.cards-casting-descbrief.brief-creation-desc'
              )}
            </p>
            <div className='w-full flex justify-center align-middle'>
              <button
                type='button'
                ref={cancelBtn}
                className='!w-[100px] bg-red-400 text-white m-auto rounded-md hidden px-3 mt-4 py-2 transition-all hover:bg-red-600'
                onClick={() => {
                  briefR.aiBriefReq = {
                    loading: false,
                    error: null,
                    data: null,
                  }
                }}
              >
                <span className='w-full'>
                  {t('form-casting:casting.cards-casting-descbrief.hide')}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Description du brief */}
      {
        <SectionForm
          error={description}
          required={briefR.brief.data?.content_type !== 'influence'}
          title={briefR.brief.data?.content_type === 'influence' ? t('form-casting:casting.cards-casting-descbrief.title-influe') : t('form-casting:casting.cards-casting-descbrief.title')}
        // description={t('form-casting:casting.cards-casting-simple.description')}
        >
          <FieldForm<Form1Props> name="description">
            {(field, meta, helpers) => {
              const { setValue } = helpers
              const { value } = field
              return (
                <>
                  <div
                    className={` ${styles['section-first-form']} ${styles['desc-brief']} flex-col !items-start !max-w-max`}
                  >
                    <div className={`w-full border rounded-xl border-gray-300`}>
                      <textarea
                        value={value as CustomBriefI['description']}
                        className={`w-full !border-transparent ${styles['text-area-describe-brief']}`}
                        onChange={(evt) => {
                          setValue((evt.target as any).value)
                        }}
                        placeholder={t(
                          'form-casting:casting.cards-casting-descbrief.placeholder-area'
                        )}
                      />
                      <div className={`${styles['word-count']}`}>
                        <span>
                          {
                            ((value as CustomBriefI['description']) ?? '')
                              .split(/\s/)
                              .filter((o) => o).length
                          }{' '}
                          {((value as CustomBriefI['description']) ?? '')
                            .split(/\s/)
                            .filter((o) => o).length === 1
                            ? t('Mot')
                            : t('Mots')}
                        </span>
                      </div>
                    </div>
                    <p
                      data-required
                      className="mt-2.5	mb-2.5 !max-w-full"
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'form-casting:casting.cards-casting-descbrief.goodtoknow'
                        ),
                      }}
                    />
                    {!regen ? (
                      <div className={`flex my-4 ${styles['ai']}`}>
                        <div className={`flex ${styles['ai-container']}`}>
                          <div className={`${styles['ai-img']}`}>
                            <img src="/assets/imgs/vogzai.png" alt="" />
                          </div>
                          <hr />
                          <div className={`${styles['ai-text']}`}>
                            <h1>
                              {t(
                                'form-casting:casting.cards-casting-descbrief.need-help'
                              )}
                            </h1>
                            <p>
                              {t(
                                'form-casting:casting.cards-casting-descbrief.need-help-desc'
                              )}
                            </p>
                            <button
                              className={`${styles['ai-btn']}`}
                              type="button"
                              onClick={() => {
                                // depends on where the product is stored
                                window.scrollTo({ top: 0, behavior: 'smooth' })
                                handleCancel()
                                briefR
                                  .aiBrief('', '')
                                  .then((response) => {
                                    setValue(
                                      response?.replace(/^\s+/, '')
                                    )
                                    if (!briefR.aiBriefReq.error) setRegen(true)
                                  })
                              }}
                            >
                              {t(
                                `form-casting:casting.cards-casting-descbrief.need-help-btn${(formikCtx.values.description?.length ?? 0) > 0 ? '-2' : ''}`
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={`flex my-4 ${styles['ai']} !bg-[#4DD8E1]`}>
                        <div
                          className={`flex ${styles['ai-container']} flex-col`}
                        >
                          <div className={`${styles['ai-text']} text-center`}>
                            <div className={`w-[80%] m-auto flex flex-col`}>
                              <h1>
                                {t(
                                  'form-casting:casting.cards-casting-descbrief.refine-title'
                                )}
                              </h1>
                              <p>
                                {t(
                                  'form-casting:casting.cards-casting-descbrief.refine-desc'
                                )}
                              </p>
                              <p className={`!text-black mt-2 mb-2`}>
                                {t(
                                  'form-casting:casting.cards-casting-descbrief.refine-example'
                                )}
                              </p>
                            </div>
                            <input
                              onChange={(evt) => {
                                setRefine((evt.target as any).value)
                              }}
                              className="w-full border rounded-md border-gray-300"
                              placeholder={t(
                                'form-casting:casting.cards-casting-descbrief.refine-placeholder'
                              )}
                            />
                            <div className={`flex gap-2 ${styles['buttons']}`}>
                              <button
                                className={`${styles['ai-btn']} bg-{#4DD8E1}}`}
                                type="button"
                                onClick={() => {
                                  handleCancel()
                                  briefR
                                    .aiBrief(
                                      (value as CustomBriefI['description']) && value as CustomBriefI['description'],
                                      refine
                                    )
                                    .then((response) => {
                                      setValue(response?.replace(/^\s+/, ''))
                                    })
                                }}
                              >
                                {t(
                                  'form-casting:casting.cards-casting-descbrief.refine-btn'
                                )}
                              </button>
                              <button
                                className={`${styles['ai-btn']} bg-[#9B86EB]`}
                                type="button"
                                onClick={() => {
                                  handleCancel()
                                  briefR
                                    .aiBrief('', '')
                                    .then((response) => {
                                      setValue(
                                        response?.replace(/^\s+/, '')
                                      )
                                    })
                                }}
                              >
                                {t(
                                  'form-casting:casting.cards-casting-descbrief.refine-need-help-btn'
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {meta.error && (
                    <span className="error leading-3 absolute bottom-2.5 right-4">
                      {meta.error}
                    </span>
                  )}
                </>
              )
            }}
          </FieldForm>
        </SectionForm>
      }
      {
        briefR.brief.data?.content_type === 'influence' && (
          <>
            {/* Conseils à faire */}
            <SectionForm
              title={t('form-casting:casting.cards-casting-dos.title')}
              description={t('form-casting:casting.cards-casting-dos.desc')}
            >
              <FieldForm<Form1Props> name="dos">
                {(field, meta, helpers) => {
                  const { setValue } = helpers
                  const { value } = field
                  return (
                    <>
                      <div
                        className={`${styles['name-brief']} flex-col !items-start`}
                      >
                        <div className="mt-2" />
                        <div className="w-full flex-col flex lg:">
                          {((value as Brief['dos']) && ((value as Brief['dos'])?.length ?? 0) > 0 ? value as Brief['dos'] : brief.dos)?.map((v, i) => (
                            <input
                              key={i}
                              className="mt-2"
                              data-input-brief
                              onChange={(evt) => {
                                const brief = (value as Brief['dos'])?.slice()
                                if (brief) {
                                  brief[i] = (evt.target as any).value
                                  setValue(brief)
                                }
                              }}
                              value={v}
                              placeholder={
                                t(
                                  'form-casting:casting.cards-casting-prerequisite.steps-examples',
                                  { returnObjects: true }
                                )[i <= 3 ? i : 3]
                              }
                            />
                          ))}
                          <button
                            data-btn-add
                            className="self-center mt-5"
                            type="button"
                            onClick={() => {
                              setValue([
                                ...(value as Brief['dos'] ?? []),
                                '',
                              ])
                            }}
                          >
                            <i className="fa-light fa-circle-plus" />
                            {'  '}
                            {t(
                              'form-casting:casting.cards-casting-prerequisite.btn-add-prerequisite'
                            )}
                          </button>
                        </div>
                      </div>
                      {meta.error && <span>{meta.error}</span>}
                    </>
                  )
                }}
              </FieldForm>
            </SectionForm>
            {/* Conseils à ne pas faire */}
            <SectionForm
              title={t('form-casting:casting.cards-casting-donts.title')}
              description={t('form-casting:casting.cards-casting-donts.desc')}
            >
              <FieldForm<Form1Props> name="donts">
                {(field, meta, helpers) => {
                  const { setValue } = helpers
                  const { value } = field
                  return (
                    <>
                      <div
                        className={`${styles['name-brief']} flex-col !items-start`}
                      >
                        <div className="mt-2" />
                        <div className="w-full flex-col flex lg:">
                          {(((value as Brief['donts'])?.length ?? 0) > 0 ? (value as Brief['donts']) : brief.donts)?.map((v, i) => (
                            <input
                              key={i}
                              className="mt-2"
                              data-input-brief
                              onChange={(evt) => {
                                const brief = (value as Brief['donts'])?.slice()
                                if (brief) {
                                  brief[i] = (evt.target as any).value
                                  setValue(brief)
                                }
                              }}
                              value={v}
                              placeholder={
                                t(
                                  'form-casting:casting.cards-casting-prerequisite.steps-examples',
                                  { returnObjects: true }
                                )[i <= 3 ? i : 3]
                              }
                            />
                          ))}
                          <button
                            data-btn-add
                            className="self-center mt-5"
                            type="button"
                            onClick={() => {
                              setValue([
                                ...(value as Brief['donts'] ?? []),
                                '',
                              ])
                            }}
                          >
                            <i className="fa-light fa-circle-plus" />
                            {'  '}
                            {t(
                              'form-casting:casting.cards-casting-prerequisite.btn-add-prerequisite'
                            )}
                          </button>
                        </div>
                      </div>
                      {meta.error && <span>{meta.error}</span>}
                    </>
                  )
                }}
              </FieldForm>
            </SectionForm>
            {/* Mentions */}
            <SectionForm
              title={t('form-casting:casting.cards-casting-mentions.title')}
              description={t('form-casting:casting.cards-casting-mentions.desc')}
            >
              <FieldForm<Form1Props> name="mentions">
                {(field, meta, helpers) => {
                  const { setValue } = helpers
                  const { value } = field
                  return (
                    <>
                      <div
                        className={`${styles['name-brief']} flex flex-col !items-start`}
                      >
                        <div className="mt-2 flex justify-center align-middle" />
                        <div className={`w-full flex-col flex lg: ${styles['mentions-inputs']}`}>
                          {(((value as Brief['mentions'])?.length ?? 0) > 0 ? (value as Brief['mentions']) : brief.mentions)?.map((v, i) => (
                            <input
                              key={i}
                              className="mt-2"
                              data-input-brief
                              onChange={(evt) => {
                                const brief = (value as Brief['mentions'])?.slice()
                                if (brief && (evt.target.value.startsWith('@') || evt.target.value.startsWith('#') || evt.target.value === '')) {
                                  brief[i] = (evt.target as any).value
                                  setValue(brief)
                                }
                              }}
                              value={v}
                              placeholder={
                                t(
                                  'form-casting:casting.cards-casting-mentions.placeholders',
                                  { returnObjects: true }
                                )[i <= 6 ? i : 6]
                              }
                            />
                          ))}
                        </div>
                        <button
                          data-btn-add
                          className="self-center mt-5 w-full"
                          type="button"
                          onClick={() => {
                            setValue([
                              ...(value as Brief['mentions'] ?? []),
                              '',
                            ])
                          }}
                        >
                          <i className="fa-light fa-circle-plus" />
                          {'  '}
                          {t(
                            'form-casting:casting.cards-casting-prerequisite.btn-add-prerequisite'
                          )}
                        </button>
                      </div>
                      {meta.error && <span>{meta.error}</span>}
                    </>
                  )
                }}
              </FieldForm>
            </SectionForm>
            {/* Redirection */}
            <SectionForm
              title={t('form-casting:casting.cards-casting-redirection.title')}
              description={t('form-casting:casting.cards-casting-redirection.desc')}
            >
              <FieldForm<Form1Props> name="redirection">
                {(field, meta, helpers) => {
                  const { setValue } = helpers
                  const { value } = field
                  return (
                    <>
                      <div
                        className={`${styles['name-brief']} flex-col !items-start`}
                      >
                        <div className="mt-2" />
                        <div className="w-full flex-col flex lg:">
                          <input
                            type='url'
                            value={value as CustomBriefI['redirection']}
                            data-input-brief
                            className={`w-full mt-2`}
                            onChange={(evt) => {
                              setValue((evt.target as any).value)
                            }}
                            placeholder='Ex : https://www.monlien.fr'
                          />
                        </div>
                      </div>
                      {meta.error && (
                        <span className="error leading-3 absolute bottom-2.5 right-4">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )
                }}
              </FieldForm>
            </SectionForm>
          </>
        )
      }
      {/* Remarks  */}
      {briefR.brief.data?.content_type !== 'influence' &&
        <SectionForm
          title={t('form-casting:casting.cards-casting-remarks.title')}
          description={t('form-casting:casting.cards-casting-remarks.desc')}
          required
          error={added_info}
        >
          <FieldForm<Form1Props> name="added_info">
            {(field, meta, helpers) => {
              const { setValue } = helpers
              const { value } = field
              return (
                <>
                  <div
                    className={`${styles['name-brief']} flex-col !items-start`}
                  >
                    <div className="flex-row flex-wrap flex items-center my-4">
                      <h4 className={styles['remarks']}>
                        {t("form-casting:casting.cards-casting-remarks.outfit")} {' '}
                        <span role="img" aria-label="vêtement">
                          👕
                        </span>
                      </h4>
                      <fieldset
                        onChange={(e) => {
                          const brief = (value as Brief['added_info']).slice()
                          const selectedOutfit = optionsOutfit.find(
                            (o) => o.value === (e.target as any).value
                          )
                          brief[0] = selectedOutfit?.text ?? ''
                          setValue(brief)
                        }}
                        id="group"
                        className={`flex !flex-col sm:!flex-row `}
                      >
                        {optionsOutfit.map((v, i) => {
                          return (
                            <label
                              key={i}
                              className="flex items-center"
                              htmlFor={`remarks-${v.value}`}
                            >
                              <input
                                key={i}
                                className="app-checkbox"
                                type="radio"
                                readOnly
                                id={`remarks-${v.value}`}
                                name={v.value}
                                value={v.value}
                                checked={
                                  v.value === 'basic'
                                    ? (field.value as Brief['added_info']).at(0) ===
                                    '' ||
                                    (optionsOutfit.some(
                                      (o) =>
                                        o.text !==
                                        (field.value as Brief['added_info']).at(0)
                                    ) &&
                                      optionsOutfit.every(
                                        (o) =>
                                          o.text !==
                                          (field.value as Brief['added_info']).at(0)
                                      )) && (field.value as Brief['added_info']).at(0) !== null
                                    : (field.value as Brief['added_info']).at(0) ===
                                    v.text
                                }
                              />
                              <span className="text-[14px]">{v.label}</span>
                            </label>
                          )
                        })}
                      </fieldset>
                    </div>

                    <div className="flex-row flex-wrap flex items-center my-4">
                      <h4 className={styles['remarks']}>
                        {t('form-casting:casting.cards-casting-remarks.type')} {' '}
                        <span role="img" aria-label="drapeau de cinéma">
                          🎬
                        </span>
                      </h4>
                      <fieldset
                        onChange={(e) => {
                          const brief = (value as Brief['added_info']).slice()
                          const selectedType = optionsType.find(
                            (o) => o.value === (e.target as any).value
                          )
                          brief[1] = selectedType?.text ?? ''
                          setValue(brief)
                        }}
                        id="group"
                        className={`flex !flex-col sm:!flex-row `}
                      >
                        {optionsType.map((v, i) => {
                          return (
                            <label
                              key={i}
                              className="flex items-center"
                              htmlFor={`remarks-${v.value}`}
                            >
                              <input
                                key={i}
                                className="app-checkbox"
                                type="radio"
                                readOnly
                                id={`remarks-${v.value}`}
                                name={v.value}
                                value={v.value}
                                checked={
                                  v.value === 'post' ?
                                    (field.value as Brief['added_info']).at(1) === ''
                                    && (field.value as Brief['added_info']).at(1) !== null
                                    : v.value === 'persoType' ?
                                      (optionsType.some(
                                        (o) =>
                                          o.text !==
                                          (field.value as Brief['added_info']).at(1)
                                      ) &&
                                        optionsType.every(
                                          (o) =>
                                            o.text !==
                                            (field.value as Brief['added_info']).at(1)
                                        ))
                                      && (field.value as Brief['added_info']).at(1) !== null
                                      : (field.value as Brief['added_info']).at(1) ===
                                      v.text
                                }
                              />
                              <span className="text-[14px]">{v.label}</span>
                            </label>
                          )
                        })}
                      </fieldset>
                    </div>

                    <div className="flex-row flex-wrap flex items-center my-4">
                      <h4 className={styles['remarks']}>
                        {t('form-casting:casting.cards-casting-remarks.music')}{' '}
                        <span role="img" aria-label="symbole de musique">
                          🎵
                        </span>
                      </h4>
                      <fieldset
                        onChange={(e) => {
                          const brief = (value as Brief['added_info']).slice()
                          const selectedMusic = optionsMusic.find(
                            (o) => o.value === (e.target as any).value
                          )
                          brief[2] = selectedMusic?.text ?? ''
                          setValue(brief)
                        }}
                        id="group"
                        className={`flex !flex-col sm:!flex-row `}
                      >
                        {optionsMusic.map((v, i) => {
                          return (
                            <label
                              key={i}
                              className="flex items-center"
                              htmlFor={`remarks-${v.value}`}
                            >
                              <input
                                key={i}
                                className="app-checkbox"
                                type="radio"
                                readOnly
                                id={`remarks-${v.value}`}
                                name={v.value}
                                value={v.value}
                                checked={
                                  v.value === 'oui'
                                    ? (field.value as Brief['added_info']).at(2) === '' &&
                                    (field.value as Brief['added_info']).at(2) !== null
                                    : v.value === 'persoMusic'
                                      ? (optionsMusic.some(
                                        (o) =>
                                          o.text !==
                                          (field.value as Brief['added_info']).at(2)
                                      ) &&
                                        optionsMusic.every(
                                          (o) =>
                                            o.text !==
                                            (field.value as Brief['added_info']).at(2)
                                        )) && (field.value as Brief['added_info']).at(2) !== null
                                      : (field.value as Brief['added_info']).at(2) ===
                                      v.text
                                }
                              />
                              <span className="text-[14px]">{v.label}</span>
                            </label>
                          )
                        })}
                      </fieldset>
                    </div>
                    <div className="flex-row flex-wrap flex items-center my-4">
                      <h4 className={styles['remarks']}>
                        {t('form-casting:casting.cards-casting-remarks.background')}{' '}
                        <span role="img" aria-label="symbole de video">
                          🎥
                        </span>
                      </h4>
                      <fieldset
                        onChange={(e) => {
                          const brief = (value as Brief['added_info']).slice()
                          const selectedBackground = optionsBackground.find(
                            (o) => o.value === (e.target as any).value
                          )
                          brief[3] = selectedBackground?.text ?? ''
                          setValue(brief)
                        }}
                        id="group"
                        className={`flex !flex-col sm:!flex-row `}
                      >
                        {optionsBackground.map((v, i) => {
                          return (
                            <label
                              key={i}
                              className="flex items-center"
                              htmlFor={`remarks-${v.value}`}
                            >
                              <input
                                key={i}
                                className="app-checkbox"
                                type="radio"
                                readOnly
                                id={`remarks-${v.value}`}
                                name={v.value}
                                value={v.value}
                                checked={
                                  v.value === 'no-background'
                                    ? (field.value as Brief['added_info']).at(3) === '' ||
                                    (optionsBackground.some(
                                      (o) =>
                                        o.text !==
                                        (field.value as Brief['added_info']).at(3)
                                    ) &&
                                      optionsBackground.every(
                                        (o) =>
                                          o.text !==
                                          (field.value as Brief['added_info']).at(3)
                                      )) && (field.value as Brief['added_info']).at(3) !== null
                                    : (field.value as Brief['added_info']).at(3) ===
                                    v.text
                                }
                              />
                              <span className="text-[14px]">{v.label}</span>
                            </label>
                          )
                        })}
                      </fieldset>
                    </div>
                    <h3>Ajouter vos propres remarques</h3>
                  </div>
                  <div
                    className={`${styles['name-brief']} flex-col !items-start`}
                  >
                    <div className="mt-2" />
                    <div className="w-full flex-col flex sm:w-9/12 lg:">
                      {(value as Brief['added_info'])?.map((v, i) => (
                        <input
                          key={i}
                          className="mt-2"
                          data-input-brief
                          onChange={(evt) => {
                            const brief = (value as Brief['added_info']).slice()
                            brief[i] = (evt.target as any).value
                            setValue(brief)
                          }}
                          placeholder={
                            t(
                              'form-casting:casting.cards-casting-remarks.remarks-examples',
                              { returnObjects: true }
                            )[i <= 3 ? i : 3]
                          }
                          value={v!}
                        />
                      ))}
                      <button
                        data-btn-add
                        className="self-center mt-5"
                        type="button"
                        onClick={() => {
                          setValue([...(value as Brief['added_info']), ''])
                        }}
                      >
                        <i className="fa-light fa-circle-plus" />
                        {'  '}
                        {t(
                          'form-casting:casting.cards-casting-remarks.btn-add-remarks'
                        )}
                      </button>
                    </div>
                  </div>
                  {meta.error && (
                    <span className="error leading-3 absolute bottom-2.5 right-4">
                      {meta.error}
                    </span>
                  )}
                </>
              )
            }}
          </FieldForm>
        </SectionForm>
      }
      {/* Name casting */}
      <SectionForm
        error={name}
        required
        info
        title={t('form-casting:casting.cards-casting-brief.title')}
      // description={t('form-casting:casting.cards-casting-simple.description')}
      >
        <FieldForm<Form1Props> name="name">
          {(field, meta, helpers) => {
            const { setValue } = helpers
            const { value } = field
            return (
              <>
                <div
                  className={`${styles['name-brief']} flex-col !items-start`}
                >
                  <div className="mt-2" />
                  <input
                    className="w-full flex sm:w-9/12"
                    data-input-brief
                    value={(value as CustomBriefI['name']) ?? ''}
                    placeholder={t(
                      'form-casting:casting.cards-casting-brief.input-placeholder'
                    )}
                    onChange={(evt) => {
                      setValue((evt.target as any).value, false)
                    }}
                  />
                  {/* <div className="mt-4" />
              <h4>
                {t('form-casting:casting.cards-casting-brief.illustration')}
                <span>{t('form-casting:casting.recommanded')}</span>
              </h4>
              <p
                className="!m-0"
                data-required
                dangerouslySetInnerHTML={{
                  __html: t(
                    'form-casting:casting.cards-casting-brief.desc-illustration'
                  ),
                }}
              /> */}
                </div>
                {meta.error && (
                  <span className="error leading-3 absolute bottom-2.5 right-4">
                    {meta.error}
                  </span>
                )}
              </>
            )
          }}
        </FieldForm>
      </SectionForm>
      {/* Name private casting */}
      <SectionForm
        recommended
        info
        title={t('form-casting:casting.cards-casting-brief.title_private')}
      // description={t('form-casting:casting.cards-casting-simple.description')}
      >
        <FieldForm<Form1Props> name="name_private">
          {(field, meta, helpers) => {
            const { setValue } = helpers
            const { value } = field
            return (
              <>
                <div
                  className={`${styles['name-brief']} flex-col !items-start`}
                >
                  <div className="mt-2" />
                  <input
                    className="w-full flex sm:w-9/12"
                    data-input-brief
                    value={(value as CustomBriefI['name_private']) ?? ''}
                    placeholder={t(
                      'form-casting:casting.cards-casting-brief.input-placeholder'
                    )}
                    onChange={(evt) => {
                      setValue((evt.target as any).value, false)
                    }}
                  />
                  {/* <div className="mt-4" />
              <h4>
                {t('form-casting:casting.cards-casting-brief.illustration')}
                <span>{t('form-casting:casting.recommanded')}</span>
              </h4>
              <p
                className="!m-0"
                data-required
                dangerouslySetInnerHTML={{
                  __html: t(
                    'form-casting:casting.cards-casting-brief.desc-illustration'
                  ),
                }}
              /> */}
                </div>
                {meta.error && (
                  <span className="error leading-3 absolute bottom-2.5 right-4">
                    {meta.error}
                  </span>
                )}
              </>
            )
          }}
        </FieldForm>
      </SectionForm>

      {/* Prerequisites */}
      {briefR.brief.data?.content_type !== 'influence' &&
        <SectionForm
          title={t('form-casting:casting.cards-casting-prerequisite.title')}
          description={t('form-casting:casting.cards-casting-prerequisite.desc')}
        >
          <FieldForm<Form1Props> name="creative_guidelines">
            {(field, meta, helpers) => {
              const { setValue } = helpers
              const { value } = field
              return (
                <>
                  <div
                    className={`${styles['name-brief']} flex-col !items-start`}
                  >
                    <div className="mt-2" />
                    <div className="w-full flex-col flex sm:w-9/12 lg:">
                      {(value as Brief['creative_guidelines'])?.map((v, i) => (
                        <input
                          key={i}
                          className="mt-2"
                          data-input-brief
                          onChange={(evt) => {
                            const brief = (value as Brief['added_info']).slice()
                            brief[i] = (evt.target as any).value
                            setValue(brief)
                          }}
                          value={v}
                          placeholder={
                            t(
                              'form-casting:casting.cards-casting-prerequisite.steps-examples',
                              { returnObjects: true }
                            )[i <= 3 ? i : 3]
                          }
                        />
                      ))}
                      <button
                        data-btn-add
                        className="self-center mt-5"
                        type="button"
                        onClick={() => {
                          setValue([
                            ...(value as Brief['creative_guidelines']),
                            '',
                          ])
                        }}
                      >
                        <i className="fa-light fa-circle-plus" />
                        {'  '}
                        {t(
                          'form-casting:casting.cards-casting-prerequisite.btn-add-prerequisite'
                        )}
                      </button>
                    </div>
                  </div>
                  {meta.error && <span>{meta.error}</span>}
                </>
              )
            }}
          </FieldForm>
        </SectionForm>
      }
      {/* Scenario */}
      {briefR.brief.data?.content_type !== 'influence' &&
        <SectionForm
          title={t('form-casting:casting.cards-casting-scenario.title')}
          description={t('form-casting:casting.cards-casting-scenario.desc')}
        >
          <FieldForm<Form1Props> name="scenario">
            {(field, meta, helpers) => {
              const { setValue } = helpers
              const { value } = field
              return (
                <>
                  <div
                    className={`${styles['name-brief']} flex-col !items-start`}
                  >
                    <div className="mt-2" />
                    <div className="w-full flex-col flex xl:w-9/12 lg:w-full">
                      <div
                        className={`grid  !grid-cols-1 xl:!grid-cols-2 gap-4 ${styles['steps-scenario']} lg:justify-center`}
                      >
                        {(value as Brief['scenario'])?.map((v, i) => (
                          <div
                            className={`max-w[400] w-full flex ${styles['step-scenario']}`}
                            key={i}
                          >
                            <span>
                              <strong className="flex flex-col items-center">
                                {i + 1}
                              </strong>{' '}
                              Étape
                            </span>
                            <input
                              onChange={(evt) => {
                                const brief = (value as Brief['scenario']).slice()
                                brief[i] = (evt.target as any).value
                                setValue(brief)
                              }}
                              placeholder={
                                t(
                                  'form-casting:casting.cards-casting-scenario.steps-examples',
                                  { returnObjects: true }
                                )[i <= 3 ? i : 3]
                              }
                              value={v}
                            />
                          </div>
                        ))}

                        <div className="flex items-center justify-center">
                          <button
                            data-btn-add
                            className="self-center"
                            type="button"
                            onClick={() => {
                              setValue([...(value as Brief['scenario']), ''])
                            }}
                          >
                            <i className="fa-light fa-circle-plus" />
                            {'  '}
                            {t(
                              'form-casting:casting.cards-casting-scenario.btn-add-step'
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {meta.error && <span>{meta.error}</span>}
                </>
              )
            }}
          </FieldForm>
        </SectionForm>
      }
      {/* Link  */}
      <SectionForm
        title={t('form-casting:casting.cards-casting-links.title')}
        description={t('form-casting:casting.cards-casting-links.desc')}
      >
        <FieldForm<Form1Props> name="to_checkout">
          {(field, meta, helpers) => {
            const { setValue } = helpers
            const { value } = field
            return (
              <>
                <div
                  className={`${styles['name-brief']} flex-col !items-start`}
                >
                  <div className="mt-2" />
                  <div className="w-full flex-col flex sm:w-9/12 lg:">
                    {(value as Brief['to_checkout']).links?.map((v, i) => (
                      <input
                        key={i}
                        className="mt-2"
                        type="url"
                        pattern="https://.*"
                        data-input-brief
                        onChange={(evt) => {
                          const brief = (value as Brief['to_checkout']).links.slice()
                          brief[i].url = (evt.target as any).value
                          setValue({
                            ...(value as Brief['to_checkout']),
                            links: brief,
                          })
                        }}
                        value={(value as Brief['to_checkout']).links[i].url}
                        placeholder={
                          t(
                            'form-casting:casting.cards-casting-links.links-examples',
                            {
                              defaultValue: [],
                              returnObjects: true,
                            }
                          ).at(0)
                        }
                      />
                    ))}
                    <div className={styles['checkbox-casting']}>
                      <input
                        type="checkbox"
                        id="linkBrand"
                        defaultChecked={
                          userInfos._company.type === 'agency'
                            ? !!brand.info?.save_info
                            : !!userInfos.brand?.info?.save_info
                        }
                        onClick={(evt) => {
                          const isChecked = (evt.target as HTMLInputElement).checked;
                          userMobx.updateGuestBrand(
                            (briefR.brief.data?.brand as BrandI)!._id!,
                            {
                              info: { save_info: isChecked },
                            }
                          )
                        }}
                      />
                      <label htmlFor="linkBrand">
                        {t(
                          'form-casting:casting.cards-casting-infobrand.placeholder-checkbox'
                        )}{' '}
                      </label>
                    </div>
                    <button
                      data-btn-add
                      className="self-center mt-5"
                      type="button"
                      onClick={() => {
                        setValue({
                          ...(value as Brief['to_checkout']),
                          links: (value as Brief['to_checkout']).links.concat({ url: '' }),
                        })
                      }}
                    >
                      <i className="fa-light fa-circle-plus" />
                      {'  '}
                      {t(
                        'form-casting:casting.cards-casting-links.btn-add-link'
                      )}
                    </button>
                  </div>
                </div>
              </>
            )
          }}
        </FieldForm>
      </SectionForm>
      {/* Remuneration casting */}
      {
        (briefR.brief.data?.status === 'created' || briefR.brief.data?.status === 'to-validate') && (
          <SectionForm
            error={remuneration}
            required
            title={t('form-casting:casting.cards-remuneration.title')}
            description={t('form-casting:casting.cards-remuneration.description')}
            override
          >
            {(h3: Element, description: string) => {
              const header: Element = h3
              const desc: string = description
              return (
                <FieldForm<Form1Props> name="remuneration" key={'remuneration'}>
                  {(field, meta, helpers) => {
                    const { setValue } = helpers
                    const { value } = field
                    return (
                      <>
                        <div className="flex md:flex-row flex-col">
                          <div className={styles['rem-header']}>
                            {header}
                            <p
                              dangerouslySetInnerHTML={{ __html: desc }}
                              data-required
                            />
                          </div>
                          <div
                            className={`${styles['name-brief']} ${styles['remuneration-brief']} flex-col !items-start`}
                          >
                            <div className="mt-2" />
                            <div className={styles['rem-label']}>
                              <span>{t('form-casting:casting.cards-remuneration.label')}</span>
                              <hr />
                              <span>{t('form-casting:casting.cards-remuneration.min', { minimumPrice: minimumPrice.toString() })}</span>
                            </div>
                            <div className={`${styles['input-rem']}`}>
                              <input
                                className="max-w-xs text-center w-full !mt-1 flex sm:w-9/12"
                                data-input-brief
                                value={(value as CustomBriefI['remuneration'])}
                                placeholder={`${recommendedPrice?.toString()} €` ?? t(
                                  'form-casting:casting.cards-remuneration.input-placeholder.euro'
                                )}
                                onChange={(evt: any) => {
                                  evt.target.value = evt.target.value.replace(/[^0-9]/g, '')
                                  setValue((evt.target as any).value, false)
                                }}
                              />
                              {
                                value && (
                                  <span>€</span>
                                )
                              }
                            </div>
                            <p className={styles['desc-remuneration']}>
                              {t('form-casting:casting.cards-remuneration.fees')}
                            </p>
                          </div>
                        </div>
                        <div className={`${styles['rem-indicator']}
                      ${(!value || value === recommendedPrice) ? styles['indicator-default'] :
                            // @ts-ignore
                            value < recommendedPrice ? styles['indicator-low']
                              : styles['indicator-high']
                          }
                      `}>
                          <div className={styles['indicator-content']}>
                            {
                              (!value || value === recommendedPrice) ?
                                (
                                  <>
                                    <h3>
                                      <i className="fa-solid fa-circle" />
                                      {t('form-casting:casting.cards-remuneration.indicator-title')}
                                    </h3>
                                    <p
                                      dangerouslySetInnerHTML={{ __html: t('form-casting:casting.cards-remuneration.indicator-desc', { price: recommendedPrice }) }}
                                    />
                                  </>
                                  // @ts-ignore
                                ) : value < recommendedPrice ?
                                  (
                                    <>
                                      <h3>
                                        <i className="fa-solid fa-circle" />
                                        {t('form-casting:casting.cards-remuneration.indicator-low-title')}
                                      </h3>
                                      <p
                                        dangerouslySetInnerHTML={{ __html: t('form-casting:casting.cards-remuneration.indicator-low-desc', { price: recommendedPrice }) }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <h3>
                                        <i className="fa-solid fa-circle" />
                                        {t('form-casting:casting.cards-remuneration.indicator-high-title')}
                                      </h3>
                                      <p>{t('form-casting:casting.cards-remuneration.indicator-high-desc')}</p>
                                    </>
                                  )
                            }
                          </div>
                        </div>
                        {meta.error && (
                          <span className="error leading-3 absolute bottom-2.5 right-4">
                            {meta.error}
                          </span>
                        )}
                      </>
                    )
                  }}
                </FieldForm>)
            }}
          </SectionForm>
        )
      }
    </>
  )
}

Form2.defaultProps = {
  errors: {},
}

export default observer(Form2)