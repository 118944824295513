import { PropTypes } from "mobx-react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { TypeBrief } from "../../../../../types-project/Brief";
import styles from './components/styles.module.scss'

interface SlideStepVogzProps{
  step: number,
  className?: string,
  onClick?: () => void
}

const SlideStepVogz: FC<SlideStepVogzProps> = ({step, className, onClick}) => {
  const { t } = useTranslation()

  // VIP type is not used, I did it on purpose
  const mainImg =
    step === 1
    ? '/assets/imgs/img-step-vogz-1.png'
    : step === 2
    ? '/assets/imgs/img-step-vogz-2.png'
    : step === 3
    ? '/assets/imgs/img-step-vogz-3.png'
    : step === 4
    ? '/assets/imgs/img-step-vogz-4.png'
    : ''
  const title = t(`register-process:start-process.step-${step}.title`)
  const desc = t(`register-process:start-process.step-${step}.desc`)


  return(
    <div className={`${styles['box-casting-type']} ${onClick && styles["clickable"]} ${className}`}
      onClick={() => {
        if (onClick) onClick()
      }}>
      <div className={styles['img-container']}>
        <img src={mainImg} alt="" />
      </div>
      <div className={styles['txt-container']}>
        <h2 dangerouslySetInnerHTML={{__html:title}}/>
        <p>{desc}</p>
      </div>
    </div>
  )
}


SlideStepVogz.propTypes={
  step: PropTypes.observableObject.isRequired,
  className: PropTypes.observableObject,
  onClick: PropTypes.observableObject
}

SlideStepVogz.defaultProps={
  className: '',
  onClick: () => {}
}

export default SlideStepVogz