/* eslint-disable react/jsx-key */
import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react';
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next';
import styles from './components/offre.module.scss'
import Switcher from './components/Switcher'
import CardOffer from './components/CardOffer'
import BtnCustom from './components/BtnCustom';
import BtnBack from '../../utils/components/Button/BtnBack';
import userMobx from '../../mobx/user';
import { ClientI } from '../../types-project/Client';



interface PremiumProps {
  showBack?: boolean;
  classNames?: {
    wrapper?: string;
  }
}

const Premium: FC<PremiumProps> = ({ showBack, classNames }) => {

  const [type, setTypeOffer] = useState<'brand' | 'agency'>('brand');
  const userInfos =userMobx.getUserInfos() as ClientI

  useEffect(() => {
    userMobx.getPublicPlans()
    if (userInfos !== null) {
      setTypeOffer(userInfos._company.type)
    }
  }, [])
  const { t } = useTranslation()
  const { loading, data, error } = userMobx.publicPlans

  return (
    <main className={`${styles['main-offers']} ${classNames?.wrapper ?? ''}`}>
      {showBack && (<section className='md:w-4/5 w-[95%] max-w-2xl'>
        <BtnBack btnName='Retour' />
      </section>)}
      <section
        className={styles['main-section-head']}>
        <h1>{t('premium:title')}</h1>
        <p>{t('premium:desc')}t</p>
        { userInfos === null &&
          <Switcher
            onChangeState={(state) => {
              setTypeOffer(state === 'first' ? 'brand' : 'agency')
            }}
            content={{
              first: 'Je suis une marque',
              second: 'Je suis une agence',
            }}
            swhitcherStyles={
              {
                activeBackgroundColorSwitcher: '#5878FF',
                activeTextColorSwitcher: '#fff',
              }
            }
            classNames={
              {
                wrapper: 'mt-5 !bg-white',
              }
            }
          />
        }
        <br />
        {(!loading && data != null) && <CardOffer isConnected={userInfos !== null} type={type} subscriptions={data} />}
        <br />
      </section>
      <section className={styles['main-section-foot__info']}>
        <div className={styles['main-section-foot__content_info']}>
          <div>
            <h3>{t('premium:know-more')}</h3>
            <p>{t('premium:rdv-desc')}</p>
          </div>
          <BtnCustom title={t('premium:rdv-btn')} onClick={() => {
            window.open('http://rdv.vogz.io', '_blank')
          }} mainColor='btn-white' />
        </div>
      </section>
    </main>
  )
}
Premium.propTypes = {
  showBack: PropTypes.bool,
  classNames: PropTypes.shape({
    wrapper: PropTypes.string.isRequired,
  })
}

Premium.defaultProps = {
  showBack: false,
}
export default observer(Premium)
