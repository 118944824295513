import React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import BtnBack from '../../../utils/components/Button/BtnBack'
import BtnBasic from '../../../utils/components/Button/BtnBasic'
import SubTitle1 from '../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../utils/components/Headings/Title1'
import styles from './components/styles.module.scss'

function PageNotFound() {

  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Not found</title>
      </Helmet>
      <main className={`${styles['main-page-404']}`}>
        <div style={{position:'absolute', height:'100%'}}>
          <img
            src='/assets/svgs/404-bottom-layer.svg'
            className={`${styles['img-layer']}`}
            alt="bottom layer" />
          <img
            src='/assets/svgs/404-top-layer.svg'
            className={`${styles['img-layer']}`}
            alt="top layer" />
        </div>

        <div className='h-5/6 w-5/6'>
          <BtnBack className={`${styles['backBtn']}`} btnName="Retour" />

          <div className="h-full flex flex-col justify-center items-center">
            <Title1
              size={50}
              text={'Oops, erreur 404'}
              className={`${styles['title']}`}
            />
            <SubTitle1
              text="La page que vous cherchez n’est pas accessible"
              className={`${styles['subtitle']}`}
            />

            <div className={`w-2/5 flex justify-center mt-16 ${styles['btnHome']}`}>
              <BtnBasic
                btnName="Revenir à l’écran d’accueil"
                type="inverted-sky-violet"
                onclick={() => {
                  navigate('/dashboard/castings')
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default PageNotFound
