import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './index.scss'
// import "./assets/fonts/fontawesome-pro-master/css/all.css";

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))
