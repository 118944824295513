import React, { Fragment, useRef } from 'react'
import ChatFeed from 'react-chat-ui/lib/ChatFeed'
import TextareaAutosize from 'react-textarea-autosize'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { isChrome, isIOS } from 'react-device-detect'
import { Message } from 'react-chat-ui'
import PropTypes from 'prop-types'
import styles from '../styles.module.scss'
import Bubble from '../Bubble'
import BtnSendMessage from './components/BtnSend'


const Chat = observer(ChatFeed)

function ChatMessages({
    classnameContainer,
    inputStyles,
    styleContainer,
    conversation,
    textMessage,
    onChangeTextMessage,
    disabledTextMessage,
    renderCustomSendButton,
    renderCustomContent,
    renderHeader,
    sendMessage,
    showBtnSendMessage,
}: ChatMessagesProps) {

    const { t } = useTranslation()

    const inputRef = useRef<HTMLTextAreaElement>(null);


    const sendMessageHandler = () => {
        const message = inputRef?.current?.value.trim();
        if (message !== '' && sendMessage) {
            sendMessage()
            inputRef.current?.blur()
            inputRef.current?.focus()
        }
    }

    return (
        <aside
            className={`${classnameContainer} ${styles['container']}`}
            style={styleContainer}
        >
            {renderHeader}
            <hr />
            <section className={`${styles['other']} ${(isIOS && !isChrome) ? styles['mobile-safari'] : (isIOS && isChrome) ? styles['ios-chrome'] : ''}`}>
                {renderCustomContent ??
                    <Fragment>
                        {conversation.length === 0 ? (
                            <div className={`${styles['placeholder-msg']} flex-1 relative items-center justify-center flex`}>
                                <img src="/assets/imgs/placeholder-msg.png" alt="messages" className='absolute h-full w-full top-0' />
                                <div className={`${styles['placeholder-msg-text']} z-10 relative flex items-center justify-center flex-col`}>
                                    <span
                                        aria-label="visage avec une goutte de sueur, souriant et embarrassé"
                                        role="img"
                                        className='text-[7.5rem] mb-2 leading-normal'
                                    >
                                        😅
                                    </span>
                                    <h3 className="bg-linear-yellow_ text-center text-main-dark-blue mb-[0.5rem] p-[5px 10px] uppercase text-[2.5rem] w-min font-hurmeBold">
                                        {t('messages:placeholder.chat.title')}
                                    </h3>
                                    <p className='text-main-dark-blue text-roboto'>{t('messages:placeholder.chat.desc')}</p>
                                </div>
                            </div>
                        ) :
                            (<Chat
                                bubblesCentered={false}
                                messages={conversation}
                                senderName={'client'}
                                bubbleStyles={{
                                    text: {
                                        fontSize: 30
                                    },
                                    chatbubble: {
                                        overflow: 'hidden'
                                    }
                                }}
                                chatBubble={Bubble}
                            />)}

                        <div className={`${styles['input-container-casting']} ${inputStyles?.classnameContainerInput}`}>
                            <div className={`${styles['input-content-casting']} ${inputStyles?.classnameWrapperInput}`}>
                                <>
                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                </>
                                <TextareaAutosize
                                    style={{ boxSizing: 'border-box' }}
                                    id="chat"
                                    ref={inputRef}
                                    className={`${styles['casting-message']} ${inputStyles?.classnameInput}`}
                                    minRows={1}
                                    maxRows={3}
                                    placeholder={t('mycastings:enter-msg')}
                                    value={textMessage}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter' && !event.shiftKey && sendMessage) {
                                            event.preventDefault()
                                            if (textMessage !== '')
                                              sendMessageHandler()
                                        }
                                    }}
                                    onChange={(value) =>
                                        onChangeTextMessage(value.currentTarget.value)
                                    }
                                    disabled={disabledTextMessage}
                                />
                            </div>
                            {(textMessage.toString().length > 0 && showBtnSendMessage) && (<div className="flex w-10">
                                {renderCustomSendButton ?? (<BtnSendMessage sendMessage={sendMessage} />)}
                            </div>)}
                        </div>
                    </Fragment>
                }
            </section>

        </aside>
    )
}

type InputStyles = {
    classnameContainerInput: React.HTMLAttributes<any>['className'],
    classnameWrapperInput: React.HTMLAttributes<any>['className'],
    classnameInput: React.HTMLAttributes<any>['className']
}

interface ChatMessagesPropsBasic {
    classnameContainer: React.HTMLAttributes<any>['className'],
    styleContainer: React.HTMLAttributes<any>['style'],
    conversation: Message[],
    textMessage: string | number | readonly string[],
    onChangeTextMessage: (value: string) => void,
    disabledTextMessage: boolean,
    renderCustomSendButton?: JSX.Element,
    renderHeader?: JSX.Element,
    renderCustomContent?: JSX.Element,
    showBtnSendMessage?: boolean,
    sendMessage: () => void,
    inputStyles?: InputStyles
}

type ChatMessagesPropsBtn = Omit<ChatMessagesPropsBasic, 'sendMessage'> & {
    renderCustomSendButton: JSX.Element
    sendMessage?: () => void
}

type ChatMessagesProps = ChatMessagesPropsBtn | ChatMessagesPropsBasic & {};

ChatMessages.defaultProps = {
    classnameContainer: '',
    styleContainer: {},
    disabledTextMessage: false,
    renderCustomSendButton: undefined,
    renderHeader: undefined,
    renderCustomContent: undefined,
    showBtnSendMessage: true,
    inputStyles: undefined

}

ChatMessages.propTypes = {
    classnameContainer: PropTypes.string,
    styleContainer: PropTypes.object,
    conversation: PropTypes.arrayOf(PropTypes.object).isRequired,
    textMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
    onChangeTextMessage: PropTypes.func.isRequired,
    disabledTextMessage: PropTypes.bool,
    renderCustomSendButton: PropTypes.func,
    renderHeader: PropTypes.func,
    renderCustomContent: PropTypes.func,
    showBtnSendMessage: PropTypes.bool,
    inputStyles: PropTypes.object,
}

export default ChatMessages