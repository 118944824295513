/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { observer } from 'mobx-react'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import ReactTooltip from 'react-tooltip'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import ReactPlayer from 'react-player'

import briefR from '../../../../../mobx/brief'
import modal from '../../../../../mobx/modal'
import styles from './components/card-styles.module.scss'
import {
  Applicant,
  ApplicantBa,
  ApplicantT,
  Brief,
  MediaBA,
  MyCasting,
  VideoDemo,
  VideoIntro,
} from '../../../../../types-project/Brief'
import userMobx from '../../../../../mobx/user'
import { BrandI } from '../../../../../types-project/Client'
import VogzterPicture from '../../../../Favorites-vogzters/Profil-vogzters/components/VogzterPicture'
import { formatVogzterName, getAge } from '../../../../../utils/helpers'
import i18n from '../../../../../utils/i18n'

// Cet component contient deux type pour pouvoir dispatcher nativement le bon type
const CardBriefApplication: FC<
  CardBriefApplicationIBa | CardBriefApplicationIDow | CardBriefApplicationVideoIntro | CardBriefApplicationVideoDemo | CardBriefApplicationIPurchases
> = (props) => {
  // Déstructuration de la liste des props reçu
  const { type, media, briefApplication, purchases, isPrivate, videoIntro, videoDemo, showBookmarked, showDownload, className, classNameBody, classNameHeader, nameCtaViewProfile, contentType, showCalendar, showDeclinePublication} = props

  // hook pour pouvoir utiliser la navigation dans notre component
  const navigate = useNavigate()

  // state pour gérer l'animation de gif quand l'utilisateur survol la card de briefapplication
  const [mouseEnter, setmouseEnter] = useState(false)

  /* state pour gérer l'animation de display de la description lorsque le purchases est a true 
    lorsque le user est sur la page mes achats notamment
  */
  const [displayDesc, setDisplayDesc] = useState(false)

  // handle click of component button
  const handleClick = () => {
    /* Si le type de la card est download et que pour le current briefapplication le status du contrat est signé, 
    son status est won, le status du média passé en props est différent de preparing 
    et aussi que la requete de génération du média ne soit pas en cour on passe sinon on passe à la seconde step
    */
    if (
      type === 'download'
        ? (media?.status === 'chosen' &&
          media?.master.status !== 'preparing' &&
          !briefR?.master.loading) && (media.deadline ? new Date(media.deadline) > new Date(): true) ||
        (purchases &&
          media?.master.status !== 'preparing' &&
          !briefR?.master.loading && (media.deadline ? new Date(media.deadline) > new Date(): true)) 
        : true
    ) {
      // Si le type de la card est briefApplication on navigue vers la page du briefApplication
      if (type === 'briefApplication') {
        navigate(
          `/dashboard/castings/${props.briefApplication?.brief._id}/briefapplication/${props.briefApplication?._id}`
        )
      } else {
        /* Si le status du master est to-create où que la date de l'expiration du media en props est inférieur à aujourd'hui,
          on génère un nouveau média avec l'id du media en props, 
          Pourquoi?: pour nous permettre de générer un média uniquement si le master peut être générer c'est a dire le status to-create ou 
          l'expiration date est inférieur à la date d'aujourd'hui donc expiré.
        */
        if (
          media?.master?.status === 'to-create' ||
          new Date(media?.master.expiresAt!).getTime() < Date.now()
        )
          // si le master est disponible pour le télécharger.
          briefR.generateMaster(
            media?._id!,
            typeof (props.media as MediaBA)?.brief_application === 'string'
              ? (props.media!.brief_application! as string)
              : undefined
          )
        else if (media?.master?.status === 'ready')
          window.open(media?.master?.source!)
      }
    }
  }
  // get the current briefapplication
  const ba = briefR.briefApplication?.data?.find(
    (v) => v?._id === media?.brief_application
  )

  // translation
  const { t } = useTranslation()

  const currentUser = (purchases || showDownload === false) ? (media && (media.user as Applicant)) :
    (briefApplication && Object.getPrototypeOf(briefApplication?.applicant) !== String.prototype) ?
      (briefApplication.applicant) : undefined

  const dateIntDeadlineMedia = new Date(media?.deadline!)

  const tooltipStatus = {
      'to-post': {
        title: t('mycastings:status-media.to-post.title'), 
        text : t('mycastings:status-media.to-post.text')
      },
      'posted': {
        title: t('mycastings:status-media.posted.title'), 
        text : t('mycastings:status-media.posted.text')
      },
      'declined': {
        title: t('mycastings:status-media.declined.title'), 
        text : t('mycastings:status-media.declined.text')
      },
      'validated': {
        title: t('mycastings:status-media.posted.title'), 
        text : t('mycastings:status-media.validated.text')
      },
      'none': {
        title: t('mycastings:status-media.to-post.title'), 
        text : t('mycastings:status-media.to-post.text')
      },

  }

  return (
    <div className={className}>
      {/* Reactooltip est utilisé pour mettre un hover avec un message au survol
      d'un élément */}
      <ReactTooltip
        id={`btm-btn-info-${media?._id}`}
        place={'top'}
        effect="solid"
        disable={
          /* React tooltip désactivé si le type de la card est briefApplication, videoIntro ou videoDemo  ou 
            que 
            - le status du briefApplication est won 
            - le status du master du media est en preparing et qu'il n'est pas en chargement.
          */
          type === 'briefApplication' || type === 'videoIntro' || type === 'videoDemo' ||
          (
            ba?.status === 'won' &&
            media?.master.status !== 'preparing' &&
            !briefR?.master.loading) ||
          (purchases &&
            media?.master.status !== 'preparing' &&
            !briefR?.master.loading)
        }
      >
        <span>
          {!purchases
            ? media?.master.status === 'preparing' || briefR?.master.loading
                ? t('mycastings:waiting')
                : ''
            : media?.master.status === 'preparing' || briefR?.master.loading
              ? t('mycastings:waiting')
              : ''}
        </span>
      </ReactTooltip>
      <div
        className={`${purchases ? styles['height-card'] : ''} ${contentType === 'influence'? styles['card-briefapplication-influe']:''} ${styles['card-briefapplication']}`}
      >
        <header className={classNameHeader}>
          {/* En tête de la card avec la photo et le NEW */}
          <figure className={type === 'download' ? styles['bought'] : ''}>
            {!isPrivate && type !== 'videoIntro' && type !== 'videoDemo' &&
              <VogzterPicture
                size={79}
                imgSource={currentUser?.profile?.picture?.source}
                gender={currentUser?.profile?.gender}
                showCertified={currentUser?.certified}
                lastActivity={currentUser?.last_activity}
                href={
                  type !== 'download'
                    ? `briefapplication/${props.briefApplication?._id ??
                    props.media?.brief_application
                    }`
                    : contentType === 'influence' 
                    ? `profil-vogzter/${currentUser?._id}` 
                    : undefined}
              />
            }
            {/* Si le nombre de média vues et le nombre de médias totals est égal
            il n'y a pas de nouveau médias donc on affiche pas le tag new ci-dessous sinon on l'affiche */}
            {briefApplication!?.media.filter((v) => v.seen === false).length ===
              briefApplication!?.media.length &&
              type !== 'download' && type !== 'videoIntro' && type !== 'videoDemo' && (
                <span className={`${styles['badge-red-avatar']}`}>NEW</span>
              )}
          </figure>
          {!isPrivate && (['briefApplication', 'download'].indexOf(type) >= 0) && (
            <div className={styles['card-desc-user-actions']}>
              <h3>
                {currentUser && (`${formatVogzterName(currentUser.first_name, currentUser.last_name)}`)}
              </h3>
              {contentType === 'influence'  && currentUser?
                <span>
                  #
                    {currentUser?.parent_code}
                </span>
              :
                <button
                  type="button"
                  onClick={() => {
                    if (currentUser && purchases)
                      navigate(`/dashboard/profil-vogzter/${currentUser._id}`)
                    else if (type === 'download')
                      navigate(`/dashboard/profil-vogzter/${props.briefApplication?.applicant._id}`)
                    else
                      navigate(`/dashboard/castings/${props.briefApplication?.brief._id}/briefapplication/${props.briefApplication?._id}`)
                  }
                  }
                >
                  {nameCtaViewProfile ?? t('mycastings:view-profile')}
                </button>
              }
            </div>
          )}
        </header>

        {/* Footer de la card */}
        <footer className={`${contentType === 'influence'? styles['card-ba-influence'] : ''}`}>
          <div>
            <div
              onMouseEnter={() => type !== 'videoIntro' && type !== 'videoDemo' && setmouseEnter(!mouseEnter)} // state gérer l'entrée de la souris
              onMouseLeave={() => type !== 'videoIntro' && type !== 'videoDemo' && setmouseEnter(!mouseEnter)} // state gérer la sortie de la souris
              className={`${styles['card-body']} ${type === 'videoIntro' || type === 'videoDemo' || !showDownload || contentType === 'influence' ? styles['card-body-bottom-radius'] : ''} ${classNameBody}`}
            >
              {/* Readable id est l'id de la vogz pour savoir quel vogz à un souci s'il veut demander une modif
              Il est affiché uniquement si le type est différent de videoIntro et videoDemo */}
              {type !== 'videoIntro' && type !== 'videoDemo'  && (
                <span className={`${styles['span']} ${contentType === 'influence'? styles['span-bottom'] : ''}`}>
                  #
                  {type === 'download'
                    ? media?.readable_id
                    : briefApplication?.media![0]?.readable_id}
                </span>
              )
              }
              {
                contentType === 'influence'  &&
              (
                <div className={`${styles['status-post']} ${
                  ['posted','validated'].indexOf(media?.publication.status!) > -1 ?
                  styles['posted']:
                  media?.publication.status === 'declined'?
                  styles['declined']:''
                }`}>
                  <span  className={`${styles['badge-posted']}`} />
                  <p>
                    <span>
                      {media?.publication.status ?
                        tooltipStatus[media?.publication.status].title
                        :''
                      }
                    </span>
                    {media?.publication.status ?
                      tooltipStatus[media?.publication.status].text:''
                    }
                  </p>
                </div>
              )
              }
              {media?.source && contentType !== 'influence' && (<button type='button' onClick={() => {
                window.open(`${media?.source.replace('.m3u8', '/medium.mp4')}`, '_blank')
              }}
                className={styles['open-btn']}
              >
                <i className="fa-solid fa-arrow-up-from-bracket" />
              </button>)}

              <span
                className={` ${styles['span']} ${styles['play-btn']}`}
                onClick={() => {
                  // make media seen if type is different than videoIntro and videoDemo
                  if (type !== 'videoDemo' && type !== 'videoIntro') {
                    briefR.setSeenMediaFromBA(
                      props.media
                        ? (media!.brief_application as MyCasting)._id
                        : briefApplication!._id,
                      props.media ? media!._id : briefApplication!.media.at(0)!._id
                    )
                  }
                  // set modal video for the media or for videoIntro or videoDemo according to type
                  modal.toogleModalVideo(
                    type === 'videoIntro'
                      ? videoIntro.source
                      : type === 'videoDemo'
                        ? videoDemo.source
                        : props.media
                          ? media!.source
                          : briefApplication!?.media![0].source,
                    true
                  )
                }}>
                <i className="fa-solid fa-play" />
              </span>
              {/* <img
                alt="play"
                src={'/assets/svgs/video-play.svg'}

              /> */}

              {/* According to the type, we either display an img thumbnail or a video static thumbnail */}
              {type !== 'videoIntro' && type !== 'videoDemo' ?
                <img
                  alt="gif-media"
                  src={
                    type === 'download'
                      ? mouseEnter
                        ? media?.gif
                        : media?.thumbnail
                      : mouseEnter
                        ? briefApplication?.media.at(0)?.gif
                        : briefApplication?.media.at(0)?.thumbnail
                  }
                />
                : <ReactPlayer
                    url={
                      type === 'videoIntro' ? videoIntro.source : videoDemo.source
                    }
                    wrapper={({ children }) => <div className='w-full h-full bg-black pointer-events-none'>{children}</div>}
                    muted
                  />
              }

            </div>
            {type === 'briefApplication' &&
              briefApplication.media.filter(
                (v) => v.seen === false && v.status === 'validated'
              ).length > 0 && (
                <motion.span
                  initial={{ opacity: 0, x: -60, y: 30 }}
                  // @ts-ignore */
                  delay={3000}
                  animate={{ opacity: 1, y: '-50%', x: '-50%' }}
                  onClick={() =>
                    navigate(
                      `/dashboard/castings/${props.briefApplication?.brief._id}/briefapplication/${props.briefApplication?._id}`
                    )
                  }
                  className={styles['badge-red']}
                >
                  +{' '}
                  {
                    briefApplication?.media.filter(
                      (v) => v.seen === false && v.status === 'validated'
                    ).length
                  }{' '}
                  {t('mycastings:new')}
                  {briefApplication!.media.filter(
                    (v) => v.seen === false && v.status === 'validated'
                  ).length > 1
                    ? 's'
                    : ''}{' '}
                  VOGZ
                </motion.span>
              )}
            
            {(['videoDemo', 'videoIntro'].indexOf(type) === -1) && showDownload && (
              <>
               { contentType === 'influence' && <br />}
                <button
                  type="button"
                  onClick={handleClick}
                  style={{ pointerEvents: 'all' }}
                  className={`${styles['btn']} ${contentType === 'influence'? styles['download-btn']:''}
                        ${type === 'download'
                      ? (( (media?.status === 'chosen' && ba?.brief.property_rights !== undefined) &&
                        media?.master.status !== 'preparing' &&
                        !briefR?.master.loading) && (media.deadline ? dateIntDeadlineMedia.getTime() > Date.now(): true) ||
                        (purchases &&
                          media?.master.status !== 'preparing' &&
                          !briefR?.master.loading) && (media.deadline ? dateIntDeadlineMedia.getTime() > Date.now() : true))
                          && (media.deadline ? dateIntDeadlineMedia.getTime() > Date.now() : false)
                        ? ''
                        : styles['disabled']
                      : ''
                    }`}
                >
                  {type === 'briefApplication'
                    ? `${briefApplication.media.filter(
                      (m) => m.status === 'validated'
                    ).length
                    }
                  ${t('mycastings:vogz-available')}`
                    : !media?.deadline || new Date(media.deadline) < new Date()
                      ? t('mycastings:expired-rights')
                      : media?.master.status === 'preparing'
                        ? media?.timeout !== undefined
                          ? `${t('mycastings:master-generation')} (${media?.timeout})`
                          : t('mycastings:course-generation')
                        : media?.master.status === 'to-create' ||
                          new Date(media!.master.expiresAt).getTime() < Date.now()
                          ? t('mycastings:generate-master')
                          : t('mycastings:download-master')}
                </button>
              </>
            )}

            {/* Show date, info of the brief inside the bought Vogz page */}
            {purchases && (
              <div className={styles['sub-footer']}>
                <div
                  className={`!flex-row items-center ${styles['header']} ${(media!?.brief as Brief).exclusive?.is_exclusive
                    ? styles['fair-green-background']
                    : (media!?.brief as Brief).private?.is_private
                      ? styles['medium-champagne-background']
                      : styles['alice-blue-background']
                    }`}
                >
                  {media?.deadline &&
                    <> 
                      <aside className='flex-1'>
                        <h3>Deadline</h3>
                        <p>
                          {DateTime.fromISO(media.deadline)
                            .setLocale('fr')
                            .toLocaleString()}
                        </p>
                      </aside>
                      <hr className='w-[.5px] bg-black h-3/4' />
                    </>
                  }
                  <aside className='flex-1'>
                    <h3>{t('mycastings:price-vogz')}</h3>
                    <p>
                      {media?.offered ? t('mycastings:card-vogz.offered') :`${(media?.brief as Brief).remuneration} €`}
                    </p>
                  </aside>
                  { ((media?.brief as Brief).content_usage && (media?.brief as Brief).property_rights && (media?.brief as Brief).property_rights !== 0) &&
                    <>
                      <hr className='w-[.5px] bg-black h-3/4' />
                      <aside className='flex-1'>
                        <h3>{t(`mycastings:content-usage`)}</h3>
                        <p>
                        {t(`mycastings:content-${(media?.brief as Brief).content_usage}`)}
                        </p>
                      </aside>
                    </>
                  }
                </div>
                <div className="flex flex-col my-2 w-full relative pl-4 pr-6 items-center">
                  <div className="flex flex-row justify-between w-full">
                    <h3>
                      #{((media?.brief as Brief).brand as BrandI).name}
                    </h3>
                    <h3>{(media!?.brief as Brief).name}</h3>
                    <i
                      className={`fa-regular cursor-pointer absolute bottom-0 right-2 ${displayDesc ? 'fa-arrow-up' : 'fa-arrow-down'}`}
                      onClick={() => {
                        setDisplayDesc(!displayDesc)
                      }}
                    />
                  </div>
                  {displayDesc && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        marginTop: -160,
                        marginBottom: 160,
                      }}
                      animate={{
                        opacity: 1,
                        marginTop: 0,
                        marginBottom: 0,
                        marginRight: '.5rem',
                        width: '100%',
                      }}
                      // @ts-ignore */
                      delay={3000}
                      exit={{
                        opacity: 0,
                        marginTop: 160,
                        marginBottom: -160,
                      }}
                    >
                      <div className="flex flex-row justify-between w-full my-2 pr-4">
                        <aside className='flex-1'>
                          <h3>{t("mycastings:date-creation")}</h3>
                          <p>
                            {DateTime.fromISO(media?.createdAt!)
                              .setLocale('fr')
                              .toLocaleString()}
                          </p>
                        </aside>
                      </div>
                      <p>{(media!?.brief as Brief).description}</p>
                    </motion.div>
                  )}
                </div>
              </div>
            )}
          </div>
          {['videoDemo', 'videoIntro'].indexOf(type) === -1 && showDownload && contentType === 'influence' && briefApplication?.brief.status !== 'closed' && (
              <Fragment>
                <div className={`${styles['option-media']}`} >
                  <div className={`${styles['part-post']}`}>
                    {(media?.publication.links.length ?? 0) > 0 && media?.publication.links.map((post, index) => (
                      <>
                        <a target='_blank' rel='noreferrer' href={post.url} aria-label='logo social'><img src={post.social?.img_small} alt={post.social?.name} /></a>
                        <br />
                      </>
                    ))}
                    <button disabled={!media?.publication.date || ['posted','validated'].indexOf(media?.publication.status) > -1} className={`${styles['btn-calendar']}  ${!media?.publication.date || media?.publication.status === 'posted' ? styles['disable-rdv'] : ''}`} type='button' onClick={showCalendar}> 
                      <img src={'/assets/svgs/calendry.svg'} alt="calendry"/> 
                    </button>
                    <br />
                    <button className={`${media?.publication.status === 'posted'? styles['btn-exclamation'] : media?.publication.status === 'declined' ? styles['btn-check'] : styles['btn-disabled'] }`} type='button' 
                    onClick={media?.publication.status === 'posted'?showDeclinePublication:()=>{
                      briefR.confirmPublication({idBA: media?.brief_application as string ,idMedia: media?._id!} )
                    }} disabled={['posted','declined'].indexOf(media?.publication.status ?? '') === -1 || ['step2','applied'].indexOf((ba as MyCasting)?.status) === -1}>
                      {
                        media?.publication.status === 'declined' ?
                        <i className="fa-solid fa-check" />
                        :
                        <i className="fa-solid fa-exclamation" />
                      }

                    </button>
                  </div>
                  <div>
                    {media?.source && (<button type='button' onClick={() => {
                        window.open(`${media?.source.replace('.m3u8', '/medium.mp4')}`, '_blank')
                      }}
                        className={styles['open-btn']}
                      >
                        <i className="fa-solid fa-arrow-up-from-bracket" />
                    </button>)}
                  </div>
                </div>
              </Fragment>
            )}
        </footer>
      </div>
    </div>
  )
}

interface CardBriefApplicationI {
  type: 'briefApplication' | 'download' | VideoIntro['type'] | VideoDemo['type'],
  showBookmarked?: boolean
  briefApplication?: MyCasting
  media?: MediaBA
  purchases?: boolean
  isPrivate?: boolean
  videoIntro?: VideoIntro
  videoDemo?: VideoDemo
  showDownload?: boolean
  className?: string
  classNameBody?: string
  classNameHeader?: string
  nameCtaViewProfile?: string
  contentType?:MyCasting['content_type']
  showCalendar?: ()=> void
  showDeclinePublication?: () => void
}

type CardBriefApplicationIBa = CardBriefApplicationI & {
  type: 'briefApplication'
  briefApplication: MyCasting
  media?: MediaBA
}

type CardBriefApplicationIDow = CardBriefApplicationI & {
  type: 'download'
  briefApplication?: MyCasting
  media: MediaBA
  isPrivate: boolean
}

type CardBriefApplicationIPurchases = CardBriefApplicationI & {
  type: 'download'
  briefApplication?: MyCasting
  media: MediaBA
  purchases: boolean
  showDownload?: boolean
}

// Type to display a vogzter video intro
type CardBriefApplicationVideoIntro = CardBriefApplicationI & {
  type: 'videoIntro'
  videoIntro: VideoIntro
  videoDemo?: any
}

// Type to display a vogzter video demo
type CardBriefApplicationVideoDemo = CardBriefApplicationI & {
  type: VideoDemo['type']
  videoDemo: VideoDemo
  videoIntro?: any
}

CardBriefApplication.propTypes = {
  showDownload: PropTypes.bool,
  className: PropTypes.string,
  classNameBody: PropTypes.string,
  classNameHeader: PropTypes.string,
  nameCtaViewProfile: PropTypes.string
}

CardBriefApplication.defaultProps = {
  showDownload: true,
  className: '',
  classNameBody: '',
  classNameHeader: '',
  nameCtaViewProfile: undefined,
}

export default observer(CardBriefApplication)
