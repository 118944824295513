import React, { FC } from 'react'
import PropTypes from 'prop-types'
import styles from './components/styles.module.scss'

const Btn1: FC<Btn1Props> = ({ onclick, btnName, disabled, className, type }) => {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={`${styles['btn1']} ${className ?? ''}`}
      onClick={onclick}
    >
      {btnName}
    </button>
  )
}

interface Btn1Props {
  onclick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
  btnName: string
  className?: string
  type?: 'button' | 'submit' | 'reset' | undefined
}

Btn1.defaultProps = {
  disabled: false,
  className: undefined,
  type: 'button'
}

Btn1.propTypes = {
  onclick: PropTypes.func.isRequired,
  btnName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.any
}

export default Btn1
