/* eslint-disable no-prototype-builtins */
/* eslint react/prop-types: 0 */
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react'
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import Lottie from 'react-lottie'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { Formik, Form } from 'formik'

import modal from '../../../../mobx/modal'
import styles from './styles.module.scss'
import * as animationData from '../../../../assets/animations/successful.json'
import CardVogz from '../../../../pages/Castings/MyCasting/components/CardVogz'
import utils from '../../../../mobx/utils'
import briefR from '../../../../mobx/brief'
import {
  ApplicantBa,
  Applicant,
  BaCustom,
  MyCasting,
  ApplicantT,
  MediaBA,
} from '../../../../types-project/Brief'
import { getAvatar, getDayt, getHour } from '../../../helpers'
import userMobx from '../../../../mobx/user'
import '@splidejs/splide/dist/css/splide.min.css'

const customStyles: Modal.Styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    transition: '1s',
    background: 'none',
    border: 'none',
    padding: '0',
    overflow: 'visible',
    height: '100%',
    pointerEvents: 'none',
  },
  overlay: {
    backgroundColor: 'rgb(0 0 0 / 50%)',
  },
}
interface FormPublicationDate {
  media: MediaBA
  date: {
    day: { value: string; error: null | string }
    hour: { value: string; error: null | string }
  },
  publicationDate?: boolean
}
const ModalCard: FC = () => {
  const { t } = useTranslation()
  const splideRef = useRef(null);
  const [hasBought, setHasBought] = useState(false)

  useEffect(() => {
    if (hasBought) {
      modal.resetData()
      if (
        !briefapplication?.brief.private?.is_private &&
        !briefapplication?.brief.exclusive?.is_exclusive
      ) {
        if (briefapplication && hasBought) {
          // Redirection URL to the purchase tab of the brief
          const url =
            window.location.href.split('?').at(0)?.concat(`?status=buys`) ??
            window.location.href
          // modal.toogleModalAddFavorite returns true if the modal is displayed or false if not
          const modalDisplayed = modal.toogleModalAddFavorite(
            true,
            briefapplication.applicant,
            () => {
              window.location.href = url
            }
          )
          // Because the callback is not called if the modal is not displayed, we need to redirect the user manually
          if (!modalDisplayed) {
            window.location.href = url
          }
        }
      }
    }
  }, [hasBought])

  const { briefapplication, briefapplications, open } = modal.getSelection

  const initialFormPublicationDate = (briefapplication?.media
    ?.filter(v => v.in_basket)
    .map(v => {
      const publicationDate = v.publication?.date ?? undefined
      return {
        media: v,
        date: {
          day: { value: publicationDate ? getDayt(publicationDate) : '', error: null },
          hour: { value: publicationDate ? getHour(publicationDate): '', error: null },
        },
        publicationDate: true,
      };
    }) || []) as FormPublicationDate[];
    
  const checkFormSend = (values:FormPublicationDate[]) => {
      const updatedFormPublicationDate = values.map(newMedia => {
        const { date, media, publicationDate } = newMedia;
        let dayError = null;
        let hourError = null;
  
        if (!publicationDate) {
          return newMedia;
        }
        if (!date.day.value || date.day.value.toString() === 'NaN') {
          dayError = t('mycastings:required-fields');
        }
  
        if (!date.hour.value || date.hour.value?.toString() === 'NaN') {
          hourError = t('mycastings:required-fields');
        }
  
        return {
          ...newMedia,
          date: {
            day: { ...date.day, error: dayError },
            hour: { ...date.hour, error: hourError },
          },
        };
      });    
      const anyMediaWithoutDate = updatedFormPublicationDate.some(
        media => media.publicationDate && (!media.date.day.value || !media.date.hour.value)
      );
  
      if (anyMediaWithoutDate) {
        if (splideRef.current as any) (splideRef.current as any).go('>');
        return values
        
      }
  
      const allMediaValidated = updatedFormPublicationDate.every(
        media => !media.date.day.error && !media.date.hour.error
      );    

      if (allMediaValidated) {
        handleClick(values);
      }
      return updatedFormPublicationDate;
  }
  const handleClick = (values?:FormPublicationDate[]) => {    
    // modal.toogleSelection(undefined, undefined)
    if (briefapplication?.hasOwnProperty('media')) {
      const prevCallBack = modal.onRequestSuccess
      modal.setOnRequestSuccess(() => {
        prevCallBack()
        setHasBought(true)
      })
      const nbMediaInBasket = briefapplication?.hasOwnProperty('media')
      ? (briefapplication?.media)?.filter((v) => {
        return v.in_basket && v.offered === false
      }).length
      : briefapplications![0]?.brief.exclusive?.is_exclusive
        ? briefapplications?.filter(
          (ba) =>
            ba.media.filter((m) => m.in_basket === true)
              .length > 0
        )?.length
        : briefapplications?.filter(
          (ba) =>
            ba.media.filter((m) => m.in_basket === true)
              .length > 0 &&
            ['won', 'lost'].indexOf(ba.status) > -1
        )?.length
      if (nbMediaInBasket && briefR.brief.data?.content_type === 'ugc') {
        const totalBasket = briefR.brief.data?.remuneration! * nbMediaInBasket        
        if ((userMobx.me.data?._company.credits! + briefR.brief.data?.prepaid!) < totalBasket) {
          userMobx.getTopUpLink(
            totalBasket - (userMobx.me.data?._company.credits! + briefR.brief.data?.prepaid!),
            {
              ok: `/dashboard/castings/${briefR.brief.data?._id}?card=ok`,
              ko: `/dashboard/castings/${briefR.brief.data?._id}?card=ko`,
            },
            (stripeUrl) => {
              window.location.href = stripeUrl
            },
            () => {}
          )
        } else
          briefR.buySelection(
            briefapplication?._id,
            false,
            modal.onRequestSuccess
          )
      } else
        if(values)
          briefR.buySelection(
            briefapplication?._id,
            true,
            async () => {
              // eslint-disable-next-line array-callback-return
              const promise = values.map(async(formMedia) => {
                if (formMedia) {
                  const dateStr = `${formMedia.date.day.value} ${formMedia.date.hour.value}`
                  const propsPublicationDate = {
                    idBA: briefapplication?._id,
                    idMedia: formMedia.media._id,
                    date: formMedia.publicationDate ? dateStr:undefined,
                  }
                  await briefR.publicationDate(propsPublicationDate)
                }
              })
              await Promise.all(promise)
              modal.onRequestSuccess()
            }
          )
    } else briefR.validateExclu()
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    // @ts-ignore
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const errors = {
    'no-more-allowance': 'Trop de vogzs sélectionnés.',
    '': 'Erreur du server.',
  } as const

  type KeysErrors = keyof typeof errors

  const applicant: Applicant = briefapplication?.applicant.hasOwnProperty(
    'profile'
  )
    ? briefapplication?.applicant
    : ((briefapplication?.applicant as unknown) as ApplicantT<ApplicantBa>)
        ?.user

  const baHasMedia = briefapplication?.hasOwnProperty('media')

  return (
    <>
      {(baHasMedia || briefapplications?.length! > 0) && (
        <Modal
          className={styles['test']}
          ariaHideApp={false}
          closeTimeoutMS={500}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          isOpen={open}
          // onAfterOpen={() => { modal.toogleSelection(undefined, undefined)  }}
          onRequestClose={() => {
            modal.toogleSelection(undefined, undefined, false)
          }}
          style={customStyles}
        >
          <div
            className={`relative max-h-[100vh] ${
              utils.isMobile ? 'block mt-40' : 'block mt-24'
            } pointer-events-auto`}
          >
            <i
              onClick={() => {
                modal.toogleSelection(undefined, undefined, false)
              }}
              className="fa-solid fa-circle-xmark text-4xl absolute right-12 -top-16 text-white pointer-events-none z-20"
            />
            <motion.div
              initial={{ opacity: 0, y: '800px' }}
              transition={{ duration: 0.5 }}
              animate={{ opacity: 1, y: 0 }}
              className={styles['content-modal-cart']}
            >
              {baHasMedia && (
                <div className={styles['bubleProfile']}>
                  <img
                    src={getAvatar(
                      applicant?.profile.gender,
                      applicant?.profile?.picture?.source
                    )}
                    alt="user profile"
                  />
                </div>
              )}
              {/*
                      // @ts-ignore */}
              <AnimatePresence exitBeforeEnter>
                {(
                  baHasMedia
                    ? briefR.validateSelection.data != null
                    : briefR.validateBaExclu.data != null
                ) ? (
                  <motion.div
                    className={styles['content-card-modal-body']}
                    key="kze"
                    initial={{ opacity: 0, y: -32 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -32 }}
                  >
                    <section>
                      <h3>{t('mycastings:modalCart.confirm-select')}</h3>
                      <Lottie
                        options={defaultOptions}
                        height={400}
                        width={400}
                      />
                    </section>
                  </motion.div>
                ) : (
                  <motion.div
                    key="modal"
                    initial={{ opacity: 0, y: -12 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -80 }}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <h3>{applicant?.first_name}</h3>
                    <p className={`text-center ${briefapplication?.brief.content_type === 'influence'? "w-3/4":''}`}>
                      { briefapplication?.brief.content_type === 'influence'?
                        t('mycastings:modalCart.programming-post') : (
                          <Fragment>
                            {t('mycastings:modalCart.valid-confirm-slct')}{' '}
                            <strong>
                              {briefapplication?.hasOwnProperty('media')
                                ? briefapplication?.media?.filter((v) => {
                                    return v.in_basket
                                  }).length
                                : briefapplications![0]?.brief.exclusive?.is_exclusive
                                ? briefapplications?.filter(
                                    (ba) =>
                                      ba.media.filter((m) => m.in_basket === true)
                                        .length > 0
                                  )?.length
                                : briefapplications?.filter(
                                    (ba) =>
                                      ba.media.filter((m) => m.in_basket === true)
                                        .length > 0 &&
                                      ['won', 'lost'].indexOf(ba.status) > -1
                                  )?.length}{' '}
                              VOGZ
                            </strong>
                            ?
                          </Fragment>
                        )
                      }  
                    </p>
                    <br /> <br />
                    <section className={styles['content-card-modal-body']}>
                    <Formik
                      initialValues={initialFormPublicationDate}
                      onSubmit={(values) => {
                        checkFormSend(values)
                      }}
                      validateOnChange={false}
                      validateOnBlur={false}

                    >
                     {  props =>(
                    <Form className={styles['caroussel-vogz']} onSubmit={props.handleSubmit}>
                        {briefapplication?.hasOwnProperty('media') ? (
                          briefapplication?.media?.filter((v) => {
                            return v.in_basket
                          }).length > 2 ||
                          (isMobile &&
                            briefapplication?.media?.filter((v) => {
                              return v.in_basket
                            }).length > 1) || briefapplication.brief.content_type === 'influence' ? (
                            <>
                              <Splide
                                ref={splideRef}
                                options={{
                                  autoplay: true,
                                  focus: 'center',
                                  type:
                                    briefapplication?.media?.filter((v) => {
                                      return v.in_basket
                                    }).length > 3
                                      ? 'loop'
                                      : '',
                                  pauseOnHover: true,
                                  perPage: utils.getWidth < 1000 ? 1 :briefapplication.brief.content_type === 'influence'? 1 : 2,
                                  pagination: false,
                                }}
                                aria-label="My Favorite Images"
                              >                              
                                {(briefapplication.brief.content_type === 'influence' ?
                                  props.values : 
                                  (briefapplication?.media?.filter((v) => { return v.in_basket}))
                                  )
                                  ?.map((v, i) => (
                                    <SplideSlide key={i} data-splide-interval="3000">
                                      <div className={styles['card-select']}>
                                        {briefapplication.brief.content_type === 'influence' && (
                                          <div className={styles['progamming-publication']}>
                                            { briefapplication?.media?.filter((v) => {
                                                return v.in_basket}).length  > 1  && 
                                              <h4> {t("mycastings:modalCart.content",{
                                                    number: i+1,
                                                    total: briefapplication?.media?.filter((v) => { return v.in_basket}).length
                                                  })}  
                                              </h4>}
                                              <br />
                                              <div className='flex flex-col mb-2'>
                                                <label htmlFor={`publication-date-${i}`}>{t("mycastings:publication-date")}</label>
                                                <input 
                                                  type="date" 
                                                  value={(v as FormPublicationDate).date.day.value}
                                                  id={`publication-date-${i}`}
                                                  min={new Date().toISOString().substring(0, 10)}
                                                  onChange={(val) =>{
                                                    props.setValues(
                                                      props.values.map((formMedia) =>
                                                          formMedia.media._id === (v as FormPublicationDate).media._id
                                                            ? {
                                                                ...formMedia,
                                                                date: { ...formMedia.date, day: { value: val.target.value, error: null } },
                                                                publicationDate: val.target.value.length > 0 
                                                              }
                                                            : formMedia
                                                      )
                                                    )
                                                  }}
                                                />
                                                {(v as FormPublicationDate).date.day.error && (
                                                  <span className="error">{(v as FormPublicationDate).date.day.error}</span>
                                                )}
                                              </div>
                                              <div className='flex flex-col mb-2'>
                                                <label htmlFor={`publication-hour-${i}`}>{t("mycastings:publication-hour")}</label>
                                                <input
                                                  type="time"
                                                  value={(v as FormPublicationDate).date.hour.value!}
                                                  id={`publication-hour-${i}`}
                                                  onChange={(val) =>{
                                                    props.setValues(
                                                      props.values.map((formMedia) =>
                                                      formMedia.media._id === (v as FormPublicationDate).media._id
                                                        ? {
                                                            ...formMedia,
                                                            date: { ...formMedia.date, hour: { value: val.target.value, error: null } },
                                                            publicationDate: val.target.value.length > 0 
                                                          }
                                                        : formMedia
                                                      )
                                                    )
                                                  } }
                                                />
                                                {(v as FormPublicationDate).date.hour.error && (
                                                <span className="error">{(v as FormPublicationDate).date.hour.error}</span>
                                                )}
                                              </div>
                                              <button
                                                type="submit"
                                                disabled={(v as FormPublicationDate).date.day.value.length === 0 && (v as FormPublicationDate).date.hour.value.length === 0}
                                                className={`${styles['btn-animate']} ${styles['btn-submit']} btn`}
                                              >
                                                {t('mycastings:modalCart.validate')}
                                              </button>
                                              <br />
                                              <button
                                                type="button"
                                                onClick={()  =>{
                                                  props.setValues(                                            
                                                    props.values.map((formMedia) =>
                                                      formMedia.media._id === (v as FormPublicationDate).media._id
                                                        ? {
                                                            ...formMedia,
                                                            publicationDate: false,
                                                          }
                                                        : formMedia
                                                    )
                                                  )
                                                  props.submitForm()
                                                  }}
                                                disabled={(v as FormPublicationDate).date.day.value.length > 0 || (v as FormPublicationDate).date.hour.value.length > 0 || briefR.validateSelection.loading}
                                                className={`${styles['btn-animate']} ${styles['btn-validate']} btn`}
                                              >
                                                {t('mycastings:modalCart.validate-no-date')}
                                              </button>
                                            
                                          </div>
                                        ) }
                                        <CardVogz confirmBasket media={ briefapplication.brief.content_type === 'influence'? (v as FormPublicationDate).media : v as MediaBA} key={i} />
                                      </div>
                                    </SplideSlide>
                                  ))}
                              </Splide>
                            </>
                          ) : (
                            <div
                              className={styles['selection-vogzs']}
                              style={{
                                gridTemplateColumns:
                                  briefapplication?.media?.filter((v) => {
                                    return v.in_basket
                                  }).length >= 2
                                    ? '50% 50%'
                                    : 'none',
                              }}
                            >
                              {briefapplication?.media
                                ?.filter((v) => {
                                  return v.in_basket
                                })
                                ?.map((v, i) => (
                                  <CardVogz confirmBasket media={v} key={i} />
                                ))}
                            </div>
                          )
                        ) : (
                          <div className={styles['caroussel-vogz']}>
                            <Splide
                              options={{
                                autoplay: true,
                                focus: 'center',
                                type:
                                  briefapplications!.filter(
                                    (ba) =>
                                      ba.media.filter((m) => m.in_basket === true)
                                        .length > 0 && ba.status !== 'won'
                                  )?.length > 3
                                    ? 'loop'
                                    : '',
                                pauseOnHover: true,
                                perPage: utils.getWidth < 1000 ? 1 : 2,
                                pagination: false,
                                arrows:
                                  briefapplications!?.filter(
                                    (ba) =>
                                      ba.media.filter((m) => m.in_basket === true)
                                        .length > 0 && ba.status !== 'won'
                                  )?.length > 2,
                              }}
                              aria-label="My Favorite Images"
                            >
                              {briefapplications
                                ?.filter(
                                  (ba) =>
                                    ba.media.filter((m) => m.in_basket === true)
                                      .length > 0 && ba.status !== 'won'
                                )
                                .map((ba, i) => (
                                  <SplideSlide data-splide-interval="3000">
                                    <CardVogz
                                      showCancel
                                      input
                                      briefApplication={ba}
                                      media={ba.media[0]}
                                      key={i}
                                      confirmBasket
                                    />
                                  </SplideSlide>
                                ))}
                            </Splide>
                          </div>
                        )}
                      </Form>
                      )}
                    </Formik>
                        <br />
                        {briefapplication!?.hasOwnProperty('media') && (
                          <div
                            className={`${styles['alert-info']} ${
                              briefapplication.brief.private?.is_private
                                ? styles['info']
                                : ''
                            }`}
                          >
                            <i className="fa-solid fa-triangle-exclamation" />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: !briefapplication.brief.private
                                  ?.is_private
                                  ? t('mycastings:modalCart.text-confirm-slct')
                                  : briefapplication.brief.content_type ===
                                    'influence'
                                  ? t(
                                      'mycastings:modalCart.text-confirm-slct-deal-influ'
                                    )
                                  : t(
                                      'mycastings:modalCart.text-confirm-slct-deal-ugc'
                                    ),
                              }}
                            />
                          </div>
                        )}
                        { briefapplication?.brief.content_type !== 'influence' && (	
                          <button
                            type="button"
                            onClick={()=>handleClick()}
                            className={`${styles['btn-animate']} ${
                              briefR.validateSelection.loading && styles['loading']
                            } ${styles['effect-left']}  btn`}
                          >
                            {t('mycastings:modalCart.btn-confirm-slct')}
                          </button>
                        )}
                        {briefR.validateSelection.error && (
                          <>
                            <br />
                            <span className="error">
                              {briefR.validateSelection.error}
                            </span>
                          </>
                        )}
       
                    </section>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default observer(ModalCard)
