/* eslint-disable react/no-danger */
import React, { FC } from 'react'
import PropTypes from 'prop-types'
import styles from './components/styles.module.scss'

const SubTitle1: FC<SubTitle1Props> = ({
  text,
  style,
  dangerouslySetInnerHTML,
  className
}) => {
  return (
    <p
      dangerouslySetInnerHTML={
        dangerouslySetInnerHTML ? { __html: text! } : undefined
      }
      className={`${styles['p-sub-heading']} ${className}`}
      style={style}
    >
      {!dangerouslySetInnerHTML ? text : undefined}
    </p>
  )
}

interface SubTitle1Props {
  dangerouslySetInnerHTML?: boolean
  text: SubTitle1Props['dangerouslySetInnerHTML'] extends boolean
    ? string
    : string | undefined
  style?: React.CSSProperties
  className?: string
}

SubTitle1.defaultProps = {
  style: {},
  text: '',
  dangerouslySetInnerHTML: false,
  className: '',
}

SubTitle1.propTypes = {
  text: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
}

export default SubTitle1
