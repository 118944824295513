/* eslint-disable react/prop-types */
import React from 'react'
import { ToastContent, ToastContentProps } from 'react-toastify'
import { ToastProps } from 'react-toastify/dist/types'
import { ChangeTypeOfKeys } from '../../../types-project/utils'

import styles from './components/styles.module.scss'

export const ToastMsg: ToastContent = ({
  closeToast,
  toastProps,
  data,
}: ToastContentProps<CustomToast>) => (
  <div className={styles['toast-custom']}>
    <aside>
      {toastProps.type === 'success' ? (
        <i
          className={`fa-regular fa-circle-check ${
            toastProps.type === 'success' ? styles['success'] : ''
          }`}
        />
      ) : toastProps.type === 'error' ? (
        <i className={`fa-regular fa-circle-xmark ${styles['error']}`} />
      ) : null}
    </aside>
    <aside>
      <h3 className={styles['title-toast']}>{data?.title}</h3>
      {data?.description && <p>{data?.description}</p>}
    </aside>
  </div>
)

export type CustomToast = {
  title: string
  description?: string
}
