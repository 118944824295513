/* eslint-disable react/no-unused-prop-types */
import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownItemProps,
  StrictDropdownProps,
} from 'semantic-ui-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { observer } from 'mobx-react'
import Skeleton from 'react-loading-skeleton'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DataGrid, GridRowsProp, GridColDef, frFR, GridRowId } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DateTime } from 'luxon'

import './components/styles.scss'
import '@progress/kendo-theme-default/dist/all.css'
import { Helmet } from 'react-helmet'
import {
  CardCastingsPROPS,
  CastingDataReducer,
  CastingTypes,
} from './components/type'
import CardCastingsVogz from './components/CardCastingsVogz'
import 'semantic-ui-css/components/dropdown.css'
import SkeletonCards from './components/SkeletonCards'
import ScrollToTop from '../../../routing/ScrollToTop'
import styles from './components/styles.module.scss'
import SearchBar from '../../../utils/components/Ui/inputs/SearchBar'
import BtnSlc1 from '../../../utils/components/Button/BtnSelectable1'
import BtnDrop, {
  CustomDopDownProps,
  // eslint-disable-next-line import/no-named-as-default-member
} from '../../../utils/components/Button/BtnDrop'
import briefR from '../../../mobx/brief'
import type { Brief, CBrief } from '../../../types-project/Brief'

import {
  filterPublishedDraft,
  sortByCreatedDate,
  TypeSort,
} from '../../../utils/helpers'
import { BrandI, TypeClient, TypeCompanyRole, Vogzter } from '../../../types-project/Client'
import BtnBasic from '../../../utils/components/Button/BtnBasic'
import BrandSwitcher from '../../../utils/components/BrandSwitcher/BrandSwitcher'
import userMobx from '../../../mobx/user'
import BriefApplication from '../BriefApplication'
import Btn1 from '../../../utils/components/Button/Btn1'
import VogzterPicture from '../../Favorites-vogzters/Profil-vogzters/components/VogzterPicture'
import modal from '../../../mobx/modal'
import utilsMobx from '../../../mobx/utils'
import { getFilesByType } from '../../../utils/indexedDB'
import i18n from '../../../utils/i18n'

// import 'semantic-ui-css/components/dropdown.min.js'

function MyCastings(props?: BrandI['_id']) {
  const [castngType, setcastngType] = useState<CastingTypes>('published')
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [displayTable, setDisplayTable] = useState(true)
  const [briefIdSelected, setBriefIdSelected] = useState<GridRowId[]>()
  const [briefSelected, setBriefSelected] = useState<CBrief>()
  const [filterCasting, setfilterCasting] = useState<
    StrictDropdownProps['value']
  >('')
  const [showMenu, setShowMenu] = useState(false)
  const [savedInspiration, setSavedInspiration] = useState<any>({})
  const [savedScript, setSavedScript] = useState<any>({})
  const { card, status } = useParams<{ card: 'ok' | 'ko', status: 'draft' | 'published' }>()
  const paymentStatus = new URLSearchParams(window.location.search).get('brief')
  const menuActionRef = useRef<HTMLDivElement>(null)
  const dbName = 'dealFiles'
  const { id } = useParams<{ id: string }>()
  // translation
  const { t } = useTranslation()
  // options notes and published dates
  const options = [
    { key: 1, text: t('mycastings:publication-date'), value: TypeSort.date },
    {
      key: 2,
      text: t('mycastings:casting-in-progress'),
      value: TypeSort.status,
    },
    { key: 3, text: t('mycastings:end-date'), value: TypeSort.deadline },
    // { key: 4, text: t('mycastings:casting-type'), value: TypeSort.private },
  ]
  const navigate = useNavigate()
  const listener = () =>
    document.addEventListener('mousedown', (event) => {
      if (event.target === null || !(event.target instanceof HTMLElement)) return
      if (!menuActionRef.current?.contains(event.target)) {
        setShowMenu(false)
      }
    })

  useEffect(() => {
    if (utilsMobx.window.width > 0 && utilsMobx.window.width < 927) {
      setDisplayTable(false)
    }
  }, [utilsMobx.window.width])
  useEffect(() => {
    // briefR.getBriefs()
    briefR.getAllBriefsFromBrand(id as string)
    if (status === 'draft') {
      setcastngType('draft')
    }
    else {
      setcastngType('published')
    }

    if (card === 'ok') {
      toast.success(t('register-process:payment-success'), { theme: 'colored' })
    } else if (card === 'ko') {
      toast.error(t('register-process:payment-error'), { theme: 'colored' })
    }

    if (paymentStatus === 'ok') {
      modal.resetData()
      modal.setTitle(i18n.t('new-deal:payment-success.title'))
      modal.setDesc(t('new-deal:payment-success.desc'))
      modal.setOnlyOneCTA(true, t('new-deal:understood'))
      modal.toogleAlert('stripe-valid')
      const request = indexedDB.deleteDatabase(dbName)
      request.onsuccess = function () {
        console.log(`Deleted database ${dbName} successfully`)
      }
      request.onerror = function (event) {
        console.log(`Error deleting database ${dbName}`, event)
      }
      request.onblocked = function (event) {
        console.log(`Database ${dbName} deletion blocked`, event)
      }
      window.history.replaceState({}, document.title, window.location.pathname)
    } else if (paymentStatus === 'ko') {
      modal.resetData()
      modal.setTitle(t('new-deal:payment-error.title'))
      modal.setDesc(t('new-deal:payment-error.desc'))
      modal.setOnlyOneCTA(true, t('new-deal:understood'))
      modal.toogleAlert('stripe-error')
      window.history.replaceState({}, document.title, window.location.pathname)
    }
    localStorage.removeItem('savedBrief')
    localStorage.removeItem('idVogzter')
    localStorage.removeItem('idOffer')
    localStorage.removeItem('isGuest')
    listener()
    if (userMobx.me.data?.company_role !== TypeCompanyRole.GUEST) {
      setDisplayTable(JSON.parse(sessionStorage.getItem('displayTable')??'true'))
    } else {
      setDisplayTable(false)
    }
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [])
  const { data, error, loading } = briefR.allBriefsBrand

  const generateSkeleton = Array.from(Array(10).keys())

  const briefs = sortByCreatedDate(
    filterPublishedDraft<CBrief>(
      briefR.allBriefsBrand.data ?? [],
      castngType,
      search
    ),
    filterCasting === 'createdAt'
      ? TypeSort.date
      : filterCasting === 'deadline'
        ? TypeSort.deadline
        : filterCasting === 'private'
          ? TypeSort.private
          : filterCasting === 'status'
            ? TypeSort.status
            : TypeSort.basic
  )
  const rows: GridRowsProp =
    briefs.map((brief, index) => {
      return {
        index: index,
        id   : brief._id,
        col1 : brief.name,
        col2 : brief.private?.is_private ? 'Deal' : 'Casting',
        col3 :  brief.createdAt,
        col4 : brief.private?.is_private || !brief.deadline? '': brief.deadline,
        col5 : brief.private?.is_private || !brief.deadline2? '': brief.deadline2 ,
        col6 : brief.remuneration,
        col7 : brief.private?.is_private ?(brief.favourites as Array<Vogzter>)?.at(0) : brief.applicants_nb,
        // col8 : brief.media_winners?.length,
        col8: t(`mycastings:status-list.${brief.status}`),
        col9: brief.targeting?.gender,
        col10: brief.targeting?.age?.min,
        col11: brief.targeting?.age?.max,
      }
    })

  const columns: GridColDef[] =
    Array.from(Array(11).keys()).map((item, index) => {
      return {
        field: `col${index + 1}`,
        headerName: t(`mycastings:table-home.${index}`),
        width: index === 0 ? 250 : 150,
        renderCell: (params) => {
          if (index === 6 && params.row.col2 === 'Deal') {
            const vogzter = params?.row?.col7 as Vogzter
            return (
              <VogzterPicture
                size={45}
                gender={vogzter?.profile?.gender}
                imgSource={vogzter?.profile?.picture?.source}
                lastActivity={vogzter?.last_activity}
                showCertified={vogzter?.certified}
                href={`/dashboard/profil-vogzter/${vogzter?._id}`}
                removeShadow

              />
            )
          }
          else if (index === 2){
            return (
              DateTime.fromISO(params.row.col3)
          .setLocale('fr')
          .toLocaleString()
            )
          }
          else if (index === 3 && params.row.col2 === 'Casting' && params.row.col4 !== ''){
            return (
              DateTime.fromISO(params.row.col4)
          .setLocale('fr')
          .toLocaleString()
            )
          }
          else if (index === 4 && params.row.col2 === 'Casting' && params.row.col5 !== ''){
            return (
              DateTime.fromISO(params.row.col5)
          .setLocale('fr')
          .toLocaleString()
            )
          }
        },
        headerClassName: 'header-table',
      }
    })


  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    frFR,
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Castings</title>
      </Helmet>
      {id && (
        <button
          type="button"
          className={`btn-txt sticky top-14 sm:top-8 left-3 z-20`}
          onClick={() => navigate(-1)}
        >
          <i className="fa-regular fa-angle-left" /> &nbsp;
          {t('messages:back')}
        </button>
      )}
      {userMobx.me.data?.company_role !== TypeCompanyRole.GUEST && <BrandSwitcher />}
      <main className={styles['casting-page']}>
        <ScrollToTop />
        <section className={styles['section-filter']}>
          {utilsMobx.window.width > 927 && userMobx.me.data?.company_role !== TypeCompanyRole.GUEST &&
            <div className={styles['filter-container']}>
              <button
                className={!displayTable ? styles['active'] : ''}
                type='button'
                onClick={() => {
                  setDisplayTable(false) 
                  sessionStorage.setItem('displayTable', 'false')
                }
                }
              ><i className="fa-solid fa-grip-vertical"/>
              </button>
              <button
                className={displayTable ? styles['active'] : ''}
                type='button'
                onClick={() =>{
                  setDisplayTable(true)
                  sessionStorage.setItem('displayTable', 'true')}
                }
              ><i className="fa-solid fa-table-list"/>
              </button>
            </div>
          }
          <SearchBar
            onChange={(v) => {
              setSearch(v.target.value ?? undefined)
            }}
            placeholder={t('mycastings:search')}
          />
          <div className={styles['btns-container']}>
            { userMobx.me.data?.company_role !== TypeCompanyRole.GUEST &&
              <div className={styles['btns-filter']}>
                <BtnSlc1
                  onclick={() => {
                    setSearch(undefined)
                    setcastngType('published')
                    navigate('?status=published')
                  }}
                  btnName={t('mycastings:published')}
                  active={castngType === 'published'}
                />
                <div className="w-10" />
                <BtnSlc1
                  onclick={() => {
                    setSearch(undefined)
                    setcastngType('draft')
                    navigate('?status=draft')
                  }}
                  btnName={t('mycastings:draft')}
                  active={castngType === 'draft'}
                />
              </div>
            }
            { displayTable && briefs!?.length > 0 && 
              <div className={styles['btns-filter']} ref={menuActionRef}>
                <Btn1
                  btnName={castngType === 'draft' ? t('team:delete') : t('mycastings:edit')}
                  onclick={() => {
                    if (castngType === 'draft' ){
                      modal.toogleAlert('warning-brief', true)
                      modal.setOnConfirm(() => {
                        briefR.deleteBrief(briefIdSelected as string[] )
                      })
                    } else if (briefIdSelected?.length === 1)
                      setShowMenu(!showMenu)
                    else
                      toast.error(t('mycastings:select-one'))
                  }}
                  className={`!min-w-[150px] ${briefSelected?.status === 'created' ? 'bg-red-500' : ''}`}
                />
                {showMenu && Object.keys(briefSelected ?? {}).length > 0 && (
                  <div className={styles['menu-action']}>
                    {briefSelected?.status !== 'closed' && (briefSelected?.private?.is_private && briefSelected.status === 'created' ) ? (
                      <li>
                        <Link
                          className="flex w-full flex-col items-center"
                          to={{ pathname: `/dashboard/castings/${briefIdSelected?.at(0)}/edit` }}
                        >
                          {t('mycastings:edit-active-casting')}
                        </Link>
                      </li>)
                      : null}
                    { !briefSelected?.private?.is_private &&
                      <li
                        onClick={() =>
                          briefR.duplicateBrief(briefSelected?._id as string, (id) => {
                            setcastngType('draft')
                            setDisplayTable(false)
                            window.document.getElementById(`in-card-${id}`)!?.scrollIntoView()
                          }, false)
                        }
                      >{
                          briefSelected?.private?.is_private
                            ? t('mycastings:duplicate-deal')
                            : t('mycastings:duplicate-casting')
                        }
                      </li>
                    }
                    {briefSelected?.status !== 'closed' && !briefSelected?.private?.is_private && (
                      <li
                        onClick={() => {
                          navigate(`/dashboard/castings/${briefSelected?._id}/extend`)
                        }}>
                        {t('mycastings:extend-casting.extend')}
                      </li>
                    )}
                    {briefSelected?.status !== 'closed' && (
                      <li
                      className={briefR.allBriefsBrand.loading || briefR.boughtMedia.loading ? styles['close-casting'] : ''}
                      onClick={() => {
                        if (!briefR.allBriefsBrand.loading && !briefR.boughtMedia.loading)
                        {
                          briefR.getAllBoughtMedia((data) => {
                            if (data?.filter(
                              (media) => {
                                    return (media?.brief as Brief)?._id === briefSelected?._id
                                  }
                                ).length > 0
                              ) {                                
                                modal.toogleAlert('close-casting', true)
                                modal.setOnConfirm(() => {
                                  briefR.closeBrief(briefSelected?._id as string)
                                })
                              } else {
                                modal.toogleAlert('warning-close-casting', true)
                              }
                            })

                            modal.setOnDismiss(() => { })
                        }
                        }}
                      >
                        {
                          briefR.allBriefsBrand.loading || briefR.boughtMedia.loading ?
                            <Skeleton className={styles['close-loading']} width={100} />:
                         (` ${t('mycastings:close-casting')} 👋`)
                        }
                      </li>
                    )}
                    {briefSelected?.status !== 'closed' && (
                      <li
                        onClick={() => {
                          navigate(`/dashboard/castings/${briefSelected?._id}/notifications?edit=true`)    
                        }}
                      >
                        {t('mycastings:notification-casting')}
                      </li>
                    )}
                  </div>)}
                  
              </div>
            }
          </div>
        </section>
        {
          displayTable && briefs!?.length > 0 ? (
            <section className={styles['section-castings']}>
              <ThemeProvider theme={theme}>
                <DataGrid
                  className={styles['table']}
                  rows={rows}
                  onCellClick={(params, e) => {
                    if (params.row.col2 === 'Deal' && (e.target as HTMLElement).getAttribute('data-field') === 'col7' ){
                      const href = (e.target as HTMLElement).querySelector('a')?.getAttribute('href') as string
                      navigate(href)
                    }
                  }}
                  columns={columns}
                  disableRowSelectionOnClick
                  initialState={{
                    sorting:{
                      sortModel: [
                        {
                          field: 'col3',
                          sort: 'desc',
                        },
                      ],
                    }
                  }}
                  onRowClick={(params,e) => {
                    if((e.target as HTMLElement).tagName.toLowerCase() === 'img'){
                      navigate(`/dashboard/castings`)
                    }
                    else if (params.row.col2 === 'Deal' && (e.target as HTMLElement).getAttribute('data-field') === 'col7' ){
                      const href = (e.target as HTMLElement).querySelector('a')?.getAttribute('href') as string
                      navigate(href)                      
                    } else if (castngType === 'published' && ( params.row.col8 !== t(`mycastings:status-list.to-validate`) || (params.row.col2 === 'Deal' && (e.target as HTMLElement).getAttribute('data-field') !== 'col7' )))
                      navigate(`/dashboard/castings/${params.row.id}`)
                    else if (params.row.col8 === t(`mycastings:status-list.to-validate`)&& params.row.col2 !== 'Deal') 
                      navigate(`/dashboard/castings`)
                    else if (params.row.col2 !== 'Deal' )
                      navigate(`/dashboard/castings/${params.row.id}/edit`)
                  }}
                  onRowSelectionModelChange={(briefIdSelected) => {
                    setBriefIdSelected(briefIdSelected)
                    if (briefIdSelected?.length === 1)
                      briefs.map((brief) => {
                        if (brief._id === briefIdSelected.at(0)) {
                          setBriefSelected(brief)
                        }
                        return null
                      }

                      )
                  }}
                  checkboxSelection
                />
              </ThemeProvider>
            </section>
          ) : (
            <div className="castings-body">
              <div className="castings-body-content">
                <div className="cards-casting-container">
                  {loading || error || briefs!?.length >= 1 ? (
                    <div className="cards-casting">
                      {loading || error
                        ? generateSkeleton.map((card, index) => {
                          return <SkeletonCards key={index} />
                          // @ts-ignore
                        })
                        : data != null
                        && sortByCreatedDate(
                          filterPublishedDraft(
                            briefR.allBriefsBrand.data!,
                            castngType,
                            search
                          ),
                          filterCasting === 'createdAt'
                            ? TypeSort.date
                            : filterCasting === 'deadline'
                              ? TypeSort.deadline
                              : filterCasting === 'private'
                                ? TypeSort.private
                                : filterCasting === 'status'
                                  ? TypeSort.status
                                  : TypeSort.basic
                        )!
                          // .filter((brief) => brief.brand === id)
                          .map((casting, index: number) => {
                            if (casting) {
                              return (
                                <CardCastingsVogz
                                  key={index}
                                  status={casting.status}
                                  type={casting?.type?.name}
                                  title={casting?.name}
                                  titlePrivate={casting?.name_private}
                                  id={casting._id}
                                  favoriteVogzter={
                                    (casting.private?.is_private === true) ? (casting.favourites as Array<Vogzter>)?.at(0) : undefined
                                  }
                                  castingScope={
                                    casting.exclusive?.is_exclusive
                                      ? 'exclusive'
                                      : casting.private?.is_private
                                        ? 'private'
                                        : 'creator'
                                  }
                                  illustration={casting.media[0]?.source}
                                  description={casting.description}
                                  creationDate={casting.createdAt}
                                  loading={briefR.allBriefsBrand.loading || briefR.boughtMedia.loading}
                                  endDate={casting.deadline2 ?? casting.deadline}
                                  callBack={(idNewB: string) => {
                                    setcastngType('draft')
                                    setDisplayTable(false)
                                    window.document.getElementById(`in-card-${idNewB}`)!
                                      ?.scrollIntoView()

                                  }}
                                />
                              )
                            }
                            return null
                          })
                      }
                    </div>
                  ) : briefs!?.length === 0 ? (
                    <div className={`${styles['placeholder-casting']}`}>
                      <img alt="placeholder" src="/assets/svgs/ph-castings.svg" />
                      <BtnBasic
                        disabled={
                          userMobx.me.data?.company_role === TypeCompanyRole.GUEST 
                        }
                        type="inverted-sky-violet"
                        onclick={() => {
                          if (userMobx.me.data?._company.premium)
                            navigate('/dashboard/new-castings')
                          else
                            navigate('/dashboard/premium')
                        }}
                        btnName={t('mycastings:create-collab')}
                      />
                    </div>
                  ) : null}
                </div>
                <br />
              </div>
            </div>
          )
        }
      </main>
    </>
  )
}
MyCastings.propTypes = {
  brandId: PropTypes.string,
}

MyCastings.defaultProps = {
  brandId: undefined,
}

export default observer(MyCastings)
