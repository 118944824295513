/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, ReactElement } from 'react'
import { observer } from 'mobx-react'
import { AnimatePresence, motion } from 'framer-motion'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'
import modal from '../../../../mobx/modal'

const customStyles = {
  content: {
    innerHeight: '400px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    transition: '1s',
    background: 'none',
    border: 'none',
    overflow: 'visible',
    padding: '0',
  },
  overlay: {
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: ' blur(15px)',
  },
}

const ModalDefault: FC = () => {

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={modal.alert.open && modal.alert.type === 'default'}
      ariaHideApp={false}
      onRequestClose={() => {
        modal.toogleAlert(undefined, false)
      }}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
    >
      <motion.main
        initial={{ opacity: 0, y: '-800px' }}
        transition={{ duration: 0.5 }}
        animate={{ opacity: 1, y: 0 }}
        className={`
          ${styles['content-modal-cart']}
          ${modal.alert.type === 'no-favorite-vogzter' && styles['content-modal-cart-nfv']}
        `}
      >
        {modal.alert.child}
      </motion.main>
    </Modal>
  )
}

export default observer(ModalDefault)
