import * as React from 'react'

import PropTypes from 'prop-types'

interface IconProps {
  color?: string
}

const MessageIcon: React.FC<IconProps> = ({ color }) => (
  <svg
    width="25"
    height="23"
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_339_19025)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03497e-05 3.87506C6.03497e-05 1.73488 1.74904 0 3.90642 0H21.0936C23.251 0 25 1.73488 25 3.87506V14.7257C25 16.8657 23.251 18.6007 21.0936 18.6007H9.24682L5.27115 22.7584C5.05126 22.9883 4.71234 23.0622 4.41564 22.9451C4.11875 22.828 3.92371 22.5435 3.92304 22.2266L3.91584 18.6007H3.90636C1.74898 18.6007 0 16.8656 0 14.7256L6.03497e-05 3.87506ZM7.8127 7.75029C6.94982 7.75029 6.25025 8.44411 6.25025 9.30028C6.25025 10.1564 6.94982 10.8503 7.8127 10.8503C8.67574 10.8503 9.37514 10.1564 9.37514 9.30028C9.37514 8.44411 8.67574 7.75029 7.8127 7.75029ZM10.9378 9.30028C10.9378 8.44411 11.6372 7.75029 12.5002 7.75029C13.3632 7.75029 14.0626 8.44411 14.0626 9.30028C14.0626 10.1564 13.3632 10.8503 12.5002 10.8503C11.6372 10.8503 10.9378 10.1564 10.9378 9.30028ZM17.1876 7.75029C16.3246 7.75029 15.6252 8.44411 15.6252 9.30028C15.6252 10.1564 16.3246 10.8503 17.1876 10.8503C18.0507 10.8503 18.7501 10.1564 18.7501 9.30028C18.7501 8.44411 18.0507 7.75029 17.1876 7.75029Z"
        fill={color}
      />
      {/* Custom edit starts here */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03497e-05 3.87506C6.03497e-05 1.73488 1.74904 0 3.90642 0H21.0936C23.251 0 25 1.73488 25 3.87506V14.7257C25 16.8657 23.251 18.6007 21.0936 18.6007H9.24682L5.27115 22.7584C5.05126 22.9883 4.71234 23.0622 4.41564 22.9451C4.11875 22.828 3.92371 22.5435 3.92304 22.2266L3.91584 18.6007H3.90636C1.74898 18.6007 0 16.8656 0 14.7256L6.03497e-05 3.87506ZM7.8127 7.75029C6.94982 7.75029 6.25025 8.44411 6.25025 9.30028C6.25025 10.1564 6.94982 10.8503 7.8127 10.8503C8.67574 10.8503 9.37514 10.1564 9.37514 9.30028C9.37514 8.44411 8.67574 7.75029 7.8127 7.75029ZM10.9378 9.30028C10.9378 8.44411 11.6372 7.75029 12.5002 7.75029C13.3632 7.75029 14.0626 8.44411 14.0626 9.30028C14.0626 10.1564 13.3632 10.8503 12.5002 10.8503C11.6372 10.8503 10.9378 10.1564 10.9378 9.30028ZM17.1876 7.75029C16.3246 7.75029 15.6252 8.44411 15.6252 9.30028C15.6252 10.1564 16.3246 10.8503 17.1876 10.8503C18.0507 10.8503 18.7501 10.1564 18.7501 9.30028C18.7501 8.44411 18.0507 7.75029 17.1876 7.75029Z"
        fill={color}
        stroke={color}
        strokeWidth={0.1}
        shapeRendering="auto"
        opacity={0.1}
      />
      {/* Custom edit ends here */}
    </g>
    <defs>
      <filter
        id="filter0_i_339_19025"
        x="0"
        y="-1"
        width="26"
        height="24"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="-1" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0431373 0 0 0 0 0.113725 0 0 0 0 0.407843 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_339_19025"
        />
      </filter>
    </defs>
  </svg>
)

MessageIcon.propTypes = {
  color: PropTypes.string,
}

MessageIcon.defaultProps = {
  color: '#2A42A7',
}

export default MessageIcon
