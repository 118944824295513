import React, { FC, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from '../components/styles.module.scss'
import ErrorMsg from '../errors/ErrorMsg'

const TextField: FC<Btn1Props> = ({
  required,
  type,
  name,
  label,
  id,
  placeholder,
  onChange,
  error,
  value,
  ...props
}) => {
  
  const [passwordVisible, setPasswordVisible] = useState(false);
  const passwordInput = useRef<HTMLInputElement>()
  const togglePasswordVisibility = () => {
    const input = passwordInput.current
    if (input?.getAttribute('type') === 'password') {
      input.setAttribute('type', 'text');
      setPasswordVisible(true);
    } else {
      input?.setAttribute('type', 'password');
      setPasswordVisible(false);
    }
  }
  return (
    <div
      className={`${styles['text-input-container']} ${
        error ? styles['errored'] : ''
      }`}
    >
      {label && (
        <label htmlFor={name}>
          {label}{' '}
          {required && <span className={styles['text-input-required']}>*</span>}
        </label>
      )}
      <input
        ref={passwordInput as any}
        type={type}
        className={styles['text-input']}
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        {...props}
      />
      {type === 'password' && (
          <i onClick={togglePasswordVisibility} id={id} className={`fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`} />
      )}
      {error && <ErrorMsg text={error} />}
    </div>
  )
}

interface Btn1Props extends React.HTMLProps<HTMLInputElement> {
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  placeholder?: string
  name?: string
  label: string
  id?: string
  error?: string
}

TextField.defaultProps = {
  required: false,
  placeholder: undefined,
  name: undefined,
  error: undefined,
  type: 'text',
  id: undefined,
  value: undefined,
}

TextField.propTypes = {
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
}

export default TextField
