/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'
import styles from './styles.module.scss'
import CustomSlider from '../../../../../../Favorites-vogzters/Profil-vogzters/components/InfoProfil/components/Portfolio/components/CustomSlider'
import { truncate } from '../../../../../../../utils/helpers'


// TODO : Améliorer le typage (interdir onDelete si enableDeletion === false par exemple)
/**
 * Composant générique permettant de sélectionner un ou plusieurs éléments parmis une liste
 * 
 * @param T - Type des données à afficher
 * @param data - Contient une liste pour chaque type de donnée à afficher (permet de prendre en charge des objets de n'importe quel type) ex : {names: ["nom1", "nom2"], descriptions: ["desc1", "desc2"], etc...}
 * @param pickList - Liste des données de type T
 * @param defaultSelection - Liste des index des éléments à sélectionner par défaut
 * @param onElementSelected - Fonction appelée lorsqu'un élément est sélectionné (ou lors de l'apparition du Picker si defaultSelection), envoi en argument l'élément sélectionné
 * @param onElementRemoved - Fonction appelée lorsqu'un élément est désélectionné
 * @param pushHandler - Fonction appelée lorsqu'on clique sur le bouton d'ajout d'élément
 * @param pushBtnTitle - Titre du bouton d'ajout d'élément
 * @param multiSelection - Permet d'activer la séléction multiple
 * @param displayOnly - Liste d'index qui permet de n'afficher que certains éléments de la liste
 * @param disableOnClick - Permet d'empêcher la sélection d'éléments
 * @param enableDeletion - Permet d'activer la suppression d'éléments
 * @param onDelete - Fonction appelée lorsqu'un élément est supprimé
 * @param className - Classe CSS à appliquer au composant
 * 
 * Note : Les variables manipulées peuvent être des objets ou des tableaux selon si multiSelection est activé ou non
 */
function ElementPicker<T>({ 
  data,
  placeholderURL, 
  pickList, 
  defaultSelection, 
  onElementSelected, 
  onElementRemoved, 
  pushHandler, 
  pushBtnTitle, 
  multiSelection, 
  displayOnly,
  disableOnClick,
  enableDeletion,
  onDelete, 
  className }: ElementPickerProps<T>) {

  type SelectionType = {data : Array<T>, lastOperation: "add" | "remove" | "none", removedElement: T | "none"}
  const [selection, setSelection] = useState<SelectionType>({ 
    data: [], 
    lastOperation: "none",
    removedElement: "none",
  })
  const [displayOnlyState, setDisplayOnlyState] = useState({ displayOnly: (displayOnly?.length ?? 0) > 0, list: displayOnly ?? [] })

  useEffect(() => {
    if ((defaultSelection?.length ?? 0) >= 1) {
      defaultSelection = defaultSelection?.filter((v, i) => v !== undefined && v < pickList.length && i < pickList.length) as Array<number>
      defaultSelection.forEach((number) =>  editSelection('add', number ?? 0))
    }
    if (displayOnly && displayOnly.length > 0) {
      setDisplayOnlyState(prev => {
        displayOnly = prev.list.filter((v) => v >= 0 && v < pickList.length) ?? []
        return { displayOnly: displayOnly.length > 0, list: displayOnly }
      })
      onElementSelected = () => {}
      onElementRemoved = () => {}
      pushHandler = () => {}
    }
  }, [])
  
  useEffect(() => {
    if (data.images) {
      data.images = data.images?.map((images) => images?.filter((image) => image !== undefined))
    }
  }, [data.images])

  // Do not remove this useEffect as calling onElementSelected and onElementRemoved inside editSelection() would break the state
  useEffect(() => {
    if (selection.lastOperation === 'none') return
    const currentData = (multiSelection ? selection.data : selection.data.at(0)) ?? []
    if (selection.lastOperation === 'add') {
      onElementSelected(currentData)
    } else if (selection.lastOperation === 'remove') {
      if (selection.removedElement === "none") return
      onElementRemoved(currentData, selection.removedElement, selection.data.length)
    }
  }, [selection])

  const editSelection = (opType: 'add' | 'remove', index: number) => {
    const element = pickList.at(index)
    if (!element) return

    if (opType === 'add') {
      setSelection(prev => {
        const newSelection = {
          data: multiSelection ? [...prev.data as Array<T>, element] : [element],
          lastOperation: opType,
          removedElement: "none" as SelectionType['removedElement'],
        }
        return newSelection
      })
      return
    }
    setSelection(prev => {
      const elementsLeft = (prev.data as Array<T>).filter((e) => e !== element)
      const newSelection = {
        ...prev, 
        data: elementsLeft,
        lastOperation: opType,
        removedElement: element,
      }
      return newSelection
    })
  }

  return (
    <main className={`${styles['page-main']} ${className}`}>
      <section>
        <div className={`${styles['card_products']}`}>
          <div className='flex overflow-x-scroll scroll-container-x'>
            {data?.names?.map((name, i) => {
              if (displayOnlyState.displayOnly && !displayOnlyState.list.includes(i)) return

              const element = pickList.at(i)
              const isSelected = element ? (selection.data as Array<T>).includes(element) : false

              return (
                <article key={i} className={`${styles['card_product']} ${isSelected ? styles['selected'] : ''}`}
                  onClick={() => {
                    if (!disableOnClick) {
                      editSelection(isSelected ? 'remove' : 'add', i)
                    }
                  }}
                >
                  {enableDeletion && (
                    <button
                      className={styles['deleteBtn']}
                      type='button'
                      onClick={() => onDelete && element && onDelete(element)}
                    >
                      <i className='fa-sharp fa-solid fa-xmark' />
                    </button>
                  )}
                  {data.images && (data.images?.at(i)?.length ?? 0) > 0 ? (
                    <CustomSlider
                      data={data.images.at(i) as Array<{ source: string, key: string }>}
                      autoScroll
                      autoScrollDelay={5000}
                      classes={{
                        className: styles['slider'],
                        wrapperClassName: styles['slider-wrapper'],
                        itemWrapperClassName:styles['slider-item-wrapper'],
                        itemClassName: styles['slider-item'],
                        itemWrapperActiveClassName: styles['slider-item-wrapper-active'],
                      }}
                      renderItem={(item, index) => (
                        <img
                          alt={name}
                          src={(data.images?.at(i)?.at(index) as { source: string, key: string }).source}
                        />
                      )}
                      showIcons={false}
                    />
                  ) : (
                    <img
                      alt={name}
                      src={placeholderURL}
                    />
                  )}
                  <div>
                    <h4>{truncate(name, 20)}</h4>

                    {data.addresses ? (
                      <>
                        <h5 className={styles['address-nth-1']}>({data.addresses.at(i)?.replace(
                          /^\d+\s[a-zA-Z]+\s.+,\s(\d{5})\s(.+)$/,
                          "$2 - $1"
                        )})</h5>
                        <span
                          className={styles['address-nth-2']}
                          role='img'
                          aria-label=''
                        >
                          📍{data.addresses.at(i)}
                        </span>
                      </>
                    ) : (
                      <p>
                        {truncate(data.descriptions?.at(i) ?? "", 105)}                        
                      </p>
                    )}
                  </div>
                </article>
              )
            })}
          </div>
          {!displayOnlyState.displayOnly && (
            <article onClick={pushHandler} className={`${styles['card_product']} ${styles['add-product']}`} >
              <span>
                <i className="fa-sharp fa-solid fa-plus" />
              </span>
              <div>
                <h4>{pushBtnTitle}</h4>
              </div>
            </article>
          )}
        </div>
      </section>
    </main >
  )
}

export const DropPhoto = ({ t, onAccept, onRemove, image, key }: DropPhotoI) => {

  return (
    <Dropzone
      accept={{
        'image/*': ['.jpeg', '.png', '.jpg'],
      }}
      noClick
      onDrop={(acceptedFiles) => {
        acceptedFiles[0].size > 500000 ?
          toast.error(t('new-casting:step2.form-product.maxsize-warning'))
          : onAccept(acceptedFiles[0], key)
      }}
    >
      {({
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
      }) => (
        <div
          className={`${styles['div-input-file']} flex-row flex items-center`}
          {...getRootProps()}
        >
          {image ?
            <>
              <img alt='' src={image instanceof File ? URL.createObjectURL(image as File) : (image as { source: string, key: string }).source} className={`${styles['img-file']}`} />
              <i className={`${styles['xmark']} fa-solid fa-xmark`} onClick={() => onRemove(image)} />
            </>
            :
            <label
              className={styles['label-file']}
              htmlFor={`input-image-${key}`}
            >
              <i className="fa-regular fa-camera fa-2xl" />
              <p
                className='mt-2'
                dangerouslySetInnerHTML={{
                  __html: isDragAccept ? t('form-casting:casting.cards-casting-inspirations.placeholder-drop-here') :
                    isDragReject ? t('form-casting:casting.cards-casting-inspirations.placeholder-wrong-file') : t('new-casting:step2.form-product.illustration_placeholder')
                }}
              />
              <input
                className="mt-2"
                data-input-media
                id={`input-image-${key}`}
                {...getInputProps()}
              />
            </label>
          }
        </div>
      )
      }
    </Dropzone >)
}

DropPhoto.defaultProps = {
  image: undefined,
}

type DropPhotoI = {
  t: TFunction<"translation", undefined>,
  onAccept: (acceptedFile: File, componentKey: number) => void,
  key: number,
  image?: DropPhotoImg,
  onRemove: (img: DropPhotoImg) => void
}

export type DropPhotoImg = File | {
  source: string;
  key: string;
}

export interface ElementPickerProps<T> {
  data: PickerElements
  placeholderURL: string
  pickList: Array<T>
  defaultSelection?: Array<number | undefined>
  onElementSelected: (element: T | Array<T>) => void
  onElementRemoved: (element: T | Array<T>, deleted: T, selectedCount: number) => void
  pushHandler: () => void
  pushBtnTitle?: string
  multiSelection?: boolean
  displayOnly?: Array<number>
  disableOnClick?: boolean
  enableDeletion?: boolean
  onDelete?: (element: T) => void
  className?: string
}

ElementPicker.defaultProps = {
  defaultSelection: undefined,
  className: '',
  pushBtnTitle: 'Nouvel élément',
  multiSelection: false,
  displayOnly: [],
  disableOnClick: false,
  enableDeletion: false,
  onDelete: () => { },
}

ElementPicker.propTypes = {
  data: PropTypes.any.isRequired,
  placeholderURL: PropTypes.string.isRequired,
  pickList: PropTypes.any.isRequired,
  defaultSelection: PropTypes.any,
  onElementSelected: PropTypes.func.isRequired,
  onElementRemoved: PropTypes.func.isRequired,
  pushHandler: PropTypes.func.isRequired,
  pushBtnTitle: PropTypes.string,
  multiSelection: PropTypes.bool,
  displayOnly: PropTypes.array,
  disableOnClick: PropTypes.bool,
  enableDeletion: PropTypes.bool,
  onDelete: PropTypes.func,
  className: PropTypes.string,
}

type PickerElements = {
  names?: string[]
  images?: Array<DropPhotoImg | undefined>[]
  descriptions?: string[]
  addresses?: string[]
}

export default observer(ElementPicker)
