import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import * as Yup from 'yup'

import { FormikProvider, useFormik } from 'formik'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../../../utils/components/Headings/Title1'
import styles from './components/steps_casting.module.scss'
import {
  pageVariantsAnimation,
  stepVariantsAnimation,
} from '../../../../../utils/constants'
import userMobx from '../../../../../mobx/user'
import TextField from '../../../../../utils/components/Ui/inputs/TextField'
import { BrandI, ClientI } from '../../../../../types-project/Client'
import DropdownCheckbox, { DropdownCheckboxOption } from '../../../../../utils/components/Ui/dropdown/DropdownCheckbox'
import { BriefParams } from '../../../../../types-project/Brief'
import briefR from '../../../../../mobx/brief'

function Step3({ nextBtnRef, setStep }: Step3Props,) {
  const [paramBrief, setParamBrief] = useState<BriefParams>({
    content_usage: undefined,
    property_rights: undefined
  })
  // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  const navigate = useNavigate()
  // in case the new URL field is empty, we get the old one from before the model change of the object brand 
  let brand: BrandI & { url?: string }
  // if the user is an agency, we get the brand from the session storage
  if (userInfos._company.type === 'agency') {
    brand = JSON.parse(sessionStorage.getItem('brand') ?? '{}')
  } else {
    // if the user is a brand, we keep the brand we got from the local storage
    brand = userInfos.brand ?? {}
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { type_casting } = useParams<{
    type_casting: 'creator' | 'exclusive' | 'private'
  }>()
  useEffect(() => {
    try {
      /**
      * @param paramsBrief is propriety brief of content_usage and property_rights
      */
      const params: BriefParams = JSON.parse(sessionStorage.getItem('paramsBrief') ?? "{}")
      if (Object.keys(params).length >  0)
        setParamBrief(params)
      if (nextBtnRef.current) {
        nextBtnRef.current.onclick = () => {
          if (type_casting === 'private') {            
            setStep(4)
          } else {
            briefR.createBrief(type_casting!, (data) => {
              navigate(`/dashboard/castings/${data._id}/edit`)})
          }
        }
        nextBtnRef.current.disabled = false
        if (paramBrief.content_usage !== undefined &&  paramBrief.property_rights !== undefined) {
          nextBtnRef.current.disabled = false
        } else {
          nextBtnRef.current.disabled = true
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return () => {
      if (nextBtnRef.current) {
        nextBtnRef.current.disabled = false
      }
    }
  }, [nextBtnRef])


  // translation
  const { t } = useTranslation()
  const propertyRights = [
    { value:6 , txt: `6 ${t('new-casting:step3.month')}`},
    { value:12 , txt: `1 ${t('new-casting:step3.year')}`},
    { value:36 , txt: `3 ${t('new-casting:step3.years')}`},
    { value:60 , txt: `5 ${t('new-casting:step3.years')}`},
  ]  
  return (
    <motion.main
      initial="initial"
      animate="in"
      exit="out"
      variants={stepVariantsAnimation}
      className={styles['page-main']}
    >
      <section className={styles['section-wrapper']}>
        <div className={styles['step-content']}>
          <div className={styles['content-usage']}>
            <h1>
              {t('new-casting:step3.title')}
            </h1>
            <div className={styles['select']}>
              <div className={`${styles['usage']} ${paramBrief.content_usage === 'organic'? styles['selected']:''}`}>
                <div className={styles['img']}>
                  <img src="/assets/imgs/organic-pic.png" alt="organic" />
                </div>
                <b>{t('new-casting:step3.organic')}</b>
                <p>{t('new-casting:step3.organic-desc')}</p>
                <BtnBasic typeBtn='button' type='sky-violet-bordered' 
                  onclick={()=>{
                    setParamBrief({...paramBrief,content_usage:'organic'})
                    if (paramBrief.property_rights !== undefined){
                      sessionStorage.setItem('paramsBrief',JSON.stringify({...paramBrief,content_usage:'organic'}))
                      if (nextBtnRef.current) {
                        nextBtnRef.current.onclick = () => {
                          if (type_casting === 'private') {
                            setStep(4)
                          } else {
                            briefR.createBrief(type_casting!, (data) => {
                              navigate(`/dashboard/castings/${data._id}/edit`)})
                          }
                        }
                        nextBtnRef.current.disabled = false
                      }
                    }
                  }} 
                btnName={t('new-casting:step3.select')} />
              </div>
              <div className={`${styles['usage']} ${paramBrief.content_usage === 'ad'? styles['selected']:''}`}>
                <div className={styles['img']}>
                  <img src="/assets/imgs/ads-pic.png" alt="ads" />
                </div>
                <b>{t('new-casting:step3.ad')}</b>
                <p>{t('new-casting:step3.ad-desc')}</p>
                <BtnBasic typeBtn='button' type='sky-violet-bordered' btnName={t('new-casting:step3.select')}
                 onclick={()=>{
                  setParamBrief({...paramBrief,content_usage:'ad'})
                  if (paramBrief.property_rights !== undefined){
                    sessionStorage.setItem('paramsBrief',JSON.stringify({...paramBrief,content_usage:'ad'}))
                    if (nextBtnRef.current) {
                      nextBtnRef.current.onclick = () => {
                        if (type_casting === 'private') {
                          setStep(4)
                        } else {
                          briefR.createBrief(type_casting!, (data) => {
                            navigate(`/dashboard/castings/${data._id}/edit`)})
                        }
                      }
                      nextBtnRef.current.disabled = false
                    }
                  }
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles['content-right']}>
            <h1>
              {t('new-casting:step3.title')}
            </h1>
            <div className={styles['right']}>
              {propertyRights.map((v,k)=>
                <BtnBasic className={`${paramBrief.property_rights === v.value? styles['isSelect']: ''}`} key={k} typeBtn='button' btnName={v.txt} type='sky-violet-bordered' 
                onclick={()=>{
                  setParamBrief({...paramBrief,property_rights:v.value})
                  if (paramBrief.content_usage !== undefined){
                    sessionStorage.setItem('paramsBrief',JSON.stringify({...paramBrief,property_rights:v.value}))
                    if (nextBtnRef.current) {
                      nextBtnRef.current.onclick = () => {
                        if (type_casting === 'private') {
                          setStep(4)
                        } else {
                          briefR.createBrief(type_casting!, (data) => {
                            navigate(`/dashboard/castings/${data._id}/edit`)})
                        }
                      }
                      nextBtnRef.current.disabled = false
                    }
                  }
                }}
                />
              )
              }
            </div>
          </div>
        </div>
      </section>
    </motion.main >
  )
}
export interface Step3Props {
  nextBtnRef: React.RefObject<HTMLButtonElement>,
  setStep: (step: number) => void,
}

Step3.prototype = {
  nextBtnRef: PropTypes.any,
}

export default observer(Step3)