import React, { FC } from "react";
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import styles from "./components/styles.module.scss"
import { Social, SocialLinks } from "../../../../../types-project/Brief";

const VogzterOffer: FC<VOProps> = ({ ...VOProps }) => {

    const { t } = useTranslation()
    
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0, y: -100 }}
                className={styles['offer-card']}
                onClick={VOProps.onClick}
            >
                <div className={styles['offer-header']}>
                    <h2>{VOProps.title}</h2>
                    <p>{VOProps.price}€<span>{VOProps.type === 'ugc' && t('vogzters:offers:content')}</span></p>
                </div>
                <div className={styles['offer-body']}>
                    <p>{VOProps.description}</p>
                    {VOProps.rights.not_allow !== undefined && VOProps.rights.not_allow === false && VOProps.rights.duration && (
                        <p className={styles['offer-duration']}>
                            {t('vogzters:offers:duration')}{' '}<span>{VOProps.rights.duration}</span>
                        </p>
                    )}
                    {VOProps.rights.not_allow !== undefined && VOProps.rights.not_allow === false && VOProps.contentUsage && (
                        <p className={styles['offer-duration']}>
                        {t('vogzters:offers:content-usage')}{' '}<span>{VOProps.contentUsage}</span>
                    </p>
                    )}
                </div>
                <div className={styles['offer-social']}>
                    {VOProps.rights.not_allow !== undefined && VOProps.rights.not_allow === false  && VOProps.rights.duration && (
                        <>
                            <span className={styles['camera']} role="img" aria-label="Camera"> 🎥 </span>
                        </>
                    )}
                    {VOProps.socials.map((social, index) => (
                        <React.Fragment key={index}>
                            {(index !== 0 || !VOProps.rights.not_allow) && <span>+</span>}
                            <img src={social.img} alt={social.name} />
                        </React.Fragment>
                    ))}
                </div>
            </motion.div>
        </AnimatePresence>
    )
}

type VOProps = {
    type: string
    title: string
    description: string
    price: number
    contentUsage: string
    rights: {
        not_allow: Boolean
        duration: string
    }
    socials: Social[],
    onClick: () => void
}

export default VogzterOffer