import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import * as Yup from 'yup'

import { Field, Formik, FormikErrors, FormikProvider, useFormik } from 'formik'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../../../utils/components/Headings/Title1'
import styles from './components/steps_casting.module.scss'
import {
  pageVariantsAnimation,
  stepVariantsAnimation,
} from '../../../../../utils/constants'
import userMobx from '../../../../../mobx/user'
import TextField from '../../../../../utils/components/Ui/inputs/TextField'
import { BrandI, Category, ClientI } from '../../../../../types-project/Client'
import DropdownCheckbox, { DropdownCheckboxOption } from '../../../../../utils/components/Ui/dropdown/DropdownCheckbox'

function Step1({ nextBtnRef, setStep }: Step1Props,) {

  const addSocialInput = () => {
    if ((formik.values.info?.social_links?.length ?? 0) < 5) {
      formik.setValues({ ...formik.values, info: { ...formik.values.info, social_links: [...formik.values.info?.social_links ?? [], { url: '' }] }})
    }
  }
  // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  // in case the new URL field is empty, we get the old one from before the model change of the object brand 
  let brand: BrandI & { url?: string }
  // if the user is an agency, we get the brand from the session storage
  if (userInfos._company.type === 'agency') {
    brand = userMobx.getBrandSession() ?? {}
  } else {
    // if the user is a brand, we keep the brand we got from the local storage
    brand = userInfos.brand ?? {}
  }
  const categoryRef = useRef<DropdownCheckbox>(null)
  // verification of the social media inputs with Yup
  const validationSchema = Yup.object().shape({
    info: Yup.object().shape({
      save_info: Yup.boolean(),
      text: Yup.string(),
      url: Yup.string().url('URL non valide pensez à rajouter https://'),
      social_links: Yup.array().of(
        Yup.object().shape({
          url: Yup.string().url('URL non valide pensez à rajouter https://'),
        })
      )
    }),
    categories: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
      })
    )
  })
  // initial values for the form
  // if the brand has already saved socials, we keep them, otherwise we keep empty values 
  const initialValues = {
    info: {
      save_info: true,
      text: brand.info?.save_info ? brand.info?.text : '',
      // si la marque a déjà sauvegardé des réseaux sociaux, on cherche dans brand.info.url, si celui-ci est undefined on prend brand.url, sinon on garde des valeurs vides
      url: brand.info?.save_info && brand.info?.url !== undefined ? brand.info?.url
        : brand.info?.save_info && brand.info?.url === undefined ? brand.url
          : '',
      social_links: brand.info?.save_info && brand.info?.social_links?.map((link) => ({ url: link.url || '' })) || [
        { url: '', text: 'instagram' }, { url: '', text: 'tiktok' }
      ]
    },
    categories: brand.categories
  }
  // formik hook to handle the form state and validation with Yup schema and the initial values above
  const formik = useFormik<BrandI>({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const errors = await formik.validateForm(values)
      if (!(Object.keys(values).length === 0 && Object.keys(errors).length === 0)) {
        const brnd = { ...brand, ...values }
        if (brand._id)
          await userMobx.updateBrand(brand._id, brnd, () => {
            setStep(2)
          })
      } else {
        if (nextBtnRef.current) nextBtnRef.current.disabled = false
      }
    }
  })

  useEffect(() => {
    userMobx.getCategories(() => {
      brand.categories && (
        brand.categories.forEach((category) => {
          categoryRef.current?.select(category._id)
        })
      )}
    )
    return () => {
      if (nextBtnRef.current) {
        nextBtnRef.current.disabled = false
      }
    }
  }, [])

  useEffect(() => {
    if (nextBtnRef.current) {
      nextBtnRef.current.disabled = false
      // submit the form when the next button is clicked and disable the button
      nextBtnRef.current.onclick = async () => {
        const f = await formik.submitForm()
      }
    }
  }, [nextBtnRef])

  let categoryOptions: DropdownCheckboxOption[] = []
  if (userMobx.categories.data) {
    categoryOptions = userMobx.categories.data.map((category: Category) => ({
      id: category._id,
      label: category.name,
      data: category as Category,
    }))
  }


  // translation
  const { t } = useTranslation()
  return (
    <motion.main
      initial="initial"
      animate="in"
      exit="out"
      variants={stepVariantsAnimation}
      className={styles['page-main']}
    >
      <section className={styles['section-wrapper']}>
        <FormikProvider value={formik}>
          <form className={styles['step-content']} onSubmit={formik.handleSubmit}>
            <div className={styles['intro-brand']}>
              <h1>{t('new-casting:step1.intro-brand')}</h1>
              <p>
                {t('new-casting:step1.intro-brand-desc')}
              </p>
              <div className={styles['category']}>
                <h3>{t('myprofil:brand-page.choose-category')}</h3>
                <DropdownCheckbox<Category>
                  ref={categoryRef}
                  isSearchable
                  multiSelection
                  placeholder={t('myprofil:brand-page.category')}
                  searchPlaceholder={t('myprofil:brand-page.search-category')}
                  options={categoryOptions}
                  onElementSelected={(element, allSelection) => {
                    formik.setFieldValue('categories', allSelection.flatMap(element => 
                      JSON.parse(JSON.stringify(element.data) ?? '{}')
                      ))
                  }}
                  onElementRemoved={(element, allSelection) => {
                    formik.setFieldValue('categories', allSelection.flatMap(element =>
                      JSON.parse(JSON.stringify(element.data) ?? '{}')
                    ))
                  }}
                />
              </div>
              <textarea
                name="info.text"
                id="infoText"
                placeholder="Décrivez votre marque"
                cols={30}
                rows={0}
                onChange={formik.handleChange}
                value={formik.values.info?.text} />
              <p className={styles['good-to-know']} dangerouslySetInnerHTML={{ __html: t('new-casting:step1.good-to-know') }} />
            </div>
            <div className={styles['socials-brand']}>
              <h1>
                {t('new-casting:step1.brand-socials')}
              </h1>
              <div className={styles['social-input']}>
                {/* the error attribute in textfield allows to display the error mentionned above in the yup verification in case it doesn't check out */}
                <TextField error={((formik.errors.info as BrandI['info'])?.social_links?.at(0) as FormikErrors<{ url: string; }>)?.url} type="text" name='info.social_links[0].url' onBlur={formik.handleBlur}
                  onChange={formik.handleChange} placeholder="https://instagram.com/" label='Instagram' value={(formik.values.info as BrandI['info'])?.social_links![0]?.url} />
              </div>
              <div className={styles['social-input']}>
                <TextField error={((formik.errors.info as BrandI['info'])?.social_links?.at(1) as FormikErrors<{ url: string; }>)?.url} type="text" name='info.social_links[1].url' onBlur={formik.handleBlur}
                  onChange={formik.handleChange} placeholder="https://Tiktok.com/" label='TikTok' value={(formik.values.info as BrandI['info'])?.social_links![1]?.url} />
              </div>
              {(formik.values.info?.social_links ?? []).filter((i, k) => k > 1).map((i, k) => (
                <div className={styles['social-input']} key={k}>
                  <TextField error={((formik.errors.info as BrandI['info'])?.social_links?.at(k) as FormikErrors<{ url: string; }>)?.url} type="text" name={`info.social_links[${k + 2}].url`} onBlur={formik.handleBlur}
                    onChange={formik.handleChange} placeholder="https://exemple.com/" label='Réseau' value={i.url} />
                </div>
              ))}
              <button
                data-btn-add
                className="self-center mt-5"
                type="button"
                onClick={addSocialInput}
              >
                <i className="fa-light fa-circle-plus" />
                Ajouter un autre réseau
              </button>
              <hr />
              <div className={`${styles['social-input']} ${styles['site']}`}>
                <TextField error={(formik.errors.info as BrandI['info'])?.url} label={t('new-casting:step1.website')} type="text" name='info.url' onBlur={formik.handleBlur}
                  onChange={formik.handleChange} placeholder="https://www.website.com/" value={(formik.values.info as BrandI['info'])?.url} />
              </div>
              <div className={styles['check-to-save']}>
                <input name='save_socials' type="checkbox" onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.setFieldValue("info.save_info", event.target.checked);
                  }} checked={(formik.values.info as BrandI['info'])?.save_info} />
                <span>
                  {t('new-casting:step1.save-info')}
                </span>
              </div>
            </div>
          </form>
        </FormikProvider>
      </section>
    </motion.main >
  )
}
export interface Step1Props {
  nextBtnRef: React.RefObject<HTMLButtonElement>,
  setStep: (step: number) => void,
}

Step1.prototype = {
  nextBtnRef: PropTypes.any,
}

export default observer(Step1)