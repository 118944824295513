import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import React, { createRef, useEffect, useState } from 'react'
import { BrandI, ClientI } from '../../../../types-project/Client'

import styles from './casting-settings.module.scss'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import BtnBasic from '../../../../utils/components/Button/BtnBasic'
import Step4 from './Steps/Step4'
import userMobx from '../../../../mobx/user'
import utilsMobx from '../../../../mobx/utils'
import Step3 from './Steps/Step3'
// TODO: Fix scroll second aside
function SettingCasting({ client }: { client: ClientI }) {
  // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  // in case the new URL field is empty, we get the old one from before the model change of the object brand 
  let brand: BrandI & { url?: string };
  // if the user is an agency, we get the brand from the session storage
  if (userInfos._company.type === 'agency') {
    brand = userMobx.getBrandSession() ?? {}
  } else {
    // if the user is a brand, we keep the brand we got from the local storage
    brand = userInfos.brand!;
  }
  // if brand.info url exists or if social links' length is greater than 0 or if brand.info text exists, it means the client already provided the info
  // so we set the default step to 2 otherwise we set it to 1
  const [step, setStep] = useState<StateStep | number>(
    (brand?.info?.url) ||
      (brand?.info?.social_links && brand.info.social_links.length > 0) ||
      (brand?.info?.text)
      ? 2
      : 1
  );
  useEffect(() => {
    userMobx.getMe()
  }, [])

  // translation
  const { t } = useTranslation()

  const navigate = useNavigate()
  const btnRef = createRef<HTMLButtonElement>()
  return (
    <app-main
      className={`${styles['page-setting']}`}
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', height: utilsMobx.getWidth > 765 ? '100%' : 'auto'}} 
    >
      {step > 1 &&
        <button
          className={`btn-txt m-4 border-0 bg-transparent`}
          type="button"
          onClick={() => {
            if (step > 1 && step <= 4) {
              setStep(step - 1)
            } else {
              navigate(-1)
            }
          }}
        >
          <i className="fa-regular fa-angle-left" /> {t('mycastings:return')}
        </button>}
      <AnimatePresence exitBeforeEnter>
        <>
          {step === 1 ? (
            <Step1 nextBtnRef={btnRef} setStep={setStep} />
          ) : step === 2 ? (
            <Step2 nextBtnRef={btnRef} setStep={setStep} />
          )
          : step === 3 ? (
            <Step3 nextBtnRef={btnRef} setStep={setStep} />
          )
           : <Step4 nextBtnRef={btnRef} setStep={setStep} brand={client.brand} />}
        </>
      </AnimatePresence>

      {( step !== 2 &&
        <section className={styles['section-btm']}>
          <BtnBasic
            ref={btnRef}
            type="inverted-sky-violet"
            typeBtn='button'
            btnName={step === 1 ? t('new-casting:start') : t('new-casting:next')}
          />
        </section>
      )}
    </app-main>
  )
}
type StateStep = 1 | 2 | 3 
export default SettingCasting
