/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { FC, useState } from 'react'
// import { motion } from "framer-motion";
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from './components/card-sendproduct.module.scss'
import { MyCasting } from '../../../../../types-project/Brief'

import briefR from '../../../../../mobx/brief'
import { displayAvatar, getAvatar } from '../../../../../utils/helpers'

const CardSendProduct: FC<MyCasting> = ({ _id, applicant, product_sent }) => {
  const [formAdress, setformAdress] = useState<{
    address: undefined | string
    error: null | string
  }>({ address: product_sent?.tracking_link, error: null })
  // translation
  const { t } = useTranslation()
  const checkFormSend = () => {
    if (formAdress.address == null || formAdress.address.length === 0) {
      setformAdress({ ...formAdress, error: t('mycastings:required-fields') })
    } else {
      setformAdress({ ...formAdress, error: '' })
      briefR.productSent(_id, formAdress.address)
    }
  }

  const navigate = useNavigate()
  const productSent = product_sent?.sent

  return (
    <article className={styles['card-send-product']}>
      <aside>
        {displayAvatar(
          applicant?.profile.gender,
          applicant?.profile?.picture?.source,
          `briefapplication/${_id}`
        )}
        <br />
        <div className='gap-3 flex flex-col'>
          <div className={`${styles['card-info-solo']} text-center`}>
            <h5 className="!text-white">📞 {t('mycastings:phone')}</h5>
            <p><a href={`tel:${applicant?.phone}`}>{applicant?.phone}</a></p>
          </div>
          <div className={`${styles['card-info-solo']} text-center`}>
            <h5 className="!text-white">📧 {t('mycastings:email')}</h5>
            <p><a href={`mailto:${applicant?.email}`}>{applicant?.email}</a></p>
          </div>
        </div>
      </aside>

      <aside>
        {productSent && (
          <span className={styles['sent-tag']}>
            {t('mycastings:product-shipped')}{' '}
            <i className="fa-solid fa-circle-check" />
          </span>
        )}
        <div className="gap-3 flex flex-col">
          <h4>
            {applicant?.first_name} {applicant?.last_name}
          </h4>
          <div>
            <div className={styles['card-info-solo']}>
              <h5>{t('mycastings:address')}</h5>
              <p>{applicant?.delivery_address}</p>
            </div>
            {/* {applicant?.delivery_address_2 && <div className={styles["card-info-solo"]}>
                            <h5>Complément d’adresse</h5>
                            <p>{applicant?.delivery_address_2}</p>
                        </div>} */}

            {/* <div className={styles['card-info-solo']}>
              <h5>sd</h5>
              <p>sdf</p>
            </div>

            <div className={styles['card-info-solo']}>
              <h5>sdf</h5>
              <p>sd</p>
            </div> */}
          </div>
          {/* 
                    <div className={styles["card-info-solo"]}>
                        <h5>Complément d’adresse</h5>
                        <p>{"undefined"}</p>
                    </div> */}
          <div className={styles['card-info-solo']}>
            <h5>{t('mycastings:additional-address')}</h5>
            <p>{applicant?.delivery_address_2 ?? 'Non défini'}</p>
          </div>

          <div>
            <h5>{t('mycastings:tracking-link')}</h5>
            <div className={styles['card-input-follow']}>
              <input
                disabled={productSent}
                value={formAdress.address!}
                onChange={(val) =>
                  setformAdress({ ...formAdress, address: val.target.value })
                }
                placeholder={t('mycastings:tracking-url')}
              />
              {formAdress.error && (
                <span className="error">{formAdress.error}</span>
              )}
            </div>
          </div>
          <button
            type="button"
            disabled={productSent}
            onClick={() => checkFormSend()}
            className={`btn ${
              productSent ? 'disabled border-solid border border-gray-600' : ''
            }`}
          >
            {productSent
              ? t('mycastings:already-shipped')
              : t('mycastings:product-shipped')}
          </button>
        </div>
      </aside>
    </article>
  )
}

export default observer(CardSendProduct)
