import React, { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from 'react-loading'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import userMobx from '../../mobx/user'
import Booking from '../Auth/RegisterProcess/components/Booking'
import PlansLists from '../Auth/RegisterProcess/components/PlansList'
import styles from './components/styles.module.scss'
import Btn1 from '../../utils/components/Button/Btn1'

interface CreditVogzProps {
  paymentRedirect?: string
}
const CreditVogz: FC<CreditVogzProps> = ({ paymentRedirect }) => {
  const { t } = useTranslation()
  const [swiperIndex, setSwiperIndex] = useState(2)
  const [openBooking, setOpenBooking] = useState(false)
  const { loading, error, data } = userMobx.me
  const navigate = useNavigate()
  const displayNavBubble = (nbSlide: number) => {
    return Array.from({ length: nbSlide }).map((value, index) => {
      return (
        <i
          className={`${styles['swiper-navigation-bubble']} ${
            swiperIndex === index
              ? styles['swiper-navigation-bubble__active']
              : ''
          }`}
        />
      )
    })
  }

  return (
    <main
      className={`${styles['main-credit']} flex justify-center items-center h-full w-full relative`}
    >
      <section
        className={`max-w-screen-xl items-center  ${styles['section-credit']}`}
      >
        {!loading && !error ? (
          <>
            <div className={styles['header']}>
              <div className={styles['title-head']}>
                <h3>{t('common:credit-page.title')}</h3>
                <p>{t('common:credit-page.desc')}</p>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <PlansLists
              setOpenBooking={setOpenBooking}
              displayNavBubble={displayNavBubble}
              setSwiperIndex={setSwiperIndex}
              displayHeader={false}
              paymentRedirect={paymentRedirect!}
            />
            {openBooking ? <Booking setOpenBooking={setOpenBooking} /> : null}
          </>
        ) : (
          <Loading />
        )}
      </section>
    </main>
  )
}
CreditVogz.propTypes = {
  paymentRedirect: PropTypes.string,
}
CreditVogz.defaultProps = {
  paymentRedirect: '/dashboard/credits-vogz',
}
export default CreditVogz
