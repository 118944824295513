import React, { useEffect, useRef, useState } from 'react'
import { Spinner } from 'grommet'
import { observer } from 'mobx-react'
import ReactTooltip from 'react-tooltip'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { last } from 'lodash'
import { isMobileSafari, isIOS, isChrome } from 'react-device-detect'
import { Rating } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReviewRatings from './components/ReviewRatings'
import UserReviews from './components/UserReviews'

import { Message } from "../../Messaging/components/Bubble/components/message";
import styles from './components/styles.module.scss'
import briefR from '../../../mobx/brief'
import { variantPage } from '../../../utils/helpers'
import {
  Brief,
  MyCasting,
  Offer,
} from '../../../types-project/Brief'
import CardVogz from '../../Castings/MyCasting/components/CardVogz'
import VogzterOffer from './components/VogzterOffer'
import { BrandI, ClientI, RatingType, TypeClient } from '../../../types-project/Client'
import InfoProfil from './components/InfoProfil'
import ChatContainer from '../../Messaging/components/ChatContainer'
import userMobx from '../../../mobx/user'
import BrandSwitcher from '../../../utils/components/BrandSwitcher/BrandSwitcher'
import CardBriefApplication from '../../Castings/MyCasting/components/CardBriefApplication'
import SubTitle1 from '../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../utils/components/Headings/Title1'
import Btn1 from '../../../utils/components/Button/Btn1'
import utilsMobx from '../../../mobx/utils'
import Favourite from '../../../utils/components/Favourite/favourite'
import BtnBasic from '../../../utils/components/Button/BtnBasic'

function ProfileVogzter() {

  const params = new URLSearchParams(window.location.search)
  const fromEmail = params.get('fromEmail') ?? 'false'
  const userInfos = userMobx.getUserInfos() as ClientI
  const brand = userMobx.getBrandSession() ?? {}
  const isGuest = userInfos === null
  const vogzterId = JSON.parse(sessionStorage.getItem('vogzter') ?? '{}')

  const brandId = !isGuest ? userInfos._company.type === TypeClient.BRAND ? userInfos.brand?._id : brand._id : null
  // show messa
  const [showMessages, setShowMessages] = useState<boolean>(false)

  const refStreamerContainer = useRef<HTMLDivElement>(null)
  const refStreamerLine = useRef<HTMLSpanElement>(null)
  const refMenu = useRef<HTMLDivElement>(null)

  // params got from react router dom
  const { idba: id } = useParams<{ idba: string }>()

  // handle navigation from our router
  const navigate = useNavigate()

  const [mediaFilter, setMediaFilter] = useState<'portfolio' | 'see-all' | 'sold-videos' | 'none'>('portfolio')
  const [displayMenu, setDisplayMenu] = useState<boolean>(false)
  const [visibleItems, setVisibleItems] = useState(2)
  const [showRate, setShowRate] = useState<boolean>(false)
  const [showReviews, setShowReviews] = useState<boolean>(false)

  const handleMediaFilters = () => {
    const indexChild = mediaFilter === 'portfolio' ? 0 : isGuest ? 1 : mediaFilter === 'see-all' ? 1 : 2

    const refSelection = refStreamerContainer.current?.children[indexChild] as HTMLButtonElement

    if (refSelection && refStreamerLine.current) {
      refStreamerLine.current.style.width = `${refSelection.offsetWidth}px`
      refStreamerLine.current.style.left = isGuest ? ['0%', '40%'][indexChild] : ['0%', '20.5%', '75%'][indexChild]
    }
  }

  const reviewValidationSchema = Yup.object().shape({
    quality: Yup.number().min(0).max(5).required(),
    punctuality: Yup.number().min(0).max(5).required(),
    communication: Yup.number().min(0).max(5).required(),
    comment: Yup.string().optional()
  })

  const initialRating = userMobx.reviews.data?.filter(r =>
    r.brand._id === brandId && r.user._id === id && r.op === 'brand'
  )[0]

  const formik = useFormik({
    initialValues: {
      quality: initialRating?.rating.quality ?? 0,
      punctuality: initialRating?.rating.punctuality ?? 0,
      communication: initialRating?.rating.communication ?? 0,
      comment: initialRating?.comment ?? ''
    },
    validationSchema: reviewValidationSchema,
    onSubmit: (values) => {
      const rating: RatingType = {
        quality: values.quality,
        punctuality: values.punctuality,
        communication: values.communication
      }
      userMobx.addReview(id!, rating, values.comment)
      setShowRate(false)
    },
    enableReinitialize: true
  })

  useEffect(() => {
    if (!isGuest) {
      if (Object.keys(vogzterId).length > 0) {
        if (brand.favourites?.includes(!vogzterId))
          userMobx.addFavouriteVogzters(vogzterId)
      }
      userMobx.getUserMessages(id!)
      sessionStorage.removeItem('vogzter')
    }
    briefR.getOneFavourite(id!)
    userMobx.getVogzter(id!)
    const pageAside = document.getElementById('page-aside')
    if (pageAside) {
      pageAside.style.overflowY = 'hidden'
    }
    userMobx.getOffersByVogzter(id!)
    userMobx.getReviews(id!)
    return () => {
      // block scroll when messages is open
      if (pageAside) {
        pageAside.style.overflowY = 'scroll'
      }
    }
  }, [])

  useEffect(() => {
    handleMediaFilters()
  }, [mediaFilter, refStreamerContainer.current?.children])
  useEffect(() => {
    if (refMenu.current) {
      refMenu.current.style.top = displayMenu ? '70px' : '-300%'
    }
    if (utilsMobx.getWidth > 1200 && !showMessages) {
      if (!isGuest) {
        userMobx.getUserMessages(id!)
        userMobx.readMsgBrand(brandId ?? '', id!, true, () => setShowMessages(true))
      } else {
        setShowMessages(true)
      }
    }
  }, [displayMenu, showMessages, utilsMobx.getWidth])

  // translation
  const { t } = useTranslation()
  const ba = briefR.detailFav.data

  const baUploadedMedia = userMobx.vogzter.data?.userMedias?.filter(md => md.brief !== null).filter((m) => {
    return ['waiting-for-upload', 'deleted', 'errored'].indexOf(m.status) === -1 && !m.offered &&
      (mediaFilter === 'sold-videos' ? m.status === 'chosen' : mediaFilter === 'see-all' ? (['chosen', 'validated'].indexOf(m.status) > -1 && (((m.brief as Brief).brand as BrandI)?._id) === brandId) : true)
  }) ?? []

  useEffect(() => {
    if (fromEmail === 'true' && !isGuest && baUploadedMedia.filter((m) =>
      m.status === 'chosen' &&
      ((m.brief as Brief).brand as BrandI)?._id === userMobx.getBrandSession()?._id
    ).length > 0) {
      setShowRate(true)
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  }, [fromEmail, baUploadedMedia])

  const hasUploadedMedia = baUploadedMedia.length > 0
  const hasSoldVideos = baUploadedMedia.filter((m) => m.status === 'chosen').length > 0


  const hasVideoDemo = (ba?.applicant?.user?.profile?.video_demo?.filter((vd) => vd.status === 'uploaded').length ?? 0) > 0
  const hasVideoIntro = ba?.applicant?.user?.profile?.video_intro?.status === 'uploaded'
  const hasPortfolio = hasVideoDemo || hasVideoIntro
  const VOGZ_SITE = process.env.REACT_APP_VOGZ_SITE_URL

  const formatDuration = (months: number) => {
    const years = Math.floor(months / 12)
    const remainingMonths = months % 12
    const andMonths = remainingMonths > 0 ? `\u00A0${t('vogzters:offers.and')}\u00A0${remainingMonths}\u00A0${t('vogzters:offers.month')}` : ''

    return years > 0 ? `${years}\u00A0${t('vogzters:offers.year')}${andMonths}` : `${months}\u00A0${t('vogzters:offers.month')}`
  }


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Profil Vogzters</title>
      </Helmet>
      <main className={`flex flex-row h-full relative ${styles['main-profil-page']} ${userInfos === null ? styles['not-connected'] : ''}`}>
        {
          (showRate && !isGuest) && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
              className={styles['rate-container']}>
              <div className={styles["modal"]}>
                <div className={styles["rate-header"]}>
                  <i className="fa-solid fa-times" onClick={() => setShowRate(false)} />
                  <span role='img' aria-label='review'>✍️</span>
                </div>
                <div className={styles["rate-content"]}>
                  <h2>{t('vogzters:reviews.modal-title', { name: ba?.applicant.user.first_name })}</h2>
                  <p>{t('vogzters:reviews.modal-desc')}</p>
                </div>
                <div className={styles["rating-stars"]}>
                  <div className={styles['ratings']}>
                    <div className={styles["quality"]}>
                      <div className={styles["quality__title"]}>
                        <h3>{t('vogzters:reviews:quality')}</h3>
                      </div>
                      <div className={styles["rating"]}>
                        <Rating
                          name="quality-rating"
                          value={
                            formik.values.quality
                          }
                          precision={1}
                          onChange={(e, value) => formik.setFieldValue('quality', value)}
                        />
                      </div>
                    </div>
                    <div className={styles["punctuality"]}>
                      <div className={styles["punctuality"]}>
                        <h3>{t('vogzters:reviews:punctuality')}</h3>
                      </div>
                      <div className={styles["rating"]}>
                        <Rating
                          name="punctuality-rating"
                          value={
                            formik.values.punctuality
                          }
                          onChange={(e, value) => formik.setFieldValue('punctuality', value)}
                          precision={1}
                        />
                      </div>
                    </div>
                    <div className={styles["communication"]}>
                      <div className={styles["communication"]}>
                        <h3>{t('vogzters:reviews:communication')}</h3>
                      </div>
                      <div className={styles["rating"]}>
                        <Rating
                          name="communication-rating"
                          value={
                            formik.values.communication
                          }
                          onChange={(e, value) => formik.setFieldValue('communication', value)}
                          precision={1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`flex justify-start items-start flex-col gap-4 px-2 ${styles['comment-field']}`}>
                  <label htmlFor="comment">
                    {t('vogzters:reviews.modal-comment')}
                  </label>
                  <textarea
                    name="comment"
                    id="comment"
                    value={formik.values.comment}
                    onChange={
                      (e) => formik.setFieldValue('comment', e.target.value)
                    }
                    placeholder={t('vogzters:reviews.modal-placeholder')}
                  />
                </div>
                <Btn1 btnName={t('vogzters:reviews.modal-send')} type='submit' onclick={
                  () => formik.handleSubmit()
                } />
              </div>
            </motion.div>
          )
        }
        {briefR.detailFav.loading ? (
          <Spinner />
        ) : briefR.detailFav.error ? (
          <h3>Error</h3>
        ) : Object.prototype.hasOwnProperty.call(ba, 'media') ? (
          <>
            {' '}
            <motion.div
              variants={variantPage} // Pass the variant object into Framer Motion
              initial="hidden" // Set the initial state to variants.hidden
              animate="enter" // Animated state to variants.enter
              exit="exit" // Exit state (used later) to variants.exit
              transition={{ type: 'linear' }} // Set the transition to linear
              className={`${styles['all-page']} overflow-y-scroll h-auto !justify-start ${userInfos === null ? styles['not-connected'] : ''}`}
            >
              {isGuest && (
                <>
                  <nav className={`${styles['top-nav-bar']}`}>
                    <div className={`${styles['logo']}`}>
                      <a href={`${VOGZ_SITE}/marques`}>VOGZ</a>
                    </div>
                    <div className={styles['menu-button']}
                      onClick={() => setDisplayMenu(!displayMenu)}
                    >
                      <i className="fa-solid fa-bars" />
                    </div>

                    {isGuest && (
                      <>
                        <div ref={refMenu} className={`${styles['menu-container']} ${displayMenu ? styles['menu-mobile'] : ''}`}>
                          <ul>
                            <a target='_blank' rel='noreferrer' href={`${VOGZ_SITE}/vogzter`}>{t("vogzters:head.title-1")}</a>
                            <a target='_blank' href={`${VOGZ_SITE}/trouver-un-createur`} rel='noreferrer'>{t("vogzters:head.title-3")}</a>
                            <a
                              className={`${styles['blog-btns']}`} target='_blank'
                              href={`${VOGZ_SITE}/offre`} rel='noreferrer'>{t("vogzters:head.title-2")}</a>
                            <a target='_blank' className={`${styles['blog-btns']}`} href={`${VOGZ_SITE}/blog`} rel='noreferrer' >{t("vogzters:head.title-4")}</a>
                          </ul>
                        </div>

                        <div>
                          <Btn1
                            btnName={t('vogzters:head.btn-1')}
                            type='button'
                            className={`${styles['btn-login']}`}
                            onclick={() => {
                              sessionStorage.setItem('vogzter', JSON.stringify(id))
                              navigate('/vogz-tunnel')
                            }
                            }
                          />
                        </div>
                      </>
                    )}
                  </nav>
                  <br />
                </>
              )}
              {!isGuest &&
                <div className={`${styles['header-page']}`}>
                  <button
                    type="button"
                    className="btn-txt"
                    onClick={() => {
                      navigate(-1)
                      // If the navigation history is empty, we redirect to the dashboard
                      navigate("/dashboard/castings")
                    }}
                  >
                    <i className="fa-regular fa-angle-left" />{' '}
                    {t('mycastings:return')}
                  </button>
                  {ba?.applicant.user &&
                    <div className={styles['btn-favourite']}>
                      <Favourite user={ba?.applicant.user!} profilBa isGuest={!!isGuest} />
                    </div>
                  }
                </div>}

              {briefR.briefApplication.error ? (
                <h4>{briefR.briefApplication.error}</h4>
              ) : (
                <>
                  <ReactTooltip
                    id="info-not-available"
                    place={'top'}
                    effect={'solid'}
                    multiline
                    clickable
                    disable={
                      ba!?.media.filter(
                        (v) => v.status === 'validated' && v.step === 2
                      ).length > 0
                    }
                  >
                    <div
                      style={{
                        backgroundColor: 'rgba(0,0,0,.5)',
                        padding: '0px 10px',
                        borderRadius: 12,
                      }}
                    >
                      {t('mycastings:no-vogz-available')}
                    </div>
                  </ReactTooltip>
                  <div className={styles['profile']}>
                    <aside>
                      <section>
                        <InfoProfil
                          isGuest={isGuest}
                          displayInfoVogzter
                          displayBio
                          displaySocials
                          user={ba?.applicant.user!}
                          first_name={ba?.applicant.user.first_name!}
                          last_name={ba?.applicant.user.last_name!}
                          parent_code={ba?.applicant.user.parent_code ?? ''}
                          delivery_address={ba?.applicant.user.delivery_address!}
                          mediaValidatedCount={ba?.mediaValidatedCount!}
                          profile={ba?.applicant.user.profile!}
                          setShowMessages={setShowMessages}
                          lastActivity={ba?.applicant.user.last_activity}
                          mediaSold={baUploadedMedia.filter((m) => m.status === 'chosen' && !m.offered)}
                          videoIntro={hasVideoIntro ? ba?.applicant?.user?.profile?.video_intro?.source : undefined}
                          classNames={{ container: styles['info-profil-name'] }}
                        />
                        <br />
                      </section>
                      <br />
                      <section>
                        <div className={styles['medias-streamer']}>
                          <div ref={refStreamerContainer} className='flex justify-start'>
                            <button
                              className={mediaFilter === 'portfolio' ? styles['selected'] : ''}
                              type='button'
                              onClick={() => setMediaFilter('portfolio')}
                            >
                              {t('vogzters:media-filters:portfolio')}
                            </button>
                            {!isGuest &&
                              <button
                                className={mediaFilter === 'see-all' ? styles['selected'] : ''}
                                type='button'
                                onClick={() => setMediaFilter('see-all')}
                              >
                                {t('vogzters:media-filters:all')}
                              </button>
                            }
                            <button
                              className={mediaFilter === 'sold-videos' ? styles['selected'] : ''}
                              type='button'
                              onClick={() => setMediaFilter('sold-videos')}
                            >
                              {t('vogzters:media-filters:sold-videos')}
                            </button>
                            <div className={`${styles['medias-streamer-line']} ${isGuest ? '!w-[210px]' : ''} `}>
                              <span ref={refStreamerLine}> </span>
                            </div>
                          </div>
                        </div>
                        <AnimatePresence exitBeforeEnter>
                          {(!hasPortfolio || !hasUploadedMedia || !hasSoldVideos) && (
                            <motion.div
                              initial={{ opacity: 0, x: '-800px' }}
                              exit={{ opacity: 0, x: '-800px' }}
                              transition={{ duration: 0.5 }}
                              animate={{ x: 0, opacity: 1 }}
                            >
                              {((userMobx.VogzterOffers.data?.length ?? 0) <= 0 && mediaFilter === 'portfolio' && !hasPortfolio) && returnPlaceholder(t, 'portfolio')}
                              {((userMobx.VogzterOffers.data?.length ?? 0) <= 0 && ['see-all', 'none'].includes(mediaFilter) && !hasUploadedMedia) && returnPlaceholder(t, 'all')}
                              {((userMobx.VogzterOffers.data?.length ?? 0) <= 0 && mediaFilter === 'sold-videos' && !hasSoldVideos) && returnPlaceholder(t, 'sold-videos')}
                            </motion.div>
                          )}
                          <motion.div
                            initial={{ opacity: 0, x: '-800px' }}
                            exit={{ opacity: 0, x: '-800px' }}
                            transition={{ duration: 0.5 }}
                            animate={{ x: 0, opacity: 1 }}
                            className={`flex my-10 !overflow-x-auto gap-5 px-2`}
                          >
                            {['see-all', 'sold-videos', 'none'].includes(mediaFilter) ? (baUploadedMedia.map((v, i) => (
                              <CardVogz
                                className={styles['card-vogz']}
                                key={i}
                                briefApplication={(ba as unknown) as MyCasting}
                                media={v}
                                showBookmarked
                                profilFav
                                showBottomStreamer
                                isGuest={isGuest}
                              />
                            ))) : (
                              <>
                                {ba?.applicant.user.profile.video_demo?.filter(vd => vd.status === 'uploaded').map((v, i) => (
                                  <CardBriefApplication
                                    className='!mt-0'
                                    classNameBody='!w-[17rem] !h-[350px] m-auto'
                                    classNameHeader='!hidden'
                                    type='videoDemo'
                                    videoDemo={v}
                                  />
                                ))}
                                {hasVideoIntro && (
                                  <CardBriefApplication
                                    className='!mt-0'
                                    classNameBody='!w-[17rem] !h-[350px] m-auto'
                                    classNameHeader='!hidden'
                                    type='videoIntro'
                                    videoIntro={ba.applicant.user.profile.video_intro!}
                                  />
                                )}
                              </>
                            )}
                          </motion.div>
                        </AnimatePresence>
                      </section>
                    </aside>
                    {isGuest && <div className={`${styles['start-collab-mobile']} ${(isIOS && !isChrome) ? styles['start-collab-mobile-safari'] : (isIOS && isChrome) ? styles['start-collab-ios-chrome'] : ''}`}>
                      <Btn1 btnName='Démarrer une collaboration' onclick={() => setShowMessages(true)} />
                    </div>}
                  </div>

                  {(userMobx.VogzterOffers.data?.length ?? 0) > 0 &&
                    (
                      <motion.div
                        initial={{ opacity: 0, width: 0 }}
                        animate={{ opacity: 1, width: "auto" }}
                        transition={{ duration: 0.2 }}
                        exit={{ opacity: 0, width: 0 }}
                        className={styles['offers-container']}
                      >
                        <h1>{t('vogzters:offers:my-offers')}</h1>
                        <div className={styles['offers']}>
                          {
                            userMobx.VogzterOffers.data?.slice(0, visibleItems).map((offer, i) => (
                              <VogzterOffer
                                key={i}
                                type={offer.type}
                                title={offer.title}
                                description={offer.description}
                                price={offer.price}
                                contentUsage={offer.content_usage}
                                rights={{
                                  not_allow: offer.rights?.not_allow ?? true,
                                  duration: formatDuration(offer.rights?.duration ?? 0)
                                }}
                                socials={offer.socials ?? []}
                                onClick={() => {
                                  if (userInfos !== null && !userInfos._company?.premium)
                                    navigate('/dashboard/premium')
                                  else
                                    navigate(`/dashboard/new-deal/${offer._id}`)
                                }}
                              />
                            ))
                          }
                        </div>
                        {((userMobx.VogzterOffers.data?.length ?? 0) > 2 && visibleItems <= 2) && (
                          <div className={styles['btn-show-more']}>
                            <Btn1
                              btnName={t('vogzters:offers:show-more')}
                              type='button'
                              onclick={() => setVisibleItems(userMobx.VogzterOffers.data?.length ?? 0)}
                            />
                          </div>
                        )}
                      </motion.div>
                    )}

                  <motion.div className={styles['user-reviews-wrapper']}>
                    {
                      (
                        baUploadedMedia.filter((m) =>
                          m.status === 'chosen' &&
                          ((m.brief as Brief).brand as BrandI)?._id === (userMobx.getBrandSession()?._id || userMobx.me.data?.brand?._id)
                        ).length > 0 ||
                        (userMobx.reviews.data?.length ?? 0) > 0
                      ) && (
                        <h1>
                          {t('vogzters:reviews:title')}
                          <span role='img' aria-label='writing'>✍️</span>
                        </h1>
                      )
                    }
                    <motion.div className={styles['user-reviews']}>
                      <div className={styles['overall-section']}>
                        <div className={styles['overall-section__top']}>
                          {
                            (
                              baUploadedMedia.filter((m) =>
                                m.status === 'chosen' &&
                                ((m.brief as Brief).brand as BrandI)?._id === (userMobx.getBrandSession()?._id || userMobx.me.data?.brand?._id)
                              ).length > 0 ||
                              (userMobx.reviews.data?.length ?? 0) > 0
                            ) && (
                              <div className={styles['overall-section__top__evals']}>
                                <div className={styles['stars']}>
                                  <Rating
                                    name="overall-rating"
                                    value={userMobx.reviews.data?.reduce((acc, curr) => acc + (curr.rating.overall ?? 0), 0) ?? 0 / (userMobx.reviews.data?.length ?? 1) ?? 0}
                                    readOnly
                                    precision={0.1}
                                  />
                                </div>
                                <div className={styles['numbers']}>
                                  {
                                    (() => {
                                      const reviews = userMobx.reviews.data ?? []
                                      const totalRating = reviews.reduce((acc, curr) => acc + (curr.rating?.overall ?? 0), 0)
                                      const reviewsCount = reviews.length || 1

                                      const averageRating = Math.round((totalRating / reviewsCount) * 10) / 10
                                      return averageRating || 0
                                    })()
                                  }/5
                                </div>
                                <div className={styles['count']}>
                                  {t('vogzters:reviews:reviews-count', { count: userMobx.reviews.data?.length ?? 0 })}
                                </div>
                              </div>
                            )}
                          {!isGuest && (baUploadedMedia.filter((m) =>
                            m.status === 'chosen' &&
                            ((m.brief as Brief).brand as BrandI)?._id === (userMobx.getBrandSession()?._id || userMobx.me.data?.brand?._id)
                          ).length > 0) && (
                              <div className={styles['overall-section__top__button']}>
                                <BtnBasic
                                  btnName={t('vogzters:reviews:write-review')}
                                  type="sky-violet-bordered"
                                  onclick={() => setShowRate(true)}
                                  typeBtn='submit'
                                />
                              </div>)}
                        </div>
                        {
                          (
                            baUploadedMedia.filter((m) =>
                              m.status === 'chosen' &&
                              ((m.brief as Brief).brand as BrandI)?._id === (userMobx.getBrandSession()?._id || userMobx.me.data?.brand?._id)
                            ).length > 0 ||
                            (userMobx.reviews.data?.length ?? 0) > 0
                          ) && (
                            <div className={styles["overall-section__bottom"]}>
                              <ReviewRatings
                                quality={userMobx.reviews.data?.reduce((acc, curr) => acc + curr.rating.quality, 0) ?? 0 / (userMobx.reviews.data?.length ?? 1) ?? 0}
                                punctuality={userMobx.reviews.data?.reduce((acc, curr) => acc + curr.rating.punctuality, 0) ?? 0 / (userMobx.reviews.data?.length ?? 1) ?? 0}
                                communication={userMobx.reviews.data?.reduce((acc, curr) => acc + curr.rating.communication, 0) ?? 0 / (userMobx.reviews.data?.length ?? 1) ?? 0}
                              />
                            </div>
                          )
                        }
                      </div>
                      {(userMobx.reviews.data?.length ?? 0) > 0 && userMobx.reviews.data && (
                        showReviews ? (userMobx.reviews.data.map((review, i) => (
                          <UserReviews key={i} review={review} />
                        )))
                          : (
                            <div className={styles['show-more-reviews']}>
                              <Btn1
                                btnName={t('vogzters:reviews:show-more')}
                                type='button'
                                onclick={() => setShowReviews(true)}
                              />
                            </div>
                          )
                      )}
                    </motion.div>
                  </motion.div>
                </>
              )}
            </motion.div>
          </>
        ) : null}
        <AnimatePresence>
          {showMessages && !briefR.detailFav.loading && (
            <motion.div
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: "auto" }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0, width: 0 }}
              className={styles['chat-container']}
            >

              <ChatContainer
                setShowMessages={setShowMessages}
                vogzter={userMobx.vogzter.data?.user}
                isGuest={isGuest}
                chatProps={{
                  bubblesCentered: false,
                  messages: userMobx.messageUser.data?.map((message, i) =>
                    new Message({
                      id: message._id,
                      message: message.text,
                      senderName: message.sender,
                      createdAt: message.createdAt,
                      read: message.read && message.sender === 'client',
                      lastOne: last(userMobx.messageUser.data)?._id === message._id,
                      offer: message.offer,
                      brief: message.brief,
                    })
                  ) ?? [],
                  senderName: 'client',
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </main>
    </>
  )
}

export default observer(ProfileVogzter)


const returnPlaceholder = (t: any, type: 'portfolio' | 'all' | 'sold-videos') => {

  const title = t('vogzters:placeholder:title')
  const desc =
    type === 'portfolio'
      ? t('vogzters:placeholder:desc-portfolio')
      : type === 'all'
        ? t('vogzters:placeholder:desc-all')
        : t('vogzters:placeholder:desc-sold-videos')

  return (
    <div className='flex w-full flex-col justify-center items-center'>
      <img src="/assets/imgs/placeholder-profile-media.png" alt="" />
      <Title1 text={title} size={20} className='uppercase !text-[#3A4161]' />
      <SubTitle1
        text={desc}
        dangerouslySetInnerHTML
        className='!text-[#3A4161] text-center'
      />
    </div>
  )
}
