// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-overflow-handler_hidden-item__2cMLS {\n  position: absolute;\n  opacity: 0;\n}\n\n.list-overflow-handler_render-overflow__xU7wg {\n  cursor: zoom-in;\n  z-index: 1;\n}\n\n.list-overflow-handler_overflow-items__2VeOx {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  padding: 15px;\n  opacity: 1;\n  transition: 0.15s;\n  background: rgba(255, 255, 255, 0.85);\n  backdrop-filter: blur(10px);\n  z-index: 5;\n  cursor: default;\n}\n.list-overflow-handler_overflow-items__2VeOx > div {\n  flex-grow: 0;\n}", "",{"version":3,"sources":["webpack://src/pages/Favorites-vogzters/components/ListOverflowHandler/components/list-overflow-handler.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;AACF;;AAEA;EACE,eAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,UAAA;EACA,iBAAA;EACA,qCAAA;EACA,2BAAA;EACA,UAAA;EACA,eAAA;AACF;AAAE;EACE,YAAA;AAEJ","sourcesContent":[".hidden-item {\n  position: absolute;\n  opacity: 0;\n}\n\n.render-overflow {\n  cursor: zoom-in;\n  z-index: 1;\n}\n\n.overflow-items {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  padding: 15px;\n  opacity: 1;\n  transition: .15s;\n  background: rgba(255, 255, 255, 0.85);\n  backdrop-filter: blur(10px);\n  z-index: 5;\n  cursor: default;\n  > div {\n    flex-grow: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden-item": "list-overflow-handler_hidden-item__2cMLS",
	"render-overflow": "list-overflow-handler_render-overflow__xU7wg",
	"overflow-items": "list-overflow-handler_overflow-items__2VeOx"
};
export default ___CSS_LOADER_EXPORT___;
