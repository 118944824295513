import React, { FC, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Dropdown,
  DropdownItemProps,
  StrictDropdownProps,
} from 'semantic-ui-react'
import styles from './components/styles.module.scss'

const BtnDrop: FC<BtnDropProps> = ({
  onSelect,
  defaultVal,
  disabled,
  options,
}) => {
  type IOslect = keyof typeof options.keys
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <div className={styles['btn-dropdown-container']}>
      <button
        type="button"
        className={`${styles['btn-dropdown']} 
      `}
        onClick={() => setOpen(!open)}
      >
        {''}
      </button>
      <Dropdown
        closeOnChange={false}
        className={styles['btn-dropdown']}
        scrolling
        placeholder={t('mycastings:sort')}
        options={options}
        onChange={(val, e) => {
          onSelect(e.value as string)
        }}
      />
    </div>
  )
}

export type CustomDopDownProps = {
  key: number
  text: string
  value: string
}
interface BtnDropProps {
  onSelect: (e: CustomDopDownProps['value']) => void
  defaultVal: string
  options: CustomDopDownProps[]
  disabled?: boolean
}

function extractValue<Type extends { value: string }[], P = keyof Type>(
  a: Type
) {
  type MainTheme = typeof a[number]
  const ll: MainTheme = a[0]
  return ll
}

BtnDrop.defaultProps = {
  disabled: false,
}

BtnDrop.propTypes = {
  onSelect: PropTypes.func.isRequired,
  defaultVal: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  // @ts-ignore eslint-disable-next-line prettier/prettier
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.any.isRequired,
}

export default BtnDrop
