import React, { FC } from 'react'
import PropTypes from 'prop-types'
import styles from './components/styles.module.scss'

const BtnSlc1: FC<BtnSlc1Props> = ({ onclick, btnName, active, disabled }) => {
  return (
    <button
      type="button"
      className={`${styles['btn-selectable1']} 
      ${styles[disabled ? 'disabled' : active ? 'active-btn' : 'not-selected']}
      `}
      onClick={onclick}
    >
      {btnName}
    </button>
  )
}

interface BtnSlc1Props {
  onclick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  btnName: string
  active?: boolean
  disabled?: boolean
}

BtnSlc1.defaultProps = {
  active: false,
  disabled: false,
}

BtnSlc1.propTypes = {
  onclick: PropTypes.func.isRequired,
  btnName: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default BtnSlc1
