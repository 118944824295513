import React from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import ReactTooltip from 'react-tooltip'
import * as animationData from '../../../../../assets/animations/fire.json'
import { ProgressBar } from './components/react-progressbar-fancy'
import styles from './style.module.scss'
import modal from '../../../../../mobx/modal'

const defaultOptions = {
  loop: true,
  autoplay: true,
  // @ts-ignore
  animationData: animationData.default,
}

function ProgressEnroll({ value }: ProgressEnrollI) {
  // translation
  const { t } = useTranslation()
  return (
    <div className={styles['progress-enroll']}>
      <Lottie
        style={{ opacity: 0.5 }}
        options={{ ...defaultOptions, loop: false }}
        height={40}
        width={40}
      />
      <ReactTooltip
        id="info-enroll"
        className='info-enroll'
        place={'bottom'}
        effect={'solid'}
        multiline
        clickable
      >
        <div className={styles['info-enroll_']}>
          <img src='/assets/imgs/jauge.png' alt='jauge'/>
          <div className={styles['info-enroll_text_side']}>
            <h5>{value} {t('mycastings:new-vogzter')}</h5>
            <p>{t('mycastings:new-vogzter-interested')}</p>
          </div>
        </div>
      </ReactTooltip>
      <ProgressBar
        score={value >= 70 ? 100 : (value * 100) / 70}
        hideText
        secondaryColor="#f12711"
        primaryColor="#f5af19"
        childrenProfile={
          <div
            data-tip
            data-for="info-enroll"
            className={styles['progress-user']}
            onClick={()=>{
              if (value > 0)
                modal.toogleModalEnroll(true)
            }}
          >
            <span className={styles['progress-number']}>{value}</span>
            <img src="/assets/imgs/user-profile.png" alt="profile" />
          </div>
        }
        className={styles['progress-bar-enroll']}
      />
      <Lottie options={defaultOptions} height={40} width={40} />
    </div>
  )
}

export default ProgressEnroll

interface ProgressEnrollI {
  value: number
}
