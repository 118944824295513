/* eslint-disable react/no-unused-prop-types */
import { motion } from 'framer-motion'
import { Spinner } from 'grommet'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import BtnBack from '../../../utils/components/Button/BtnBack'
import briefR from '../../../mobx/brief'
import { Brief } from '../../../types-project/Brief'
import Btn1 from '../../../utils/components/Button/Btn1'
import userMobx from '../../../mobx/user'
import { Vogzter } from '../../../types-project/Client'
import styles from './components/styles.module.scss'
import CardFavouriteVogzter from '../../Favorites-vogzters/components/CardFavouriteVogzter'
import { CustomBriefI } from '../NewCasting/subscreens/EditCasting/components/Forms/FormModel/castingFormModel'
import Search from '../../../utils/components/SearchBar'
import SearchBar from '../../../utils/components/Ui/inputs/SearchBar'

const AddFavourites = () => {
  const { t } = useTranslation()

  const { id } = useParams<{
    id: string
  }>()

  const navigate = useNavigate()
  const [value, setvalue] = useState<string>()

  const filterSearch = (element: any[]) => {
    if (!value) {
      return element
    }
    return element.filter((doc: { first_name: string; last_name: string }) => {
      const firstName = doc?.first_name.toLowerCase()
      const lastName = doc?.last_name.toLowerCase()
      return firstName?.includes(value) || lastName?.includes(value)
    })
  }
  useEffect(() => {
    briefR.getBrief(id!)
    userMobx.getFavouriteVogzters()
  }, [])



  const brief = briefR.brief.data
  const favouriteVogzters = userMobx.brand.data?.favourites as Array<Vogzter>
  const brand = userMobx.brand.data

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Ajouter mes vogzters favoris</title>
      </Helmet>
      {userMobx.vogzters.loading || briefR.brief.loading ? (
        <Spinner />
      ) : (
        !briefR.brief.error &&
        !userMobx.vogzters.error &&
        favouriteVogzters.length > 0 && (
          <>
            {brief!.exclusive?.is_exclusive
              ? null
              : brief!.private?.is_private
              ? null
              : navigate('/dashboard/castings')}
            <BtnBack btnName={'Retour'} customAction={() => navigate(-1)} />
            <main className={`${styles['body-favourite']}`}>
              <motion.div
                initial={{ opacity: 0, x: '-800px' }}
                exit={{ opacity: 0, x: '-800px' }}
                transition={{ duration: 1 }}
                animate={{ x: 0, opacity: 1 }}
                className="flex flex-col items-center justify-center h-full"
              >
                <div className="m-2">
                  <Search
                    onChange={(v) => {
                      setvalue(v.target.value)
                    }}
                    placeholder={t('mycastings:search')}
                  />
                </div>

                <div className={styles['div-card-select']}>
                  <div className={`${styles['card-select-fav']}`}>
                    {filterSearch(favouriteVogzters).length > 0 ? (
                      filterSearch(favouriteVogzters)
                        .map((vogzter: Vogzter, k) => {
                          return (
                            <CardFavouriteVogzter
                              key={k}
                              user={vogzter}
                              form
                              onclick={() => {
                                if (brief?.favourites?.includes(vogzter._id)) {
                                  brief.favourites = (brief?.favourites as CustomBriefI['favourites'])?.filter(
                                    (e) => e !== vogzter?._id
                                  )
                                } else {
                                  brief?.favourites!?.push(vogzter._id)
                                }
                              }}
                              _select={(brief?.favourites as CustomBriefI['favourites'])?.includes(
                                vogzter._id
                              )}
                            />
                          )
                        })
                    ) : (
                      <p>{t('favorite:no-favorite')}</p>
                    )}
                  </div>
                </div>
                <Btn1
                  onclick={() => {
                    briefR.patchBrief(
                      { favourites: brief?.favourites },
                      id as string,
                      () => {
                        toast.success(t('mycastings:confirmation-add-fav'), {
                          theme: 'colored',
                        })
                      }
                    )
                  }}
                  btnName={'Confirmer la sélection'}
                  className={styles['btn-selection']}
                >
                  <img src="./assets/sgvs/lock.svg" alt="" />
                </Btn1>
              </motion.div>
            </main>
          </>
        )
      )}
    </>
  )
}

export default observer(AddFavourites)
