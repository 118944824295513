import { Variants } from 'framer-motion/types/types'
import i18n from './i18n'


export const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
)

export const formValid = ({ formErrors, ...rest }: any) => {
  let valid = true

  // validate form errors being empty
  Object.values(formErrors).forEach((val: any) => {
    val.length > 0 && (valid = false)
    //  console.log(formErrors)
  })

  // validate the form was filled out
  Object.values(rest).forEach((val: any) => {
    val === null && (valid = false)
    //  console.log(val)
  })
  return valid
}


export const pageVariantsAnimation: Variants = {
  out: {
    opacity: 0,
    y: '-50px',
  },
  in: {
    opacity: 1,
    y: '0',
  },
  initial: {
    opacity: 1,
    y: '50px',
  },
  // out: {
  //   opacity: 0,
  //   marginTop:-80
  // },
}

export const stepVariantsAnimation = {
  out: {
    opacity: 0,
    x: '-10px',
  },
  in: {
    opacity: 1,
    x: '0',
  },
  initial: {
    opacity: 1,
    x: '10px',
  },
}

export const bubleVariantsAnimation = {
  out: {
    opacity: 0,
    y: '-150px',
  },
  in: {
    opacity: 1,
    y: '0',
  },
  initial: {
    opacity: 1,
    y: '150px',
  },
  // out: {
  //   opacity: 0,
  //   marginTop:-80
  // },
}

export function swap(json: any) {
  const ret = {} as any
  // eslint-disable-next-line
  for (const key in json) {
    ret[json[key]] = key
  }
  return ret
}

export function diffSDate(startDate: any) {
  const today = new Date()

  const xmas = new Date(startDate)
  const msPerDay = 24 * 60 * 60 * 1000
  const timeLeft = xmas.getTime() - today.getTime()

  const eDaysLeft = timeLeft / msPerDay
  const daysLeft = Math.floor(eDaysLeft)
  const eHrsLeft = (eDaysLeft - daysLeft) * 24
  const hrsLeft = Math.floor(eHrsLeft)
  const minsLeft = Math.floor((eHrsLeft - hrsLeft) * 60)
  // eslint-disable-next-line
  return daysLeft > 0
    ? `${daysLeft > 0 ? daysLeft + i18n.t('mycastings:date.day') : ''} ${
        hrsLeft > 0 ? hrsLeft + i18n.t('mycastings:date.hour') : ''
      } ${
        minsLeft > 0
          ? `${i18n.t(`mycastings:date.and`)} ${minsLeft}${i18n.t(
              `mycastings:date.minute`
            )}`
          : ''
      }`
    : i18n.t('mycastings:date.expired')
}

/**
 * Ajouter trois points a la fin d'un text s'il est trop long
 * @param text text string to truncate
 * @param size what size truncate to
 */
export function truncate(text: string, size: number) {
  return text.length > size ? `${text.slice(0, size - 1)}…` : text
}

/**
 * On met la première lettre de la string en majuscule
 */
export function capitalizeFirstLetter(str: string) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)
}

