import React, { FC, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types"
import { AnimatePresence, motion } from "framer-motion"
import styles from "./components/list-overflow-handler.module.scss"
import BtnClose from "../../../../utils/components/Button/BtnClose";

interface ListOverflowHandlerProps {
  list: Array<React.ReactElement>
  renderOverflow: (nbOverflow: number) => React.ReactElement
}

/**
 * Component that handles the overflow of a list of HTML elements.
 * 
 * When the renderOverflow button is clicked, the hidden elements are displayed and take all available space of it's first `position: relative` parent
 * 
 * @param list list of HTML elements to display
 * @param renderOverflow function that returns an HTML element to display when the list is overflowing
 */

const ListOverflowHandler: FC<ListOverflowHandlerProps> = ({ list, renderOverflow }) => {

  const [showHiddenElements, setShowHiddenElements] = useState(false)
  const refContainer = useRef<HTMLDivElement>(null)
  const refWrapper = useRef<HTMLDivElement>(null)
  const refRenderOverflow = useRef<HTMLDivElement>(null)
  const renderOverflowWidth = useRef(refRenderOverflow.current?.clientWidth ?? 0)
  const [countHiddenElements, setCountHiddenElements] = useState(0)
  const countHiddenElementsRef = useRef(countHiddenElements)
  const previousWidths = { clientWidth: 0, scrollWidth: 0 }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
        if (!refWrapper.current?.children) return

        const currentClientWidth = entry.target.clientWidth
        const currentScrollWidth = entry.target.scrollWidth
        const isOverflowing = currentClientWidth < currentScrollWidth
        const isGrowing = currentClientWidth > previousWidths.clientWidth || currentScrollWidth > previousWidths.scrollWidth
        
        const childrenDivs = Array.from(refWrapper.current.children).slice()
        // remove renderOverflow div
        childrenDivs.pop()

        // We use childrenDivs.length -1 because we want to display at least one item
        if (isOverflowing) { setCountHiddenElements((prev) => Math.min(prev + 1, childrenDivs.length -1)) } 
        else if (isGrowing) {
          const selectionIndex = childrenDivs.length - countHiddenElementsRef.current
          const willAppearWidth = childrenDivs.at(selectionIndex)?.clientWidth ?? 0
          const willOverflow = refWrapper.current.clientWidth + willAppearWidth + renderOverflowWidth.current >= currentScrollWidth
          if (!willOverflow && countHiddenElementsRef.current > 0) { 
            setCountHiddenElements((prev) => Math.max(prev - 1, 0)) 
          }
        }
        previousWidths.clientWidth = currentClientWidth
        previousWidths.scrollWidth = currentScrollWidth
    })

    if (refContainer.current) {
      resizeObserver.observe(refContainer.current)
    }

    return () => resizeObserver.disconnect()
  }, [refContainer, list.length, countHiddenElements])

  useEffect(() => {
    /* 
    - Updates the countHiddenElementsRef when the state changes
    - The reference enables us to get the real value count inside the ResizeObserver 
    */
    countHiddenElementsRef.current = countHiddenElements
    if (countHiddenElements > 0) {
      renderOverflowWidth.current = refRenderOverflow.current?.clientWidth ?? 0
     }
  }, [countHiddenElements])

  return (
    <div ref={refContainer} className="flex w-full z-30">
      <div ref={refWrapper} className="flex items-center">
        {list.map((item, index) => {
          const isHidden = index > (list.length -1) - countHiddenElements
          return (
            <div 
              key={index}
              className={`flex flex-col flex-shrink-0 ${!!isHidden && styles['hidden-item']}`}
            >
              {item}
            </div>
          )
        })}
        <div ref={refRenderOverflow} className={(!list.length || !countHiddenElements) ? 'hidden' : styles['render-overflow']}
          onClick={() => setShowHiddenElements(prev => !prev)}
        >
          {renderOverflow(countHiddenElements)}
          <AnimatePresence>
            {showHiddenElements && 
              <motion.div className={styles['overflow-items']}
                key="list-overflow-handler-overflow-items"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
              >
                <BtnClose 
                  className={{ container: 'flex-shrink-0 border-blue-dark self-end', icon: 'text-blue-dark' }}
                />
                <div className="flex flex-wrap justify-center m-auto scroll-container-y">
                  {list
                    // .slice(list.length - countHiddenElements, list.length) shows only hidden elements
                    .map((item, index) => <div key={index}>{item}</div>)}
                </div>
              </motion.div>
            }
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

ListOverflowHandler.propTypes = {
  list: PropTypes.any.isRequired,
  renderOverflow: PropTypes.func.isRequired,
}

export default ListOverflowHandler