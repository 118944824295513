import React, { FC } from "react";
import PropTypes from "prop-types"
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ListOverflowHandler from "../ListOverflowHandler"
import Badge from "../Badge"

interface DeletableListProps<T> {
  list: Array<{ id: string, label: string, data: T }>
  handleDeletion: (newList: Array<T>, removedId: string) => void
}

function DeletableList<T>({ list, handleDeletion }: DeletableListProps<T>) {

  const { t } = useTranslation()

  const listElements = list.map((item, index) => (
    <Badge 
      label={t(item.label)} 
      deletable
      handleDeletion={() => handleDeletion(list.filter((o) => o.id !== item.id).map((e) => e.data), item.id)}
    />
  ))

  return (
    <ListOverflowHandler
      list={listElements}
      renderOverflow={(nbOverflow) => <Badge label={`+${nbOverflow}`} /> }
    />
  )
}

DeletableList.propTypes = {
  list: PropTypes.any.isRequired,
  handleDeletion: PropTypes.func.isRequired,
}


export default observer(DeletableList)