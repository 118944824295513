import * as React from 'react'
import PropTypes from 'prop-types'

interface IconProps {
  color?: string
}

const MovieClapperIcon: React.FC<IconProps> = ({ color }) => (
  <svg
    width="27"
    height="21"
    viewBox="0 0 27 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_339_14519)">
      <path
        d="M26.3721 3.49573V0.830561C26.3721 0.371847 25.9977 0.000202522 25.5356 0.000202522H22.9211L1.9771 0H1.90977C1.44769 0 1.07331 0.371629 1.07331 0.830359V2.50559C0.476569 2.51714 0 2.99914 0 3.59435C0 4.18956 0.479629 4.67155 1.07331 4.68311L1.07352 6.34683C1.07352 6.78813 1.4187 7.14821 1.85446 7.17719V18.8689C1.85446 20.0447 2.81679 21 4.00124 21H23.4447C24.6292 21 25.5915 20.0447 25.5915 18.8689L25.5911 7.17719C26.0269 7.14823 26.372 6.78815 26.372 6.34683V3.68166L2.18794 3.68146C2.19079 3.6525 2.19079 3.6205 2.19079 3.59153C2.19079 3.55954 2.18794 3.52774 2.18488 3.49574L26.3721 3.49573ZM1.09384 4.29996C0.699081 4.29996 0.380216 3.98342 0.380216 3.59155C0.380216 3.19967 0.699086 2.88313 1.09384 2.88313C1.4886 2.88313 1.80747 3.19967 1.80747 3.59155C1.80747 3.98342 1.4886 4.29996 1.09384 4.29996ZM21.7537 0.214895L25.2194 3.27498H21.046L17.5802 0.214895H21.7537ZM23.9065 15.823H3.5391V15.5618H23.9065V15.823ZM23.9065 11.3838H3.5391V11.1225H23.9065V11.3838ZM9.65432 7.17982L13.3687 3.89898H17.5424L13.828 7.17982H9.65432ZM21.5051 7.17982H17.3314L21.0459 3.89898H25.2193L21.5051 7.17982ZM5.69136 3.89898H9.8648L6.15038 7.17982H1.97694L5.69136 3.89898ZM5.6885 3.27481L2.22279 0.214729H6.39623L9.86194 3.27481H5.6885ZM13.3684 3.27481L9.90273 0.214729H14.0762L17.5419 3.27481H13.3684Z"
        fill={color}
      />
      {/* Custom edit starts here */}
      <path
        d="M26.3721 3.49573V0.830561C26.3721 0.371847 25.9977 0.000202522 25.5356 0.000202522H22.9211L1.9771 0H1.90977C1.44769 0 1.07331 0.371629 1.07331 0.830359V2.50559C0.476569 2.51714 0 2.99914 0 3.59435C0 4.18956 0.479629 4.67155 1.07331 4.68311L1.07352 6.34683C1.07352 6.78813 1.4187 7.14821 1.85446 7.17719V18.8689C1.85446 20.0447 2.81679 21 4.00124 21H23.4447C24.6292 21 25.5915 20.0447 25.5915 18.8689L25.5911 7.17719C26.0269 7.14823 26.372 6.78815 26.372 6.34683V3.68166L2.18794 3.68146C2.19079 3.6525 2.19079 3.6205 2.19079 3.59153C2.19079 3.55954 2.18794 3.52774 2.18488 3.49574L26.3721 3.49573ZM1.09384 4.29996C0.699081 4.29996 0.380216 3.98342 0.380216 3.59155C0.380216 3.19967 0.699086 2.88313 1.09384 2.88313C1.4886 2.88313 1.80747 3.19967 1.80747 3.59155C1.80747 3.98342 1.4886 4.29996 1.09384 4.29996ZM21.7537 0.214895L25.2194 3.27498H21.046L17.5802 0.214895H21.7537ZM23.9065 15.823H3.5391V15.5618H23.9065V15.823ZM23.9065 11.3838H3.5391V11.1225H23.9065V11.3838ZM9.65432 7.17982L13.3687 3.89898H17.5424L13.828 7.17982H9.65432ZM21.5051 7.17982H17.3314L21.0459 3.89898H25.2193L21.5051 7.17982ZM5.69136 3.89898H9.8648L6.15038 7.17982H1.97694L5.69136 3.89898ZM5.6885 3.27481L2.22279 0.214729H6.39623L9.86194 3.27481H5.6885ZM13.3684 3.27481L9.90273 0.214729H14.0762L17.5419 3.27481H13.3684Z"
        fill={color}
        stroke={color}
        strokeWidth={0.05}
        shapeRendering="auto"
        opacity={0.1}
      />
      {/* Custom edit ends here */}
    </g>
    <defs>
      <filter
        id="filter0_i_339_14519"
        x="0"
        y="-1"
        width="27.3721"
        height="22"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="-1" />
        <feGaussianBlur stdDeviation="3.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0431373 0 0 0 0 0.113725 0 0 0 0 0.407843 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_339_14519"
        />
      </filter>
    </defs>
  </svg>
)

MovieClapperIcon.propTypes = {
  color: PropTypes.string,
}

MovieClapperIcon.defaultProps = {
  color: '#2A42A7',
}

export default MovieClapperIcon
