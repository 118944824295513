/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-pascal-case */

/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { motion } from 'framer-motion'
import 'react-step-progress-bar/styles.css'
import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  useFormik,
  useFormikContext,
} from 'formik'
// @ts-ignore
import { ProgressBar, Step } from 'react-step-progress-bar'
// import the stylesheet
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../../../utils/components/Headings/Title1'
/* @ts-ignore */
import styles from './components/style.module.scss'
import {
  pageVariantsAnimation,
  stepVariantsAnimation,
} from '../../../../../utils/constants'
import BtnBack from '../../../../../utils/components/Button/BtnBack'
import { Tags } from '../../../../../utils/helpers'
import Form1 from './components/Forms/Form1'
import Form2 from './components/Forms/Form2'
import Form3 from './components/Forms/Form3'
import { Brief, Link, MediaBrief, SocialLinks } from '../../../../../types-project/Brief'
import castingFormModel, {
  CustomBriefI,
  DeepPartial,
} from './components/Forms/FormModel/castingFormModel'
import validationSchema from './components/Forms/FormModel/validationSchema'
import utilsMobx from '../../../../../mobx/utils'
import briefR from '../../../../../mobx/brief'
import Summary from '../Summary'
import userMobx from '../../../../../mobx/user'
import {
  Address,
  BrandI,
  ClientI,
  ClientUpdate,
  ProductsBrand,
  Vogzter,
} from '../../../../../types-project/Client'
import pricingAlgorithm from './components/PricingAlgorithm'

const Formik_ = observer(Formik)

function EditCasting() {
  const [step, setStep] = useState<0 | 1 | 2 | 3>(0)
  const { id } = useParams<{
    id: string
  }>()

  const [recommendedPrice, setRecommendedPrice] = useState<number>(100)
  const [minimumPrice, setMinimumPrice] = useState<number>(100)
  const [searchQuery] = useSearchParams()
  const paramsUrl: { currentStep?: string | null } = Object.fromEntries([
    ...searchQuery,
  ])

  const [briefType, setBriefType] = useState<'casting' | 'casting-plus' | 'on-spot'>('casting')

  const currentValidationSchema = validationSchema(
    briefR.brief.data!?.exclusive!.is_exclusive
      ? 'exclusive'
      : briefR.brief.data!?.private?.is_private
        ? 'private'
        : 'creator'
    , briefType, briefR.brief.data!?.content_type, minimumPrice ?? 100)[step]

  // navigation
  const navigate = useNavigate()

  // const { values, submitForm, errors } = useFormikContext<CustomBriefI>();
  // const params = new URLSearchParams(location.query)
  // const tags = params.get('tags')

  // brief creation mobx data
  const { data, loading, error } = briefR.briefCreationReq
  const userInfos: ClientI = userMobx.getUserInfos() as ClientI
  const brand: BrandI = userMobx.getBrandSession() ?? {}
  const selectedVogzterSession: Vogzter = JSON.parse(sessionStorage.getItem('selectedVogzter') ?? "{}")
  const selectedProduct: ProductsBrand = JSON.parse(sessionStorage.getItem('product') ?? "{}")

  const brandLogo: MediaBrief = {
    source: userInfos._company.type === 'agency' ? (brand?.logo ? brand?.logo : '') : (userInfos.brand?.logo ? userInfos.brand.logo : ''),
    type: "image",
    key: "logo",
    _id: userInfos.brand?._id ? userInfos.brand._id : '',
  }

  useEffect(() => {    
    setRecommendedPrice(pricingAlgorithm(selectedProduct).updatedPrice)
    setMinimumPrice(pricingAlgorithm(selectedProduct).minimumPrice)
  }, [briefR.briefCreationReq.data, selectedProduct])

  useEffect(() => {
    briefR.getBrief(id as string, (brief_) => {
      briefR.getAllCountries()
      briefR.getAllLanguages()
      if (getStepAvailabilities(redirect_step, brief_)) {
        setStep(redirect_step)
      }
    })
    const redirect_step = JSON.parse(paramsUrl.currentStep ?? '0')
  }, [])
  function handleSubmit<
    T extends CustomBriefI,
    V extends FormikHelpers<CustomBriefI>
  >(values: T, formikHelpers: V) {
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname
      }?currentStep=${step + 1}`
    window.history.pushState({ path: newUrl }, '', newUrl)
    document
      .getElementById('page-aside')!
      ?.scrollTo({ top: 0, behavior: 'smooth' })


    switch (step) {
      case 0:
        if (values.on_spot?.is_on_spot && values.on_spot?.addresses) {
          values.on_spot.addresses = values.on_spot.addresses.flatMap((a) => (a as Address)._id ?? "")
        }
        briefR.patchBrief(values, id as string, () => {
          sessionStorage.removeItem('selectedVogzter')
        })
        setStep(1)
        break
      case 1:
        values.creative_guidelines = values.creative_guidelines?.filter(
          (e) => e !== ''
        )
        values.scenario = values.scenario?.filter((e) => e !== '')
        values.added_info = values.added_info?.filter((e) => e !== '')
        values.to_checkout = {
          ...values.to_checkout,
          // @ts-ignore
          links: values.to_checkout!?.links?.filter((e) => e !== ''),
        }
        briefR.patchBrief(values, id as string)
        if (
          userInfos.brand?.info?.save_info ||
          (userInfos._company.type === 'agency' && brand.info?.save_info)
        ) {
          userMobx.updateBrand((values.brand as BrandI)!._id!, {
            info: {
              // filter social links where value is empty or when the to checkout link url equals values brand info url
              social_links: values.to_checkout!?.links?.filter((e: SocialLinks) => e.url !== '' && e.url !== (values.brand as BrandI).info?.url) as [
                SocialLinks
              ],
            },
          })
        }
        setStep(2)
        break
      case 2:
        briefR.uploadFile(values, id as string, () => {
          sessionStorage.removeItem('product')
          navigate(`/dashboard/castings/${id as string}/summary`)
        })
        break
      default:
        break
    }
  }
  // translation
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Vogz - ${briefR.brief.data?.ratio ? 'Editer' : 'Ajouter'
          } un casting`}</title>
      </Helmet>
      <motion.main
        animate="in"
        id="main-casting-df"
        exit="out"
        variants={stepVariantsAnimation}
        onAnimationComplete={(evt) => {
          utilsMobx.handleAssideEnded(true)
        }}
        className={`${styles['page-main']} ${utilsMobx.aside_animation_ended ? '!transform-none' : ''
          }`}
      >
        <header>
          <BtnBack
            btnName={'Retour'}
            customAction={() => navigate('/dashboard/castings')}
          />
          <div className={styles['page-container']}>
            <Tags
              type={briefR.brief.data!?.type!?.name}
              className={styles['tag']}
            />
            <br />
            <br />
            <div className={styles['progress-bar-container']}>
              <div className={styles['progress-bar']}>
                {/* @ts-ignore */}
                <ProgressBar
                  height={3}
                  hasStepZero
                  percent={percentage(step, 2)}
                  unfilledBackground="rgba(188, 201, 255, 1)"
                  filledBackground="var(--main-blue-color-bg)"
                >
                  {/* @ts-ignore */}
                  <Step transition="scale">
                    {({ accomplished }: { accomplished: boolean }) => (
                      <div
                        onClick={() => { }}
                        className={styles['dot-step-container']}
                      >
                        <span
                          className={`
                        ${styles['dot-step-title']} 
                        ${accomplished ? styles['accomplished'] : ''}`}
                        >
                          {briefR.brief.data?.private && briefR.brief.data?.private.is_private
                            ? t('new-casting:deal-params')
                            : t('new-casting:brief-params')}
                        </span>
                        <div
                          className={`${styles['dot-step']} ${accomplished && styles['dot-step-done']
                            }`}
                        />
                        {accomplished && <i className="fa-solid fa-check" />}
                      </div>
                    )}
                  </Step>
                  {/* @ts-ignore */}
                  <Step transition="scale">
                    {({ accomplished }: { accomplished: boolean }) => (
                      <div className={styles['dot-step-container']}>
                        <span
                          className={`
                       ${styles['dot-step-title']} 
                       ${accomplished ? styles['accomplished'] : ''}`}
                        >
                          {t('new-casting:your-brief')}
                        </span>
                        <div
                          className={`${styles['dot-step']} ${accomplished && styles['dot-step-done']
                            }`}
                        />
                        {accomplished && <i className="fa-solid fa-check" />}
                      </div>
                    )}
                  </Step>
                  {/* @ts-ignore */}
                  <Step transition="scale">
                    {({ accomplished }: { accomplished: boolean }) => (
                      <div className={styles['dot-step-container']}>
                        <span
                          className={`
                           ${styles['dot-step-title']} 
                           ${accomplished ? styles['accomplished'] : ''}`}
                        >
                          {t('new-casting:medias')}
                        </span>
                        <div
                          className={`${styles['dot-step']} ${accomplished && styles['dot-step-done']
                            }`}
                        />
                        {accomplished && <i className="fa-solid fa-check" />}
                      </div>
                    )}
                  </Step>
                </ProgressBar>
              </div>
            </div>
          </div>
        </header>
        {briefR.brief.loading || !briefR.brief.data || briefR.countries.loading ||
         !briefR.countries.data || briefR.languages.loading || !briefR.languages.data? (
          <h3>wait</h3>
        ) : (
          <Formik
            enableReinitialize={false}
            onSubmit={handleSubmit}
            validationSchema={currentValidationSchema}
            validateOnChange={false}
            initialValues={getInitialValues(
              {
                ...(briefR.brief.data as unknown) as CustomBriefI, // Copie les propriétés de l'objet briefR.brief.data dans un nouvel objet de type CustomBriefI
                favourites: selectedVogzterSession?._id ? [selectedVogzterSession._id] : (briefR.brief.data && briefR.brief.data.favourites), // Si selectedVogzterSession._id existe, ajoute-le à un tableau favourites, sinon crée un tableau vide
                media: (((briefR.brief.data as unknown) as CustomBriefI).media as Array<MediaBrief>).length === 0 ? // Si l'objet CustomBriefI n'a pas de propriété media ou si elle est vide
                  (Object.entries(selectedProduct).length) ? // Si l'objet selectedProduct a des propriétés
                    [brandLogo, ((selectedProduct.images?.at(0) as MediaBrief))].concat((((briefR.brief.data as unknown) as CustomBriefI).media as Array<MediaBrief>) ?? []) : // Crée un tableau avec le logo de la marque et la première image de selectedProduct, puis ajoute les éléments de l'objet media de CustomBriefI s'il existe
                    [brandLogo].concat((((briefR.brief.data as unknown) as CustomBriefI).media as Array<MediaBrief>) ?? []) : // Crée un tableau avec le logo de la marque, puis ajoute les éléments de l'objet media de CustomBriefI s'il existe
                  ((briefR.brief.data as unknown) as CustomBriefI).media, // Utilise l'objet media de CustomBriefI tel quel s'il existe
                to_checkout: {
                  ...((briefR.brief.data as unknown) as CustomBriefI).to_checkout,
                  // Copie les propriétés de l'objet to_checkout de CustomBriefI dans un nouvel objet et ajoute les liens sociaux de la marque à la propriété links de to_checkout s'ils existent 
                  links: ((briefR.brief.data as unknown) as CustomBriefI).to_checkout?.links?.length ?? -1 > 0
                    ? ((briefR.brief.data as unknown) as CustomBriefI).to_checkout?.links
                    : (((userInfos._company.type === 'agency' && brand?.info?.social_links?.filter((link) => link.url !== brand?.info?.url))
                      ? [...(brand?.info?.social_links ?? []), { url: brand?.info?.url ?? '' }]
                      : (userInfos._company.type === 'brand' && userInfos.brand?.info?.social_links?.filter((link) => link.url !== userInfos.brand?.info?.url))
                        ? [...(userInfos.brand?.info?.social_links ?? []), { url: userInfos.brand?.info?.url ?? '' }]
                        : (userInfos.brand?.info?.social_links ?? [])
                    ) ?? []).filter((link): link is SocialLinks => link !== (undefined && '') ?? [])
                }
              }
            ) as CustomBriefI}
            component={({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
            }: FormikProps<CustomBriefI>) => (
              <Form id="Form1" className={styles['page-body']}>
                {step === 0 ? (
                  <Form1 brief={castingFormModel} errors={errors} onBriefTypeChanged={(type) => setBriefType(type)} values={values} />
                ) : step === 1 ? (
                  <Form2 brief={castingFormModel} errors={errors} recommendedPrice={recommendedPrice} minimumPrice={minimumPrice} />
                ) : step === 2 ? (
                  <Form3 brief={castingFormModel} errors={errors} />
                ) : (
                  step === 3 && <Summary />
                )}
                <div className={styles['btn-bottom-fl']}>
                  <div>
                    <BtnBasic
                      onclick={() => {
                        const newUrl = `${window.location.protocol}//${window.location.host
                          }${window.location.pathname}?currentStep=${step - 1}`
                        window.history.pushState({ path: newUrl }, '', newUrl)
                        if (step > 0) setStep((step - 1) as 0 | 1 | 2 | 3)
                        if (step <= 0) navigate('/dashboard/castings')
                      }}
                      btnName={'Retour'}
                      type="sky-violet-bordered"
                    />
                    <div className="w-3" />
                    <BtnBasic
                      disabled={loading}
                      btnName={
                        step === 2
                          ? loading
                            ? 'Envoie en cours...'
                            : 'Terminer'
                          : step === 1 && loading
                            ? 'Enregistrement en cours...'
                            : 'Suivant'
                      }
                      type={'inverted-sky-violet'}
                      typeBtn="submit"
                    />
                  </div>
                </div>
              </Form>
            )}
          />
        )}
      </motion.main>
    </>
  )
}

function percentage(partialValue: number, totalValue: number) {
  return (100 * partialValue) / totalValue
}

function getInitialValues(brf: CustomBriefI): Omit<CustomBriefI, 'type'> & { type: string | undefined } {
  return {
    ...brf,
    media: brf.media?.length
      ? (brf.media as any[]).concat(
        // if the brf has a media length >= 3 we increase the length to reach 4 and fill with undefined
        new Array(4 - (brf.media?.length >= 4 ? 4 : brf.media?.length) ?? 0).fill(undefined)
      )
      : castingFormModel.media!,
    inspiration: brf.inspiration?.media?.length
      ? brf.inspiration
      : castingFormModel.inspiration,
    favourites: brf.favourites ?? castingFormModel.favourites,
    script: brf.script ?? castingFormModel.script,
    music: brf.music ?? castingFormModel.music,
    scenario: brf.scenario?.length ? brf.scenario : castingFormModel.scenario,
    type: brf.type?._id,
    added_info: brf.added_info?.length
      ? brf.added_info
      : castingFormModel.added_info,
    creative_guidelines: brf.creative_guidelines?.length
      ? brf.creative_guidelines
      : castingFormModel.creative_guidelines,
  }
}

const getStepAvailabilities: (step: number, brief: Brief) => boolean = (
  step: number,
  brief: Brief
) => {
  if (step === 1) {
    if (
      brief?.exclusive?.is_exclusive &&
      Object.prototype.hasOwnProperty.call(brief.exclusive, 'reward') &&
      Object.prototype.hasOwnProperty.call(brief.exclusive, 'conditions') &&
      Object.prototype.hasOwnProperty.call(brief.exclusive, 'type') &&
      brief.ratio &&
      brief.length?.max &&
      brief?.length?.min
    )
      return true
    else if (
      brief.private?.is_private &&
      brief.remuneration &&
      brief.ratio &&
      brief.length?.max &&
      brief?.length?.min
    )
      return true
    else if (brief.ratio && brief.length?.max && brief?.length?.min) return true
  } else if (step === 2) {
    return !!(brief.name && brief.description)
  }
  return false
}

EditCasting.prototype = {}

export default observer(EditCasting)
