import React, { useEffect, useRef, useState, FC } from 'react'
import { motion } from 'framer-motion'

import * as yup from 'yup'
import { observer } from 'mobx-react'

import { Helmet } from 'react-helmet'
import Loading from 'react-loading'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ScrollToTop from '../../../../routing/ScrollToTop'
import { pageVariantsAnimation } from '../../../../utils/constants'
import { BrandI, ClientI } from '../../../../types-project/Client'
import s from './components/styles.module.scss'
import TextField from '../../../../utils/components/Ui/inputs/TextField'
import UserMobx from '../../../../mobx/user'
import BrandsCard from './components/BrandsCard'
import BodyBrand from './components/BodyBrand'
import BtnBack from '../../../../utils/components/Button/BtnBack'


const Brand: FC = () => {


  const user = UserMobx.me.data!
  const isAgency = user?._company.type === 'agency'
  const brand = isAgency ? UserMobx.getBrandSession()! : user.brand
  const navigate = useNavigate()
  const { t } = useTranslation()
  

  useEffect(() => {
    UserMobx.getBrands((brands)=> {
      if (brands.length === 0)
        navigate('/dashboard/brands/new')
    })
    if (Object.keys(JSON.parse(sessionStorage.getItem('newBrand') ?? '{}')).length > 0) {
      UserMobx.setBrandSession(JSON.parse(sessionStorage.getItem('newBrand') ?? '{}'))
      sessionStorage.removeItem('newBrand')
    } else
      UserMobx.resetBrandSession()
  }, [])
  

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Profil - Brand</title>
      </Helmet>
      <main className="flex w-full h-auto 2xl:justify-center items-center justify-start 2xl:ml-0 sm:ml-0 ml-4 flex-col  sm:min-h-full p-4">
        <div className=" w-full absolute top-16 left-2 sm:top-4 z-20">
          <BtnBack btnName= {t('mycastings:return')} />
        </div>
        <motion.div
          className="w-full"
          initial="initial"
          animate="in"
          exit="out"
          transition={{ type: 'spring', bounce: 0 }}
          variants={pageVariantsAnimation}
        >
          <ScrollToTop />
          {!UserMobx.me.loading && !UserMobx.me.error ? (
            isAgency ? (
              !UserMobx.allBrands.loading &&
              !UserMobx.allBrands.error &&
              (UserMobx.allBrands.data ?? []).length > 0 ? (
                !brand?._id ? (
                  <div className={s['cards-brand']}>
                    {(UserMobx.allBrands.data ?? []).map((brnd, k) => (
                      <BrandsCard key={brnd._id} brand={brnd} />
                    ))}
                  </div>
                ) : (
                  <BodyBrand isAgency={isAgency}/>
                )
              ) : (
                <Loading />
              )
            ) : (
              <BodyBrand isAgency={isAgency}/>
            )
          ) : (
            <Loading />
          )}
        </motion.div>
      </main>
    </>
  )
}


export default observer(Brand)




