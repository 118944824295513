/* eslint-disable @typescript-eslint/naming-convention */
import briefR from "../../../../../../../mobx/brief"
import { ProductsBrand } from "../../../../../../../types-project/Client"

export default function pricingAlgorithm(selectedProduct?: ProductsBrand) {
    let updatedPrice = 100
    let minimumPrice = 100
    const { briefCreationReq } = briefR
    if (briefCreationReq.data) {
        const {
            property_rights,
            content_usage,
            with_product,
            on_spot,
            targeting,
            content_type
        } = briefCreationReq.data

        if (!with_product && !on_spot?.is_on_spot) {
            switch (property_rights) {
                case 6:
                    updatedPrice = 100
                    break
                case 12:
                    updatedPrice = 120
                    break
                case 36:
                    updatedPrice = content_usage === 'ad' ? 150 : 120
                    break
                case 60:
                    updatedPrice = content_usage === 'ad' ? 200 : 150
                    break
                default:
                    break
            }
        } else if (with_product && !on_spot?.is_on_spot) {
            minimumPrice = 100
            switch (property_rights) {
                case 6:
                    updatedPrice = 100
                    break
                case 12:
                    updatedPrice = 100
                    break
                case 36:
                    updatedPrice = content_usage === 'ad' ? 150 : 120
                    break
                case 60:
                    updatedPrice = content_usage === 'ad' ? 200 : 150
                    break
                default:
                    break
            }
            if (selectedProduct && selectedProduct.price) {
                if (selectedProduct.price < 50) {
                    updatedPrice *= 0.8
                    minimumPrice = 100 * 0.8
                } else if (selectedProduct.price < 100) {
                    updatedPrice *= 0.6
                    minimumPrice = 100 * 0.6
                } else if (selectedProduct.price >= 100) {
                    updatedPrice *= 0.6
                    minimumPrice = 100 * 0.5
                }
            }
        } else if (on_spot?.is_on_spot) {
            minimumPrice = 100
            switch (property_rights) {
                case 6:
                    updatedPrice = 200
                    break
                case 12:
                    updatedPrice = 220
                    break
                case 36:
                    updatedPrice = 250
                    break
                case 60:
                    updatedPrice = 300
                    break
                default:
                    break
            }
            if (on_spot.dotation) {
                if (on_spot.dotation < 50) {
                    updatedPrice *= 0.9
                    minimumPrice = 100 * 0.9
                } else if (on_spot.dotation < 100) {
                    updatedPrice *= 0.8
                    minimumPrice = 100 * 0.8
                } else if (on_spot.dotation >= 100) {
                    updatedPrice *= 0.7
                    minimumPrice = 100 * 0.6
                }
            }
        }

        if (
            targeting?.gender === 'male' ||
            (targeting?.age?.min ?? 0) >= 35
        ) {
            minimumPrice += (100 * 0.2)
            updatedPrice *= 1.2
        }

        if (content_type === 'influence') {
            minimumPrice += (minimumPrice * 0.2)
            updatedPrice *= 1.2
        }
    }
    updatedPrice = Math.round(updatedPrice)
    minimumPrice = Math.round(minimumPrice)
    return {
        updatedPrice,
        minimumPrice,
    }
}