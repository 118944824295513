import React, { useState, FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Loading from 'react-loading'

import s from './components/styles.module.scss'
import { Vogzter } from '../../../types-project/Client'
import userMobx from '../../../mobx/user'
import { Applicant } from '../../../types-project/Brief'

const Favourite = ({ user, form, profilBa, isGuest, className }: SectionFormProps) => {
  const brand = userMobx.brand.data

  const navigate = useNavigate()
  const changeStatusFavourite = () => {
    // Checks if the user is a guest if is user set sessionStorage with the id of the vogzter
    if (isGuest) {
      sessionStorage.setItem('vogzter', JSON.stringify(user._id))
      navigate('/login')
    } else {
      // Checks whether we are currently in a form or not
      if (!form) {
        if (brand?.favourites?.includes(user._id)) {
          userMobx.deleteFavouriteVogzters(user._id)
        } else {
          userMobx.addFavouriteVogzters(user._id)
        }
      }
    }
  }

  // translation
  const { t } = useTranslation()
  return (
    <>
      {userMobx.updateFav.loading || userMobx.updateFav.error ? (
        <Loading height={'15px'} width={'20px'} color='grey' className={`!inline !bg-transparent ${profilBa ? s['profil-fav'] : ''} ${s['favourite']}`} />
      ) : (
        <button
          type="button"
          onClick={changeStatusFavourite}
          className={` ${form ? s['favourite-cursor-none'] : ''} ${profilBa ? s['profil-fav'] : ''}
           ${profilBa && brand?.favourites?.includes(user._id) ? s['is-favourite'] : ''}
           ${s['favourite']} ${className ?? ""}`}
        >
          <i
            className={`fa-solid fa-heart ${brand?.favourites?.includes(user._id) ? s['is-favourite'] : ''
              }`}
          />
          {profilBa && t("favorite:favorite")}
        </button>
      )}
    </>
  )
}
interface SectionFormProps {
  user: Vogzter | Applicant
  form?: boolean
  profilBa?: boolean
  isGuest?: boolean
  className?: string
}

Favourite.defaultProps = {
  form: false,
  profilBa: false,
  isGuest: false,
  className: undefined
}

Favourite.prototype = {
  user: PropTypes.object,
  className: PropTypes.string
}
export default observer(Favourite)
