import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import * as Yup from 'yup'

import { FormikProvider, useFormik } from 'formik'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../../../utils/components/Headings/Title1'
import styles from './components/steps_casting.module.scss'
import {
  pageVariantsAnimation,
  stepVariantsAnimation,
} from '../../../../../utils/constants'
import userMobx from '../../../../../mobx/user'
import TextField from '../../../../../utils/components/Ui/inputs/TextField'
import { BrandI, ClientI } from '../../../../../types-project/Client'
import DropdownCheckbox, { DropdownCheckboxOption } from '../../../../../utils/components/Ui/dropdown/DropdownCheckbox'
import { BriefParams } from '../../../../../types-project/Brief'
import briefR from '../../../../../mobx/brief'

function Step2({ nextBtnRef, setStep }: Step2Props,) {
  const [paramBrief, setParamBrief] = useState<BriefParams>({
    content_type: undefined
  })
  // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  const navigate = useNavigate()
  // in case the new URL field is empty, we get the old one from before the model change of the object brand 
  let brand: BrandI & { url?: string }
  // if the user is an agency, we get the brand from the session storage
  if (userInfos._company.type === 'agency') {
    brand = JSON.parse(sessionStorage.getItem('brand') ?? '{}')
  } else {
    // if the user is a brand, we keep the brand we got from the local storage
    brand = userInfos.brand ?? {}
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { type_casting } = useParams<{
    type_casting: 'creator' | 'exclusive' | 'private'
  }>()
  useEffect(() => {
    if (paramBrief.content_type !== undefined) {
      sessionStorage.setItem('paramsBrief', JSON.stringify({ ...paramBrief, content_type: paramBrief.content_type }));
      if (paramBrief.content_type === 'ugc') {
        setStep(3);
      } else {
        briefR.createBrief(type_casting!, (data) => {
          navigate(`/dashboard/castings/${data._id}/edit`);
        });
      }
    }
  }, [paramBrief.content_type])


  // translation
  const { t } = useTranslation()

  return (
    <motion.main
      initial="initial"
      animate="in"
      exit="out"
      variants={stepVariantsAnimation}
      className={styles['page-main']}
    >
      <section className={styles['section-wrapper']}>
        <div className={styles['step-content']}>
          <div className={styles['content-type']}>
            <h1>
              {t('new-casting:content-type.title')}
            </h1>
            <div className={styles['select']}>
              <div className={`${styles['type']} ${styles['influence']} ${paramBrief.content_type === 'influence'? styles['selected']:''}`}>
                <div className={styles['img']}>
                  <img src="/assets/imgs/influence-pic.png" alt="influence" />
                </div>
                <b>{t('new-casting:content-type.influence.title')}</b>
                <p>{t('new-casting:content-type.influence.desc')}</p>
                <BtnBasic typeBtn='button' type='inverted-sky-violet' 
                  onclick={()=>{
                    setParamBrief({...paramBrief,content_type:'influence'})
                    if (paramBrief.content_type !== undefined){
                      sessionStorage.setItem('paramsBrief',JSON.stringify({...paramBrief,content_type:'influence'}))
                        if (paramBrief.content_type === 'influence'){
                          briefR.createBrief(type_casting!, (data) => {
                            navigate(`/dashboard/castings/${data._id}/edit`)})
                        } else {
                          setStep(3)
                        }
                    }
                  }} 
                btnName={t('new-casting:content-type.start')} />
              </div>
              <div className={`${styles['type']} ${paramBrief.content_type === 'ugc'? styles['selected']:''}`}>
                <div className={styles['img']}>
                  <img src="/assets/imgs/ugc-pic.png" alt="ugc" />
                </div>
                <b>{t('new-casting:content-type.ugc.title')}</b>
                <p>{t('new-casting:content-type.ugc.desc')}</p>
                <BtnBasic typeBtn='button' type='inverted-sky-violet' btnName={t('new-casting:content-type.start')}
                 onclick={()=> {
                    setParamBrief({...paramBrief,content_type:'ugc'})
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className={styles['content-right']}>
            <h1>
              {t('new-casting:step3.title')}
            </h1>
            <div className={styles['right']}>
              {propertyRights.map((v,k)=>
                <BtnBasic className={`${paramBrief.property_rights === v.value? styles['isSelect']: ''}`} key={k} typeBtn='button' btnName={v.txt} type='sky-violet-bordered' 
                onclick={()=>{
                  setParamBrief({...paramBrief,property_rights:v.value})
                  if (paramBrief.content_usage !== undefined){
                    sessionStorage.setItem('paramsBrief',JSON.stringify({...paramBrief,property_rights:v.value}))
                    if (nextBtnRef.current) {
                      nextBtnRef.current.onclick = () => {
                        if (type_casting === 'private') {
                          setStep(4)
                        } else {
                          briefR.createBrief(type_casting!, (data) => {
                            navigate(`/dashboard/castings/${data._id}/edit`)})
                        }
                      }
                      nextBtnRef.current.disabled = false
                    }
                  }
                }}
                />
              )
              }
            </div>
          </div> */}
        </div>
      </section>
    </motion.main >
  )
}
export interface Step2Props {
  nextBtnRef: React.RefObject<HTMLButtonElement>,
  setStep: (step: number) => void,
}

Step2.prototype = {
  nextBtnRef: PropTypes.any,
}

export default observer(Step2)