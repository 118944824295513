export class GloBalErrorService extends Error {
  message: string

  constructor(err?: any) {
    super(err) // 'Error' breaks prototype chain here
    this.name = 'GloBalError'
    this.message = this.getErrorMessage(err)
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }

  // eslint-disable-next-line class-methods-use-this
  private getErrorMessage(error?: any) {
    const errormessage =
      error.response && error.response.data
        ? error.response.data?.message
        : error.message
    return errormessage
  }
}
