/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { displayAvatar } from '../../../../utils/helpers'

import styles from './components/card-styles.module.scss'

const CardBriefApplicationSkeleton = () => {
  const { t } = useTranslation()
  return (
    <div className='mt-32'>
      <div
        className={`${styles['height-card']} ${styles['card-briefapplication']}`}
      >
        <header>
          <figure className={styles['bought']}>
            {displayAvatar('male')}
            <>
              <div style={{ height: 10 }} />
            </>
          </figure>

          <div className={styles['card-desc-user-actions']}>
            <div />
            <div />
          </div>
        </header>
        <footer>
          <div className={styles['card-body']}>
            <img alt="play" src={'/assets/svgs/video-play.svg'} />
            <div />
          </div>

          <button
            type="button"
            style={{ pointerEvents: 'all' }}
            className={`${styles['btn']}
                        ${styles['disabled']}`}
          >
            {t('mycastings:download-master')}
          </button>
        </footer>
      </div>
    </div>
  )
}

export default observer(CardBriefApplicationSkeleton)
