import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import mobx, { observable, action, computed, makeObservable } from 'mobx'
import { BrandI, Client, CustomClient, ClientI } from '../types-project/Client'
import { Endpoint, EndT, api } from '../utils/apiUtils'
import { ErrorRequest, Request } from '../types-project/Request'
import userMobx from './user'
import { GloBalErrorService } from '../types-project/models/services/Errors'
import i18n from '../utils/i18n'
import { NotificationsBrief } from '../types-project/Brief'


let instance: Team
const endpoints = new Endpoint()

class Team {
  constructor() {
    if (instance) {
      makeObservable(instance)
      return instance
    }
    instance = this
    makeObservable(instance)
  }

  @observable members: Request<Client[]> = {
    loading: false,
    data: [],
    error: null,
  }

  @action.bound
  async addMember(member: CustomClient) {
    try {
      const {
        data,
        status,
      }: AxiosResponse<Client | ErrorRequest> = await api.post(
        endpoints.getEndpoint(EndT.allTeamMember, userMobx.me.data!?._company!._id),
        member
      )

      if (status === 200)
        this.members = {
          ...this.members,
          loading: false,
          data: [...this.members.data ?? []].concat((data as Client)),
          error: null,
        }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message

      toast.error(i18n.t(`team:${errorMessage}`))
    }
  }

  @action.bound
  async deleteMember(id: string) {
    try {
      const {
        data,
        status,
      }: AxiosResponse<Client | ErrorRequest> = await api.delete(
        endpoints
          .getEndpoint(EndT.updateTeamMember, userMobx.me.data!?._company!._id)
          .replace(':memberId', id)
      )
      if (status === 200) {
        this.members = {
          loading: false,
          data: this.members.data?.filter(members => members._id !== id) as Client[],
          error: null,
        }
      }
    } catch (e) {
      toast.error((e as ErrorRequest).message)
    }
  }

  @action.bound
  async allMember(callBack?: (data:Client[]) => void) {
    try {
      this.members = { ...this.members, loading: true, data: [], error: null }
      const {
        data,
        status,
      }: AxiosResponse<Client[] | ErrorRequest> = await api.get(
        endpoints.getEndpoint(
          EndT.allTeamMember,
          userMobx.me.data!?._company!?._id
        )
      )
      if (status === 200) {
        if (callBack) callBack(data as Client[])
        this.members = {
          ...this.members,
          loading: false,
          data: data as Client[],
          error: null,
        }
      }
      else
        this.members = {
          ...this.members,
          loading: true,
          data: [],
          error: null,
        }
    } catch (e) {
      toast.error((e as ErrorRequest).message)
    }
  }

  @action.bound
  async notifAllMember(notifications: NotificationsBrief[], callBack?: (data:Client[]) => void) {
    try {
      const {
        data,
        status,
      }: AxiosResponse<Client[] | ErrorRequest> = await api.patch(
        endpoints.getEndpoint(
          EndT.updateNotifMember,
          userMobx.me.data?._company?._id
        ),
         { notifications }
      )
      if (status === 200) {
        if (callBack) callBack(data as Client[])
        this.members = {
          ...this.members,
          loading: false,
          data: data as Client[],
          error: null,
        }
      }
      else
        this.members = {
          ...this.members,
          loading: true,
          data: [],
          error: null,
        }
    } catch (e) {
      toast.error((e as ErrorRequest).message)
    }
  }
}

const team = new Team()
export default team
