import React, { Dispatch, FC, SetStateAction } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import userMobx from '../../../../mobx/user'
import { AllMessages, ClientI, GroupedMessages } from '../../../../types-project/Client'
import { countMsgNRead, getAvatar, timeRender, truncate } from '../../../../utils/helpers'
import styles from './components/card-message.module.scss'
import VogzterPicture from '../../../Favorites-vogzters/Profil-vogzters/components/VogzterPicture'

interface CardMsgProp {
  message: GroupedMessages
  selectedIdUserCurrentMessages?: string
  setSelectedIdUserCurrentMessages: (id: string) => void
}
const CardMsg: FC<CardMsgProp> = ({
  message,
  selectedIdUserCurrentMessages,
  setSelectedIdUserCurrentMessages,
}) => {
  const { t } = useTranslation()
  const userInfos = userMobx.getUserInfos() as ClientI
  const brand =
    userInfos._company.type === 'agency'
      ? userMobx.getBrandSession()!
      : userInfos?.brand
  const userCurrent = message.user
  return (
    <div
      className={`${styles['card-msg']} ${
        selectedIdUserCurrentMessages === message.user?._id ? styles['current-chat'] : ''
      }`}
      onClick={()=> {
        setSelectedIdUserCurrentMessages(message.user?._id)
        userMobx.readMsgBrand(brand?._id!,message.user?._id)
      }}
    >
      <VogzterPicture
        size={80}
        imgSource={userCurrent.profile?.picture?.source}
        showCertified={userCurrent?.certified}
        msgCount={countMsgNRead(message.messages)}
        lastActivity={message.user.last_activity}
      />
      <div className={styles['profil-info']}>
        <h3>
          {userCurrent?.first_name ?? ''}{' '}
          {`${userCurrent?.last_name}.` ?? ''}{' '}
          <button
            type="button"
            onClick={() => {
              if (userMobx.brand.data?.favourites?.includes(message.user?._id)) {
                userMobx.deleteFavouriteVogzters(message.user?._id)
              } else {
                userMobx.addFavouriteVogzters(message.user?._id)
              }
            }}
          >
            {userMobx.brand.data?.favourites?.includes(message.user?._id) ? (
              <i className="fa-solid fa-heart top-5 right-6 text-[#F41A1A] z-[3]" />
            ) : (
              <i className="fa-solid fa-heart top-5 right-6 text-[#FFD1D1] z-[3]" />
            )}
          </button>
        </h3>        
        <p className={`${message.messages[message.messages.length - 1]?.sender === 'client' ? styles['sender-you'] : ''}`}>
          {message.messages[message.messages.length - 1]?.sender === 'client' ? <span>{t("messages:you")} :</span> :null} {truncate(message.messages[message.messages.length - 1]?.text, 70)}
        </p>
        <p>{message.messages[message.messages.length - 1]?.sender === 'client' ? t("messages:delivered") : t("messages:received")} {timeRender(new Date(message.messages[message.messages.length - 1]?.createdAt))} </p>
      </div>
    </div>
  )
}
CardMsg.propTypes = {
  message: PropTypes.any.isRequired,
  selectedIdUserCurrentMessages: PropTypes.any.isRequired,
  setSelectedIdUserCurrentMessages: PropTypes.any.isRequired,
}

export default CardMsg
