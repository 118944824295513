/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import {
    Form, FormikHelpers, FormikProvider,
    useFormik
} from 'formik'
import { split } from 'lodash'
import Dropzone from 'react-dropzone'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { motion } from 'framer-motion'
import Loading from 'react-loading'
import { Loader } from 'semantic-ui-react'
import Skeleton from 'react-loading-skeleton'
import SubTitle1 from '../../../utils/components/Headings/SubTitle1'
import userMobx from '../../../mobx/user'
import { BrandI, ClientI, Vogzter } from '../../../types-project/Client'
import BtnBack from '../../../utils/components/Button/BtnBack'
import Title1 from '../../../utils/components/Headings/Title1'
import styles from './components/styles.module.scss'
import VogzterPicture from '../../Favorites-vogzters/Profil-vogzters/components/VogzterPicture'
import { Form1Props } from '../NewCasting/subscreens/EditCasting/components/Forms/FormModel/type-forms'
import { Brief, Offer } from '../../../types-project/Brief'
import SectionForm from '../NewCasting/subscreens/EditCasting/SectionForm'
import FieldForm from '../NewCasting/subscreens/EditCasting/FieldForm'
import castingFormModel, { CustomBriefI } from '../NewCasting/subscreens/EditCasting/components/Forms/FormModel/castingFormModel'
import utilsMobx from '../../../mobx/utils'
import { saveFile, getFilesByType, FileRecord, FileType, initializeDB } from '../../../utils/indexedDB'
import briefR from '../../../mobx/brief'
import BtnBasic from '../../../utils/components/Button/BtnBasic'
import modal from '../../../mobx/modal'
import i18n from '../../../utils/i18n'
import Btn1 from '../../../utils/components/Button/Btn1'
import BrandSwitcher from '../../../utils/components/BrandSwitcher/BrandSwitcher'

function NewDeal() {
    const { t } = useTranslation()
    const { idOffer } = useParams()
    const location = useLocation()
    const userInfos =userMobx.getUserInfos() as ClientI
    const isGuest = userInfos === null
    const navigate = useNavigate()
    const VOGZ_SITE = process.env.REACT_APP_VOGZ_SITE_URL
    const [pdfFiles, setPdfFiles] = useState<FileRecord[]>([])
    const [videoFiles, setVideoFiles] = useState<FileRecord[]>([])
    const [isLoading, setIsLoading] = useState(true)

    localStorage.setItem('isGuest', JSON.stringify(isGuest))
    let brand = {} as BrandI
    if (!isGuest) {
        if (userInfos._company.type === 'agency') {
            brand = localStorage.getItem('brand') ? JSON.parse(localStorage.getItem('brand') ?? '{}') : {} as BrandI
        }
    }
    const [translationsLoaded, setTranslationsLoaded] = useState(false)
    const [displayMenu, setDisplayMenu] = useState<boolean>(false)
    const refMenu = useRef<HTMLDivElement>(null)


    const formatDuration = (months: number) => {
        const years = Math.floor(months / 12)
        const remainingMonths = months % 12
        const andMonths = remainingMonths > 0 ? `\u00A0${i18n.t('vogzters:offers.and')}\u00A0${remainingMonths}\u00A0${t('vogzters:offers.month')}` : ''
        return years > 0 ? `${years}\u00A0${t('vogzters:offers.year')}${andMonths}` : `${months}\u00A0${t('vogzters:offers.month')}`
    }
    const card = new URLSearchParams(location.search).get('card')

    useEffect(() => {
        if (!translationsLoaded) return
        if (card === 'ok') {
            modal.resetData()
            modal.setTitle(i18n.t('new-deal:top-up-success.title'))
            modal.setDesc(i18n.t(`new-deal:top-up-success.desc`))
            modal.toogleAlert('stripe-valid')
            modal.setOnlyOneCTA(true, t('new-deal:understood'))
            window.history.replaceState({}, document.title, window.location.pathname)
        }
        if (card === 'ko') {
            modal.resetData()
            modal.setTitle(t('new-deal:top-up-error.title'))
            modal.setDesc(i18n.t(`new-deal:top-up-error.desc`))
            modal.toogleAlert('stripe-error')
            modal.onlyOneCTA = true
            modal.setOnlyOneCTA(true, t('new-deal:understood'))
            window.history.replaceState({}, document.title, window.location.pathname)
        }
    }, [card, translationsLoaded])

    useEffect(() => {
        if (refMenu.current) {
            refMenu.current.style.top = displayMenu ? '70px' : '-300%'
        }
    }, [displayMenu, utilsMobx.getWidth])

    useEffect(() => {
        if (idOffer) {
            userMobx.getOffer(idOffer)
        }

        const fetchData = async () => {
            const [pdfs, videos] = await Promise.all([
                new Promise((resolve) => getFilesByType('pdf', resolve)),
                new Promise((resolve) => getFilesByType('video', resolve))
            ])

            setPdfFiles(pdfs as FileRecord[])
            setVideoFiles(videos as FileRecord[])
        }


        initializeDB().then(() => {
            fetchData()
        }).catch(error => {
            console.log(error)
        })

        if (i18n.isInitialized) {
            setTranslationsLoaded(true)
        } else {
            i18n.on('initialized', () => setTranslationsLoaded(true))
        }
        return () => i18n.off('initialized')
    }, [])

    useEffect(() => {
        setInitialValues(prevValues => ({
            ...prevValues,
            script: pdfFiles.length > 0 ? pdfFiles[0].file : '',
            inspiration: {
                ...prevValues.inspiration,
                media: videoFiles.map((videoFile) => videoFile.file),
            }
        }))

        setIsLoading(false)
    }, [pdfFiles, videoFiles])

    const savedBrief = JSON.parse(localStorage.getItem('savedBrief') ?? '{}') as Brief
    const offer = userMobx.offer.data ?? {} as Offer
    const [initialValues, setInitialValues] = useState<CustomBriefI>(
        {
            dos: savedBrief.dos || ['', '', ''],
            donts: savedBrief.donts || ['', '', ''],
            inspiration: { media: [] },
            script: '',
            mentions: savedBrief.mentions || ['', '', '', '', '', ''],
            redirection: savedBrief.redirection || '',
            to_checkout: savedBrief.to_checkout || { links: [{ url: '' }, { url: '' }, { url: '' }] },
            description: savedBrief.description || ''
        }
    )

    const dealValidationSchema = Yup.object().shape({
        dos: Yup.array().of(Yup.string().notRequired()),
        donts: Yup.array().of(Yup.string().notRequired()),
        mentions: Yup.array().of(Yup.string().notRequired()),
        redirection: Yup.string().url('URL Invalid').notRequired(),
        inspiration: Yup.object({
            media: Yup.array().of(Yup.string().notRequired()).notRequired(),
        }).notRequired(),
        script: Yup.string().nullable().notRequired(),
        to_checkout: Yup.object().shape({
            links: Yup.array()
                .of(
                    Yup.object().shape({
                        url: Yup.string().url('URL Invalid').notRequired(),
                    }).notRequired()
                ).notRequired(),
        }).notRequired(),
        description: Yup.string().notRequired(),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: dealValidationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    })

    async function handleSubmit<
        T extends CustomBriefI,
        V extends FormikHelpers<T>
    >(values: T, { setSubmitting, setErrors }: V) {
        try {
            const scriptFileRecord: FileRecord = {
                id: 'script',
                type: 'pdf',
                name: 'script.pdf',
                file: values.script as File,
            }
            await saveFile(scriptFileRecord)
            const videoFileRecords: FileRecord[] = values.inspiration?.media.map((videoFile, index) => ({
                id: `video_${index}`,
                type: 'video',
                name: videoFile instanceof File ? videoFile.name : split(videoFile.source, '/')[split(videoFile.source, '/')?.length - 1],
                file: videoFile as File,
            })) || []
            await Promise.all(videoFileRecords.map((videoFileRecord) => saveFile(videoFileRecord)))

            const dataToStore = {
                ...values,
                favourites: [offer.owner._id],
                name: offer.title,
                remuneration: offer.price,
                status: 'created',
                offer: offer
            }

            const onSuccess = (stripeUrl: string) => {
                window.location.href = stripeUrl
            }

            const onFail = () => {
                modal.toogleAlert('stripe-error', true)
                modal.setOnConfirm(() => {
                    const priceToPay = offer.price - (userMobx.me.data?._company.credits ?? 0)
                    userMobx.getTopUpLink(priceToPay, {
                        ok: `/dashboard/new-deal/${idOffer}?card=ok`,
                        ko: `/dashboard/new-deal/${idOffer}?card=ko`
                    },
                        onSuccess,
                        onFail)
                })
            }

            localStorage.setItem('savedBrief', JSON.stringify(dataToStore))
            localStorage.setItem('idOffer', idOffer ?? '')

            if (isGuest) {
                // modal for when you're an unlogged user telling you to sign up and top up
                modal.resetData()
                modal.setTitle(offer.title)
                modal.setDesc(t('new-deal:sign-up-top-up'))
                modal.setOnConfirm(() => {
                    window.location.href = '/signup'
                })
                modal.toogleAlert('launch-casting')
            } else {
                if ((userMobx.me.data?._company.credits ?? 0) < offer.price) {
                    // modal for when you're connected but you don't have enough credits
                    modal.resetData()
                    modal.setTitle(offer.title)
                    modal.setDesc(i18n.t(`new-deal:not-enough-credit`))
                    modal.toogleAlert('launch-casting')
                    modal.setOnConfirm(async () => {
                        const priceToPay = offer.price - (userMobx.me.data?._company.credits ?? 0)
                        userMobx.getTopUpLink(priceToPay,
                            {
                                ok: `/dashboard/new-deal/${idOffer}?card=ok`,
                                ko: `/dashboard/new-deal/${idOffer}?card=ko`
                            },
                            onSuccess,
                            onFail)
                    })
                } else {
                    if (userInfos._company.type === 'agency' && !brand._id) {
                        // modal for when you're an agency but you don't have a brand selected
                        modal.resetData()
                        modal.setTitle(i18n.t(`new-deal:no-brand-selected.title`))
                        modal.setDesc(i18n.t(`new-deal:no-brand-selected.desc`))
                        modal.toogleAlert('warning')
                        modal.onlyOneCTA = true
                        modal.setOnlyOneCTA(true, t('new-deal:understood'))
                        modal.setOnConfirm(() => {
                            setTimeout(() => {
                                modal.resetData()
                            }, 1000)
                        })
                    } else {
                        await briefR.createBrief('private', async (data) => {
                            values.dos = values.dos?.filter((v) => v !== '')
                            values.donts = values.donts?.filter((v) => v !== '')
                            values.mentions = values.mentions?.filter((v) => v !== '')
                            await briefR.patchBrief({ ...values, favourites: [offer.owner._id], offer: offer, name: offer.title, remuneration: offer.price, content_type: offer.type, content_usage: offer.content_usage, ratio: 'portrait' }, data?._id ?? '')
                            await briefR.uploadFile({ inspiration: values.inspiration, script: values.script }, data?._id ?? '', () => { })
                            await briefR.validateBrief(data?._id ?? '')
                            let favouritesList
                            if (userInfos._company.type === 'agency') {
                                favouritesList = brand.favourites
                            } else if (userInfos._company.type === 'brand') {
                                favouritesList = userInfos.brand?.favourites
                            }
                            if (favouritesList && !favouritesList.includes(offer.owner._id)) {
                                await userMobx.addFavouriteVogzters(offer.owner._id)
                            }
                            navigate(`/dashboard/castings/${data._id}/notifications`)
                        })
                    }
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
    if (isLoading) {
        return <Loader />
    } else {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Vogz - Nouveau Deal</title>
                </Helmet>
                {isGuest && (
                    <>
                        <nav className={`${styles['top-nav-bar']}`}>
                            <div className={`${styles['logo']}`}>
                                <a href={`${VOGZ_SITE}/marques`}>VOGZ</a>
                            </div>
                            <div className={styles['menu-button']}
                                onClick={() => setDisplayMenu(!displayMenu)}
                            >
                                <i className="fa-solid fa-bars" />
                            </div>

                            {isGuest && (
                                <>
                                    <div ref={refMenu} className={`${styles['menu-container']} ${displayMenu ? styles['menu-mobile'] : ''}`}>
                                        <ul>
                                            <a target='_blank' rel='noreferrer' href={`${VOGZ_SITE}/comment-ca-marche`}>{t("vogzters:head.title-1")}</a>
                                            <a
                                                className={`${styles['blog-btns']}`} target='_blank'
                                                href={`${VOGZ_SITE}/offre`} rel='noreferrer'>{t("vogzters:head.title-2")}</a>
                                            <a target='_blank' href={`${VOGZ_SITE}/case-study`} rel='noreferrer'>{t("vogzters:head.title-3")}</a>
                                            <a target='_blank' className={`${styles['blog-btns']}`} href={`${VOGZ_SITE}/blog`} rel='noreferrer' >{t("vogzters:head.title-4")}</a>
                                        </ul>
                                    </div>

                                    <div>
                                        <Btn1
                                            btnName={t('vogzters:head.btn-1')}
                                            type='button'
                                            className={`${styles['btn-login']}`}
                                            onclick={() => {
                                                sessionStorage.setItem('vogzter', JSON.stringify(offer.owner._id))
                                                navigate('/vogz-tunnel')
                                            }
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </nav>
                        <br />
                    </>
                )}
                <motion.main className={`flex flex-col items-center w-full p-4 justify-center
                ${styles['page-main']} ${utilsMobx.aside_animation_ended ? '!transform-none' : ''
                    }`}>
                    {!isGuest && (<div className=" w-full absolute top-16 left-2 sm:top-4 z-20">
                        <BtnBack btnName={t('mycastings:return')} />
                    </div>)}
                    <div className={`max-w-screen-2xl w-3/4 ${styles['deal-form']}`}>
                        {(!isGuest && userInfos._company.type === 'agency') && (
                            <div className={styles['brand-switcher-container']}>
                                <BrandSwitcher />
                            </div>
                        )}
                        <section className={`relative ${styles['vogzter-section']}`}>
                            <div className={styles['heading']}>
                                <Title1
                                    text={t('new-deal:proposal')}
                                    className="uppercase"
                                    style={{ color: 'black' }}
                                    size={35}
                                />
                                <div className={styles['vogzter-name']}>
                                    {
                                        (!userMobx.offer.data?.owner.first_name && !userMobx.offer.data?.owner.last_name) ? (
                                            <Skeleton height="50px" width="300px" style={{ lineHeight: 'unset' }} />
                                        ) : (
                                            <>
                                                <Title1
                                                    text={`${userMobx.offer.data?.owner.first_name}. ${userMobx.offer.data?.owner.last_name}`}
                                                    style={{ color: 'black' }}
                                                />
                                                <span className="text-xl">#{userMobx.offer.data?.owner.parent_code}</span>
                                            </>
                                        )
                                    }

                                </div>
                            </div>
                            <div className={styles['vogzter-image']}>
                                {
                                    !userMobx.offer.data?.owner?.profile?.gender ? (
                                        <Skeleton circle height={80} width={80} />
                                    ) : (
                                        <VogzterPicture
                                            size={80}
                                            gender={userMobx.offer.data?.owner?.profile?.gender}
                                            imgSource={userMobx.offer.data?.owner?.profile?.picture?.source}
                                            lastActivity={userMobx.offer.data?.owner?.last_activity}
                                            showCertified={userMobx.offer.data?.owner?.certified}
                                            href={`/dashboard/profil-vogzter/${userMobx.offer.data?.owner?._id}`}
                                            removeShadow
                                        />
                                    )
                                }
                            </div>
                        </section>
                        <hr />
                        <section className={styles['offer-section']}>
                            <div className={styles['offer-info']}>
                                {
                                    offer.title === undefined ? (
                                        <Skeleton height="50px" width="300px" style={{ lineHeight: 'unset' }} />
                                    ) : (
                                        <h2>{offer.title}</h2>
                                    )
                                }
                                {
                                    offer.description === undefined ? (
                                        <Skeleton height="50px" width="300px" style={{ lineHeight: 'unset' }} />
                                    ) : (
                                        <SubTitle1
                                            text={offer.description}
                                            className="text-gray-500"
                                        />
                                    )
                                }
                                {
                                    offer.rights?.not_allow !== undefined && offer.rights.not_allow === false && offer.rights?.duration && (
                                        <p className={styles['offer-duration']}>
                                            {t('vogzters:offers.duration')} <span>{formatDuration(offer.rights?.duration ?? 0)}</span>
                                        </p>
                                    )
                                }
                                {
                                    offer.rights?.not_allow !== undefined && offer.rights.not_allow === false && offer.content_usage && (
                                        <p className={styles['offer-duration']}>
                                            {t('vogzters:offers.content-usage')} <span>{offer.content_usage}</span>
                                        </p>
                                    )
                                }
                                <div className={styles['offer-social']}>
                                    {offer.rights?.not_allow !== undefined && offer.rights.not_allow === false && offer.rights.duration && (
                                        <>
                                            <span className={styles['camera']} role="img" aria-label="Camera"> 🎥 </span>
                                        </>
                                    )}
                                    {offer.socials?.map((social, index) => (
                                        <React.Fragment key={index}>
                                            <img src={social.img} alt={social.name} />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            {
                                offer.price === undefined ? (
                                    <Skeleton height="80px" width="300px" style={{ lineHeight: 'unset' }} />
                                ) : (
                                    <div className={styles['offer-price']}>
                                        <Title1
                                            text={t('new-deal:price')}
                                            style={{ color: 'black' }}
                                        />
                                        <Title1
                                            text={`${offer.price}€${offer.type === 'ugc' ? t('vogzters:offers:content') : ''}`}
                                            style={{ color: 'black' }}
                                        />
                                    </div>
                                )
                            }
                        </section>
                        <hr />
                        <FormikProvider value={formik}>
                            <Form id='deal-form' className={styles['form-elements']}>
                                <SectionForm
                                    title={t('form-casting:casting.cards-casting-descbrief.title')}
                                    description={t('form-casting:casting.cards-casting-simple.description-deal-form')}
                                >
                                    <FieldForm<Form1Props> name="description">
                                        {(field, meta, helpers) => {
                                            const { setValue } = helpers
                                            const { value } = field
                                            return (
                                                <>
                                                    <div
                                                        className={` ${styles['section-first-form']} ${styles['desc-brief']} flex-col !items-start !w-full mt-2`}
                                                    >
                                                        <div className={`w-full border rounded-xl border-gray-300`}>
                                                            <textarea
                                                                value={value as CustomBriefI['description']}
                                                                className={`w-full !border-transparent ${styles['text-area-describe-brief']}`}
                                                                onChange={(evt) => {
                                                                    setValue((evt.target as any).value)
                                                                }}
                                                                placeholder={t(
                                                                    'form-casting:casting.cards-casting-descbrief.placeholder-area'
                                                                )}
                                                            />
                                                            <div className={`${styles['word-count']}`}>
                                                                <span>
                                                                    {
                                                                        ((value as CustomBriefI['description']) ?? '')
                                                                            .split(/\s/)
                                                                            .filter((o) => o).length
                                                                    }{' '}
                                                                    {((value as CustomBriefI['description']) ?? '')
                                                                        .split(/\s/)
                                                                        .filter((o) => o).length === 1
                                                                        ? t('Mot')
                                                                        : t('Mots')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {meta.error && (
                                                        <span className="error leading-3 absolute bottom-2.5 right-4">
                                                            {meta.error}
                                                        </span>
                                                    )}
                                                </>
                                            )
                                        }}
                                    </FieldForm>
                                </SectionForm>
                                <hr />
                                <SectionForm
                                    title={t('form-casting:casting.cards-casting-dos.title')}
                                    description={t('form-casting:casting.cards-casting-dos.desc')}
                                >
                                    <FieldForm<Form1Props> name="dos">
                                        {(field, meta, helpers) => {
                                            const { setValue } = helpers
                                            const { value } = field
                                            return (
                                                <>
                                                    <div
                                                        className={`${styles['name-brief']} flex-col !items-start`}
                                                    >
                                                        <div className="mt-2" />
                                                        <div className="w-full flex-col flex lg:">
                                                            {(value as Brief['dos'])?.map((v, i) => (
                                                                <input
                                                                    key={i}
                                                                    className="mt-2"
                                                                    data-input-brief
                                                                    onChange={(evt) => {
                                                                        const brief = (value as Brief['dos'])?.slice()
                                                                        if (brief) {
                                                                            brief[i] = (evt.target as any).value
                                                                            setValue(brief)
                                                                        }
                                                                    }}
                                                                    value={v}
                                                                    placeholder={
                                                                        t(
                                                                            'form-casting:casting.cards-casting-prerequisite.steps-examples',
                                                                            { returnObjects: true }
                                                                        )[i <= 3 ? i : 3]
                                                                    }
                                                                />
                                                            ))}
                                                            <button
                                                                data-btn-add
                                                                className="self-center mt-5"
                                                                type="button"
                                                                onClick={() => {
                                                                    setValue([
                                                                        ...(value as Brief['dos'] ?? []),
                                                                        '',
                                                                    ])
                                                                }}
                                                            >
                                                                <i className="fa-light fa-circle-plus" />
                                                                {'  '}
                                                                {t(
                                                                    'form-casting:casting.cards-casting-prerequisite.btn-add-prerequisite'
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {meta.error && <span>{meta.error}</span>}
                                                </>
                                            )
                                        }}
                                    </FieldForm>
                                </SectionForm>
                                <hr />
                                <SectionForm
                                    title={t('form-casting:casting.cards-casting-donts.title')}
                                    description={t('form-casting:casting.cards-casting-donts.desc')}
                                >
                                    <FieldForm<Form1Props> name="donts">
                                        {(field, meta, helpers) => {
                                            const { setValue } = helpers
                                            const { value } = field
                                            return (
                                                <>
                                                    <div
                                                        className={`${styles['name-brief']} flex-col !items-start`}
                                                    >
                                                        <div className="mt-2" />
                                                        <div className="w-full flex-col flex lg:">
                                                            {(value as Brief['donts'])?.map((v, i) => (
                                                                <input
                                                                    key={i}
                                                                    className="mt-2"
                                                                    data-input-brief
                                                                    onChange={(evt) => {
                                                                        const brief = (value as Brief['donts'])?.slice()
                                                                        if (brief) {
                                                                            brief[i] = (evt.target as any).value
                                                                            setValue(brief)
                                                                        }
                                                                    }}
                                                                    value={v}
                                                                    placeholder={
                                                                        t(
                                                                            'form-casting:casting.cards-casting-prerequisite.steps-examples',
                                                                            { returnObjects: true }
                                                                        )[i <= 3 ? i : 3]
                                                                    }
                                                                />
                                                            ))}
                                                            <button
                                                                data-btn-add
                                                                className="self-center mt-5"
                                                                type="button"
                                                                onClick={() => {
                                                                    setValue([
                                                                        ...(value as Brief['donts'] ?? []),
                                                                        '',
                                                                    ])
                                                                }}
                                                            >
                                                                <i className="fa-light fa-circle-plus" />
                                                                {'  '}
                                                                {t(
                                                                    'form-casting:casting.cards-casting-prerequisite.btn-add-prerequisite'
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {meta.error && <span>{meta.error}</span>}
                                                </>
                                            )
                                        }}
                                    </FieldForm>
                                </SectionForm>
                                <hr />
                                {offer.type === 'influence' && (
                                    <>
                                        <SectionForm
                                            title={t('form-casting:casting.cards-casting-mentions.title')}
                                            description={t('form-casting:casting.cards-casting-mentions.desc')}
                                        >
                                            <FieldForm<Form1Props> name="mentions">
                                                {(field, meta, helpers) => {
                                                    const { setValue } = helpers
                                                    const { value } = field
                                                    return (
                                                        <>
                                                            <div
                                                                className={`${styles['name-brief']} flex flex-col !items-start`}
                                                            >
                                                                <div className="mt-2 flex justify-center align-middle" />
                                                                <div className={`w-full flex-col flex lg: ${styles['mentions-inputs']}`}>
                                                                    {(value as Brief['mentions'])?.map((v, i) => (
                                                                        <input
                                                                            key={i}
                                                                            className="mt-2"
                                                                            data-input-brief
                                                                            onChange={(evt) => {
                                                                                const brief = (value as Brief['mentions'])?.slice()
                                                                                if (brief && (evt.target.value.startsWith('@') || evt.target.value.startsWith('#') || evt.target.value === '')) {
                                                                                    brief[i] = (evt.target as any).value
                                                                                    setValue(brief)
                                                                                }
                                                                            }}
                                                                            value={v}
                                                                            placeholder={
                                                                                t(
                                                                                    'form-casting:casting.cards-casting-mentions.placeholders',
                                                                                    { returnObjects: true }
                                                                                )[i <= 6 ? i : 6]
                                                                            }
                                                                        />
                                                                    ))}
                                                                </div>
                                                                <button
                                                                    data-btn-add
                                                                    className="self-center mt-5 w-full"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setValue([
                                                                            ...(value as Brief['mentions'] ?? []),
                                                                            '',
                                                                        ])
                                                                    }}
                                                                >
                                                                    <i className="fa-light fa-circle-plus" />
                                                                    {'  '}
                                                                    {t(
                                                                        'form-casting:casting.cards-casting-prerequisite.btn-add-prerequisite'
                                                                    )}
                                                                </button>
                                                            </div>
                                                            {meta.error && <span>{meta.error}</span>}
                                                        </>
                                                    )
                                                }}
                                            </FieldForm>
                                        </SectionForm>
                                        <hr />
                                        <SectionForm
                                            title={t('form-casting:casting.cards-casting-redirection.title')}
                                            description={t('form-casting:casting.cards-casting-redirection.desc')}
                                        >
                                            <FieldForm<Form1Props> name="redirection">
                                                {(field, meta, helpers) => {
                                                    const { setValue } = helpers
                                                    const { value } = field
                                                    return (
                                                        <>
                                                            <div
                                                                className={`${styles['name-brief']} flex-col !items-start`}
                                                            >
                                                                <div className="mt-2" />
                                                                <div className="w-full flex-col flex lg:">
                                                                    <input
                                                                        value={value as CustomBriefI['redirection']}
                                                                        data-input-brief
                                                                        className={`w-full mt-2`}
                                                                        onChange={(evt) => {
                                                                            setValue((evt.target as any).value)
                                                                        }}
                                                                        placeholder='Ex : https://www.monlien.fr'
                                                                    />
                                                                </div>
                                                            </div>
                                                            {meta.error && (
                                                                <span className="error leading-3 absolute bottom-2.5 right-4">
                                                                    {meta.error}
                                                                </span>
                                                            )}
                                                        </>
                                                    )
                                                }}
                                            </FieldForm>
                                        </SectionForm>
                                        <hr />
                                    </>
                                )}
                                <SectionForm
                                    title={t('form-casting:casting.cards-casting-inspirations.title')}
                                    description={t('form-casting:casting.cards-casting-inspirations.desc')}
                                    conditions={t(
                                        'form-casting:casting.cards-casting-inspirations.conditions'
                                    )}
                                >
                                    <FieldForm<Form1Props> name="inspiration">
                                        {(field, meta, helpers) => {
                                            const { setValue } = helpers
                                            const { value } = field
                                            return (
                                                <>
                                                    <div
                                                        className={`${styles['content-medias']} mt-4 flex flex-col`}
                                                    >
                                                        {/* media loop */}
                                                        {(value as CustomBriefI['inspiration'])?.media?.map(
                                                            (v, i) => (
                                                                <div
                                                                    key={i}
                                                                    className={`${styles['div-media']} px-2 my-1 flex flex-row`}
                                                                >
                                                                    <i className="fa-solid fa-play" />
                                                                    <p>
                                                                        {v instanceof File
                                                                            ? (v as File).name
                                                                            : split(v.source, '/')[
                                                                            split(v.source, '/')?.length - 1
                                                                            ]}
                                                                    </p>
                                                                    <i
                                                                        className="fa-solid fa-xmark"
                                                                        onClick={() => {
                                                                            if (v instanceof File) {
                                                                                castingFormModel.inspiration = {
                                                                                    media: (value?.media as Array<
                                                                                        File
                                                                                    > | null)!.filter((v, k) => k !== i),
                                                                                }
                                                                                setValue(castingFormModel.inspiration, false)
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div
                                                        className={`${styles['div-content-file']} flex-wrap !items-center`}
                                                    >
                                                        <div className="mt-2" />
                                                        <Dropzone
                                                            accept={{
                                                                'video/*': ['.mp4'],
                                                            }}
                                                            noClick
                                                            onDrop={(acceptedFiles) => {
                                                                const files = {
                                                                    media: (value as CustomBriefI['inspiration'])?.media.slice(),
                                                                }

                                                                files.media!.push(...acceptedFiles)
                                                                setValue(files)
                                                            }}
                                                        >
                                                            {({
                                                                getRootProps,
                                                                getInputProps,
                                                                isDragAccept,
                                                                isDragReject,
                                                            }) => (
                                                                <div
                                                                    className={`${styles['div-input-file']} flex-row  mt-6 flex mr-5 items-center`}
                                                                    {...getRootProps()}
                                                                >
                                                                    <label
                                                                        className={styles['label-file']}
                                                                        htmlFor="input-video"
                                                                    >
                                                                        <i className="fa-solid fa-arrow-down-to-bracket fa-2xl" />
                                                                        <br />
                                                                        <p
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: t(
                                                                                    `form-casting:casting.cards-casting-inspirations.${isDragAccept
                                                                                        ? 'placeholder-drop-here'
                                                                                        : isDragReject
                                                                                            ? 'placeholder-wrong-file'
                                                                                            : 'placeholder'
                                                                                    }`
                                                                                ),
                                                                            }}
                                                                        />
                                                                        <input
                                                                            className="mt-2"
                                                                            data-input-media
                                                                            id="input-video"
                                                                            {...getInputProps()}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </>
                                            )
                                        }}
                                    </FieldForm>
                                </SectionForm>
                                <hr />
                                <SectionForm
                                    title={t('form-casting:casting.cards-casting-script.title')}
                                    description={t('form-casting:casting.cards-casting-script.desc')}
                                    conditions={t('form-casting:casting.cards-casting-script.conditions')}
                                >
                                    <FieldForm<Form1Props> name="script">
                                        {(field, meta, helpers) => {
                                            const { setValue } = helpers
                                            const { value } = field
                                            return (
                                                <>
                                                    <div
                                                        className={`${styles['content-medias']} mt-4 flex flex-col`}
                                                    >
                                                        {/* media loop */}
                                                        {(value as CustomBriefI['script']) && (
                                                            <div
                                                                className={`${styles['div-media']} px-2 my-1 flex flex-row`}
                                                            >
                                                                <i className="fa-solid fa-file-pdf" />
                                                                <p>
                                                                    {value instanceof File
                                                                        ? (value as File).name
                                                                        : split(value as string, '/')[
                                                                        split(value as string, '/').length - 1
                                                                        ]}
                                                                </p>
                                                                <i
                                                                    className="fa-solid fa-xmark"
                                                                    onClick={() => {
                                                                        if (value instanceof File) {
                                                                            castingFormModel.script = undefined
                                                                            setValue(castingFormModel.script)
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div
                                                        className={`${styles['div-content-file']} flex-wrap !items-center`}
                                                    >
                                                        <div className="mt-2" />
                                                        <Dropzone
                                                            accept={{
                                                                'application/pdf': ['.pdf'],
                                                            }}
                                                            multiple={false}
                                                            noClick
                                                            onDrop={(acceptedFiles) => {
                                                                setValue(acceptedFiles[0])
                                                            }}
                                                        >
                                                            {({
                                                                getRootProps,
                                                                getInputProps,
                                                                isDragAccept,
                                                                isDragReject,
                                                            }) => (
                                                                <div
                                                                    className={`${styles['div-input-file']} flex-row  mt-6 flex mr-5 items-center`}
                                                                    {...getRootProps()}
                                                                >
                                                                    <label
                                                                        className={styles['label-file']}
                                                                        htmlFor="input-script"
                                                                    >
                                                                        <i className="fa-solid fa-arrow-down-to-bracket fa-2xl" />
                                                                        <br />
                                                                        <p
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: t(
                                                                                    'form-casting:casting.cards-casting-script.placeholder'
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </label>
                                                                    <input
                                                                        className="mt-2"
                                                                        data-input-media
                                                                        id="input-script"
                                                                        {...getInputProps()}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </>
                                            )
                                        }}
                                    </FieldForm>
                                </SectionForm>
                                <hr />
                                <SectionForm
                                    title={t('form-casting:casting.cards-casting-links.title')}
                                    description={t('form-casting:casting.cards-casting-links.desc')}
                                >
                                    <FieldForm<Form1Props> name="to_checkout">
                                        {(field, meta, helpers) => {
                                            const { setValue } = helpers
                                            const { value } = field
                                            return (
                                                <>
                                                    <div
                                                        className={`${styles['name-brief']} flex-col !items-start`}
                                                    >
                                                        <div className="mt-2" />
                                                        <div className="w-full flex-col flex lg:">
                                                            {(value as Brief['to_checkout']).links?.map((v, i) => (
                                                                <input
                                                                    key={i}
                                                                    className="mt-2"
                                                                    type="url"
                                                                    pattern="https://.*"
                                                                    data-input-brief
                                                                    onChange={(evt) => {
                                                                        const brief = (value as Brief['to_checkout']).links.slice()
                                                                        brief[i].url = (evt.target as any).value
                                                                        setValue({
                                                                            ...(value as Brief['to_checkout']),
                                                                            links: brief,
                                                                        })
                                                                    }}
                                                                    value={(value as Brief['to_checkout']).links[i].url}
                                                                    placeholder={
                                                                        t(
                                                                            'form-casting:casting.cards-casting-links.links-examples',
                                                                            {
                                                                                defaultValue: [],
                                                                                returnObjects: true,
                                                                            }
                                                                        ).at(0)
                                                                    }
                                                                />
                                                            ))}
                                                            <button
                                                                data-btn-add
                                                                className="self-center mt-5"
                                                                type="button"
                                                                onClick={() => {
                                                                    setValue({
                                                                        ...(value as Brief['to_checkout']),
                                                                        links: (value as Brief['to_checkout']).links.concat({ url: '' }),
                                                                    })
                                                                }}
                                                            >
                                                                <i className="fa-light fa-circle-plus" />
                                                                {'  '}
                                                                {t(
                                                                    'form-casting:casting.cards-casting-links.btn-add-link'
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }}
                                    </FieldForm>
                                </SectionForm>
                                <div className={styles['submit-section']}>
                                    <div className={styles['price-recap']}>
                                        <div className={styles["price-total"]}>
                                            <span className={styles["price-total-title"]}>
                                                {t('new-deal:total-price')}
                                            </span>
                                            {
                                                offer.price === undefined ? (
                                                    <Skeleton height="50px" width="300px" style={{ lineHeight: 'unset' }} />
                                                ) : (
                                                    <span className={styles["price-total-value"]}>
                                                        {offer.price}€{offer.type === 'ugc' ? t('vogzters:offers.content') : ''}
                                                    </span>
                                                )
                                            }
                                        </div>
                                        {/* <div className={styles["price-disclaimer"]}>
                                            <p>
                                                {t('new-deal:disclaimer')}
                                            </p>
                                        </div> */}
                                    </div>
                                    <div className={styles['submit-btn']}>
                                        <BtnBasic
                                            btnName={t('new-deal:send-deal')}
                                            type="sky-violet-bordered"
                                            disabled={formik.isSubmitting}
                                            typeBtn='submit'
                                        />
                                    </div>
                                </div>
                            </Form>
                        </FormikProvider>
                    </div>
                </motion.main>
            </>
        )
    }

}

export default observer(NewDeal)