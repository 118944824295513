/* eslint-disable react/prop-types */

import { Link } from "react-router-dom";
import React, { FC } from "react";
import styles from "./components/btn.module.scss";


interface BtnProps {
  title: string;
  onClick?: () => void
  className?: string;
  mainColor?: "btn-white" | "btn-violet";
  typeBtn?: "button" | "link";
  href?: string;
  disabled?: boolean;
  loading?: boolean;
}

const BtnCustom: FC<BtnProps> = ({
  title,
  onClick,
  mainColor,
  className,
  typeBtn,
  href,
  disabled
}) => {
  return (
    <div className={`${styles["btn"]} ${styles[mainColor ?? "btn-violet"]} ${className ?? ""} ${(disabled) ? styles["disabled"] : ''}`}
      onClick={() => {
        if (disabled) return
        onClick?.()
      }}
    >
      <div className={`flex justify-center`}>
        <Link to={(disabled) ? "#" : href ?? "#"}>
          {title}
        </Link>
      </div>
    </div>
  );
};

export default BtnCustom;
