/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import { TFunction } from 'i18next'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import styles from './styles.module.scss'
import SubTitle1 from '../../../utils/components/Headings/SubTitle1'
import briefR from '../../../mobx/brief'
import BtnBasic from '../../../utils/components/Button/BtnBasic'
import { MOTION_VARIANTS } from '../../../utils/helpers'
import userMobx from '../../../mobx/user'
import { Vogzter } from '../../../types-project/Client'
import BrandSwitcher from '../../../utils/components/BrandSwitcher/BrandSwitcher'
import modal from '../../../mobx/modal'
import BtnBack from '../../../utils/components/Button/BtnBack'

export default function NewCasting(props: any) {
  const options = [
    {
      title: 'Casting Public',
      illustration: '/assets/imgs/group-people.png',
      icon: '/assets/imgs/icon-cinema.png',
    },
    {
      title: 'Casting Clients',
      illustration: '/assets/imgs/casting-client.png',
      icon: '/assets/imgs/icon-bag.png',
    },
    {
      title: 'Deal Privé',
      illustration: '/assets/imgs/casting-prive.png',
      icon: '/assets/imgs/icon-lock.png',
    },
  ]

  const navigate = useNavigate()

  const [option, setOptions] = useState<boolean>(false)
  useEffect(() => {
    userMobx.getFavouriteVogzters()
    briefR.getBriefs()
  }, [])

  const favouriteVogzters = userMobx.brand.data?.favourites as Array<Vogzter>
  // translation
  const { t } = useTranslation()
  const promptAddFavorites = () => {
    modal.toogleAlert('no-favorite-vogzter', true)
    modal.setOnlyOneCTA(true, t('new-casting:modal-private-deal:btn-title'))
    modal.setOnConfirm(() => {
      navigate('/dashboard/favorites-vogzters')
    })
  }
  const company = userMobx.me.data?._company
  const isCastingOffered = company?.offered_casting_count === 1 && briefR.briefs.data?.length === 0

  const [hoveredStarter, setHoveredStarter] = useState<'casting' | 'deal' | null>()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Nouveau casting</title>
      </Helmet>
      <BrandSwitcher />
      <main>
        <BtnBack className='w1200:ml-14 w1200:mt-14' btnName={t('common:back')} />
        <motion.header
          className='flex flex-col items-center sm:mt-5'
          initial={{ opacity: 0, x: '-800px' }}
          exit={{ opacity: 0, x: '-800px' }}
          transition={{ duration: 1 }}
          animate={{ x: 0, opacity: 1 }}
        >
          <h3 className='text-2xl font-hurmeBold text-center'>{t('new-casting:launch-casting')}</h3>
          <SubTitle1
            className='max-w-sm text-center leading-4'
            text={t('new-casting:choosing-best')}
          />
        </motion.header>
        <br />
        <br />
        <motion.section
          className='flex flex-wrap justify-center gap-12'
          initial={{ opacity: 0, x: '-800px' }}
          exit={{ opacity: 0, x: '-800px' }}
          transition={{ duration: 1.5 }}
          animate={{ x: 0, opacity: 1 }}
        >
          <StartCollab 
            className={hoveredStarter === 'casting' ? 'opacity-50' : ''} 
            collabType="casting"
            setHovered={setHoveredStarter}
            t={t} 
          />
          <StartCollab
            className={hoveredStarter === 'deal' ? 'opacity-50' : ''} 
            collabType="deal"
            setHovered={setHoveredStarter}
            t={t} 
          />
        </motion.section>
      </main>
    </>
  )
}

interface StartCollabProps {
  collabType: 'casting' | 'deal'
  t: TFunction
  className?: string
  setHovered?: (collabType: 'casting' | 'deal' | null) => void
}

const StartCollab: FC<StartCollabProps> = observer(({ collabType, t, className, setHovered }) => {

  const navigate = useNavigate()
  const company = userMobx.me.data?._company

  const headerImg = collabType === 'casting' ? '/assets/imgs/icon-cinema.png' : '/assets/imgs/icon-lock.png'
  const isCastingOffered = collabType === "casting" && (company?.offered_casting_count === 1 && briefR.briefs.data?.length === 0)
  const isPriceCrossedOut = collabType === 'casting' && ((company?.offered_casting_count ?? -1) > 0) 
  const oppositeCollabType = collabType === 'casting' ? 'deal' : 'casting';

  return (
    <article 
      className={`${styles['start-collab']} ${styles[collabType]} ${className ?? ''}`}
      onMouseEnter={() => setHovered && setHovered(oppositeCollabType)}
      onMouseLeave={() => setHovered && setHovered(null)}
    >
      <header>
        <img src={headerImg} alt="" />
        {isCastingOffered && (
          <div>
           {t('new-casting:casting-offered')}
          </div>
        )}
      </header>
      <section>
        <h1>
          {collabType === 'casting' ? t('new-casting:campagne') : t('new-casting:deal')}
          {/* <span 
            className={`ml-2 ${isPriceCrossedOut ? 'line-through' : ''}`}
          >
            {collabType === 'casting' ? '(200€)' :null}
          </span> */}
        </h1>
        {collabType === 'casting' ? (
          <p>
            {t('new-casting:community-vogzter1')}{' '}
            <b>15 000 Vogzters</b>{' '}
            {t('new-casting:community-vogzter2')}
          </p>
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: t('new-casting:private-casting-desc'),
            }}
          />
        )}
      </section>
      <footer>
        <BtnBasic
          btnName={t('new-casting:select')}
          type='inverted-sky-violet'
          onclick={() => {
            if (collabType === 'casting') 
              navigate(`/dashboard/new-castings/creator`)
            else 
              navigate(`/dashboard/favorites-vogzters`)
          }}
          />
      </footer>
    </article>
  )
})
