/* eslint-disable react/prop-types */
import React, { FC, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { gsap } from 'gsap'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styles from './components/card-styles.module.scss'

import briefR from '../../../../../mobx/brief'
import modal from '../../../../../mobx/modal'
import { countDown, displayAvatar, getAvatar, truncate } from '../../../../../utils/helpers'
import { MediaCastings, MyCasting } from '../../../../../types-project/Brief'
import userMobx from '../../../../../mobx/user'
import { TypeClient, TypeCompanyRole } from '../../../../../types-project/Client'

const CardVogzClient: FC<CardVogzProps> = ({
  briefApplication,
  media
}) => {
  const [mouseEnter, setmouseEnter] = useState(false)

  // const [confirm, setConfirm] = useState<"confirmed" | "to-confirm" | "applied">(briefApplication.status === "applied" ? "applied" : "confirmed")

  const confirm = briefApplication!.status

  const { data: brief, loading, error } = briefR.brief

  // handle navigation from our router
  const navigate = useNavigate()
  // translation
  const { t } = useTranslation()

  const accept = media.in_basket
  // ref of buttons for sliding
  const btn1Ref = useRef()
  const btn2Ref = useRef()
  // ref of overlay to animate
  const overlayRef = useRef()
  const name = briefApplication!?.applicant.first_name
  const firstName = briefApplication!?.applicant.last_name
  const nameShort =
    name && firstName ? `${name}.${firstName.substring(0, 1)}` : 'Name.P'

  const handleRefuseBtn = () => {
    if (confirm === 'applied') {
      gsap.to(btn1Ref.current!, { x: 145, duration: 0.2 })
      gsap
        .to(btn2Ref.current!, {
          x: -145,
          flexDirection: 'row-reverse',
          duration: 0.2,
        })
        .eventCallback('onComplete', () => {
          briefR.briefApplication.data!.find(
            (ba) => ba._id === briefApplication!._id
          )!.status = 'step2'
          gsap.to(overlayRef.current!, { scale: 1, duration: 0.2 })
        })
    } else if (confirm === 'step2') {
      briefR.basketOrDecline(briefApplication!, 'decline').then((v) => {
        if (v === undefined) {
          briefR.briefApplication.data!.find(
            (ba) => ba._id === briefApplication!._id
          )!.status = 'lost'
          gsap.to(overlayRef.current!, { scale: 0, duration: 0.2 })
          gsap.to(btn1Ref.current!, { x: 0, duration: 0 })
        }
      })
    }
  }

  const handleAcceptBtn = () => {
    if (confirm === 'step2') {
      gsap.to(btn1Ref.current!, { x: 0, duration: 0.2 })
      gsap
        .to(btn2Ref!.current!, { x: 0, flexDirection: 'row', duration: 0.2 })
        .eventCallback('onComplete', () => {
          briefR.briefApplication.data!.find(
            (ba) => ba._id === briefApplication!._id
          )!.status = 'applied'
          gsap.to(overlayRef.current!, { scale: 0, duration: 0.2 })
        })
    } else if (!accept) {
      briefR.basketOrDecline(briefApplication!, 'validate')
    }
  }

  return (
    <article
      className={`${styles['card-vogz-client']} ${briefApplication?.brief?.exclusive?.is_exclusive
          ? styles['card-vogz-exclusive']
          : ''
        }`}
    >
      {!loading && !error && brief?.exclusive?.is_exclusive && (
        <div>
          <aside className={styles['card-applicant-exclusif']}>
            <figure>
              {displayAvatar(
                briefApplication!?.applicant?.profile?.gender,
                briefApplication!?.applicant?.profile?.picture?.source,
                `briefapplication/${briefApplication?._id}`
              )}
            </figure>
            <div className={styles['card-desc-briefapplication']}>
              <h3>{truncate(nameShort, 15)}</h3>
              {!userMobx.me.error &&
                !userMobx.me.loading &&
                userMobx.me.data?.company_role !== TypeCompanyRole.GUEST && (
                  <button
                    type="button"
                    onClick={() =>
                      navigate(
                        `/dashboard/${briefApplication!?.brief._id
                        }/briefapplication/${briefApplication!?._id}`
                      )
                    }
                  >
                    {t('mycastings:view-profile')}
                  </button>
                )}
            </div>
          </aside>{' '}
        </div>
      )}
      <h2 className={styles['countdown-vogz-client']}>
        {countDown(new Date(briefApplication!.expiresAt!))}
      </h2>
      <div
        onMouseEnter={() => setmouseEnter(!mouseEnter)}
        onMouseLeave={() => setmouseEnter(!mouseEnter)}
      >
        {accept && (
          <button
            type="button"
            onClick={() => briefR.addToBasket(media?._id)}
            className={styles['cancel-choice']}
          >
            <span className={styles['info-cancel']}>Annuler mon choix</span>
            <i className="fa-solid fa-circle-x" />
          </button>
        )}
        <div
          className={`${styles['card-body']} ${briefApplication?.brief?.exclusive?.is_exclusive
              ? styles['exclusive']
              : ''
            }`}
        >
          <span>#{media?.readable_id}</span>
          <div ref={overlayRef as any} className={styles['card-overlay']}>
            <span>
              Êtes-vous certains de vouloir refuser cette <strong>VOGZ</strong>{' '}
              ?
            </span>
          </div>
          {!media?.seen && (
            <motion.span
              style={{
                bottom:
                  media?.step === 2 || !briefApplication?.brief?.with_product
                    ? 20
                    : 'unset',
              }}
              initial={{ opacity: 0, x: -60, y: 30 }}
              transition={{ delay: 2 }}
              animate={{ opacity: 1, y: 0, x: 0 }}
              className={styles['badge-red']}
            >
              NEW
            </motion.span>
          )}

          <img
            alt="video"
            onClick={() => {
              briefR.setSeenMediaFromBA(briefApplication?._id!, media._id)
              modal.toogleModalVideo(media.source, true)
            }}
            src={'/assets/svgs/video-play.svg'}
          />
          <img
            src={mouseEnter ? media?.gif : media?.thumbnail}
            alt="thumbnail"
          />
        </div>
        <div className={styles['card-footer']}>
          {new Date(briefApplication!.expiresAt!).getTime() >=
            new Date().getTime() ? (
            <>
              <button
                type="button"
                ref={btn1Ref as any}
                onClick={() => handleRefuseBtn()}
                className={`${styles['btn-rfs']} ${accept ? styles['not-visible'] : ''
                  }   ${styles[confirm]}`}
              >
                {confirm === 'step2'
                  ? 'Confirmer'
                  : confirm === 'applied'
                    ? 'Refuser'
                    : 'Refusée'}{' '}
                <i className="fa-solid fa-circle-x" />
              </button>
              <button
                type="button"
                disabled={accept}
                ref={btn2Ref as any}
                onClick={() => handleAcceptBtn()}
                className={`${styles['btn-vlt']} ${accept
                    ? styles['access']
                    : confirm === 'lost'
                      ? styles['not-visible']
                      : ''
                  } ${styles[confirm]}`}
              >
                {accept
                  ? 'Validé'
                  : confirm !== 'step2'
                    ? 'Valider'
                    : 'Annuler'}{' '}
                {confirm !== 'step2' && (
                  <i className="fa-solid fa-circle-check" />
                )}
              </button>
            </>
          ) : (
            <button
              type="button"
              disabled
              className={`${styles['btn-rfs']} ${accept ? styles['not-visible'] : ''
                }   ${styles['lost']}`}
            >
              Temps écoulé <i className="fa-solid fa-circle-x" />
            </button>
          )}
        </div>
      </div>
    </article>
  )
}

interface CardVogzProps {
  media: MediaCastings
  briefApplication?: MyCasting
}

export default observer(CardVogzClient)
