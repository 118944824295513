import { Heading, Box, Text } from 'grommet'
import React from 'react'

export default function CGVPage() {
  return (
    <main style={{ height: '100%', overflow: 'scroll', margin: "0px 20px", padding: "20px 0px" }}>
      <p style={{ textAlign: 'center' }}>
        <strong>CONDITIONS GÉNÉRALES D'UTILISATION ANNONCEURS</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>Version au 16 Juillet 2021</strong>
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>DÉFINITIONS</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Pour les besoins des présentes conditions générales d’utilisation, les
        termes suivants ont la définition ci-après mentionnée, d’autres termes
        pouvant par ailleurs être définis :
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Annonceur</strong>&nbsp;»&nbsp;: désigne toute personne
        physique ou morale agissant à titre professionnel au sens du Code de la
        Consommation et sollicitant la Société pour obtenir la création de
        Contenus par les Vogzters.
      </p>
      <p style={{ textAlign: 'justify' }}>
        «<strong>&nbsp;Application</strong>»&nbsp;: désigne l’application mobile
        Wardogz, propriété de la Société et hébergée par Vercel, permettant à
        l’Annonceur d’acquérir des Contenus.
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Brief(s)</strong>&nbsp;»&nbsp;: désigne les critères
        exigés par l’Annonceur pour la création de Contenus par un Vogzer dans
        le cadre d’un Casting.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Casting(s)</strong> »&nbsp;: désigne tout concours
        proposé par un Annonceur sur l’Application dans le but de sélectionner
        un Vogzter chargé par la suite de créer du Contenu faisant la promotion
        d’un produit ou d’un service pour le compte de l’Annonceur.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>«&nbsp;Compte Annonceur »</strong>&nbsp;: désigne l’espace
        personnel réservé à l’Annonceur inscrit sur l’Application lui permettant
        notamment de publier les Briefs, créer les Castings, échanger avec les
        Vogzters et sélectionner des Contenus.
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Conditions Générales d’Utilisation</strong>&nbsp;» ou
        «&nbsp;<strong>CGU</strong>&nbsp;»&nbsp;: désignent l’ensemble des
        stipulations prévues par les présentes ayant pour objet de définir et
        d’encadrer les modalités et conditions d’utilisation de l’Application
        par les Annonceurs.
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Contenu(s)</strong>&nbsp;» ou «&nbsp;
        <strong>Vogz(s)</strong>&nbsp;»&nbsp;: désigne tout contenu digital sous
        quelque forme que ce soit téléchargé par le Vogzter sur l’Application
        puis proposé à l’Annonceur dans le cadre de sa participation à un
        Casting, en ce compris les Créations.
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Création(s)</strong>&nbsp;» : désigne l’ouvrage final
        créé par le Vogzter, choisi par l’Annonceur à l’issu du Casting et
        faisant la promotion pour le compte de l’Annonceur de son produit ou
        service.
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Devis</strong>&nbsp;»&nbsp;: désigne la facture
        prévisionnelle adressée par la Société à l’Annonceur et acceptée par
        lui, précisant notamment le prix du service, le nombre de Casting
        prévus, leur durée et le nombre de Contenus pouvant être retenus.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Partie(s)</strong>&nbsp;»&nbsp;: désigne individuellement
        ou collectivement la Société et/ou l’Annonceur.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Société</strong>&nbsp;»&nbsp;: désigne la société{' '}
        <strong>WARDOGZ, </strong>société par actions simplifiée au capital
        social de 1.000 euros, dont le siège social est situé 73, boulevard
        Victor Hugo - 92200 Neuilly-sur-Seine, immatriculée au registre du
        commerce et des sociétés de Nanterre sous le numéro 825&nbsp;106 248,
        représentée par Alan GHENASSIA.
      </p>
      <p style={{ textAlign: 'justify' }}>
        «&nbsp;<strong>Vogzter(s)</strong>&nbsp;»&nbsp;: désigne toute personne
        majeure utilisant l’Application afin de participer à des Castings, soit
        disposant d’un numéro SIRET et inscrite au Registre du Commerce et des
        Sociétés, au Répertoire des Métiers, à la Maison des Artistes, à
        l’Agessa, ayant le statut de micro-entrepreneur, soit exerçant en tant
        qu’indépendant dans des conditions légales depuis la France ou
        l’étranger et qui propose ses services.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>OBJET</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Les CGU ont pour objet de définir et d’encadrer les modalités
        d’utilisation de l’Application par l’Annonceur. Les CGU peuvent être
        consultées à tout moment sur l’Application.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Elles prévalent sur toute clause contraire figurant sur les documents ou
        la correspondance existants entre les Parties, sous réserve
        d’éventuelles conditions particulières conclues selon le Devis.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>ACCÈS À L’APPLICATION</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        L’accès à l’Application est préalablement soumis à l’acceptation par
        l’Annonceur, d’une part, des CGU et, d’autre part, du Devis établi entre
        les Parties.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Il est expressément convenu que toute utilisation de l’Application et
        tout accès à l'un quelconque de ses Contenus effectués, à quelque titre
        que ce soit, implique obligatoirement l’acceptation sans réserve et le
        respect, par l’Annonceur, des Conditions Générales. L’Annonceur déclare
        en conséquence avoir lu et pris connaissance des Conditions Générales.
      </p>
      <p style={{ textAlign: 'justify' }}>
        En cas de réserve ou de contestation sur tout ou partie des dispositions
        des Conditions Générales, l’Annonceur reconnaît qu’il n’aura plus droit
        d'accéder à l’Application.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Chaque Annonceur garantit agir en tant que professionnel tel que défini
        dans l’article liminaire du Code de la consommation.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Pour s’enregistrer sur l’Application, l’Annonceur doit créer un Compte
        Annonceur en suivant les étapes prévues sur l’Application.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Une fois le Compte Annonceur créé, l’Annonceur se connectera à
        l’Application à l’aide d’un identifiant et d’un mot de passe défini par
        lui.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Il est rappelé que l’identifiant de connexion et le mot de passe ont un
        caractère strictement confidentiel et personnel. L’Annonceur s’engage à
        cet égard à prendre toutes les dispositions nécessaires pour assurer
        leur confidentialité et ne pas les divulguer à des tiers.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Dans le cas où l’Annonceur remarquerait une utilisation anormale ou
        frauduleuse de ses informations de connexion, il devra en avertir la
        Société dans les plus brefs délais.&nbsp;
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>ETABLISSEMENT DU DEVIS</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        La Société propose à l’Annonceur un Devis, qui devra être accepté par
        lui aux fins d’accéder à l’Application.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Le Devis fera notamment mention du prix du service, du nombre de
        Castings envisagés par l’Annonceur, de leur durée, du nombre de Contenus
        pouvant être sélectionnés par lui, etc.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Un devis complémentaire pourra être établi entre les Parties en fonction
        des demandes et besoins spécifiques de l’Annonceur. A titre d’exemple,
        fera l’objet d’un devis complémentaire la sélection par l’Annonceur d’un
        nombre de Contenus supérieurs à celui arrêté dans le Devis initial.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Les documents contractuels sont, par ordre de priorité
        décroissante&nbsp;:&nbsp;
      </p>
      <ul>
        <li>Le Devis et ses éventuels avenants,</li>
        <li>Les Conditions Générales.&nbsp;</li>
      </ul>
      <p style={{ textAlign: 'justify' }}>
        En cas de contradiction entre des documents de nature différente et/ou
        de rang différent, il est expressément convenu que les dispositions
        contenues dans le document de rang supérieur prévaudront, et que les
        annexes prévaudront sur les documents auxquels ils sont joints.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Toute modification du Devis devra faire l'objet d'un avenant signé par
        les deux parties ou de l’émission d’un nouveau Devis.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>CASTINGS</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <em>5.1 Création d’un Casting</em>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur propose à des Vogzters de participer à des Castings aux fins
        de présenter un Contenu faisant la promotion, pour le compte de
        l’Annonceur, d’un produit ou d’un service. Il publie sur l’Application
        une annonce faisant mention du Brief qui s’imposera aux Vogzters.
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur peut limiter l’accès d’un Vogzter à un Casting en fonction
        des exigences énumérées dans le Brief. A titre d’exemples, pourront être
        retenus par l’Annonceur des critères fondés sur l’âge du Vogzter, sur
        son sexe, sur sa notoriété, etc.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur reste seul responsable des critères choisis, qui ne devront
        se montrer ni injustifiés ni discriminatoires, et de leurs implications
        sur les Vogzters.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Les Vogzters répondant aux critères imposés par le Brief pour un Casting
        peuvent participer à celui-ci en proposant un Contenu.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <em>5.2 Déroulement du Casting</em>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        En participant au Casting, le Vogzter doit obligatoirement intégrer à sa
        candidature un Contenu digital, sous forme de piste vidéo, conforme au
        Brief contenant le projet créatif qu’il a élaboré.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Pendant toute la durée du Casting telle que prévue dans le Devis, et
        jusqu’au moment où l’Annonceur sélectionnera la Vogz retenue,
        l’Annonceur dispose d’un accès illimité, directement sur son Compte
        Annonceur, aux Contenus envoyés par les Vogzters candidats audit
        Casting.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Une fois que le Vogzter a confirmé sa participation au Casting, et
        pendant toute la durée de celui-ci, le Vogzter et l’Annonceur peuvent
        entrer en relation directe <em>via </em>l’Application.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Les conversations entre l’Annonceur et le Vogzter doivent se limiter,
        notamment, à des échanges sur les exigences du Brief, sur les modalités
        du Casting, sur le Contenu proposé par le Vogzter. Elles ne revêtent
        aucun caractère confidentiel en ce que la Société dispose d’un droit de
        regard sur ces dernières.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Lors du déroulement du Casting, la Société se réserve le droit de
        modifier, corriger ou supprimer les Contenus envoyés à l’Annonceur,
        notamment lorsqu’ils contreviennent aux termes des présentes CGU.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <em>5.3 Durée des Castings</em>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        Les Castings ont une durée moyenne de six (6) à dix (10) jours. La durée
        du Casting est prévue au Devis. Au-delà de cette durée, le Casting
        pourra être prolongé autant de fois que l’exigeront les besoins de
        l’Annonceur pour effectuer sa sélection, moyennant rémunération de cent
        cinquante euros (150€) HT pour quatre (4) jours
        supplémentaires.&nbsp;&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Une fois le Casting clos, l’Annonceur dispose de deux (2) jours pour
        déterminer la Vogz gagnante. Cette durée pourra être prolongée sans
        limite moyennant rémunération de cent cinquante euros (150€) HT pour
        quatre (4) jours supplémentaires.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <em>5.4 Sélection des Contenus</em>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        A l’issu du Casting, l’Annonceur sélectionne le Contenu ou les Contenus
        proposé(s) par les Vogzters, à raison du nombre définis par le Devis.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La sélection du ou des Contenus se fait par l’Annonceur à son entière
        discrétion. Tout Contenu sélectionné au-delà du nombre de Contenus prévu
        dans le Devis, fera l’objet d’une facturation supplémentaire déterminée
        par un devis ultérieur.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur peut également choisir de ne retenir aucun Contenu pour le
        Casting. Les sommes visées au Devis resteront intégralement dues, sous
        réserve de la souscription de la garantie visée à l’article 5.5.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <em>5.5 Garantie «&nbsp;Satisfait ou Remboursé&nbsp;»&nbsp;</em>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        La garantie «&nbsp;Satisfait ou Remboursé&nbsp;» au profit de
        l’Annonceur permet à ce dernier, s’il ne sélectionne à l’issu du Casting
        aucune Vogz, d’être remboursé du prix des Contenus commandés suivant le
        Devis initial établi entre les Parties, à l’exception des frais de
        lancement du Casting, à hauteur de cent-cinquante euros (150€) HT, qui
        sont définitivement acquis par la Société.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La souscription par l’Annonceur de la garantie «&nbsp;Satisfait ou
        Remboursé&nbsp;» apparaît dans le Brief du Casting et est visible par
        les Vogzters.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <em>5.6 Réalisation de la Création finale</em>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        Le Vogzter, dont le Contenu a été sélectionné par l’Annonceur à l’issu
        du Casting, se verra envoyer le produit sur lequel devra porter la
        Création ou recevra des indications sur le service dont il devra faire
        la promotion.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Il s’engage alors à produire une Création finale, mettant en avant le
        produit et/ou le service que l’Annonceur souhaite promouvoir
        conformément au projet créatif prévu dans le Contenu
        sélectionné.&nbsp;&nbsp;&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Une fois la Création livrée, la Société se chargera de faire parvenir à
        l’Annonceur le contrat de cession de droits de propriété intellectuelle
        par le Vogzter sur la Création.&nbsp;
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>CONDITIONS FINANCIERES</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Les conditions financières ainsi que le prix du S-service, objet des
        Conditions Générales, est défini dans le Devis.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Il est entendu entre les Parties que les conditions financières se
        composent d’une part des frais de création d’un Casting et d’autre part
        de l’acquisition de Contenus.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Il est indiqué que les frais de création d’un Casting seront facturés à
        l’Annonceur lors de la signature du Devis et sont définitivement acquis
        par la Société.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La facturation du service est indépendante des facturations liées aux
        abonnements et contrats&nbsp;; aussi, l’Annonceur fait son affaire
        personnelle de la négociation des tarifs relatifs notamment aux réseaux
        et services de télécommunications éventuellement nécessaires pour
        visualiser les résultats des traitements.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Le défaut de paiement à l’échéance de chacune des factures émises par la
        Société entraînera, sans mise en demeure préalable, l’exigibilité
        d’intérêt de retard au taux de trois fois (3x) le taux de l’intérêt
        légal et ce, nonobstant les dispositions suivantes&nbsp;:
      </p>
      <ul>
        <li>
          L’exigibilité de l’intégralité des sommes dues en principal et
          intérêts&nbsp;;
        </li>
        <li>
          La suspension du service jusqu’à parfait paiement de la totalité des
          sommes dues&nbsp;;
        </li>
        <li>
          Le paiement d’une indemnité forfaitaire de 40 euros pour frais de
          recouvrement.
        </li>
      </ul>
      <p style={{ textAlign: 'justify' }}>
        Le service est facturé en euros hors taxes, le taux de TVA applicable
        étant celui en vigueur au jour de la facturation, étant précisé que les
        modalités de paiement figurent sur le Devis.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>OBLIGATIONS ET ENGAGEMENTS DES ANNONCEURS</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Dans le cadre de l’utilisation de l’Application, l’Annonceur s’engage à
        se conformer aux lois et règlements en vigueur, à respecter les droits
        des tiers et les dispositions des CGU.
      </p>
      <ol>
        <li>
          <strong>
            <em>Obligations générales</em>
          </strong>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Chaque Annonceur a pour obligation de&nbsp;:
      </p>
      <ul>
        <li>
          fournir des informations exactes, sincères, à jour et complètes lors
          de la création du Compte Annonceur,
        </li>
        <li>
          mettre à jour sans délai les informations renseignées lors de la
          création d’un Compte Annonceur si celles-ci venaient à évoluer,
        </li>
        <li>
          ne pas détourner la finalité de l’Application pour commettre
          directement ou indirectement des crimes, délits ou contraventions
          réprimés par la loi,
        </li>
        <li>
          ne pas utiliser les informations présentes sur l’Application en vue de
          leur utilisation à quelque titre que ce soit par une société
          concurrente de la Société ou pour créer un service similaire,
        </li>
        <li>ne pas dénigrer l’Application ou la Société,</li>
        <li>
          ne pas tenter de détourner les internautes vers un concurrent, et de
          manière plus générale, et&nbsp;
        </li>
        <li>utiliser l’Application conformément à sa finalité.</li>
      </ul>
      <p style={{ textAlign: 'justify' }}>
        La Société se réserve le droit, à tout moment et à son entière
        discrétion, de suspendre ou de supprimer l’accès à l’Application à un
        Annonceur et de prendre toutes les mesures contre ce dernier, si la
        Société considère que cet Annonceur ne respecte pas les CGU.
      </p>
      <p style={{ textAlign: 'justify' }}>
        S’agissant des Annonceurs domiciliés en dehors de France, ceux-ci sont
        également tenus de respecter toutes les obligations leur incombant en
        vertu de la législation applicable à leur statut dans leur pays de
        résidence, ainsi que les obligations découlant de leur utilisation de
        l’Application.
      </p>
      <ol>
        <li>
          <strong>
            <em>Engagements spécifiques de l’Annonceur</em>
          </strong>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur s’engage à&nbsp;:&nbsp;
      </p>
      <ul>
        <li>
          ne pas demander les coordonnées personnelles du Vogzter pour échanger
          en dehors de l’Application, et
        </li>
      </ul>
      <ul>
        <li>
          ne pas exploiter ou partager, sous quelque forme que ce soit, à des
          fins autres que celles prévues par les CGU les Contenus envoyés par
          les Vogzters.
        </li>
      </ul>
      <ol>
        <li>
          <strong>
            <em>Utilisation illicite ou interdite</em>
          </strong>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur ne peut utiliser l’Application qu’à des fins licites et
        autorisées par les CGU. Cette utilisation ne devra contrevenir à aucune
        règlementation en vigueur applicable. Notamment les Castings et Briefs
        ne pourront être proposés que des produits et services licites et ne
        contrevenant à aucun droit de tiers.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur accepte également de dédommager intégralement la Société
        pour tout dommage, perte, coût ou frais qu’elle encourt, y compris des
        frais juridiques, découlant de l’utilisation par l’Annonceur de
        l’Application à des fins illicites ou interdites.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur ne peut utiliser l’Application d’une manière qui pourrait
        endommager, désactiver, surcharger ou altérer l’Application. Il lui est
        également interdit d’interférer avec l’utilisation et/ou la jouissance
        de l’Application par toute autre partie. En outre, l’Annonceur ne peut
        pas sonder, analyser, tester la vulnérabilité ou enfreindre les mesures
        de sécurité ou d’authentification de l’Application. L’Annonceur ne peut
        pas obtenir ou tenter d’obtenir par quelconque moyen des éléments ou des
        informations non intentionnellement mis à sa disposition par la Société
        ou fournis par le biais de l’Application.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Société se réserve le droit, à tout moment, de divulguer les
        informations nécessaires pour se conformer à une loi, réglementation,
        procédure légale ou demande émanant des pouvoirs publics. La qualité
        d’information dite nécessaire est soumise à la seule appréciation de la
        Société.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Société peut, de plein droit et à son entière discrétion, modifier,
        refuser de mettre en ligne ou supprimer tout élément ou information
        publié par l’Annonceur sur l’Application.&nbsp;
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>ENTRÉE EN VIGUEUR - DURÉE</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Les Conditions Générales entrent en vigueur à compter de leur signature
        au jour de la signature du Devis par l’Annonceur. Elles sont conclues
        pour la durée figurant au Devis.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>TERME DU CONTRAT - RÉSILIATION</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Les Conditions Générales prennent fin à l’expiration du terme
        contractuel visé dans le Devis.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Tout renouvellement ou extension des services nécessite un nouveau
        Devis.
      </p>
      <p style={{ textAlign: 'justify' }}>
        En cas de manquement par l'une des Parties aux obligations des CGU, non
        réparé dans un délai de dix (10) jours calendaires à compter de l'envoi
        d'une notification écrite indiquant le manquement en cause, l'autre
        Partie pourra faire valoir immédiatement et de plein droit la
        résiliation des Conditions Générales, sans préjudice de tous dommages et
        intérêts auxquels elle pourrait prétendre en vertu des présentes.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La résiliation des CGU entraine automatiquement la résiliation du Devis
        en vigueur.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>MODIFICATION DES CGU ET IMPREVISION</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        La Société peut être amenée à faire évoluer les CGU à tout moment.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Toute modification majeure des CGU et des droits des Annonceurs sera
        soumise à une acceptation expresse des CGU renouvelées de la part de
        l’Annonceur, qui fera l’objet d’une notification électronique et
        préalable à toute poursuite de l’utilisation de l’Application.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Chacune des Parties déclare irrévocablement écarter l’application des
        dispositions de l’article 1195 du Code civil à ses obligations au titre
        des CGU. Par conséquent, chacune des Parties reconnait que les CGU ne
        seront pas susceptibles de révision ou de résiliation judiciaire du fait
        d’un changement de circonstances imprévisibles lors de sa conclusion,
        rendant son exécution excessivement onéreuse pour l’une ou l’autre des
        Parties.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>RESPONSABILITÉ</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <em>10.1 Responsabilité de la Société&nbsp;</em>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Société est soumise à une obligation de moyens pour l’exécution de
        ses obligations contractuelles.
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur reconnaît avoir été en mesure d'évaluer au stade de
        l’inscription sur l’Application son engagement contractuel vis-à-vis de
        la Société, les capacités de l’Application et les spécificités de son
        utilisation. L’Annonceur ne pourra exiger la mise en place de nouveaux
        services, nouvelles fonctionnalités, ou évolutions.
      </p>
      <p style={{ textAlign: 'justify' }}>
        En conséquence, la responsabilité de la Société ne peut être engagée
        qu’en cas de faute de celle-ci prouvée par l’Annonceur.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Société ne saurait être tenue pour responsable de quelque façon que
        ce soit, des infractions aux obligations lui incombant, notamment dans
        le cadre de la lutte contre le travail dissimulé et du respect des
        règles de transparence, ainsi que des dommages pouvant en résulter.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Ainsi, la Société ne saurait être tenue pour responsable des
        informations fausses, trompeuses ou non à jour qui lui sont communiquées
        par l’Annonceur.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Société ne saurait être tenue responsable des manquements à des
        obligations qui ne relèvent pas de sa faute et qui auraient pour cause
        des éléments qu’elle ne saurait maîtriser, notamment par suite&nbsp;:
      </p>
      <ul>
        <li>
          d’une erreur dans les informations fournies par l’Annonceur ou un
          tiers,&nbsp;
        </li>
        <li>
          du fait des éléments matériels et/ou des logiciels utilisés par
          l’Annonceur,
        </li>
        <li>
          de la destruction d’informations à la suite ou non d’erreurs commises
          par l’Annonceur,
        </li>
        <li>
          de problèmes de télécommunication indépendants de la volonté des
          Parties empêchant l’exécution normale de l’Application par l’Annonceur
          , et/ou
        </li>
        <li>
          de force majeure ou d’une circonstance indépendante de la Société.
        </li>
      </ul>
      <p style={{ textAlign: 'justify' }}>
        La Société ne saurait être responsable que de dommages directs survenus
        de son fait, du fait de ses préposés et/ou éventuels sous-traitants. La
        Société ne pourra en aucun cas être tenue pour responsable des dommages
        et préjudices indirects de quelque nature qu’ils soient, résultant de
        l’utilisation de l’Application par les Annonceurs. Aucune réclamation ne
        sera recevable concernant les retombées commerciales, le succès, les
        résultats attendus par l’Annonceur du fait de l’utilisation de
        l’Application. La Société exclut toute responsabilité à ce titre.
      </p>
      <p style={{ textAlign: 'justify' }}>
        A titre d’exemple, la responsabilité de la Société ne pourra en aucun
        cas être engagée lorsque, à la suite d’un Casting, le Vogtzer gagnant
        publie sur la Plateforme ou sur tout autre moyen de télécommunication
        public ou privé un contenu qui porterait indirectement préjudice à
        l’Annonceur, à sa réputation ou à son image de marque. La Société ne
        garantit ni la quantité et/ou la qualité des Contenus présentés par les
        Vogzers sur l’Application.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Société ne garantit ni la quantité et/ou la qualité des Contenus
        présentés par les Vogzers sur l’Application.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Société ne saurait être tenue responsable des agissements de
        l’Annonceur dans le cadre de son utilisation de l’Application.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Dans la limite de ce qu’autorise la loi, la Société ne pourra être tenue
        responsable de tout dommage découlant de l'utilisation ou de la mauvaise
        utilisation de l’Application par l’Annonceur.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Société s'efforce d'assurer au mieux de ses possibilités,
        l'exactitude et la mise à jour des informations diffusées sur
        l’Application, dont elle se réserve le droit de corriger, à tout moment
        et sans préavis, le contenu.
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Application peut contenir des liens vers d'autres sites ou
        applications. Ces sites ou applications étant édités et gérés par des
        tiers, et dans la mesure où la Société n’exerce aucun contrôle sur le
        contenu publié sur ces sites, l’Annonceur s’interdit d’entamer toute
        action judiciaire relative au contenu de ces sites ou à leur utilisation
        à l’encontre de la Société.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Société ne sera pas responsable des dommages découlant de
        l'utilisation ou de l'impossibilité d'utiliser l’Application. La Société
        s’engage à faire ses meilleurs efforts pour que l’Application soit
        disponible 24h/24, 7 jours /7, sans interruption autre que celles
        requises pour les besoins de la maintenance curative ou évolutive.
        Toutefois, elle ne garantit pas une disponibilité permanente. La Société
        se réserve le droit de modifier, corriger ou supprimer des contenus ou
        d’interrompre temporairement l’Application à tout moment et sans
        préavis.
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur ne pourra en aucun cas rechercher la responsabilité de la
        Société en raison de tels dysfonctionnements, anomalies, erreurs ou
        interruptions de l’Application, même dans l’hypothèse où ceux-ci
        auraient eu une implication dans la réalisation des prestations et/ou
        lui auraient causé quelque préjudice que ce soit.
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur peut joindre la Société pour toute question relative à
        l’Application <em>via</em> l’adresse email suivante&nbsp;:
        contact@vogz.io. La Société fera ses meilleurs efforts pour répondre aux
        demandes de l’Annonceur dans les plus brefs délais.
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur convient que tout grief tiré de l’Application ou de son
        utilisation, ou s’y rapportant, doit être formulé dans un délai d’un (1)
        mois à compter du fait générateur du grief, sous peine de forclusion
        définitive à raison dudit grief.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          En tout état de cause, le montant cumulé des dommages-intérêts auquel
          la Société pourrait être condamnée est expressément limité au montant
          versé par l’Annonceur à la Société au titre du Devis concerné
        </strong>
        .
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <em>10.2 Responsabilité de l’Annonceur</em>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur garantit la conformité des éléments transmis sur
        l’Application aux lois et règlements en vigueur, et qu’ils sont
        notamment libres de tous droits et soumis à aucune clause de
        confidentialité. Il garantit la Société contre tout recours émanant de
        tout tiers à quelque titre que ce soit du fait de la diffusion des
        Briefs, messages et informations transmises <em>via </em>l’Application.
        Ainsi, les informations sont diffusées sous la seule responsabilité de
        l’Annonceur.
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur garde la responsabilité des déclarations légales ou
        obligations qui lui incombent.
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Annonceur garantit la Société contre toute réclamation découlant des
        informations communiquées par lui ou de son utilisation de l’Application
        en violation des CGU.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>PROPRIÉTÉ INTELLECTUELLE</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        La Société accorde à l’Annonceur une licence révocable, gratuite,
        incessible, non-exclusive et mondiale d’utiliser l’Application
        conformément aux CGU.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Tous les éléments autres que ceux apportés par les Annonceurs et
        figurant sur l’Application, ainsi que l’Application elle-même, sont
        protégés par le droit d'auteur, le droit des marques, les dessins et
        modèles et/ou tout autre droit de la propriété intellectuelle.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Ces éléments comprennent de façon non exhaustive&nbsp;: les
        photographies, images, dessins, illustrations, textes, vidéos, logos,
        marques, modèles, logiciels, etc.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Ces éléments appartiennent à la Société ou bien sont utilisés avec
        l'accord de leurs titulaires.&nbsp;
      </p>
      <p style={{ textAlign: 'justify' }}>
        Aucune licence ni aucun droit autre que celui d’utiliser l’Application
        n’est conféré à quiconque au regard des droits de propriété
        intellectuelle.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Toute reproduction, représentation, utilisation, adaptation,
        modification, incorporation, traduction, commercialisation, partielle ou
        intégrale par quelque procédé et sur quelque support que ce soit
        (papier, numérique, etc.) sont interdites sans l'autorisation écrite
        préalable de la Société, hormis les exceptions visées à l'article
        L.122-5 du Code de Propriété Intellectuelle, sous peine de constituer un
        délit de contrefaçon de droits de propriété intellectuelle.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>FORCE MAJEURE</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Les cas de force majeure suspendent de plein l'exécution des CGU, sauf
        meilleur accord entre les Parties, et les exonèrent de toute
        responsabilité.
      </p>
      <p style={{ textAlign: 'justify' }}>
        De façon expresse et non exhaustive, sont considérés comme cas de force
        majeure ou cas fortuits, outre ceux habituellement retenus par la
        jurisprudence des cours et tribunaux français: les grèves totales ou
        partielles, internes ou externes à l'entreprise, intempéries, épidémies,
        guerres, blocages des moyens de transport ou d'approvisionnement pour
        quelque raison que ce soit, tremblements de terre, incendies, tempêtes,
        inondations, dégâts des eaux, destructions de matériel graves,
        restrictions gouvernementales, légales ou réglementaires, décisions du
        CAS, modifications légales ou réglementaires des formes de
        commercialisation, blocages total ou partiel des réseaux et services de
        télécommunications, et tout autre cas indépendant de la volonté expresse
        des parties empêchant l'exécution normale des CGU.
      </p>
      <p style={{ textAlign: 'justify' }}>
        La Partie constatant l'événement devra sans délai informer l'autre
        Partie de son impossibilité à exécuter sa prestation et s'en justifier
        auprès de celle-ci. Dans un tel cas de force majeure, la suspension des
        obligations d’une Partie ne pourra en aucun cas être une cause
        d’engagement de sa responsabilité pour inexécution de l'obligation en
        cause, ni induire le versement de dommages-intérêts ou pénalités de
        retard.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Pendant cette suspension, les Parties conviennent que les frais
        engendrés par la situation seront à la charge de la partie empêchée.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>BONNE FOI ET TOLERANCE</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Les Parties conviennent d'exécuter leurs obligations avec une parfaite
        bonne foi.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Les Parties conviennent réciproquement que le fait, pour l'une des
        Parties de tolérer une situation, n'a pas pour effet d'accorder à
        l'autre partie des droits acquis.
      </p>
      <p style={{ textAlign: 'justify' }}>
        De plus, une telle tolérance ne peut être interprétée comme une
        renonciation à faire valoir les droits en cause.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>INDÉPENDANCE DES PARTIES</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Les Parties reconnaissent agir chacune pour leur propre compte comme des
        commerçants indépendants et ne seront pas considérées agent l'une de
        l'autre.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Les CGU ne constituent ni une association, ni une franchise, ni un
        mandat donné par l'une des Parties à l'autre.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Aucune des Parties ne peut prendre un engagement au nom et pour le
        compte de l'autre Partie.
      </p>
      <p style={{ textAlign: 'justify' }}>
        En outre, chacune des Parties demeure seule responsable de ses actes,
        allégations, engagements, prestations, produits et personnels.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>CESSION&nbsp;</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        La Société pourra céder à toute personne morale de son choix, tout ou
        partie des droits et obligations définies aux Conditions Générales et au
        Devis, à charge pour elle d'en informer l’Annonceur.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>INDÉPENDANCE DES STIPULATIONS</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Si l’une quelconque des stipulations des CGU venait à être déclarée
        nulle au regard d’une disposition législative ou réglementaire en
        vigueur et/ou d’une décision de justice ayant autorité de la chose
        jugée, elle sera réputée non écrite, mais n’affectera en rien la
        validité des autres clauses qui demeureront pleinement applicables.
      </p>
      <ol>
        <li>
          <span style={{ textDecoration: 'underline' }}>
            <strong>LOI APPLICABLE- JURIDICTION - LANGUE</strong>
          </span>
        </li>
      </ol>
      <p style={{ textAlign: 'justify' }}>
        Les CGU sont régies par la loi française. Elles sont rédigées en langue
        française. Dans le cas où elles seraient traduites en une ou plusieurs
        langues, seul le texte français ferait foi en cas de litige.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Dans l'hypothèse où un litige naîtrait de la présente relation
        contractuelle, et sous réserve des dispositions d’ordre public
        applicables en matière de compétence juridictionnelle, les juridictions
        du ressort de la Cour d’Appel du siège social de la Société seront
        seules compétentes pour connaître de tout litige éventuel relatif aux
        CGU.
      </p>
      <p>
        <strong>Mentions Légales</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        L’Application est la propriété de la société <strong>WARDOGZ, </strong>
        société par actions simplifiée au capital social de 1.000 euros, dont le
        siège social est situé 73, boulevard Victor Hugo - 92200
        Neuilly-sur-Seine, immatriculée au registre du commerce et des sociétés
        de Nanterre sous le numéro 825&nbsp;106 248, représentée par Alan
        GHENASSIA.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>E-mail</span>&nbsp;:
        contact@vogz.io
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>Téléphone</span>
        &nbsp;:&nbsp; 01 84 20 45 88
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          Numéro TVA intracommunautaire
        </span>
        &nbsp;: FR06825106248
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          Directeur de la publication
        </span>
        &nbsp;: Monsieur Alan GHENASSIA, représentant légal de WARDOGZ.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>Hébergeur</span>: Vercel
      </p>
    </main>
  )
}
