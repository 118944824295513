/* eslint-disable react/button-has-type */
import React, { FC } from 'react'
import { Spinner } from 'grommet'
import PropTypes from 'prop-types'
import styles from './components/styles.module.scss'

const BtnBasic = React.forwardRef<HTMLButtonElement, BtnBasicProps>(({
  onclick,
  disabled,
  btnName,
  type,
  typeBtn,
  iconAfter,
  isLoading,
  spinnerColor,
  className
}, ref) => (
  <button
    ref={ref}
    disabled={disabled}
    type={typeBtn}
    className={`
        ${styles['btn-basic']} ${styles[type]} ${disabled ? styles['disabled'] : ''} 
        ${className}`}
    onClick={onclick}
  >
    {isLoading && <div className='flex w-full justify-center'><Spinner color={spinnerColor} /></div>}
    {!isLoading && (
      <>
        {btnName}
        {iconAfter}
      </>
    )}
  </button>
))


type BtnBasicProps = BtnBasicPropsN | BtnBasicPropsClicked

interface BtnBasicPropsN extends BtnProps {
  onclick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  typeBtn?: 'submit' | 'reset' | 'button'
}

interface BtnBasicPropsClicked extends BtnProps {
  onclick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  typeBtn: 'submit' | 'reset' | 'button'
}

interface BtnProps {
  type:
  | 'dark-blue'
  | 'sky-blue'
  | 'pastel-blue'
  | 'pastel-blue-actif'
  | 'sky-violet'
  | 'inverted-sky-violet'
  | 'sky-violet-bordered'
  disabled?: boolean
  onclick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  btnName: string
  iconAfter?: any
  isLoading?: boolean
  spinnerColor?: string
  className?: string
}

BtnBasic.defaultProps = {
  disabled: false,
  typeBtn: 'button',
  iconAfter: null,
  className: '',
  isLoading: false,
  spinnerColor: undefined,
  onclick: () => {}
}

BtnBasic.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onclick: PropTypes.any,
  btnName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  // @ts-ignore
  type: PropTypes.oneOf([
    'dark-blue',
    'sky-blue',
    'pastel-blue',
    'pastel-blue-actif',
    'sky-violet',
    'inverted-sky-violet',
    'sky-violet-bordered',
  ]).isRequired,
  // @ts-ignore
  typeBtn: PropTypes.oneOf(['submit', 'reset', 'button']),
  iconAfter: PropTypes.any,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  spinnerColor: PropTypes.string
}

export default BtnBasic
