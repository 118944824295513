/* eslint-disable global-require */
import { Grommet } from 'grommet'
import React, { useEffect } from 'react'
import WindowSizeListener from 'react-window-size-listener'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import { inject } from '@vercel/analytics'
import { ToastContainer } from 'react-toastify'
import languages from '@cospired/i18n-iso-languages'
import countries from 'i18n-iso-countries'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { SkeletonTheme } from 'react-loading-skeleton'
import * as mobx from 'mobx'

import 'react-calendar/dist/Calendar.css'
import utilsMobx from './mobx/utils'
import './utils/i18n'
import queryClient from './utils/clientProvider'
import Router from './routing'
import api from './utils/apiUtils'
import ModalVideo from './utils/components/Modals/ModalVideo'
import ModalAlert from './utils/components/Modals/ModalAlert'
import ModalCard from './utils/components/Modals/ModalCart'
import ModalPopUp from './utils/components/Modals/ModalPopUp'
import ModalAddFavorite from './utils/components/Modals/ModalAddFavorite'
import ModalDefault from './utils/components/Modals/ModalDefault'
import ModalEnroll from './utils/components/Modals/ModalEnroll'
import socketServiceInstance, { SocketService } from './sockets'
import userMobx from './mobx/user'
import { ClientI, TypeClient } from './types-project/Client'

// @ts-ignore
window.mobx = mobx
const App: React.FC = () => {
  const theme = {
    global: {
      colors: {
        myColor: '#808191',
        'background-contrast': {
          dark: '#FFFFFF14',
          light: '#0000000A',
        },
        'active-background': 'background-contrast',
        'active-text': 'red',
        icon: 'text',
        // focus color is an important indication for keyboard navigation accessibility,
        // it will be an ill advice to set it to undefined and remove focus
        focus: '#000',
        text: {
          font: {
            size: '10px',
          },
          dark: '#C0CADC',
          light: '#444444',
        },
      },
    },
    formField: {
      margin: 'small',
    },
    button: {
      default: {
        color: '#fff',
        border: {
          color: '#5777FF',
          width: '1px',
        },
        background: {
          color: '#5777FF',
        },
        font: {
          weight: 700,
        },
        text: {
          size: {
            medium: '10px',
          },
        },
      },
      hover: {
        default: {
          background: {
            color: '#fff',
          },
          color: '#5777FF',
        },
        secondary: {
          border: {
            width: '3px',
          },
          padding: {
            horizontal: '9px',
            vertical: '3px',
          },
        },
      },
      active: {
        background: {
          color: '#5777FF',
        },
        color: '#5777FF',
        secondary: {
          border: {
            color: 'transparent',
          },
        },
      },
    },
    checkBox: {
      size: '18px',
      hover: {
        border: {
          color: 'grey',
        },
      },
      icon: {
        extend: `color:#5777FF`,
      },
      toggle: {
        color: { dark: '#5777FF', light: '#d9d9d9' },
      },
    },
  }

  useEffect(() => {
    api.interceptors.request.use(
      async (config) => {
        const token = userMobx.getToken()
        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          }
        }
        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )
    inject()
    const userInfos = userMobx.getUserInfos() as ClientI
    if (userInfos !== null || Object.keys(userMobx.me.data ?? {}).length > 0) {
      if (
        userMobx.me.data?._company?.type === TypeClient.AGENCY ??
        userInfos?._company.type === TypeClient.AGENCY
      ) {
        if (userMobx.getBrandSession() !== null)
          socketServiceInstance.emit(`brand`, userMobx.getBrandSession()?._id)
      } else {
        socketServiceInstance.emit(
          `brand`,
          userMobx.me.data?.brand?._id ?? userInfos.brand?._id
        )
      }
    }
    languages.registerLocale(
      require('@cospired/i18n-iso-languages/langs/en.json')
    )
    languages.registerLocale(
      require('@cospired/i18n-iso-languages/langs/fr.json')
    )
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
    countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
  }, [])
  

  return (
    <QueryClientProvider client={queryClient}>
      {/* @ts-ignore */}
      <Grommet theme={theme}>
        <WindowSizeListener
          onResize={(windowSize) => {
            utilsMobx.handleWindow(
              windowSize.windowWidth,
              windowSize.windowHeight
            )
            utilsMobx.handleOpen((windowSize.windowWidth as number) <= 1440)
          }}
        />
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </SkeletonTheme>
      </Grommet>
      <ModalAlert />
      <ModalCard />
      <ModalPopUp />
      <ModalAddFavorite />
      <ModalEnroll />
      <ToastContainer />
      <ModalVideo />
      <ModalDefault />
    </QueryClientProvider>
  )
}
export default App
