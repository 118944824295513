import React, { useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { useFormik } from 'formik';
import { usePlacesWidget } from 'react-google-autocomplete';
import ElementPicker, { DropPhoto, ElementPickerProps } from '../ElementPicker'
import { Address, BrandI } from '../../../../../../../types-project/Client'
import userMobx from '../../../../../../../mobx/user'
import modal from '../../../../../../../mobx/modal'
import styles from './styles.module.scss'
import Title1 from '../../../../../../../utils/components/Headings/Title1'
import BtnBasic from '../../../../../../../utils/components/Button/BtnBasic'


function AddressPicker({ defaultSelection, onAddressSelected, onAddressRemoved, displayOnly, preventSelection, enableDeletion, onDelete, className }: AddressPickerProps) {
    // translation
    const { t } = useTranslation()

    if (onAddressSelected === undefined) onAddressSelected = () => { }
    if (onAddressRemoved === undefined) onAddressRemoved = () => { }
    
    return (
        <ElementPicker<Address>
            className={`w-full ${className}`}
            displayOnly={displayOnly}
            placeholderURL='/assets/imgs/placeholder-address.png'
            data={{
                names: userMobx.me.data?.addresses?.map((address) => address.name) || [],
                images: userMobx.me.data?.addresses?.map((address) => address.images) || [],
                addresses: userMobx.me.data?.addresses?.map((address) => address.address) || []
            }}
            pickList={userMobx.me.data?.addresses || []}
            defaultSelection={defaultSelection}
            onElementSelected={onAddressSelected}
            onElementRemoved={onAddressRemoved}
            pushHandler={() => modal.toogleVisibilityPopup({ val: true, content: <ContentModal t={t} /> })}
            pushBtnTitle='Nouvelle adresse'
            disableOnClick={preventSelection}
            multiSelection
            enableDeletion={enableDeletion}
            onDelete={onDelete}
        />
    )
}

// component to handle popup modal content
export function ContentModal({ t }: { t: TFunction<"translation", undefined> }) {


    const [isAddressFromGoogle, setIsAddressFromGoogle] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    const initialValues: Omit<Address, "images"> & { images: Array<(File | { source: string, key: string }) | undefined> } = {
        name: "",
        address: "",
        region: "",
        coordinates: {
            lat: 0,
            lng: 0
        },
        images: [undefined, undefined, undefined] as Array<File | undefined>
    }

    // scheme for form address validation
    const validationFormScheme = Yup.object().shape({
        name: Yup.string(),
        address: Yup.string().required(t("new-casting:step2:form-address:address_required")),
        region: Yup.string().required(t("new-casting:step2:form-address:region_required")),
        coordinates: Yup.object().shape({
            lat: Yup.number().required(t("mycastings:new-casting:address-error")),
            lng: Yup.number().required(t("mycastings:new-casting:address-error"))
        }),
    })

    const formik = useFormik<Address>({
        initialValues: initialValues,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: validationFormScheme,
        onSubmit: async (values) => {
            const errors = await formik.validateForm(values)
            if (!(Object.keys(values).length === 0 && Object.keys(errors).length === 0)) {
                formik.validateForm(values)
            }
            const address: Address = values
            if (!address.name) {
                address.name = userMobx.me.data?._company.name || ""
            }
            address.images = (address.images as Array<any>)?.filter((img) => img !== undefined) as Array<File>
            setIsLoading(true)
            await userMobx.addAddress(address, () => setIsLoading(false))
            modal.toogleVisibilityPopup({ val: false, content: undefined })
        }
    })
    const googlePlaces = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
        onPlaceSelected: (place) => {
            formik.setFieldValue('address', place.formatted_address ?? formik.values.address)
            const lat = place.geometry?.location?.lat()
            const lng = place.geometry?.location?.lng()
            const region = place.address_components?.find((component) => component.types.includes('administrative_area_level_1'))?.long_name
            formik.setFieldValue('region', region ?? formik.values.region)
            formik.setFieldValue('coordinates', { lat: lat, lng: lng })
            setIsAddressFromGoogle(true)
        },
        language: 'fr',
        options: {
            types: ['address'],
            componentRestrictions: { country: 'fr', }
        }
    })
    
    return (
        <form className={styles['content-modal']} onSubmit={formik.submitForm} autoComplete='none'>
            <div className={styles['header-content-modal']}>
                <Title1 text={t('new-casting:step2.form-address.title')} size={23} />
                <hr />
            </div>
            <div className={styles['body-content-modal']}>
                <div className={styles['body-content-input-modal']}>
                    <div className="flex flex-row items-baseline">
                        <label htmlFor="name">{t('new-casting:step2:form-address:store-name')}</label>
                        <span className="ml-2 text-[#5777FF] font-bold text-[14px]">{t('new-casting:step2:form-product:optionnal')}</span>
                    </div>
                    <input id='name' value={formik.values.name} name="name" onChange={formik.handleChange} placeholder={t('new-casting:step2:form-address:store-name-placeholder')} />
                    {formik.errors.name && (<span className={styles['body-content-input-modal-error']}>{formik.errors.name}</span>)}
                </div>
                <div className={styles['body-content-input-modal']}>
                    <div className="flex flex-row items-baseline">
                        <label htmlFor="address">{t('new-casting:step2:form-address:address')}</label>
                        <span className="ml-2 text-[#5777FF] font-bold text-[14px]">{t('new-casting:step2:form-product:required')}</span>
                    </div>
                    <input
                        ref={googlePlaces.ref as unknown as React.RefObject<HTMLInputElement>} 
                        id='address'
                        name="address" 
                        value={formik.values.address} 
                        onChange={(e) => {
                            formik.handleChange(e)
                            setIsAddressFromGoogle(false)
                        }}
                        autoComplete='none'
                        placeholder={t('new-casting:step2.form-address.address-placeholder')} 
                    />
                    {formik.errors.address && (<span className={styles['body-content-input-modal-error']}>{formik.errors.address}</span>)}
                    {/* The following input is a trick to prevent the browser from showing autocompletion, see https://stackoverflow.com/questions/37503656/react-doesnt-render-autocomplete-off */}
                    <input
                        className='hidden opacity-0'
                        type="text" 
                        autoComplete="on" 
                        value="" 
                        readOnly
                    />
                </div>

                <div className={styles['body-content-input-modal']}>
                    <div className="flex flex-row items-baseline">
                        <label>{t('new-casting:step2:form-address:img-picker-title')}</label>
                        <span className="ml-2 text-[#5777FF] font-bold text-[14px]">{t('new-casting:step2:form-product:optionnal')}</span>
                    </div>
                    <div
                        className={`${styles['div-content-file']} flex-wrap flex gap-3 pt-2 !items-center`}
                    >
                        {formik.values.images.map((image, index) => {
                            return (
                                <div className='m-auto'>
                                <DropPhoto
                                    t={t}
                                    image={image}
                                    onRemove={(img) => {
                                        formik.setFieldValue('images', (formik.values.images as Array<File>).map((value, index_) => {
                                            if (index_ === index) {
                                                return undefined
                                            } else {
                                                return value
                                            }
                                        }))
                                    }}
                                    onAccept={(file) => {
                                        formik.setFieldValue('images', (formik.values.images as Array<File>).map((value, index_) => {
                                            if (index_ === index) {
                                                return file
                                            } else {
                                                return value
                                            }
                                        }))
                                    }}
                                    key={index}
                                />
                                </div>
                            )
                        })}

                    </div>
                    <div className={styles['body-content-input-modal-infos']}>
                        <span dangerouslySetInnerHTML={{ __html: t('new-casting:step2:form-address.format') }} />
                        <span dangerouslySetInnerHTML={{ __html: t('new-casting:step2:form-address.maxsize') }} />
                    </div>
                </div>
            </div>
            <BtnBasic
                disabled={!isAddressFromGoogle || isLoading}
                isLoading={isLoading}
                spinnerColor='white'
                type='inverted-sky-violet' 
                typeBtn='button' 
                onclick={() => { formik.submitForm(); }} 
                btnName={t('new-casting:step2:form-product:save')}
            />
        </form>
    )
}

interface AddressPickerProps {
    defaultSelection?: ElementPickerProps<Address>['defaultSelection']
    onAddressSelected?: ElementPickerProps<Address>['onElementSelected']
    onAddressRemoved?: ElementPickerProps<Address>['onElementRemoved']
    displayOnly?: ElementPickerProps<Address>['displayOnly']
    preventSelection?: ElementPickerProps<Address>['disableOnClick']
    enableDeletion?: ElementPickerProps<Address>['enableDeletion']
    onDelete?: ElementPickerProps<Address>['onDelete']
    className?: string
}

AddressPicker.defaultProps = {
    defaultSelection: undefined,
    onAddressSelected: undefined,
    onAddressRemoved: undefined,
    displayOnly: [],
    preventSelection: false,
    enableDeletion: false,
    onDelete: undefined,
    className: ''
}

export default observer(AddressPicker)
