import React, { Fragment } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import modal from '../../../../../../mobx/modal'


const handleContinueBtn = () => {
    Cookies.set('browserNotCompatibleSeen', 'true')
    modal.toogleAlert(undefined, false)
}


function PageNotAvailable() {

    const { t } = useTranslation()

    return (
        <Fragment>
            <header className='w-full flex-1 flex justify-center'>
                <img src='/assets/svgs/dino.svg' alt="dino" className='w-3/5 max-w-[310px]' />
            </header>
            <section className={`${styles['content-section-body']} flex-[.6] flex-col flex justify-start`}>
                <h3>{t('common:browser-not-compatible')}</h3>
                <p>{t('common:browser-not-compatible-desc')}</p>

                <div className={`${styles['icons-browsers']} flex flex-row justify-around`}>
                    <div className='flex-1 max-w-[54px]'>
                        <img src='/assets/imgs/chrome.png' alt='chrome.png' />
                        <i className="fa-sharp fa-solid fa-circle-check" />
                    </div>
                    <div className='flex-1 max-w-[54px]'>
                        <img src='/assets/imgs/firefox.png' alt='firefox.png' />
                        <i className="fa-sharp fa-solid fa-circle-check" />
                    </div>
                    <div className='flex-1 max-w-[54px]'>
                        <img src='/assets/imgs/opera.png' alt='opera.png' />
                        <i className="fa-sharp fa-solid fa-circle-check" />
                    </div>
                    <div className='flex-1 max-w-[54px]'>
                        <img src='/assets/imgs/safari.png' alt='safari.png' />
                        <i className="fa-sharp fa-solid fa-circle-xmark" data-fa-circle-xmark />
                    </div>
                </div>
                <button className={styles['btn-continue']} onClick={() => handleContinueBtn()} type='button'>{t('common:continue-anyways')}</button>
            </section>
        </Fragment>
    )
}

export default PageNotAvailable