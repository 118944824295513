// import/no-named-as-default-member
import React, { FC, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { observer } from 'mobx-react'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, NavLinkProps, useNavigate } from 'react-router-dom'
import { Menu, MenuItem as MnItm, MenuButton } from '@szhsin/react-menu'
import { slide as MenuSide } from 'react-burger-menu'

import { Popover } from '@headlessui/react'
import Btn1 from '../Button/Btn1'
import style from './components/rightpanel.module.scss'
import '@szhsin/react-menu/dist/core.css'
import utilsMobx from '../../../mobx/utils'
import userMobx from '../../../mobx/user'
import { ClientI, TypeClient, TypeCompanyRole } from '../../../types-project/Client'
import { countMsgNRead, truncate } from '../../helpers'
import MovieClapperIcon from './components/MovieClapperIcon'
import HeartIcon from './components/HeartIcon'
import MessageIcon from './components/MessageIcon'
import VogzIcon from './components/VogzIcon'
import ProfileIcon from './components/ProfileIcon'
import DocumentIcon from './components/DocumentIcon'
import VogztersIcon from './components/VogztersIcon'

type TypeMenu = {
  icon: (color: `#${string}`) => ReactNode
  name: string
  path: string
  path2?: string
}

const VOGZ_FAQ_CLIENT = process.env.REACT_APP_VOGZ_FAQ_CLIENT

function RightPanel() {
  const refSideBar = useRef<HTMLElement>(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        utilsMobx.setSideBarWidth(entry.contentRect.width)
      })
    })

    if (refSideBar.current) {
      resizeObserver.observe(refSideBar.current)
    }

    return () => resizeObserver.disconnect()
  }, [refSideBar])

  const menus: TypeMenu[] = [
    {
      icon: (color) => <MovieClapperIcon color={color} />,
      path: '/dashboard/castings',
      path2: '/dashboard/brands',
      name: 'collabs',
    },
    {
      icon: (color) => <VogztersIcon color={color} />,
      path: '/dashboard/favorites-vogzters',
      name: 'favorite',
    },
    {
      icon: (color) => <MessageIcon color={color} />,
      path: '/dashboard/messaging',
      name: 'chat',
    },
    {
      icon: (color) => <VogzIcon color={color} />,
      path: '/dashboard/purchases',
      name: 'purchases',
    },
    {
      icon: (color) => <ProfileIcon color={color} />,
      path: '/dashboard/profile',
      name: 'profil',
    },
    // {
    //   icon: 'fa-user-vneck',
    //   path: '/dashboard/home',
    //   name: 'profil',
    // },
  ]

  const menusContext: TypeMenu[] =
    userMobx.me.data?.company_role === TypeCompanyRole.GUEST
      ? menus.filter(
        (m) =>
          m.name !== 'documents' &&
          m.name !== 'favorite' &&
          m.name !== 'profil' &&
          m.name !== 'purchases' &&
          m.name !== 'chat'
      )
      : menus

  // navigation
  const navigate = useNavigate()
  const userInfos = userMobx.getUserInfos() as ClientI
  useEffect(() => {
    userMobx.getAllMsgBrand()
  }, [])
  const nbMsgNread = countMsgNRead(userMobx.messages.data?.flatMap((group) => group.messages)!)
  const routeChange = () => {
    const path = '/dashboard/brands'
    navigate(path)
  }
  // translation
  const { t } = useTranslation()


  function stylesMenuItem(first: boolean) {
    return {
      color: 'blue',
      opacity: `${first ? 1 : '0.25'}`,
      hover: {
        color: '#fff',
        backgroundColor: '#333',
        // borderBottomLeftRadius: `${first ? 0 : "4px"}`,
        // borderBottomRightRadius: `${first ? 0 : "4px"}`,
        // borderTopLeftRadius: `${!first ? 0 : "4px"}`,
        // borderTopRightRadius: `${!first ? 0 : "4px"}`
      },
    }
  }

  let selectedBrand = userMobx.getBrandSession() ?? {}
  typeof localStorage.getItem('brand') === undefined
    ? (selectedBrand = JSON.parse(Cookies.get('brand') || '{}'))
    : null
  return (
    <aside
      ref={refSideBar}
      id="right-panel"
      className={`${style['right-panel']}
        ${utilsMobx.mobile ? style['mobile'] : ''}
        ${utilsMobx.open ? style['active'] : ''}`}
    >
      <main>
        <header
          onClick={() => {
            navigate(
              `${userMobx.me.data?._company.type === TypeClient.AGENCY &&
                userMobx.me.data?.company_role !== TypeCompanyRole.GUEST
                ? '/dashboard/brands'
                : '/dashboard/castings'
              }`
            )
          }}
          className="cursor-pointer"
        >
          <img src={userMobx.me.data?._company?.logo} alt="ico-logo" />
        </header>
        <hr />
        <section>
          <ul className={style['list-menu-links']}>
            {menusContext
              .filter((v) => (!userMobx.me.data ? v.name !== 'collabs' : v))
              .map((menu, i) => (
                <>
                  <li key={i}
                    data-for={`tooltip-${i}`}
                    data-tip={t(`dashboard-right-panel:links.${menu.name}`)}
                    className={`${['favorite', 'chat'].indexOf(menu.name) > -1 ? (userMobx.me.data?._company.type === TypeClient.AGENCY && userMobx.getBrandSession() === null ? style['disabled'] : null) : null}`}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? style['list-menu-active'] : ''
                      }
                      to={
                        menu.name === 'collabs'
                          ? userMobx.me.data?._company?.type! ===
                            TypeClient.AGENCY &&
                            !userMobx.getBrandSession()?._id &&
                            userMobx.me.data?.company_role !== TypeCompanyRole.GUEST
                            ? '/dashboard/brands'
                            : '/dashboard/castings'
                          : menu.path
                      }
                    >
                      {menu.icon(menu.path === window.location.pathname || menu.path2 === window.location.pathname ? "#2A42A7" : "#A9BBFF")}
                      <span>{t(`dashboard-right-panel:links.${menu.name}`)}</span>
                      {menu.name === 'chat' && nbMsgNread! > 0 ? <span className={style['nb-msg-n-read']}>{nbMsgNread}</span> : null}
                    </NavLink>
                    <ReactTooltip
                      id={`tooltip-${i}`}
                      place={'bottom'}
                      effect={'float'}
                      backgroundColor={'#E8ECFC !important'}
                      textColor={'#615AF6'}
                      arrowColor={'transparent'}
                      className={style['tooltip-menu']}
                    />
                  </li>
                </>
              ))}
            {
              userMobx.getBrandSession() === null &&
              userMobx.me.data?._company.type === TypeClient.AGENCY && (
                <ReactTooltip
                  id="favorites-tooltip"
                  place={'bottom'}
                  effect={'float'}
                  backgroundColor={'#E8ECFC !important'}
                  textColor={'#615AF6'}
                  arrowColor={'transparent'}
                  className={'tooltip-casting'}
                >
                  <span>{t('dashboard-right-panel:no-brand')}</span>
                </ReactTooltip>
              )}
          </ul>
        </section>
        <section>
          {userMobx.me.data?.company_role !== TypeCompanyRole.GUEST ?
            <>
              <div
                data-tip
                data-for="lancer-casting-tooltip"
                className={`${style['new-collab']} ${(userMobx.me.data?._company.type === TypeClient.AGENCY &&
                  userMobx.getBrandSession() === null &&
                  style['disabled'])
                  }`}
                onClick={() => {
                  // userMobx.me.data?._company.type === TypeClient.BRAND &&
                  // userMobx.me.data !== null
                  //   ? navigate('/dashboard/new-castings')
                  // : userMobx.brandSession.data !== null ||
                  //   userMobx.getBrandSession() !== null
                  // ? navigate('/dashboard/new-castings')
                  // : console.log(userMobx.me.data?._company.type)

                  if (userMobx.brandSession.data !== null ||
                    userMobx.getBrandSession() !== null ||
                    (userMobx.me.data?._company.type === TypeClient.BRAND &&
                      userMobx.me.data !== null))
                    navigate('/dashboard/new-castings')
                }}
              >
                <span>
                  <b>+</b>
                </span>
                <h3>{t('dashboard-right-panel:new-collab')}</h3>
              </div>
              <span className={style['available-credits']}>
                <h4>{userMobx.me.data?._company?.credits ?? 0}€</h4>
                <span>
                  {t('dashboard-right-panel:credits')}{' '}
                  <span>
                    {t('dashboard-right-panel:available')}
                  </span>
                </span>
              </span>
            </>
            : null}
        </section>
        <section>
          <>
            <div className='w-full'>
              {userMobx.me.data?.company_role !== TypeCompanyRole.GUEST && !userMobx.me.data?._company.premium ?
                <>
                  <button className={`${style['btn-bottom']} ${style['shadow-fx-btn']} ${style['premium']}`}
                    type='button'
                    onClick={() => { navigate('/dashboard/premium') }}
                  >
                    <h3>{t('dashboard-right-panel:premium')}</h3>
                    <span><i className='fa-solid fa-crown' /></span>
                  </button>
                  <br />
                </>
                : null
              }
              {
                <div className={`${userMobx.me.data?._company.premium ? 'flex gap-1' : 'flex flex-col gap-1'}`}>
                  {userMobx.me.data?.company_role !== TypeCompanyRole.GUEST && userMobx.me.data?._company.premium ?
                    <button className={`${style['btn-bottom']} ${style['shadow-fx-btn']} ${style['vogz-coin-btn']}`}
                      type='button'
                      onClick={() => { navigate('/dashboard/credits-vogz') }}
                    >
                      <h3>{t('dashboard-right-panel:vogz-credits')}</h3>
                      <span>+</span>
                    </button>
                    : null
                  }
                  <br />
                  <a className={`${style['btn-bottom']} ${style['shadow-fx-btn']} ${style['faq-btn']}`}
                    href={VOGZ_FAQ_CLIENT}
                    target="_blank"
                    rel='noreferrer'
                  >
                    <h3>{t('dashboard-right-panel:faq')}</h3>
                    <span>?</span>
                  </a>
                </div>
              }
              <br />
              <button className={`${style['btn-bottom']} ${style['logout-btn']}`}
                type='button'
                onClick={userMobx.logout}
              >
                <h3>{t('dashboard-right-panel:logout')} <i className='fa-solid fa-power-off' /></h3>
              </button>
            </div>

            {userMobx.getBrandSession() === null &&
              userMobx.me.data?._company.type === TypeClient.AGENCY && (
                <ReactTooltip
                  id="lancer-casting-tooltip"
                  place={'bottom'}
                  effect={'float'}
                  backgroundColor={'#E8ECFC !important'}
                  textColor={'#615AF6'}
                  arrowColor={'transparent'}
                  className={'tooltip-casting'}
                >
                  {userMobx.me.data?.company_role === TypeCompanyRole.GUEST ? (
                    <span>
                      {t('dashboard-right-panel:no-acces')}
                    </span>
                  ) : (
                    <span>{t('dashboard-right-panel:no-brand')}</span>
                  )}
                </ReactTooltip>
              )}
          </>
          {1 + 1 === 3 && (
            <div className={style['ask-vogz']}>
              <i className="fa-solid fa-phone" />
              <div>
                <span>{t(`dashboard-right-panel:question`)}</span>
                <a href="tel:01 84 80 87 99">
                  {t(`dashboard-right-panel:number`)}
                </a>
              </div>
            </div>
          )}
        </section>
        <hr />
        <footer>
          <img
            className='w-[90px]'
            src="/assets/imgs/vogzLogo.png"
            alt=""
          />
        </footer>
      </main>
    </aside>
  )
}

export default observer(RightPanel)
