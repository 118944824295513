import { motion } from 'framer-motion'
import React from 'react'
import moment from 'moment'
import { ChatBubbleProps } from 'react-chat-ui'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import styles from './components/bubble.module.scss'
import { Message } from './components/message'
import i18n from '../../../../utils/i18n'

const Bubble = ({ message, bubbleStyles }: ChatBubbleProps & { message: Message & { createdAt: string } }) => {

  const splitSpaces = message?.message?.split(' ')

  const isLink = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return urlRegex.test(text)
  }

  const formatDuration = (months: number) => {
    const years = Math.floor(months / 12)
    const remainingMonths = months % 123
    const andMonths = remainingMonths > 0 ? `\u00A0${i18n.t('vogzters:offers.and')}\u00A0${remainingMonths}\u00A0${t('vogzters:offers.month')}` : ''
    return years > 0 ? `${years}\u00A0${t('vogzters:offers.year')}${andMonths}` : `${months}\u00A0${t('vogzters:offers.month')}`
}

  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <div className={`${styles['bubble']} ${styles[`--${message?.senderName}-container`]} `}>
      <span>{moment(message?.createdAt).fromNow().toLocaleLowerCase()}</span>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        transition={{ type: 'spring', bounce: 0 }}
        variants={bubleVariantsAnimation}
        style={{ pointerEvents: 'auto', overflow: 'hidden' }}
        className={`${styles['bubble-content']} ${message.offer ? styles['offer-container'] : styles[`--${message?.senderName}`]}`}
      >
        {
          message?.offer ? (
            <div className={styles['offer']} aria-disabled={message.offer.state === 'deleted'} onClick={() => {
              if(message.offer?.state !== 'deleted')
                navigate(`/dashboard/new-deal/${message.offer?._id}`)
            }}>
              <div className={styles['offer-header']}>
                <h2>{message?.offer.title}</h2>
                <p>{message?.offer.price}€<span>{message?.offer.type === 'ugc' && t('vogzters:offers:content')}</span></p>
              </div>
              <div className={styles['offer-body']}>
                <p>{message?.offer.description}</p>
                {message?.offer.rights?.not_allow !== undefined && message?.offer.rights.not_allow === false && message?.offer.rights.duration && (
                  <p className={styles['offer-duration']}>
                    {t('vogzters:offers:duration')}{' '}<span>{formatDuration(message?.offer.rights.duration)}</span>
                  </p>
                )}
              </div>
            </div>
          ) : (
            <p>
              {splitSpaces.map((word, index) => {
                if (isLink(word)) {
                  return (
                    <a key={index} href={word} target="_blank" rel="noopener noreferrer">
                      {word}
                    </a>
                  )
                }
                return (
                  <span key={index}> {word} </span>
                )
              })}
            </p>
          )
        }
        {message?.senderName === 'test' && (
          <div>
            {' '}
            <svg
              id="svg-chat"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.0712748 11C7.7373 9.91865 9.59229 4.14599 9.59229 0.0909119L0.009758 1.4426C3.28412 3.59802 1.45265 7.81749 0.0851336 10.9681L0.0712748 11Z"
                fill="#082535"
              />
            </svg>
          </div>
        )}
      </motion.div>
      {message.lastOne && message.read && (<span>Vu ✓</span>)}
    </div>
  )
}

const bubleVariantsAnimation = {
  out: {
    opacity: 0,
    y: '-150px',
  },
  in: {
    opacity: 1,
    y: '0',
  },
  initial: {
    opacity: 1,
    y: '150px',
  },
  // out: {
  //   opacity: 0,
  //   marginTop:-80
  // },
}

export default observer(Bubble)
