/* eslint-disable react/no-danger */
import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import 'react-step-progress-bar/styles.css'
// @ts-ignore
import { ProgressBar, Step } from 'react-step-progress-bar'
// import the stylesheet
import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../../../utils/components/Headings/Title1'
import styles from './components/style.module.scss'
import {
  pageVariantsAnimation,
  stepVariantsAnimation,
} from '../../../../../utils/constants'

function SectionForm({
  children,
  title,
  description,
  required,
  recommended,
  error,
  castingPlus,
  dealPlus,
  conditions,
  info,
  optional,
  override,
}: SectionFormProps) {
  if (error){ 
      document
      .getElementById(title)
      ?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }

    const h3 = (<h3
    dangerouslySetInnerHTML={{ __html: title }}
    className={`${styles['title-section-form']} ${info ? styles['info'] : ''} ${
      castingPlus ? styles['casting-plus']
        : dealPlus
        ? styles['deal-plus']  // Deal privé avec envoie de produit
      : ''} ${
        !required ? styles['show-optional'] : ''
      } flex items-center`}
    />)
  return (
    override === true ? (
      <>
      <section
      id={title}
      data-info={info}
      data-required={required}
      data-recommended={recommended}
      data-optionnal={optional}
      data-errored={error !== undefined}
      className={`${styles['section-form']} ${styles['active']}`}
    >
      {(children as (title: Element, desc?: string) => Element)(h3 as unknown as Element, description)}
    </section>
      </>
    ) : (
    <section
      id={title}
      data-info={info}
      data-required={required}
      data-recommended={recommended}
      data-optionnal={optional}
      data-errored={error !== undefined}
      className={`${styles['section-form']} ${styles['active']}`}
    >
      {h3}
      {description && (
        <p
          dangerouslySetInnerHTML={{ __html: description }}
          data-required
          //  className={styles['desc-section-form']}
        />
      )}
      <div className={styles['form-aside-content']}>{children}</div>
      {conditions && (
        <p data-conditions dangerouslySetInnerHTML={{ __html: conditions }} />
      )}
    </section>
    )
  )
}

interface SectionFormProps {
  children: SectionFormProps['override'] extends boolean ? (title: Element, desc?: string) => React.ReactNode : React.ReactNode
  title: string
  description?: string
  required?: boolean
  recommended?: boolean
  error?: string
  conditions?: string
  castingPlus?: boolean
  info?: boolean
  dealPlus?: boolean
  override?: boolean
  optional?: boolean
}

SectionForm.defaultProps = {
  required: false,
  description: undefined,
  recommended: false,
  error: undefined,
  conditions: undefined,
  castingPlus:  false,
  info: false,  dealPlus: false,
  override: false,
  optional: true,
}

SectionForm.prototype = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  conditions: PropTypes.string,
}

export default SectionForm
