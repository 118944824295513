/* eslint-disable react/prop-types */
import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Rating } from '@mui/material'
import styles from "./components/styles.module.scss"

interface ReviewRatingsProps {
    quality?: number
    communication?: number
    punctuality?: number
    overall?: number
}


const ReviewRatings: FC<ReviewRatingsProps> = ({ quality, communication, punctuality, overall }) => {
    const { t } = useTranslation()
    const [isSmallScreen, setIsSmallScreen] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.outerWidth <= 768)
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className={styles['ratings']}>
            {!isSmallScreen ? (
                <>
                    <div className={styles["quality"]}>
                        <div className={styles["quality__title"]}>
                            <h3>{t('vogzters:reviews:quality')}</h3>
                        </div>
                        <div className={styles["rating"]}>
                            <Rating
                                name="quality-rating"
                                value={
                                    quality
                                }
                                readOnly
                                precision={1}
                            />
                            <p>
                                {Math.round((quality ?? 0) * 10) / 10}/5
                            </p>
                        </div>
                    </div>
                    <div className={styles["punctuality"]}>
                        <div className={styles["punctuality"]}>
                            <h3>{t('vogzters:reviews:punctuality')}</h3>
                        </div>
                        <div className={styles["rating"]}>
                            <Rating
                                name="punctuality-rating"
                                value={
                                    punctuality
                                }
                                readOnly
                                precision={1}
                            />
                            <p>
                                {Math.round((punctuality ?? 0) * 10) / 10}/5
                            </p>
                        </div>
                    </div>
                    <div className={styles["communication"]}>
                        <div className={styles["communication"]}>
                            <h3>{t('vogzters:reviews:communication')}</h3>
                        </div>
                        <div className={styles["rating"]}>
                            <Rating
                                name="communication-rating"
                                value={
                                    communication
                                }
                                readOnly
                                precision={1}
                            />
                            <p>
                                {Math.round((communication ?? 0) * 10) / 10}/5
                            </p>
                        </div>
                    </div>
                </>
            ): (
                <div className={styles["overall"]}>
                <div className={styles["overall__title"]}>
                    <h3>{t('vogzters:reviews:overall')}</h3>
                </div>
                <div className={styles["rating"]}>
                    <Rating
                        name="quality-rating"
                        value={
                            overall
                        }
                        readOnly
                        precision={1}
                    />
                    <p>
                        {Math.round((overall ?? 0) * 10) / 10}/5
                    </p>
                </div>
            </div>
            )}
        </div>
    )
}

ReviewRatings.defaultProps = {
    quality: 0,
    communication: 0,
    punctuality: 0,
    overall: 0
}

export default ReviewRatings;