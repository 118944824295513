import { TextField } from '@mui/material'
import TextareaAutosize from 'react-textarea-autosize'
import { FormikProvider, useFormik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { Spinner } from 'grommet'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { Link, Router, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import ReactLoading from 'react-loading'
import { ChatFeed } from 'react-chat-ui'
import { toast } from 'react-toastify'

import styles from '../brief-styles.module.scss'
import myStyles from './components/private-deal.module.scss'
import briefR from '../../../../../mobx/brief'
import modalR from '../../../../../mobx/modal'
import { Message } from '../../../../Messaging/components/Bubble/components/message'
import {
  Applicant,
  ApplicantT,
  BaCustom,
  MediaBA,
  MyCasting,
  VideoDemo,
} from '../../../../../types-project/Brief'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import Title1 from '../../../../../utils/components/Headings/Title1'
import InfoProfil from '../../../../Favorites-vogzters/Profil-vogzters/components/InfoProfil'
import CardVogz from '../CardVogz'
import userMobx from '../../../../../mobx/user'
import CardBriefApplication from '../CardBriefApplication'
import { formatVogzterName, getAvatar, getDayt, getHour, Tags, timeLeft, truncate } from '../../../../../utils/helpers'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import Bubble from '../../../../Messaging/components/Bubble'
import utilsMobx from '../../../../../mobx/utils'
import Portfolio from '../../../../Favorites-vogzters/Profil-vogzters/components/InfoProfil/components/Portfolio'
import { Address, ClientI } from '../../../../../types-project/Client'
import VogzterPicture from '../../../../Favorites-vogzters/Profil-vogzters/components/VogzterPicture'
import CardRDVStore from '../CardRDVStore'
import CardTips from '../CardTips'
import CardSetPublication from '../CardSetPublication'
import CardDeclinedPublication from '../CardDeclinedPublication'

interface PrivateDealProps {
  idBa: string
  setShowPortfolio: Dispatch<SetStateAction<boolean>>,
  showPortfolio?: boolean
}

const PrivateDeal: FC<PrivateDealProps> = ({ idBa, setShowPortfolio, showPortfolio }) => {
  const [showMessages, setShowMessages] = useState<boolean>(false)
  const [displayMedias, setDisplayMedia] = useState<boolean>(false)

  const favoriteVogzter = ['to-validate','closed','declined'].indexOf(briefR.brief.data?.status ?? '') > -1 ? (briefR.brief.data?.favourites?.at(0) as ApplicantT) : briefR.briefApplication.data?.at(0)?.applicant
  
  const briefApplication = briefR.detailBA.data?.briefApplication
  // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  const brand = userInfos._company.type === 'agency'
    ? userMobx.getBrandSession()
    : userMobx.brand.data

  useEffect(() => {
    briefR.getOneBa(idBa, (ba) => {
      if (
        (ba?.brief?.with_product &&
          !ba.product_sent.sent) ||
        ba?.status === 'created' ||
        ba?.brief.on_spot?.is_on_spot && ba?.rendez_vous?.date
      ) {
        setShowMessages(true)
      }
      else if (window.location.hash.substring(1) === 'messages') {
        userMobx.getUserMessages(ba.applicant._id)
        userMobx.readMsgBrand(brand?._id ?? '', ba.applicant._id, true, () => setShowMessages(true))
      }
    })
  }, [])

  useEffect(() => {
    if ((briefApplication?.media.filter((m) => m && m.status === 'chosen').length ?? 0) > 0) {
      setDisplayMedia(true)
    }
  }, [briefR.detailBA.data])

  const baChosenLength = briefApplication?.media.filter((m) => m.status === 'chosen').length ?? 0

  return (
    <>
      {
        (briefR.brief.loading ||
          briefR.brief.error ||
          briefR.brief.data === null) ||
        (['to-validate','closed','declined'].indexOf(briefR.brief.data?.status ?? '') === -1 && 
        ((briefR.detailBA.loading ||
          briefApplication === null ||
          briefR.detailBA.error) ||
        (briefR.briefApplication.loading ||
          briefR.briefApplication.error ||
          briefR.briefApplication.data === null)) )? (
        <div className='flex w-full h-full justify-center items-center'>
          <Spinner />
        </div>
      ) :
       (
        <SelectionNotDone
          briefApplication={briefApplication as BaCustom}
          favoriteVogzter={favoriteVogzter as Applicant}
          idBa={idBa}
          showPortfolio={showPortfolio}
          showMessages={showMessages}
          setShowMessages={setShowMessages}
          setShowPortfolio={setShowPortfolio}
          setDisplayMedia={setDisplayMedia}
          displayMedia={displayMedias}
        />
      )}
    </>
  )
}

const HandleDealNotStarted: FC<HandleDealNotStartedProps> = ({ dealExpired , vogzter}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  
  // Deadline enables us to check how much time left there is before cancelation of the deal
  const tmp = new Date(briefR.brief.data?.createdAt!)
  const baDeadline = new Date(
    tmp.getFullYear(),
    tmp.getMonth(),
    tmp.getDate() + 7
  )
  baDeadline.setHours(tmp.getHours())
  baDeadline.setMinutes(tmp.getMinutes())
  let diff = baDeadline.getTime() - new Date().getTime()
  const daysLeft = Math.floor(diff / (1000 * 60 * 60 * 24))
  diff -= daysLeft * (1000 * 60 * 60 * 24)
  const hoursLeft = Math.floor(diff / (1000 * 60 * 60))
  const vogzterName = `${(briefR.brief.data?.favourites?.at(0) as ApplicantT)?.first_name} ${(briefR.brief.data?.favourites?.at(0) as ApplicantT)?.last_name}`

  return (
    <>
      {briefR.brief.data?.status === 'declined' || dealExpired || (briefR.brief.data?.status === 'closed' && briefR.briefApplication.data?.at(0)?.media.filter(m=>m.status === 'chosen').length === 0 )  ||
        (briefR.briefApplication.data?.at(0)?.status === 'lost' && briefR.briefApplication.data?.at(0)?.lost_reason === 'closed') ? (
        <>
          <Title1
            className="uppercase !text-[#2F2E41]"
            text={
              briefR.brief.data?.status === 'declined' || (briefR.brief.data?.status === 'closed' && briefR.briefApplication.data?.at(0)?.lost_reason !== 'expired' )
                ? t('mycastings:private-deal:canceled')
                : t('mycastings:private-deal:closed')
            }
            size={35}
          />
          <p className="text-center">
             {briefR.brief.data?.status === 'closed' && briefR.briefApplication.data?.at(0)?.lost_reason !== 'expired' 
              ? t('mycastings:private-deal:canceled-desc-client')
              : briefR.brief.data?.status === 'declined'? 
              t('mycastings:private-deal:canceled-desc',{ vogzter : vogzterName} )
              : t('mycastings:private-deal:closed-desc')
              }
          </p>
        </>
      ) : (
        briefR.brief.data?.status === 'to-validate' && (
          // Si le vogzter n'a pas encore accepté
          <>
            <Title1
              text={t('mycastings:created')}
              size={35}
              className="uppercase !text-[#2F2E41]"
            />
            <Title1
              className="uppercase !text-[#B2C3FF]"
              text={String(t('mycastings:private-deal:time-left'))
                .replace('XX', daysLeft.toString())
                .replace('XX', hoursLeft.toString())}
              size={29}
            />
            <br />
            <p className="text-center text-[1.5ch]">
              {t('mycastings:private-deal:time-left-desc')}
            </p>
            <br />
            <br />
            <div className="flex w-2/3 justify-center">
              <BtnBasic
                btnName={t('mycastings:cancel-deal')}
                type="sky-violet-bordered"
                onclick={() => {
                  modalR.resetData()
                  modalR.toogleAlert('close-casting', true)
                  modalR.setOnConfirm(() => {
                    briefR.closeBrief(briefR.brief.data?._id as string,
                      () => {
                        modalR.resetData()
                        navigate('/dashboard/castings')
                      }
                    )
                  })
                }}
              />
            </div>
          </>
        )
      )}
    </>
  )
}

interface HandleDealNotStartedProps {
  vogzter: ApplicantT
  dealExpired: boolean
}

HandleDealNotStarted.propTypes = {
  vogzter: PropTypes.any.isRequired,
  dealExpired: PropTypes.bool.isRequired
}

export const SelectionNotDone: FC<SelectionNotDoneProps> = observer(
  ({
    showMessages,
    briefApplication,
    favoriteVogzter,
    setShowMessages,
    setShowPortfolio,
    showPortfolio,
    setDisplayMedia,
    displayMedia,
  }) => {
    type TrackingLink = {
      trackingLink: string
    }

    // translation
    const { t } = useTranslation()
    const navigate = useNavigate()
    const Chat = observer(ChatFeed)
    const [textMessage, setTextMessage] = useState<string>('')
    const [showModalTips, setModalTips] = useState<boolean>(false)
    const [showModalSendProduct, setModalSendProduct] = useState<boolean>(false)    
    const [showModalCalendar, setModalCalendar] = useState<MediaBA|null>(null)  
    const [showModalDeclinePublication, setShowModalDeclinePublication] = useState<MediaBA|null>(null)  

    useEffect (() => {
      if (showModalCalendar) {
        document.getElementById('calendar-modal')?.scrollIntoView({ behavior: 'smooth' })
        document.getElementById('page-aside')?.style.setProperty('overflow', 'hidden')      
      } else if (showModalDeclinePublication) {
        document.getElementById('decline-publication-modal')?.scrollIntoView({ behavior: 'smooth' })
        document.getElementById('page-aside')?.style.setProperty('overflow', 'hidden')      
      
      } else
        document.getElementById('page-aside')?.style.setProperty('overflow', 'auto')
    }, [showModalCalendar,showModalDeclinePublication])

  // Fonction pour gérer le changement d'étape dans le parcours client
  // click to handle step of the client parcours
  // @index
  function handleStep(index: number) {
    switch (index) {
      case 1:
        setDisplayMedia(false)
        break
      case 2:
        setDisplayMedia(true)
        break
      default:
        setDisplayMedia(false)
        break
    }
  }  
  const vogzter = ['to-validate','closed','declined'].indexOf(briefR.brief.data?.status ?? '') > -1 ? briefR.brief.data?.favourites?.at(0) as ApplicantT : briefApplication?.applicant as ApplicantT   
  const userInfos = userMobx.getUserInfos() as ClientI
    const brand = userInfos._company.type === 'agency'
      ? userMobx.getBrandSession()
      : userMobx.brand.data
    const sendMessage = () => {
      userMobx.sendMessage(textMessage, vogzter._id, (brand?._id ?? ''))
      setTextMessage('')
    }
    const validationSchema = Yup.object().shape({
      trackingLink: Yup.string()
        .url('lien invalide')
        .required('Merci de renseigner le lien de suivi'),
    })

    const handleSendProduct = () => {
      briefR.productSent(
        briefR.briefApplication.data?.at(0)?._id ?? '',
        formik.values.trackingLink,
        () => {
          setModalSendProduct(false)
          toast.success(`Produit envoyé avec succès`)
        }
      )
    }
    const formik = useFormik<TrackingLink>({
      initialValues: { trackingLink: '' },
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
      validationSchema: validationSchema,
      onSubmit: () => {
        handleSendProduct()
      },
    })
     

    const { daysLeft, hoursLeft, minutesLeft } = timeLeft(new Date(briefR?.brief?.data?.deadline ?? new Date()))

    const dealExpired = daysLeft < 0 || (daysLeft < 0 && hoursLeft < 0) || (daysLeft < 0 && hoursLeft < 0 && minutesLeft < 0)

    const [formRdv, setFormRdv] = useState<{
      date: {
        day: { value: string, error: null | string },
        hour: { value: string, error: null | string }
      },
      address: { value: string | undefined, error: null | string }
    }>({
      date: {
        day: { value: briefR.briefApplication.data?.at(0)?.rendez_vous ? getDayt(briefR.briefApplication.data?.at(0)?.rendez_vous?.date ?? '') : '', error: null },
        hour: { value: briefR.briefApplication.data?.at(0)?.rendez_vous ? getHour(briefR.briefApplication.data?.at(0)?.rendez_vous?.date ?? '') : '', error: null }
      }, address: { value: briefR.briefApplication.data?.at(0)?.rendez_vous?.address, error: null }
    })
    const [edit, setEdit] = useState<"step-1" | "step-2">()

    const mediasChosen = briefR.briefApplication
    .data!.map((ba) => ba.media)
    .flat()
    .filter((m) => m.status === 'chosen')

    const checkFormSend = () => {
      const { address, date } = formRdv
      let addressError: string | null = null
      let dayError: string | null = null
      let hourError: string | null = null

      if (!address.value) {
        addressError = t('mycastings:required-fields')
      }

      if (!date.day.value || date.day.value.toString() === 'NaN') {
        dayError = t('mycastings:required-fields')
      }

      if (!date.hour.value || date.hour.value?.toString() === 'NaN') {
        hourError = t('mycastings:required-fields')
      }
      setFormRdv(prevState => ({
        ...prevState,
        date: {
          ...prevState.date,
          day: { ...prevState.date.day, error: dayError },
          hour: { ...prevState.date.hour, error: hourError }
        },
        address: { ...prevState.address, error: addressError }
      }))
      if (!addressError && !dayError && !hourError) {
        const dateStr = `${date.day.value} ${date.hour.value}`
        if (edit === "step-2") {
          briefR.editRdv(briefR.briefApplication.data?.at(0)?._id ?? '', address.value!, new Date(dateStr))
          setEdit("step-1")
        }
        else
          briefR.confirmRdv(briefR.briefApplication.data?.at(0)?._id ?? '', address.value!, new Date(dateStr))
      }
    }
    useEffect(() => {
      if (briefR.briefApplication.data?.at(0)?.rendez_vous && briefR.briefApplication.data?.at(0)?.rendez_vous?.address && briefR.briefApplication.data?.at(0)?.rendez_vous?.date) {
        setEdit("step-1")
      }
    
    }, [briefR.briefApplication.data?.at(0)?.rendez_vous])



    useEffect(() => {
      if (utilsMobx.getWidth > 1200 && !showMessages) {
        userMobx.getUserMessages(briefR.briefApplication.data?.at(0)?.applicant._id ?? '')
        userMobx.readMsgBrand(brand?._id ?? '', (briefR.briefApplication.data?.at(0)?.applicant._id ?? ''), true, () => setShowMessages(true))
      }
    }, [utilsMobx.getWidth])

    const applicant = briefR.briefApplication.data?.at(0)?.applicant

    return (
      <section className={`relative flex w-full justify-center`}>
        <div
          className={`${showMessages ? myStyles['body-first-step'] : ''
            } flex flex-row flex-1 justify-start mt-[5%]`}
        >
          <div className="flex flex-col w-9/12 flex-1 justify-start items-center mt-[3%]">
            <InfoProfil
              profile={vogzter?.profile}
              user={favoriteVogzter!}
              // briefApplicationsWon={briefR.detailBA.data?.briefApplicationsWon!}
              // mediaValidatedCount={briefR.detailBA.data?.mediaValidatedCount!}
              first_name={vogzter?.first_name!}
              last_name={vogzter?.last_name!}
              parent_code={vogzter?.parent_code ?? ''}
              delivery_address={vogzter?.delivery_address!}
              lastActivity={vogzter?.last_activity}
              showBtnAction={{
                tips:{
                  show: briefR.brief.data?.status === 'active',
                  onClick: () => {
                    setModalTips(true)
                  }
                },
                sendProduct: {
                  show: briefR.brief.data?.status === 'active',
                  onClick: () => {
                    setModalSendProduct(true)
                  }
                },
                closeCollab:{
                  show: briefR.brief.data?.status === 'active',
                  onClick: () => {
                    modalR.toogleAlert('close-casting', true)
                    modalR.setOnConfirm(() => {
                      briefR.cancelBrief(briefR.briefApplication.data?.at(0)?._id as string)
                    })
                  }
                }
              }}
              classNames={{
                container: myStyles['info-profile-container'],
                sectionName: myStyles['section-name'],
                descApplicant: myStyles['desc-applicant'],
              }}
              setShowMessages={briefR.brief.data?.status !== 'to-validate' && ['created', 'applied', 'step2'].indexOf(briefR.briefApplication.data?.at(0)?.status!) > -1 ? setShowMessages : undefined}
            />
            <br />
            {(briefR?.brief.data?.deadline !== undefined
              && briefR.briefApplication.data?.at(0)?.status && briefR.briefApplication.data.at(0)?.status === 'lost' && briefR.briefApplication.data?.at(0)?.lost_reason === 'expired'
              && ['declined', 'created'].indexOf(briefR.briefApplication.data?.at(0)?.status!) <= -1) && (
                <div className={myStyles['deadline-streamer']}>
                  <p>{t('mycastings:private-deal:deadline-streamer-text')}</p>&nbsp;
                  <b>
                    {t('mycastings:private-deal:deadline-streamer-time-left', { nbDays: daysLeft, nbHours: hoursLeft, nbMinutes: minutesLeft })}
                  </b>
                </div>
              )}
            <br />
            { ['to-validate','closed','declined'].indexOf(briefR.brief.data?.status ?? '') === -1 && 
              <section className={myStyles['sub-head']}>
                <div
                  style={{
                    justifyContent: (briefR?.brief.data?.with_product || briefR?.brief.data?.on_spot?.is_on_spot)
                      ? 'space-between'
                      : 'center',
                  }}
                  className={myStyles['steps']}
                >
                    <div className={myStyles['step']}>
                      <h2
                        className={
                          !displayMedia && (briefApplication?.media.filter((v) => v.status === 'validated').length ?? 0) > 0
                            ? myStyles['active-btn']
                            : (briefApplication?.media.filter((v) => v.status === 'validated').length ?? 0) > 0
                              ? myStyles['not-selected-text']
                              : myStyles['disabled']
                        }
                      >
                        1
                      </h2>
                      <div data-tip data-for="main">
                        <button
                          type="button"
                          onClick={() => handleStep(1)}
                          className={`${myStyles['btn']} ${(briefApplication?.media.filter((v) => v.status === 'validated').length ?? 0) > 0 && !displayMedia
                            ? myStyles['active-btn']
                            : (briefApplication?.media.filter((v) => v.status === 'validated').length ?? 0) > 0
                              ? myStyles['not-selected']
                              : myStyles['disabled']
                            }`}
                        >
                          {t('mycastings:content')}{' '}
                        </button>
                      </div>
                    </div>
                  
                  <ReactTooltip
                    id="main"
                    place={'top'}
                    effect={'float'}
                    disable
                    multiline
                  >
                    <span>{t('mycastings:step-unavailable')}</span>
                  </ReactTooltip>
                  <div className={myStyles['step']}>
                    {briefApplication?.brief.status !== 'closed' && (
                      <h2
                        className={
                          displayMedia && mediasChosen.length > 0
                            ?  myStyles['active-btn']
                              :  mediasChosen.length > 0
                            ? myStyles['not-selected-text']
                              : myStyles['disabled']
                        }
                      >
                        2
                      </h2>
                    )}
                    <div data-tip data-for="main">
                      <button
                        type="button"
                        onClick={() => handleStep(2)}
                        className={`${myStyles['btn']} ${displayMedia && mediasChosen.length > 0
                          ? myStyles['active-btn']
                          :  mediasChosen.length > 0
                            ? myStyles['not-selected']
                            : myStyles['disabled']
                          }`}
                      >
                        {t('mycastings:validated')}
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            }


            {briefR?.brief.data?.status !== 'to-validate' && showModalSendProduct ? (
              <div className={styles['parent-modal']}>
                <div className={styles['product-shipment-modal']}>
                  <div className={styles['top-part']}>
                    <h1>{t('mycastings:private-deal:product-shipment')}</h1>
                    <p>{t('mycastings:private-deal:product-shipment-msg')}</p>
                    <button type='button' onClick={()=> setModalSendProduct(false)}>
                      <i className='fa-regular fa-circle-xmark'/>
                    </button>
                  </div>
                  <div className={styles['bottom-part']}>
                    <h5>
                      {briefApplication?.applicant?.first_name}{' '}
                      {briefApplication?.applicant?.last_name}
                    </h5>
                    <br />
                    <b>{t('mycastings:private-deal:full-address')}</b>
                    <p>
                      {briefR.briefApplication.data?.at(0)?.applicant
                        .delivery_address ?? t('mycastings:private-deal:no-info')}
                    </p>
                    {!briefR.briefApplication.data?.at(0)?.applicant.delivery_address &&
                      <p className='error leading-normal'>{t('mycastings:private-deal:no-info-error')}</p>
                    }
                    <br />
                    <b>{t('mycastings:private-deal:further-address')}</b>
                    <p>
                      {briefR.briefApplication.data?.at(0)?.applicant
                        ?.delivery_address_2 ?? t('mycastings:private-deal:no-info')}
                    </p>
                    <br />
                    <b>{t('mycastings:phone')}</b>
                    <p>
                      <a href={`mailto:${applicant?.phone}`}>
                        {applicant?.phone ?? t('mycastings:private-deal:no-info')}
                      </a>
                    </p>
                    <br />
                    <b>{t('mycastings:email')}</b>
                    <p>
                      <a href={`mailto:${applicant?.email}`}>
                        {applicant?.email ?? t('mycastings:private-deal:no-info')}
                      </a>
                    </p>
                    <br />
                    <b>{t('mycastings:tracking-link')}</b>
                    <FormikProvider value={formik}>
                      <form onSubmit={formik.handleSubmit} className={styles['form']}>
                        <TextField
                          required
                          className={styles['text-field']}
                          type="url"
                          onChange={formik.handleChange}
                          value={formik.values.trackingLink}
                          name="trackingLink"
                          placeholder="https://lien-de-suivi"
                        />
                      </form>
                    </FormikProvider>
                    <BtnBasic
                      disabled={!formik.isValid}
                      type="inverted-sky-violet"
                      btnName="Produit expédié"
                      typeBtn="submit"
                      onclick={() => {
                        handleSendProduct()
                      }}
                    />
                  </div>
                </div>
              </div>
            )
            : briefR?.brief.data?.status !== 'to-validate' && showModalTips ? (
              <div className={styles['parent-modal']}>
                <CardTips setModalTips={setModalTips} />
              </div>
            )  
            
            : briefR?.brief.data?.status !== 'to-validate' && showModalCalendar ? (
              <CardSetPublication
                showModalCalendar={showModalCalendar}
                setModalCalendar={setModalCalendar}
                briefApplication={briefApplication!}
              />
            ):
            briefR?.brief.data?.status !== 'to-validate' && showModalDeclinePublication ? (
              <CardDeclinedPublication
                showModalDeclinePublication={showModalDeclinePublication}
                setShowModalDeclinePublication={setShowModalDeclinePublication}
              />
            ) 

            :briefR?.brief.data?.status !== 'to-validate' && (briefApplication?.media.filter((v) => ['validated','chosen'].indexOf(v.status ) > -1 ).length ?? 0) > 0 ? (
              <>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    initial={{ opacity: 0, x: '-800px' }}
                    exit={{ opacity: 0, x: '-800px' }}
                    transition={{ duration: 0.5 }}
                    animate={{ x: 0, opacity: 1 }}
                    className={`${styles['grid2']} grid grid--flex grid--flex-3 grid--flex-2--t grid--flex-1--s mt-4`}
                  >
                    {!displayMedia ? (
                    briefApplication?.media.filter((v) => v.status === 'validated')
                      .map((v, i) => (
                        <CardVogz
                          key={i}
                          showBasket={
                            (!briefApplication?.brief.with_product || (briefApplication?.brief?.with_product && briefApplication.product_sent.sent && briefApplication.brief.private?.is_private)) ||
                            (!briefApplication?.brief?.on_spot?.is_on_spot || (briefApplication?.brief?.on_spot?.is_on_spot && briefApplication?.rendez_vous?.date.length > 0)) &&
                            briefApplication.status !== 'won' &&
                            briefApplication.status !== 'lost'
                          }
                          briefApplication={briefApplication!}
                          media={v}
                          showBookmarked
                          className={'mb-8'}
                        />
                      ))
                      )
                      :
                      displayMedia &&
                      briefR.briefApplication.data
                      ?.at(0)
                          ?.media?.filter((m) => m && m.status === 'chosen')
                          .map((value, i) => (
                            <div className="flex flex-1 justify-center ml-4 mr-4" key={i}>
                              <CardBriefApplication
                                className={`card-brief-application-${briefR.brief.data?.content_type}`}
                                media={value}
                                type="download"
                                isPrivate
                                showBookmarked
                                contentType={briefR.brief.data?.content_type}
                                showCalendar={()=> {
                                  setModalCalendar(value)
                                }}
                                showDeclinePublication={() => {
                                  setShowModalDeclinePublication(value)
                                }}
                              />
                            </div>
                          ))}
                  </motion.div>
                </AnimatePresence>
                { !displayMedia && briefApplication !== undefined &&  
                <button
                  type="button"
                  disabled={
                    briefApplication.media.filter(
                      (ba, i) => ba.in_basket === true
                    ).length === 0
                  }
                  onClick={() => {
                    modalR.setOnRequestSuccess(() => {
                      window.location.reload()
                    })
                    modalR.toogleSelection(
                      briefApplication,
                      undefined,
                      !modalR.selection.open
                    )
                  }}
                  className={` ${styles['deal']}
                      ${briefApplication!?.media.filter(
                    (ba, i) => ba.in_basket === true
                  ).length > 0
                      ? styles['active']
                      : ''
                    }`}
                >
                  {t('mycastings:validate-select')}{' '}
                  {briefApplication.media.filter(
                        (ba, i) => ba.in_basket === true
                      ).length > 0 &&
                    <span className="badge ">
                    {
                      briefApplication.media.filter(
                        (ba, i) => ba.in_basket === true
                      ).length
                    }
                  </span>}
                </button>}
              </>
            )

                :
                (
                  <div className="relative flex w-5/6 md:w-full flex-1 items-center justify-center">
                    <img src="/assets/imgs/placeholder-private-deal.png" alt="" className='max-w-lg' />
                    <br />
                    {/* "Si le vogzter n'a pas encore accepter le deal" || "Si le deal à été annuler" || "Si le vogzter à refuser le deal" */}
                    {['to-validate','closed','declined'].indexOf(briefR.brief.data?.status ?? '') > -1 || briefR.briefApplication.data?.at(0)?.lost_reason === 'closed' || dealExpired ||
                      briefR.briefApplication.data?.at(0)?.status === 'declined' || briefR.briefApplication.data?.at(0)?.status === 'declined-client' ? (
                      <div className="absolute h-full flex w-full justify-center items-center backdrop-blur-md">
                        <div className={styles['absolute-container']}>
                          <HandleDealNotStarted
                            vogzter={ ['to-validate','closed','declined'].indexOf(briefR.brief.data?.status ?? '') > -1 ? briefR.brief.data?.favourites?.at(0) as ApplicantT: briefApplication?.applicant as ApplicantT}
                            dealExpired={dealExpired}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
          </div>
          <aside
            className={` ${showMessages ? myStyles['message-deal'] : 'hidden'
              } w-3/12 ${showMessages ? 'sm:!right-0 !z-20' : ''} `}
            style={{
              right: showMessages ? '0' : '-1300px',
              transition: '.5s right ease-in-out',
            }}
          >
            {utilsMobx.getWidth < 1200 &&
              <button
                className={myStyles['btn-close-msg']}
                type="button"
                onClick={() => {
                  setShowMessages(false)
                }}
              >
                <i className="fa-regular fa-xmark" />
              </button>
            }
            <section>
              <VogzterPicture
                imgSource={vogzter.profile?.picture?.source}
                showCertified={vogzter?.certified}
                lastActivity={vogzter?.last_activity}
              />
              {vogzter.parent_code &&
                <div className="flex">
                  <p className='leading-[.5rem] text-sm font-normal uppercase'>{vogzter.parent_code ? '#' : ''}{vogzter.parent_code}</p>
                </div>
              }
              {vogzter.first_name === undefined ? 
                <Skeleton
                  width={192}
                  className='!-ml-3'
                  highlightColor="#ffffff"
                />
              : 
                <h2>{vogzter.first_name} {vogzter.last_name}</h2>
              }
              <div className="flex">
                <a onClick={()=>navigate(`/dashboard/profil-vogzter/${vogzter._id}`)} href={''}>{t('mycastings:view-profile')}</a>
              </div>
            </section>
            <section className='flex'>
              <h2>{briefR?.brief.data?.name} <i className="fa-solid fa-file-invoice"/></h2>
              {!!briefR.brief.data?.description && (
                <div className={myStyles['description']}>
                  <p>{briefR.brief.data?.description}</p>
                </div>
              )}
            </section>
            <hr />
            <section>
              <Chat
                bubblesCentered={false}
                messages={userMobx.messageUser.data?.map((message, i) =>
                  new Message({
                    id: message._id,
                    message: message.text,
                    senderName: message.sender,
                    read: message.read && message.sender === 'client',
                    createdAt: message.createdAt,
                    lastOne: userMobx.messageUser.data ? i === userMobx.messageUser.data?.length - 1 : true,
                    offer: message.offer,
                    brief: message.brief,
                  })
                ) ?? []}
                senderName={'client'}
                chatBubble={Bubble}
              />
              {briefR.briefApplication.loading && (
                <h1>{t('mycastings:messages-uploads')}</h1>
              )}
              <div className={`${myStyles['input-container-casting']}`}>
                <div
                  className={`search-bar-casting ${myStyles['input-content-casting']}`}
                >
                  <>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  </>
                  <TextareaAutosize
                    style={{ boxSizing: 'border-box' }}
                    id="search-castings"
                    className={`${myStyles['casting-message']}`}
                    minRows={1}
                    maxRows={3}
                    placeholder={t('mycastings:enter-msg')}
                    value={textMessage}
                    onChange={(value) =>
                      setTextMessage(value.currentTarget.value)
                    }
                  />
                  {/* <textarea placeholder="Envoyer un message privé" className={`${styles["casting-message"]}`} id="search-castings" /> */}
                </div>
                <div className="flex w-10">
                  {briefR.message.loading ? (
                    <p>
                      {' '}
                      <ReactLoading
                        type="spokes"
                        color="#000"
                        width={22}
                        className="flex items-center"
                      />{' '}
                    </p>
                  ) : (
                    <p
                      // htmlFor="search-castings"
                      className="fas fa-paper-plane"
                      onClick={() => {
                        sendMessage()
                      }}
                    />
                  )}
                </div>
              </div>
            </section>
          </aside>
        </div>

      </section>
    )
  }
)

interface SelectionNotDoneProps {
  showMessages: boolean
  briefApplication?: BaCustom
  favoriteVogzter: Applicant
  idBa: string
  setShowMessages: Dispatch<SetStateAction<boolean>>
  setShowPortfolio: Dispatch<SetStateAction<boolean>>
  showPortfolio?: boolean
  setDisplayMedia: Dispatch<SetStateAction<boolean>>
  displayMedia: boolean
}

SelectionNotDone.propTypes = {
  showMessages: PropTypes.bool.isRequired,
  briefApplication: PropTypes.any,
  favoriteVogzter: PropTypes.any.isRequired,
  idBa: PropTypes.string.isRequired,
  setShowMessages: PropTypes.any.isRequired,
  showPortfolio: PropTypes.bool,
  setDisplayMedia: PropTypes.any.isRequired,
  displayMedia: PropTypes.bool.isRequired,
}

const SelectionDone: FC<SelectionDoneProps> = ({
  briefApplication,
  displayMedias
}) => {
  const { t } = useTranslation()

  const type = briefApplication?.brief?.type?.name ?? 'ugc'
  const castingType =
    type === 'product-review-unboxing'
      ? 'Test de produit'
      : type === 'choregraphy'
        ? 'Choregraphie'
        : type === 'creativity'
          ? 'Créativité'
          : type === 'testimony'
            ? 'Témoignage'
            : type === 'acting'
              ? 'Acting'
              : type === 'vip'
                ? 'Casting Client'
                : ''

  let mObserver: IntersectionObserver

  const refPartTwo = useRef<HTMLDivElement>(null)

  const [lightColor, setLightColor] = useState<'white' | 'yellow' | 'none'>('none')

  useEffect(() => {
    if (!briefApplication || (briefApplication && briefApplication.media.length < 1)) {
      setLightColor('none')
    } else if (refPartTwo.current) {
      mObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting === true) {
          setLightColor('yellow')
        } else {
          setLightColor('white')
        }
      })
      mObserver.observe(refPartTwo.current)
    }
    return () => mObserver.disconnect()
  }, [])

  return (
    <section className={myStyles['selection-done']}>
      <div className={myStyles['part-one']}>
        <div className="flex flex-1 flex-col justify-center items-end">
          <div className='flex flex-col items-center'>
            <VogzterPicture
              size={110}
              imgSource={briefApplication?.applicant.profile?.picture?.source}
              gender={briefApplication?.applicant?.profile?.gender}
              showCertified={briefApplication?.applicant?.certified}
              lastActivity={briefApplication?.applicant?.last_activity}
              href={`/dashboard/profil-vogzter/${briefApplication?.applicant?._id}`}
            />
            <h4>
              {briefApplication?.applicant.first_name}{' '}
              {briefApplication?.applicant.last_name}
            </h4>
          </div>
        </div>
        <span className={myStyles['separator']} />
        <div className="flex flex-1 flex-col items-start">
          <div className='flex w-[80%] flex-col items-center'>
            <b className={`${myStyles['casting-type']} tag-${type}`}>
              {castingType}
            </b>
            <h3>{briefR.brief.data?.name}</h3>
            <SubTitle1
              text={truncate(briefApplication?.brief.description ?? '', 200)}
              className={myStyles['desc-brand']}
            />
          </div>
        </div>
      </div>

      <div ref={refPartTwo} className={myStyles['part-two']}>
        <h1>{t('mycastings:purchases')}</h1>
        <div className="flex flex-wrap justify-between">
          {displayMedias &&
            briefR.briefApplication.data
              ?.at(0)
              ?.media?.filter((m) => m && m.status === 'chosen')
              .map((value, i) => (
                <div className="flex flex-1 justify-center ml-4 mr-4" key={i}>
                  <CardBriefApplication
                    media={value}
                    type="download"
                    isPrivate
                    showBookmarked
                  />
                </div>
              ))}
        </div>
      </div>
      <div className={`${myStyles['white-light']} ${lightColor === 'white' ? myStyles['opacity-1'] : myStyles['opacity-0']}`}>
        {' '}
      </div>
      <div className={`${myStyles['yellow-light']} ${lightColor === 'yellow' ? myStyles['opacity-1'] : myStyles['opacity-0']}`}>
        {' '}
      </div>
    </section>
  )
}

interface SelectionDoneProps {
  briefApplication: BaCustom
  displayMedias: boolean
}

SelectionDone.propTypes = {
  briefApplication: PropTypes.any.isRequired,
  displayMedias: PropTypes.bool.isRequired,
}

PrivateDeal.defaultProps = {
  showPortfolio: true
}

PrivateDeal.propTypes = {
  idBa: PropTypes.string.isRequired,
  setShowPortfolio: PropTypes.any.isRequired,
  showPortfolio: PropTypes.bool
}

export default observer(PrivateDeal)