import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ChatFeed, Message } from "react-chat-ui"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { isIE, browserName, isMobileSafari, isIOS, isChrome } from 'react-device-detect';
import Loading from "react-loading"
import styles from "./components/chat-container.module.scss"
import { formatVogzterName, getAvatar, Tags, truncate } from "../../../../utils/helpers"
import { Applicant, BaCustom } from "../../../../types-project/Brief"
import CertifiedVogzter from "../../../Favorites-vogzters/Profil-vogzters/components/CertifiedVogzter"
import SubTitle1 from "../../../../utils/components/Headings/SubTitle1"
import userMobx from "../../../../mobx/user"
import ChatMessages from "../ChatMessages"
import i18n from "../../../../utils/i18n"
import { ClientI } from "../../../../types-project/Client"
import utilsMobx from "../../../../mobx/utils"
import BrandSwitcher from "../../../../utils/components/BrandSwitcher/BrandSwitcher"

// TODO add support for briefApplication messaging
const ChatContainer: FC<ChatContainerProps> = ({ setShowMessages, briefApplication, vogzter, isGuest, chatProps: { messages } }) => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [textMessage, setTextMessage] = useState<string>('')

  const Chat = observer(ChatFeed)

  const sendMessage = () => {
    userMobx.sendMessage(
      textMessage,
      vogzter?._id ?? '',
      userMobx.brand.data?._id ?? ''
    )
    setTextMessage('')
  }

  useEffect(() => {
    if (userMobx.getUserInfos() !== null)
      userMobx.getMe()
  }, [])

  const isFavorite = userMobx.brand.data?.favourites?.includes(vogzter?._id)


  return (
    <div className={`${styles['chat-container-wrapper']}  ${isGuest ? styles['chat-public'] : ''}`}>
      <ChatMessages
        classnameContainer={`${styles['chat-container']} ${isMobileSafari ? styles['mobile-safari'] : ''}`}
        conversation={messages}
        onChangeTextMessage={(v) => { setTextMessage(v) }}
        sendMessage={sendMessage}
        textMessage={textMessage}
        disabledTextMessage={false}
        renderHeader={
          <Fragment>
            <section>
              {utilsMobx.getWidth < 1200 && <button
                type="button"
                onClick={() => {
                  const pageAside = document.getElementById('page-aside')
                  if (pageAside) {
                    pageAside.style.overflowY = 'scroll'
                  }
                  setShowMessages(false)
                }}
              >
                <i className="fa-regular fa-xmark" />
              </button>}
            </section>
            <section>
              {briefApplication && (
                <>
                  { !!briefApplication?.brief.type && (
                    <div className={styles['tags']}>
                      <Tags type={briefApplication?.brief.type.name} />
                    </div>
                  )}
                  <h2>{briefApplication!?.brief.name}</h2>
                  <p className="hidden sm:!flex">
                    {truncate(briefApplication!?.brief?.description, 120)}
                  </p>
                </>
              )}
              {vogzter && (
                <div className={styles['header-vogzter']}>
                  <div className="flex items-center w-full justify-center">
                    <img src={vogzter.profile.picture?.source ?? getAvatar(vogzter.profile.gender)} alt="" />
                    {!isGuest &&
                      <>
                        <hr className="rotate-90" />
                        <BrandSwitcher dntComesFromBriefList className={styles['brand-switcher-profil']} />
                      </>
                    }
                  </div>
                  <p>#{vogzter.parent_code}</p>
                  <b>{formatVogzterName(vogzter.first_name, vogzter.last_name)}</b>
                  {vogzter.certified && (
                    <CertifiedVogzter />
                  )}
                </div>
              )}
            </section>
            <hr />
          </Fragment>
        }
        showBtnSendMessage
        inputStyles={{
          classnameContainerInput: styles['input-container-casting'],
          classnameWrapperInput: styles['input-content-casting'],
          classnameInput: styles['casting-message'],
        }}
        renderCustomContent={
          (briefApplication === undefined &&
            vogzter &&
            !isFavorite) ? (
            isGuest ?
              <Fragment>
                <div className={`${styles['guest']} ${(isIOS && isChrome) ? styles['ios-chrome'] : ''} `} >
                  <b>
                    {t('vogzters:placeholder.chat-guest.title')}
                  </b>
                  <ol type="1">
                    <li data-nb={1}>
                      {t('vogzters:placeholder.chat-guest.subtitle-1')}
                    </li>
                    <li data-nb={2}>
                      {t('vogzters:placeholder.chat-guest.subtitle-2')}
                      <p>
                        {t('vogzters:placeholder.chat-guest.desc-2')}
                      </p>
                    </li>
                    <li data-nb={3}>
                      {t('vogzters:placeholder.chat-guest.subtitle-3')}
                      <p>
                        {t('vogzters:placeholder.chat-guest.desc-3')}
                      </p>
                    </li>

                  </ol>
                  <br className="hidden sm:block" />
                  <button
                    disabled={!isGuest}
                    className={styles['offer-deal']}
                    type="button"
                    onClick={() => {
                      sessionStorage.setItem(
                        'vogzter',
                        JSON.stringify(vogzter._id)
                      )
                      if (isGuest)
                        navigate('/login')
                      else
                        navigate('/dashboard/new-castings/private')
                    }}
                  >
                    <b>{t('mycastings:profil-vogzter:offer-deal')}</b>
                  </button>
                </div>
              </Fragment>
              :
              <Fragment>
                <div className={styles['not-favourite']}>
                  <b className="w-[78%]">
                    {t('mycastings:profil-vogzter:send-msg-title', { vogzter: formatVogzterName(vogzter.first_name, vogzter.last_name) })}
                  </b>
                  <SubTitle1
                    className={styles['send-msg-desc']}
                    text={t('mycastings:profil-vogzter:send-msg-desc', { vogzter: formatVogzterName(vogzter.first_name, vogzter.last_name) })}
                    dangerouslySetInnerHTML
                  />
                  <span role="img" aria-label="">👇</span>
                  <br />
                  <div className="relative flex justify-center">
                    {
                      userMobx.updateFav.loading || userMobx.updateFav.error ? (
                        <Loading color='grey' className={styles['heart']} />
                      ) : (
                        <button
                          type="button"
                          className={styles['heart']}
                          onClick={() => {
                            if (isGuest) {
                              sessionStorage.setItem('vogzter', JSON.stringify(vogzter._id))
                              navigate('/login')
                            } else
                              userMobx.addFavouriteVogzters(vogzter._id, false)
                          }}
                        >
                          <i className="fa-solid fa-heart text-[#FFD1D1]" />
                        </button>
                      )
                    }
                  </div>
                </div>
              </Fragment>
          ) : undefined
        }
      />
    </div>
  )
}

interface ChatContainerProps {
  setShowMessages: Dispatch<SetStateAction<boolean>>
  briefApplication?: BaCustom
  vogzter?: Applicant
  chatProps: ChatFeedInterface
  isGuest?: boolean
}

interface ChatFeedInterface {
  bubblesCentered?: boolean
  bubbleStyles?: object
  messages: Message[]
  senderName: string
  showSenderName?: boolean
}

ChatContainer.propTypes = {
  setShowMessages: PropTypes.func.isRequired,
  briefApplication: PropTypes.any,
  vogzter: PropTypes.any,
  chatProps: PropTypes.any.isRequired,
  isGuest: PropTypes.bool,
}

ChatContainer.defaultProps = {
  briefApplication: undefined,
  vogzter: undefined,
  isGuest: false,
}

export default observer(ChatContainer)