/* eslint-disable @typescript-eslint/lines-between-class-members */
import { Message as Message_ } from 'react-chat-ui'
import { Offer } from '../../../../../types-project/Brief';

interface MessageData {
    id: number | string;
    message: string;
    senderName?: string;
    createdAt?: string;
    read?: boolean;
    lastOne?: boolean;
    offer?: Offer;
    brief?: string;
}

export class Message extends Message_ {
    createdAt?: string;
    read?: boolean;
    lastOne?: boolean;
    offer?: Offer;
    brief?: string;
    // @ts-ignore
    constructor(messageData: MessageData) {
        super(messageData);
        this.createdAt = messageData.createdAt;
        this.read = messageData.read;
        this.lastOne = messageData.lastOne;
        this.offer = messageData.offer;
        this.brief = messageData.brief;
    };
}