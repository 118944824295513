import React, { FC } from 'react'
import PropTypes from 'prop-types'
import styles from '../components/styles.module.scss'

const SearchBar: FC<SearchProps> = ({ placeholder, onChange, value }) => {
  return (
    <div className={`${styles['input-search-container']}`}>
      <label htmlFor="search-id" className="fal fa-search" />
      <input
        type="text"
        id="search-id"
        className={styles['input-search']}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

interface SearchProps extends React.HTMLProps<HTMLInputElement> {
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

SearchBar.defaultProps = {
  placeholder: undefined,
  value: undefined,
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default SearchBar
