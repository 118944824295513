/* eslint-disable no-empty */
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import BtnBack from '../../../../../utils/components/Button/BtnBack'
import styles from './components/steps_casting.module.scss'
import Title1 from '../../../../../utils/components/Headings/Title1'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import VogzterPickerItem from './components/VogzterPicker/VogzterPickerItem'
import userMobx from '../../../../../mobx/user'
import { ClientI, Vogzter } from '../../../../../types-project/Client'
import briefR from '../../../../../mobx/brief'


function Step4({ nextBtnRef, setStep }: Step4Props) {

    const [selectedVogzter, setSelectedVogzter] = useState<undefined | Vogzter>()
    const navigate = useNavigate()
    // translation
    const { t } = useTranslation()

    const favoriteVogzters = userMobx.favouriteVogzters.data as Array<Vogzter>

    useEffect(() => {
        userMobx.getFavouriteVogzters()
        if (nextBtnRef.current) {
            nextBtnRef.current.disabled = true
        }
        try {
            const selectedVogzterSession: Vogzter = JSON.parse(sessionStorage.getItem('selectedVogzter') ?? "")
            setSelectedVogzter(selectedVogzterSession)
        } catch {}
    }, [])

    useEffect(() => {
        if (nextBtnRef.current) {
            nextBtnRef.current.disabled = false
            nextBtnRef.current.onclick = () => {
                briefR.createBrief(type_casting!, (data) => {
                    navigate(`/dashboard/castings/${data._id}/edit`)
                })
            }
        }
    }, [selectedVogzter])


    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { type_casting } = useParams<{
        type_casting: 'creator' | 'exclusive' | 'private'
    }>()

    useEffect(() => {
        userMobx.getFavouriteVogzters()
        try {
            const selectedVogzterSession = JSON.parse(sessionStorage.getItem('selectedVogzter') ?? "")
            setSelectedVogzter(selectedVogzterSession)
            if (nextBtnRef.current) {
                nextBtnRef.current.onclick = () => {
                    briefR.createBrief(type_casting!, (data) => {
                        navigate(`/dashboard/castings/${data._id}/edit`)
                    })
                }
                nextBtnRef.current.disabled = false
            }
        } catch (error) { }

        return () => {
            if (nextBtnRef.current) {
                nextBtnRef.current.disabled = false
            }
        }
    }, [])

    return (
        <section className='justify-center flex min-h-[calc(100vh-183px)] overflow-y-scroll'>
            <div className={"flex w-full md:w-5/6 max-w-4xl flex-col"}>
                <Title1
                    className='uppercase'
                    text={t('new-casting:secondpage:step-2:title')}
                    size={40}
                    style={{ textAlign: 'center' }}
                />
                <SubTitle1
                className='w-full text-center'
                    text={t('new-casting:secondpage:step-2:desc')}
                // style={{ width: '70%' }}
                />

                {userMobx.vogzters.loading ? <h2>Loading</h2> :
                    userMobx.vogzters.error ? <h4>{userMobx.vogzters.error}</h4> :
                        <div className='grid place-items-center w900:!grid-cols-2 !grid-cols-1 xl:!grid-cols-3 md:gap-4 md:place-items-start !mt-7 !pb-12'>
                            {favoriteVogzters?.map((vg, key) =>
                                <VogzterPickerItem
                                    className={`${styles['vogzter-picker-item']} max-w-[17rem] !m-auto`}
                                    selected={selectedVogzter}
                                    setSelection={(v) => {
                                        setSelectedVogzter(v)
                                        sessionStorage.setItem('selectedVogzter', JSON.stringify(v))
                                    }}
                                    vogzter={vg}
                                    key={key}
                                    isFavorite
                                />
                            )}
                        </div>}
            </div>
        </section>
    )
}


export interface Step4Props {
    nextBtnRef: React.RefObject<HTMLButtonElement>
    brand: ClientI['brand']
    setStep: (step: number) => void,
}

export default observer(Step4)