import React, { FC } from 'react'
import PropTypes from 'prop-types'
import s from './components/styles.module.scss'
import ErrorMsg from '../Ui/errors/ErrorMsg'

const SearchBar: FC<SearchProps> = ({ onChange, value, containerClass, inputClass, maxLength }) => {
  return (
    <div className={`${s['div-search']} ${containerClass}`}>
      <input
        className={inputClass}
        type="search"
        id="search"
        maxLength={maxLength}
        onChange={onChange}
        value={value}
        data-search
        placeholder="&#61442; Recherche"
      />
    </div>
  )
}

interface SearchProps extends React.HTMLProps<HTMLInputElement> {
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
  containerClass?: string
  inputClass?: string
}

SearchBar.defaultProps = {
  onChange: undefined,
  value: undefined,
  containerClass: '',
  inputClass: '',
  maxLength: undefined
}

SearchBar.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  maxLength: PropTypes.number
}

export default SearchBar
