import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import userMobx from '../../mobx/user'
import styles from './components/styles.module.scss'
import { ClientI } from '../../types-project/Client'

const Profile: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    userMobx.getMe()
  }, [])
  const userInfos =userMobx.getUserInfos() as ClientI

  const checkIsProfilComplete = () => {
    if ((userMobx.me.data?.email.length ?? 0) > 0 && (userMobx.me.data?.profile?.first_name.length ?? 0) > 0 && (userMobx.me.data?.profile?.name.length ?? 0) > 0 )
      return true
    else return false
  }
  const checkIsBrandComplete = () => {
    if(userInfos._company.type === 'agency'){
      const brand = userMobx.getBrandSession()
      if((brand?.info?.text?.length ?? 0) > 0 &&
        (brand?.info?.url?.length ?? 0) > 0 &&
        (brand?.info?.social_links?.length ?? 0) > 0 )
        return true
      else return false
    } else {
      if((userInfos.brand?.info?.text?.length ?? 0) > 0 &&
        (userInfos.brand?.info?.url?.length ?? 0) > 0 &&
        (userInfos.brand?.info?.social_links?.length ?? 0) > 0 )
        return true
    }
  }
  return (
    <>
      <main
        className={`flex flex-col items-center w-full h-auto sm:min-h-full p-4 justify-center`}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Vogz - Profil</title>
        </Helmet>
        <section className={`${styles['items-profile']}`}>
          {userMobx.me.loading ? (
            <div>loading</div>
          ) : userMobx.me.error ? (
            <h1>error</h1>
          ) : (
            <>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    navigate('/dashboard/profile/my-profile')
                  }}
                  className={styles['profil']}
                >
                  <span className={`${checkIsProfilComplete()? 'bg-[#46FFB1]':'bg-[#FED339]'}`}>{checkIsProfilComplete()? t("myprofil:validate"): t("myprofil:to-complete")  }</span>
                  <img src="/assets/svgs/myProfil.svg" alt="decorative" />
                  {t('myprofil:profil')}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    navigate('/dashboard/profile/team')
                  }}
                  className={styles['profil']}
                >
                  <img src="/assets/svgs/myTeam.svg" alt="decorative" />
                  {t('myprofil:team')}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    navigate('/dashboard/profile/brand')
                  }}
                  className={styles['profil']}
                >
                  <span className={`${checkIsBrandComplete()? 'bg-[#46FFB1]':'bg-[#FED339]'}`}>{checkIsBrandComplete()? t("myprofil:validate"): t("myprofil:to-complete")  }</span>
                  <img src="/assets/svgs/myBrand.svg" alt="decorative" />
                  {userMobx.me.data?._company.type === 'agency'?  t('myprofil:brands') : t('myprofil:brand')}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() =>  userMobx.getLinkStripe(data=>window.open(data))}
                  className={styles['profil']}
                >
                  <img src="/assets/svgs/subscription-pic.svg" alt="decorative" />
                  {t('myprofil:subscription')}
                </button>
              </div>
            </>
          )}
        </section>
      </main>
    </>
  )
}
export default Profile
